import React, { Component } from 'react';
import { Row, Col, Table, Button, message, Input } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class MyClients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // data: {},
      data: [],
      pagination: {},
      page: 1,
      size: 5,
			sortField:"updated_at",
      // sortField:"name",
      sortOrder:"ascend",
      searchValues: {
        name: '',
        description: '',
        email: '',
        address: '',
        iscommission:"",
        commission:''
      }
    }
    this.lang = props.language._CLIENT.SETUP;
    this.msg = props.language._CLIENT.MESSAGE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.DESCRIPTION}
            <Search
              placeholder={this.lang.TABLEHEADERS.DESCRIPTION}
              onChange={this.handleChangeSearchValues("description")}
            />
          </span>
        ),
        dataIndex: 'description',
        key: 'description',
        width: '20%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.description, b.description),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.DESCRIPTION}
            <Search
              placeholder={this.lang.TABLEHEADERS.DESCRIPTION}
              onChange={this.handleChangeSearchValues("description")}
            />
          </span>
        ),
        dataIndex: 'description',
        key: 'description',
        width: '20%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.description, b.description),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.EMAIL}
            <Search
              placeholder={this.lang.TABLEHEADERS.EMAIL}
              onChange={this.handleChangeSearchValues("email")}
            />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        width: '20%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.email, b.email),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.EMAIL}
            <Search
              placeholder={this.lang.TABLEHEADERS.EMAIL}
              onChange={this.handleChangeSearchValues("email")}
            />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        width: '20%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.email, b.email),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.ADDRESS}
            <Search
              placeholder={this.lang.TABLEHEADERS.ADDRESS}
              onChange={this.handleChangeSearchValues("address")}
            />
          </span>
        ),
        dataIndex: 'address',
        key: 'address',
        width: '20%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.address, b.address),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.ADDRESS}
            <Search
              placeholder={this.lang.TABLEHEADERS.ADDRESS}
              onChange={this.handleChangeSearchValues("address")}
            />
          </span>
        ),
        dataIndex: 'address',
        key: 'address',
        width: '20%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.address, b.address),
      },
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '20%',
        key: 'last_name',
        align:'center'
      }
    ];
  }

  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    localStorage.setItem('updateClientId', record.id);
    this.props.history.push('/dashboard/clientupdate');
  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager, pageNumber: pagination.current });

    if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
      if (this.state.pageNumber  !== pager.current) {
        this.fetch({ page: pagination.current, });
      }
    } 
    else {
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }

    /*let pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      page: pager.current,
      pagination: pager,
    }, () => {
      this.fetch();
    })*/

  }//End of Method

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/clients/${record.id}`,{headers})
      .then(res => { /*console.log(res)*/
        message.success(this.msg.DELETESUCCESS);
        this.fetch();
      })
      .catch(err => {
        message.error(this.msg.DELETEFAILURE,3);
        console.log('Error occured while deleting the Client->',err);
      })
  }

  componentDidMount() {
    /*console.log(this.props);*/
    this.fetch();
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
        <Col span={13}>
          <Link to="/dashboard/addclient">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={13}>
          <Link to="/dashboard/addclient">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
      </Row>
    }
  }

  fetch = (params={}) => {
    const headers = getHeadersForHttpReq();
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else { sortOrder = 'DESC' }
    
    let data ={
      // client_id : client_id,
      name: this.state.searchValues.name || '',
      description: this.state.searchValues.description || '',
      email: this.state.searchValues.email || '',
      address: this.state.searchValues.address || '',
      iscommission: this.state.searchValues.iscommission || "",
      commission: this.state.searchValues.commission || '',
      page,
      sortColumn: params.sortField || this.state.sortField ,
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'no'
    }
    // console.log('Sending data to api ->',data);
    axios.post(`${complete_URL}/clients/pagination_table`,data, {headers})
    .then(response => {
      // console.log('response of exp new pagination ->',response)
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      this.setState({ data: response.data.data, pagination })
      // console.log('Expense data-> ',response);
    })
    .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })
    
    /*axios.get(`${complete_URL}/clients/table/?page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`)
      .then(res => {
        //console.log(res.data);
        let pagination = { ...this.state.pagination };
        pagination.total = res.data.totalElements;
        pagination.pageSize = parseInt(res.data.size);
        this.setState({
          data: res.data,
          pagination: pagination,
        })
      })
      .catch(err => {  console.log(err) })*/
  }

  render() {
    return (
        <>
          {this.CustomRow}
          <Row id="clientsTable">
            <Col span={2}></Col>
            <Col span={20}>
              <Table
                columns={this.columns}
                rowKey="id"
                // dataSource={this.state.data.selectedElements}
                dataSource={this.state.data}
                onChange={this.handleChangePagination}
                pagination={this.state.pagination}
              />
            </Col>
          </Row>
        </>
    )
  }
}

export default MyClients;