import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route,Switch} from 'react-router-dom'
import Login from './screens/Login'
import SelectClient from './screens/SelectClient'
import Dashboard from './screens/Dashboard'
import english from './translation/bqt-english'
import arabic from './translation/bqt-arabic'
import RoutNotFound from './components/RoutNotFound/index'



class App extends Component {
  render() {
    let language = {};
    if (localStorage.getItem("lang")) {
      let currentLang = localStorage.getItem("lang");
      if (currentLang === "EN") {
        require('./index_en.css')
        // console.log("chek",currentLang === "EN")
        language = english;
      }
      else if(currentLang === "AR"){
        // console.log("chek false",currentLang === "AR")
        require('./index_ar.css')
        language = arabic;

      }
    }
    else {
      localStorage.setItem("lang", "EN");
      window.location.reload()
    }

    return (
      <Router>
        <Switch>
        <Route exact path="/" render={(props) => <Login {...props} language={language} />} />
        <Route exact path="/select" render={(props) => <SelectClient {...props} language={language} />} />
        <Route path="/dashboard" render={(props) => <Dashboard {...props} language={language} />} />
        <Route exact render={(props) => <RoutNotFound {...props} language={language} />} />
        </Switch>
      </Router>
    );
  }
}

export default App;
