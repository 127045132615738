import React, { Component } from 'react';
import { Row, Col,Table,Select, message, Button,Input,Form, Icon, Spin, Modal } from 'antd';
import ReactGoogleMapMultiMarker from '../ReactGoogleMapMultiMarker';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';

if (localStorage.getItem('lang') === 'AR') {
    require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Client_Requests extends Component{
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            service_types : [],
            pagination: {},
            page: 1,
            size: 5,
            sortField:"name",
            sortOrder:"ascend",
            searchValues: {
              selected_service_type: '',
              name: '',
              name_ar: '',
              email: '',
              id_card: '',
              ph_num: '',
              ph_alt_num: '',
              new_service_type_name: '',
              new_service_type_name_ar: '',
              commercial_reg_no: '',
              orderBy : '',
              column_name :'name'
            },
            pkgmodelVisible : false,
            reqmodelVisible : false,
            selected_contractor : {},
            file: null,
            name: '',
            upload : false,
            dir : 'ltr', 
            display: 'block',
            spin : true,
            showMap : true,
        }

    this.lang = props.language._CLIENT_CONTRACTOR_REQ.SETUP;
    this.pkg_lang = props.language._CONTRACTOR.UPDATE.PACKAGE;
    this.msg  = props.language._CLIENT_CONTRACTOR_REQ.MESSAGE;

    
    this.columns = [
      (localStorage.getItem('lang')==='EN')?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width : '20%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name, b.name)
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAMEAR}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAMEAR}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name_ar',
        key: 'name_ar',
        align : 'right',
        width : '20%',
        sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar)
      },
      (localStorage.getItem('lang')==='EN')?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.EMAIL}
            <Search
              placeholder={this.lang.TABLEHEADERS.EMAIL}
              onChange={this.handleChangeSearchValues("email")}
            />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        width : '20%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.email, b.email),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.EMAIL}
            <Search
              placeholder={this.lang.TABLEHEADERS.EMAIL}
              onChange={this.handleChangeSearchValues("email")}
            />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        align : 'right',
        width : '20%',
        sorter: (a, b) => this.compareByAlph(a.email, b.email),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.PHONENO}
            <Search
              placeholder={this.lang.TABLEHEADERS.PHONENO}
              onChange={this.handleChangeSearchValues("ph_num")}
            />
          </span>
        ),
        dataIndex: 'ph_num',
        key: 'ph_num',
        width : '15%',
        sorter: (a, b) => this.compareByAlph(a.ph_num, b.ph_num),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.PHONENO}
            <Search
              placeholder={this.lang.TABLEHEADERS.PHONENO}
              onChange={this.handleChangeSearchValues("ph_num")}
            />
          </span>
        ),
        dataIndex: 'ph_num',
        key: 'ph_num',
        width : '15%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.ph_num, b.ph_num),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.STATUS}
            <Search
              placeholder={this.lang.TABLEHEADERS.STATUS}
              onChange={this.handleChangeSearchValues("status")}
            />
          </span>
        ),
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.address, b.address),
        render : (text, record) =>  record.ccr_id ? <p>{text}</p> : <p>{'--'}</p> 
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.STATUS}
            <Search
              placeholder={this.lang.TABLEHEADERS.STATUS}
              onChange={this.handleChangeSearchValues("status")}
            />
          </span>
        ),
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.status, b.status),
        render : (text, record) =>  record.ccr_id ? <p>{text}</p> : <p>{'--'}</p> 
      },
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.fetchPackageData.bind(this, record)}>{this.lang.BUTTON.DISPLAYPKGS}</Button>
          </span>
        ),
        key: 'pkg_btn',
        width : '7%',
        align:'center'
      },
      {
        title: `${'.'}`,
        render: (text, record) => (
          <span>
            {
              record.cc_id ? 
              <Button type="primary" disabled = {true}>{this.lang.BUTTON.ASSIGNED}</Button>
              :
              record.ccr_id ? 
                <Button type="primary" disabled = {true}>{record.req_by == 1 ? this.lang.BUTTON.REQUESTED : this.lang.BUTTON.RECEIVED}</Button>
                :
                <Button type="primary" onClick={this.displayRequestModal.bind(this, record)}>{this.lang.BUTTON.SEND_REQ}</Button>
            }
          </span>
        ),
        key: 'req_btn',
        width : '7%',
        align:'center'
      }
    ];

     
    this.pkg_columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'type',
        render: (text, record) =>  <p>{text}</p>,
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'type',
        align:'right',
        render: (text, record) =>  <p>{text}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        render: (text, record) =>  <p>{text}</p>,
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        align:'right',
        render: (text, record) =>  <p>{text}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONPERCENT}`,
        dataIndex: 'commission_percentage',
        render: (text, record) =>  <p>{text}</p>,
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONPERCENT}`,
        dataIndex: 'commission_percentage',
        align:'right',
        render: (text, record) =>  <p>{text}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        render: (text, record) =>  <p>{text}</p>,
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        align:'right',
        render: (text, record) =>  <p>{text}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.ISACTIVE}`,
        dataIndex: 'is_active',
        render: (text, record) => <p>{text===1? 'Yes':'No'}</p>,
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.ISACTIVE}`,
        dataIndex: 'is_active',
        align:'right',
        render: (text, record) => <p>{text===1? 'نعم':'لا'}</p>,
      }
    ]

    }// End of Client_Requests
    
    setComponentDirection=()=>{
      if(localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
      }
      else {
        this.setState({dir : 'rtl', display: 'flex'})
      }
    }
    
    compareByAlph = (a, b) => {
        if (a > b) { return -1; }
        if (a < b) { return 1; }
        return 0;
    }
    
    displayRequestModal =(record)=>{
      this.setState({modelVisible : true, selected_contractor : record})
    }

    handleSendRequest =()=>{
      let c_notes = this.refs.desc_Ref.state.value ? this.refs.desc_Ref.state.value : '--';
      this.setState({spin_msg : this.msg.REQSENDTOCONTTIP, spin : true,modelVisible : false, pkgmodelVisible : false});

      const headers = getHeadersForHttpReq();
      let data = {
        lang : localStorage.getItem('lang'),
        cont_id : this.state.selected_contractor.id,
        user_id : localStorage.getItem('user_id'),
        client_id : localStorage.getItem('client_id'),
        contractor_email : this.state.selected_contractor.email,
        req_by : 'client',
        client_desc : c_notes
      }    
      axios.post(`${complete_URL}/cont_clients_req/add`, data, {headers})
      .then(res => { //console.log('cont req res.data->',res.data);
        if (res.data.success) {
          this.handleSubmitDocument();
          this.fetch();
          message.success(this.msg.REQSENDTOCONTSUCCESS);
        } 
        else {
          this.setState({modelVisible : false, spin : false})
          message.error(this.msg.REQSENDTOCONTFAILURE,3);
        }
      })
      .catch(err => {
        this.setState({modelVisible : false, spin : false});
        console.log('Error occured while sending the Request to Service Provider->',err);
        if(err.data.code === '002'){
          message.error(this.msg.REQMAILSENDTOCONTFAILURE,3);
        }
        else {
          message.error(this.msg.REQSENDTOCONTFAILURE,3);
        }
      })
    }// End of Method

    displayRequestModal =(record)=>{
      this.setState({modelVisible : true, selected_contractor : record})
    }

    handleChangePagination = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({ pagination: pager, pageNumber: pagination.current });
    
        if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
          if (this.state.pageNumber  !== pager.current) {
            this.fetch({ page: pagination.current, });
          }
        } 
        else {
          this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
          });
        }
    }

    handleChangeSearchValues = (prop) => (e) => {
        let search = { ...this.state.searchValues };
        search[`${prop}`] = e.target.value;
        this.setState({
          searchValues: search
        }, () => {
          this.fetch();
        });
    }
  
    fetch = (params={}) => {
      const client_id = localStorage.getItem('client_id');
      const headers = getHeadersForHttpReq(); 
      let page,sortOrder ;
      if (params.page) { page = params.page - 1 }
      else { page = 0;}

      if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
      else if (params.sortOrder == 'descend') { sortOrder = 'DESC' }
      else { sortOrder = 'ASC' }
      
      let data ={
        client_id : client_id,
        selected_service_type : this.state.searchValues.selected_service_type || '',
        name: this.state.searchValues.name || '',
        name_ar: this.state.searchValues.name_ar || '',
        email: this.state.searchValues.email || '',
        id_card: this.state.searchValues.id_card || '',
        ph_num: this.state.searchValues.ph_num || '',
        ph_alt_num: this.state.searchValues.ph_alt_num || '',
        new_service_type_name: this.state.searchValues.new_service_type_name || '',
        new_service_type_name_ar: this.state.searchValues.new_service_type_name_ar || '',
        commercial_reg_no: this.state.searchValues.commercial_reg_no || '',
        
        page,
        sortColumn: params.sortField || this.state.sortField ,
        sortOrder,
        size:this.state.size,
        orderBy: '',
        column_name: 'no'
      }
        // axios.post(`${complete_URL}/cont_clients_req/fetch_cont_for_client_to_reqs_pagination_table`,data, {headers})
        // axios.post(`${complete_URL}/cont_clients_req/fetch_cont_for_client_to_reqs_bySrv_pagination_table`,data, {headers})
        axios.post(`${complete_URL}/cont_clients_req/fetch_un_cont_for_client_to_reqs_bySrv_pagination_table`,data, {headers})
        .then(response => {
          // console.log('response of fetch_cont_for_client_to_reqs_bySrv_pagination_table response.data ->',response.data);
          let pagination = {};
          Object.assign(pagination,{
            ...this.state.pagination,
            total : response.data.totalElements,
            current : page +1,
            pageSize : this.state.size
          });
          
          this.setState({ data: response.data.data, pagination, spin : false});
        })
        .catch(err => { 
          this.setState({spin : false});
          console.log('Error occured while fetching pagination data new method ->',err);
         })
    }

    getIndServiceContractorForRequests =(service_type_id)=>{
      if(service_type_id){ //console.log('service_type_id->',service_type_id);
        let old_obj = Object.assign({},this.state.searchValues); 
        Object.assign(old_obj,{selected_service_type : service_type_id});
        this.setState({searchValues : old_obj},()=>{
          this.fetch();
        });
      }
    }// End of Method

    //New ajax API call for fetching Assigned independent contractor of selected Service Type
    getAssignedServiceTypes = (client_id)=>{
      const headers = getHeadersForHttpReq();
      axios.get(`${complete_URL}/client_servicetypes/${client_id}`,{headers})
        .then(res => { // console.log('service_type->',res)
          this.setState({ service_types: res.data.data })
        })
        .catch(err => {  console.log('Issue while fetching new service types ->',err) })
    }

    componentDidMount(){
      const client_id = localStorage.getItem('client_id');
      this.fetch();
      this.getAssignedServiceTypes(client_id);
      this.setComponentDirection();
    }

    fetchPackageData = async (record) => {
      const cont_id = record.id;
      if (cont_id) {
        let headers = getHeadersForHttpReq(); 
        await axios.get(`${complete_URL}/contractorpackages/fetch_by_contractor/${cont_id}`,{headers})
        .then(res => { // console.log('packagesData->',res.data.data[0])
            this.setState({ packagesData: res.data.data, pkgmodelVisible : true })
        })
        .catch(err => { console.log('Error while fetching Contractor packages->',err); })
      }
      else {
        message.error('Kindly select any Service Provider and try again!',3);
      }
    }
    
    componentWillMount() {
      // if (localStorage.getItem('lang') === 'EN') {
      //   this.CustomRow = <Row id="clientTableHeader">
      //     <Col span={1}> </Col>
      //     <Col span={10}><p style={{ textAlign: 'left'}}>{this.lang.TABLEHEADERS.REQCONTTITLE}</p></Col>
      //     <Col span={12}>{'NOTE: Display independent Service Types Dropdown'} </Col>
      //   </Row>
      // }
      // else {
      //   this.CustomRow = <Row id="clientTableHeader">
      //     <Col span={12} > </Col>
      //     <Col span={10}><p>{this.lang.TABLEHEADERS.REQCONTTITLE}</p></Col>
      //     <Col span={1}></Col>
      //   </Row>
      // }
    }

    closeModal = (key, value)=>{
      this.setState({[key] : value},()=>{
        if (key === 'modelVisible' && value === false) {
          this.removeDoc();
        }
      })
    }

    handleSaveDocForUpload = (e) => {
      e.preventDefault();
      if (e.currentTarget.files && e.currentTarget.files[0]) {
        var file = e.currentTarget.files[0];
        let fileName = file.name;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (file.size <= 1024 * 1024 * 2 && /png|xlsx|pdf|odt|docx|jpe?g/i.test(extension)) {
          // console.log(extension)
          this.setState({ file: file, name:fileName, upload: true } )
        } 
        else {
          this.setState({file: null,name : '', upload:false},()=>{
            message.error(this.msg.REQSENDTDOCTYPEERROR,2);
          })
        }
      }// End of Method File format check
    }// End of Method


    handleSubmitDocument = () => {
      if (this.state.upload && this.state.file) {
        let imgdata = new FormData(); 
        imgdata.append('file', this.state.file);
        imgdata.append('name', this.state.name);
        imgdata.append('req_by', '1');
        imgdata.append('user_id', localStorage.getItem('user_id'));
        imgdata.append('cont_id', this.state.selected_contractor.id);
        imgdata.append('client_id',localStorage.getItem('client_id'));
        let headers = {
          'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
          'Content-Type': 'multipart/form-data'
        };
      
        this.setState({ spin : true });
        axios.post(`${complete_URL}/images/cont_client_doc/add`, imgdata, { responseType: 'blob' }, headers)
          .then(res => {
            let reader = new FileReader();
            reader.readAsDataURL(res.data);
            this.setState({spin:false, file: null, upload:false },()=>{
              message.success(this.msg.REQSENDTDOCADDSUCCESS,2);
            })
          })
          .catch(err => {
            console.log('Error occured while uploading Contract doc by Contractor while sending request->',err)
            this.setState({spin:false },()=>{ message.error(this.msg.REQSENDTDOCADDFAILURE,3); });
          })
      } //may used or may be removed
    }//End of Method

    removeDoc=()=>{
      this.setState({file: null, upload:false});
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      let {dir, display, service_types, file, data, pagination, packagesData, pkgmodelVisible,
         modelVisible, showMap, spin_msg, spin } = this.state;
      
      let EN_FLAG = localStorage.getItem('lang') === 'EN';
      let w = window.innerWidth;
      // console.log('w ->',w);
      let servTypeWidth = {width : '30%'};
      //1200px, 1200px >= x > 990px, 990px >= x > 768px,  768px >= x > 640px, 640px >= x > 480, 480 >= x > 400, 400 >= x -> never gonna happen?
      if(w >=1680){ servTypeWidth = {width : '40%'}; }
      else if (w < 1680 && w >= 1280) { servTypeWidth = {width : '40%'}; }
      else if (w < 1280 && w >= 768) { servTypeWidth = {width : '70%'}; }
      else {servTypeWidth = {width : '100%'};}
      let infoBorder = EN_FLAG ? {borderLeftStyle : 'solid'} :{borderRightStyle :'solid'} ;

      return (
          <Spin tip = {spin_msg} spinning = {spin}>
            {/* {CustomRow} */}
            <Row gutter={24} dir= {dir} style={{display : display,marginBottom:'2%' }}>
              <Col span={12} >{/*TITLE & SEVICE TYPE DROPDOWN */}
              <Row gutter={24} id = 'clientTableHeader' dir= {dir} style={{display : display,marginBottom:'2%' }}>
                <Col span={2}></Col>
                <Col span={22}><p style={{ textAlign: 'left'}}>{this.lang.TABLEHEADERS.REQCONTTITLE}</p></Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
              <Col span={2}></Col>
                <Col span={22}>
                  <Form.Item label={this.lang.TABLEHEADERS.SERVICETYPE}>
                    {getFieldDecorator('service_type', {
                      rules: [{ required: true, message: this.msg.SELECTSERVICETYPEERROR }],
                    })(
                      <Select style={servTypeWidth} onChange={this.getIndServiceContractorForRequests} placeholder={this.lang.TABLEHEADERS.SERVICETYPE}>
                        {dir === 'ltr' ?
                          service_types.length > 0 && service_types.map(item => (
                            <Select.Option value={item.new_service_type} key={item.id}> { item.name } </Select.Option>
                          )) :
                          service_types.length > 0 && service_types.map(item => (
                            <Select.Option style={{textAlign:'right'}} value={item.new_service_type} key={item.id}> { item.name_ar } </Select.Option>
                          )) }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              </Col>
              <Col span={12} style={infoBorder}>
                {showMap && data.length > 0 &&
                  <ReactGoogleMapMultiMarker  /**Pass array of Contractors to show Marker & PopOver */
                    {...this.props} 
                    lat = {23.614328} /*Center Point */
                    lng = {58.545284}
                    markers = {data}
                    is_dynamic = {false}
                  />}
              </Col>
            </Row>
            
            <Row id="clientsTable" gutter={24}>{/*Contractors Table */}
                <Col span={24}>
                <Table
                    columns={this.columns}
                    rowKey="id"
                    // style={{marginTop : '10px'}}
                    // scroll={{ x: 1200 }}
                    dataSource={ data }
                    onChange={this.handleChangePagination}
                    pagination={pagination}
                /> 
                </Col>
            </Row>
            <Row>{/*Send Request Model */}
              <Modal 
                title={this.lang.BUTTON.SEND_REQ}
                visible={modelVisible} 
                closable = {true}
                destroyOnClose={true}
                onCancel = {()=>this.closeModal('modelVisible',false)} 
                width = {'50%'}
                footer = {false}
              >
                <React.Fragment>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Input.TextArea ref="desc_Ref" rows={12} style={{ width: '100%' }} />
                    </Col>
                  </Row>
                  <br/>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Input type="file" style={{lineHeight: '16px'}} onChange={this.handleSaveDocForUpload} /> 
                    </Col>
                    <Col span={12}>
                      {file &&
                        <><span>{file.name}</span>
                        <Icon type="close" style={{marginLeft:'10px',textAlign:'right'}} onClick={()=>{this.removeDoc()}}/></>
                      }
                    </Col>
                  </Row>
                  <Row gutter={24} style={{marginTop: '5%'}}>
                    <Col span={24}  style={{textAlign: 'center'}}>
                      <Button type="primary" shape="round" onClick={this.handleSendRequest}>{this.lang.BUTTON.SEND_REQ} </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              </Modal>
            </Row>
            <Row>{/*Model of Packages of Select Contractor */}
              <Modal 
                title={this.pkg_lang.TITLE}
                visible={pkgmodelVisible} 
                closable = {true}
                destroyOnClose={true}
                onCancel = {()=>this.closeModal('pkgmodelVisible',false)}
                width = {'70%'}
                footer = {false}
              >
                <Row id="packagesTable"  style={{ overflow: 'scroll' }}>
                  <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                      <Table 
                        rowKey="pkg_id" 
                        size={'small'}
                        style={{ marginTop: '10px' }} 
                        columns={this.pkg_columns} 
                        dataSource={packagesData} 
                      />
                  </Col>
                </Row>
              </Modal>
            </Row>
            
          </Spin>
      )
    }// End of render
  }//End of Component

  export default Client_Requests =  Form.create({ name: 'sendReqBy' })(Client_Requests);