import React, {Component} from 'react'
import { Row, Col, Form, Icon, Button, InputNumber, Select, Table, message, Spin } from 'antd'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
// import moment from 'moment';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class HallBookingPrice extends Component {
  constructor(props) {
    super()
    this.state = {
      hall_id : props.hall_id,  
      uom: [],
      uop: [],
      days: [], 
      data: [],
      prices: [],
      sortField:"day_name",
      sortOrder:"ascend",
      pagination: {},
      page: 1,
      size: 10,
      searchValues: {
        day_name : '',
        day_name_ar : '',
        uop_name : '',
        uop_name_ar: '',
        price : '',
      },
      
      updateHallPrice: '',
      priceList: false, 
      aprice_spin : false,
      uprice_spin : false,
      dir : 'ltr', 
      display: 'block', 
    }
    this.lang = props.lang;
    this.msg = props.msg;

    this.colms_price = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.DAY}`,
        dataIndex: 'day_name',
        width: '30%',
        align : 'center',
        render: (text, record) =><div style={{textAlign:'center'}}><button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>
           { text }
          </button></div>,
      } :
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.DAY}`,
        dataIndex: 'day_name_ar',
        width: '27%',
        align : 'right',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>
           { text
            //  this.state.days.filter( item => item.id == text)[0].name
             }
          </button>,
      } ,
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.UOM}`,
        dataIndex: 'uop_name',
        width: '30%',
        align : 'center',
        render: (text, record) =><div style={{textAlign:'center'}}><button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button></div> ,
      } :
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.UOM}`,
        dataIndex: 'uop_name_ar',
        align : 'right',
        width: '27%',
        render: (text, record) =><div style={{textAlign:'center',paddingLeft : '40%'}}><button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button></div>,
      } ,
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.PRICE}`,
        dataIndex: 'price',
        width: '18%',
        align : 'center',
        render: (text, record) =><div style={{textAlign:'center'}}> <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button></div>,
      }:
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.PRICE}`,
        dataIndex: 'price',
        align : 'right',
        width: '27%',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      },
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deletePrice.bind(this, record)}>{this.lang.HALLPRICE.SETUP.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '22%',
        align : 'center',
        key: 'last_name',
      }
    ]

  }//End of Constructor 
  
  
  handlePriceNavigation = (record) => {
    let data = {
      id: record.id,
      hall_id: record.hall_id,
      uop: record.uop,
      // created_by: record.created_by,
      // updated_by: record.updated_by,
      price: record.price,
      day: record.day,
      day_name : record.day_name,
      day_name_ar : record.day_name_ar
    }
    // console.log('onclick hallprice record :',record)

    this.setState({ updateHallPrice: data, priceList: 2 })
  }

  deletePrice = (record) => {
    let  headers =  getHeadersForHttpReq();
    
    axios.delete(`${complete_URL}/hallprices/${record.id}`,{headers})
      .then(res => {
        message.success(this.msg.HALLPRICEDELETESUCCESS ,2);
        this.fetch();
      })
      .catch(err => { 
        console.log('Error occured while deleting the hall price->',err);
        message.error(this.msg.HALLPRICEDELETEFAILURE ,2);
      });
  }

  fetch = (params={}) => { 
    const client_id = localStorage.getItem('client_id');
    let  headers =  getHeadersForHttpReq();
    
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else { sortOrder = 'DESC' }
    
    let data ={
      client_id : client_id,
      hall_id : this.state.hall_id ,
      day_name : this.state.searchValues.day_name || '',
      day_name_ar : this.state.searchValues.day_name_ar || '',
      uop_name : this.state.searchValues.uop_name || '',
      uop_name_ar: this.state.searchValues.uop_name_ar || '',
      price : this.state.searchValues.price || '',

      page,
      sortColumn: params.sortField || this.state.sortField ,
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'no'
    }
    // console.log('Sending data to api ->',data);
    axios.post(`${complete_URL}/hallprices/pagination_table`,data, {headers})
    .then(response => {
      // console.log('response of exp new pagination ->',response)
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      this.setState({ data: response.data.data, pagination })
      // console.log('Expense data-> ',response);
    })
    .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })

  }//End of Method

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
         pagination: pager,
         pageNumber: pagination.current
      });

      if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
        if (this.state.pageNumber  !== pager.current) {
          this.fetch({ page: pagination.current });
        }
      } 
      else {
         this.fetch({
         results: pagination.pageSize,
         page: pagination.current,
         sortField: sorter.field,
         sortOrder: sorter.order,
         ...filters,
         });
      }

  }//End of Method
  
  handleSubmitHallPrice = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['rice','uop','day'],(err, values) => {
      if (!err) {
        this.setState({aprice_spin : true});
        // let newValues = {}
        // newValues.price = values.rice
        // newValues.uop = values.uop
        // newValues.day = values.day
        // newValues.hall_id = this.state.hall.id
        // newValues.user_id = user_id
        // console.log(newValues)
        const user_id = localStorage.getItem('user_id');
          
        Object.assign(values,{
          user_id : user_id, 
          hall_id : this.state.hall_id,
          price : values.rice
        });
        let self = this
        const headers = getHeadersForHttpReq();
        
        axios.post(`${complete_URL}/hallprices/add`, values,{headers})
          .then(res => {
            if (res.data.success) {
              self.setState({ aprice_spin : false, priceList: false, },()=>{
                message.success(this.msg.HALLPRICEADDSUCCESS,2);
                this.fetch();
              })
            }
            else{
              self.setState({ aprice_spin : false },()=>{
                message.error(this.msg.HALLPRICEADDFAILURE,2);
              })
            }
          })
          .catch(err => {
            console.log('Error occured while adding the Price in the hall->',err);
            self.setState({ aprice_spin : false,},()=>{
              message.error(this.msg.HALLPRICEADDFAILURE,2);
            })
          })
      }//End of if error not found
    })
  }//End of Method

  handleSubmitHallPriceUpdate = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['price1','uop1','day1'],(err, values) => {
      if (!err) {
        this.setState({uprice_spin : true});
        // let newValues = {}
        // newValues.hall_id = this.state.hall.id
        // newValues.user_id = user_id
        // newValues.uop = values.uop
        // newValues.day = values.day
        // newValues.price = values.rice

        const user_id = localStorage.getItem('user_id');
        Object.assign(values,{
          user_id : user_id, 
          hall_id : this.state.hall_id,
          // price : values.price1,
          // uop : values.uop1,
          // day : values.day1
        });
        // console.log(this.state.updateHallPrice.id)
        let self = this
        let  headers =  getHeadersForHttpReq();
        
        axios.put(`${complete_URL}/hallprices/update/${this.state.updateHallPrice.id}`, values , {headers})
          .then(res => {
            if (res.data.success) {
              self.setState({ uprice_spin : false, priceList: false, },()=>{
                message.success(this.msg.HALLPRICEUPDATESUCCESS,2);
                this.fetch();
              })
            }
            else{
              self.setState({ uprice_spin : false,},()=>{
                message.error(this.msg.HALLPRICEUPDATEFAILURE,2);
              })
            }
          })
          .catch(err => {
            console.log('Error occured while updating the Price in the hall->',err);
            self.setState({ uprice_spin : false,},()=>{
              message.error(this.msg.HALLPRICEUPDATEFAILURE,2);
            })
          })
      }
    })
  }

  componentWillMount(){
    if (localStorage.getItem('lang') === 'EN') {
      
      this.CreateHallPriceHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ priceList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.HALLPRICE.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.HallPriceTableHeader = <Row id="clientTableHeader">
        <Col span={9}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.HALLPRICE.SETUP.LABELS.TITLE}</p></Col>
        <Col span={14}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ priceList: true }) }}>{this.lang.HALLPRICE.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateHallPriceHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ priceList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.HALLPRICE.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
    else {
      
      this.CreateHallPriceHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ priceList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.HALLPRICE.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.HallPriceTableHeader = <Row id="clientTableHeader">
        <Col span={8}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ priceList: true }) }}>{this.lang.HALLPRICE.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={15}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.HALLPRICE.SETUP.LABELS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateHallPriceHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ priceList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.HALLPRICE.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }

  }

  componentDidMount() {
    this.getHallDataByParameter('uom');
    this.getHallDataByParameter('uop');
    this.getHallBookingDays();
    this.fetch();
    this.setComponentDirection();
  }

  getHallDataByParameter =(key)=>{ 
    const headers = getHeadersForHttpReq();
    if (key) {
      axios.get(`${complete_URL}/parameters/${key}`, {headers})
      .then(result => { //console.log(result)
        this.setState({ [key] : result.data.data[0] })
      })
      .catch(err => { console.log('Error occured while fetching '+key,err) })
    }
  }//End of Method

  getHallBookingDays =()=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/HALL_PRICE_DAY`,{headers})
    .then(result => { //console.log('days',result.data.data)
      this.setState({ days: result.data.data})
    })
    .catch(err => {  console.log('Error occured while fetching Hall booking days ->',err) })
  }//End of Method

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    let {dir, display, uop, days, priceList, data, pagination} = this.state;
    
    return (
        <Row gutter={24} >
            <Col span={24}>
            {
                  //Create Hall Price Screen
              priceList === true ?
                  <Spin tip={this.msg.HALLPRICEADDTIP} spinning = {this.state.aprice_spin}>
                    <Form onSubmit={this.handleSubmitHallPrice} style={{ marginTop: '20px' }}>
                      {this.CreateHallPriceHeader}
                      <Row gutter={24} dir = { dir } style={{ display }}>
                        <Col span={8}>
                          <Form.Item label={this.lang.HALLPRICE.CREATE.LABELS.PRICE}>
                            {getFieldDecorator('rice', {
                              rules: [{ required: true, message: this.msg.INSERTPRICEERROR }],
                            })(
                              <InputNumber step={0.1} placeholder= {this.lang.HALLPRICE.CREATE.LABELS.PRICE} style={{ width: '100%' }} />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label={this.lang.HALLPRICE.CREATE.LABELS.UOM}>
                            {getFieldDecorator('uop', {
                              rules: [{ required: true, message: this.msg.SELECTUOPERROR }],
                            })(
                              <Select >
                                {
                                  (localStorage.getItem('lang') === 'EN') ?
                                    uop.length > 0 && uop.map(uop_item => (
                                      <Select.Option key={uop_item.id} value={uop_item.id}>{uop_item.name}</Select.Option>
                                    ))
                                    : uop.length > 0 && uop.map(uop_item => (
                                      <Select.Option style={{textAlign:'right'}} key={uop_item.id} value={uop_item.id}>{uop_item.name_ar}</Select.Option>
                                    ))
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label= {this.lang.HALLPRICE.CREATE.LABELS.DAY}>
                            {getFieldDecorator('day', {
                              rules: [{ required: true, message: this.msg.SELECTDAYERROR }],
                            })(
                              <Select   placeholder= {this.lang.HALLPRICE.CREATE.LABELS.DAY}>
                                {
                                  (localStorage.getItem('lang') === 'EN') ?
                                    days.length > 0 && days.map(day_item => (
                                      <Select.Option key={day_item.id} value={day_item.id}>{day_item.name}</Select.Option>
                                    ))
                                    : days.length > 0 && days.map(day_item => (
                                      <Select.Option style={{textAlign:'right'}} key={day_item.id} value={day_item.id}>{day_item.name_ar}</Select.Option>
                                    ))
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                    </Spin>
              : priceList === false ?
                      //View Hall Price Screen
                      <>
                        {this.HallPriceTableHeader}
                        <Row id="clientsTable">
                          <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                            <Table 
                              columns={this.colms_price} 
                              rowKey="id" 
                              // dataSource={this.state.prices}
                              dataSource={data}
                              onChange={this.handleChangePagination}
                              pagination={pagination} 
                              style={{ marginTop: '10px' }} 
                            />
                          </Col>
                        </Row>
                      </>
                      //Update Hall Price Screen
                      :
                      <Spin tip={this.msg.HALLPRICEUPDATETIP} spinning = {this.state.uprice_spin}>
                      <Form onSubmit={this.handleSubmitHallPriceUpdate.bind(this)} style={{ marginTop: '20px' }}>
                        {this.UpdateHallPriceHeader}
                        <Row gutter={24} dir = { dir } style={{ display }}>
                          <Col span={8}>
                            <Form.Item label={this.lang.HALLPRICE.UPDATE.LABELS.PRICE}>
                              {getFieldDecorator('price1', {
                                initialValue: this.state.updateHallPrice.price,
                                rules: [{ required: true, message: this.msg.INSERTPRICEERROR }],
                              })(
                                <InputNumber placeholder={this.lang.HALLPRICE.UPDATE.LABELS.PRICE} style={{ width: '100%' }} />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label={this.lang.HALLPRICE.UPDATE.LABELS.UOM}>
                              {getFieldDecorator('uop1', {
                                initialValue: this.state.updateHallPrice.uop,
                                rules: [{ required: true, message: this.msg.SELECTUOPERROR }],
                              })(
                                <Select  >
                                  {
                                    (localStorage.getItem('lang') === 'EN') ?
                                      uop.length > 0 && uop.map(uop_item => (
                                        <Select.Option key={uop_item.id} value={uop_item.id}>{uop_item.name}</Select.Option>
                                      ))
                                      : uop.length > 0 && uop.map(uop_item => (
                                        <Select.Option style={{textAlign:'right'}} key={uop_item.id} value={uop_item.id}>{uop_item.name_ar}</Select.Option>
                                      ))
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                          <Form.Item label={this.lang.HALLPRICE.UPDATE.LABELS.DAY}>
                            {getFieldDecorator('day1', {
                              initialValue: this.state.updateHallPrice.day,
                              rules: [{ required: true, message: this.msg.SELECTDAYERROR }],
                            })(
                              <Select  >
                                {
                                  (localStorage.getItem('lang') === 'EN') ?
                                    days.length > 0 && days.map(day_item => (
                                      <Select.Option key={day_item.id} value={day_item.id}>{day_item.name}</Select.Option>
                                    ))
                                    : days.length > 0 && days.map(day_item => (
                                      <Select.Option style={{textAlign:'right'}}  key={day_item.id} value={day_item.id}>{day_item.name_ar}</Select.Option>
                                    ))
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        </Row>
                      </Form>
                    </Spin>
                }
            </Col>
        </Row>
    )
  }

}//End of Component
export default HallBookingPrice = Form.create({name: 'hall_booking_price'})(HallBookingPrice);