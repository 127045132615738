import React,{Fragment, Component} from 'react'
import API_URL from '../../constants'
import axios from 'axios';
import { message, Table,Input, DatePicker} from 'antd';
import moment from "moment";
const pt = localStorage.getItem('pt');
const completeUrl = API_URL + pt

class ClientAccountPaymentsTable extends Component{
    constructor(props){
        super(props)
        this.state = {
            data:[],
            amount:'',
            date:'',
            comment:'',
            pay_category:'',
            pay_category_ar:'',
            pay_reason:'',
            pay_reason_ar:'',
            size:5,
            sortField:"date",
            pagination: {},
            sortOrder:"ascend"
      
        }
     
        this.columns = [{
            title:(
              <span>
               {this.props.language.TABLEHEADERS.DATE}
                <br/><DatePicker style={{width:'100%'}}
                type="text"   onChange={(e) =>  this.setState({date:moment(e).format("YYYY-MM-DD")},() =>this.fetch())}/>
              </span>
            ),
            dataIndex: 'date',
            sorter: true,
              render: (text, record) => <button className="link-button" >{text}</button>,
          },
          {
            title: (
              <span>
              {this.props.language.TABLEHEADERS.AMOUNT}

                <br/><Input.Search type="text" name="amount" onChange={this.onChangeSearch}/>
              </span>
            ),
            dataIndex: 'amount',
            sorter: true,
                  render: (text, record) => <button className="link-button" >{text}</button>
      
          },
          {
            title:
            (
              <span>
              {this.props.language.TABLEHEADERS.COMMENT}
                <br/><Input.Search type="text"  name="comment" onChange={this.onChangeSearch}/>
              </span>
            ), 
            dataIndex: 'comment',
            sorter: true,
            render: (text, record) => <button className="link-button" >{text}</button>
      
          },   
          {
            title:
            (
              <span>
              {this.props.language.TABLEHEADERS.PAY_CAT}

                <br/><Input.Search type="text"  name={ localStorage.getItem("lang") == "EN" ? "pay_category" : "pay_category_ar"} onChange={this.onChangeSearch}/>
              </span>
            ), 
            dataIndex: localStorage.getItem("lang") == "EN" ? "pay_category" : "pay_category_ar",
            sorter: true,
            render: (text, record) => <button className="link-button" >{text}</button>
      
          },   
          {
            title:
            (
              <span>
              {this.props.language.TABLEHEADERS.PAY_REAS}

                <br/><Input.Search type="text"  name={ localStorage.getItem("lang") == "EN" ? "pay_reason" : "pay_reason_ar"} onChange={this.onChangeSearch}/>
              </span>
            ), 
            dataIndex: localStorage.getItem("lang") == "EN" ? "pay_reason_name" : "pay_reason_name_ar",
            sorter: true,
            render: (text, record) => <button className="link-button" >{text}</button>
      
          },   
          ];
    }
    componentDidMount() {
        this.fetch()
    }
    onChangeSearch= (e)=> {
        this.setState({[e.target.name]:e.target.value}, () => {
          this.fetch()
        })
      }
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
          pageNumber: pagination.current
        });
    
        if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
          if (this.state.pageNumber  !== pager.current) {
            this.fetch({
              page: pagination.current,
            });
          }
        } else {
          this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
          });
        }
      }
    fetch = (params={}) => {
        let page,sortOrder
        if (params.page) {
            page = params.page - 1
          }
            else{
              page = 0;
          }
          if (params.sortOrder == 'ascend') {
            sortOrder = 'ASC'
          }
          else {
            sortOrder = 'DESC'
          }
        let data = {
            client: localStorage.getItem("clientaccount"),
            amount:this.state.amount || '',
            date: this.state.date || '',
            comment:this.state.comment ||'',
            pay_category:this.state.pay_category ||'',
            pay_category_ar:this.state.pay_category_ar ||'',
            pay_reason:this.state.pay_reason ||'',
            pay_reason_ar:this.state.pay_reason_ar ||'',
            page,
            sortColumn: params.sortField || this.state.sortField ,
            sortOrder,
            size:this.state.size
        }

        axios.post(`${completeUrl}/clientpayments/get_payments`,data)
        .then(res => {
            if(res.data.success){
                const pagination = { ...this.state.pagination };
                pagination.total = res.data.totalElements;
                pagination.current = page +1;
                pagination.pageSize = this.state.size;
                // message.success(res.data.message)
                this.setState({data:res.data.res,pagination})
            }else{
                message.error(res.data.message)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
    render(){
        return(
            <Fragment>
            <Table
        style={{marginTop:'10px'}}
          columns={  this.columns}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowKey="id"
        />
            </Fragment>
        )

    }
}

export default ClientAccountPaymentsTable