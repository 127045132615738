import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, InputNumber, Collapse, Card, message, Select, DatePicker } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom';
import axios from 'axios';
import IncomeDocuments from '../IncomeDocuments/IncomeDocuments'
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const Panel = Collapse.Panel
const complete_URL = API_URL+pt;
const TextArea = Input.TextArea;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class IncomeUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      income_type: [],
      incomes: {},
      img: '',
      dir : 'ltr', 
      display: 'block'
    };

    this.lang = props.language._INCOME;
    //Extra Columns.. not being used..
    this.columns = [
      {
        title: 'From Date',
        dataIndex: 'from_date',
        render: (text, record) => <button className="link-button"
          onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      {
        title: 'To Date',
        dataIndex: 'to_date',
      },
      {
        title: ' Discount',
        dataIndex: 'discount',
      },
      {
        title: 'Actions',
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>DELETE</Button>
          </span>
        ),
        width: '30%',
        key: 'last_name',
      }
    ];

    //Extra Columns.. not being used..
    this.colms_price = [
      {
        title: 'Price',
        dataIndex: 'price',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,

      },
      {
        title: 'Unit Of Price',
        dataIndex: 'uop_name',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,

      },
      {
        title: 'Actions',
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deletePrice.bind(this, record)}>DELETE</Button>
          </span>
        ),
        width: '30%',
        key: 'last_name',
      }
    ]
  }

  //Extra method.. not being used..
  handlePriceNavigation = () => { }

  setComponentDirection=()=>{
    if (localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    const client_id = localStorage.getItem('client_id');  
    this.fetch();
    this.getIncomeTypes(client_id);
    this.setComponentDirection();
  }

  fetch = () => {
    const headers = getHeadersForHttpReq();
    let a = JSON.parse(localStorage.getItem('updateIncome')).id
    // console.log(a)
    axios.get(`${complete_URL}/incomes/by_id/${a}`,{headers})
      .then(res => {
        this.setState({ incomes: res.data.data[0] });
        // console.log(res.data.data[0])
      })
      .catch(err => { console.log('Error occured while fetching selected Income data ->',err) });
  }

  getIncomeTypes = (client_id)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/incometypes/by_client/${client_id}`,{headers})
    .then(res => { // console.log('income_types->',res)
      this.setState({ income_type: res.data.data });
    })
    .catch(err => { console.log('Issue while fetching income_types ->',err) })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (parseFloat(values.amount) > 0) {
        let a = JSON.parse(localStorage.getItem('updateIncome'))
        
        const headers = getHeadersForHttpReq();
        const user_id = localStorage.getItem('user_id');
        const client_id = localStorage.getItem('client_id');
        let is_contr = localStorage.getItem('is_contr');
        let d = moment(values.date).locale('en').format('YYYY-MM-DD');
        
        if (is_contr != 1) {
          Object.assign(values,{user_id, client_id, contractor_id : null, is_contr : 0, date : d});
        }
        else{
          const contractor_id = JSON.parse(localStorage.getItem('updateContractor')).id
          Object.assign(values,{user_id, client_id : null, contractor_id, is_contr : 1, date : d});
        }

        axios.put(`${complete_URL}/incomes/${a.id}`, values, {headers})
          .then(res => {
            if (res.data.success) {
              message.success(this.lang.MESSAGE.UPDATESUCCESS/*res.data.message*/);
              let income_route = is_contr != 1 ?  'income' : 'income_contr';
              this.props.history.push(income_route);
            } 
            else {
              message.error(this.lang.MESSAGE.UPDATEFAILURE,3/*res.data.message*/)
            }
          })
          .catch(err => {
            message.error(this.lang.MESSAGE.UPDATEFAILURE,3)
            console.log('Error occured while updating the income record->',err)
          });
        } //End of positive value
        else{
          message.error(this.props.language.INSERTPOSITIVEAMOUNTWARN,3);
        }
      }//End of no error
    });
  }//End of method

  componentWillMount() {
    let nav_route = '/dashboard/income';
    if (localStorage.getItem('is_contr') == 1){
      nav_route = '/dashboard/income_contr'
    }

    if (localStorage.getItem('lang') === 'EN') {
      this.IncomeUpdateHeader = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to = { nav_route }>
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={12}> <h1 style={{textAlign: 'center'}}>{this.lang.UPDATE.INCOMEUPDATE.LABELS.TITLE}</h1> </Col>
        <Col className="btn-box" span={6} style={{display: 'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to = { nav_route }>
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.IncomeUpdateHeader = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to = { nav_route }>
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign: 'center'}}>{this.lang.UPDATE.INCOMEUPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to = { nav_route }>
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let {dir, display, income_type} = this.state;

    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}> </Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.IncomeUpdateHeader}
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.UPDATE.INCOMEUPDATE.LABELS.AMOUNT}>
                    {getFieldDecorator('amount', {
                      initialValue: this.state.incomes.amount,
                      rules: [{ required: true, message: this.lang.MESSAGE.INSERTAMOUNTERROR }],
                    })(
                      <InputNumber min={0} style={{width:'100%'}} placeholder={this.lang.UPDATE.INCOMEUPDATE.LABELS.AMOUNT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label= {this.lang.UPDATE.INCOMEUPDATE.LABELS.BILLNO}>
                    {
                        getFieldDecorator('bill', {
                          initialValue: this.state.incomes.BILL_NO,
                          rules: [{ required: true, message: this.lang.MESSAGE.INSERTBILLNOERROR }],
                        })(
                        <Input type="text" placeholder={this.lang.UPDATE.INCOMEUPDATE.LABELS.BILLNO}/>
                        )
                    }
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.UPDATE.INCOMEUPDATE.LABELS.INCOME}>
                  { getFieldDecorator('income', {
                    initialValue: this.state.incomes.new_income_type_id,
                    rules: [{ required: true, message: this.lang.MESSAGE.SELECTINCOMEERROR }],
                    })(
                      <Select onChange={this.selectIncome} placeholder={this.lang.UPDATE.INCOMEUPDATE.LABELS.INCOME}>
                        {
                          localStorage.getItem('lang') ==='EN' ?
                            income_type.length > 0 && income_type.map(item => (
                              <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))
                            :
                            income_type.length > 0 && income_type.map(item => (
                              <Select.Option style={{textAlign: 'right'}} key={item.id} value={item.id}>{item.name_ar}</Select.Option>
                            ))

                        }
                      </Select>)
                  }
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.UPDATE.INCOMEUPDATE.LABELS.DATE}                             >
                    {getFieldDecorator('date', {
                      initialValue:moment(this.state.incomes.date),
                      rules: [{ required: true, message: this.lang.MESSAGE.INSERTDATEERROR }],
                    })( 
                      <DatePicker style={{width:'100%'}} />
                      )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={24}>
                  <Form.Item label={this.lang.UPDATE.INCOMEUPDATE.LABELS.NOTE}>
                    {getFieldDecorator('note', {
                      initialValue: this.state.incomes.note,
                      rules: [{ required: false, message: this.lang.MESSAGE.INSERTNOTEERROR }],
                    })(
                      <TextArea placeholder={this.lang.UPDATE.INCOMEUPDATE.LABELS.NOTE}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Collapse accordion bordered={false}
              expandIconPosition={dir === 'ltr' ? 'left':'right'}
              expandIcon={({ isActive }) =>
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
                <Panel header={this.lang.UPDATE.INCOMEDOCUMENT.TITLE} key="3" >
                  <IncomeDocuments income_id={this.state.incomes.id} {...this.props} />
                </Panel>
            </Collapse>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default IncomeUpdate = Form.create({ name: 'income_update_form' })(IncomeUpdate);