import React, { Component } from "react";
import {
  DatePicker,
  Divider,
  Row,
  Switch,
  Col,
  Input,
  Form,
  Icon,
  Button,
  Card,
  Table,
  message,
  Select,
  TimePicker,
  InputNumber,
  Modal,
  Collapse,
  Spin,
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";
import HallPayments from "../HallPayments/HallPayments";
import HallPackages from "../HallPackages";
import ReservationDocuments from "../ReservationDocuments/ReservationDocuments";
import HallServices from "../HallServices/HallServices";
import CreateCustomer from "../ReservationAdd/CreateShotCustomer";
import API_URL from "../../constants";
import getHeadersForHttpReq from "../../constants/token";
import getHeadersForPDFreport from "../../constants/reportHeaders";

require("./style.css");
const confirm = Modal.confirm;
const Panel = Collapse.Panel;
const pt = localStorage.getItem("pt");
const complete_URL = API_URL + pt;
if (localStorage.getItem("lang") === "AR") {
  require("../css/global-css.css");
}

class ReservationUpadte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banquets: [],
      cities: [],
      gender: [],
      halls: [],
      customers: [],
      tdata: [],
      contractors: [],
      startValue: null,
      endValue: null,
      endOpen: false,
      reservationByOwner: 0,
      timeStartOpen: false,
      timeEtartOpen: false,
      timeEndOpen: false,
      event_types: [],
      currentDate: null,
      reservation: {},
      hallPrice: [],
      res_amounts: {},
      logo_url: "",
      discountOptions: [
        { id: 0, name: "Percentage", name_ar: "بنسبة مئوية" },
        { id: 1, name: "Amount", name_ar: "حسب الكمية" },
      ],
      discountOption: 0,
      parent_resrv_Counter: 0,
      parent_hall_pmt_Counter: 0,
      parent_hall_srv_Counter: 0,
      parent_hall_pkg_Counter: 0,
      parent_hall_doc_Counter: 0,
      spin: true,
      spin_msg: "",
      reportData: {},
      show_Payment_Modal: false,
      show_Cancel_Notes_Modal: false,
      isCancelling: false,
      isPackagesAdded: false,
      pkg_percent: 0,
      hall_percent: 0,
      directCancel: false,
      showCancellationNotesModal: false,
      cancel_changeStatus_margin: { margin: "0 auto" },
      cust_comb_phone: "",
      dir: "ltr",
      display: "block",
    };
    this.lang = props.language._RESERVATION.UPDATE.RESERVATION;
    this.lang2 = props.language._RESERVATION.UPDATE.PAYMENTS.HALLPAYMENT;
    this.msg = props.language._RESERVATION.MESSAGE;
    this.cust_msg = props.language._CUSTOMER.MESSAGE;
    // eslint-disable-next-line

    this.total_payment_columns = [
      localStorage.getItem("lang") === "EN"
        ? {
            title: `${this.lang2.SETUP.LABELS.CONTRACTORNAME}`,
            dataIndex: "contractor_id",
            render: (text, record) => (
              <p>
                {text == "00000000-0000-00"
                  ? `${this.lang2.SETUP.LABELS.HALLRENT}`
                  : this.state.contractors.length > 0 &&
                    this.state.contractors.filter((i) => i.id == text).length >
                      0 &&
                    this.state.contractors.length > 0 &&
                    this.state.contractors.filter((i) => i.id == text).length >
                      0 &&
                    this.state.contractors.filter((i) => i.id == text)[0].name}
              </p>
            ),
          }
        : {
            title: `${this.lang2.SETUP.LABELS.CONTRACTORNAME}`,
            dataIndex: "contractor_id",
            align: "right",
            render: (text, record) => (
              <p>
                {text == "00000000-0000-00"
                  ? `${this.lang2.SETUP.LABELS.HALLRENT}`
                  : this.state.contractors.length > 0 &&
                    this.state.contractors.filter((i) => i.id == text).length >
                      0 &&
                    this.state.contractors.length > 0 &&
                    this.state.contractors.filter((i) => i.id == text).length >
                      0 &&
                    this.state.contractors.filter((i) => i.id == text)[0]
                      .name_ar}
              </p>
            ),
          },
      localStorage.getItem("lang") === "EN"
        ? {
            title: `${this.lang2.SETUP.LABELS.PAYMENTTYPE}`,
            dataIndex: "payment_type",
            render: (text, record) => <p>{text}</p>,
          }
        : {
            title: `${this.lang2.SETUP.LABELS.PAYMENTTYPE}`,
            dataIndex: "payment_type_ar",
            align: "right",
            render: (text, record) => <p>{text}</p>,
          },
      localStorage.getItem("lang") === "EN"
        ? {
            title: `${this.lang2.SETUP.LABELS.PAMOUNT}`,
            dataIndex: "amount",
            render: (text, record) => <p>{text}</p>,
          }
        : {
            title: `${this.lang2.SETUP.LABELS.PAMOUNT}`,
            dataIndex: "amount",
            align: "right",
            render: (text, record) => <p>{text}</p>,
          },
      localStorage.getItem("lang") === "EN"
        ? {
            title: `${this.lang2.SETUP.LABELS.FINEAMOUNT}`,
            dataIndex: "penality",
            width: "12%",
            render: (text, record) => (
              <InputNumber
                value={text}
                min={0}
                max={parseFloat(record.amount)}
                style={{ width: "90px" }}
                onChange={(e) => this.changePenality(e, record)}
              />
            ),
          }
        : {
            title: `${this.lang2.SETUP.LABELS.FINEAMOUNT}`,
            dataIndex: "penality",
            width: "12%",
            align: "right",
            render: (text, record) => (
              <InputNumber
                value={text}
                min={0}
                max={parseFloat(record.amount)}
                style={{ width: "90px" }}
                onChange={(e) => this.changePenality(e, record)}
              />
            ),
          },
      localStorage.getItem("lang") === "EN"
        ? {
            title: `${this.lang2.SETUP.LABELS.TAMOUNT}`,
            dataIndex: "total_amount",
            render: (text, record) => <p>{text}</p>,
          }
        : {
            title: `${this.lang2.SETUP.LABELS.TAMOUNT}`,
            dataIndex: "total_amount",
            align: "right",
            render: (text, record) => <p>{text}</p>,
          },
    ]; // The Logic of printing the Contractor name/ [Hall type or Contract Type] is messy. Should be changed
  }

  setComponentDirection = () => {
    if (localStorage.getItem("lang") === "EN") {
      this.setState({ dir: "ltr", display: "block" });
    } else {
      this.setState({ dir: "rtl", display: "flex" });
    }
  };

  changePenality = (val, payment_rec) => {
    let newPaymentData = [];
    Object.assign(newPaymentData, this.state.tdata);
    let index = newPaymentData.findIndex((item) => item.id === payment_rec.id);
    Object.assign(newPaymentData[index], { penality: val });
    this.setState(
      { tdata: newPaymentData } /*,()=>{
      console.log('index->',index, 'tdata->',this.state.tdata);
    }*/
    );
    // console.log('value : ',val,' payment_rec:',payment_rec);
  };

  updateChildCounterByParent = (key, counterValue) => {
    this.setState({ [key]: counterValue });
  };

  disabledStartDate = (startValue) => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  handleOk = (values) => {
    values.user_id = localStorage.getItem("user_id");
    values.client_id = localStorage.getItem("client_id");
    values.birthdate = "1985-01-01";
    values.email = "";
    values.ph_alt_num = "";
    const headers = getHeadersForHttpReq();

    axios
      .post(`${complete_URL}/customers/add`, values, { headers })
      .then((res) => {
        if (res.data.success) {
          message.success(this.cust_msg.ADDSUCCESS);
          this.getCustomers();
          this.setState({ createCustomerOpen: false });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        message.error(this.cust_msg.ADDFAILURE, 2);
        console.log("Error occured while creating new Customer->", err);
      });
  };

  handleCancel = () => {
    this.setState({ createCustomerOpen: false });
  };

  selectHall = (v) => {
    const headers = getHeadersForHttpReq();

    axios
      .get(`${complete_URL}/hallprices/reservation/${v}`, { headers })
      .then((result) => {
        // console.log(result.data.data[0])
        this.setState({ hallPrice: result.data.data[0] });
      })
      .catch((err) => {
        console.log(
          "Error occured while fetching available Hall Price ->",
          err
        );
      });
  };

  onChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    // console.log(open)
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open, timeStartOpen: true });
  };

  async componentDidMount() {
    // let headers = getHeadersForHttpReq();
    let userid = localStorage.getItem("user_id");
    let client_id = localStorage.getItem("client_id");
    let date = new Date();
    this.fetch();
    let gender = this.getGenders();
    let event_types = this.getEventTypes();
    let banquets = this.getBanquets(client_id); //console.log('banquets->',banquets)
    let customers = this.getCustomers(client_id);
    let contractors = this.getAssignedContByClient(); //this.getContractors();
    this.getIsOwnerBooleanCheck(userid); //console.log('didmount reservationByOwner->',reservationByOwner)
    // let {reservationByOwner} =await this.getIsOwnerBooleanCheck(userid); //console.log('didmount reservationByOwner->',reservationByOwner)

    /*axios.get(`${complete_URL}/reservations/get_total_and_received_amount_by_res_id/${localStorage.getItem('reservationUpdate').substring(0, 16)}`,{headers})
    .then(res => {*/ // console.log('hhhh',res.data.data[0])
    this.setState({
      /*reservationByOwner : reservationByOwner,*/ banquets,
      customers,
      event_types,
      /*res_amounts : res.data.data[0],*/ gender,
      contractors,
      currentDate: moment(date),
      spin: false,
    });
    /*})
    .catch(res_err=>{
      console.log('Error in receiving total and Received amount->',res_err)
    });*/

    if (localStorage.getItem("lang") === "EN") {
      this.CustomRow = (
        <Row className="top-header">
          <Col span={3}>
            <Link to="/dashboard/reservations">
              <Button type="danger">
                <Icon type="arrow-left" />
              </Button>
            </Link>
          </Col>
          <Col span={3}></Col>
          <Col span={7}>
            <h1>{this.lang.HALLUPDATE.LABELS.TITLE}</h1>
          </Col>
          <Col span={2}></Col>
          <Col span={6}>
            <Form.Item>
              {this.state.reservation.status === "pending" ? (
                <React.Fragment>
                  <Row gutter={24} className="custom-box">
                    <Col span={12} className="btn-box">
                      <Button
                        type="primary"
                        htmlType="submit"
                        title="save"
                        className="save"
                      >
                        <Icon type="save" theme="filled" />
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        type="primary"
                        className="confirmBtn"
                        onClick={this.handleSubmitReserved}
                        title="confirm"
                      >
                        {this.lang.HALLUPDATE.LABELS.CONFIRM}
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              ) : this.state.reservation.status === "reserved" ? (
                <div>
                  {this.state.reservationByOwner == 1 && (
                    <Button
                      type="danger"
                      block
                      style={{ color: "white", backgroundColor: "#fb3333" }}
                      onClick={this.cancelReservationUpdated}
                    >
                      {this.lang.HALLUPDATE.LABELS.CANCEL}
                    </Button>
                  )}
                  {this.state.reservationByOwner == 1 && (
                    <Button
                      type="danger"
                      block
                      onClick={this.statusChangeToPending}
                    >
                      {this.lang.HALLUPDATE.LABELS.CHANGESTATUS}
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )}
            </Form.Item>
          </Col>
        </Row>
      );
    } else {
      this.CustomRow = (
        <Row className="top-header">
          <Col span={2}></Col>
          <Col span={3}>
            <Link to="/dashboard/reservations">
              <Button type="danger">
                <Icon type="arrow-left" />
              </Button>
            </Link>
          </Col>
          <Col span={6}>
            <Form.Item>
              {this.state.reservation.status === "pending" ? (
                <React.Fragment>
                  <Row gutter={24} className="custom-box">
                    <Col span={12} className="btn-box">
                      <Button
                        type="primary"
                        htmlType="submit"
                        title="save"
                        className="save"
                      >
                        <Icon type="save" theme="filled" />
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        type="primary"
                        className="confirmBtn"
                        onClick={this.handleSubmitReserved}
                        title="confirm"
                      >
                        {this.lang.HALLUPDATE.LABELS.CONFIRM}
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              ) : this.state.reservation.status === "reserved" ? (
                <div>
                  {this.state.reservationByOwner == 1 && (
                    <Button
                      type="danger"
                      block
                      style={{ color: "white", backgroundColor: "#fb3333" }}
                      onClick={this.cancelReservationUpdated}
                    >
                      {this.lang.HALLUPDATE.LABELS.CANCEL}
                    </Button>
                  )}
                  {this.state.reservationByOwner == 1 && (
                    <Button
                      type="danger"
                      block
                      onClick={this.statusChangeToPending}
                    >
                      {this.lang.HALLUPDATE.LABELS.CHANGESTATUS}
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )}
            </Form.Item>
          </Col>
          <Col span={3}></Col>
          <Col span={7}>
            <h1>{this.lang.HALLUPDATE.LABELS.TITLE}</h1>
          </Col>
        </Row>
      );
    }
    this.setComponentDirection();
  }

  // getHallUOPs =()=>{
  //   let headers = getHeadersForHttpReq();
  //   axios.get(`${complete_URL}/halls/by_banquet/${this.state.reservation.banquet}`, {headers})
  //   .then(res => { // console.log('HallUOPs in update reservation scren->',res)

  //   })
  //   .catch(err => { console.log('Error occured while fetching HallUOPs->',err) });
  // }

  getReservationPaymentInfo = () => {
    const headers = getHeadersForHttpReq();
    const resrv_id = localStorage.getItem("reservationUpdate").substring(0, 16);
    if (resrv_id) {
      axios
        .get(
          `${complete_URL}/reservations/get_total_and_received_amount_by_res_id/${resrv_id}`,
          { headers }
        )
        .then((res) => {
          // console.log('ReservationPaymentInfo in update reservation scren->',res)
          this.setState({ res_amounts: res.data.data[0] });
        })
        .catch((err) => {
          console.log(
            "Error occured while fetching ReservationPaymentInfo ->",
            err
          );
        });
    }
  };

  getAllPayments = () => {
    const headers = getHeadersForHttpReq();
    const res_id = this.state.reservation.id;
    if (res_id) {
      axios
        .get(`${complete_URL}/hallpayments/by_resrv_id/${res_id}`, { headers })
        .then((res) => {
          //console.log('payment_data->',payment_data)
          let payment_data = res.data.data;
          this.setState({ tdata: payment_data });
        })
        .catch((err) => {
          console.log("Error occured while fetching Hall payment_data->", err);
        });
    } else {
      message.error(this.msg.RESRVNOTFOUND, 2);
    }
  };
  //Old method~ Before Merge feature
  getContractors = () => {
    const headers = getHeadersForHttpReq();
    let contractors = [];
    axios
      .get(`${complete_URL}/contractors/a/cont_all/`, { headers })
      .then((res) => {
        // console.log('contractors->',res.data.data)
        // this.setState({ contractors: res.data.data })
        Object.assign(contractors, res.data.data);
      })
      .catch((err) => {
        console.log("Error occured while fetching old contractors ->", err);
      });
    return contractors;
  };

  getAssignedContByClient = () => {
    const headers = getHeadersForHttpReq();
    const client_id = localStorage.getItem("client_id");
    let contractors = [];
    axios
      .get(`${complete_URL}/contractors/independent/${client_id}`, { headers })
      .then((res) => {
        // console.log('ind contractors->',res.data.data);
        // this.setState({ contractors: res.data.data })
        Object.assign(contractors, res.data.data);
      })
      .catch((err) => {
        console.log(
          "Error occured while fetching independent contractors ->",
          err
        );
      });
    return contractors;
  };

  getGenders = () => {
    const headers = getHeadersForHttpReq();
    let gender = [];
    axios
      .get(`${complete_URL}/parameters/get_gender`, { headers })
      .then((res) => {
        // this.setState({ gender: res.data.data[0] })
        Object.assign(gender, res.data.data[0]);
      })
      .catch((err) => {
        console.log("Error occured while fetching stored Genders data ->", err);
      });
    return gender;
  };

  getCustomers = (client_id) => {
    const headers = getHeadersForHttpReq();
    let customers = [];
    axios
      .get(`${complete_URL}/customers/${client_id}`, { headers })
      .then((res) => {
        Object.assign(customers, res.data.data);
      })
      .catch((err) => {
        console.log("Error occured while fetching stored customers ->", err);
      });
    return customers;
  };

  getCompleteReportData = () => {
    let customer = this.state.customers.filter(
      (c) => c.id == this.state.reservation.customer
    );
    let temp = this.state.reportData;
    Object.assign(temp, {
      customer_name: customer[0].name,
      customer_name_ar: customer[0].name_ar,
    });
    return temp;
  };

  selectBanquet = (v) => {
    const headers = getHeadersForHttpReq();
    axios
      .get(`${complete_URL}/halls/by_banquet/${v}`, { headers })
      .then((res) => {
        // console.log(res.data.data[0])
        this.setState({ halls: res.data.data[0] });
      })
      .catch((err) => {
        console.log(
          "Error occured while fetching Halls of selected Banquet->",
          err
        );
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const headers = getHeadersForHttpReq();

        if (
          this.state.reservation.total_rent -
            this.state.res_amounts.received_rent <
          values.total_discounted_amount
        ) {
          // message.error('Discount Amount Exceeds!');
          message.error(this.msg.RESRVDISCOUNTEXCEEDS, 3);
          return false;
        }
        values.status = "pending";
        values.reservationByOwner = this.state.reservationByOwner;
        values.from_date = moment(values.from_date).format("YYYY-MM-DD");
        values.to_date = moment(values.to_date).format("YYYY-MM-DD");
        values.reservation_date = moment(values.reservation_date).format(
          "YYYY-MM-DD"
        );
        values.from_time = moment(values.from_time).format("HH:mm:ss");
        values.client_id = localStorage.getItem("client_id");
        values.to_time = moment(values.to_time).format("HH:mm:ss");
        values.id = this.state.reservation.id;
        values.total_discounted_amount = parseFloat(
          values.total_discounted_amount
        );
        values.user_id = localStorage.getItem("user_id");

        axios
          .put(
            `${complete_URL}/reservations/${this.state.reservation.id}`,
            values,
            { headers }
          )
          .then((res) => {
            // console.log('save btn ajax then res ->',res);
            if (res.data.success === true) {
              this.fetch();
              message.success(
                this.msg.RESRVUPDATESUCCESS,
                2 /*res.data.message*/
              );
            } else if (res.data.success === "confirm") {
              confirm({
                title: this.msg.RESRVEXISTSPENDING, //res.data.message,
                onOk() {
                  values.forceWrite = true;
                  axios
                    .post(`${complete_URL}/reservations/add`, values, {
                      headers,
                    })
                    .then((resu) => {
                      if (resu.data.success === true) {
                        this.fetch();
                        message.success(this.msg.RESRVADDSUCCESS);
                        // message.success(resu.data.message)
                      } else {
                        message.error(
                          this.msg.ADDFAILURE,
                          2 /*resu.data.message*/
                        );
                      }
                    });
                },
                onCancel() {
                  // console.log('Cancel button pressed');
                },
              });
            } else {
              //To be checked...
              message.error(res.data.message);
              // console.log(res)
            }
          })
          .catch((err) => {
            message.error(this.msg.ADDFAILURE, 3);
            console.log("Error occured while creating the Reservation->", err);
          });
      }
    });
  };

  handleSubmitReserved = () => {
    const headers = getHeadersForHttpReq();

    let user_id = localStorage.getItem("user_id");
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.status = "reserved";
        values.id = this.state.reservation.id;
        values.client_id = localStorage.getItem("client_id");
        values.user_id = user_id;
        if (localStorage.getItem("lang") === "EN") values.lang = "EN";
        else values.lang = "AR";
        // console.log(this.props.form.validateFields)
        axios
          .put(
            `${complete_URL}/reservations/${this.state.reservation.id}`,
            values,
            { headers }
          )
          .then((res) => {
            console.log("Success part reserv update res->", res);
            if (res.data.success === true) {
              if (
                res.data.message === "Successfully status changed to reserved"
              ) {
                message.success(this.msg.RESRVSTATUSRSUCCESS, 2);
              }
              this.fetch();
              // message.success(res.data.message)
            } else if (res.data.success === "confirm") {
              confirm({
                title: this.msg.RESRVEXISTSPENDING, //res.data.message,
                onOk() {
                  values.forceWrite = true;
                  axios
                    .post(`${complete_URL}/reservations/add`, values, {
                      headers,
                    })
                    .then((resu) => {
                      if (resu.data.success === true) {
                        this.fetch();
                        message.success(
                          this.msg.RESRVADDSUCCESS,
                          2 /*resu.data.message*/
                        );
                      } else {
                        message.error(
                          this.msg.ADDFAILURE,
                          2 /*resu.data.message*/
                        );
                      }
                    });
                },
                onCancel() {
                  console.log("Cancel");
                },
              });
            } else if (
              res.data.message === "Already slot is reserved for this Hall"
            ) {
              message.error(this.msg.RESRVSLOTOCCUPIEDERROR); // console.log(res)
            } else if (
              res.data.message === "Please pay full security deposite"
            ) {
              message.error(this.msg.RESRVSECPAYERROR, 2);
            } else if (
              res.data.message ===
              "System failed to update the status to reserved!"
            ) {
              message.error(this.msg.RESRVSTATUSRFAILURE, 2);
            }
          })
          .catch((err) => {
            console.log("Error occured while updating the reservation", err);
            message.error(this.msg.RESRVUPDATEFAILURE, 3);
          });
      }
    });
  };

  cancelReservation = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const headers = getHeadersForHttpReq();
        let client_id = localStorage.getItem("client_id");
        let user_id = localStorage.getItem("user_id");
        if (client_id && user_id) {
          Object.assign(values, {
            client_id: client_id,
            user_id: user_id,
            id: this.state.reservation.id,
            status: "canceled",
            lang: localStorage.getItem("lang"),
            from_date: moment(values.from_date).format("YYYY-MM-DD"),
            to_date: moment(values.to_date).format("YYYY-MM-DD"),
            reservation_date: moment(values.reservation_date).format(
              "YYYY-MM-DD"
            ),
            from_time: moment(values.from_time).format("HH:mm:ss"),
            to_time: moment(values.to_time).format("HH:mm:ss"),
          });
          // values.status = 'canceled'
          // values.lang = localStorage.getItem("lang")
          // values.from_date = moment(values.from_date).format('YYYY-MM-DD')
          // values.to_date = moment(values.to_date).format('YYYY-MM-DD')
          // values.reservation_date = moment(values.reservation_date).format('YYYY-MM-DD')
          // values.from_time = moment(values.from_time).format('HH:mm:ss')
          // values.client_id = localStorage.getItem('client_id')
          // values.to_time = moment(values.to_time).format('HH:mm:ss')
          // values.id = this.state.reservation.id
          // values.user_id = localStorage.getItem('user_id')

          axios
            .put(
              `${complete_URL}/reservations/${this.state.reservation.id}`,
              values,
              { headers }
            )
            .then((res) => {
              // console.log(res)
              if (res.data.success === true) {
                this.fetch();
                message.success(res.data.message);
              } else {
                message.error(res.data.message);
                // console.log(res)
              }
            })
            .catch((err) => {
              message.error("Failed to canceled reservation");
              console.log(err);
            });
        } else {
          message.error(this.msg.LOGINERROR, 2);
        }
      }
    });
  };

  showConfirm = () => {
    confirm({
      title: `${this.lang.HALLUPDATE.CANCELRESERVATION.CONFIRMCANCELMESSAGE}`,
      onOk() {
        console.log("showConfirm..OK called!");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  cancelReservationUpdated = () => {
    // console.log('cancelReservationUpdated called->');
    let client_id = localStorage.getItem("client_id");
    let user_id = localStorage.getItem("user_id");
    if (client_id && user_id) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          values.status = "canceled";
          values.lang = localStorage.getItem("lang");
          values.client_id = localStorage.getItem("client_id");
          values.resrv_id = this.state.reservation.id;
          values.user_id = localStorage.getItem("user_id");

          let directCancel = true;
          if (this.state.tdata) {
            for (let i = 0; i < this.state.tdata.length; i++) {
              if (this.state.tdata[i].amount > 0) {
                directCancel = false;
              }
              if (
                this.state.tdata[i].amount > 0 &&
                this.state.tdata[i].payment_type === "contractor"
              ) {
                this.setState({ isPackagesAdded: true });
              }
            } //End of Lopp to check if payment for hall rent or any package(s)
            if (directCancel) {
              //directCancel
              Object.assign(values, { directCancel: true });
              this.setState({
                directCancel: true,
                customer: values.customer,
                show_Cancel_Notes_Modal: true,
              });
            } else {
              this.setState(
                {
                  directCancel: false,
                  customer: values.customer,
                  show_Payment_Modal: true,
                } /*,()=>{
              console.log('Payment data..this.state.tdata->',this.state.tdata);
            }*/
              );
            }
          } else {
            message.error(this.msg.RESRVPMTNOTFOUND, 3);
          }
        } else {
          console.log("Err in cancellation process->", err);
        }
      });
    } else {
      message.error(this.msg.LOGINERROR, 2);
    }
  };
  //old HTTP service served by old API
  directCancelReservationService = (data) => {
    let c_notes = this.refs.cancellation_notes_Ref.state.value
      ? this.refs.cancellation_notes_Ref.state.value
      : ".";
    Object.assign(data, { cancellation_notes: c_notes });
    const headers = getHeadersForHttpReq();

    axios
      .post(`${complete_URL}/reservations/cancel_paid_resrv`, data, { headers })
      .then((res) => {
        // console.log(res)
        if (res.data.success === true) {
          this.fetch();
          message.success(this.msg.RESRVSTATUSCSUCCESS /*res.data.message*/);
        } else if (
          res.data.message ===
          "System is Unable to send cancellation notification report to the Customer"
        ) {
          message.success(this.msg.RESRVSTATUSCSUCCESS, 2 /*res.data.message*/);
          message.error(this.msg.SENDNOTFFAILURE, 2);
        }
      })
      .catch((err) => {
        //  message.error('Failed to canceled reservation')
        if (err.data.message === "System failed to cancel the Reservation!") {
          message.error(this.msg.RESRVSTATUSCFAILURE, 2);
        } else if (
          err.data.message === "Error found while deducting paid amount!"
        ) {
          message.error(this.msg.RESERVCANCELAMOUNTDEDUCTERROR, 2);
          message.error(this.msg.RESRVSTATUSCFAILURE, 2);
        } else {
          message.error(this.msg.RESRVSTATUSCFAILURE, 2);
        }
        console.log("Error occured while Canceling the reservation->", err);
      });
  };

  cancelPaidReservationService = () => {
    /*if (this.state.tdata.length > 0) {*/
    this.setState({ isCancelling: true });
    let c_notes = this.refs.cancellation_notes_Ref.state.value
      ? this.refs.cancellation_notes_Ref.state.value
      : ".";
    let data = {
      directCancel: this.state.directCancel,
      payment_data: this.state.tdata,
      resrv_id: this.state.reservation.id,
      status: "canceled",
      cancellation_notes: c_notes,
      lang: localStorage.getItem("lang"),
      user_id: localStorage.getItem("user_id"),
      client_id: localStorage.getItem("client_id"),
      customer: this.state.customer,
    };
    const headers = getHeadersForHttpReq();

    axios
      .post(`${complete_URL}/reservations/cancel_paid_resrv`, data, { headers })
      .then((res) => {
        // console.log(res)
        if (res.data.success === true) {
          this.setState(
            {
              isCancelling: false,
              show_Payment_Modal: false,
              show_Cancel_Notes_Modal: false,
            },
            () => {
              this.fetch();
              // message.success(res.data.message);
              message.success(
                this.msg.RESRVSTATUSCSUCCESS /*res.data.message*/
              );
            }
          );
        } else if (
          res.data.message ===
          "System is Unable to send cancellation notification report to the Customer"
        ) {
          this.setState({ isCancelling: false });
          message.success(this.msg.RESRVSTATUSCSUCCESS, 2 /*res.data.message*/);
          message.error(this.msg.SENDNOTFFAILURE, 2);
        } else {
          this.setState({ isCancelling: false });
          message.error(res.data.message);
        }
        // console.log('cancelPaidReservationService..res.data->',res.data);
      })
      .catch((err) => {
        this.setState({ isCancelling: false });
        // message.error('Failed to cancel reservation')
        // console.log(err)
        if (err.data.message === "System failed to cancel the Reservation!") {
          message.error(this.msg.RESRVSTATUSCFAILURE, 2);
        } else if (
          err.data.message === "Error found while deducting paid amount!"
        ) {
          message.error(this.msg.RESERVCANCELAMOUNTDEDUCTERROR, 2);
          message.error(this.msg.RESRVSTATUSCFAILURE, 2);
        } else {
          message.error(this.msg.RESRVSTATUSCFAILURE, 2);
        }
        console.log("Error occured while Canceling the reservation->", err);
      });

    /*}
    else {
      this.setState({isCancelling :false}); 
      message.error(this.lang.HALLUPDATE.CANCELRESERVATION.UNABLECANCELRESERVATION,3);}*/
  };

  closePaymentDeductionModal = () => {
    this.setState({ show_Payment_Modal: false });
  };

  closecCancelNotesModal = () => {
    this.setState({
      show_Cancel_Notes_Modal: false,
      showCancellationNotesModal: false,
    });
  };

  onHallDedPercentChange = (e) => {
    this.setState(
      { hall_percent: e } /*,()=>{
      console.log('this.state.hall_percent->',this.state.hall_percent)
    }*/
    );
  };

  onPackageDedPercentChange = (e) => {
    this.setState(
      { pkg_percent: e } /*,()=>{
      console.log('this.state.pkg_percent->',this.state.pkg_percent)
    }*/
    );
  };

  fetch = () => {
    if (localStorage.getItem("reservationUpdate")) {
      const headers = getHeadersForHttpReq();
      const resrv_id = localStorage
        .getItem("reservationUpdate")
        .substring(0, 16);

      // axios.get(`${complete_URL}/reservations/by_id/${resrv_id}`, {headers})
      axios
        .get(`${complete_URL}/reservations/by_id_upd/${resrv_id}`, { headers })
        .then((res) => {
          if (res.data.success) {
            // console.log('reservation obj->',res.data.data[0]);
            let cust_comb_phone =
              res.data.data[0].ph_num +
              " | " +
              (res.data.data[0].ph_alt_num
                ? res.data.data[0].ph_alt_num
                : "--");
            this.setState(
              { reservation: res.data.data[0], cust_comb_phone },
              () => {
                axios
                  .get(
                    `${complete_URL}/halls/by_banquet/${this.state.reservation.banquet}`,
                    { headers }
                  )
                  .then((res) => {
                    // console.log(res)
                    this.setState({ halls: res.data.data[0] }, () => {
                      this.getAllPayments();
                      this.getReservationPaymentInfo();
                      // Need to call everytime onChange because HallPrices depend on Hall.
                      axios
                        .get(
                          `${complete_URL}/hallprices/reservation/${this.state.reservation.hall}`,
                          { headers }
                        )
                        .then((result) => {
                          // console.log('HallUOPs in update reservation scren->', result.data.data[0])
                          let new_reportData = {
                            reserv_no: this.state.reservation.no,
                            resrv_date: this.state.reservation.reservation_date,
                          };
                          let temp = this.state.reportData;
                          if (
                            new_reportData.reserv_no &&
                            new_reportData.resrv_date /*res.data.data[0]*/
                          ) {
                            Object.assign(temp, new_reportData);
                          }
                          let showCancellationNotesModal = false;
                          let margin = { margin: "0 auto" }; //
                          if (this.state.reservation.status === "canceled") {
                            showCancellationNotesModal = true;
                          }
                          if (this.state.reservation.status === "reserved") {
                            margin = { margin: "-3px auto" };
                          }
                          this.setState({
                            hallPrice: result.data.data[0],
                            reportData: temp,
                            showCancellationNotesModal:
                              showCancellationNotesModal,
                            cancel_changeStatus_margin: margin,
                          });
                        })
                        .catch((err) => {
                          console.log(
                            "Error occured while fetching HallUOPs->",
                            err
                          );
                        });
                    });
                  })
                  .catch((err) => {
                    console.log(
                      "Error occured while fetching Hall Prices ->",
                      err
                    );
                  });
              }
            );
            // console.log(res.data)
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(
            "Error occured while fetching Reservation data Object ->",
            err
          );
        });
    }
  };

  getIsOwnerBooleanCheck = (userid) => {
    // let reservationByOwner = {};
    const headers = getHeadersForHttpReq();
    axios
      .get(`${complete_URL}/users/${userid}`, { headers })
      .then((res) => {
        // console.log('from API call ..reservationByOwner->',res.data.is_owner);
        this.setState({ reservationByOwner: res.data.is_owner });
        // Object.assign(reservationByOwner , {reservationByOwner : res.data.is_owner});
      })
      .catch((err) => {
        console.log("Error occured while fetching User type data ->", err);
      });
    // return reservationByOwner;
  };

  getEventTypes = () => {
    const headers = getHeadersForHttpReq();
    let event_types = [];
    axios
      .get(`${complete_URL}/parameters/event_type`, { headers })
      .then((res) => {
        // this.setState({ event_types: res.data.data[0] }/*, () => { console.log(this.state.event_types) }*/)
        Object.assign(event_types, res.data.data[0]);
      })
      .catch((err) => {
        console.log("Error occured while fetching Event Types data->", err);
      });
    return event_types;
  };

  getBanquets = (client_id) => {
    const headers = getHeadersForHttpReq();
    let banquets = [];
    axios
      .get(`${complete_URL}/banquets/by_clients/${client_id}`, { headers })
      .then((res) => {
        // console.log('banquets recv from api..',res.data.data[0])
        Object.assign(banquets, res.data.data[0]);
      })
      .catch((err) => {
        console.log("Error occured while fetching Banquets ->", err);
      });
    return banquets;
  };

  downloadReservationReport = () => {
    this.setState({
      spin: true,
      spin_msg: this.lang.HALLUPDATE.LABELS.GENERATE_REPORT,
    });
    let opt = "en";
    let resrv_id = localStorage.getItem("reservationUpdate");
    if (resrv_id) {
      if (localStorage.getItem("lang") == "AR") {
        opt = "-ar";
      }
      let client_id = localStorage.getItem("client_id");
      let api_main_part = API_URL.split("/")[2];
      let req_data = {
        api_main_part: api_main_part,
        port: pt,
        client_id: client_id,
        opt: opt,
        resrv_id: resrv_id.substring(0, 16),
        show_rules: true,
      };
      axios
        .get(`${complete_URL}/reservation_reports/export_reserv/`, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/pdf",
            Authorization:
              localStorage.getItem("tokenType") +
              " " +
              localStorage.getItem("token"),
          },
          params: { data: JSON.stringify(req_data) },
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/pdf",
            Authorization:
              localStorage.getItem("tokenType") +
              " " +
              localStorage.getItem("token"),
          }); //make available for download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          let version = opt == "en" ? "_Eng" : "_Arb";
          link.download =
            "Reservation_report_" +
            this.state.reservation.no +
            version +
            ".pdf";
          link.click();
          this.setState({ spin: false });
        })
        .catch((err) => {
          console.log("Error occured while fetching the report buffer ->", err);
          this.setState({ spin: false });
        });
    } //End of if reservationID
  };

  handleDiscountTypeSelection = (val) => {
    this.setState({ discountOption: val });
  };

  onChangeDiscountPercentage = (e) => {
    let t_rent = parseInt(this.state.reservation.total_rent);
    let disc_perct = parseInt(e);

    if (Number.isInteger(t_rent) && Number.isInteger(e)) {
      let newDiscAmount = t_rent * disc_perct * 0.01;
      let new_rent_to_be_paid = t_rent - newDiscAmount;
      if (
        new_rent_to_be_paid >= parseInt(this.state.res_amounts.received_rent)
      ) {
        let updReserv = { ...this.state.reservation };
        updReserv.total_discounted_amount = newDiscAmount;
        updReserv.discount_percent = disc_perct;
        this.setState({ reservation: updReserv }, () => {
          this.props.form.setFieldsValue({
            total_discounted_amount: newDiscAmount,
          });
        });
      } else {
        message.error(this.msg.RESRVDISCOUNTVALIDERROR);
      }
    } else if (!e) {
      let newDiscAmount = t_rent;
      let updReserv = { ...this.state.reservation };
      updReserv.total_discounted_amount = newDiscAmount + "";

      this.setState({ reservation: updReserv });
    }
  };

  statusChangeToPending = () => {
    const headers = getHeadersForHttpReq();

    const resrv_id = localStorage.getItem("reservationUpdate");
    if (resrv_id) {
      if (this.state.reservationByOwner == 1) {
        axios
          .post(
            `${complete_URL}/reservations/change_status_to_pending`,
            { id: resrv_id },
            { headers }
          )
          .then((res) => {
            if (res.data.success) {
              this.fetch();
              message.success(
                this.msg.RESRVSTATUSPSUCCESS /*res.data.message*/
              );
            } else {
              message.error(this.msg.RESRVSTATUSPFAILURE, 2);
            }
          })
          .catch((pending_st_err) => {
            console.log(
              "Error occured while Changing status to Pending->",
              pending_st_err
            );
            message.error(this.msg.RESRVSTATUSPFAILURE, 2);
          });
      }
    } else {
      message.error(this.msg.RESRVNOTFOUND);
    }
  };

  onChangeDiscountAmount = (e) => {
    let t_rent = parseInt(this.state.reservation.total_rent);
    let disc_amt = parseInt(e);
    if (Number.isInteger(t_rent) && Number.isInteger(e)) {
      let newDiscPercent = parseFloat((disc_amt * 100) / t_rent).toFixed(2);
      let new_rent_to_be_paid = t_rent - disc_amt;
      if (
        new_rent_to_be_paid >= parseInt(this.state.res_amounts.received_rent)
      ) {
        let updReserv = { ...this.state.reservation };
        updReserv.total_discounted_amount = disc_amt;
        updReserv.discount_percent = newDiscPercent;
        this.setState({ reservation: updReserv }, () => {
          this.props.form.setFieldsValue({ discount_percent: newDiscPercent });
        });
      } else {
        message.error(this.msg.RESRVDISCOUNTVALIDERROR, 2);
      }
    }
  };

  handleChangeCustomer = (customer_id) => {
    if (customer_id) {
      let selectedCustomer = this.state.customers.filter(
        (i) => i.id === customer_id
      );
      if (selectedCustomer.length > 0) {
        this.setState({
          cust_comb_phone:
            selectedCustomer[0].ph_num +
            " | " +
            (selectedCustomer[0].ph_alt_num
              ? selectedCustomer[0].ph_alt_num
              : "--"),
        });
      }
    } else {
      this.setState({ cust_comb_phone: "--" });
    }
  }; //End of Method

  render() {
    // console.log(' this.state.reservationByOwner->', this.state.reservationByOwner);
    // console.log(' resrc obj ->',this.state.reservation);
    const { getFieldDecorator } = this.props.form;

    let {
      dir,
      display,
      banquets,
      halls,
      hallPrice,
      customers,
      event_types,
      reservationByOwner,
      tdata,
      spin,
    } = this.state;
    let EN_FLAG = localStorage.getItem("lang") === "EN";

    return (
      <Spin tip={""} spinning={spin}>
        <Row gutter={12} style={{ marginTop: "50px" }}>
          <Col span={2}></Col>
          <Col span={20}>
            <Card>
              <Collapse
                accordion
                bordered={false}
                defaultActiveKey={["1"]}
                expandIconPosition={EN_FLAG ? "left" : "right"}
                expandIcon={({ isActive }) => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                )}
              >
                <Panel header={this.lang.TITLE} key="1">
                  <Form
                    onSubmit={this.handleSubmit}
                    style={{ marginTop: "20px" }}
                  >
                    {EN_FLAG
                      ? (this.CustomRow = (
                          <Row gutter={24} className="top-header">
                            <Col span={2}>
                              <Link to="/dashboard/reservations">
                                <Button type="danger">
                                  <Icon type="arrow-left" />
                                </Button>
                              </Link>
                            </Col>
                            <Col span={9}>
                              <h1>{this.lang.HALLUPDATE.LABELS.TITLE}</h1>
                            </Col>
                            <Col span={4} style={{ margin: "13px auto" }}>
                              <Button
                                type="primary"
                                onClick={this.downloadReservationReport}
                                icon="download"
                              >
                                {this.lang.HALLUPDATE.LABELS.REPORT}
                              </Button>
                            </Col>
                            <Col span={9}>
                              <Form.Item>
                                {this.state.reservation.status === "pending" ? (
                                  <React.Fragment>
                                    <Row gutter={24} className="custom-box">
                                      <Col
                                        span={6}
                                        className="btn-box"
                                        style={{
                                          paddingRight: 0,
                                          marginTop: "3px",
                                        }}
                                      >
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          title="save"
                                          className="save"
                                        >
                                          <Icon type="save" theme="filled" />
                                        </Button>
                                      </Col>
                                      <Col
                                        span={8}
                                        style={{
                                          width: "80px",
                                          marginTop: "3px",
                                        }}
                                      >
                                        <Button
                                          type="primary"
                                          className="confirmBtn"
                                          onClick={this.handleSubmitReserved}
                                          title="confirm"
                                        >
                                          {this.lang.HALLUPDATE.LABELS.CONFIRM}
                                        </Button>
                                      </Col>
                                      <Col
                                        span={10}
                                        style={{
                                          width: "100px",
                                          marginTop: "-10px",
                                          marginBottom: "-13px",
                                          paddingLeft: "16px",
                                        }}
                                      >
                                        {reservationByOwner == 1 && (
                                          <Button
                                            type="danger"
                                            block
                                            style={{
                                              color: "white",
                                              backgroundColor: "#fb3333",
                                            }}
                                            onClick={
                                              this.cancelReservationUpdated
                                            }
                                          >
                                            {this.lang.HALLUPDATE.LABELS.CANCEL}
                                          </Button>
                                        )}
                                      </Col>
                                      {/* <Col span={2}></Col> */}
                                    </Row>
                                  </React.Fragment>
                                ) : this.state.reservation.status ===
                                  "reserved" ? (
                                  <Row gutter={12}>
                                    <Col span={12}>
                                      {reservationByOwner == 1 && (
                                        <Button
                                          type="danger"
                                          block
                                          style={{
                                            color: "white",
                                            backgroundColor: "#fb3333",
                                          }}
                                          onClick={
                                            this.cancelReservationUpdated
                                          }
                                        >
                                          {this.lang.HALLUPDATE.LABELS.CANCEL}
                                        </Button>
                                      )}
                                    </Col>
                                    <Col span={12}>
                                      {reservationByOwner == 1 && (
                                        <Button
                                          onClick={this.statusChangeToPending}
                                          type="danger"
                                          block
                                        >
                                          {
                                            this.lang.HALLUPDATE.LABELS
                                              .CHANGESTATUS
                                          }
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                ) : (
                                  ""
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        ))
                      : (this.CustomRow = (
                          <Row className="top-header">
                            <Col span={2} style={{ margin: "-3px auto" }}>
                              <Link
                                style={{ float: "left" }}
                                to="/dashboard/reservations"
                              >
                                <Button type="danger">
                                  <Icon type="arrow-left" />
                                </Button>
                              </Link>
                            </Col>
                            <Col
                              span={9}
                              style={this.state.cancel_changeStatus_margin}
                            >
                              <Form.Item>
                                {this.state.reservation.status === "pending" ? (
                                  <React.Fragment>
                                    <Row gutter={24} className="custom-box">
                                      <Col
                                        span={10}
                                        style={{
                                          width: "100px",
                                          marginTop: "-13px",
                                          marginBottom: "-13px",
                                          paddingLeft: "16px",
                                        }}
                                      >
                                        {this.state.reservationByOwner == 1 && (
                                          <Button
                                            type="danger"
                                            style={{
                                              color: "white",
                                              backgroundColor: "#fb3333",
                                            }}
                                            block
                                            onClick={
                                              this.cancelReservationUpdated
                                            }
                                          >
                                            {this.lang.HALLUPDATE.LABELS.CANCEL}
                                          </Button>
                                        )}
                                      </Col>
                                      <Col span={5}>
                                        <Button
                                          type="primary"
                                          className="confirmBtn"
                                          onClick={this.handleSubmitReserved}
                                          title="confirm"
                                        >
                                          {this.lang.HALLUPDATE.LABELS.CONFIRM}
                                        </Button>
                                      </Col>
                                      <Col
                                        span={8}
                                        className="btn-box"
                                        style={{ paddingLeft: 0 }}
                                      >
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          title="save"
                                          className="save"
                                        >
                                          <Icon type="save" theme="filled" />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                ) : this.state.reservation.status ===
                                  "reserved" ? (
                                  <React.Fragment>
                                    <Row gutter={24}>
                                      <Col span={12}>
                                        {reservationByOwner == 1 && (
                                          <Button
                                            type="danger"
                                            block
                                            onClick={this.statusChangeToPending}
                                          >
                                            {
                                              this.lang.HALLUPDATE.LABELS
                                                .CHANGESTATUS
                                            }
                                          </Button>
                                        )}
                                      </Col>
                                      <Col span={12}>
                                        {reservationByOwner == 1 && (
                                          <Button
                                            type="danger"
                                            block
                                            style={{
                                              color: "white",
                                              backgroundColor: "#fb3333",
                                            }}
                                            onClick={
                                              this.cancelReservationUpdated
                                            }
                                          >
                                            {this.lang.HALLUPDATE.LABELS.CANCEL}
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={4} style={{ margin: "10px auto" }}>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  onClick={this.downloadReservationReport}
                                  icon="download"
                                >
                                  {this.lang.HALLUPDATE.LABELS.REPORT}
                                </Button>
                              </Form.Item>
                            </Col>
                            <Col span={9}>
                              <h1>{this.lang.HALLUPDATE.LABELS.TITLE}</h1>
                            </Col>
                          </Row>
                        ))}

                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={8}>
                        <Form.Item label={this.lang.HALLUPDATE.LABELS.STATUS}>
                          {getFieldDecorator("status", {
                            initialValue:
                              this.state.reservation.status === "pending"
                                ? this.lang.HALLUPDATE.STATUS.PENDING
                                : this.state.reservation.status === "reserved"
                                ? this.lang.HALLUPDATE.STATUS.RESERVED
                                : this.lang.HALLUPDATE.STATUS.CANCELLED,
                            rules: [{ required: true, message: "" }],
                          })(<Input readOnly />)}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={this.lang.HALLUPDATE.LABELS.NO}>
                          {getFieldDecorator("no", {
                            initialValue: this.state.reservation.no,
                            rules: [{ required: true, message: "" }],
                          })(<Input readOnly />)}
                        </Form.Item>
                      </Col>
                      <Col span={8}> </Col>
                    </Row>
                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={8}>
                        <Form.Item label={this.lang.HALLUPDATE.LABELS.BANQUET}>
                          {getFieldDecorator("banquet", {
                            initialValue: this.state.reservation.banquet,
                            rules: [
                              {
                                required: true,
                                message: this.msg.SELECTBANQUETERROR,
                              },
                            ],
                          })(
                            <Select
                              onChange={this.selectBanquet}
                              placeholder={this.lang.HALLUPDATE.LABELS.BANQUET}
                            >
                              {EN_FLAG
                                ? banquets.length > 0 &&
                                  banquets.map((banquet) => (
                                    <Select.Option
                                      key={banquet.id}
                                      disabled={
                                        this.state.reservation.status ===
                                        "reserved"
                                      }
                                      value={banquet.id}
                                    >
                                      {banquet.name}
                                    </Select.Option>
                                  ))
                                : banquets.length > 0 &&
                                  banquets.map((banquet) => (
                                    <Select.Option
                                      style={{ textAlign: "right" }}
                                      key={banquet.id}
                                      disabled={
                                        this.state.reservation.status ===
                                        "reserved"
                                      }
                                      value={banquet.id}
                                    >
                                      {banquet.name_ar}
                                    </Select.Option>
                                  ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={this.lang.HALLUPDATE.LABELS.HALL}>
                          {getFieldDecorator("hall", {
                            initialValue: this.state.reservation.hall,
                            rules: [
                              {
                                required: true,
                                message: this.msg.SELECTHALLERROR,
                              },
                            ],
                          })(
                            <Select
                              placeholder={this.lang.HALLUPDATE.LABELS.HALL}
                              onChange={this.selectHall}
                            >
                              {EN_FLAG
                                ? halls.length > 0 &&
                                  halls.map((hall) => (
                                    <Select.Option
                                      key={hall.id}
                                      value={hall.id}
                                    >
                                      {hall.name}
                                    </Select.Option>
                                  ))
                                : halls.length > 0 &&
                                  halls.map((hall) => (
                                    <Select.Option
                                      style={{ textAlign: "right" }}
                                      key={hall.id}
                                      value={hall.id}
                                    >
                                      {hall.name_ar}
                                    </Select.Option>
                                  ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={this.lang.HALLUPDATE.LABELS.UOP}>
                          {getFieldDecorator("hallPrice", {
                            initialValue: this.state.reservation.uop,
                            rules: [
                              {
                                required: true,
                                message: this.msg.SELECTUOPERROR,
                              },
                            ],
                          })(
                            <Select
                              placeholder={this.lang.HALLUPDATE.LABELS.UOP}
                            >
                              {EN_FLAG
                                ? hallPrice.length > 0 &&
                                  hallPrice.map((uop_item) => (
                                    <Select.Option
                                      key={uop_item.id}
                                      value={uop_item.uop}
                                    >
                                      {uop_item.uop_name}
                                    </Select.Option>
                                  ))
                                : hallPrice.length > 0 &&
                                  hallPrice.map((uop_item) => (
                                    <Select.Option
                                      style={{ textAlign: "right" }}
                                      key={uop_item.id}
                                      value={uop_item.uop}
                                    >
                                      {uop_item.uop_name_ar}
                                    </Select.Option>
                                  ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={8}>
                        <Form.Item label={this.lang.HALLUPDATE.LABELS.CUSTOMER}>
                          {getFieldDecorator("customer", {
                            initialValue: this.state.reservation.customer,
                            rules: [
                              {
                                required: true,
                                message: this.msg.SELECTCUSTOMERERROR,
                              },
                            ],
                          })(
                            <Select
                              onChange={this.handleChangeCustomer}
                              dropdownRender={(menu) => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: "4px 0" }} />
                                  <div
                                    onMouseDown={() =>
                                      this.setState({
                                        createCustomerOpen: true,
                                      })
                                    }
                                    value="create"
                                    style={{
                                      padding: "8px",
                                      cursor: "pointer",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Icon type="plus" />{" "}
                                    {this.lang.HALLUPDATE.LABELS.CUSTOMERTITLE}
                                  </div>
                                </div>
                              )}
                            >
                              {EN_FLAG
                                ? customers.length > 0 &&
                                  customers.map((item) => (
                                    <Select.Option
                                      value={item.id}
                                      key={item.id}
                                    >
                                      {item.name}
                                    </Select.Option>
                                  ))
                                : customers.length > 0 &&
                                  customers.map((item) => (
                                    <Select.Option
                                      style={{ textAlign: "right" }}
                                      value={item.id}
                                      key={item.id}
                                    >
                                      {item.name_ar}
                                    </Select.Option>
                                  ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={this.lang.HALLUPDATE.LABELS.CUSTOMER_PHONE}
                        >
                          {getFieldDecorator("ph_no", {
                            initialValue: this.state.cust_comb_phone,
                            rules: [{ required: false, message: "" }],
                          })(<Input readOnly />)}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={this.lang.HALLUPDATE.LABELS.RESERVATIONDATE}
                        >
                          {getFieldDecorator("reservation_date", {
                            initialValue: moment(
                              this.state.reservation.reservation_date
                            ),
                            rules: [
                              {
                                required: true,
                                message: this.msg.INSERTRESRVDATEERROR,
                              },
                            ],
                          })(<DatePicker style={{ width: "100%" }} />)}
                        </Form.Item>
                      </Col>
                      {/* <Col span={8}>
                    <Form.Item label={this.lang.HALLUPDATE.LABELS.CUSTOMER_PHONE}>
                        {getFieldDecorator('customer', {
                          initialValue: this.state.reservation.customer,
                          rules: [{ required: false, message: '' }],
                        })(
                          <Select disabled = {true}>
                            {
                              customers.length > 0 && customers.map(item => (
                                <Select.Option style={{textAlign:'center'}} value={item.id} key={item.id}>{item.ph_num + ' | ' + (item.ph_alt_num ? item.ph_alt_num : '--') }</Select.Option>
                              ))
                          }
                        </Select>
                      )}
                    </Form.Item>
                    </Col> */}
                    </Row>
                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={6}>
                        <Form.Item label={this.lang.HALLUPDATE.LABELS.FROMDATE}>
                          {getFieldDecorator("from_date", {
                            initialValue: moment(
                              this.state.reservation.from_date
                            ),
                            rules: [
                              {
                                required: true,
                                message: this.msg.INSERTFROMDATEERROR,
                              },
                            ],
                          })(
                            <DatePicker
                              disabledDate={this.disabledStartDate}
                              format="YYYY-MM-DD"
                              placeholder={this.lang.HALLUPDATE.LABELS.FROMDATE}
                              onChange={this.onStartChange}
                              onOpenChange={this.handleStartOpenChange}
                              style={{ width: "100%" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label={this.lang.HALLUPDATE.LABELS.TODATE}>
                          {getFieldDecorator("to_date", {
                            initialValue: moment(
                              this.state.reservation.to_date
                            ),
                            rules: [
                              {
                                required: true,
                                message: this.msg.INSERTTODATEERROR,
                              },
                            ],
                          })(
                            <DatePicker
                              disabledDate={this.disabledEndDate}
                              format="YYYY-MM-DD"
                              placeholder={this.lang.HALLUPDATE.LABELS.TODATE}
                              onChange={this.onEndChange}
                              open={this.state.endOpen}
                              onOpenChange={this.handleEndOpenChange}
                              style={{ width: "100%" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label={this.lang.HALLUPDATE.LABELS.FROMTIME}>
                          {getFieldDecorator("from_time", {
                            initialValue: moment(
                              this.state.reservation.from_time,
                              "HH:mm:ss"
                            ),
                            rules: [
                              {
                                required: true,
                                message: this.msg.INSERTFROMTIMEERROR,
                              },
                            ],
                          })(
                            <TimePicker
                              use12Hours
                              format="h:mm a"
                              style={{ width: "100%" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label={this.lang.HALLUPDATE.LABELS.TOTIME}>
                          {getFieldDecorator("to_time", {
                            initialValue: moment(
                              this.state.reservation.to_time,
                              "HH:mm:ss"
                            ),
                            rules: [
                              {
                                required: true,
                                message: this.msg.INSERTTOTIMEERROR,
                              },
                            ],
                          })(
                            <TimePicker
                              use12Hours
                              format="h:mm a"
                              style={{ width: "100%" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={8}>
                        <Form.Item
                          label={this.lang.HALLUPDATE.LABELS.EVENTTYPE}
                        >
                          {getFieldDecorator("event_type", {
                            initialValue: this.state.reservation.event_type,
                            rules: [
                              {
                                required: true,
                                message: this.msg.SELECTEVENTTYPEERROR,
                              },
                            ],
                          })(
                            <Select
                              placeholder={
                                this.lang.HALLUPDATE.LABELS.EVENTTYPE
                              }
                            >
                              {EN_FLAG
                                ? event_types.length > 0 &&
                                  event_types.map((event) => (
                                    <Select.Option
                                      key={event.id}
                                      value={event.id}
                                    >
                                      {event.name}
                                    </Select.Option>
                                  ))
                                : event_types.length > 0 &&
                                  event_types.map((event) => (
                                    <Select.Option
                                      style={{ textAlign: "right" }}
                                      key={event.id}
                                      value={event.id}
                                    >
                                      {event.name_ar}
                                    </Select.Option>
                                  ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={this.lang.HALLUPDATE.LABELS.NOOFPERSONS}
                        >
                          {getFieldDecorator("no_person", {
                            initialValue: this.state.reservation.no_person,
                            rules: [
                              {
                                required: true,
                                message: this.msg.INSERTNOOFPERSONSERROR,
                              },
                            ],
                          })(
                            <InputNumber
                              placeholder={
                                this.lang.HALLUPDATE.LABELS.NOOFPERSONS
                              }
                              style={{ width: "100%" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      {reservationByOwner == 1 && (
                        <Col span={8}>
                          <Form.Item
                            label={this.lang.HALLUPDATE.LABELS.DISCOUNTTYPE}
                          >
                            {getFieldDecorator("discountOption", {
                              initialValue: this.state.discountOptions[0].id,
                              rules: [
                                {
                                  required: true,
                                  message: this.msg.SELECTDISCOUNTTYPEERROR,
                                },
                              ],
                            })(
                              <Select
                                onChange={(e) =>
                                  this.handleDiscountTypeSelection(e)
                                }
                                placeholder={""}
                              >
                                {EN_FLAG
                                  ? this.state.discountOptions.map(
                                      (discount) => (
                                        <Select.Option
                                          key={discount.id}
                                          value={discount.id}
                                        >
                                          {discount.name}
                                        </Select.Option>
                                      )
                                    )
                                  : this.state.discountOptions.map(
                                      (discount) => (
                                        <Select.Option
                                          style={{ textAlign: "right" }}
                                          key={discount.id}
                                          value={discount.id}
                                        >
                                          {discount.name_ar}
                                        </Select.Option>
                                      )
                                    )}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={8}>
                        <Form.Item
                          label={this.lang.HALLUPDATE.LABELS.SECURITYDEPOSITE}
                        >
                          {getFieldDecorator("sec_dep", {
                            initialValue: this.state.reservation.sec_dep,
                            rules: [{ required: false, message: "" }],
                          })(
                            // <InputNumber disabled placeholder="Enter no. of persons " style={{ width: '100%' }} />
                            <label style={{ fontWeight: 700 }}>
                              {this.state.reservation.sec_dep}
                            </label>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={this.lang.HALLUPDATE.LABELS.ISPAIDSECURITY}
                        >
                          {getFieldDecorator("isSecDepPaid", {
                            valuePropName: "checked",
                            initialValue:
                              this.state.reservation.isSecDepPaid == 1
                                ? true
                                : false,
                          })(<Switch />)}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={this.lang.HALLUPDATE.LABELS.ISRETURNEDSECURITY}
                        >
                          {getFieldDecorator("isSecDepReturned", {
                            valuePropName: "checked",
                            initialValue:
                              this.state.reservation.isSecDepReturned == 1
                                ? true
                                : false,
                          })(<Switch />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={8}>
                        <Form.Item
                          label={this.lang.HALLUPDATE.LABELS.TOTALHALLRENT}
                        >
                          {getFieldDecorator("total_rent", {
                            initialValue: this.state.reservation.total_rent,
                            rules: [{ required: false, message: "" }],
                          })(
                            // <InputNumber placeholder="Enter no. of persons " value={this.state.reservation.total_rent} readOnly style={{ width: '100%' }} />
                            <label style={{ fontWeight: 700 }}>
                              {this.state.reservation.total_rent -
                                this.state.reservation.total_discounted_amount}
                            </label>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={
                            this.lang.HALLUPDATE.LABELS.TOTALRECEIVEDAMOUNT
                          }
                        >
                          {getFieldDecorator("received_amount", {
                            initialValue:
                              this.state.res_amounts.received_amount,
                            rules: [{ required: false, message: "" }],
                          })(
                            <label style={{ fontWeight: 700 }}>
                              {this.state.res_amounts.received_amount}
                            </label>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={this.lang.HALLUPDATE.LABELS.TOTALAMOUNT}
                        >
                          {getFieldDecorator("rr", {
                            initialValue: this.state.res_amounts.total_amount,
                            rules: [{ required: false, message: "" }],
                          })(
                            // <InputNumber disabled placeholder="Enter no. of persons " style={{ width: '100%' }} />
                            <label style={{ fontWeight: 700 }}>
                              {this.state.res_amounts.total_amount}
                            </label>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    {reservationByOwner == 1 && (
                      <Row gutter={24} dir={dir} style={{ display }}>
                        <Col span={8}>
                          <Form.Item
                            label={
                              this.lang.HALLUPDATE.LABELS.RECIEVEDAMOUNTRENT
                            }
                          >
                            {getFieldDecorator("recieved_ammount", {
                              initialValue:
                                this.state.res_amounts.received_rent,
                              rules: [{ required: false, message: "" }],
                            })(
                              <label style={{ fontWeight: 700 }}>
                                {this.state.res_amounts.received_rent}
                              </label>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label={
                              this.lang.HALLUPDATE.LABELS.TOTALDISCOUNTAMOUNT
                            }
                          >
                            {getFieldDecorator("total_discounted_amount", {
                              initialValue:
                                this.state.reservation.total_discounted_amount,
                              rules: [{ required: false, message: "" }],
                            })(
                              <InputNumber
                                max={
                                  Number(this.state.reservation.total_rent) -
                                  Number(this.state.res_amounts.received_rent)
                                }
                                disabled={this.state.discountOption == "0"}
                                min={0.0}
                                placeholder={""}
                                onChange={(e) => this.onChangeDiscountAmount(e)}
                                style={{ width: "100%" }}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label={
                              this.lang.HALLUPDATE.LABELS.DISCOUNTPERCENTAGE
                            }
                          >
                            {getFieldDecorator("discount_percent", {
                              initialValue:
                                this.state.reservation.discount_percent,
                              rules: [{ required: false, message: "" }],
                            })(
                              <InputNumber
                                max={Number(
                                  100 -
                                    Number(
                                      this.state.res_amounts.received_rent /
                                        this.state.reservation.total_rent
                                    ) *
                                      100
                                ).toFixed(2)}
                                min={0.0}
                                disabled={this.state.discountOption == "1"}
                                placeholder={""}
                                onChange={(e) =>
                                  this.onChangeDiscountPercentage(e)
                                }
                                style={{ width: "100%" }}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    {/* <Row gutter={24} dir = {dir} style={{display}}>
                    <Col span={12}>
                    <Form.Item label={this.lang.HALLUPDATE.LABELS.PAIDSECURITYDEPOSITE} >
                        {getFieldDecorator('aaa', {
                          initialValue: this.state.reservation.recieved_sec_dep,
                          rules: [{ required: false, message: 'Please Select Event Type!' }],
                        })(
                          // <InputNumber disabled placeholder="Enter no. of persons " style={{ width: '100%' }} />
                          <label style={{ fontWeight: 700 }}>{this.state.reservation.recieved_sec_dep}</label>
                        )}
                      </Form.Item>
                    </Col>
                  </Row> */}
                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={24}>
                        <Form.Item
                          label={this.lang.HALLUPDATE.LABELS.WRITENOTE}
                        >
                          {getFieldDecorator("notes", {
                            initialValue: this.state.reservation.notes,
                            rules: [
                              {
                                required: false,
                                message: this.msg.INSERTNOTESERROR,
                              },
                            ],
                          })(
                            <Input.TextArea
                              placeholder={
                                this.lang.HALLUPDATE.LABELS.WRITENOTE
                              }
                              style={{ width: "100%" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Panel>
                {/* </Collapse>
                        <Collapse accordion bordered={false} expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}> */}
                <Panel
                  header={
                    this.props.language._RESERVATION.UPDATE.PAYMENTS.TITLE
                  }
                  key="2"
                >
                  <HallPayments
                    {...this.props}
                    status={this.state.reservation.status}
                    reservationId={localStorage.getItem("reservationUpdate")}
                    hallId={this.state.reservation.hall}
                    reservation_id={this.state.reservation.id}
                    resrv_id={this.state.reservation.id}
                    reloadPage={this.fetch}
                    getCompleteReportData={this.getCompleteReportData}
                    // reportData = {this.state.reportData}

                    updateChildCounterByParent={this.updateChildCounterByParent}
                    parent_resrv_Counter={this.state.parent_resrv_Counter}
                    parent_hall_pmt_Counter={this.state.parent_hall_pmt_Counter}
                    parent_hall_srv_Counter={this.state.parent_hall_srv_Counter}
                    parent_hall_pkg_Counter={this.state.parent_hall_pkg_Counter}
                    parent_hall_doc_Counter={this.state.parent_hall_doc_Counter}
                  />
                </Panel>
                {/*<Panel header={this.props.language._RESERVATION.UPDATE.SERVICES.TITLE} key="3" >
                <HallServices  
                  {...this.props}
                  reloadPage={this.fetch} 
                  status={this.state.reservation.status} 
                  reservationId={localStorage.getItem('reservationUpdate')} 
                  hallId={this.state.reservation.hall} 
                  reservation_id={this.state.reservation.id} 

                  updateChildCounterByParent = {this.updateChildCounterByParent}
                  parent_resrv_Counter       = {this.state.parent_resrv_Counter}
                  parent_hall_pmt_Counter    = {this.state.parent_hall_pmt_Counter}
                  parent_hall_srv_Counter    = {this.state.parent_hall_srv_Counter}
                  parent_hall_pkg_Counter    = {this.state.parent_hall_pkg_Counter}
                  parent_hall_doc_Counter    = {this.state.parent_hall_doc_Counter} 
                />
                </Panel>*/}
                <Panel
                  header={
                    this.props.language._RESERVATION.UPDATE.SERVICES
                      ./*PACKAGES.*/ TITLE
                  }
                  key="4"
                  id="packages"
                >
                  <HallPackages
                    {...this.props}
                    reloadPage={this.fetch}
                    status={this.state.reservation.status}
                    reservationId={localStorage.getItem("reservationUpdate")}
                    hallId={this.state.reservation.hall}
                    reservation_id={this.state.reservation.id}
                    resrv_id={this.state.reservation.id}
                    updateChildCounterByParent={this.updateChildCounterByParent}
                    parent_resrv_Counter={this.state.parent_resrv_Counter}
                    parent_hall_pmt_Counter={this.state.parent_hall_pmt_Counter}
                    parent_hall_srv_Counter={this.state.parent_hall_srv_Counter}
                    parent_hall_pkg_Counter={this.state.parent_hall_pkg_Counter}
                    parent_hall_doc_Counter={this.state.parent_hall_doc_Counter}
                  />
                </Panel>
                <Panel
                  header={
                    this.props.language._RESERVATION.UPDATE.RESERVATIONDOCUMENT
                      .TITLE
                  }
                  key="5"
                  id="documents"
                >
                  <ReservationDocuments
                    {...this.props}
                    reloadPage={this.fetch}
                    reservationId={localStorage.getItem("reservationUpdate")}
                    resrv_id={this.state.reservation.id}
                    updateChildCounterByParent={this.updateChildCounterByParent}
                    parent_resrv_Counter={this.state.parent_resrv_Counter}
                    parent_hall_pmt_Counter={this.state.parent_hall_pmt_Counter}
                    parent_hall_srv_Counter={this.state.parent_hall_srv_Counter}
                    parent_hall_pkg_Counter={this.state.parent_hall_pkg_Counter}
                    parent_hall_doc_Counter={this.state.parent_hall_doc_Counter}
                  />
                </Panel>
              </Collapse>
            </Card>
          </Col>
          <Col span={4}></Col>
          <Modal
            visible={this.state.createCustomerOpen}
            title="Add New Customer"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={false}
          >
            <CreateCustomer
              gender={this.state.gender}
              language={this.props.language}
              handleCancel={this.handleCancel}
              handleOk={this.handleOk}
            />
          </Modal>
          <Modal
            visible={this.state.show_Payment_Modal}
            title={this.lang.HALLUPDATE.CANCELRESERVATION.CANCEL}
            closable={true}
            onCancel={this.closePaymentDeductionModal}
            destroyOnClose={true}
            width={"80%"}
            footer={false}
          >
            <Spin
              tip={this.lang.HALLUPDATE.CANCELRESERVATION.CANCELMSG}
              spinning={this.state.isCancelling}
            >
              {this.lang.HALLUPDATE._DIRECTION === "ltr" && (
                <Row id="paymentTable" span={24}>
                  <Col span={15}>
                    <Row gutter={24}>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <h2>{this.lang.HALLUPDATE.CANCELRESERVATION.TITLE}</h2>
                      </Col>
                    </Row>
                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <Table
                          width={"100%"}
                          size={"small"}
                          columns={this.total_payment_columns}
                          rowKey="id"
                          dataSource={tdata}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1}>{""}</Col>
                  <Col span={8}>
                    <Row gutter={24}>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <h2>
                          {this.lang.HALLUPDATE.CANCELRESERVATION.CANCELNOTES}
                        </h2>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={24}>
                        <Input.TextArea
                          ref="cancellation_notes_Ref"
                          rows={12}
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                    <Row
                      gutter={24}
                      dir={dir}
                      style={{ marginTop: "5%", display }}
                    >
                      <Col span={24} style={{ textAlign: "center" }}>
                        <Button
                          type="primary"
                          shape="round"
                          onClick={this.cancelPaidReservationService}
                        >
                          {" "}
                          {this.lang.HALLUPDATE.CANCELRESERVATION.CANCEL}{" "}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {this.lang.HALLUPDATE._DIRECTION === "rtl" && (
                <Row id="paymentTable" span={24}>
                  <Col span={8}>
                    <Row gutter={24}>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <h2>
                          {this.lang.HALLUPDATE.CANCELRESERVATION.CANCELNOTES}
                        </h2>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={24}>
                        <Input.TextArea
                          dir={"rtl"}
                          ref="cancellation_notes_Ref"
                          rows={12}
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={24} style={{ marginTop: "5%" }}>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <Button
                          type="primary"
                          shape="round"
                          onClick={this.cancelPaidReservationService}
                        >
                          {" "}
                          {this.lang.HALLUPDATE.CANCELRESERVATION.CANCEL}{" "}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1}>{""}</Col>
                  <Col span={15}>
                    <Row gutter={24}>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <h2>{this.lang.HALLUPDATE.CANCELRESERVATION.TITLE}</h2>
                      </Col>
                    </Row>
                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <Table
                          width={"100%"}
                          size={"small"}
                          columns={this.total_payment_columns}
                          rowKey="id"
                          dataSource={this.state.tdata}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Spin>
          </Modal>
          <Modal
            visible={this.state.show_Cancel_Notes_Modal}
            title={this.lang.HALLUPDATE.CANCELRESERVATION.CANCEL}
            closable={true}
            onCancel={this.closecCancelNotesModal}
            destroyOnClose={true}
            width={"50%"}
            footer={false}
          >
            <Spin
              tip={this.lang.HALLUPDATE.CANCELRESERVATION.CANCELMSG}
              spinning={this.state.isCancelling}
            >
              <Row gutter={24}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <h2>{this.lang.HALLUPDATE.CANCELRESERVATION.CANCELNOTES}</h2>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Input.TextArea
                    ref="cancellation_notes_Ref"
                    rows={12}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
              <Row gutter={24} style={{ marginTop: "5%" }}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.cancelPaidReservationService}
                  >
                    {" "}
                    {this.lang.HALLUPDATE.CANCELRESERVATION.CANCEL}{" "}
                  </Button>
                </Col>
              </Row>
            </Spin>
          </Modal>
          <Modal
            visible={this.state.showCancellationNotesModal}
            title={this.lang.HALLUPDATE.CANCELRESERVATION.CANCEL}
            closable={true}
            onCancel={this.closecCancelNotesModal}
            footer={false}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Input.TextArea
                  readOnly={true}
                  value={this.state.reservation.cancellation_notes}
                  rows={12}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Modal>
        </Row>
      </Spin>
    );
  }
}

export default ReservationUpadte = Form.create({ name: "resrv_update_form" })(
  ReservationUpadte
);
