import React, { Component } from 'react';
import { Row, Col, Form, Table, message, Select, Button,Input, Spin } from 'antd'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';

if (localStorage.getItem('lang') === 'AR') {
    require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Contractor_Client extends Component{
    constructor(props) {
        super(props)
        this.state = {
            clientsList : [],
            selected_clients : [],
            all_selected_clients : [],
            assigned_clientsList : [],
            data: [],
            pagination: {},
            page: 1,
            size: 5,
            sortField:"name",
            sortOrder:"ascend",
            searchValues: {
              name: '',
              name_ar :''
            },
            dir : 'ltr', 
            display: 'block',
            spin : false,
            delete_spin : false,
            spin_msg : ''
        }

    this.lang = props.language._CONTRACTOR_CLIENT.SETUP;
    this.msg = props.language._CONTRACTOR_CLIENT.MESSAGE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <p>{text}</p>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <p>{text}</p>,
      },
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '20%',
        key: 'last_name',
        align:'center'
      }
    ];
    
    }// End of Contractor_Client

    setComponentDirection=()=>{
      if (localStorage.getItem('lang')==='EN') {
          this.setState({dir : 'ltr', display: 'block'})
      }
      else {
          this.setState({dir : 'rtl', display: 'flex'})
      }
    }
     
    compareByAlph = (a, b) => {
        if (a > b) { return -1; }
        if (a < b) { return 1; }
        return 0;
    }

    deleteCategory = (record) => {
      this.setState({spin_msg: this.msg.DELETETIP ,spin : true})
      const cont_client_id = record.id;
      if(cont_client_id) {
       let data = {
        cont_client_id : cont_client_id,
        contractor_id : record.contractor_id,
        client_id : record.client_id
       };
       const headers = getHeadersForHttpReq();
       axios.post(`${complete_URL}/cont_clients/delete_cont_client`, data, {headers})
       .then(delete_res=>{
          if(delete_res.data.code == '001'){
            this.setState({spin : false})
            message.error(this.msg.DELETEDEPENDENCYERROR,3);
          }
          else if(delete_res.data.success){
            this.fetch();
            this.getAssignedClients();
            this.setState({spin : false},()=>message.success(this.msg.DELETESUCCESS));
          }
          else{
            this.setState({spin : false});
            message.error(this.msg.DELETEFAILURE,3);
          }
       })
       .catch(delete_err=>{
          this.setState({spin : false})
          console.log('Error occured while deleting the Contractor_Client record->',delete_err);
          message.error(this.msg.DELETEFAILURE,3);
       })
      }
      else { 
        this.setState({spin : false}); 
        message.error(this.props.language.LOGINERROR,3) 
      }
    }// End of Method

    handleChangePagination = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({ pagination: pager, pageNumber: pagination.current });
    
        if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
          if (this.state.pageNumber  !== pager.current) {
            this.fetch({ page: pagination.current, });
          }
        } 
        else {
          this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
          });
        }
    }

    handleChangeSearchValues = (prop) => (e) => {
        let search = { ...this.state.searchValues };
        search[`${prop}`] = e.target.value;
        this.setState({
          searchValues: search
        }, () => {
          this.fetch();
        });
    }
  
    fetch = (params={}) => {
      // const client_id = localStorage.getItem('client_id');
      const cont_id = JSON.parse(localStorage.getItem('updateContractor')).id;
      let headers =  getHeadersForHttpReq();
      let page,sortOrder ;
      if (params.page) { page = params.page - 1 }
      else { page = 0;}

      if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
      else if (params.sortOrder == 'descend') { sortOrder = 'DESC' }
      else { sortOrder = 'ASC' }
      
      let data ={
        cont_id : cont_id,
        name: this.state.searchValues.name || '',
        name_ar: this.state.searchValues.name_ar || '',
        page,
        sortColumn: params.sortField || this.state.sortField ,
        sortOrder,
        size:this.state.size,
        orderBy: '',
        column_name: 'no'
      }
        // console.log('Sending data to api ->',data);
        axios.post(`${complete_URL}/cont_clients/pagination_table`,data, {headers})
        .then(response => {
          //console.log('response of cont_clients pagination response.data.data ->',response.data.data)
          let pagination = {};
          Object.assign(pagination,{
            ...this.state.pagination,
            total : response.data.totalElements,
            current : page +1,
            pageSize : this.state.size
          });
          /*let ct_data = response.data.data;
          let res = [];
          if (localStorage.getItem('lang')==='EN') {
            if (ct_data.length > 0) {
              res  = ct_data.map( ct=>{ return ct.name })
            }
          }
          else{
            if (ct_data.length > 0) {
              res  = ct_data.map( ct=>{ return ct.name_ar });
            }
          }*/
          
        this.setState({ data: response.data.data, pagination/*, selected_clients : res*/ })
          // console.log('Expense data-> ',response);
        })
        .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })
      
    }

    componentDidMount(){
      this.fetch();
      this.getClients();
      this.getAssignedClients();
    }

    getClients=()=>{
      let headers =  getHeadersForHttpReq();
      axios.get(`${complete_URL}/clients/`,{headers})
        .then(res => { /*console.log(res)*/
            this.setState({ clientsList: res.data })
        })
        .catch(clt_err => { console.log('Error in fetching Clients list->',clt_err) })
    }
    
    getAssignedClients =()=> {
      const cont_id = JSON.parse(localStorage.getItem('updateContractor')).id;
      if(cont_id){
        let headers =  getHeadersForHttpReq();
        axios.get(`${complete_URL}/cont_clients/${cont_id}`, {headers})
          .then(response => { 
            let ct_data = response.data.data;
            let res = [];
            if (localStorage.getItem('lang')==='EN') {
              if (ct_data.length > 0) {
                res  = ct_data.map( ct=>{ return ct.name })
              }
            }
            else{
              if (ct_data.length > 0) {
                res  = ct_data.map( ct=>{ return ct.name_ar });
              }
            }
              this.setState({ selected_clients : res, all_selected_clients : response.data.data})
          })
          .catch(clt_err => { console.log('Error in fetching assigned Clients list->',clt_err) })
      }
    }

    removeAlreadyAssigned=(selectedArr)=>{
      let { all_selected_clients } = this.state;
      for (let i = 0; i < selectedArr.length; i++) {
        if (localStorage.getItem('lang')==='EN') {
          for (let j = 0; j < all_selected_clients.length; j++) {
            if (selectedArr[i] === all_selected_clients[j].name) {
              selectedArr.splice(i,1);
              j = 0;
            }
          }// End of inner loop
        }
        else{
          for (let j = 0; j < all_selected_clients.length; j++) {
            if (selectedArr[i] === all_selected_clients[j].name_ar) {
              selectedArr.splice(i,1);
              j = 0;
            }
          }// End of inner loop
        }
      }

      return selectedArr;
    }

    handleClientSelection=(e)=>{
      e.preventDefault();
      this.setState({spin_msg: this.msg.ASIGNTIP ,spin : true})
      const cont_id = JSON.parse(localStorage.getItem('updateContractor')).id;
      if (cont_id) {
        this.props.form.validateFields((err, values) => {
          if (!err) {
            const  headers = getHeadersForHttpReq();
            let new_clients = this.removeAlreadyAssigned(values.clients);
            if (new_clients.length > 0) {            
              let data = {
                  cont_id : cont_id,
                  user_id: localStorage.getItem('user_id'),
                  client_id : localStorage.getItem('client_id'),
                  clients: new_clients
              }    
              
              axios.post(`${complete_URL}/cont_clients/add`, data, {headers})
              .then(res => {
                if(res.data.success) {
                  this.getAssignedClients();
                  this.fetch();
                  this.setState({spin : false},()=>message.success(this.msg.ADDSUCCESS));
                } 
                else {
                  this.setState({spin : false},()=>message.error(this.lang.MESSAGE.ADDFAILURE,3))
                }
              })
              .catch(err => {
                  console.log('Error occured while assigning the Client(s)->',err);
                  this.setState({spin : false},()=>message.error(this.lang.MESSAGE.ADDFAILURE,3))
              })
            }
            else { this.setState({spin : false},()=>message.error(this.msg.SELECTERROR,3));}
          }// End of Error not found
        })
      }
      else{ message.error(this.props.language.LOGINERROR,3); }
    }

    render() {
        
        const { getFieldDecorator } = this.props.form;
        // console.log('this.state.selected_clients',this.state.selected_clients);
        //ISSUE NOTE: On assigning any new Service Type, The selected Clients names does not display completly

        return (
            <Spin tip={this.state.spin_msg} spinning = {this.state.spin}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form onSubmit={this.handleClientSelection} >
                    <Row gutter={24}  dir={this.state.dir} style={{display : this.state.display}}>
                        <Col span={24}>
                        <Form.Item label={this.lang.TABLEHEADERS.TITLE}  >
                            {getFieldDecorator('clients', {
                              initialValue: this.state.selected_clients,
                              rules: [{ required: false, message: '' }],
                            })(
                                <Select mode="multiple" 
                                  // onChange={this.selectClients} 
                                  placeholder={ this.lang.TABLEHEADERS.TITLE} 
                                  // defaultValue ={this.state.selected_clients}
                                  style={{ width: '100%' }} 
                                  // optionLabelProp="label"
                                >
                                {
                                    (localStorage.getItem('lang') === 'EN') ?
                                    this.state.clientsList.map(ct => (
                                        <Select.Option style={{textAlign:'left', marginRight: '6px'}} key={ct.id} value={ct.id}  label = {ct.name}>{ct.name}</Select.Option>
                                    ))
                                    : this.state.clientsList.map(ct => (
                                        <Select.Option style={{textAlign:'right', marginRight: '6px'}} key={ct.id} value={ct.id}  label = {ct.name_ar}>{ct.name_ar}</Select.Option>
                                    ))
                                }
                                </Select>)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}  dir={this.state.dir} style={{display : this.state.display}}>
                        <Col span={8}></Col>
                        <Col span={8}>
                              <Button type="primary" block htmlType="submit" >{this.lang.BUTTON.ASSIGN}</Button>
                        </Col>
                        <Col span={8}></Col>    
                    </Row>
                    </Form>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                 <Table
                    columns={this.columns}
                    rowKey="id"
                    style={{marginTop : '10px'}}
                    dataSource={this.state.data}
                    onChange={this.handleChangePagination}
                    pagination={this.state.pagination}
                /> 
                </Col>
            </Row>
        </Spin>


        )
    }// End of render
  }//End of Component

  export default Contractor_Client;