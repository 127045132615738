import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, Card, message, Select} from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;


class ScreensUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      screen: {
        name: '',
        url: '',
      },
      modules: [],
      module: {},
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._SCREEN.UPDATE
  }
  
  setComponentDirection =()=>{
    if (localStorage.getItem('lang') === 'EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    const screen_id = localStorage.getItem('updateScreen');
    if (screen_id) {
      this.getModules();
      this.getScreenObject(screen_id);
      this.getScreenAssignedModuleInfo(screen_id);
    }
    else{
      this.props.history.push('/dashboard/screens');
    }

    // axios.get(`${complete_URL}/screens/${localStorage.getItem('updateScreen')}`,{ headers})
    //   .then(res => {
    //     this.setState({ screen: res.data[0] })
    //   })
    //   .catch(err => {console.log(err) })

    // axios.get(`${complete_URL}/modules/`,{headers})
    //   .then(result => { /*console.log(result.data)*/
    //     this.setState({ modules: result.data })
    //   })
    //   .catch(err => { console.log(err) })

    // console.log(localStorage.getItem('updateScreen'))

    // axios.get(`${complete_URL}/modulesscreens/getmodule_by_screen/${localStorage.getItem('updateScreen')}`, { headers})
    //   .then(result => { /*console.log(result.data)*/
    //     this.setState({ module: result.data.data[0] })
    //   })
    //   .catch(err => {console.log(err) })

    // setTimeout(() => { console.log(this.state) }, 500)
  }

  getScreenObject =(screen_id)=>{
    const headers =  getHeadersForHttpReq();
    axios.get(`${complete_URL}/screens/${screen_id}`,{ headers})
      .then(res => {
        this.setState({ screen: res.data[0] })
      })
      .catch(err => {console.log('Error occured while fetching Screen Object ->',err) });
  }

  getModules=()=>{
    const headers =  getHeadersForHttpReq();
    axios.get(`${complete_URL}/modules/`,{headers})
      .then(result => { /*console.log(result.data)*/
        this.setState({ modules: result.data })
      })
      .catch(err => { console.log('Error occured while fetching all Modules ->',err) })
  }

  getScreenAssignedModuleInfo=(screen_id)=>{
    const headers =  getHeadersForHttpReq();

    axios.get(`${complete_URL}/modulesscreens/getmodule_by_screen/${screen_id}`, { headers})
      .then(result => { /*console.log(result.data)*/
        this.setState({ module: result.data.data[0] });
      })
      .catch(err => {console.log('Error occured while fetching screen assigned Module info ->',err) });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const headers =  getHeadersForHttpReq();
        const screen_id = localStorage.getItem('updateScreen');
        const user_id = localStorage.getItem('user_id');
        let screen_data = { name: values.name, url: values.url, name_ar: values.name_ar, user_id };

        axios.put(`${complete_URL}/screens/${screen_id}`,screen_data, {headers})
          .then(res => {
            this.props.history.push('screens')
            message.success('Screen Has been Updated')
          })
          .catch(err => { message.error('Screen Update Failed') })

        let data = {
          client_id: localStorage.getItem('client_id'),
          user_id: localStorage.getItem('user_id'),
          module_id: values.module_id,
          screen_id: localStorage.getItem('updateScreen')
        }
        if (this.state.module) {
          // console.log('update')
          axios.put(`${complete_URL}/modulesscreens/${this.state.module.module_id}`, data, {headers})
            .then(res => {
              message.success("Module has been assigned to screen Updated")
              // console.log(res)
            })
            .catch(err => {
              console.log('Error occured while updating module-Screen assignment ->',err)
              message.error("Module assigned to screen Update failed");
            })
        } 
        else {
          axios.post(`${complete_URL}/modulesscreens/register`, data, {headers})
            .then(res => {
              message.success("Module has been assigned to screen")
              // console.log(res)
            })
            .catch(err => {
              console.log('Error occured while creating module-Screen record ->',err)
              message.error("Module has assigned failed")
            })
        }
      }
    })
  }

  deleteUser = () => {
    const headers =  getHeadersForHttpReq();
    axios.delete(`${complete_URL}/screens/${localStorage.getItem('updateScreen')}`,{headers})
      .then(res => { /*console.log(res)*/
        this.props.history.push('screens')
        message.success('Screen has been Deleted')
      })
      .catch(err => {
        message.error('Screen can not be Deleted')
        console.log('Error occured while deleting the Screen ->',err)
      })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/screens">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={14}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          {/* <Button className="delete" type="" onClick={this.deleteUser} title="delete"><Icon type="delete" theme="filled" /></Button> */}
          <Link to="/dashboard/screens">
            <Button style={{margin: '16px auto'}} className="delete"><Icon type="delete"  theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/screens">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to="/dashboard/screens">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          {/* <Button className="delete" type="" onClick={this.deleteUser} title="delete"><Icon type="delete" theme="filled" /></Button> */}
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      initialValue: this.state.screen.name,
                      rules: [{ required: true, message: 'Please input  Name!' }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} type="text" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      initialValue: this.state.screen.name_ar,
                      rules: [{ required: true, message: 'Please input Screen Arabic Name!' }],
                    })(
                      <Input dir="rtl" placeholder={this.lang.LABELS.NAMEAR} type="text" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.URL}>
                    {getFieldDecorator('url', {
                      initialValue: this.state.screen.url,
                      rules: [{ required: true, message: 'Please input Screen URL!' }],
                    })(
                      <Input placeholder={this.lang.LABELS.URL} type="text" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.MODULEID}>
                    {getFieldDecorator('module_id', {
                      initialValue: this.state.module && this.state.module.module_id,
                      rules: [{ required: false, message: 'Please assign a module!' }],
                    })(
                      <Select>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            Object.assign(this.state.modules).map(uom => (
                              <Select.Option key={uom.id} value={uom.id}>{uom.name}</Select.Option>
                            ))
                            :
                            Object.assign(this.state.modules).map(uom => (
                              <Select.Option key={uom.id} value={uom.id}>{uom.name_ar}</Select.Option>
                            ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>)
  }
}

export default ScreensUpdate = Form.create({ name: 'normal_login' })(ScreensUpdate);