import React, { Component } from 'react';
import { Row, Col, Tabs } from 'antd'
// These Contractor Screens for guiadence only
// import ContractorsAssignedBqt from '../ContractorsAssignedBqt';
// import ClientRequests from '../ClientRequests';
// import ApproveContractorRequest from '../ApproveContractorRequest'

// import ClientsAssignedCont from '../../components/ClientsAssignedCont';
// import ContractorClientRequest from '../../components/ContractorClientRequest';
// import ApproveClientRequest from '../../components/ApproveClientRequest';

import ClientsAssignedCont from './ClientsAssignedCont';
import ContractorClientRequest from './ContractorClientRequest';
import ApproveClientRequest from './ApproveClientRequest';

const { TabPane } = Tabs;


class AllClientScreensOfContractor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
    
  }

  render() {
    let is_EN = localStorage.getItem('lang') === 'EN';
    return (
        <Row gutter={24} style={{ marginTop: '10px' }}>
          <Col span={23} style={{margin : '0 25px 14px 25px'}}>
            <Tabs defaultActiveKey="1" type="card" size={'Large'}>
              <TabPane tab = {is_EN ? 'Assigned' : 'تعيين' } key="1">
                <ClientsAssignedCont {...this.props} />
              </TabPane>
              <TabPane tab = {is_EN ? 'Send Requests' : 'ارسل طلبات' } key="2">
                <ContractorClientRequest {...this.props} />
              </TabPane>
              <TabPane tab = {is_EN ? 'Received Requests' : 'الطلبات الواردة' } key="3">
                <ApproveClientRequest {...this.props} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
    )
  }
}

export default AllClientScreensOfContractor;
