import React from 'react'
import { /*Table,*/ Row, Col, Form, Icon, Button, InputNumber, Table, Select, message} from 'antd'
import axios from 'axios'
import API_URL from '../../constants/index';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class HallServices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hallServicesList: false,
      data: [],
      service_types: [],
      parameters: [],
      service_contractor: [],
    }
    // console.log(props)
    this.lang = props.language._RESERVATION.UPDATE.SERVICES.HALLSERVICE

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.SERVICECONTRACTOR}`,
        dataIndex: 'service_contractor_name',
        width: '20%',
        align : 'left',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.SERVICECONTRACTOR}`,
        dataIndex: 'service_contractor_name_ar',
        width: '20%',
        align : 'right',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.SERVICETYPE}`,
        dataIndex: 'new_service_type_name',
        width: '25%',
        align : 'left',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.SERVICETYPE}`,
        dataIndex: 'new_service_type_name_ar',
        width: '25%',
        align : 'right',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.AMOUNT}`,
        dataIndex: 'amount',
        width: '20%',
        align : 'center',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.AMOUNT}`,
        dataIndex: 'amount',
        width: '20%',
        align : 'right',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        width: '20%',
        align : 'center',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        width: '20%',
        align : 'right',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      },
      // {
      //   title: `${this.lang.SETUP.LABELS.ACTION}`,
      //   key: 'last_name',
      //   width: '20%',
      //   render: (text, record) => (
      //     <span>
      //       <Button type="primary" onClick={this.deleteDoc.bind(this, record)}>{this.lang.SETUP.BUTTON.DELETE}</Button>
      //     </span>
      //   ),
      // }
    ]
  }

  getContractors = (val) => {
    let  headers = {
      'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
      }

    axios.get(`${complete_URL}/contractors/fetch_by_service?type=${val}`, {headers})
      .then((res) => {
        if (res.data.success) {
          this.setState({ service_contractor: res.data.data })
        } else {
          message.error(res.data.message)
        }
      })
      .catch(err => { message.error(err.message) })
  }

  
  handlePriceNavigation = () => { }

  reload = () => {
    this.props.reloadPage();
  }

  handleSubmitHallDocument = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.user_id = localStorage.getItem('user_id')
        values.reservation_id = this.props.reservationId
        values.hall = this.props.hallId
        let  headers = {
          'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
          'Content-Type': 'application/json' 
          }    
        axios.post(`${complete_URL}/hallservices/add`, values,  {headers})
          .then(result => {
            if (result.data.success) {
              message.success(result.data.message)
              // console.log(result)
              this.reload();
              this.fetch()
            } else {
              message.error(result.data.message)
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  componentDidMount() {
    this.fetch()
  }
//here to be updated by new service type
  fetch = () => {
    let  headers = {
      'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
      }
      const client_id = localStorage.getItem('client_id');
      this.getServiceTypes(client_id);  

    axios.get(`${complete_URL}/parameters/`,{headers})
      .then(result => {
        // console.log(result.data.data[0])
        this.setState({ parameters: result.data.data[0] })
      })
      .catch(err => {  console.log(err) })

    axios.get(`${complete_URL}/hallservices/by_reservations/${this.props.reservationId}`,{headers})
      .then(res => {
        setTimeout(() => {
          this.setState({ data: res.data.data }/*, () => {
            console.log(this.state.data)
          }*/)
        })
      })
      .catch(err => { console.log(err) })
  }
  getServiceTypes = (client_id)=>{
    let  headers = {
      'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
      }
    axios.get(`${complete_URL}/servicetypes/by_client/${client_id}`, {headers})
      .then(res => { // console.log('service_type->',res)
        this.setState({ service_types: res.data.data })
      })
      .catch(err => {  console.log('Issue while fetching new service types ->',err) })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row>
        <Col span={4}>
          <Button type="danger" title="back" onClick={() => { this.setState({ hallServicesList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ textAlign: 'center', fontSize: '18px' }}>{this.lang.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit" title="save" style={{ float: "right" }}><Icon type="save" theme="filled" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.CustomRow2 = <Row id="clientTableHeader">
        <Col span={9}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.LABELS.TITLE}</p></Col>
        <Col span={13}>
          {
            this.props.status !== 'canceled' &&
            <Button type="primary" style={{ float: 'right' }} onClick={() => { return false /*this.setState({ hallServicesList: true })*/ }}>{this.lang.SETUP.BUTTON.ADD}</Button>
          }
        </Col>
        <Col span={2}></Col>
      </Row>

      this.CustomRow3 = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ hallServicesList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}> Hall Service Update</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit" title="save" style={{ float: "right" }}><Icon type="save" theme="filled" /></Button>
          </Form.Item>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ textAlign: 'center', fontSize: '18px' }}>{this.lang.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Button type="danger" title="back" onClick={() => { this.setState({ hallServicesList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
      </Row>

      this.CustomRow2 = <Row id="clientTableHeader">
        <Col span={13}>
          {
            this.props.status !== 'canceled' &&
            <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ hallServicesList: true }) }}>{this.lang.SETUP.BUTTON.ADD}</Button>
          }
        </Col>
        <Col span={9}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.LABELS.TITLE}</p></Col>
        <Col span={2}></Col>
      </Row>

      this.CustomRow3 = <Row>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}> Hall Service Update</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ hallServicesList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
      </Row>
    }
  }
  deleteDoc = (record) => {
    let  headers = {
      'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
      }

    axios.delete(`${complete_URL}/hallservices/${record.id}`, {headers})
      .then(res => {
        if (res.data.success) {
          message.success(res.data.message)
          this.reload();
          this.fetch()
        } else {
          message.error(res.data.message)
        }
      })
      .catch(err => {
        message.error(err.message)
      })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <React.Fragment>
        {
          this.state.hallServicesList === true ?
            <Form onSubmit={this.handleSubmitHallDocument} style={{ marginTop: '20px' }}>
              {this.CustomRow}
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.SERVICETYPE}>
                    {getFieldDecorator('new_service_type', {
                      rules: [{ required: true, message: 'Please Select Service Type!' }],
                    })(
                      <Select onChange={this.getContractors}>
                        {this.state.service_types.map(item => (
                          <Select.Option value={item.id} key={item.id}>
                            {
                              (localStorage.getItem('lang') === 'EN') ?
                                item.name
                                :
                                item.name_ar
                            }
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.SERVICECONTRACTOR}>
                    {getFieldDecorator('service_contractor', {
                      rules: [{ required: true, message: '' }],
                    })(
                      <Select>
                        {this.state.service_contractor.map(item => (
                          <Select.Option value={item.id} key={item.id}>
                            {
                              (localStorage.getItem('lang') === 'EN') ?
                                item.name
                                :
                                item.name_ar
                            }
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.AMOUNT}>
                    {getFieldDecorator('amount', {
                      rules: [{ required: true, message: 'Please insert the amount!' }],
                    })(
                      <InputNumber placeholder='Amount' style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.COMMISSIONAMOUNT}>
                    {getFieldDecorator('commission_amount', {
                      rules: [{ required: true, message: 'Please insert the commission amount!' }],
                    })(
                      <InputNumber placeholder='Commission Amount' style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            : this.state.hallServicesList === false ?
              //View Hall Service Screen
              <div>
                {this.CustomRow2}
                <Row id="clientsTable">
                  {/* <Col span={2}></Col> */}
                  <Col span={23}>
                    <Table columns={this.columns} rowKey="id" dataSource={this.state.data} style={{ marginTop: '10px' }} />,
                    </Col>
                </Row>

              </div>
              //Update Hall Service Screen
              :
              <Form onSubmit={this.handleSubmitHallDocumentUpdate.bind(this)} style={{ marginTop: '20px' }}>
                {this.CustomRow3}
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item label="Price">
                      {getFieldDecorator('rice', {
                        rules: [{ required: true, message: 'Please input Hall Discount!' }],
                      })(
                        <InputNumber placeholder="Enter Hall Discount" style={{ width: '100%' }} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Umit Of Price">
                      {getFieldDecorator('uop', {
                        rules: [{ required: true, message: 'Please Select Unit of Measure' }],
                      })(
                        <input />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
        }
      </React.Fragment>)
  }
}

export default HallServices = Form.create({ name: 'normal_login' })(HallServices);
