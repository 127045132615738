import React from 'react'
import { Radio, Row, Col, Form, Button, InputNumber, Input } from 'antd'

class CreateCustomer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          createCustomerOpen: false,
          dir : 'ltr', 
          display: 'block',
          male : ''
        }
        this.lang = props.language._CUSTOMER.CREATE;
        this.msg = props.language._CUSTOMER.MESSAGE;
    }

    createCustomer = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if(!err){
          this.props.handleOk(values);
        }
      })
    }

    setComponentDirection =()=>{
      if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
      }
      else {
        this.setState({dir : 'rtl', display: 'flex'})
      }
    }

    componentDidMount(){ 
      if(this.props.gender){
        if(this.props.gender.length > 0){
          let male_obj = this.props.gender.filter(g=>g.name ==='Male');
          if (male_obj) {
            this.setState({male : male_obj[0].id});
          }          
        }
      }
      this.setComponentDirection();
    }//End of Method
      
    render(){
        const { getFieldDecorator } = this.props.form;
        let {dir, display} = this.state;
        return(
            <Form onSubmit={this.createCustomer}>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                    <Form.Item label= {this.lang.LABELS.NAME}  >
                      {getFieldDecorator('name', {
                        rules: [{ required: true, message: this.msg.INSERTNAMEERROR}],
                      })(
                        <Input placeholder= {this.lang.LABELS.NAME} />
                      )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label= {this.lang.LABELS.NAMEAR} >
                      {getFieldDecorator('name_ar', {
                        rules: [{ required: false, message: this.msg.INSERTNAMEARERROR }],
                      })(
                        <Input placeholder= {this.lang.LABELS.NAMEAR} />
                      )}
                    </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                    <Form.Item label= {this.lang.LABELS.PHONENO} >
                      {getFieldDecorator('ph_num', {
                        rules: [{ required: true, message: this.msg.INSERTPHONENOERROR }],
                      })(
                        <InputNumber style={{width:'100%'}} placeholder= {this.lang.LABELS.PHONENO} />
                      )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label= {this.lang.LABELS.IDCARDNO} >
                      {getFieldDecorator('id_card', {
                        rules: [{ required: true, message: this.msg.INSERTIDNOERROR }],
                      })(
                        <InputNumber style={{width:'100%'}} placeholder= {this.lang.LABELS.IDCARDNO} />
                      )}
                    </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label= {this.lang.LABELS.GENDER} >
                    {getFieldDecorator('gender', {
                      initialValue: this.state.male,
                      rules: [{ required: false, message: this.msg.SELECTGENDERERROR }],
                    })(
                      <Radio.Group>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            this.props.gender.length > 0 && this.props.gender.map(item => (
                              <Radio placeholder = {this.lang.LABELS.GENDER} value={item.id} key={item.id}>{item.name}</Radio>
                            ))
                            :
                            this.props.gender.length > 0 && this.props.gender.map(item => (
                              <Radio placeholder = {this.lang.LABELS.GENDER} value={item.id} key={item.id}>{item.name_ar}</Radio>
                            ))
                        }
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label= {this.lang.LABELS.EMAIL} >
                    {getFieldDecorator('email', {
                      rules: [{ required: false, message: this.msg.INSERTEMAILERROR }],
                    })(
                      <Input  type="email" style={{width:'100%'}} placeholder= {this.lang.LABELS.EMAIL}  />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                    <Button block primary className="save" type="" onClick={this.props.handleCancel} title="save"  style={{background:"#FF1E04",borderColor:"#FF1E04", color:"#fff" }}>{this.lang.BUTTON.EXIT}</Button>
                </Col>
                <Col span={12}>
                  <Button block primary className="save" type="" htmlType="submit" title="save"  style={{background:"#FD5C7D",borderColor:"#FD5C7D", color:"#fff" }}>{this.lang.BUTTON.SAVE}</Button>
                </Col>
              </Row>
            </Form>
        )
    }
}

export default CreateCustomer = Form.create({ name: 'customer_form' })(CreateCustomer);