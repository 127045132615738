import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import API_URL from '../../constants'
import getHeadersForHttpReq from '../../constants/token';

const { Item } = Menu
const { Sider } = Layout;
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class SideMenu extends Component {
  state = {
    theme: 'dark',
    current: '',
    data: [],
    img: ''
  }

  LogOut = () => {
    this.props.history.push('/')
  }

  changeTheme = (value) => {
    this.setState({ theme: value ? 'dark' : 'light', });
  }

  handleClick = (e) => {
    this.setState({ current: e.key, img: '' });
  }

  componentDidMount() {
    const id = localStorage.getItem('user_id');
    const is_contr = localStorage.getItem("is_contr");
    let lang = '';
    localStorage.getItem('lang') === 'EN' ? (lang = 'en'): (lang = 'ar')
    let  headers =  getHeadersForHttpReq();
    let options = { url:`${complete_URL}/users/getScreens_by_user`,
                    method:"POST", data: { id, lang, is_contr},headers
    }

    axios(options)
      .then(res => { //console.log('screenList',res.data.data)
        localStorage.setItem('screenList', JSON.stringify(res.data.data))
        
        this.setState({ data: Object.entries(res.data.data), img: localStorage.getItem('client_logo_image') });
      })
      .catch(err => { console.log('Error occured while fetching User assigned screens',err) })
    // axios.get(`${complete_URL}/images/${ localStorage.getItem('logo_img')}`, { responseType: 'blob' })
    // .then(res => {
    //     var reader = new FileReader();
    //     let a
    //     reader.readAsDataURL(res.data);
    //     reader.onloadend = () => {
    //         this.setState({img:reader.result}, () => console.log(this.state.img))
    //     }  
    // })
    // .catch(err => console.log(err))
  }
  
  render() {
    let contFlag = localStorage.getItem('is_contr') == 1;
    let logoStyle = {width: '50px', height: '50px', borderRadius: '0', margin: '20px auto 13px auto',
                      display: 'table'};
    let logo_img =  localStorage.getItem('client_logo_image') || '/dummy_image.jpg';
    let client_name = !contFlag ? localStorage.getItem('client_name') : ''                 
    return (
      <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
        <Link to={!contFlag ? "/dashboard" : "/dashboard/contpackages" }>
          <h1 style={{ color: '#fff', textAlign: 'center'
            //margin: !this.props.collapsed ? "25px 55px" : "25px 25px" 
          }}>{!this.props.collapsed ?
            <span>
              <img alt="Logo here" style={logoStyle} src={logo_img} />
              <span style={{ marginLeft: '15px' }}>{client_name}</span>
            </span>
            :
            client_name}</h1>
        </Link>
        <Menu
          theme={this.state.theme}
          onClick={this.handleClick}
          style={{ marginTop: '25px' }}
          mode="inline"
          selectedKeys={[this.state.current]}
        >
          {
            (localStorage.getItem('lang') === 'EN') ?
              this.state.data.map(i => (
                <Menu.SubMenu key={i[0]} title={<span><Icon type="setting" /><span>{i[0]} </span></span>}>
                  {i[1].map((item, i) => (
                    <Item key={item.screen_id}>
                      <Link to={"/dashboard" + item.url}>
                        {/* {this.props.collapsed ? */}
                        <Icon type="dashboard" theme="outlined" />
                        <span>
                          {item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}
                        </span>
                        {/* : */}
                        {/* } */}
                      </Link>
                    </Item>
                  ))}
                </Menu.SubMenu>
              ))
              :
              this.state.data.map(i => (
                <Menu.SubMenu key={i[0]} title={<span><Icon type="setting" /><span  style={{paddingRight : '4%'}}>{i[0]} </span></span>}>
                  { i[1].map((item, i) => (
                    <Item key={item.screen_id}>
                      <Link to={"/dashboard" + item.url}>
                        {/* {this.props.collapsed ? */}
                        <Icon type="dashboard" theme="outlined" />
                        <span style={{paddingRight : '4%'}}>
                          {item.name_ar}
                        </span>
                        {/* : */}
                        {/* } */}
                      </Link>
                    </Item>
                    )) }
                </Menu.SubMenu>
              ))
          }
        </Menu>
      </Sider>
    );
  }
}

export default withRouter(SideMenu);