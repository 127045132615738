import React, { Component } from 'react';
import { Row, Col, Table, Button, message, Input } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios'
import API_URL from '../../constants'
const pt = localStorage.getItem('pt');
const Search = Input.Search;

class Clients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      pagination: {},
      page: 1,
      size: 5,
      searchValues: {
        name: '',
        description: '',
        email: '',
        address: '',
        iscommission: 1,
        commission:''
      }
    }
    this.lang = props.language._CLIENT_ACCOUNT.SETUP;
    this.EN_FLAG = localStorage.getItem('lang') === 'EN';
    this.columns = [
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={`Search ${this.lang.TABLEHEADERS.NAME}`}
              // onSearch={value => console.log(value)}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),

        dataIndex: 'name',
        key: 'name',
        width: '20%',
        align :  this.EN_FLAG ? 'left' : 'right',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      {
        title: (
          <span>{this.lang.TABLEHEADERS.DESCRIPTION}
            <Search
              placeholder={`Search ${this.lang.TABLEHEADERS.DESCRIPTION}`}
              // onSearch={value => console.log(value)}
              onChange={this.handleChangeSearchValues("description")}

            />
          </span>
        ),
        dataIndex: 'description',
        key: 'description',
        width: '20%',
        align :  this.EN_FLAG ? 'left' : 'right',
        sorter: (a, b) => this.compareByAlph(a.description, b.description),
      },
      {
        title: (
          <span>{this.lang.TABLEHEADERS.EMAIL}
            <Search
              placeholder={`${this.lang.TABLEHEADERS.EMAIL}`}
              // onSearch={value => console.log(value)}
              onChange={this.handleChangeSearchValues("email")}

            />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        width: '20%',
        align :  this.EN_FLAG ? 'left' : 'right',
        sorter: (a, b) => this.compareByAlph(a.email, b.email),
      },
      {
        title: (
          <span>{this.lang.TABLEHEADERS.COMM}
            <Search
              placeholder={`${this.lang.TABLEHEADERS.COMM}`}
              // onSearch={value => console.log(value)}
              onChange={this.handleChangeSearchValues("commission")}

            />
          </span>
        ),
        dataIndex: 'commission',
        key: 'commission',
        width: '20%',
        align :  this.EN_FLAG ? 'left' : 'right',
        render:(text) => text+"%",
        sorter: (a, b) => this.compareByAlph(a.commission, b.commission),
      },
    ];
  }

  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    localStorage.setItem('clientaccount', record.id);
    this.props.history.push('/dashboard/clientaccountdetails');
  }

  handleChangePagination = (pagination, filters, sorter) => {
    let pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      page: pager.current,
      pagination: pager,
    }, () => {
      this.fetch();
    })
  }

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }

  componentDidMount() {
    this.fetch();
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
        <Col span={13}>
          <Link to="/dashboard/addclient">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={13}>
          <Link to="/dashboard/addclient">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
      </Row>
    }
  }

  fetch = () => {
    axios.get(`${API_URL}${pt}/clients/table/?page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`)
      .then(res => {
        let pagination = { ...this.state.pagination };
        pagination.total = res.data.totalElements;
        pagination.pageSize = parseInt(res.data.size);
        this.setState({
          data: res.data,
          pagination: pagination,
        })
      })
      .catch(err => {  console.log(err) })
  }

  render() {
    return (
        <>
          {this.CustomRow}
          <Row id="clientsTable">
            <Col span={2}></Col>
            <Col span={20}>
              <Table
                columns={this.columns}
                rowKey="id"
                dataSource={this.state.data.selectedElements}
                onChange={this.handleChangePagination}
                pagination={this.state.pagination}
              />
            </Col>
          </Row>
        </>
    )
  }
}
export default Clients;