import React, { Component } from 'react';
import { Row, Col, Table, Button, message, Input, DatePicker } from 'antd'
// import moment from 'moment'
import { Link } from 'react-router-dom'
import axios from 'axios'
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Offers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // data: {},
      data: [],
      pagination: {},
      page: 1,
      size: 5,
      // sortField:"NAME",
			sortField:"updated_at",
      sortOrder:"ascend",
      searchValues: {
        name: '',
        name_ar: '',
        description: '',
        discount:'',
        from_date: '',
        to_date: '',
        orderBy : '',
        column_name :'NAME'
      }
    }
    this.lang = props.language._OFFER.SETUP;
    // this.lang = props.language._NOTIFICATIONS.SETUP

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'NAME',
        key: 'NAME',
        width: '12.5%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.NAME, b.NAME),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      } :
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'NAME',
        key: 'NAME',
        width: '12.5%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.NAME, b.NAME),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      } ,
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAMEAR}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAMEAR}
              onChange={this.handleChangeSearchValues("name_ar")}
            />
          </span>
        ),
        dataIndex: 'NAME_AR',
        key: 'NAME_AR',
        width: '12.5%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.NAME_AR, b.NAME_AR),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAMEAR}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAMEAR}
              onChange={this.handleChangeSearchValues("name_ar")}
            />
          </span>
        ),
        dataIndex: 'NAME_AR',
        key: 'NAME_AR',
        width: '12.5%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.NAME_AR, b.NAME_AR),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.DESCRIPTION}
            <Search
              placeholder= {this.lang.TABLEHEADERS.DESCRIPTION}
              onChange={this.handleChangeSearchValues("description")}
            />
          </span>
        ),
        dataIndex: 'DESCRIPTION',
        key: 'DESCRIPTION',
        width: '12.5%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.DESCRIPTION, b.DESCRIPTION),
      } :
      {
        title: (
          <span>{this.lang.TABLEHEADERS.DESCRIPTION}
            <Search
              placeholder= {this.lang.TABLEHEADERS.DESCRIPTION}
              onChange={this.handleChangeSearchValues("description")}
            />
          </span>
        ),
        dataIndex: 'DESCRIPTION',
        key: 'DESCRIPTION',
        width: '12.5%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.DESCRIPTION, b.DESCRIPTION),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span> {this.lang.TABLEHEADERS.DISCOUNTPERCENT}
            <Search
              placeholder= {this.lang.TABLEHEADERS.DISCOUNTPERCENT}
              onChange={this.handleChangeSearchValues("discount")}
            />
          </span>
        ),
        dataIndex: 'DISCOUNT_PERCENT',
        key: 'DISCOUNT_PERCENT',
        width: '12.5%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.DISCOUNT_PERCENT, b.DISCOUNT_PERCENT),
      } : 
      {
        title: (
          <span> {this.lang.TABLEHEADERS.DISCOUNTPERCENT}
            <Search
              placeholder= {this.lang.TABLEHEADERS.DISCOUNTPERCENT}
              onChange={this.handleChangeSearchValues("discount")}
            />
          </span>
        ),
        dataIndex: 'DISCOUNT_PERCENT',
        key: 'DISCOUNT_PERCENT',
        width: '12.5%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.DISCOUNT_PERCENT, b.DISCOUNT_PERCENT),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span> {this.lang.TABLEHEADERS.FROMDATE}
            <DatePicker
              onChange={this.onChangeDate("from_date")}
              format="YYYY-MM-DD"
              placeholder = {this.lang.TABLEHEADERS.FROMDATE}
            />
          </span>
        ),
        dataIndex: 'FROM_DATE',
        key: 'FROM_DATE',
        width: '12.5%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.FROM_DATE, b.FROM_DATE),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: (
          <span> {this.lang.TABLEHEADERS.FROMDATE}
            <DatePicker
              onChange={this.onChangeDate("from_date")}
              format="YYYY-MM-DD"
              placeholder = {this.lang.TABLEHEADERS.FROMDATE}
            />
          </span>
        ),
        dataIndex: 'FROM_DATE',
        key: 'FROM_DATE',
        width: '12.5%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.FROM_DATE, b.FROM_DATE),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span> {this.lang.TABLEHEADERS.TODATE}
            <DatePicker
              onChange={this.onChangeDate("to_date")}
              format="YYYY-MM-DD"
              placeholder= {this.lang.TABLEHEADERS.TODATE}
            />
          </span>
        ),
        dataIndex: 'TO_DATE',
        key: 'TO_DATE',
        width: '12.5%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.TO_DATE, b.TO_DATE),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      } :
      {
        title: (
          <span> {this.lang.TABLEHEADERS.TODATE}
            <DatePicker
              onChange={this.onChangeDate("to_date")}
              format="YYYY-MM-DD"
              placeholder= {this.lang.TABLEHEADERS.TODATE}
            />
          </span>
        ),
        dataIndex: 'TO_DATE',
        key: 'TO_DATE',
        width: '12.5%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.TO_DATE, b.TO_DATE),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '8%',
        align : 'center'
      }
    ];
  }


  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    localStorage.setItem('offerId', record.ID)
    this.props.history.push('/dashboard/offerupdate')
  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      pageNumber: pagination.current
    });

    if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
      if (this.state.pageNumber  !== pager.current) {
        this.fetch({
          page: pagination.current,
        });
      }
    } else {
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }

    // if(document.getElementsByClassName('ant-calendar-date').length < 1){
    //   let pager = { ...this.state.pagination };
    //   pager.current = pagination.current;

    //   let searchValues = { ...this.state.searchValues}
    //   searchValues.column_name = sorter.field
    //   searchValues.orderBy = sorter.order

    //   this.setState({
    //     page: pager.current,
    //     pagination: pager,
    //     searchValues    
    //   }, () => {
    //     // console.log('column name in offer screen ->',searchValues.column_name);
    //     this.fetch();
    //   })
    // }
  }

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }

  onChangeDate = (type) => (date, dateString) => {
    if (type === 'from_date') {
      let temp = { ...this.state.searchValues };
      temp.from_date = dateString;
      this.setState({
        searchValues: temp
      }, () => {
        this.fetch();
      })
    } else if (type === 'to_date') {
      let temp2 = { ...this.state.searchValues };
      temp2.to_date = dateString;
      this.setState({
        searchValues: temp2
      }, () => {
        this.fetch();
      })
    }
  };

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();
    let authOptions = {
      method: "POST",
      url: complete_URL+'/offers/deleteOffer',
      data: {
        id:record.ID
      },
      headers
    }
    axios(authOptions)
      .then(res => {
        if(res.data.success){
          this.fetch()
          message.success(res.data.message)
        }else{
          message.error(res.data.message)
        }
      })
      .catch(err => {
        message.error(err.message)
      })
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = (params={}) => {
    const client_id = localStorage.getItem('client_id');
    const headers = getHeadersForHttpReq();
      let page,sortOrder ;
      if (params.page) { page = params.page - 1 }
      else { page = 0;}

      if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
      else { sortOrder = 'DESC' }
      
      let data ={
        client_id : client_id,
        name: this.state.searchValues.name || '',
        name_ar: this.state.searchValues.name_ar || '',
        description: this.state.searchValues.description || '',
        discount:this.state.searchValues.discount || '',
        from_date: this.state.searchValues.from_date || '',
        to_date: this.state.searchValues.to_date || '',
        page,
        sortColumn: params.sortField || this.state.sortField ,
        sortOrder,
        size:this.state.size,
        orderBy: '',
        column_name: 'name'
      }
      // console.log('Sending data to api ->',data);
      axios.post(`${complete_URL}/offers/pagination_table`,data, {headers})
      .then(response => {
        // console.log('response of Offers new pagination ->',response.data.data)
        let pagination = {};
        Object.assign(pagination,{
          ...this.state.pagination,
          total : response.data.totalElements,
          current : page +1,
          pageSize : this.state.size
        });

        // let pagination = { ...this.state.pagination };
        // pagination.total = response.data.totalElements;
        // pagination.current = page +1;
        // pagination.pageSize = this.state.size;
        this.setState({ data: response.data.data, pagination });
        // console.log('Expense data-> ',response);
      })
      .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })
    
    // let authOptions = {
    //   method: "GET",
    //   url: complete_URL+'/offers/offers_by_client',
    //   params: {
    //     page:this.state.page,
    //     size:this.state.size,
    //     client_id:localStorage.getItem('client_id'),
    //     searchValues:this.state.searchValues
    //   },
    //   headers
    // }
    // axios(authOptions)
    //   .then(res => {
    //     let pagination = { ...this.state.pagination };
    //     pagination.total = res.data.totalElements;
    //     pagination.pageSize = parseInt(res.data.size);
    //     this.setState({
    //       data: res.data,
    //       pagination: pagination,
    //     })
        
    //   })
    //   .catch(err => {
    //     message.error(err.message)
    //   })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.OfferSetupHeader = <Row id="clientTableHeader" gutter={24}>
        <Col span={6}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
        <Col span={16}>
          <Link to="/dashboard/offeradd">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.OfferSetupHeader = <Row id="clientTableHeader" gutter={24}>
        <Col span={6}>
          <Link to="/dashboard/offeradd">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
        <Col span={17}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
      </Row>
    }
  }

  render() {
    return ( 
        <>
          {this.OfferSetupHeader}
          <Row id="clientsTable">
            {/* <Col span={2}></Col>
            <Col span={20}> */}
            <Col span={23} style={{margin : '0 25px 14px 25px'}}>
              <Table
                columns={this.columns}
                rowKey="ID"
                // dataSource={this.state.data.selectedElements}
                dataSource={this.state.data}
                onChange={this.handleChangePagination}
                pagination={this.state.pagination}
              />
            </Col>
          </Row>
        </> 
    )
  }
}
export default Offers;