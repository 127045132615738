import React, { Component } from 'react';
import { Row, Col,Table, message, Button, Input, Icon, Spin, Modal } from 'antd'
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';

if (localStorage.getItem('lang') === 'AR') {
    require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Approve_Client_Request extends Component{
    constructor(props) {
      super(props)
      this.state = {
          data: [],
          pagination: {},
          page: 1,
          size: 5,
          sortField:"name",
          sortOrder:"ascend",
          searchValues: {
            name: '',
            name_ar :'',
            description :'', 
            email  :'',
            address :''
          },
          selected_client : {},
          client_doc : '00000000-0000-00',
          packagesData : [],
          dir : 'ltr', 
          display: 'block',
          modelVisible : false,
          spin : false,
          spin_msg : ''
      }

      this.lang = props.language._CONTRACTOR_CLIENT_REQ.SETUP;
      this.msg = props.language._CONTRACTOR_CLIENT_REQ.MESSAGE;
  
      
      this.columns = [
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.NAME}
              <Search
                placeholder={this.lang.TABLEHEADERS.NAME}
                onChange={this.handleChangeSearchValues("name")}
              />
            </span>
          ),
          dataIndex: 'name',
          key: 'name',
          width: '17%',
          align : 'left',
          sorter: (a, b) => this.compareByAlph(a.name, b.name),
        }:
        {
          title: (
            <span>{this.lang.TABLEHEADERS.NAME}
              <Search
                placeholder={this.lang.TABLEHEADERS.NAME}
                onChange={this.handleChangeSearchValues("name")}
              />
            </span>
          ),
          dataIndex: 'name',
          key: 'name',
          width: '17%',
          align : 'right',
          sorter: (a, b) => this.compareByAlph(a.name, b.name),
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.DESCRIPTION}
              <Search
                placeholder={this.lang.TABLEHEADERS.DESCRIPTION}
                onChange={this.handleChangeSearchValues("description")}
              />
            </span>
          ),
          dataIndex: 'description',
          key: 'description',
          width: '17%',
          align : 'left',
          sorter: (a, b) => this.compareByAlph(a.description, b.description),
        }:
        {
          title: (
            <span>{this.lang.TABLEHEADERS.DESCRIPTION}
              <Search
                placeholder={this.lang.TABLEHEADERS.DESCRIPTION}
                onChange={this.handleChangeSearchValues("description")}
              />
            </span>
          ),
          dataIndex: 'description',
          key: 'description',
          width: '17%',
          align : 'right',
          sorter: (a, b) => this.compareByAlph(a.description, b.description),
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.EMAIL}
              <Search
                placeholder={this.lang.TABLEHEADERS.EMAIL}
                onChange={this.handleChangeSearchValues("email")}
              />
            </span>
          ),
          dataIndex: 'email',
          key: 'email',
          width: '23%',
          align : 'left',
          sorter: (a, b) => this.compareByAlph(a.email, b.email),
        }:
        {
          title: (
            <span>{this.lang.TABLEHEADERS.EMAIL}
              <Search
                placeholder={this.lang.TABLEHEADERS.EMAIL}
                onChange={this.handleChangeSearchValues("email")}
              />
            </span>
          ),
          dataIndex: 'email',
          key: 'email',
          width: '23%',
          align : 'right',
          sorter: (a, b) => this.compareByAlph(a.email, b.email),
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.ADDRESS}
              <Search
                placeholder={this.lang.TABLEHEADERS.ADDRESS}
                onChange={this.handleChangeSearchValues("address")}
              />
            </span>
          ),
          dataIndex: 'address',
          key: 'address',
          width: '17%',
          align : 'left',
          sorter: (a, b) => this.compareByAlph(a.address, b.address),
        }:
        {
          title: (
            <span>{this.lang.TABLEHEADERS.ADDRESS}
              <Search
                placeholder={this.lang.TABLEHEADERS.ADDRESS}
                onChange={this.handleChangeSearchValues("address")}
              />
            </span>
          ),
          dataIndex: 'address',
          key: 'address',
          width: '17%',
          align : 'right',
          sorter: (a, b) => this.compareByAlph(a.address, b.address),
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.STATUS}
              <Search
                placeholder={this.lang.TABLEHEADERS.STATUS}
                onChange={this.handleChangeSearchValues("status")}
              />
            </span>
          ),
          dataIndex: 'status',
          key: 'status',
          width: '10%',
          align : 'left',
          sorter: (a, b) => this.compareByAlph(a.status, b.status),
          render : (text, record) =>  record.ccr_id ? <p>{text}</p> : <p>{'--'}</p> 
        }:
        {
          title: (
            <span>{this.lang.TABLEHEADERS.STATUS}
              <Search
                placeholder={this.lang.TABLEHEADERS.STATUS}
                onChange={this.handleChangeSearchValues("status")}
              />
            </span>
          ),
          dataIndex: 'status',
          key: 'status',
          width: '10%',
          align : 'right',
          sorter: (a, b) => this.compareByAlph(a.status, b.status),
          render : (text, record) =>  record.ccr_id ? <p>{text}</p> : <p>{'--'}</p> 
        },
        {
          title: `${this.lang.TABLEHEADERS.ACTION}`,
          render: (text, record) => (
            <span>
              <Button type="primary" onClick={this.displayClientDetailsModal.bind(this, record)}>{this.lang.BUTTON.DETAILS}</Button>
            </span>
          ),
          key: 'pkg_btn',
          width : '7%',
          align:'center'
        },
        {
          title: '.',
          render: (text, record) => (
            <span>
              {
                record.status === "Approved"?
                <Button type="primary" disabled = {true}>{this.lang.BUTTON.APPROVED}</Button>
                :
                <Button type="primary" onClick={this.handleApproveReq.bind(this, record)}>{this.lang.BUTTON.APPROVEREQ}</Button>
              }
            </span>
          ),
          width: '11%',
          key: 'last_name',
          align:'center'
        }
      ];

    }// End of Approve_Client_Request

    setComponentDirection=()=>{
      if(localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
      }
      else {
        this.setState({dir : 'rtl', display: 'flex'})
      }
    }

    compareByAlph = (a, b) => {
        if (a > b) { return -1; }
        if (a < b) { return 1; }
        return 0;
    }

    displayClientDetailsModal =(record)=>{
      let cd = record.client_doc ? record.client_doc : '00000000-0000-00';
      localStorage.setItem('clntprof',record.id);
      this.setState({client_doc : cd, selected_client : record, modelVisible : true});
    }

    handleApproveReq = (record)=>{
      this.setState({spin_msg : this.msg.REQAPPROVEBYCONTTIP, spin : true});

      let  headers = getHeadersForHttpReq();
      let data = {
        client_id : record.id,
        user_id : localStorage.getItem('user_id'),
        cont_id : JSON.parse(localStorage.getItem('updateContractor')).id,
        ccr_id : record.ccr_id,
        req_by : 1
      }    
      axios.post(`${complete_URL}/cont_clients_req/approve_cont_client_req`, data, {headers})
      .then(res => { //console.log('cont req res.data->',res.data);
        if (res.data.success) {
          this.setState({modelVisible : false, spin : false})
          message.success(this.msg.REQAPPROVEBYCONTSUCCESS);
          this.fetch();
        } 
        else {
          this.setState({modelVisible : false, spin : false},()=>message.error(this.msg.REQAPPROVEBYCONTFAILURE,3));
        }
      })
      .catch(err => {
        this.setState({modelVisible : false, spin : false},()=>message.error(this.msg.REQAPPROVEBYCONTFAILURE,3));
        console.log('Error occured while approving the Client/Banquet Request->',err)
      })
    }

    handleChangePagination = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({ pagination: pager, pageNumber: pagination.current });
    
        if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
          if (this.state.pageNumber  !== pager.current) {
            this.fetch({ page: pagination.current, });
          }
        } 
        else {
          this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
          });
        }
    }

    handleChangeSearchValues = (prop) => (e) => {
        let search = { ...this.state.searchValues };
        search[`${prop}`] = e.target.value;
        this.setState({
          searchValues: search
        }, () => {
          this.fetch();
        });
    }
  
    fetch = (params={}) => {
      const cont_id = JSON.parse(localStorage.getItem('updateContractor')).id;
      let headers = getHeadersForHttpReq();
      let page,sortOrder ;
      if (params.page) { page = params.page - 1 }
      else { page = 0;}

      if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
      else if (params.sortOrder == 'descend') { sortOrder = 'DESC' }
      else { sortOrder = 'ASC' }
      
      let data ={
        cont_id : cont_id,
        name: this.state.searchValues.name || '',
        name_ar: this.state.searchValues.name_ar || '',
        description : this.state.searchValues.description || '',
        email : this.state.searchValues.email || '',
        address : this.state.searchValues.address || '',
        page,
        sortColumn: params.sortField || this.state.sortField ,
        sortOrder,
        size:this.state.size,
        orderBy: '',
        column_name: 'no'
      }
        // console.log('Sending data to api ->',data);
        axios.post(`${complete_URL}/cont_clients_req/get_client_req_pagination_table`,data, {headers})
        .then(response => {
           //console.log('response of cont_clients_re pagination response.data.data ->',response.data.data)
          let pagination = {};
          Object.assign(pagination,{
            ...this.state.pagination,
            total : response.data.totalElements,
            current : page +1,
            pageSize : this.state.size
          });
          
          this.setState({ data: response.data.data, pagination })
          // console.log('Expense data-> ',response);
        })
        .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })
      
    }

    componentDidMount(){
        this.fetch();
        this.setComponentDirection();
    }
    
    componentWillMount() {
      if (localStorage.getItem('lang') === 'EN') {
        this.CustomRow = <Row id="clientTableHeader">
          <Col span={1}></Col>
          <Col span={10}><p style={{ textAlign: 'left'}}>{this.lang.TABLEHEADERS.APPROVECLIENTREQTITLE}</p></Col>
          <Col span={12}></Col>
        </Row>
      }
      else {
        this.CustomRow = <Row id="clientTableHeader">
          <Col span={12} > </Col>
          <Col span={10}><p style={{ textAlign: 'right'}}>{this.lang.TABLEHEADERS.APPROVECLIENTREQTITLE}</p></Col>
          <Col span={1}></Col>
        </Row>
      }
    }
    closeModal = ()=>{
      this.setState({modelVisible : false})
    }

    downloadDoc = () => {
      let documentid = this.state.client_doc;
      if (documentid !== '00000000-0000-00') {
        // console.log(documentid)
        axios.get(`${complete_URL}/images/${documentid}`, { responseType: 'blob' })
          .then(results => {
            let doc
            var reader = new FileReader();
            reader.readAsDataURL(results.data);
            reader.onloadend = () => {
              doc = reader.result
              const link = document.createElement('a');
              link.href = doc;
              link.setAttribute('download', `Request-Document.${results.data.type.split('/')[1]}`); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(err => {console.log('Error occured while downloading the Request Document sent by Client/Banquet->',err); })
      }
    }// End of Method


    render() {
      let is_EN = localStorage.getItem('lang') === 'EN';
      let {spin, spin_msg, data, pagination, modelVisible, client_doc,selected_client, dir, display} = this.state;

        return (
            <Spin tip={spin_msg} spinning = {spin}>
              {this.CustomRow}
              <Row id="clientsTable" gutter={24}>
                <Col span={24}>
                  <Table
                    rowKey="id"
                    columns={this.columns}
                    dataSource={data}
                    onChange={this.handleChangePagination}
                    pagination={pagination}
                  /> 
                </Col>
              </Row>
              <Modal centered
                  title={this.lang.BUTTON.DETAILS}
                  visible={modelVisible} 
                  closable = {true}
                  destroyOnClose={true}
                  onCancel = {this.closeModal}
                  width = {'80%'}
                  footer = {false}
                >
                  <Row gutter={24}>
                    <Col span={24}>
                      <Input.TextArea  rows={12} readOnly value={selected_client.client_desc} style={{ width: '100%' }} />
                    </Col>
                  </Row>
                  <br/>
                  <Row gutter={24} dir = {dir} style={{display}}>
                    <Col span={8}>
                    {
                      client_doc !== '00000000-0000-00' ?
                      <a><p style={{fontSize:'15px'}} onClick={this.downloadDoc}>{this.lang.TABLEHEADERS.CLIENTDOC}
                        <Icon type="download" title = {this.lang.TABLEHEADERS.CLIENTDOC} style={{marginLeft:'10px'}} /> </p></a>
                      :<p style={{fontSize:'15px'}} >{this.lang.TABLEHEADERS.NOTDOCATTACHED}</p>
                    }
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}>
                      <Link to='/dashboard/bqt-profile' target="_blank">{this.lang.TABLEHEADERS.PROFILE}</Link>
                    </Col>
                  </Row>
              </Modal>
            </Spin>
        )
    }// End of render
  }//End of Component

  export default Approve_Client_Request;