import "devextreme/dist/css/dx.light.css";
import React from "react";
import axios from "axios";
import DataGrid, {
  Scrolling,
  Sorting,
  LoadPanel,
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Export,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RangeRule,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import "devextreme-react/autocomplete";
import getHeadersForPDFreport from "../../constants/reportHeaders";
import API_URL from "../../constants";
// import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

const pt = localStorage.getItem("pt");
const complete_URL = API_URL + pt;
const client_id = localStorage.getItem("client_id");

class StatisticsV2 extends React.Component {
  constructor(props) {
    super(props);
    this.onExporting = this.onExporting.bind(this);

    this.state = {
      receiptsAmountList: [],
    };
    this.birthDateOptions = { width: "100%" };

    this.phoneOptions = { mask: "+1 (000) 000-0000" };
    this.notesOptions = { height: 140 };
    this.buttonOptions = {
      text: "Get Report",
      type: "success",
      useSubmitBehavior: true,
    };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      fromDate: event.target.fromDate.value,
      toDate: event.target.toDate.value,
    };
    let api_main_part = API_URL.split("/")[2];
    let req_data = {
      api_main_part,
      port: pt,
      client_id,
    };
    if (req_data.client_id) {
      const headers2 = getHeadersForPDFreport();
      Object.assign(req_data, data);

      axios
        .get(`${complete_URL}/finance_reports/GetReceiptsAmount/`, {
          headers: { ...headers2 },
          params: { data: JSON.stringify(req_data) },
        })
        .then((response) => {
          console.log(response.data.data[0]);
          this.setState({ receiptsAmountList: response.data.data[0] });
        })
        .catch((err) => {
          this.setState({ spin: false });
          console.log(err);
        });
    } //End of Finance request
    else {
    }
    event.preventDefault();
  };

  render() {
    let { receiptsAmountList } = this.state;

    return (
      <React.Fragment>
        <form
          action="your-action"
          onSubmit={(event) => this.handleSubmit(event)}
        >
          <Form
            readOnly={false}
            showColonAfterLabel={true}
            showValidationSummary={true}
            validationGroup="customerData"
            labelMode="static"
          >
            <GroupItem cssClass="second-group" colCount={2}>
              <GroupItem>
                <SimpleItem dataField="fromDate" editorType="dxDateBox" />
                <SimpleItem dataField="toDate" editorType="dxDateBox" />
              </GroupItem>
              <GroupItem>
                <ButtonItem
                  width={200}
                  horizontalAlignment="left"
                  buttonOptions={this.buttonOptions}
                />
              </GroupItem>
            </GroupItem>
          </Form>
        </form>
        <DataGrid
          dataSource={receiptsAmountList}
          keyExpr="Date"
          showColumnLines={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          height={440}
          //defaultColumns={columns}
          showBorders={true}
          onExporting={this.onExporting}
        >
          <Summary>
            <TotalItem
              column="Amount"
              summaryType="sum"
              displayFormat="المجموع: {0} عماني ريال"
              valueFormat="decimal"
            />
          </Summary>
          <Pager allowedPageSizes={10} showPageSizeSelector={true} />
          <Paging defaultPageSize={10} />
          <Export enabled={true} allowExportSelectedData={false} />
        </DataGrid>
      </React.Fragment>
    );
  }
  onExporting(e) {
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet("Main sheet");
    // exportDataGrid({
    //   component: e.component,
    //   worksheet,
    //   autoFilterEnabled: true,
    // }).then(() => {
    //   workbook.xlsx.writeBuffer().then((buffer) => {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       "DataGrid.xlsx"
    //     );
    //   });
    // });
    // e.cancel = true;
  }
}

export default StatisticsV2;
