import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, Card, message } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state ={
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._USER.CREATE;
    this.msg = props.language._USER.MESSAGE;
  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const headers = getHeadersForHttpReq();
        let data = {
          name: values.name,
          username: values.username,
          email: values.email,
          password: values.password,
          client_id: localStorage.getItem('client_id')
        }
        
        axios.post(`${complete_URL}/users/register`, data,{headers})
          .then(res => { /*console.log(res)*/debugger; 
            if (res.data.success) {
              message.success(this.msg.ADDSUCCESS);
              this.props.history.push('/dashboard/users');
            }
            else if (res.data.code === '001'){
              message.error(this.msg.DUPLICATEERROR,3);
            }
            else 
              message.error(this.msg.ADDFAILURE,3);
          })
          .catch(user_reg_err => { 
            message.error(this.msg.ADDFAILURE,3);
            console.log('Error occured while registering User->',user_reg_err)
          });
      }
    });
  }

  componentDidMount(){
    this.setComponentDirection();
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/users">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={14}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/users">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/users">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to="/dashboard/users">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              {/* <Row gutter={24} className="top-header">
                <Col span={8}>
                  <Link to="/dashboard/users">
                    <Button type="danger"><Icon type="arrow-left" /></Button>
                  </Link>
                </Col>
                <Col span={8}>
                  <h1>Add Users</h1>
                </Col>
                <Col span={8}>
                  <Button type="primary" htmlType="submit"><Icon type="save" />
                  
                </Button>
                </Col>
              </Row> */}
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: this.msg.INSERTNAMEERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.USERNAME}>
                    {getFieldDecorator('username', {
                      rules: [{ required: true, message: this.msg.INSERTUSERNAMEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.USERNAME} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.EMAIL}>
                    {getFieldDecorator('email', {
                      rules: [{ required: true, message: this.msg.INSERTEMAILERROR }],
                    })(
                      <Input type="email" placeholder={this.lang.LABELS.EMAIL} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.PASSWORD}>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: this.msg.INSERTPASSWORDERROR }],
                    })(
                      <Input type="password" autoComplete="new-password" placeholder={this.lang.LABELS.PASSWORD} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    );
  }
}

export default AddUser = Form.create({ name: 'normal_login' })(AddUser);