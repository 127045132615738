import React, { Component } from 'react';
import { Row, Col, Form,Table, message, Select, Button,Input,Modal, Spin } from 'antd'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';

if (localStorage.getItem('lang') === 'AR') {
    require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class ServiceTypeClientAssign extends Component{
    constructor(props) {
        super(props)
        this.state = {
          servicetypesList : [],
          selected_servicetypes : [],
          all_selected_servicetypes : [], 
          data: [],
          pagination: {},
          page: 1,
          size: 5,
          sortField:"name",
          sortOrder:"ascend",
          searchValues: {
            name: '',
            name_ar :'',
            description: '',
          },
          dir : 'ltr', 
          display: 'block',
          modelVisible : false,
          spin : false,
          delete_spin : false,
          spin_msg : ''
        }

    this.lang = props.language._SERVICETYPE_CLIENT.SETUP;
    this.msg = props.language._SERVICETYPE_CLIENT.MESSAGE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width: '70%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <p>{text}</p>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name_ar")}
            />
          </span>
        ),
        dataIndex: 'name_ar',
        key: 'name_ar',
        width: '70%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
        render: (text, record) => <p>{text}</p>,
      },
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '20%',
        key: 'last_name',
        align:'center'
      }
    ];
    
    }// End of ServiceTypeClientAssign

    setComponentDirection=()=>{
      if (localStorage.getItem('lang')==='EN') {
          this.setState({dir : 'ltr', display: 'block'})
      }
      else {
          this.setState({dir : 'rtl', display: 'flex'})
      }
    }
     
    compareByAlph = (a, b) => {
        if (a > b) { return -1; }
        if (a < b) { return 1; }
        return 0;
    }

    deleteCategory = (record) => {
      this.setState({spin_msg: this.msg.DELETETIP ,spin : true})
      const headers = getHeadersForHttpReq();
      if(headers) {
       let data = {
        id : record.id,
        client_id : localStorage.getItem('client_id'),
        new_service_type : record.new_service_type,
       };
       axios.post(`${complete_URL}/client_servicetypes/delete_srv_client`, data, {headers})
       .then(delete_res=>{
          if(delete_res.data.success){
            message.success(this.msg.DELETESUCCESS);
            this.fetch();
            this.getAssignedServiceTypes();
            this.setState({spin : false});
          }
          else if(delete_res.data.code == '001'){
            this.setState({spin : false})
            message.error(this.msg.DELETEDEPENDENCYERROR,3);
          }
          else{
            this.setState({spin : false});
            message.error(this.msg.DELETEFAILURE,3);
          }
       })
       .catch(delete_err=>{
          this.setState({spin : false})
          console.log('Error occured while deleting the ServiceTypeClientAssign record->',delete_err);
          message.error(this.msg.DELETEFAILURE,3);
       })
      }
      else { 
        this.setState({spin : false}); 
        message.error(this.props.language.LOGINERROR,3) 
      }
        // message.error('feature to be implemented');
    }

    handleChangePagination = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({ pagination: pager, pageNumber: pagination.current });
    
        if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
          if (this.state.pageNumber  !== pager.current) {
            this.fetch({ page: pagination.current, });
          }
        } 
        else {
          this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
          });
        }
    }

    handleChangeSearchValues = (prop) => (e) => {
        let search = { ...this.state.searchValues };
        search[`${prop}`] = e.target.value;
        this.setState({
          searchValues: search
        }, () => {
          this.fetch();
        });
    }
  
    fetch = (params={}) => {
      const client_id = localStorage.getItem('client_id');
      let headers =  getHeadersForHttpReq();
      let page,sortOrder ;
      if (params.page) { page = params.page - 1 }
      else { page = 0;}

      if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
      else if (params.sortOrder == 'descend') { sortOrder = 'DESC' }
      else { sortOrder = 'ASC' }
      
      let data ={
        client_id : client_id,
        name: this.state.searchValues.name || '',
        name_ar: this.state.searchValues.name_ar || '',

        page,
        sortColumn: params.sortField || this.state.sortField ,
        sortOrder,
        size:this.state.size
      }
        
      axios.post(`${complete_URL}/client_servicetypes/pagination_table`,data, {headers})
        .then(response => {
          //console.log('response of cont_clients pagination response.data.data ->',response.data.data)
          let pagination = {};
          Object.assign(pagination,{
            ...this.state.pagination,
            total : response.data.totalElements,
            current : page +1,
            pageSize : this.state.size
          });
          
          this.setState({ data: response.data.data, pagination });
        })
        .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })
      
    }

    componentDidMount(){
      this.fetch();
      this.getAssignedServiceTypes();
      this.getIndependentServiceTypes();
    }
  
    componentWillMount() {
      if (localStorage.getItem('lang') === 'EN') {
        this.CustomRow = <Row id="clientTableHeader">
          <Col span={1}></Col>
          <Col span={11}><p style={{ textAlign: 'left'}}>{this.lang.TABLEHEADERS.TITLE}</p></Col>
          <Col span={10}>
            <Button onClick={this.displayServiceModel} style={{ marginRight: '12px',float: 'right' }} type="primary">{this.lang.BUTTON.ADD}</Button>
          </Col>
          <Col span={1}></Col>
        </Row>
      }
      else {
        this.CustomRow = <Row id="clientTableHeader">
          <Col span={1}></Col>
          <Col span={12} >
            <Button onClick={this.displayServiceModel} style={{ marginLeft: '12px',float: 'left' }} type="primary">{this.lang.BUTTON.ADD}</Button>
          </Col>
          <Col span={10}><p style={{ textAlign: 'right'}}>{this.lang.TABLEHEADERS.TITLE}</p></Col>
          <Col span={1}></Col>
        </Row>
      }
    }

    getAssignedServiceTypes =()=> {
      const client_id = localStorage.getItem('client_id');
      if(client_id){
        let headers =  getHeadersForHttpReq();
        axios.get(`${complete_URL}/client_servicetypes/${client_id}`, {headers})
          .then(response => { 
            let asvt_data = response.data.data;
            let res = [];
            if (localStorage.getItem('lang')==='EN') {
              if (asvt_data.length > 0) {
                res  = asvt_data.map( ct=>{ return ct.name })
              }
            }
            else{
              if (asvt_data.length > 0) {
                res  = asvt_data.map( ct=>{ return ct.name_ar });
              }
            }
              this.setState({ selected_servicetypes : res, all_selected_servicetypes : response.data.data }/*,()=>{console.log('assigned_clientsList->',this.state.assigned_clientsList)}*/)
          })
          .catch(clt_err => { console.log('Error in fetching assigned ServiceTypes->',clt_err) })
      }
    }
    
    getIndependentServiceTypes = ()=>{
      let  headers = getHeadersForHttpReq();
      axios.get(`${complete_URL}/servicetypes/independent`,{headers})
        .then(res => {  //console.log('service_types->',res.data.data)
          this.setState({ servicetypesList: res.data.data })
        })
        .catch(err => {  console.log('Issue while fetching service_types ->',err) })
    }

    removeAlreadyAssigned=(selectedArr)=>{
      let { all_selected_servicetypes } = this.state;
      for (let i = 0; i < selectedArr.length; i++) {
        if (localStorage.getItem('lang')==='EN') {
          for (let j = 0; j < all_selected_servicetypes.length; j++) {
            if (selectedArr[i] === all_selected_servicetypes[j].name) {
              selectedArr.splice(i,1);
              j = 0;
            }
          }// End of inner loop
        }
        else{
          for (let j = 0; j < all_selected_servicetypes.length; j++) {
            if (selectedArr[i] === all_selected_servicetypes[j].name_ar) {
              selectedArr.splice(i,1);
              j = 0;
            }
          }// End of inner loop
        }
      }

      return selectedArr;
    }

    handleServiceTypesSelection=(e)=>{
      e.preventDefault();
      this.setState({spin_msg: this.msg.ASIGNTIP ,spin : true})
      const client_id = localStorage.getItem('client_id');
      if (client_id) {
        this.props.form.validateFields((err, values) => {
          if (!err) {
            const  headers = getHeadersForHttpReq();
            let new_service_types = this.removeAlreadyAssigned(values.servicetypes);
            if (new_service_types.length > 0) {
              let data = {
                client_id : localStorage.getItem('client_id'),
                user_id : localStorage.getItem('user_id'),
                servicetypes: new_service_types
              }    
              
              axios.post(`${complete_URL}/client_servicetypes/add`, data, {headers})
              .then(res => {
                if (res.data.success) {
                  this.fetch();
                  this.getAssignedServiceTypes();
                  this.setState({spin : false, modelVisible : false},()=>message.success(this.msg.ADDSUCCESS));
                } 
                else {
                  this.setState({spin : false, modelVisible : false},()=>message.error(this.lang.MESSAGE.ADDFAILURE,3))
                }
              })
              .catch(err => {
                  console.log('Error occured while assigning the Client(s)->',err)
                  this.setState({spin : false},()=>message.error(this.lang.MESSAGE.ADDFAILURE,3))
              })
              
            }
            else { this.setState({spin : false},()=>message.error(this.msg.SELECTERROR,3));}
          }
        })
      }
      else{ message.error(this.props.language.LOGINERROR,3); }
    }

    displayServiceModel = () => {
      this.setState({ modelVisible: true })
    }
    handleServiceTypeOk = () => {
      this.setState({ modelVisible: false })
    }
    handleServiceTypeCancel = () => {
      this.setState({ modelVisible: false })     
    }

    render() {
      // console.log('ServiceTypeClientAssign render called');  
      const { getFieldDecorator } = this.props.form;

        return (
          <>  
          {this.CustomRow}
          <Row gutter={24}>
            <Col span={23} style={{margin : '0 25px 14px 25px'}}>
            <Table
                columns={this.columns}
                rowKey="id"
                style={{marginTop : '10px'}}
                dataSource={this.state.data}
                onChange={this.handleChangePagination}
                pagination={this.state.pagination}
            /> 
            </Col>
          </Row>
          <Modal 
              title={this.lang.TABLEHEADERS.TITLE}
              visible={this.state.modelVisible} 
              onOk={this.handleServiceTypeOk} 
              onCancel={this.handleServiceTypeCancel} 
              width = {'50%'}
              footer = {false}
            >
            <Spin tip={this.state.spin_msg} spinning = {this.state.spin}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form onSubmit={this.handleServiceTypesSelection} >
                    <Row gutter={24}  dir={this.state.dir} style={{display : this.state.display}}>
                        <Col span={24}>
                        <Form.Item label={this.lang.TABLEHEADERS.TITLE}  >
                            {getFieldDecorator('servicetypes', {
                              initialValue: this.state.selected_servicetypes,
                              rules: [{ required: false, message: '' }],
                            })(
                                <Select mode="multiple" 
                                  placeholder={ this.lang.TABLEHEADERS.TITLE}
                                  style={{ width: '100%' }} 
                                  optionLabelProp="label"
                                >
                                {
                                    (localStorage.getItem('lang') === 'EN') ?
                                    this.state.servicetypesList.map(ct => (
                                        <Select.Option style={{textAlign:'left', marginRight: '6px'}} key={ct.id} value={ct.id}  label = {ct.name}>{ct.name}</Select.Option>
                                    ))
                                    : this.state.servicetypesList.map(ct => (
                                        <Select.Option style={{textAlign:'right', marginRight: '6px'}} key={ct.id} value={ct.id}  label = {ct.name_ar}>{ct.name_ar}</Select.Option>
                                    ))
                                }
                                </Select>)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}  dir={this.state.dir} style={{display : this.state.display}}>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <Button type="primary" block htmlType="submit" >{this.lang.BUTTON.ASSIGN}</Button>
                        </Col>
                        <Col span={8}></Col>    
                    </Row>
                    </Form>
                </Col>
            </Row>
        </Spin>
        </Modal>
        </>
        )
    }// End of render
  }//End of Component

  // export default ServiceTypeClientAssign;
export default ServiceTypeClientAssign = Form.create({ name: 'srv_typ_client_assign' })(ServiceTypeClientAssign);
