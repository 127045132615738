import React, { Component } from 'react';
import { Row,  Col,  Form,  Input,  Card,  Icon,  Button,  Checkbox, message } from 'antd';
import axios from 'axios';
import API_URL from '../../constants';
import jwt_decode from 'jwt-decode';
import LanguageChangingDropdown from '../../LanguageChangingDropdown/LanguageChangingDropdown';

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading : false
    }
    this.lang = props.language._LOGINFORM
  }

  handleSubmit = (e) => {
    e.preventDefault();
    
    this.props.form.validateFields((err, values) => {
      if (!err && values.userName && values.password) {
        this.setState({loading : true});
        let data = { username: values.userName, password: values.password }
        const complete_URL = API_URL +''+ localStorage.getItem('pt');
        
        axios.post(`${complete_URL}/users/login`, data)
          .then(res => { /*console.log('users/login data->',res.data)*/
            this.setState({loading : false});
            if (res.data.success) {
              message.success(this.lang.MESSAGES.LOGINSUCCESSFULLY/*'Loged in successfully'*/);
              const decode = jwt_decode(res.data.token);

              localStorage.setItem('tokenType',res.data.tokenType);
              localStorage.setItem('token',res.data.token);
              localStorage.setItem('user_name',decode.name);
              localStorage.setItem('user_id', decode.id);
              localStorage.setItem('user_image_id', decode.image_id);
              localStorage.setItem('client_id', decode.client_id);
              localStorage.setItem('is_contr', decode.is_contr);
              
              if (decode.is_contr != 1) {
              axios.get(`${complete_URL}/clients/${decode.client_id}`)
                .then(res => {
                  localStorage.setItem('client_name', res.data.data.name);
                  localStorage.setItem('logo_img', res.data.data.logo_img);
                  let img_id = res.data.data.logo_img;
                  if (img_id) {
                   axios.get(`${complete_URL}/images/${img_id}`, { responseType: 'blob' })
                    .then(res => {
                      let reader = new FileReader();
                      reader.readAsDataURL(res.data);
                      reader.onloadend = () => { //console.log(reader.result)
                        localStorage.setItem('client_logo_image', reader.result);
                      }
                    })
                    .catch(clt_img_err => { console.log('Client Image  issue->',clt_img_err.message) })
                  }
                  else{
                    localStorage.setItem('client_logo_image', '/dummy_image.jpg');
                  }
                  window.location.reload()
                })
                .catch(clt_err => { console.log('Client Data issue->',clt_err.message) })
                this.setState({loading : false});
              }
              else{ // set Contractor JSON object in localstorage
                let cont_data = JSON.stringify(res.data.cont_data);
                localStorage.setItem('updateContractor',cont_data);
                //Now this will navigate to Contractors packages screen
                this.setState({loading : false});
                window.location.reload();
              }
            }
            else if(res.data.code == '002'){
              message.error('User with these credentials does not exists.. Please try again!',3);
            } 
            else if(res.data.code == '003'){
              message.error('Incorrect username or password!',3);
            } 
            else { 
              message.error(this.lang.MESSAGES.LOGINUNSUCCESSFULLY/*'username or password incorrect'*/);
            }
          })
          .catch(clt_login_err => { 
            this.setState({loading : false});
            console.log('Client Login issue->',clt_login_err.message)
            if (clt_login_err.message === 'Network Error') {
              message.error('Unable to connect to the System!!');
            }
        })
      }
      /*else{this.setState({loading : false},()=>{
        console.log('Kindly insert username and password and try again!');
      });}*/
    });
  }

  handleUsernameChange = (e)=>{
    if(e.target.value.length > 0){
      let pt = '5000'
      if(e.target.value === 'admin_demo' || e.target.value === 'tuser_demo'){ pt = '3000' }

      localStorage.setItem('pt',pt);
    }
  }

  componentWillMount() {
    // if (!localStorage.getItem('pt') ) {
    localStorage.setItem('pt','5000');// put here '3000' ~patch for TEST project
    // }
    let user_id = localStorage.getItem('user_id'); 
    if (user_id == null) {
      this.props.history.push('/');
    } 
    else if (user_id === '00000000-0000-00') {
      this.props.history.push('/select');
    }  
    else {
      const ph = (localStorage.getItem('is_contr') == '1') ? '/dashboard/contpackages' : '/dashboard';
      this.props.history.push(ph);
    }
  }// End of WillMount


  render() {
    const { getFieldDecorator } = this.props.form;
    
    return (
      <Row gutter={24} className="login-back">
        <Col span={4}>{''}</Col>
        <Col span={16}>
        <Card className="loginFormCSS2" >
          {
            this.lang._DIRECTION === 'ltr' ?
            <>
                <Row gutter={24}>
                  <Col span={3}>
                      <LanguageChangingDropdown color='##42AAFF' {...this.props} />
                  </Col>
                  <Col span={18} style={{margin: '-30px auto'}}><img style={{width:'150px'}}  src='/Hadath.png' size={80} /></Col>
                </Row>
                <Row gutter={24}>
                  <Col span={13}></Col>
                  <Col span={8}><h1 style={{ display: 'inline-block' }}>{this.lang.LABELS.TITLE}</h1></Col>
                </Row>
                <Row gutter={24}>
                  <Col span={2}></Col>
                  <Col span={10} style={{marginTop:'-20px'}}>
                    <h1 style={{textAlign:"left", fontWeight: 600}}>{'Hadath'}</h1>
                    <p style={{textAlign:"left",color:'black', fontFamily: 'monospace'}}>{'Integrated and coherent application specialized in managing wedding halls, events and conferences as well as all  types of reservations'}</p>
                  </Col>
                  <Col span={10}>
                    <Form onSubmit={this.handleSubmit}  className="login-form">
                      <Row gutter={24}>
                        <Col span={24} style={{marginTop : '-10px',marginBottom : '-10px' }}>
                          <Form.Item>
                            {getFieldDecorator('userName', {
                              rules: [{ required: true, message: 'Please input your username!' }],
                              onChange : (e)=> this.handleUsernameChange(e)
                            })(
                              <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}  placeholder={this.lang.LABELS.USERNAME} />
                            )}
                          </Form.Item>
                        </Col>
                        </Row>
                        <Row gutter={24}>
                        <Col span={24} style={{marginTop : '-10px',marginBottom : '-10px' }}>
                          <Form.Item>
                        {getFieldDecorator('password', {
                          rules: [{ required: true, message: 'Please input your Password!' }],
                        })(
                          <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} autoComplete= 'false'  placeholder={this.lang.LABELS.PASSWORD} />
                        )}
                      </Form.Item>
                        </Col>
                        </Row>
                        <Row gutter={24}>
                        <Col span={16} style={{textAlign:"left"}}>
                          <Form.Item>
                            {getFieldDecorator('remember', {
                              valuePropName: 'checked',
                              initialValue: true,
                            })(
                              <Checkbox >{this.lang.LABELS.REMEMBERME}</Checkbox>
                            )}
                          </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Button type="ghost"  loading={this.state.loading} block htmlType="submit">
                          {this.lang.BUTTON.LOGIN}
                        </Button>
                      </Col>
                      </Row>
                      {/* <Row gutter={24}>
                        <Col span={16}><Link to="/" className="login-form-forgot" href="#">{this.lang.LABELS.FORGOTPASSWORD}</Link></Col>
                      </Row> */}
                    </Form>
                  </Col>
                  <Col span={2}></Col>
                </Row>
            </>
          :
            <>
                <Row gutter={24}>
                  <Col span={3}></Col>
                  <Col span={18} style={{margin: '-30px auto'}}><img style={{width:'150px'}}  src='/Hadath.png' size={80} /></Col>
                  <Col span={3}>
                      <LanguageChangingDropdown color='##42AAFF' {...this.props} />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={13}><h1 style={{ display: 'inline-block' }}>{this.lang.LABELS.TITLE}</h1></Col>
                  <Col span={11}></Col>
                </Row>
                <Row gutter={24}>
                  <Col span={2}></Col>
                  
                  <Col span={10}>
                    <Form onSubmit={this.handleSubmit}  className="login-form">
                      <Row gutter={24}>
                        <Col span={24} style={{marginTop : '-10px',marginBottom : '-10px' }}>
                          <Form.Item>
                            {getFieldDecorator('userName', {
                              rules: [{ required: true, message: 'Please input your username!' }],
                              onChange : (e)=> this.handleUsernameChange(e)
                            })(
                              <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}  placeholder={this.lang.LABELS.USERNAME} />
                            )}
                          </Form.Item>
                        </Col>
                        </Row>
                        <Row gutter={24}>
                        <Col span={24} style={{marginTop : '-10px',marginBottom : '-10px' }}>
                          <Form.Item>
                        {getFieldDecorator('password', {
                          rules: [{ required: true, message: 'Please input your Password!' }],
                        })(
                          <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} autoComplete= 'false'  placeholder={this.lang.LABELS.PASSWORD} />
                        )}
                      </Form.Item>
                        </Col>
                        </Row>
                        <Row gutter={24}>
                        <Col span={9} >
                          <Button type="ghost"  loading={this.state.loading} block htmlType="submit">
                            {this.lang.BUTTON.LOGIN}
                          </Button>
                        </Col>
                        <Col span={15} style={{textAlign:"right"}}>
                          <Form.Item>
                            {getFieldDecorator('remember', {
                              valuePropName: 'checked',
                              initialValue: true,
                            })(
                              <Checkbox >{this.lang.LABELS.REMEMBERME}</Checkbox>
                            )}
                          </Form.Item>
                      </Col>
                      </Row>
                      {/* <Row gutter={24}>
                        <Col span={16}><Link to="/" className="login-form-forgot" href="#">{this.lang.LABELS.FORGOTPASSWORD}</Link></Col>
                      </Row> */}
                    </Form>
                  </Col>
                  <Col span={10} style={{marginTop:'-20px'}}>
                    <h1 style={{textAlign:"right", fontWeight: 600, fontSize: '32px'}}>{'حدث'}</h1>
                    <h3 style={{textAlign:"right", fontWeight: 300, fontSize:'16px'}}>نظام متكامل ومترابط  متخصص بادارة قاعات الافراح والمناسبات والمؤتمرات
      وادارة جميع انواع الحجوزات</h3>
                  </Col>
                  <Col span={2}></Col>
                </Row>
            </>
          }
          </Card>
        </Col>
        <Col span={4}>{''}</Col>
      </Row>
    )
  }
}

export default Login = Form.create({ name: 'normal_login' })(Login);