import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, Card, message, Table } from 'antd'
// import { Link } from 'react-router-dom'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const Search = Input.Search;
const TextArea = Input.TextArea;
const complete_URL = API_URL+pt;

class ServiceType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ServiceTypeFormVisible: false,
      ServiceTypeTableVisible: false,
      ServiceTypeUpdateVisible : false,
      // data: {},
      data: [],
      sortField:"name",
      sortOrder:"ascend",
      pagination: {},
      page: 1,
      size: 5,
      searchValues: {
        name: '',
        name_ar: '',
        description: '',
        orderBy: '',
        column_name: 'name'
      },
      serviceTypeObj : { },
      img_url : '',
      dir : 'ltr', 
      display: 'block'
    }
    this.name_ref = null;
    this.lang = props.language._SERVICETYPE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (<span>{this.lang.SETUP.TABLEHEADERS.NAME}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.NAME}
                    onChange={this.handleChangeSearchValues("name")}
                  />
                </span>
        ),
        dataIndex: 'name',
        key: 'name',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: ( <span>{this.lang.SETUP.TABLEHEADERS.NAME}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.NAME}
                    onChange={this.handleChangeSearchValues("name")}
                  />
                </span>
        ),
        dataIndex: 'name',
        key: 'name',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) =><div style={{textAlign:'center'}}> <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: ( <span>{this.lang.SETUP.TABLEHEADERS.NAMEAR}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.NAMEAR}
                    onChange={this.handleChangeSearchValues("name_ar")}
                  />
                </span>
        ),
        dataIndex: 'name_ar',
        key: 'name_ar',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
        render: (text, record) =><div style={{textAlign:'right',marginRight:'30px'}}> <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
      }:
      {
        title: ( <span>{this.lang.SETUP.TABLEHEADERS.NAMEAR}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.NAMEAR}
                    onChange={this.handleChangeSearchValues("name_ar")}
                  />
                </span>
        ),
        dataIndex: 'name_ar',
        key: 'name_ar',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: ( <span>{this.lang.SETUP.TABLEHEADERS.DESCRIPTION}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.DESCRIPTION}
                    onChange={this.handleChangeSearchValues("description")}
                  />
                </span>
        ),
        dataIndex: 'description',
        key: 'description',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.description, b.description),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: ( <span>{this.lang.SETUP.TABLEHEADERS.DESCRIPTION}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.DESCRIPTION}
                    onChange={this.handleChangeSearchValues("description")}
                  />
                </span>
        ),
        dataIndex: 'description',
        key: 'description',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.description, b.description),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      
      {
        title: `${this.lang.SETUP.BUTTON.DELETE}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.SETUP.BUTTON.DELETE}</Button>
          </span>
        ),
        key: 'action',
        align : 'center',
      }
    ];  

  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }//End of Method

  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    let img_url = '';
    if (record.image_id!== '00000000-0000-00' && record.image_id != null) {
      img_url = `${complete_URL}/images/${record.image_id}`;
    }
    this.setState({ serviceTypeObj : record,img_url,
                    ServiceTypeUpdateVisible : true,
                    ServiceTypeFormVisible : false })
  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
         pagination: pager,
         pageNumber: pagination.current
      });

      if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
        if (this.state.pageNumber  !== pager.current) {
          this.fetch({ page: pagination.current });
        }
      } 
      else {
         this.fetch({
         results: pagination.pageSize,
         page: pagination.current,
         sortField: sorter.field,
         sortOrder: sorter.order,
         ...filters,
         });
      }

    /*let pager = { ...this.state.pagination };
    pager.current = pagination.current;

    let searchValues = { ...this.state.searchValues }
    searchValues.column_name = sorter.field
    searchValues.orderBy = sorter.order

    this.setState({
      page: pager.current,
      pagination: pager,
      searchValues
    }, () => {   this.fetch();})*/
  
  }//End of method

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }

  HandleServiceTypeFormVisibility = () => {
    this.setState({ ServiceTypeFormVisible: !this.state.ServiceTypeFormVisible },()=>{
      if(this.state.ServiceTypeFormVisible){
        this.setState({ServiceTypeUpdateVisible : false});
      }
    })
  }

  HandleServiceTypeUpdateFormVisibility = () => {
    this.setState({ ServiceTypeUpdateVisible : !this.state.ServiceTypeUpdateVisible })
  }

  HandleServiceTypeTableVisibility = () => {
    this.setState({ ServiceTypeTableVisible: !this.state.ServiceTypeTableVisible })
  }

  componentDidMount() {
    // const client_id = localStorage.getItem('client_id');
    // this.getServiceTypes(client_id);
    this.getIndependentServiceTypes();
    this.fetch();
    this.setComponentDirection();
  }

  fetch = (params={}) => {
    const headers = getHeadersForHttpReq();
    const client_id = localStorage.getItem('client_id');
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else { sortOrder = 'DESC' }
    
    let data ={
      client_id : client_id,
      name: this.state.searchValues.name || '',
      name_ar: this.state.searchValues.name_ar || '',
      description: this.state.searchValues.description || '',
      
      page,
      sortColumn: params.sortField || this.state.sortField ,
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'name'
    }
    // console.log('Sending data to api ->',data);
    // When Contractor feature is implemented live ~
    // axios.post(`${complete_URL}/servicetypes/pagination_table`,data, {headers})
    axios.post(`${complete_URL}/servicetypes/pagination_table_ind`,data, {headers})
    .then(response => {
      // console.log('response of service types new pagination ->',response)
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      this.setState({ data: response.data.data, pagination })
      // console.log('servicetypes data-> ',response);
    })
    .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })

   /* axios.get(`${complete_URL}/servicetypes/by_clients/table?id=${client_id}&page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`,{headers})
      .then(res => {
        let pagination = { ...this.state.pagination };
        pagination.total = res.data.totalElements;
        pagination.pageSize = parseInt(res.data.size);
        this.setState({
          data: res.data,
          pagination: pagination,
        })
      })
      .catch(err => { console.log(err) })*/
      
  }

  getServiceTypes = (client_id)=>{
    const headers = getHeadersForHttpReq();

    axios.get(`${complete_URL}/servicetypes/by_client/${client_id}`, {headers})
      .then(res => { // console.log('service_types->',res)
        this.setState({ service_type: res.data.data })
      })
      .catch(err => {  console.log('Issue while fetching service_types ->',err) })
  }

  getIndependentServiceTypes = ()=>{
    const headers = getHeadersForHttpReq();

    axios.get(`${complete_URL}/servicetypes/independent`, {headers})
      .then(res => { // console.log('service_types->',res)
        this.setState({ service_type: res.data.data })
      })
      .catch(err => {  console.log('Issue while fetching service_types ->',err) })
  }

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();

    axios.delete(`${complete_URL}/servicetypes/${record.id}`, {headers})
      .then(res => { //message.success('Service Type Has been deleted') // console.log(res)
        if(res.data.success){
          message.success(res.data.message);
          this.fetch();
        }
        else{
          message.error(res.data.message);
          console.log('Error.. failure in deleting new service type->',res.data);
        }
      })
      .catch(err => {
        message.error('Service Type can not be deleted')
        console.log('Error occured while deleting the Service Type ->',err)
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const headers = getHeadersForHttpReq();

        values.user_id = localStorage.getItem('user_id')
        values.client_id = localStorage.getItem('client_id')
        // console.log(In ServiceType.. handleSubmit values->,values)
          axios.post(`${complete_URL}/servicetypes/add`, values, { headers})
            .then(res => {
              if (res.data.success) {
                message.success(res.data.message); // console.log('In ServiceType.. handleSubmit.. res->',res)
                this.fetch();
                values = null;
                this.props.form.resetFields();
              } 
              else {
                message.error(res.data.message)
              }
            })
            .catch(err => {
              message.error('Failed to add Service Type!!');
              console.log(err)
            }); 
      }
      else{
        console.log('Error occured while inserting new Service type->',err);
        message.error(err);
      }
    });
  }

  handleNameChange = (rule, value, callback)=>{
    if(value ) {
      if(this.state.service_type.length > 0){
        let exists_exp = this.state.service_type.filter(exp_typ => exp_typ.name.replace(/\s/g, '') === value.replace(/\s/g, ''));
        try {
          if( Object.keys(exists_exp).length > 0 ){          
            rule.message = 'Service Type already Exists!!';
            throw new Error('Service Type already exists2!');
            callback()
          }
          callback();
         } catch (err) {
           callback(err);
        }
      }
      callback();
    }//End of if Value input
    else{
      try {
        if(!value ) {
            rule.message = 'Kindly input the Service Type name!!';
            throw new Error('Kindly input the Service Type name!');
            callback();
          }
          callback();  
        } catch (err) {
          callback(err);
        }
    }
  }

  handleNameArChange = (rule, value, callback)=>{
    if(value ) {
      if(this.state.service_type.length > 0){
        let exists_exp = this.state.service_type.filter(exp_typ => exp_typ.name_ar.replace(/\s/g, '') === value.replace(/\s/g, ''));
        try {
          if( Object.keys(exists_exp).length > 0 ){          
            rule.message = 'Service Type Arabic Name already Exists!!';
            throw new Error('Service Type already exists2!');
            callback()
          }
          callback();
        } catch (err) {
          callback(err);
        }
      }
      callback();
    }//End of if Value input
    else{
      try {
        if(!value ) {
        rule.message = 'Kindly input the Service Type Arabic name!!';
        throw new Error('Kindly input the Service Type Arabic name!');
        callback();
        }
          callback();  
      } catch (err) {
        callback(err);
      }
    }
  }

  handleUpdateSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.user_id = localStorage.getItem('user_id')
        values.client_id = localStorage.getItem('client_id')
        // console.log(values)
        const headers = getHeadersForHttpReq();

        axios.put(`${complete_URL}/servicetypes/${this.state.serviceTypeObj.id}`, values, { headers})
          .then(res => {
            if (res.data.success) {
              message.success(res.data.message);
              this.fetch();
            } else {
              message.error(res.data.message)
            }
          })
          .catch(err => {
            message.error('Service added failed')
            console.log(err)
          })
          this.props.form.resetFields();
          e.target.reset();
          this.setState({serviceTypeObj :  {  name : '',name_ar : '', description : '' } }, ()=>{
            this.HandleServiceTypeUpdateFormVisibility();  
          });
      }
    });
  }

  componentWillMount() {

    this.ModalHeader = <Row gutter={24}>
    <Col span={12} style={{ paddingLeft: '15px', marginBottom: '10px' }}>
      <Button 
        onClick={this.HandleServiceTypeTableVisibility} 
        style={{ float: 'left', marginLeft : '-5%' }} 
        type="primary">{this.lang.SETUP.BUTTON.DISPLAYSERVICETYPES}
      </Button>
    </Col>
    <Col span={12} style={{ paddingRight: 0, marginBottom: '10px' }}>
      <Button 
        onClick={this.HandleServiceTypeFormVisibility} 
        style={{ float: 'right' }} 
        type="primary">
          {this.lang.SETUP.BUTTON.ADD}
      </Button>
    </Col>
  </Row>
  this.InsertServiceTypeHeader = <Row gutter={24} className="top-header" style={{ paddingTop: 0, paddingBottom: 0 }} >
    <Col span={3} >
      <Button onClick={this.HandleServiceTypeFormVisibility} type="danger"><Icon type="arrow-left" /></Button>
    </Col>
    <Col span={14}>
      <h1  style={{ textAlign: 'center' }}>{this.lang.CREATE.LABELS.INSERTTITLE}</h1>
    </Col>
    <Col span={6} className="btn-box" style={{ marginTop: '-24px', marginRight: '12px', float: "right", display: 'flex' }} >
      <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
      <Button style={{marginLeft: '20px'}} onClick={this.HandleServiceTypeFormVisibility} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
    </Col>
  </Row>
  this.UpdateServiceTypeHeader = <Row gutter={24} className="top-header" style={{ paddingTop: 0, paddingBottom: 0 }} >
    <Col span={3} >
      <Button onClick={this.HandleServiceTypeFormVisibility} type="danger"><Icon type="arrow-left" /></Button>
    </Col>
    <Col span={14}>
      <h1  style={{ textAlign: 'center' }}>{this.lang.CREATE.LABELS.UPDATETITLE}</h1>
    </Col>
    <Col span={6} className="btn-box" style={{ marginTop: '-24px', marginRight: '12px', float: "right", display: 'flex' }} >
      <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
      <Button style={{marginLeft: '20px'}} onClick={this.HandleServiceTypeFormVisibility} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
    </Col>
  </Row>

  }
  
  // 03Dec2020
  handleImageUpload = (e) => {
    e.preventDefault();
    // console.log(e)
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      if (this.state.serviceTypeObj.id) {
      let file = e.currentTarget.files[0];
      let fileName = file.name;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (file.size <= 1024 * 1024 * 2 && /png|jpe?g/i.test(extension)) {
        let a;
        let imgdata = new FormData();
        imgdata.append('file', file);
        imgdata.append('client_id', localStorage.getItem('client_id'));
        imgdata.append('user_id', localStorage.getItem('user_id'));
        imgdata.append('srv_type_id', this.state.serviceTypeObj.id);
        let  headers = {
          'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
          'Content-Type': 'multipart/form-data'
        }
        let thiss = this
        axios.post(`${complete_URL}/images/srv_type/add`, imgdata, { responseType: 'blob' }, headers)
          .then(res => {
            var reader = new FileReader();
            reader.readAsDataURL(res.data);
            reader.onloadend = () => {
              a = reader.result
              thiss.setState({ img_url: a });
            }
            message.success(this.lang.MESSAGE.SERVICETYPEIMAGEADDSUCCESS ,3);
          })
          .catch(err => { 
            console.log('Error occured while uploading Service Type image->',err);
            message.success(this.lang.MESSAGE.SERVICETYPEIMAGEADDFAILURE ,3);
          });

        setTimeout(() => { this.setState({ img_url: a });  }, 800);
      }
      else{
        message.error(this.lang.CREATE.LABELS.FILEUPLOAD ,3);
      }
    }//End of Any Service Type selected or not 
   }//End of image is selected for upload
  }//End of Method
  
  render() {
    const { getFieldDecorator } = this.props.form;
    let {dir, display, data, pagination, ServiceTypeFormVisible, 
         ServiceTypeUpdateVisible, ServiceTypeTableVisible } = this.state;
    

    return (
      <>
        {this.ModalHeader}
        {ServiceTypeFormVisible &&
          <Card style={{ marginLeft: '-12px', marginRight: '-12px' }}>
            <Form id = {'serviceTypeInsertId'} onSubmit={this.handleSubmit}>
              {this.InsertServiceTypeHeader}
              <Row gutter={24} dir={dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label= {this.lang.SETUP.TABLEHEADERS.NAME}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, validator : (rule,value, callback)=> this.handleNameChange(rule,value, callback) , message: this.lang.MESSAGE.INSERTNAMEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.SETUP.TABLEHEADERS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.SETUP.TABLEHEADERS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      rules: [{ required: false,validator : (rule,value, callback)=> this.handleNameArChange(rule,value, callback) , message: this.lang.MESSAGE.INSERTNAMEARERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.SETUP.TABLEHEADERS.NAMEAR} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir={dir} style={{ display }}>
                <Col span={24}>
                  <Form.Item label={this.lang.SETUP.TABLEHEADERS.DESCRIPTION}>
                    {getFieldDecorator('description', {
                      rules: [{ required: false, message:  this.lang.MESSAGE.INSERTDESCRIPTIONERROR }],
                    })(
                      <TextArea type="note"  type="note" placeholder={this.lang.SETUP.TABLEHEADERS.DESCRIPTION} style={{ width: '100%' }}  autoSize={{ minRows: 2, maxRows: 6 }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        }
        {ServiceTypeUpdateVisible  &&
          <Card style={{ marginLeft: '-12px', marginRight: '-12px' }}>
            <Form  onSubmit={this.handleUpdateSubmit}>
              {this.UpdateServiceTypeHeader}
              <Row gutter={24} dir={dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.SETUP.TABLEHEADERS.NAME} >
                    {getFieldDecorator('name_Update', {
                      initialValue: this.state.serviceTypeObj.name,
                      rules: [{ required: true, validator : (rule,value, callback)=> this.handleNameChange(rule,value, callback) , message: this.lang.MESSAGE.INSERTNAMEERROR  }],
                    })(
                      <Input type="text" placeholder={this.lang.SETUP.TABLEHEADERS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.SETUP.TABLEHEADERS.NAMEAR}>
                    {getFieldDecorator('name_ar_Update', {
                      initialValue: this.state.serviceTypeObj.name_ar,
                      rules: [{ required: false, validator : (rule,value, callback)=> this.handleNameArChange(rule,value, callback) , message: this.lang.MESSAGE.INSERTNAMEARERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.SETUP.TABLEHEADERS.NAMEAR} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir={dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.SETUP.TABLEHEADERS.DESCRIPTION}>
                    {getFieldDecorator('description_Update', {
                      initialValue: this.state.serviceTypeObj.description,
                      rules: [{ required: false, message: this.lang.MESSAGE.INSERTDESCRIPTIONERROR }],
                    })(
                      <TextArea type="note" placeholder={this.lang.SETUP.TABLEHEADERS.DESCRIPTION} style={{ width: '100%' }}  autoSize={{ minRows: 2, maxRows: 6 }}  />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <p>{this.lang.CREATE.LABELS.FILEUPLOAD}</p>
                  <Form.Item>
                    {getFieldDecorator('file', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <><Row gutter={24}>
                          <Col span={24}>
                            <img  width={'100%'} height={'150px'} src={ this.state.img_url || '/dummy_image.jpg'} alt="Image here" />
                          </Col>
                        </Row>
                        <Row gutter={24} style={{marginTop:'5px'}}>
                          <Col span={24}>
                            <Input type="file" style={{width: '35% !important'}} id="files" className="file" onChange={this.handleImageUpload} />
                          </Col>
                      </Row></>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        }  
        {ServiceTypeTableVisible  &&
          <Card style={{marginTop : '1%', marginLeft: '-12px', marginRight: '-12px' }} >
            <Row gutter={24}>
              <Col span={24} style={{textAlign:"center", marginTop: '-1.5%', marginBottom: '-2%', fontSize: '23px', fontWeight : '400', color: 'black'}}>
                <p>{this.lang.TITLE}</p>
              </Col>
            </Row>
            <Row gutter={24} id="clientsTable"  dir= {dir}>
              <Col span={24} >
                  <Table
                    size={'small'}
                    columns={this.columns}
                    rowKey="id"
                    // dataSource={this.state.data.selectedElements}
                    dataSource={data}
                    onChange={this.handleChangePagination}
                    pagination={pagination}
                  />
              </Col>
            </Row>
          </Card>
        }
        
      </>
    )
  }
}

export default ServiceType = Form.create({ name: 'normal_login' })(ServiceType);