import React, { Component } from 'react';
import { Row, Col, Form,  Table, Button,Icon, Select,InputNumber, message, Input, Spin,Tooltip, List} from 'antd'
import axios from 'axios'
import RuleTitleUpdate from './RuleTitleUpdate';
import API_URL from '../../constants'
import getHeadersForHttpReq from '../../constants/token';

const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Rules extends Component {
  constructor(props) {
		super(props)
		this.state = {
      rule_parents: [],
      rules: [],
      rule_titles:[],
      data: [],
      titleLines : [],
      ruleLines : [],
      rules_seq_nos : [],
      rulestypes_seq_nos : [],
      selectedRuleTypeObj : {},
      updateRuleTypeObj : {},
      pagination: {},
      page: 1,
      size: 5,
      // sortField:"rule_parent_name",
      // sortField:"rule_title",
      sortField:"seq_no",
      sortOrder:"descend",
      searchValues:{
        // rule_parent_name:'',
        // rule_parent_name_ar: '', 
        rule_title:'',
        rule_title_ar:'',
        title_desc: '',
      },
      max_seq_no : 0.0,
      screen_opt:'table',
      loading1: false,
      loading2: false,
      updTitleLoading: false,
      spin_msg : '',
      spin: true,
      dir : 'ltr', 
      display: 'block',
    }
    
    this.lang = props.lang;
    this.refArrayEn=[];
    this.refArrayAr=[];
    this.refArrayDesc=[];
    this.refArraySeq =[];
    this.refArray=[];
  }
  
  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  handleNavigation=(record)=>{
    localStorage.setItem('updRuleTitle',JSON.stringify(record))
    this.setState({screen_opt: 'update'});
  }

  deleteCategory =(record,route)=>{
    let msg;
    const headers = getHeadersForHttpReq();
    if(route === 'rules') {
      this.setState({spin_msg : this.lang.MESSAGE.DELETERULETIP, spin :true})
      msg = this.lang.MESSAGE.DELETERULESUCCESS;
    }
    else if(route === 'rulestypes') {
      this.setState({spin_msg : this.lang.MESSAGE.DELETERULETITLETIP, spin :true})
      msg = this.lang.MESSAGE.DELETERULETITLESUCCESS;
    }
    axios.delete(`${complete_URL}/${route}/${record.id}`,{headers})
      .then(res => {
        if(res.data.success){  
          this.fetch();
          this.setState({ spin :false})
          message.success(msg ,2);
          // if(route === 'rules') {
          //   this.getRulesByRuleType(this.state.updateRuleTypeObj.id);
          //   this.setState({ spin :false})
          // }
          // else {
          //   this.fetch();
          //   this.setState({ spin :false})
          // }
        }
        else if(res.data.code ==='001'){
          this.setState({ spin :false})
          message.error(this.lang.MESSAGE.DELETEDEPENDENCYERROR ,2);
        }
      })
      .catch(err => { 
        this.setState({ spin :false})
        if(route === 'rules') {
          console.log('Error occured while deleting the Rule->',err);
          message.error(this.lang.MESSAGE.DELETERULEFAILURE ,2);
        }
        else if(route === 'rulestypes') {
          console.log('Error occured while deleting the Rule Title->',err);
          message.error(this.lang.MESSAGE.DELETERULETITLEFAILURE ,2);
        }
      })
  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      pageNumber: pagination.current,
      sortOrder:sorter.order,
      sortField: sorter.field
    },()=>{
        this.fetch({
          results: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
    });
  }//End of Method

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }

  componentDidMount() {
    this.fetch();
    // this.getRuleParents('CUST_RULE');    
    this.setComponentDirection();
  }

  resetRefArrays=()=>{
    this.refArrayEn=[];
    this.refArrayAr=[];
    this.refArrayDesc=[];
    this.refArraySeq =[];
    this.refArray=[];
  }
  
  componentWillMount(){
    if(localStorage.getItem('lang') === 'EN') {
      this.CreateRulesHeader = <Row>
        <Col span={4} style={{textAlign:'center'}}>
          <Button type="danger" onClick={() => { this.setState({ screen_opt: 'table',titleLines : [] },()=>{this.resetRefArrays(); }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={16} style={{textAlign:'center'}}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.CREATE.LABELS.TITLETYPE}</h1>
        </Col>
        <Col span={4} style={{textAlign:'center'}}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.RulesTableHeader = <Row id="clientTableHeader">
        <Col span={9}><p style={{ float: 'left', marginLeft: '5%', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.TABLEHEADERS.TITLE}</p></Col>
        <Col span={14}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ screen_opt: 'add' }) }}>{this.lang.BUTTON.ADDTITLE}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateRulesHeader = <Row>
        <Col span={4} style={{textAlign:'center'}}>
          <Button type="danger" onClick={() => { this.setState({ screen_opt: 'table',ruleLines:[] },()=>{this.resetRefArrays(); }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={16} >
          <h1 style={{ fontSize: '22px',textAlign:'center' }}>{this.lang.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={4} style={{textAlign:'center'}}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
    else {
      
      this.CreateRulesHeader = <Row>
        <Col span={4} style={{textAlign:'center'}}>
          <Button type="danger" onClick={() => { this.setState({ screen_opt: 'table',titleLines : [] },()=>{this.resetRefArrays(); }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={4}></Col>
        <Col span={8} style={{textAlign:'center'}}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.CREATE.LABELS.TITLETYPE}</h1>
        </Col>
        <Col span={4}></Col>
        <Col span={4} style={{textAlign:'center'}}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.RulesTableHeader = <Row id="clientTableHeader">
        <Col span={5}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ screen_opt: 'add' }) }}>{this.lang.BUTTON.ADDTITLE}</Button>
        </Col>
        <Col span={9}></Col>
        <Col span={8}><p style={{ textAlign: 'right', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.TABLEHEADERS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateRulesHeader = <Row>
        <Col span={4} style={{textAlign:'center'}}>
          <Button type="danger" onClick={() => { this.setState({ screen_opt: 'table',titleLines : [] },()=>{this.resetRefArrays(); }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={16} style={{textAlign:'center'}}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={4} style={{textAlign:'center'}}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }

  }//End of Method WillMount
  
  /*
  getRuleParents=(param_type)=>{
    const headers = getHeadersForHttpReq();

    axios.get(`${complete_URL}/parameters/by_param_type/${param_type}`,{headers})
      .then(res => { // console.log('rule_parents->',res.data.data)
        this.setState({ rule_parents : res.data.data[0] } )
      })
      .catch(err => { console.log('Error occured while fetching Rule Type->',err) })
  }*/

  getRulesByRuleType=(rule_type_id)=>{
    const headers = getHeadersForHttpReq();

    axios.get(`${complete_URL}/rules/by_rule_type_id/${rule_type_id}`,{headers})
      .then(res => {  //console.log('rules->',res.data.data)
        this.setState({ rules : res.data.data } )
      })
      .catch(err => { console.log('Error occured while fetching Rules->',err) })
  }

  getRuleTitles=(rule_parent)=>{
    const headers = getHeadersForHttpReq();
    const data = {
      client_id : localStorage.getItem('client_id'),
      bqt_id : this.props.bqt_id,
      rule_parent : rule_parent
    }

    axios.get(`${complete_URL}/rulestypes/titles_by_parent`,{
      headers : {headers},
      params: { data: JSON.stringify(data) }
    })
      .then(res => {
        this.setState({ rule_titles : res.data.data })
      })
      .catch(err => { console.log('Error occured while fetching Rules of selected Rule Type',err) })
  }
  
  fetch = (params={}) => {
    const client_id = localStorage.getItem('client_id');
    let headers = getHeadersForHttpReq();
      
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}
    
    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else if (params.sortOrder == 'descend') { sortOrder = 'DESC' }
    else { sortOrder = 'DESC' }
    let sortFeildNew = localStorage.getItem('lang') === 'EN' ? 'rule_title':'rule_title_ar';
    
    let data ={
      client_id ,
      bqt_id : this.props.bqt_id,
      // rule_parent : rule_parent,
      // rule_parent_name: this.state.searchValues.rule_parent_name || '',
      // rule_parent_name_ar: this.state.searchValues.rule_parent_name_ar || '', 
      rule_title: this.state.searchValues.rule_title || '',
      rule_title_ar: this.state.searchValues.rule_title_ar || '',
      title_desc: this.state.searchValues.title_desc || '',

      page,
      sortColumn: params.sortField || this.state.sortField || 'seq_no',//sortFeildNew,
      sortOrder,
      size:this.state.size
    }
    axios.post(`${complete_URL}/rulestypes/pagination_table`,data, {headers})
    .then(response => { // console.log('response of exp new pagination ->',response);
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      if (response.data.data.length > 0) {
        let ruleTitles = response.data.data;
        for (let j = 0; j < ruleTitles.length; j++) { 
          let rule_type_id = ruleTitles[j].id;
          axios.get(`${complete_URL}/rules/by_rule_type_id/${rule_type_id}`,{headers})
          .then(res => {  //console.log('rules->',res.data.data)
            Object.assign(ruleTitles[j], {rules : res.data.data})
          })
          .catch(err => { console.log('Error occured while fetching Rules ->',err) })
        }
      }
      this.setState({ data: response.data.data, pagination, spin :false},()=>{
        this.getAllSeqNosOfTitles('rulestypes', 'rulestypes_seq_nos');
      })
    })
    .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) });
  }//End of Fetch pagination

  getAllSeqNosOfTitles = (route, key)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/${route}/all_seq_no`,{headers})
      .then(res => {  //console.log('all_seq_no ->',res.data.data)
        let max_seq_no = this.getMaxSeqNo(res.data.data)
        this.setState({ [key] : res.data.data, max_seq_no : parseFloat(max_seq_no).toFixed(1) } )
      })
      .catch(err => { console.log('Error occured while fetching all seq no->',err) })
  }

  getMaxSeqNo = (arr)=>{
    if (arr.length > 0) {
      let max_seq_no = parseFloat(arr[0].seq_no);
      for (let j = 1; j < arr.length; j++) {
        if (parseFloat(arr[j].seq_no) > max_seq_no) {
          max_seq_no = arr[j].seq_no;
        }
      }//End of Loop
      return parseFloat(max_seq_no).toFixed(0);
    }
    else return 0.0;
  }//End of method
  
  checkAllUniqueSeqNo=(add_new_seq_no)=>{
    let flag = true;
    let { rulestypes_seq_nos } = this.state;
    //Check from saved Title seq nos
    for (let i = 0; i < this.refArraySeq.length; i++) {
      if (this.refArrayEn[i] && this.refArrayAr[i] && this.refArraySeq[i]) {

        let seq_no = this.refArraySeq[i].inputNumberRef.input.ariaValueNow ;
        //refArr --->add_new_seq_no
        if ( parseFloat(add_new_seq_no)=== parseFloat(seq_no)) {
          return false;
        }//End of add_new_seq_no check

        for (let j = 0; j < rulestypes_seq_nos.length; j++) {
          if (parseFloat(rulestypes_seq_nos[j].seq_no) === parseFloat(seq_no)) {
            return false;
          }
        }//End of Loop of Stored Seq no(s)#
        
        //Check from new Title seq nos in refArray
        for (let k = 0; k < this.refArraySeq.length; k++) {
          if (this.refArrayEn[k] && this.refArrayAr[k] && this.refArraySeq[k]) {
            let seq_no2 = this.refArraySeq[k].inputNumberRef.input.ariaValueNow ;
            if ( parseFloat(seq_no2)=== parseFloat(seq_no) && k !== i) {
              return false;
            }
          }
        }//End of Loop of refArray Seq no(s) duplicates check#
      }
    }//End of Loop of refArray Seq no(s)
    for (let x = 0; x < rulestypes_seq_nos.length; x++) {
      if ( parseFloat(add_new_seq_no)=== parseFloat( rulestypes_seq_nos[x].seq_no)) {
        return false;
      }//End of add_new_seq_no check
    }

    return flag;
  }

  handleSubmitTitle = (e) => {
    e.preventDefault();
    const user_id = localStorage.getItem('user_id');
    const client_id = localStorage.getItem('client_id');
    let is_EN = localStorage.getItem('lang') === 'EN'; 
    this.props.form.validateFields(['rule_title','rule_title_ar','seq_no','title_desc'],(err, values) => {
      if (!err) {
        this.setState({loading1 : true});
        let noRepeatFlag = this.checkAllUniqueSeqNo(values.seq_no);
        if (!noRepeatFlag) {
          this.setState({loading1 : false});
          message.info(this.lang.MESSAGE.SEQNOREPEATERROR,3);
        }
        else{
          let multiTitles = [];
          for (let j = 0; j < this.refArrayEn.length; j++) {
            if (this.refArrayEn[j] && this.refArrayAr[j] && this.refArraySeq[j]) {
              let t = this.refArrayEn[j].state.value ? this.refArrayEn[j].state.value : '';
              let tr = this.refArrayAr[j].state.value ? this.refArrayAr[j].state.value : '';
    
              let seq_no = this.refArraySeq[j].inputNumberRef.input.ariaValueNow ; 
              let title_desc = this.refArrayDesc[j].state.value ? this.refArrayDesc[j].state.value : '';
              let rule_title = t ? t :  tr;
              let rule_title_ar = tr ? tr : t ;
              if ( rule_title.length > 0 && rule_title_ar.length > 0 ) {//We can't afford empty Title
                let obj = { rule_title , rule_title_ar, seq_no : seq_no , title_desc};
                multiTitles.push(obj);            
              }
            }
          }
          const data = {
            client_id: client_id,
            user_id : user_id, 
            bqt_id : this.props.bqt_id,
            // rule_parent: values.rule_parent,
            rule_title: values.rule_title ? values.rule_title : '',
            rule_title_ar: values.rule_title_ar ? values.rule_title_ar : '',
            seq_no : values.seq_no,
            title_desc : values.title_desc ? values.title_desc : '',
            multiTitles : JSON.stringify(multiTitles)
          }
          let  headers = getHeadersForHttpReq();
          
          axios.post(`${complete_URL}/rulestypes/add`, data,{headers})
            .then(res => {
              this.setState({ loading1 : false })
              if (res.data.success) {
                message.success(this.lang.MESSAGE.ADDRULETITLESUCCESS,2);
                // this.props.form.setFieldsValue({rule_parent: ''});
                this.props.form.setFieldsValue({title_desc: ''});
                this.props.form.setFieldsValue({rule_title: ''});
                this.props.form.setFieldsValue({rule_title_ar: ''});
                this.fetch();
                this.resetRefArrays();
                this.setState({titleLines : [],screen_opt: 'table' });
              }
              else{
                if(res.data.code==='001') {
                  message.error(this.lang.MESSAGE.TITLEEXISTSERROR,3)
                }
                else{
                  message.error(this.lang.MESSAGE.ADDRULETYPEFAILURE,3)
                }
              }
            })
            .catch(err => {
              console.log('Error occured while adding the Rule Title->',err);
              this.setState({ loading1 : false,},()=>{
                message.error(this.lang.MESSAGE.ADDRULETYPEFAILURE,2);
              })
            })

        }//End of no repeat Seq no(s)
      }//End of if error not found
    })
  }//End of Method


  deleteItemFromArray=(arr, index)=>{
    let new_Arr = []; 
    for (let i = 0; i < arr.length; i++) {
      if(i!== index){
        new_Arr.push(arr[i]);
      }
    }
    return new_Arr;
  }

  removeAddedTitleLine=(index)=>{
    if ( this.state.titleLines.length === 1 ) {
      this.resetRefArrays();
      this.setState({titleLines:[]});
    }
    else{
      this.refArraySeq = this.deleteItemFromArray(this.refArraySeq, index);
      this.refArrayEn = this.deleteItemFromArray(this.refArrayEn, index);
      this.refArrayAr = this.deleteItemFromArray(this.refArrayAr, index);
      this.refArrayDesc = this.deleteItemFromArray(this.refArrayDesc, index);
      this.refArray = this.deleteItemFromArray(this.refArray, index);
      
      let oldLines = Object.assign([],this.state.titleLines);
      oldLines.splice(index,1);
      this.setState({titleLines : oldLines },()=>{
        if (oldLines.length === 0) {//Confirm to remove all refs
          this.resetRefArrays();
        }
      });
    }
  }//End of removeAddedTitleLine

  addTitleLine=()=>{
    let { titleLines , max_seq_no } = this.state;

    let line = 
      <div key={'row_main'+titleLines.length} ref= {(node)=>{this.refArray.push(node)}} >
        <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
          <Col span={23}></Col>
          <Col span={1}>
      <Icon type="close" onClick={() => {this.removeAddedTitleLine(titleLines.length)}} style={{fontSize: '18px'}} title = {this.lang.BUTTON.DELETE} />
          </Col>
        </Row>
      <Row gutter={24} key={'row_en'+titleLines.length}  dir= {this.state.dir} style={{display : this.state.display, marginBottom:'2.5%' }}>
        <Col span={5}>
          <InputNumber min={0.0} step={0.1} style={{ width: '100%' }} defaultValue={(parseFloat(max_seq_no) + titleLines.length + 2.0)} ref= {(node)=>{this.refArraySeq.push(node)}} />
        </Col>
        <Col span={19}>
          {/* <Form.Item label={this.lang.LABELS.RULETITLE}>
            {getFieldDecorator('rule_title'+titleLines.length, {
              rules: [{ required: is_EN, message: this.lang.MESSAGE.INSERTTITLEERROR }],
            })( */}
                <Input.TextArea required rows={2} dir={'ltr'} ref= {(node)=>{this.refArrayEn.push(node)}} placeholder={this.lang.LABELS.RULETITLE} />
            {/* )}
          </Form.Item> */}
        </Col>
        </Row>
      <Row gutter={24} key={'row_ar'+titleLines.length}  dir= {this.state.dir} style={{display : this.state.display,marginBottom:'2.5%' }}>
      <Col span={5}></Col>
        <Col span={19}>
          {/* <Form.Item label={this.lang.LABELS.RULETITLEAR}>
            {getFieldDecorator('rule_title_ar'+titleLines.length, {
              rules: [{ required: !is_EN, message: this.lang.MESSAGE.INSERTTITLEERROR }],
            })( */}
                <Input.TextArea required rows={2} dir={'rtl'} ref= {(node)=>{this.refArrayAr.push(node)}} placeholder={this.lang.LABELS.RULETITLEAR} />
            {/* )}
          </Form.Item> */}
        </Col>
      </Row>
      <Row gutter={24} key={'row_desc'+titleLines.length}  dir= {this.state.dir} style={{display : this.state.display,marginBottom:'2.5%' }}>
        <Col span={5}></Col>
        <Col span={19}>
        <Input.TextArea rows={2} ref= {(node)=>{this.refArrayDesc.push(node)}} dir= {this.state.dir} placeholder={this.lang.LABELS.DESCRIPTION} />
        </Col>
      </Row>
      <br/>
      </div>

    let oldLines = Object.assign([], titleLines);
    this.setState({titleLines : oldLines.concat(line) })
  }//End of Method


  openTitleTable=()=>{
    this.setState({screen_opt:'table', titleLines:[]},()=>{
      this.resetRefArrays();
      this.fetch();
    })
  }

  //Incomplete method for removing left margin from Delete icon in Rules
  updateCss =()=>{
    let div = document.getElementsByClassName("ant-list-item-action");
    console.log("div->",div);
    /*let style = {'flex': '0 0 auto', 'margin-left': 0, 'padding': 0, 'font-size': 0,
      'list-style': 'none'}
    div.setAttribute('style', style);*/
  }

  render() {
    const { getFieldDecorator } = this.props.form
    let is_EN = localStorage.getItem('lang') === 'EN';
    let { /*rule_parents, rule_titles,*/ titleLines,  max_seq_no } = this.state;
    let fontStyle = {fontSize: is_EN ? '13.5px':'15px', fontWeight:is_EN ? 400:500}
    
    const columns = [
    //   is_EN ?
    //   {
    //     title: (
    //       <span>{this.lang.LABELS.RULEPARENTNAME}
    //       <Search
    //         placeholder={this.lang.LABELS.RULEPARENTNAME}
    //         onChange={this.handleChangeSearchValues('rule_parent_name')}
    //       />
    //     </span>
    //   ),
    //   dataIndex: 'rule_parent_name',
    //   key: 'rule_parent_name',
    //   width: '18%',
    //   align : 'left',
    //   sorter: (a, b) => a.rule_parent_name.length - b.rule_parent_name.length,
    //   sortOrder: this.state.sortField === "rule_parent_name" && this.state.sortOrder,
    // }:
    // {
    //   title: (
    //     <span>{this.lang.LABELS.RULEPARENTNAMEAR}
    //     <Search
    //       placeholder={this.lang.LABELS.RULEPARENTNAMEAR}
    //       // onSearch={value => console.log('')}
    //       onChange={this.handleChangeSearchValues('rule_parent_name_ar')}
    //     />
    //   </span>
    // ),
    // dataIndex: 'rule_parent_name_ar',
    // key: 'rule_parent_name_ar',
    // width: '15%',
    // align : 'right',
    // sorter: (a, b) => a.rule_parent_name_ar.length - b.rule_parent_name_ar.length,
    // sortOrder: this.state.sortField === 'rule_parent_name_ar' && this.state.sortOrder,
    // },
    {
      title : this.lang.LABELS.SEQ_NO,
      dataIndex: 'seq_no',
      key : 'seq_no',
      width : '15%',
      align: 'center',
			sorter: (a, b) => a.seq_no.length - b.seq_no.length,
      sortOrder: this.state.sortField === 'seq_no' && this.state.sortOrder,
    render: (text, record) => <p style={fontStyle}>{text}</p>
    },
    is_EN ?
    {
      title: (
        <span>{this.lang.LABELS.RULETITLE}
          <Search
            allowClear
            placeholder={this.lang.LABELS.RULETITLE}
            onChange={this.handleChangeSearchValues('rule_title')}
          />
        </span>
      ),
      dataIndex: 'rule_title',
      key: 'rule_title',
      width: '45%',
      // align : is_EN ? 'left':'right',
      sorter: (a, b) => a.rule_title.length - b.rule_title.length,
      sortOrder: this.state.sortField === 'rule_title' && this.state.sortOrder,
      render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}><p style={{ fontSize:'14px',textAlign:'left'}}>{text}</p></button>,
    }:
    {
      title: (
        <span>{this.lang.LABELS.RULETITLE}
          <Search
            allowClear
            placeholder={this.lang.LABELS.RULETITLE}
            onChange={this.handleChangeSearchValues('rule_title_ar')}
          />
        </span>
      ),
      dataIndex: 'rule_title_ar',
      key: 'rule_title_ar',
      width: '45%',
      align : is_EN ? 'left':'right',
      sorter: (a, b) => a.rule_title_ar.length - b.rule_title_ar.length,
      sortOrder: this.state.sortField === 'rule_title_ar' && this.state.sortOrder,
      render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}><p style={{ fontSize:'16px',fontWeight: 600, textAlign:'left'}}>{text}</p></button>,
    }, 
    {
      title: (
        <span>{this.lang.LABELS.DESCRIPTION}
          <Search
            allowClear
            placeholder={this.lang.LABELS.DESCRIPTION}
            onChange={this.handleChangeSearchValues('title_desc')}
          />
        </span>
      ),
      dataIndex: 'title_desc',
      key: 'title_desc',
      width: '20%',
      align : is_EN ? 'left':'right',
      onCell: () => {
        return {
           style: {
              whiteSpace: 'nowrap',
              maxWidth: 150,
           }
        }
     },
      sorter: (a, b) => a.title_desc.length - b.title_desc.length,
      sortOrder: this.state.sortField === 'title_desc' && this.state.sortOrder,
      // render: (text, record) => (
      //   text ? 
      //   <p style={{ textAlign:'left'}}>{text}</p>:<p>{'Not Found'}</p>
      // )
      render: (text) => (
        text ? 
        <Tooltip title={text}>
           <div style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{text}</div>
        </Tooltip>
        :<p>{'--'}</p>
     )
    },
    // is_EN ?
    // {
    //   title: (
    //     <span>{this.lang.LABELS.DESCRIPTION}
    //       <Search
    //         placeholder={this.lang.LABELS.DESCRIPTION}
    //         onChange={this.handleChangeSearchValues('description')}
    //       />
    //     </span>
    //   ),
    //   dataIndex: 'description',
    //   key: 'description',
    //   width: '54%',
    //   align : 'left',
    //   sorter: (a, b) => a.description.length - b.description.length,
    //   sortOrder: this.state.sortField === 'description' && this.state.sortOrder,
    //   ellipsize:true 
    // }:
    // {
    //   title: (
    //     <span>{this.lang.LABELS.DESCRIPTIONAR}
    //       <Search
    //         placeholder={this.lang.LABELS.DESCRIPTIONAR}
    //         onChange={this.handleChangeSearchValues('description_ar')}
    //       />
    //     </span>
    //   ),
    //   dataIndex: 'description_ar',
    //   key: 'description_ar',
    //   width: '55%',
    //   align : 'right',
    //   sorter: (a, b) => a.description_ar.length - b.description_ar.length,
    //   sortOrder: this.state.sortField === 'description_ar' && this.state.sortOrder,
    // },
    {
      title: `${this.lang.LABELS.ACTION}`,
      render: (text, record) => (
        <span>
          <Button type="primary" onClick={this.deleteCategory.bind(this, record,'rulestypes')}>{this.lang.BUTTON.DELETE}</Button>
        </span>
      ),
      width: '10%',
      align : 'center',
      key: 'action',
    }
    ]; 


  return (
    <Row gutter={24}>
      <Col span={24}>
      {
        this.state.screen_opt === 'table' &&
        <>{this.RulesTableHeader}
        <Spin tip={this.state.spin_msg} spinning = {this.state.spin}>
        <Row id="clientsTable">
          <Col span={24} >
            <Table
              columns={columns}
              rowKey="id"
              size={'small'}
              dataSource={this.state.data}
              expandedRowRender={rule_title => (
                <List
                  bordered
                  size="small"
                  rowKey = {'id'}
                  itemLayout="horizontal"
                  dataSource={rule_title.rules}
                  renderItem={(item, index )=> (
                    <>
                        {/* <List.Item  actions={[<Button type="primary" onClick={() => {this.deleteCategory(item,'rules')}}>{this.lang.BUTTON.DELETE}</Button>]}> */}
                        <List.Item  actions={[<Icon type="delete" onClick={() => {this.deleteCategory(item,'rules')}} style={{  fontSize: '20px'}} title = {this.lang.BUTTON.DELETE} /> ]}>
                          <List.Item.Meta
                            description = {
                              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                                <Col span={3}><p style={{ color:'#000',...fontStyle }}>{item.seq_no}</p> </Col>
                                <Col span={21}><p style={{ color:'#000',...fontStyle}}>{is_EN ? item.description : item.description_ar}</p> </Col>
                              </Row>
                            }
                          />
                        </List.Item>
                      </>
                  )}
                />
              )}
              onChange={this.handleChangePagination}
              pagination={this.state.pagination}
            />
          </Col>
        </Row>
        </Spin>
        </>
      }
      {
        this.state.screen_opt === 'add' &&
        <Spin tip={''} spinning = {false}>
          <Form onSubmit={this.handleSubmitTitle} style={{ marginTop: '20px' }}>{/*Title addition */}
            {this.CreateRulesHeader}
            
            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
              {/* <Col span={10}>
                <Form.Item label={this.lang.LABELS.RULEPARENTNAME}>
                  {getFieldDecorator('rule_parent', {
                    rules: [{ required: true, message: this.lang.MESSAGE.SELECTTYPEERROR }],
                  })(
                    <Select onChange={this.getRuleTitles} placeholder={this.lang.LABELS.RULEPARENTNAME} >
                      {
                        (localStorage.getItem('lang') === 'EN') ?
                          rule_parents.length > 0 && rule_parents.map(rp => (
                            <Select.Option key={rp.id} value={rp.id}>{rp.name}</Select.Option>
                          ))
                          :rule_parents.length > 0 &&  rule_parents.map(rp => (
                            <Select.Option style={{textAlign:'right'}} key={rp.id} value={rp.id}>{rp.name_ar}</Select.Option>
                          ))
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col> */}
              <Col span={5}>
                <Form.Item label={this.lang.LABELS.SEQ_NO}>
                  {getFieldDecorator('seq_no', {
                    initialValue: (parseFloat(max_seq_no) + 1 ),
                    rules: [{ required: true, message: this.lang.MESSAGE.INSERTSEQERROR }],
                  })(
                    <InputNumber min={0} step={0.1} style={{ width: '100%' }} />
                  )}
                </Form.Item>
              </Col>
              <Col span={19}>
                <Form.Item label={this.lang.LABELS.RULETITLE}>
                  {getFieldDecorator('rule_title', {
                    rules: [{ required: true, message: this.lang.MESSAGE.INSERTTITLEERROR }],
                  })(
                     <Input.TextArea rows={2} dir={'ltr'} placeholder={this.lang.LABELS.RULETITLE} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
            <Col span={5}></Col>
              <Col span={19}>
              <Form.Item label={this.lang.LABELS.RULETITLEAR}>
                {getFieldDecorator('rule_title_ar', {
                  rules: [{ required: true, message: this.lang.MESSAGE.INSERTTITLEERROR }],
                })(
                  <Input.TextArea rows={2} dir={'rtl'} placeholder={this.lang.LABELS.RULETITLEAR} />
                )}
              </Form.Item>
            </Col>
            </Row>
            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
              <Col span={5} style={{ marginTop: '50px' }}>
                <Button style={{backgroundColor:'#28a745', color:'white'}} onClick={() =>this.addTitleLine()}> {this.lang.BUTTON.ADD}  </Button>
              </Col>  
              <Col span={19}>
              <Form.Item label={this.lang.LABELS.DESCRIPTION}>
                {getFieldDecorator('title_desc', {
                  rules: [{ required: false, message: this.lang.MESSAGE.INSERTTITLEDESCERROR }],
                })(
                  <Input.TextArea rows={2} dir= {this.state.dir} placeholder={this.lang.LABELS.DESCRIPTION} />
                )}
              </Form.Item>
              </Col>
            </Row>  
            {titleLines}
            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
              <Col span={8}></Col>
              <Col span={8} style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Button type="primary" block htmlType="submit" loading={this.state.loading1}>
                  {this.lang.BUTTON.SAVE} 
                </Button>
              </Col>
              <Col span={8}></Col>
            </Row>
          </Form>
        </Spin>
      }
      {
        this.state.screen_opt === 'update' &&
        <RuleTitleUpdate 
          {...this.props}
          bqt_id = {this.props.bqt_id}  
          lang = {this.props.language._RULE} 
          getMaxSeqNoByParent = {this.getMaxSeqNo}
          openTitleTableByParent = {this.openTitleTable}
          deleteItemFromArrayByParent = {this.deleteItemFromArray}
        />
      }
      </Col>
    </Row>
    );
  
  }//End of RENDER
}//End of Component

export default Rules = Form.create({ name: 'normal_login' })(Rules);