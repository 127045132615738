import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, Card, message, InputNumber, DatePicker, Switch, Select } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios';
import moment from 'moment';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const TextArea = Input.TextArea;
const complete_URL = API_URL+pt;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class OfferUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      object:{},
      banquets: [],
      halls: [],
      hallPrice: [],
      contractors: [],
      packagesData : [],
      offerOption: 0,
      discountOption : 0,
      offerOptions: [{'id':0,'name':'Package','name_ar':'حزمة من القاعات'},{'id':1,'name':'Hall','name_ar':'قاعه داخلية'}], 
      discountOptions: [{'id':0,'name':'Percentage','name_ar':'بنسبة مئوية'},{'id':1,'name':'Amount','name_ar':'حسب الكمية'}],
      uom:true,
      image: '',
      dir : 'ltr', 
      display: 'block',
      startValue: null,
      endValue: null,
      endOpen: false,
  }
    this.lang = props.language._OFFER;

}

  setComponentDirection=()=>{
    if(localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    this.getOfferObject();
    this.setComponentDirection();
  }

  getOfferObject =()=>{
    const headers = getHeadersForHttpReq();
    let authOptions = {
        method: "GET",
        url: complete_URL+'/offers/offersById',
        params: {
          id:localStorage.getItem('offerId')
        },
        headers
      }
    
      axios(authOptions)
        .then(res => {
          if(res.data.success){
            // console.log('offerUpd recv obj->',res.data.object);
            this.setState({object:res.data.object},()=>{this.fetch()})
            // message.success(res.data.message)
          }
          else{ message.error(res.data.message);  }
        })
        .catch(err => { 
          console.log('Error occured while fetching selected Offer data->',err);
          message.error(err.message);
        })
  }

  fetch =()=>{
    this.getBanquets()
    if(this.state.object.BANQUET){
      this.selectBanquet(this.state.object.BANQUET);//fetch Halls Of selected banquet
    }
    // this.getContractors();
    this.getAssignedContByClient();
    if(this.state.object.CONTRACTOR_ID){
      this.fetchPackageDataByContractorId(this.state.object.CONTRACTOR_ID);//fetch Packages Of selected Contractor
    }
    if(this.state.object.IMAGE_ID){
        this.getOfferImageBanner(this.state.object.IMAGE_ID);
    }
    this.setState({offerOption:this.state.object.OFFER_OPTION,
                   discountOption : this.state.object.DISCOUNT_OPTION
                  })
  }
  // unncessary code ,, not being used
  deleteCategory(e) {
    const headers = getHeadersForHttpReq();
    let authOptions = {
        method: "POST",
        url: complete_URL+'/offers/deleteOffer',
        data: {
          id:    localStorage.getItem('offerId')
        },
        headers
      }
    
      axios(authOptions)
        .then(res => {
          if(res.data.success){
            message.success(res.data.message)
            e.props.history.push('/dashboard/offers')
          }
          else{ message.error(res.data.message); }
        })
        .catch(err => {
          message.error(err.message)
        });
  }
 
  handleSubmit = (e) => {
    e.preventDefault();
    const client_id = localStorage.getItem('client_id')
    const user_id = localStorage.getItem('user_id')
    const headers =  getHeadersForHttpReq();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        Object.assign(values,{
          client_id : client_id,
          user_id : user_id,
          id : localStorage.getItem('offerId'),
          from_date : moment(values.from_date).locale('en').format('YYYY-MM-DD'),
          to_date : moment(values.to_date).locale('en').format('YYYY-MM-DD'),
          from_time : '00:00:00',
          to_time : '00:00:00',
          contractor : this.state.offerOption == 0  ? values.contractor : '',
          package : this.state.offerOption == 0  ?  values.package : '',
          banquet : this.state.offerOption == 1  ?  values.banquet : '',
          hall : this.state.offerOption == 1  ? values.hall : '',
          comm_status : values.comm_status ? 1 : 0,
          status : values.status ? 1 : 0
        })

        // values.client_id = client_id
        // values.user_id = user_id
        // values.id =  localStorage.getItem('offerId')
        // values.from_date = moment(values.from_date).locale('en').format('YYYY-MM-DD')
        // values.to_date = moment(values.to_date).locale('en').format('YYYY-MM-DD')
        // values.from_time = moment(values.from_time).locale('en').format('HH:mm:ss')
        // values.to_time = moment(values.to_time).locale('en').format('HH:mm:ss')
        // values.comm_status = values.comm_status ? 1 : 0
        // values.status = values.status ? 1 : 0
        
        axios.post(`${complete_URL}/offers/offerUpdate`, values, {headers})
          .then(res => { /*console.log(res)*/
            if(res.data.success){
              message.success(this.lang.MESSAGE.UPDATESUCCESS);
              this.props.history.push('/dashboard/offers');
            }
            else{ message.error(this.lang.MESSAGE.UPDATEFAILURE,3); }
          })
          .catch(err => {
            console.log('Error occured while updating the Offer->',err)
            message.error(err.message,3);
          })
      }
    })
  }//End of Method

  getBanquets = () =>{
    const headers =  getHeadersForHttpReq();
    const client_id = localStorage.getItem('client_id');
    if (client_id) {
      axios.get(`${complete_URL}/banquets/by_clients/${client_id}`,{headers})
        .then(res => {
          this.setState({ banquets: res.data.data[0], currentDate: moment(new Date()) })
        })
        .catch(err => { console.log('Error occured while fetching Banquets ->',err) })
    }//End of Client ID check
  }//End of Method

  selectBanquet = (v) => {
    const headers =  getHeadersForHttpReq();
    axios.get(`${complete_URL}/halls/by_banquet/${v}`,{headers})
        .then(res => {
          this.setState({ halls: res.data.data[0] })
        })
        .catch(err => { console.log('Error occured while fetching Halls by Banquet',err) })
  }//End of Method

  selectHall = (v) => {
    const headers =  getHeadersForHttpReq();
    axios.get(`${complete_URL}/hallprices/reservation/${v}`,{headers})
      .then(result => {
          this.setState({ hallPrice: result.data.data[0] })
      })
      .catch(err => { console.log('Error occured while fetching Halls Price->',err) })
  }//End of Method
  //Old method for fetching the Contractors(Before Contractor feature) 
  getContractors =()=>{
    const headers =  getHeadersForHttpReq();
    axios.get(`${complete_URL}/contractors/fetch_by_clientId/${localStorage.getItem('client_id')}`,{headers})
    .then(res => { 
      this.setState({ contractors: res.data.data}/*,()=>{console.log('Recv data of contractors in offer',this.state.contractors)}*/)
    })
    .catch(err => { console.log('Error occured while fetching Contractors By Client->',err) })
  }//End of Method
  
  //New ajax API call for fetching Assigned independent contractors( of selected Service Type)
  getAssignedContByClient=()=>{
    const headers = getHeadersForHttpReq();
    const client_id = localStorage.getItem('client_id');
    axios.get(`${complete_URL}/contractors/independent/${client_id}`,{headers})
      .then(res => { // console.log('ind contractors->',res.data.data);
        this.setState({ contractors: res.data.data });
      })
      .catch(err => { console.log('Error occured while fetching Assigned Contractors->',err) })
  }//End of Method

  fetchPackageDataByContractorId = (contractorId) => {
    const headers = getHeadersForHttpReq();
    // let id = JSON.parse(localStorage.getItem('updateContractor')).id
    axios.get(`${complete_URL}/contractorpackages/fetch_by_contractor/${contractorId}`,{headers})
    .then(res => { /*console.log(res.data.data[0])*/
      this.setState({ packagesData: res.data.data })
    })
    .catch(err => { console.log('Error occured while fetching Packages of selected Contractor->',err) });
  }//End of Method

  handleImageUpload = async (e) => {
      e.preventDefault();
      // console.log(e)
      if (e.currentTarget.files && e.currentTarget.files[0]) {
        let file = e.currentTarget.files[0];
        let fileName = file.name;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (file.size <= 1024 * 1024 * 2 && /png|jpe?g/i.test(extension)) {
          let a;
          let imgdata = new FormData();
          imgdata.append('file', file)
          imgdata.append('client_id', localStorage.getItem('client_id'))
          imgdata.append('offer_id', localStorage.getItem('offerId'))
         
          const headers = {
            'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
            'Content-Type': 'multipart/form-data'
          }
          await axios.post(`${complete_URL}/images/offerBanner/addImage`, imgdata, { responseType: 'blob' }, headers)
            .then(res => { /*console.log(res)*/
              let reader = new FileReader();
              reader.readAsDataURL(res.data);
              reader.onloadend = () => {
                a = reader.result;
                this.setState({ image: a  });
              }
            })
            .catch(err => { console.log('Error occured while fetching Image of Offer->',err) })
        }//End of Check if file is Selected
      }
  }// End of Method

  getOfferImageBanner = async (image_id) =>{
    const headers = getHeadersForHttpReq();
      await axios.get(`${complete_URL}/images/${image_id}`, { responseType: 'blob' },{headers})
      .then(res => {
        var reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          this.setState({ image: reader.result })
        }
      })
      .catch(err => { console.log(err) })
  }// End of Method

  handleOfferTypeSelection =(val)=>{
      let temp = {...this.state.object};
      temp.OFFER_OPTION = val;
      this.setState({offerOption:val, object: temp});
  }

  handleDiscountTypeSelection =(val)=>{
      let temp = {...this.state.object};
      temp.DISCOUNT_OPTION = val;
      this.setState({discountOption:val, object: temp},()=>{
        if (val == 0) {
          this.props.form.setFieldsValue({ discount_amount : 0.00})
        }
        else {
          this.props.form.setFieldsValue({ discount  : 0.00})
        }
      });
  }
  
  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.OfferUpdateHeader = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/offers">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={12}> <h1 style={{textAlign: 'center'}}>{this.lang.UPDATE.OFFERUPDATE.LABELS.TITLE}</h1> </Col>
        <Col className="btn-box" span={6} style={{display: 'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/offers">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.OfferUpdateHeader = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/offers">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign: 'center'}}>{this.lang.UPDATE.OFFERUPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to="/dashboard/offers">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }// End of Method

  disabledStartDate = (startValue) => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  }

  disabledEndDate = (endValue) => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  }
  
  onChange = (field, value) => {
    this.setState({ [field]: value });
  }

  onStartChange = (value) => {
    this.onChange('startValue', value);
  }

  onEndChange = (value) => {
    this.onChange('endValue', value);
  }

  handleStartOpenChange = (open) => {
    // console.log(open)
    if (!open) {
      this.setState({ endOpen: true });
    }
  }
  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open});
  }
    
  render() {
    const { getFieldDecorator } = this.props.form;
    let { dir, display, contractors, packagesData, banquets, halls, offerOptions, discountOptions } = this.state;

    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.OfferUpdateHeader}
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.UPDATE.OFFERUPDATE.LABELS.NAME}>
                    {getFieldDecorator('name', {
                        initialValue:this.state.object.NAME,
                      rules: [{ required: true, message: this.lang.MESSAGE.INSERTNAMEERROR }],
                    })(
                      <Input readOnly = { this.state.object.STATUS == 1 } placeholder={this.lang.UPDATE.OFFERUPDATE.LABELS.NAME} type="text" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.UPDATE.OFFERUPDATE.LABELS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      initialValue:this.state.object.NAME_AR,
                      rules: [{ required: false, message: this.lang.MESSAGE.INSERTNAMEARERROR }],
                    })(
                      <Input readOnly = { this.state.object.STATUS == 1 } type="text" placeholder={this.lang.UPDATE.OFFERUPDATE.LABELS.NAMEAR} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={dir === 'ltr' ? 5 : 6}>
                  <Form.Item label={this.lang.UPDATE.OFFERUPDATE.LABELS.OFFERTYPE} >
                      {getFieldDecorator('offerOption', {
                        initialValue: this.state.object.OFFER_OPTION,
                        rules: [{ required: true, message: this.lang.MESSAGE.SELECTOFFERTYPEERROR }],
                      })(
                      <Select disabled = { this.state.object.STATUS == 1 } onChange={(e)=>this.handleOfferTypeSelection(e)} placeholder={this.lang.UPDATE.OFFERUPDATE.LABELS.OFFERTYPE} >
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            offerOptions.length > 0 && offerOptions.map(offer => (
                              <Select.Option  key={offer.id} value={offer.id}>{offer.name}</Select.Option>
                            ))
                            : offerOptions.length > 0 && offerOptions.map(offer => (
                              <Select.Option style={{textAlign:'right'}} key={offer.id} value={offer.id}>{offer.name_ar}</Select.Option>
                            ))
                        }
                      </Select>)}
                    </Form.Item>
                </Col>
                {
                  this.state.object.OFFER_OPTION == 0 &&
                  <React.Fragment>
                    <Col span={ 7}>
                    <Form.Item label= {this.lang.CREATE.LABELS.CONTRACTOR}>
                      {getFieldDecorator('contractor', {
                        initialValue: this.state.object.CONTRACTOR_ID,
                        rules: [{ required: true, message:  this.lang.MESSAGE.SELECTCONTRACTORERROR  }],
                      })(
                      <Select disabled = { this.state.object.STATUS == 1 } onChange={this.fetchPackageDataByContractorId} placeholder= {this.lang.CREATE.LABELS.CONTRACTOR}>
                          {
                          (localStorage.getItem('lang') === 'EN') ?
                            contractors.length > 0 && contractors.map(contractor => (
                              <Select.Option key={contractor.id} value={contractor.id}>{contractor.name}</Select.Option>
                            ))
                            : contractors.length > 0 && contractors.map(contractor => (
                              <Select.Option style={{textAlign:'right'}} key={contractor.id} value={contractor.id}>{contractor.name_ar}</Select.Option>
                            ))
                          }
                        </Select>)}
                      </Form.Item>
                    </Col>
                    <Col span={ 7}>
                      <Form.Item label= {this.lang.CREATE.LABELS.PACKAGE}>
                        {getFieldDecorator('package', {
                          initialValue: this.state.object.PACKAGE_ID,
                          rules: [{ required: true, message:  this.lang.MESSAGE.SELECTPACKAGEERROR }],
                        })(
                        <Select disabled = { this.state.object.STATUS == 1 } placeholder= {this.lang.CREATE.LABELS.PACKAGE} >
                          {
                            (localStorage.getItem('lang') === 'EN') ?
                              packagesData.length > 0 && packagesData.map(pkg => (
                                <Select.Option key={pkg.id} value={pkg.id}>{pkg.type}</Select.Option>
                              ))
                              : packagesData.length > 0 && packagesData.map(pkg => (
                                <Select.Option style={{textAlign:'right'}} key={pkg.id} value={pkg.id}>{pkg.type}</Select.Option>
                              ))
                          }
                          </Select>)}
                      </Form.Item>
                    </Col>
                    <Col span={dir === 'ltr' ? 5 : 4}>
                      <Form.Item label= {this.lang.CREATE.LABELS.COMMSTATUS}>
                      {getFieldDecorator('comm_status', { 
                        valuePropName: 'checked', initialValue:this.state.object.COMM_STATUS == 1 ? true : false })(<Switch disabled = { this.state.object.STATUS == 1 } />)}
                      </Form.Item>
                    </Col>

                  </React.Fragment>
                }
                {
                  this.state.object.OFFER_OPTION == 1 &&
                  <React.Fragment>
                    <Col span={8}>
                    <Form.Item label= {this.lang.CREATE.LABELS.BANQUET} >
                      {getFieldDecorator('banquet', {
                        initialValue: this.state.object.BANQUET,
                        rules: [{ required: true, message:  this.lang.MESSAGE.SELECTBANQUETERROR }],
                      })(
                      <Select disabled = { this.state.object.STATUS == 1 } onChange={this.selectBanquet} placeholder= {this.lang.CREATE.LABELS.BANQUET}>
                          {
                          (localStorage.getItem('lang') === 'EN') ?
                            banquets.length > 0 && banquets.map(banquet => (
                              <Select.Option key={banquet.id} value={banquet.id}>{banquet.name}</Select.Option>
                            ))
                            : banquets.length > 0 && banquets.map(banquet => (
                              <Select.Option style={{textAlign:'right'}} key={banquet.id} value={banquet.id}>{banquet.name_ar}</Select.Option>
                            ))
                        }
                        </Select>)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label= {this.lang.CREATE.LABELS.HALL}>
                        {getFieldDecorator('hall', {
                          initialValue: this.state.object.HALL,
                          rules: [{ required: true, message: this.lang.MESSAGE.SELECTHALLERROR  }],
                        })(
                        <Select disabled = { this.state.object.STATUS == 1 } placeholder= {this.lang.CREATE.LABELS.HALL}>
                            {
                            (localStorage.getItem('lang') === 'EN') ?
                              halls.length > 0 && halls.map(hall => (
                                <Select.Option key={hall.id} value={hall.id}>{hall.name}</Select.Option>
                              ))
                              : halls.length > 0 && halls.map(hall => (
                                <Select.Option style={{textAlign:'right'}} key={hall.id} value={hall.id}>{hall.name_ar}</Select.Option>
                              ))
                          }
                          </Select>)}
                      </Form.Item>
                    </Col>
                  </React.Fragment>

                }
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={8}>
                  <Form.Item label=  {this.lang.CREATE.LABELS.FROMDATE}>
                    {getFieldDecorator('from_date', {
                      initialValue:moment(this.state.object.FROM_DATE),
                      rules: [{ required: true, message: this.lang.MESSAGE.INSERTFROMDATEERROR }],
                    })(
                      // <DatePicker disabled = { this.state.object.STATUS == 1 } type="text" placeholder="From Date" dateFormat='DD-MM-YYYY' style={{ width: '100%' }} />
                      <DatePicker
                          disabled = { this.state.object.STATUS == 1 } 
                          disabledDate={this.disabledStartDate}
                          format="YYYY-MM-DD"
                          placeholder={this.lang.CREATE.LABELS.FROMDATE}
                          onChange={this.onStartChange}
                          onOpenChange={this.handleStartOpenChange}
                          style={{ width: '100%' }} 
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label= {this.lang.CREATE.LABELS.TODATE}>
                    {getFieldDecorator('to_date', {
                      initialValue:moment(this.state.object.TO_DATE),
                      rules: [{ required: true, message: 'Please input End Date!' } ],
                    })(
                      // <DatePicker disabled = { this.state.object.STATUS == 1 } type="text" placeholder="To Date" dateFormat='DD-MM-YYYY' style={{ width: '100%' }}/>
                      <DatePicker
                          disabled = { this.state.object.STATUS == 1 } 
                          disabledDate={this.disabledEndDate}
                          format="YYYY-MM-DD"
                          placeholder={this.lang.CREATE.LABELS.TODATE}
                          onChange={this.onEndChange}
                          open={this.state.endOpen}
                          onOpenChange={this.handleEndOpenChange}
                          style={{ width: '100%' }} 
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label= {this.lang.UPDATE.OFFERUPDATE.LABELS.FINALIZESTATUS}>
                      {getFieldDecorator('status', { 
                        valuePropName: 'checked', 
                        initialValue: this.state.object.STATUS == 1 ? true : false 
                      })(
                        <Switch disabled = { this.state.object.STATUS == 1 } /> 
                      )}
                    </Form.Item>
                  </Col>
              </Row>
              
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={8}>
                  <Form.Item label= {this.lang.CREATE.LABELS.DISCOUNTTYPE}>
                        {getFieldDecorator('discountOption', {
                          initialValue:this.state.object.DISCOUNT_OPTION,
                          rules: [{ required: true, message: this.lang.MESSAGE.SELECTDISCOUNTTYPEERROR }],
                        })(
                        <Select disabled = { this.state.object.STATUS == 1 } onChange={(e)=>this.handleDiscountTypeSelection(e)} placeholder={this.lang.CREATE.LABELS.DISCOUNTTYPE}>
                          {
                            (localStorage.getItem('lang') === 'EN') ?
                              discountOptions.length > 0 && discountOptions.map(discount => (
                                <Select.Option key={discount.id} value={discount.id}>{discount.name}</Select.Option>
                              ))
                              : discountOptions.length > 0 && discountOptions.map(discount => (
                                <Select.Option style={{textAlign:'right'}} key={discount.id} value={discount.id}>{discount.name_ar}</Select.Option>
                              ))
                          }
                          </Select>)}
                      </Form.Item>
                  </Col>
                <Col span={8}>
                    <Form.Item label= {this.lang.CREATE.LABELS.DISCOUNTPERCENTAGE}>
                      {getFieldDecorator('discount', {
                        initialValue:this.state.object.DISCOUNT_PERCENT,
                        rules: [{ required: true, message: this.lang.MESSAGE.INSERTPERCENTERROR }],
                      })(
                      <InputNumber disabled = { this.state.object.DISCOUNT_OPTION == 1 } type="text" max ={100.00} min={0.00} readOnly = { this.state.object.STATUS == 1 } placeholder={this.lang.CREATE.LABELS.DISCOUNTPERCENTAGE} style={{width:'100%'}}/>
                      )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label= {this.lang.CREATE.LABELS.DISCOUNTAMOUNT}>
                    {getFieldDecorator('discount_amount', {
                      initialValue:this.state.object.DISCOUNT_AMOUNT,
                      rules: [{ required: true, message: this.lang.MESSAGE.INSERTAMOUNTERROR  }],
                    })(
                    <InputNumber disabled = { this.state.object.DISCOUNT_OPTION == 0 } type="text" min={0.00} readOnly = { this.state.object.STATUS == 1 } placeholder={this.lang.CREATE.LABELS.DISCOUNTAMOUNT} style={{width:'100%'}}/>
                    )}
                  </Form.Item>
                </Col>                  
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={16}>
                  <Form.Item label={this.lang.UPDATE.OFFERUPDATE.LABELS.DESCRIPTION}>
                    {getFieldDecorator('description', {
                      initialValue:this.state.object.DESCRIPTION,
                      rules: [{ required: false, message: this.lang.MESSAGE.INSERTDESCRIPTIONERROR }],
                    })(
                      <TextArea  readOnly = {this.state.object.STATUS == 1 } style={{ width: '100%' }} placeholder={this.lang.CREATE.LABELS.DESCRIPTION} autoSize={{ minRows: 2, maxRows: 6 }} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label= {this.lang.UPDATE.OFFERUPDATE.LABELS.OFFERIMAGE}>
                      {getFieldDecorator('file', {
                        rules: [{ required: false, message: this.lang.MESSAGE.UPLOADIMAGEERROR  }],
                      })(
                        <Row>
                          <img size={80} style={{width:'50%'}} src={ this.state.image || '/dummy_image.jpg'} alt="Image here" />
                          <Input 
                            disabled = { this.state.object.STATUS == 1 } 
                            placeholder={this.lang.UPDATE.OFFERUPDATE.LABELS.OFFERIMAGE} 
                            type="file" id="file" className="file" onChange={this.handleImageUpload} />
                        </Row>
                      )}
                    </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default OfferUpdate = Form.create({ name: 'offer_update_form' })(OfferUpdate);