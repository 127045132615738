import React, { Component } from 'react';
// import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker , InfoWindow } from 'react-google-maps';
import Geocode from "react-geocode"

import MAP_KEY from '../../constants/info';


class Map extends Component {
  constructor(props) {
    super(props)
  }

  onMapClick =(event)=>{
    if (this.props.is_dynamic) {
      let lat = event.latLng.lat() , lng = event.latLng.lng();
      
      if (lat && lng) {
        Geocode.setApiKey(MAP_KEY);
        Geocode.fromLatLng(lat, lng)
        .then(
          response => {
            const address = response.results[0].formatted_address;
            // console.log('address found from geocodes ->',address);
            this.props.getGeoLocations({lat : lat, lng : lng, address : address});
          },
          error => {
            console.error('error found from geocodes ->',error);
          }
        );
      }//End of if lat && lng received
    }//End of Map type condition
  }//End of Method

  componentDidMount(){
    
  }

   render() {
    //  console.log('In render reactGoogleMap.. this.props.lat,this.props.lng ' , this.props.lat,this.props.lng);

    const EmbedMyGoogleMap = withScriptjs(withGoogleMap(googleProps => (
        <GoogleMap
          defaultCenter = {{lat: parseFloat( this.props.lat), lng: parseFloat(this.props.lng)}}
          defaultZoom = { 12 }
          streetViewControl =  {false}
          disableDefaultUI = {true}
          panControl  =  {false}
          onClick={this.onMapClick} 
        >
          {googleProps.isMarkerShown && <Marker position={{ lat: parseFloat(this.props.lat ), lng: parseFloat( this.props.lng) }} />}
        </GoogleMap>
    )));
    return(
        <div>
          <EmbedMyGoogleMap
            isMarkerShown 
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            // googleMapURL={'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places'}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={ <div style={{ height: `30vh`, width: '100%' }} /> }
            mapElement={ <div style={{ height: `100%` }} /> }
          />
        </div>
    );
    
    // return( <p>{'Google Map Featrue under maintenance'}</p> )

   }

  };
  /*const MyMapComponent = compose(
    withProps({
      isMarkerShown: true,
      // googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`,
      googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{  height: `45vh`, width: '100%' }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((googleProps) =>
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 24.926294, lng: 67.022095}}
    >
      {googleProps.isMarkerShown && <Marker position={{ lat: 24.926294, lng: 67.022095 }} />}
    </GoogleMap>
  );*/

export default  Map; //MyMapComponent;