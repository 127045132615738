import React,{Fragment,useState} from 'react'
import { Row, Col, Form, Input, Select,message, InputNumber, DatePicker,Divider,Icon, Button,Modal } from 'antd'
import moment from 'moment'
import CreateReasonForPayment from './CreateReasonForPayment'
import API_URL from '../../constants';
import axios from 'axios';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
const { TextArea } = Input;

function AddClientAccountPayments(props){
    const { getFieldDecorator } = props.form
    const [ CreateReasonOpen, setCreateReasonOpen ]  = useState(false)
    const [ SelectedReasonID, setSelectedReasonID ]  = useState('')

    function pay(e){
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                values.date = moment(values.date).format("YYYY-MM-DD")
                values.reason = values.reason ? values.reason : null
                values.comment = values.comment ? values.comment : ""
                values.user = localStorage.getItem("user_id")
                values.client = localStorage.getItem("clientaccount")
                props.addPayment(values)
            }
        })
    }
    function handleOk(values){
        let  headers = {
            'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
            'Content-Type': 'application/json' 
            }
       var data = {
            name: values.name,
            name_ar:values.name_ar,
            code: values.code.toUpperCase(),
            type: "PAYMENT_REASON",
            parent: undefined,
            user_id: localStorage.getItem('user_id'),
            parent_id: null,
            is_parent: 0
        }
    
        axios.post(`${complete_URL}/parameters/add`, data, {headers})
        .then(res => { 
            if(res.data.success){
                message.success(res.data.message)
                props.getPaymentReasons()
                setCreateReasonOpen(false)
                console.log(res.data.data.id.substr(0,16))
                setSelectedReasonID(res.data.data.id.substr(0,16))
            }else{
                message.error(res.data.message)
            }
        })
    }
    function handleCancel(){
        setCreateReasonOpen(false)
    }
    
    return(
        <Fragment>
            <Form onSubmit={pay} > 
            <Row gutter={12}>
                    <Col span={16}>
                    </Col>
                    <Col span={4}>
                                <Button htmlType="submit">{props.language.BUTTON.SAVE}</Button>
                    </Col>
                    <Col span={4}>
                                <Button onClick={() => { props.toggleFunc() }}>{props.language.BUTTON.EXIT}</Button>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label={props.language.LABELS.AMOUNT}>
                            {getFieldDecorator('amount', {
                                rules: [{ required: true, message: 'Please input you Amount!' }],
                            })(
                                <InputNumber min={0} placeholder={props.language.LABELS.AMOUNT} type="text" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={props.language.LABELS.DATE}>
                            {getFieldDecorator('date', {
                                rules: [{ required: true, message: 'Please input you Amount!' }],
                            })(
                                <DatePicker placeholder={props.language.LABELS.DATE}  />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={props.language.LABELS.PAY_CAT}>
                            {getFieldDecorator('pay_category', {
                                rules: [{ required: true, message: 'Please input you Amount!' }],
                            })(
                                <Select placeholder={props.language.LABELS.PAY_CAT} style={{width:'100%'}}>
                                    {
                                        props.payment_category.length > 0 &&
                                        props.payment_category.map((item,index) => (
                                            <Select.Option value={item.id} key={index} >
                                                {item.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={props.language.LABELS.COMMENT}>
                            {getFieldDecorator('comment', {
                                rules: [{ required: false, message: 'Please input you Amount!' }],
                            })(
                                <TextArea placeholder={props.language.LABELS.COMMENT}  />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={props.language.LABELS.PAY_REAS}>
                            {getFieldDecorator('reason', {
                                initialValue:SelectedReasonID ? SelectedReasonID : undefined,
                                rules: [{ required: false, message: 'Please input you Amount!' }],
                            })(
                                <Select placeholder={props.language.LABELS.PAY_REAS} 
                                value={SelectedReasonID ?SelectedReasonID: undefined}

                                 style={{width:'100%'}}
                                dropdownRender={menu => (
                                <div   >
                                  {menu}
                                  <Divider style={{ margin: '4px 0' }} />
                                  <div onMouseDown={() => setCreateReasonOpen(true)} value='create' style={{ padding: '8px', cursor: 'pointer' }}>
                                    <Icon type="plus" /> Add Reason
                                  </div>
                                </div>
                              )}
                                >
                                    {
                                        props.payment_reason.length > 0 &&
                                        props.payment_reason.map((item,index) => (
                                            <Select.Option value={item.id} key={index} >
                                                {item.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Modal
                visible={CreateReasonOpen}
                title="Add New Reason"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
            >
            <CreateReasonForPayment handleOk={(values) =>handleOk(values) } handleCancel={() => handleCancel()}/>
        </Modal>
        </Fragment>
    )
}

export default AddClientAccountPayments = Form.create({ name: 'AddClientAccountPayments' })(AddClientAccountPayments);