import React from 'react';
import {  Row,  Col,  Form,  Icon,  Button,  InputNumber,  Table,  Input,  message } from 'antd';
import axios from 'axios';
import API_URL from '../../constants/index';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

let Search = Input.Search;

class IncomeDocuments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      IncomeDocumentsList: false,
      file: '',
      upload: false,
      name: '',
      data: {},
      pagination: {},
      page: 1,
      size: 5,
      no:'',
      searchValues: {
        name: ''
      },
      dir : 'ltr', 
      display: 'block'
    }
    this.lang = props.language._INCOME.UPDATE.INCOMEDOCUMENT;
    this.msg = props.language._INCOME.UPDATE.INCOMEDOCUMENT.MESSAGE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span> {this.lang.SETUP.LABELS.DOCNO}
            <Search
              placeholder = {this.lang.SETUP.LABELS.DOCNO}
              onChange={this.handleChangeSearchValues("no")}
            />
          </span>
        ),
        dataIndex: 'no',
        key: 'no',
        width: '30%',
        align:'left',
        sorter: (a, b) => this.compareByAlph(a.no, b.no),
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: (
          <span> {this.lang.SETUP.LABELS.DOCNO}
            <Search
              placeholder = {this.lang.SETUP.LABELS.DOCNO}
              onChange={this.handleChangeSearchValues("no")}
            />
          </span>
        ),
        dataIndex: 'no',
        key: 'no',
        width: '30%',
        align:'right',
        sorter: (a, b) => this.compareByAlph(a.no, b.no),
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.SETUP.LABELS.NAME}
            <Search
              placeholder={ this.lang.SETUP.LABELS.NAME }
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        align:'left',
        width: '30%',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: (
          <span>{this.lang.SETUP.LABELS.NAME}
            <Search
              placeholder={ this.lang.SETUP.LABELS.NAME }
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        align:'right',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      },
      {
        title: `${this.lang.SETUP.LABELS.DOCUMENT}`,
        dataIndex: 'document',
        key: 'document',
        align:'center',
        width: '27%',
        render: (text, record) => <button onClick={this.downloadDoc.bind(this, record)}>{this.lang.SETUP.BUTTON.DOWNLOAD}</button>
      },
      {
        title: `${this.lang.SETUP.LABELS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteDoc.bind(this, record)}>{this.lang.SETUP.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '12%',
        align : 'center',
        key: 'last_name',
      }
    ]
  }
  
  setComponentDirection=()=>{
    if (localStorage.getItem('lang')==='EN') {
       this.setState({dir : 'ltr', display: 'block',textAlignValue : 'left'});
     }
     else {
       this.setState({dir : 'rtl', display: 'flex',textAlignValue : 'right'});
     }
  }

  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }

  handleChangePagination = (pagination, filters, sorter) => {
    let pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      page: pager.current,
      pagination: pager,
    }, () => {
      this.fetch();
    })
  }

  handleSubmitIncomeDocument = (e) => {
    e.preventDefault();
    let imgdata = new FormData();
    console.log(localStorage.getItem('updateIncome'));
    imgdata.append('file', this.state.file);
    imgdata.append('name', this.state.name);
    imgdata.append('no', this.state.no);
    imgdata.append('user_id', localStorage.getItem('user_id'));
    imgdata.append('income_id', JSON.parse(localStorage.getItem('updateIncome')).id);
    let headers = {
      'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
      'Content-Type': 'multipart/form-data',
    }

    if (this.state.upload) {
      axios.post(`${complete_URL}/images/income_documents/add`, imgdata, { responseType: 'blob' }, {headers})
        .then(res => {
          var reader = new FileReader();
          reader.readAsDataURL(res.data);
          message.success(this.msg.DOCADDSUCCESS)
          this.fetch()
          this.setState({ IncomeDocumentsList: false })
        })
        .catch(err => {
          message.error(this.msg.DOCADDFAILURE,3)
          console.log('Error occured while uploading Income Doc->',err)
        })
    } 
    else {
      message.error(this.msg.DOCTYPEERROR,3);
    }
  }

  handleImageUpload = (e) => {
    e.preventDefault();
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      var file = e.currentTarget.files[0];
      let fileName = file.name;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (file.size <= 1024 * 1024 * 2 && /png|xlsx|pdf|odt|doc|docx|jpe?g/i.test(extension)) {
        /*console.log(extension)*/
        this.setState({ file: file, upload: true })
      } else {
        message.error(this.msg.DOCTYPEERROR,3);
      }
    }
  }

  handlePriceNavigation = () => { }

  deleteDoc = (record) => {
    axios.delete(`${complete_URL}/incomedocuments/?id=${record.id}&document_id=${record.document_id}`)
      .then(res => {
        if (res.data.success) {
          message.success(this.msg.DOCDELETESUCCESS/*res.data.message*/)
          this.fetch()
        } 
        else {
          message.error(this.msg.DOCDELETEFAILURE,3/*res.data.message*/)
        }
      })
      .catch(err => {
        console.log('Error occured while deleting the Income Doc->',err)
        message.error(this.msg.DOCDELETEFAILURE,3/*err.message*/)
      })
  }

  downloadDoc = (record) => {
    // console.log(record.document_id)
    axios.get(`${complete_URL}/images/${record.document_id}`, { responseType: 'blob' })
      .then(results => {
        console.log(results);
        let doc
        var reader = new FileReader();
        reader.readAsDataURL(results.data);
        reader.onloadend = () => {
          doc = reader.result
          const link = document.createElement('a');
          link.href = doc;
          link.setAttribute('download', `Income-Document.${results.data.type.split('/')[1]}`); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(err => { console.log(err) })
  }

  nameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  noChange = (e) => {
    this.setState({ no: e})
  }

  componentDidMount() {
    this.fetch();
    this.setComponentDirection();
  }

  fetch = () => {
    const id = this.props.income_id;
    axios.get(`${complete_URL}/incomedocuments/?id=${id}&page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`)
      .then(res => {
        let pagination = { ...this.state.pagination };
        pagination.total = res.data.totalElements;
        pagination.pageSize = parseInt(res.data.size);

        setTimeout(() => {
          this.setState({
            data: res.data,
            pagination: pagination,
          }/*, () => {
            console.log(this.state.data)
          }*/)
        })
      })
      .catch(err => { console.log(err) })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.AddDocHeaderRow = <Row >
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ IncomeDocumentsList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={16}>
          <h1 style={{ fontSize: '18px', textAlign: 'center'}}>{this.lang.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={4} style={{textAlign:'center'}}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.TableHeaderRow = <Row  id="clientTableHeader">
        <Col span={14}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.LABELS.TITLE}</p></Col>
        <Col span={9}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ IncomeDocumentsList: true }) }}>{this.lang.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateDocHeaderRow = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ IncomeDocumentsList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={4}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}> Income Document Update</h1>
        </Col>
        <Col span={4}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
    else {
      this.AddDocHeaderRow = <Row >
        <Col span={4} style={{textAlign: 'center'}}>
          <Button type="danger" onClick={() => { this.setState({ IncomeDocumentsList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={16}>
          <h1 style={{ fontSize: '18px', textAlign: 'center'}}>{this.lang.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.TableHeaderRow = <Row id="clientTableHeader">
        <Col span={1}></Col>
        <Col span={7}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ IncomeDocumentsList: true }) }}>{this.lang.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={15}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.LABELS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateDocHeaderRow = <Row>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}> Income Document Update</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ IncomeDocumentsList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <>
        {
          this.state.IncomeDocumentsList === true ?
            <Form onSubmit={this.handleSubmitIncomeDocument} style={{ marginTop: '20px' }}>
              {this.AddDocHeaderRow}
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                <Form.Item label= {this.lang.CREATE.LABELS.DOCNO}>
                    {getFieldDecorator('no', {
                      rules: [{ required: true, message: this.msg.INSERTDOCNOERROR }],
                    })(
                      <InputNumber dir= {this.state.dir} min={0} placeholder={this.lang.CREATE.LABELS.DOCNO} onChange={this.noChange} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.DOCUMENTNAME}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: this.msg.INSERTDOCNAMEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.CREATE.LABELS.DOCUMENTNAME} onChange={this.nameChange} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={24}>
                  <p>{this.lang.CREATE.LABELS.FILEUPLOAD}</p>
                  <Form.Item label={this.lang.CREATE.LABELS.DOCUMENTUPLOAD}>
                    {getFieldDecorator('income_doc', {
                      rules: [{ required: true, message: this.msg.UPLOADDOCERROR }],
                    })(
                      <Input type="file" onChange={this.handleImageUpload} style={{height : '50%'}} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            : this.state.IncomeDocumentsList === false ?
              //View Income Documents Screen
              <div>
                {this.TableHeaderRow}
                <Row id="clientsTable">
                  {/* <Col span={2}></Col>
                  <Col span={20}> */}
                  <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                    <Table
                      columns={this.columns}
                      rowKey="id"
                      dataSource={this.state.data.selectedElements}
                      pagination={this.state.pagination}
                      onChange={this.handleChangePagination}
                      style={{ marginTop: '10px' }} 
                    />,
                  </Col>
                </Row>

              </div>
              //Update Income Documents Screen
              :
              <Form onSubmit={this.handleSubmitIncomeDocumentUpdate.bind(this)} style={{ marginTop: '20px' }}>
                {this.UpdateDocHeaderRow}
                <Row gutter={24}>

                  <Col span={8}>
                    <Form.Item label="Price">

                      {getFieldDecorator('rice', {
                        rules: [{ required: true, message: 'Please input Income Discount!' }],
                      })(
                        <InputNumber placeholder="Enter Income Discount" style={{ width: '100%' }} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Unit Of Price">
                      {getFieldDecorator('uop', {
                        rules: [{ required: true, message: 'Please Select Unit of Measure' }],
                      })(
                        <input />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
        }
      </>
    )
  }
}

export default IncomeDocuments = Form.create({ name: 'income_docs_form' })(IncomeDocuments);