import React, { Component } from 'react';
import { Row, Col, Icon,Table, List } from 'antd'
import axios from 'axios';
// import { Link } from 'react-router-dom'
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class ContractorPackages extends Component {
    constructor(props) {
      super(props)
      this.state = {
        packagesData: [],
        dir : 'ltr', 
        display: 'block'
      }
      this.lang = props.language._CONTRACTOR.UPDATE.PACKAGE;
      this.msg = props.language._CONTRACTOR.UPDATE.PACKAGE.MESSAGE;
  
      this.columns = [
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.SETUP.LABELS.PACKAGETYPE}`,
          dataIndex: 'type',
          render: (text, record) =>  <p>{text}</p>,
        }:
        {
          title: `${this.lang.SETUP.LABELS.PACKAGETYPE}`,
          dataIndex: 'type',
          align:'right',
          render: (text, record) =>  <p>{text}</p>,
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.SETUP.LABELS.PACKAGEPRICE}`,
          dataIndex: 'price',
          render: (text, record) =>  <p>{text}</p>,
        }:
        {
          title: `${this.lang.SETUP.LABELS.PACKAGEPRICE}`,
          dataIndex: 'price',
          align:'right',
          render: (text, record) =>  <p>{text}</p>,
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.SETUP.LABELS.COMMISSIONPERCENT}`,
          dataIndex: 'commission_percentage',
          render: (text, record) =>  <p>{text + '%'}</p>,
        }:
        {
          title: `${this.lang.SETUP.LABELS.COMMISSIONPERCENT}`,
          dataIndex: 'commission_percentage',
          align:'right',
          render: (text, record) =>  <p>{text + '%'}</p>,
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
          dataIndex: 'commission_amount',
          render: (text, record) =>  <p>{text}</p>,
        }:
        {
          title: `${this.lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
          dataIndex: 'commission_amount',
          align:'right',
          render: (text, record) =>  <p>{text}</p>,
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.SETUP.LABELS.ISACTIVE}`,
          dataIndex: 'is_active',
          render: (text, record) => <p>{text===1? 'Yes':'No'}</p>,
        }:
        {
          title: `${this.lang.SETUP.LABELS.ISACTIVE}`,
          dataIndex: 'is_active',
          align:'right',
          render: (text, record) => <p>{text===1? 'نعم':'لا'}</p>,
        }
        // {
        //   title: `${this.lang.SETUP.LABELS.ACTION}`,
        //   align :'center',
        //   render: (text, record) => (
        //     <span>
        //       <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.SETUP.BUTTON.DELETE}</Button>
        //     </span>
        //   ),
        // }
      ]
    }//End of Constructor

    //ReadOnly purpose so this navigation not to be used
    // handleNavigation = (record) => {
    //     localStorage.setItem('updatePackage', JSON.stringify(record))
    //     this.props.history.push('/dashboard/contpackageUpdate')
    // }

    //ReadOnly purpose so this navigation not to be used
    // deleteCategory = (record) => {
    //     const id = record.id
    //     if (id) {
    //     axios.delete(`${complete_URL}/contractorpackages/${id}`)
    //     .then(res => { //console.log('res->',res);
    //         // message.success('Packages Has been deleted')
    //         if (res.data.success) {
    //         message.success(this.msg.DELETESUCCESS);
    //         this.fetchPackageData();
    //         }
    //         else if(res.data.code === '003'){
    //         message.error(this.msg.DATANOTFOUNDERROR,3);
    //         }
    //         else{
    //         message.error(this.msg.DELETEFAILURE,3);
    //         }
    //     })
    //     .catch(err => {
    //         console.log('Issue while deleting the Contractor Package ->',err);
    //         message.error(this.msg.DELETEFAILURE,3);
    //     })
    //     }
    //     else{
    //     message.error(this.msg.DATANOTFOUNDERROR,3);
    //     }
    // }//End of Delete Method

    fetchPackageData = async () => {
      const client_id = localStorage.getItem('client_id');
      const id = JSON.parse(localStorage.getItem('updateContractor')).id;
      if (id) {
        const headers = getHeadersForHttpReq();
        await axios.get(`${complete_URL}/contractorpackages/fetch_by_contractor/${id}`,{headers})
        .then(res => { // console.log('packagesData->',res.data.data[0])
          let cont_pkgs = res.data.data;
            for (let i = 0; i < cont_pkgs.length; i++) {
              let cont_pkg_id = cont_pkgs[i].id;
              axios.get(`${complete_URL}/contractorpkgrate/byPkgIdClientId/${cont_pkg_id}/${client_id}`,{headers})
              .then(pkg_rate_res => {  //console.log('rules->',res.data.data)
                Object.assign(cont_pkgs[i], {pkg_spcl_rate : pkg_rate_res.data.data})
              })
              .catch(pkg_rate_err => { console.log('Error occured while fetching Rules ->',pkg_rate_err) })
            }
          this.setState({ packagesData: cont_pkgs })
        })
        .catch(err => { console.log('Error occured while fetching Contractor Packages & Special Rates->',err) })
      }
    }//End of method

    componentDidMount(){
        this.fetchPackageData();
    }

    componentWillMount(){
        if (localStorage.getItem('lang') === 'EN') {
          this.CustomRow = <Row id="clientTableHeader">
            <Col span={8}><p>{this.lang.SETUP.LABELS.TITLE}</p></Col>
            <Col span={10}></Col>
            <Col span={4}>
              {/* <Link to="/dashboard/contProfile">
                <Button type="primary">{'Contractor Profile'}</Button>
              </Link> */}
              {/* <Link to="/dashboard/contpackageAdd">
                <Button type="primary">{this.lang.SETUP.BUTTON.ADD}</Button>
              </Link> */}
            </Col>
            <Col span={1}></Col>
          </Row>
        }
        else {
          this.CustomRow = <Row id="clientTableHeader">
            <Col span={1}></Col>
            <Col span={6} >
              {/* <Link to="/dashboard/contProfile">
                <Button type="primary">{'Contractor Profile'}</Button>
              </Link> */}
              {/* <Link to="/dashboard/contpackageAdd">
                <Button type="primary">{this.lang.SETUP.BUTTON.ADD}</Button>
              </Link> */}
            </Col>
            <Col span={6}></Col>
            <Col span={9}><p>{this.lang.SETUP.LABELS.TITLE}</p></Col>
            <Col span={1}></Col>
          </Row>
        }

    }

    render(){

    let is_EN = localStorage.getItem('lang') === 'EN';
    let pkgStyle = {/*paddingLeft:'7%',*/ color:'#000',fontSize: is_EN ? '13.5px':'15px', fontWeight:is_EN ? 400:500};

    return (
            <React.Fragment>
            {this.CustomRow}
            <Row id="packagesTable">
              <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                  <Table 
                    columns={this.columns} 
                    rowKey="id" 
                    dataSource={this.state.packagesData}
                    expandedRowRender={cont_pkg => (
                      <List
                        bordered
                        size="small"
                        rowKey = {'id'}
                        itemLayout="horizontal"
                        dataSource={cont_pkg.pkg_spcl_rate}
                        renderItem={(item, index )=> (
                          <>
                              {/* <List.Item  actions={[<Button type="primary" onClick={() => {this.deleteCategory(item,'rules')}}>{this.lang.BUTTON.DELETE}</Button>]}> */}
                              <List.Item  >
                                <List.Item.Meta
                                  description = {
                                    <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                                      <Col span={4}></Col>
                                      <Col span={6}><p style={pkgStyle}>{item.price}</p> </Col>
                                      <Col span={6}><p style={pkgStyle}>{item.commission_percentage + '%'}</p> </Col>
                                      <Col span={8}><p style={pkgStyle}>{item.commission_amount}</p> </Col>
                                    </Row>
                                  }
                                />
                              </List.Item>
                            </>
                        )}
                      />
                    )} 
                    style={{ marginTop: '10px' }} 
                  />
              </Col>
            </Row>
            </React.Fragment>
        )
    }

}//End of Class
export default ContractorPackages;