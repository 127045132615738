import React,{Fragment, useState, useEffect } from 'react'
import { Col, Row, Form, Button, Icon, Card, InputNumber, message} from 'antd'
import {Link} from 'react-router-dom';
import axios from 'axios'
import API_URL from '../../constants'

const pt = localStorage.getItem('pt');
const Item = Form.Item

function ClientDetails(props) {
    function submit(e){
        e.preventDefault()
        props.form.validateFields((err, values) => {
            let obj = {...props.client}
            obj.commission = values.commission
            console.log(obj)
            axios.put(`${API_URL}${pt}/clients/commission_update/${obj.id}`, values)
            .then(res => { 
                if(!res.data.error){
                    message.success(res.data.message)
                }
            })
            .then(err => { console.log(err) })
        })

    }
    const [ commission, setCommission ]  = useState(0)
    useEffect(() => {
        setCommission(props.client.commission)
    },[props.client.commission])
    function ArabicRow() {
        return (
            <Form onSubmit={submit}> 
                            
                                <Row className="top-header" gutter={24}>
                                    <Col className="btn-box" span={8}>
                                        <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
                                        <Button className="delete" type="" title="delete"><Icon type="delete" theme="filled" /></Button>
                                    </Col>
                                    <Col span={9}>
                                        <h1>{props.language.LABELS.TITLE}</h1>
                                    </Col>
                                    <Col span={7}>
                                        <Link to="/dashboard/clientaccount">
                                            <Button type="danger"><Icon type="arrow-right" /></Button>
                                        </Link>
                                    </Col>
                                </Row>
                            <Row gutter={12} >
                                <Col span={8}>
                                    <Item label={props.language.LABELS.DESCRIPTION}>{props.client.description}</Item>
                                </Col>
                                <Col span={8}>
                                    <Item label={props.language.LABELS.EMAIL}>{props.client.email}</Item>
                                </Col>
                                <Col span={8}>
                                    <Item label={props.language.LABELS.NAME}>{props.client.name}</Item>
                                </Col>
                            </Row>
                            <Row gutter={12} style={{ marginTop: '50px' }}>
                                <Col span={12}>
                                    <Item label={props.language.LABELS.TOTAL_AMOUNT}>{props.clientAccount.res_amount}</Item>
                                </Col>
                                <Col span={12}>
                                    <Item  dir='rtl' label={props.language.LABELS.COMMISSION}>
                                    {props.form.getFieldDecorator('commission', {
                                        initialValue:commission,
                                        rules: [{ required: true, message: 'Please input commisson!' }],
                                        })(
                                        <InputNumber dir="ltr" onChange={(e) => setCommission(e)}  min={0} max={100}  placeholder={props.language.LABELS.COMMISSION} />
                                            )}
                                    </Item>
                                </Col>
                                </Row>
                                <Row gutter={12} style={{ marginTop: '50px' }}>
                                <Col span={12}>
                                    <Item  dir='rtl' label={props.language.LABELS.TOTAL_COMM}>{props.clientAccount.total_amount}</Item>
                                </Col>
                                <Col span={12}>
                                    <Item  dir='rtl' label={props.language.LABELS.PAID_AMOUNT}>{props.clientAccount.pay_amount}</Item>
                                </Col>
                            </Row>
                        </Form>
        )
    }
    function EnglishRow() {
        return (
            <Form onSubmit={submit}> 
                        <Row className="top-header" gutter={24}>
                            <Col span={7}>
                                <Link to="/dashboard/clientaccount">
                                    <Button type="danger"><Icon type="arrow-left" /></Button>
                                </Link>
                            </Col>
                            <Col span={9}>
                                <h1>{props.language.LABELS.TITLE}</h1>
                            </Col>
                            <Col className="btn-box" span={8}>
                                <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
                                <Button className="delete" type="" title="delete"><Icon type="delete" theme="filled" /></Button>
                            </Col>
                        </Row>
                            <Row gutter={12} >
                                <Col span={8}>
                                    <Item label={props.language.LABELS.NAME}>{props.client.name}</Item>
                                </Col>
                                <Col span={8}>
                                    <Item label={props.language.LABELS.EMAIL}>{props.client.email}</Item>
                                </Col>
                                <Col span={8}>
                                    <Item label={props.language.LABELS.DESCRIPTION}>{props.client.description}</Item>
                                </Col>
                            </Row>
                            <Row gutter={12} style={{ marginTop: '50px' }}>
                                <Col span={12}>
                                    <Item label={props.language.LABELS.COMMISSION}>
                                    {props.form.getFieldDecorator('commission', {
                                        initialValue:commission,
                                        rules: [{ required: true, message: 'Please input commisson!' }],
                                        })(
                                        <InputNumber onChange={(e) => setCommission(e)}  min={0} max={100} style={{width:'100%'}} placeholder={props.language.LABELS.COMMISSION} />
                                            )}
                                    </Item>
                                </Col>
                                <Col span={12}>
                                    <Item label={props.language.LABELS.TOTAL_AMOUNT}>{props.clientAccount.res_amount}</Item>
                                </Col>
                                <Col span={12}>
                                    <Item label={props.language.LABELS.PAID_AMOUNT}>{props.clientAccount.pay_amount}</Item>
                                </Col>
                                <Col span={12}>
                                    <Item label={props.language.LABELS.TOTAL_COMM}>{props.clientAccount.total_amount}</Item>
                                </Col>
                            </Row>
                        </Form>
        )
    }
    return(
        <Fragment>
            {
                localStorage.getItem('lang') === 'EN' ? 
                EnglishRow()
                :
                ArabicRow()
            }
        </Fragment>
    )
}

// export default ClientDetails
export default ClientDetails = Form.create({ name: 'normal_login' })(ClientDetails);