import React, { Component } from 'react';
import {Row, Col, Empty, Card, Avatar, Icon, Spin} from 'antd';
import ReactGoogleMap from '../ReactGoogleMap/index';
import axios from 'axios';
import API_URL from '../../constants'
import getHeadersForHttpReq from '../../constants/token';

if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;


class ContractorProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contractor: '',
      contractor_obj: {},
      all_selected_clients : [],
      lat : 23.614328   ,//-91, //[-90 , +90]
      lng : 58.545284   ,//-181, // [-180 , +180] //or we can also set location of OMAN..
      showMap : true,
      editGoogleAddr : false,
      img_url : '',
      spin : true,
      spin_msg :'System is Loading..Please wait..',
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._CONTRACTOR.UPDATE;
    // this.lang1 = props.language._CONTRACTOR.UPDATE.PACKAGE;
    // this.msg  = props.language._CONTRACTOR.MESSAGE;
    
  }

  setComponentDirection=()=>{
    if(localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    let cont_id = localStorage.getItem('contprof');
    if(cont_id) {
      this.fetch(cont_id);// FETCH CONTRACTOR DATA For Profile
      this.getAssignedClients(cont_id);
      this.setComponentDirection();
      this.setState({spin:false});
    }
    else{ this.props.history.push('/dashboard/assignedcontractors'); }
  }

  fetch=(cont_id)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/contractors/fetch_profile_data/${cont_id}`,{headers})
    .then(cont_res=>{//console.log('Contractor Profile data ->',cont_res.data.data);
      if (cont_res.data.success) {
        this.setState({contractor_obj : cont_res.data.data[0]})
      }
    })
    .catch(cont_err=>{
      console.log('Error occured while fetching Contractor Profile data->',cont_err)
    })
  }//End of Method

  getAssignedClients =(cont_id)=> {
    if(cont_id){
      const headers =  getHeadersForHttpReq();
      axios.get(`${complete_URL}/cont_clients/${cont_id}`, {headers})
        .then(response => { //console.log('Assigned Clients list->',response.data.data);
            this.setState({ all_selected_clients : response.data.data})
        })
        .catch(clt_err => { console.log('Error in fetching assigned Clients list->',clt_err) })
    }
  }//End of Method
  
  render() {
    // const { getFieldDecorator } = this.props.form;
    let {contractor_obj,all_selected_clients, img_url,showMap, dir, display, spin_msg, spin } = this.state;
    let {NAME, EMAIL, PHONENO, SERVICETYPE, ADDRESS, AREA, STREET, BUILDINGVILLAHOUSE, CITY,
       COUNTRY, GOOGLEMAPADDRESS, WORKING } = this.lang.LABELS;
       
    let EN_FLAG = localStorage.getItem('lang') === 'EN';
    let fontStyleT = {fontSize:'14px', color: 'rgba(0, 0, 0, 0.85)'};
    let w = window.innerWidth;
    //1200px, 1200px >= x > 990px, 990px >= x > 768px,  768px >= x > 640px, 640px >= x > 480, 480 >= x > 400, 400 >= x -> never gonna happen?
    if (w < 1280 && w >= 1024) { fontStyleT = {fontSize:'13px'}; }
    else if (w < 1024 && w >= 768) { fontStyleT = {fontSize:'9px'}; }
    let ph = contractor_obj.ph_num;
    if (contractor_obj.ph_alt_num) { ph = contractor_obj.ph_num + ' / '+contractor_obj.ph_alt_num; }
    let working_Clients = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    if (all_selected_clients.length > 0) {
      working_Clients = all_selected_clients.map((ct,i)=>{
        return <p key={'ct'+i} style={fontStyleT}>{ct.seq_no+'  :   '} <span >{EN_FLAG ? ct.name : ct.name_ar}</span></p>
      });
    }

    return (
      <Spin tip={spin_msg} spinning={spin}>
        <Row gutter={24} dir = {dir} style={{margin : '20px 0px' }}>
          {/* <div style={{padding : '0 0.3%', width:'100%', display: EN_FLAG ? 'inline-block':'block'}}> */}
            <Card key={'info_card'} size={'small'} bordered={false}>
              <Row gutter={24} dir= {dir} style={{margin : '20px 0px', display : display }}>
              <Col span={3} style={{paddingRight:'0', paddingLeft:'0'}}>
                <Avatar size={100} icon="user"  src={img_url || '/dummy_image.jpg'} />
              </Col>
              <Col span={21} style={{ marginTop:'3%'}}>{/*Descriptions*/}
              {/*  
              <Descriptions  size={'default'}>
                <Descriptions.Item label={NAME}><span style={fontStyleT}><p>{EN_FLAG ? contractor_obj.name : contractor_obj.name_ar}</p></span></Descriptions.Item>
                <Descriptions.Item label={EMAIL}><span style={fontStyleT}><p>{contractor_obj.email}</p></span></Descriptions.Item>
                <Descriptions.Item label={SERVICETYPE}><span style={fontStyleT}><p>{EN_FLAG ? contractor_obj.new_service_type_name : contractor_obj.new_service_type_name_ar}</p></span></Descriptions.Item>
              </Descriptions>*/}
              <Row gutter={24} dir = {dir} style={{display}}>
                <Col span={8}><p style={fontStyleT}>{NAME+' :  '} <span >{EN_FLAG ? contractor_obj.name : contractor_obj.name_ar}</span></p></Col>
                <Col span={8}><p style={fontStyleT}>{EMAIL+' :  '} <span >{contractor_obj.email}</span></p></Col>
                <Col span={8}><p style={fontStyleT}>{SERVICETYPE+' :  '} <span >{EN_FLAG ? contractor_obj.new_service_type_name : contractor_obj.new_service_type_name_ar}</span></p></Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}}>
                <Col span={24}><h3 style={{fontWeight:'bold'}}>{ADDRESS}</h3></Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}}>
                <Col span={8}><p style={fontStyleT}>{AREA+' :  '} <span >{contractor_obj.area}</span></p></Col>
                <Col span={8}><p style={fontStyleT}>{STREET+' :  '} <span >{contractor_obj.street}</span></p></Col>
                <Col span={8}><p style={fontStyleT}>{BUILDINGVILLAHOUSE+' :  '} <span >{contractor_obj.building}</span></p></Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}}>
                <Col span={8}><p style={fontStyleT}>{CITY+' :  '} <span >{EN_FLAG ? contractor_obj.city_name : contractor_obj.city_name_ar}</span></p></Col>
                <Col span={8}><p style={fontStyleT}>{COUNTRY+' :  '} <span >{EN_FLAG ? contractor_obj.country_name : contractor_obj.country_name_ar}</span></p></Col>
                <Col span={8}><p style={fontStyleT}>{PHONENO+' :  '}<span >{ph}</span></p></Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}}>
              <Col span={24}>
                <p> <Icon type="phone" title = {PHONENO} style={{marginRight:'10px',fontSize:'18px'}} />{ph}</p>
              </Col>
              </Row>
              </Col>
              </Row>
            </Card>
          {/* </div> */}
          <Row gutter={24} dir = {dir} style={{margin : '10px 0px', display : display }}>
            <Col span={12}>
              <Card key={'card2'} size={'small'} bordered={false}>
                <h3 style={{fontWeight:'bold'}}>{WORKING}</h3>
                {working_Clients}
              </Card>
            </Col>
            <Col span={12}>
              <Card key={'card3'} size={'small'} bordered={false}>
              {
                showMap && 
                <ReactGoogleMap  
                  {...this.props} 
                  lat = {contractor_obj.lat} 
                  lng = {contractor_obj.lng} 
                  getGeoLocations = {this.getGeoLocations}
                  is_dynamic = {false}
                />
              }
              <p style={{marginTop:'20px',...fontStyleT}}>{GOOGLEMAPADDRESS+' :  '}<span style={{fontWeight:'bold'}}>{contractor_obj.map_loc_addr}</span></p>
              </Card>
            </Col>
          </Row>
        </Row>
      </Spin>
    )
  }
}

export default ContractorProfile;
// export default ContractorProfile = Form.create({ name: 'normal_login' })(ContractorProfile);
