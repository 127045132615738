function screenNavCheck (module, screenUrl, lang){
  const allowedScreens = localStorage.getItem('screenList');
  
  if (allowedScreens) {
    const allowdScreensParsed = JSON.parse(allowedScreens);
    if(lang === 'EN' || lang === 'AR'){
      if (allowdScreensParsed.hasOwnProperty(module)) {
        let allowScreenPermission = false;
        for (let i = 0; i < allowdScreensParsed[module].length && !allowScreenPermission; i++) {
          if (allowdScreensParsed[module][i].url === screenUrl) {
            allowScreenPermission = true;
          }        
        }
        return allowScreenPermission;
      }
      else{ return false}
    }// End of language check
  }// End of screens found check
  else{ return false}
}

export default screenNavCheck;