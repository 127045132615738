import React, { Component } from 'react';
import axios from 'axios';
import { Select, Row, Col, Card, DatePicker, Icon, Button, Spin, message } from 'antd'
import moment from 'moment';
import YearPicker from "react-year-picker";
import ReservationGenericReport from './ReservationGenericReport';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import getHeadersForPDFreport from '../../constants/reportHeaders';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const { RangePicker, MonthPicker } = DatePicker;
if (localStorage.getItem('lang') === 'AR') {
   require('moment/locale/ar');
   require('./arabic.css');
   require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class Reportscreen extends Component {
   constructor(props) {
      super(props)
      this.state = {
         ReportOptions: [{ 'id': 0, 'name': 'Contractor Report', 'name_ar': 'تقرير  مقدم الخدمات' },
                         { 'id': 1, 'name': 'Finance Report', 'name_ar': 'تقرير المالية' },
                         { 'id': 2, 'name': 'Reservations Report', 'name_ar': 'تقرير الحجز' },
                         { 'id': 3, 'name': 'Payment Transactions Report', 'name_ar': 'تقرير المدفوعات' },
                         { 'id': 4, 'name': 'Reservation List Report', 'name_ar': 'تقرير قائمة الحجوزات' }],
         reportOptIndex: 0,
         filterNode: '',
         filterContCalenderNode: '',
         filterResrvCalenderNode: '',
         showFilter: false,
         showContCalenderFilter: false,
         showResrvCalenderFilter: false,
         searchValues_fn_Report: {
            assignedClientId : '',
            month: '',  year: '',
            month1: '', year1: '',
            month2: '', year2: '',
            yearOnly: '', allData: ''
         },
         searchValues_cont_Report: {
            serv_cont_name: '', serv_cont_name_ar: '',
            date: '', from_date: '', to_date: '',
            month: '', year: '', yearOnly: '',
            month1: '',year1: '', cont_id : '',
            month2: '', year2: '', allData: ''
         },
         searchValues_resrv_Report : {
            date: '', from_date: '', to_date: '',
            month: '', year: '', yearOnly: '',
            month1: '',year1: '', status: 'all',
            month2: '', year2: '', allData: ''
         },
         contractors: [],
         resrv_Options: [{ 'id': 0, 'name': 'pending', 'name_ar': 'قيد الانتظار' },
                         { 'id': 1, 'name': 'reserved', 'name_ar': 'محجوز' },
                         { 'id': 2, 'name': 'canceled', 'name_ar': 'ألغيت' },
                         { 'id': 3, 'name': 'all', 'name_ar': 'جميع' }],
         progressbar: false,
         disableDownloadReport: true,
         disableDownloadContReport: true,
         disableDownloadResrvReport: true,
         reportDate: new Date(),
         fn_mode: ['month', 'month'],
         cont_mode: ['month', 'month'],
         resrv_mode: ['month', 'month'],
         resrv_range_value: [moment(new Date(), 'YYYY/MM'), moment(new Date(), 'YYYY/MM')],
         fn_range_value: [moment(new Date(), 'YYYY/MM'), moment(new Date(), 'YYYY/MM')],
         cont_range_value: [moment(new Date(), 'YYYY/MM'), moment(new Date(), 'YYYY/MM')],
         spin: false,
         spinningMessage: '',
         startValue: null,
         endValue: null,
         endOpen: false,
         dir : 'ltr', 
         display: 'block',
         textAlignValue : 'left'
      }
      this.lang = props.language._REPORTS;
   }

   setComponentDirection =()=>{
      if (localStorage.getItem('lang')==='EN') {
         this.setState({dir : 'ltr', display: 'block',textAlignValue : 'left'})
      }
      else {
         this.setState({dir : 'rtl', display: 'flex',textAlignValue : 'right'})
      }
   }

   componentWillMount() {
      if (localStorage.getItem('lang') === 'EN') {
         this.CustomRow = <Row gutter={24} id="clientTableHeader">
            <Col span={2}></Col>
            <Col span={5}><p>{this.lang.TITLE}</p> </Col>
            <Col span={13}></Col>
         </Row>
      }
      else {
         this.CustomRow = <Row gutter={24} id="clientTableHeader">
            <Col span={15}></Col>
            <Col span={6}><p>{this.lang.TITLE}</p> </Col>
            <Col span={2}></Col>
         </Row>
      }
   }

   componentDidMount() {
      // const client_id = localStorage.getItem('client_id');
      // this.getContractors(client_id);
      this.getAssignedServiceContractors();
      this.setComponentDirection(); 
   }

   // Old method
   getContractors = (client_id) => {
      if (client_id) {
         const headers = getHeadersForHttpReq();
         axios.get(`${complete_URL}/contractors/fetch_by_clientId/${client_id}`,{headers})
            .then(res => {
               this.setState({ contractors: res.data.data })
            })
            .catch(err => {
               console.log('Error occured while fetching old Contractors->',err)
            })
      }
      else{ message.error(this.lang.MESSAGE.LOGINERROR,3)}
   }//End of Method getContractors

  //New ajax API call for fetching Assigned independent contractor of selected Service Type
   getAssignedServiceContractors =()=>{ 
     const client_id = localStorage.getItem('client_id');
     if (client_id) {
      const  headers = getHeadersForHttpReq();
      axios.get(`${complete_URL}/contractors/independent/${client_id}`,{headers})
      .then((res) => {
         if (res.data.success) { // console.log('Assigned independent contractors->',res.data.data);
            this.setState({ contractors: res.data.data });
         } 
         //  else { message.error(res.data.message) }
      })
      .catch(err => { 
         console.log('Error occured while fetching assigned Contractors->',err)
         message.error(err.message); 
      });
     }
     else{ message.error(this.lang.MESSAGE.LOGINERROR,3)}
   }

   disabledStartDate = (startValue) => {
      const endValue = this.state.endValue;
      if (!startValue || !endValue) {
         return false;
      }
      return startValue.valueOf() > endValue.valueOf();
   }

   disabledEndDate = (endValue) => {
      const startValue = this.state.startValue;
      if (!endValue || !startValue) {
         return false;
      }
      return endValue.valueOf() <= startValue.valueOf();
   }//End of Method

   onStartChange = (date, dateString, report_type) => {
      this.setState({ 'startValue': date },
      () => {
         if (this.state.startValue && this.state.endValue) {
            let st = moment(dateString).locale('en').format('YYYY-MM-DD');
            let ed = moment(this.state.endValue).locale('en').format('YYYY-MM-DD');
            this.setParamsDatesDuration(st, ed, false, report_type);
         }
      });
   }//End of Method

   onEndChange = (date, dateString, report_type) => {
      this.setState({ 'endValue': date },
         () => {
            if (this.state.startValue && this.state.endValue) {
               let st = moment(this.state.startValue).locale('en').format('YYYY-MM-DD');
               let ed = moment(dateString).locale('en').format('YYYY-MM-DD');
               this.setParamsDatesDuration(st, ed, false, report_type);
            }
         });
   }//End of Method

   handleStartOpenChange = (open) => {
      if (!open) {
         this.setState({ endOpen: true });
      }
   }

   handleEndOpenChange = (open) => {
      this.setState({ endOpen: open });
   }//End of Method

   filterfnDataOptionOnchange = (val) => {
      let resetObj = {
         month: '', year: '', month1: '', year1: '',
         month2: '',  year2: '', yearOnly: '',
         allData: val == 'aa' ? 'Yes' : ''
      }
      let new_report_obj = Object.assign(this.state.searchValues_fn_Report, resetObj)
      this.setState({
         searchValues_fn_Report: new_report_obj,
         disableDownloadReport: val == 'aa' ? false : true,
         filterNode: val,
         showFilter: true,
      }/*, () => {
         this.state.filterNode == 'aa' && this.fetchesGraphDataAlltime()
      }*/);
   }//End of Method

   filterContDataOptionOnchange = (val) => {
      let { serv_cont_name, serv_cont_name_ar } = this.state.searchValues_cont_Report
      let disableDownloadContReport = val == 'aa' ? false : true;
      if (!serv_cont_name || !serv_cont_name_ar) {
         disableDownloadContReport = true;
      }
      let resetObj = {
         date: '', from_date: '', to_date: '',
         month: '', year: '', month1: '', year1: '',
         month2: '', year2: '', yearOnly: '',
         allData: val == 'aa' ? 'Yes' : ''
      }
      let new_report_obj = Object.assign(this.state.searchValues_cont_Report, resetObj);
      this.setState({ disableDownloadContReport,
         searchValues_cont_Report: new_report_obj,
         filterContCalenderNode: val,
         showContCalenderFilter: true
      });
   } //End of Method

   filterResrvDataOptionOnchange = (val) => {
      // if (val) {
         let resetObj = {
            date: '', from_date: '', to_date: '',
            month: '', year: '', month1: '', year1: '',
            month2: '', year2: '', yearOnly: '', status : 'all',
            allData: val == 'aa' ? 'Yes' : ''
         }
         let new_report_obj = Object.assign(this.state.searchValues_resrv_Report, resetObj)
         this.setState({
            searchValues_resrv_Report: new_report_obj,
            disableDownloadResrvReport : val == 'aa' ? false : true,
            filterResrvCalenderNode: val,
            showResrvCalenderFilter: true,
         })
      // }
   } //End of Method filterResrvDataOptionOnchange 

   handlePanelChange = (value, mode, report_type) => {
      let month1 = moment(value[0]).locale('en').format('MM');
      let year1 = moment(value[0]).locale('en').format('YYYY');

      let month2 = moment(value[1]).locale('en').format('MM');
      let year2 = moment(value[1]).locale('en').format('YYYY');
      if (month1 && month2 && year1 && year2) {
         if (report_type === 'fn') {
            this.setState({
               fn_range_value: value,
               fn_mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
            });
            /*this.fetchesGraphBetweenMonths(month1, month2, year1, year2);*/
            this.setParamsBetweenMonths(month1, month2, year1, year2, false, 'fn');
         }
         else if (report_type === 'cont') {
            this.setState({
               cont_range_value: value,
               cont_mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
            });
            this.setParamsBetweenMonths(month1, month2, year1, year2, false, 'cont');
         }
         else if (report_type === 'resrv') {
            this.setState({
              resrv_range_value: value,
              resrv_mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
            });
            this.setParamsBetweenMonths(month1, month2, year1, year2, false, 'resrv');
         }
      }//End of if Range months and years found
   };//End of Method

   generate_pdf_popup = (data,file_name)=>{
      const blob = new Blob([data], { type: 'application/pdf' });//make available for download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = file_name;
      /*link.click();*/
      link.target = '_blank';
      window.open(link);
   }//End of Method
   
   getReportBasicData = ()=>{
      let opt = 'en';
      const client_id = localStorage.getItem('client_id');
      const user_name= localStorage.getItem('user_name');
      const user_id = localStorage.getItem('user_id');
      if (localStorage.getItem('lang') == 'AR') { opt = '-ar' }
      let api_main_part = API_URL.split('/')[2];
      let req_data = {
         api_main_part, port : pt, opt, user_name, client_id, user_id
      }
      if (localStorage.getItem('is_contr') != 1) {
         Object.assign(req_data,{ is_contr : 0})
      }
      else{
         const contractor_id = JSON.parse(localStorage.getItem('updateContractor')).id;
         const selectedClient = this.state.searchValues_fn_Report.assignedClientId;
         Object.assign(req_data,{contractor_id, selectedClient, is_contr : 1});
      }
      return req_data;
   }//End of Method
   
   generateContractorReportGeneric=(req_data, file_name, dataNotFoundMsg)=>{
      const { cont_id, serv_cont_name, serv_cont_name_ar } = this.state.searchValues_cont_Report;
      Object.assign(req_data, { cont_id, serv_cont_name, serv_cont_name_ar });
      const headers2 = getHeadersForPDFreport();
      axios({ url: complete_URL+'/contractor_reports/generic_report', headers: headers2,
              method: 'post', data: req_data, responseType: 'arraybuffer'
      })
      .then(response => {
         this.setState({ spin: false });
         if (response.data.byteLength > 0) {
            this.generate_pdf_popup( response.data, file_name);
         }
         else if (response.statusText === 'Required input not found to generate Contractor report!!') {
            message.error(this.lang.MESSAGE.CONTREPORTDATANOTPROVIDEDERROR,3)
         }
         else if (response.statusText === 'No Content') {
            message.error(dataNotFoundMsg,3)
         }
         else {  message.error(response.statusText); }
      })
      .catch(err => {
         this.setState({ spin: false });
         message.error(this.lang.MESSAGE.CONTREPORTGENERATEFAILURE/*err.message*/,3);
         console.log('Error occured while fetching Contractor report->',err);
      })
   }//End of Contractor Generic Method 

   generateReservationReportGeneric = (req_data, file_name)=>{
      Object.assign(req_data, {status: this.state.searchValues_resrv_Report.status, show_rules : false});
      const headers2 = getHeadersForPDFreport();
      axios({ url:complete_URL+'/reservation_reports/by_duration_data_client_id', headers: headers2,
              method: 'post', data: req_data, responseType: 'arraybuffer'
      })
      .then(resrv_response=>{
         this.setState({ spin: false }); 
         if (resrv_response.data.byteLength > 0) { 
            this.generate_pdf_popup( resrv_response.data, file_name);
         }
         else { message.error(this.lang.MESSAGE.RESRVREPORTGENERATEFAILURE,3/*resrv_response.statusText*/); }
      })
      .catch(res_err=>{
         this.setState({ spin: false });
         console.log('in ReportScreen.. resrv report..res_err->',res_err);
         if (res_err.statusText === 'Reservations not found in this duration!!') {
            message.error(this.lang.MESSAGE.RESRVDATANOTFOUNDERROR,3);
         }
         else if (res_err.statusText === 'Provided incomplete data!!') {
            message.error(this.lang.MESSAGE.RESRVREPORTDATANOTPROVIDEDERROR,3);
         }
         else if (res_err.statusText === 'No Content' || res_err.statusText === '[object Object]') {
                 message.error(this.lang.MESSAGE.RESRVDATANOTFOUNDERROR,3);
         } 
         else{ message.error(this.lang.MESSAGE.RESRVREPORTGENERATEFAILURE,3)}
      });
   }//End of Reservation Generic Method 

   downloadOnDateData = (date, report_type) => { 
      let req_data = this.getReportBasicData(); 
      if (req_data.client_id) {
         Object.assign(req_data, {date});
         const duration = date+'';
         const version = req_data.opt == 'en' ? 'Eng' : 'Arb'; 
         if (report_type === 'fn') {
            message.success('Feature to be added!!')
         }
         else if (report_type === 'cont') {
            let file_name = 'Contractor_report_' + duration +'_'+version+ '.pdf';
            this.generateContractorReportGeneric(req_data, file_name, this.lang.MESSAGE.CONTREPORTDATEDATANOTFOUNDERROR);
         }
         else if (report_type === 'resrv') {
            let file_name = 'Reservation_report_' + duration +'_'+version+ '.pdf';
            this.generateReservationReportGeneric(req_data, file_name);
         }
      }//End of ClientID exists
      else { message.error(this.lang.MESSAGE.LOGINERROR,3);  }
   }//End of Method

   downloadBetweenDatesData = (date1, date2, report_type) => {
      let req_data = this.getReportBasicData(); 
      if (req_data.client_id) {
         Object.assign(req_data, {date1, date2, from_date : date1, to_date :date2,});
         const duration =  date1 + '_to_' + date2; 
         const version = req_data.opt == 'en' ? 'Eng' : 'Arb'; 
         if (report_type === 'fn') {
            message.error('Feature to be added soon!!');
         }
         else if (report_type === 'cont') {
            let file_name = 'Contractor_report_' + duration +'_'+version+ '.pdf';
            this.generateContractorReportGeneric(req_data, file_name, this.lang.MESSAGE.CONTREPORTDATESDATANOTFOUNDERROR);
         }
         else if (report_type === 'resrv') {
            let file_name = 'Reservation_report_' + duration +'_'+version+ '.pdf';
            this.generateReservationReportGeneric(req_data, file_name);
         }
      }//End of ClientID exists
      else { message.error(this.lang.MESSAGE.LOGINERROR,3);  }
   }//End of Method

   downloadOneMonthOfYearData = (month, year, report_type) => {
      let req_data = this.getReportBasicData(); 
      if (req_data.client_id) {
         Object.assign(req_data, {month, year});
         const duration = month + '_' + year; 
         const version = req_data.opt == 'en' ? 'Eng' : 'Arb';
         if (report_type === 'fn') {
            this.setState({ reportDate: moment(`${year}-${month}`) })
            axios.get(`${complete_URL}/finance_reports/export_pdf_month/`, {
               responseType: 'arraybuffer',
               headers: { 'Accept': 'application/pdf' },
               params: { data: JSON.stringify(req_data) }
            })
            .then(response => {
               this.setState({ spin: false });
               if (response.data.byteLength > 0) {
                  let file_name = 'Finance_report_' + duration + '.pdf';
                  this.generate_pdf_popup( response.data, file_name);
               }
               else if (response.statusText === 'Required input not found to generate Finance report!!') {
                  message.error(this.lang.MESSAGE.FINCREPORTDATANOTPROVIDEDERROR,3)
               }
               else { message.error(response.statusText); }
            })
            .catch(err => {
               this.setState({ spin: false });
               message.error(this.lang.MESSAGE.FINCREPORTGENERATEFAILURE/*err.message*/);
               console.log(err);
            })
         }//End of Finance request
         else if (report_type === 'cont') {
            let file_name = 'Contractor_report_' + duration +'_'+version+ '.pdf';
            this.generateContractorReportGeneric(req_data, file_name, this.lang.MESSAGE.CONTREPORTMONTHDATANOTFOUNDERROR);
         }
         else if (report_type === 'resrv'){
            let file_name = 'Reservation_report_' + duration +'_'+version+ '.pdf';
            this.generateReservationReportGeneric(req_data, file_name);
         }
      }//End of ClientID exists
      else { message.error(this.lang.MESSAGE.LOGINERROR,3);  }
   }//End of Method

   downloadBetweenMonthsData = (month1, month2, year1, year2, report_type) => {
      let req_data = this.getReportBasicData();
      if (req_data.client_id) {
         const duration = month1 + '_' + year1 + '_to_' + month2 + '_' + year2; 
         const version = req_data.opt == 'en' ? 'Eng' : 'Arb';
         Object.assign(req_data, {month1, month2, year1, year2});
         if (report_type === 'fn') {
            axios.get(`${complete_URL}/finance_reports/export_pdf_months/`, {
               responseType: 'arraybuffer',
               headers: { 'Accept': 'application/pdf' },
               params: { data: JSON.stringify(req_data) }
            })
               .then(response => {
                  this.setState({ spin: false });
                  if (response.data.byteLength > 0) {
                     let file_name = 'Finance_report_' + duration + '.pdf';
                     this.generate_pdf_popup( response.data, file_name);
                  }
                  else if (response.statusText === 'Required input not found to generate Finance report!!') {
                     message.error(this.lang.MESSAGE.FINCREPORTDATANOTPROVIDEDERROR,3)
                  }
                  else { message.error(response.statusText); }
               })
               .catch(err => {
                  this.setState({ spin: false });
                  message.error(this.lang.MESSAGE.FINCREPORTGENERATEFAILURE/*err.message*/);
                  console.log(err);
               })
         }
         else if (report_type === 'cont') {
            let file_name = 'Contractor_report_' + duration +'_'+version+ '.pdf';
            this.generateContractorReportGeneric(req_data, file_name, this.lang.MESSAGE.CONTREPORTMONTHSDATANOTFOUNDERROR);
         }
         else if (report_type === 'resrv'){
            let file_name = 'Reservation_report_' + duration +'_'+version+ '.pdf';
            this.generateReservationReportGeneric(req_data, file_name);
         }
      }//End of ClientID exists
      else { message.error(this.lang.MESSAGE.LOGINERROR,3);  }
   }//End of Method

   downloadYearOnlyData = (yearOnly, report_type) => {
      let req_data = this.getReportBasicData();
      if (req_data.client_id) {
         const duration = yearOnly;
         const version = req_data.opt == 'en' ? 'Eng' : 'Arb';
         Object.assign(req_data, {yearOnly});
         if (report_type === 'fn') {
            axios.get(`${complete_URL}/finance_reports/export_pdf_year/`, {
               responseType: 'arraybuffer',
               headers: { 'Accept': 'application/pdf' },
               params: { data: JSON.stringify(req_data) }
            })
               .then(response => {
                  this.setState({ spin: false });
                  if (response.data.byteLength > 0) {
                     let file_name = 'Finance_report_complete_' + duration + '.pdf';
                     this.generate_pdf_popup( response.data, file_name);
                  }
                  else if (response.statusText === 'Required input not found to generate Finance report!!') {
                     message.error(this.lang.MESSAGE.FINCREPORTDATANOTPROVIDEDERROR,3)
                  }
                  else { message.error(response.statusText); }
               })
               .catch(err => {
                  this.setState({ spin: false });
                  message.error(this.lang.MESSAGE.FINCREPORTGENERATEFAILURE/*err.message*/);
                  console.log(err);
               })
         }
         else if (report_type === 'cont') {
            let file_name = 'Contractor_report_' + duration +'_'+version+ '.pdf';
            this.generateContractorReportGeneric(req_data, file_name, this.lang.MESSAGE.CONTREPORTYEARDATANOTFOUNDERROR);
         }
         else if (report_type === 'resrv'){
            let file_name = 'Reservation_report_' + duration +'_'+version+ '.pdf';
            this.generateReservationReportGeneric(req_data, file_name);
         }
      }//End of ClientID exists
      else { message.error(this.lang.MESSAGE.LOGINERROR,3); }
   }//End of Method

   downloadAllData = (report_type) => {
      let req_data = this.getReportBasicData();
      if (req_data.client_id) {
         const version = req_data.opt == 'en' ? 'Eng' : 'Arb';
         Object.assign(req_data, {allData: 'yes'}); 
         if (report_type === 'fn') {
            axios.get(`${complete_URL}/finance_reports/export_pdf_completeData/`, {
               responseType: 'arraybuffer',
               headers: { 'Accept': 'application/pdf' },
               params: { data: JSON.stringify(req_data) }
            })
               .then(response => {
                  this.setState({ spin: false });
                  if (response.data.byteLength > 0) { 
                     this.generate_pdf_popup( response.data, 'Finance_report_all_data.pdf');
                  }
                  else if (response.statusText === 'Required input not found to generate Finance report!!') {
                     message.error(this.lang.MESSAGE.FINCREPORTDATANOTPROVIDEDERROR,3)
                  }
                  else { message.error(response.statusText); }
               })
               .catch(err => {
                  this.setState({ spin: false });
                  message.error(this.lang.MESSAGE.FINCREPORTGENERATEFAILURE/*err.message*/);
                  console.log(err);
               })
         }
         else if (report_type === 'cont') {
            let file_name = 'Contractor_report_all_data' +'_'+version+ '.pdf';
            this.generateContractorReportGeneric(req_data, file_name, this.lang.MESSAGE.CONTREPORTALLDATANOTFOUNDERROR);
         }
         else if (report_type === 'resrv'){
            let file_name = 'Reservation_report_all_data'+version+'.pdf';
            this.generateReservationReportGeneric(req_data, file_name);
         }
      }//End of ClientID exists
      else { message.error(this.lang.MESSAGE.LOGINERROR,3); }
   }//End of Method

   setParamsOnDate = (date, resetOpt, report_type) => {
      if (date) {
         if (report_type === 'fn') {
            let new_report_obj = Object.assign(this.state.searchValues_fn_Report, { date: date })
            this.setState({
               searchValues_fn_Report: new_report_obj,
               disableDownloadReport: resetOpt,
            })
         }//End of if Fin
         else if (report_type === 'cont') {
            if (!resetOpt) {
               let { serv_cont_name, serv_cont_name_ar } = this.state.searchValues_cont_Report
               if (!serv_cont_name || !serv_cont_name_ar) {
                  resetOpt = true
               }
            }
            let new_report_obj = Object.assign(this.state.searchValues_cont_Report, { date: date })
            this.setState({
               searchValues_cont_Report: new_report_obj,
               disableDownloadContReport: resetOpt
            })
         }//End of if Cont
         else if (report_type === 'resrv') {
            let new_report_obj = Object.assign(this.state.searchValues_resrv_Report, { date: date })
            this.setState({
              searchValues_resrv_Report: new_report_obj,
              disableDownloadResrvReport: resetOpt,
            })
         }//End of if resrv
      }
   }//End of Method

   setParamsDatesDuration = (from_date, to_date, resetOpt, report_type) => {
      if (from_date && to_date) {
         // console.log('setParamsDatesDuration.. from_date->',from_date,' to_date->',to_date);
         if (report_type === 'fn') {
            let new_report_obj = Object.assign(this.state.searchValues_fn_Report, { from_date: from_date, to_date: to_date })
            this.setState({
               searchValues_fn_Report: new_report_obj,
               disableDownloadReport: resetOpt,
            });
         }//End of if Fin
         else if (report_type === 'cont') {
            if (!resetOpt) {
               let { serv_cont_name, serv_cont_name_ar } = this.state.searchValues_cont_Report
               if (!serv_cont_name || !serv_cont_name_ar) {
                  resetOpt = true
               }
            }
            let new_report_obj = Object.assign(this.state.searchValues_cont_Report, { from_date: from_date, to_date: to_date })
            this.setState({
               searchValues_cont_Report: new_report_obj,
               disableDownloadContReport: resetOpt
            });
         }//End of if Cont
         else if (report_type === 'resrv') {
            let new_report_obj = Object.assign(this.state.searchValues_resrv_Report, { from_date: from_date, to_date: to_date })
            this.setState({
               searchValues_resrv_Report: new_report_obj,
               disableDownloadResrvReport: resetOpt
            });
         }//End of if Resrv
      }
   }//End of Method

   setParamsOneMonthOfYear = (month, year, resetOpt, report_type) => {
      if (report_type === 'fn') {
         let new_report_obj = Object.assign(this.state.searchValues_fn_Report, { month: month, year: year })
         this.setState({ searchValues_fn_Report: new_report_obj,  disableDownloadReport: resetOpt })
         /*if (month && year) {// create bar chart if data is being set
            this.fetchesGraphData2(month, year);
         }*/
      }//End of if Fin
      else if (report_type === 'cont') {
         if (!resetOpt) {
            let { serv_cont_name, serv_cont_name_ar } = this.state.searchValues_cont_Report
            if (!serv_cont_name || !serv_cont_name_ar) { resetOpt = true }
         }
         let new_report_obj = Object.assign(this.state.searchValues_cont_Report, { month: month, year: year })
         this.setState({ searchValues_cont_Report: new_report_obj, disableDownloadContReport: resetOpt })
      }//End of if Cont
      else if (report_type === 'resrv') {
         let new_report_obj = Object.assign(this.state.searchValues_resrv_Report, { month: month, year: year })
         this.setState({
          searchValues_resrv_Report: new_report_obj,
          disableDownloadResrvReport: resetOpt,
         })
      }//End of if resrv
   }//End of Method

   setParamsBetweenMonths = (month1, month2, year1, year2, resetOpt, report_type) => {
      if (report_type === 'fn') {
         let new_report_obj = Object.assign(this.state.searchValues_fn_Report, { month1: month1, month2: month2, year1: year1, year2: year2 })
         this.setState({ searchValues_fn_Report: new_report_obj,   disableDownloadReport: resetOpt })
         /*if(month1 && year1 && month2 && year2){// create bar chart if data is being set
            //Here first already Bar chart is created from 'handleChange' actionListener and then params are set in State         
         }*/
      }//End of if Fin
      else if (report_type === 'cont') {
         if (!resetOpt) {
            let { serv_cont_name, serv_cont_name_ar } = this.state.searchValues_cont_Report
            if (!serv_cont_name || !serv_cont_name_ar) { resetOpt = true }
         }
         let new_report_obj = Object.assign(this.state.searchValues_cont_Report, { month1: month1, month2: month2, year1: year1, year2: year2 })
         this.setState({ searchValues_cont_Report: new_report_obj, disableDownloadContReport: resetOpt })
      }//End of if Cont
      else  if (report_type === 'resrv') {
         let new_report_obj = Object.assign(this.state.searchValues_resrv_Report, { month1: month1, month2: month2, year1: year1, year2: year2 })
         this.setState({
          searchValues_resrv_Report: new_report_obj,
          disableDownloadResrvReport: resetOpt,
         })
      }
   }//End of Method

   setParamsYear = (yearOnly, resetOpt, report_type) => {
      if (report_type === 'fn') {
         let new_report_obj = Object.assign(this.state.searchValues_fn_Report, { yearOnly: yearOnly })
         this.setState({  searchValues_fn_Report: new_report_obj, disableDownloadReport: resetOpt })
         /*if (yearOnly) {// create bar chart if data is being set
            this.fetchesGraphDataByYear(yearOnly);
         }*/
      }
      else if (report_type === 'cont') {
         if (!resetOpt) {
            let { serv_cont_name, serv_cont_name_ar } = this.state.searchValues_cont_Report
            if (!serv_cont_name || !serv_cont_name_ar) { resetOpt = true }
         }
         let new_report_obj = Object.assign(this.state.searchValues_cont_Report, { yearOnly: yearOnly })
         this.setState({  searchValues_cont_Report: new_report_obj, disableDownloadContReport: resetOpt })
      }
      else if (report_type === 'resrv') {
         let new_report_obj = Object.assign(this.state.searchValues_resrv_Report, { yearOnly: yearOnly })
         this.setState({
          searchValues_resrv_Report: new_report_obj,
          disableDownloadResrvReport: resetOpt,
         })
      }
   }//End of Method

   downloadReport = (report_type) => {
      if (report_type === 'fn') {
         this.setState({ spinningMessage: this.lang.MESSAGE.FINCDOWNLOADREPORTTIP, spin: true })
         let { month, year, month1, month2, year1, year2, yearOnly, allData } = this.state.searchValues_fn_Report;
         if (month && year) {
            this.downloadOneMonthOfYearData(month, year, 'fn')
            this.setParamsOneMonthOfYear('', '', true, 'fn');
         }
         else if (month1 && month2 && year1 && year2) {
            this.downloadBetweenMonthsData(month1, month2, year1, year2, 'fn');
            this.setParamsBetweenMonths('', '', '', '', true, 'fn');
         }
         else if (yearOnly) {
            this.downloadYearOnlyData(yearOnly, 'fn');
            this.setParamsYear('', true, 'fn');
         }
         else if (allData) { 
            this.downloadAllData('fn');
            this.setState({
               searchValues_fn_Report: {
                  month: '', year: '',
                  month1: '', year1: '', month2: '',
                  year2: '', yearOnly: '', allData: ''
               },
               disableDownloadReport: true,
            });
         }
      }//End of if Fin
      else if (report_type === 'cont') {
         this.setState({ spinningMessage: this.lang.MESSAGE.CONTDOWNLOADREPORTTIP, spin: true })
         let { serv_cont_name, serv_cont_name_ar, date, from_date, to_date, month, year, month1, month2, year1, year2, yearOnly, allData } = this.state.searchValues_cont_Report;
         // console.log('Contractor report obj->', this.state.searchValues_cont_Report);
         if (date && serv_cont_name && serv_cont_name_ar) {
            this.downloadOnDateData(date, 'cont');
            this.setParamsOnDate('', true, 'cont');
         }
         else if (from_date && to_date && serv_cont_name && serv_cont_name_ar) {
            this.downloadBetweenDatesData(from_date, to_date, 'cont');
            this.setParamsDatesDuration('', '', true, 'cont');
         }
         else if (month && year && serv_cont_name && serv_cont_name_ar) {
            this.downloadOneMonthOfYearData(month, year, 'cont')
            this.setParamsOneMonthOfYear('', '', true, 'cont');
         }
         else if (month1 && month2 && year1 && year2 && serv_cont_name && serv_cont_name_ar) {
            this.downloadBetweenMonthsData(month1, month2, year1, year2, 'cont');
            this.setParamsBetweenMonths('', '', '', '', true, 'cont');
         }
         else if (yearOnly && serv_cont_name && serv_cont_name_ar) {
            this.downloadYearOnlyData(yearOnly, 'cont');
            this.setParamsYear('', true, 'cont');
         }
         else if (allData && serv_cont_name && serv_cont_name_ar) {
            this.downloadAllData('cont');
            this.setState({
               searchValues_cont_Report: {
                  cont_id : '',
                  serv_cont_name: '', serv_cont_name_ar: '',
                  date: '', from_date: '', to_date: '',
                  month: '', year: '', yearOnly: '',
                  month1: '',year1: '', 
                  month2: '', year2: '', allData: ''
               },
               disableDownloadContReport: true,
            });
         }
      }//End of Contractor Choice
      else if (report_type === 'resrv') {
         this.setState({ spinningMessage: this.lang.MESSAGE.RESRVDOWNLOADREPORTTIP, spin: true })
         let { status, date, from_date, to_date, month, year, month1, month2, year1, year2, yearOnly, allData } = this.state.searchValues_resrv_Report;
         if (date ) {
            this.downloadOnDateData(date, report_type);
            this.setParamsOnDate('', true, report_type);
         }
         else if (from_date && to_date ) {
            this.downloadBetweenDatesData(from_date, to_date, report_type);
            this.setParamsDatesDuration('', '', true, report_type);
         }
         else if (month && year) {
            this.downloadOneMonthOfYearData(month, year, report_type);
            this.setParamsOneMonthOfYear('', '', true, report_type);
         }
         else if (month1 && month2 && year1 && year2) {
            this.downloadBetweenMonthsData(month1, month2, year1, year2, report_type);
            this.setParamsBetweenMonths('', '', '', '', true, report_type);
         }
         else if (yearOnly) {
            this.downloadYearOnlyData(yearOnly, report_type);
            this.setParamsYear('', true, report_type);
         }
         else if (allData) {
            this.downloadAllData(report_type);
            this.setState({
               searchValues_resrv_Report: {
                  date: '', from_date: '', to_date: '',
                  month: '', year: '', yearOnly: '',
                  month1: '',year1: '', status,
                  month2: '', year2: '', allData: ''
               },
               disableDownloadResrvReport: true,
            });
         }
      }//End of Reservation Choice
   }//End of downloadReport Method

   handleChange = (value, report_type) => {
      // console.log('handleChange ->',value,report_type);
      if (report_type === 'fn') {
         this.setState({ fn_range_value: value });
      }
      else if (report_type === 'cont') {
         this.setState({ cont_range_value: value });
      }
      else if (report_type === 'resrv') {
         this.setState({ resrv_range_value: value });
      }
      // console.log('RangePicker ->',value);
   };

   // Check if Contractor is selected & any of duration is also selected then allow USER to download report
   handleSelectContractor = (contractor_index) => {
      let {id, name, name_ar} = this.state.contractors[contractor_index];
      // console.log('Contractor->',contractor);
      if (contractor_index > -1) {
         let { date, from_date, to_date, month, year, month1, month2, year1, year2, yearOnly, allData } = this.state.searchValues_cont_Report;
         let disableDownloadContReport = true;
         if (date || (from_date && to_date) || (month && year) || (month1 && month2 && year1 && year2) || (yearOnly) || (allData)) {
            disableDownloadContReport = false;
         }
         // let newContObj = Object.assign(this.state.searchValues_cont_Report, { serv_cont_name: contractor.name, serv_cont_name_ar: contractor.name_ar })
         let newContObj = {...this.state.searchValues_cont_Report, cont_id : id, serv_cont_name: name, serv_cont_name_ar: name_ar };

         this.setState({ disableDownloadContReport,  searchValues_cont_Report: newContObj });
      }
   }//End of Method

   handleSelectStatus = (statusOpt_index) => {
      let statusObj = this.state.resrv_Options[statusOpt_index];
      // console.log('statusObj->',statusObj);
      if (statusOpt_index > -1 && statusOpt_index < this.state.resrv_Options.length) {
         let {date, from_date, to_date, month, year, month1, month2, year1, year2, yearOnly, allData} = this.state.searchValues_resrv_Report;
            let disableDownloadResrvReport = true;
            if ((date)||(from_date && to_date)||(month && year)||(month1 && month2 && year1 && year2)||(yearOnly)||(allData) ) {
               disableDownloadResrvReport = false;
            }
            let newResrvObj = Object.assign(this.state.searchValues_resrv_Report, { status : statusObj.name })
            this.setState({disableDownloadResrvReport,  searchValues_resrv_Report: newResrvObj });
      }//End of Index Validation
   }//End of Method

   handleSelectReportType = (report_index) => {
      if (report_index > -1 && report_index < this.state.ReportOptions.length) {
         this.setState({ reportOptIndex: report_index })
      }
   }//End of Method

   render() {
      const is_EN = localStorage.getItem('lang') === 'EN';
      let {contractors,showFilter, filterNode, showContCalenderFilter, filterContCalenderNode,filterResrvCalenderNode, showResrvCalenderFilter,
            spinningMessage, spin, ReportOptions, reportOptIndex, dir, display, textAlignValue, } = this.state;

      const {DATE_OPTION, DATEL, MONTHLY_OPTION, MONTHL, YEARLY_OPTION, ALL, STATUS,
               STARTDATE, ENDDATE, STARTMONTH, ENDMONTH, CONTRACTOR } = this.lang.LABELS;

      return (
         <Spin tip={spinningMessage} spinning={spin}>
            {this.CustomRow}
            <Row id="clientsTable" gutter={24} style={{ marginLeft: '8.5%', marginRight: '8.5%' }}>
               <Col span={24}>
                  <Card className="chartClass"
                     title={[
                        <span key={'title123'} style={{ display: 'flex', flex: 1, justifyContent: 'space-between', }} >
                           <Row gutter={24} dir={dir} style={{ marginTop: '3%', width: '100%', display }}>
                              <Col span={12}>
                                 {(is_EN) ? <h1>{ReportOptions[reportOptIndex].name}</h1> 
                                          : <h1>{ReportOptions[reportOptIndex].name_ar}</h1>
                                 }
                              </Col>
                              <Col span={12}>
                                 <Select  value={(is_EN) ? ReportOptions[reportOptIndex].name : ReportOptions[reportOptIndex].name_ar}
                                          style={{ width: '50%' }} placeholder={'?'}
                                          onChange={(rpt_id) => { this.handleSelectReportType(rpt_id) }} 
                                    >
                                    {(is_EN) ?
                                       ReportOptions.map((rpt, index) => (
                                          <Select.Option key={'rpt'+rpt.id} value={index}>{rpt.name}</Select.Option>
                                       ))
                                       : ReportOptions.map((rpt, index) => (
                                          <Select.Option style={{textAlign:'right'}} key={'rpt'+rpt.id} value={index}>{rpt.name_ar}</Select.Option>
                                       ))
                                    }
                                 </Select>
                              </Col>
                           </Row>
                        </span>
                     ]}>
                     {  //Contractor report
                        reportOptIndex == 0 && <>
                           <Row gutter={24}  dir ={dir} style={{display, zIndex : 999}}>
                              <Col span={8}>
                                 <Select suffixIcon={[<Icon key={'ctft'} type="filter" />]} style={{ width: '100%' }}
                                    onChange={(value) => { this.filterContDataOptionOnchange(value) }} placeholder={this.props.language.FILTER_OPTION}>
                                    <Select.Option style={{textAlign: textAlignValue}} value="d"   key={'ct'+1}>{ DATE_OPTION}</Select.Option>
                                    <Select.Option style={{textAlign: textAlignValue}} value="ds"  key={'ct'+2}>{ DATEL}</Select.Option>
                                    <Select.Option style={{textAlign: textAlignValue}} value="m"   key={'ct'+3}>{ MONTHLY_OPTION}</Select.Option>
                                    <Select.Option style={{textAlign: textAlignValue}} value="mm"  key={'ct'+4}>{ MONTHL}</Select.Option>
                                    <Select.Option style={{textAlign: textAlignValue}} value="y"   key={'ct'+5}>{ YEARLY_OPTION}</Select.Option>
                                    <Select.Option style={{textAlign: textAlignValue}} value="aa"  key={'ct'+6}>{ ALL}</Select.Option>
                                 </Select>
                              </Col>
                              <Col span={16} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                 {
                                    showContCalenderFilter &&
                                      filterContCalenderNode === 'd' ?
                                       <DatePicker format="YYYY-MM-DD" onChange={(e) => { this.setParamsOnDate(moment(e).locale('en').format('YYYY-MM-DD'), false, 'cont') }} style={{ width: '40%' }} placeholder={DATE_OPTION} />
                                       :
                                      filterContCalenderNode === 'ds' ?
                                          (<> <DatePicker
                                                disabledDate={this.disabledStartDate}
                                                format="YYYY-MM-DD" placeholder={STARTDATE} style={{ width: '40%' }}
                                                onChange={(date, dateString) => { this.onStartChange(date, dateString, 'cont') }}
                                                onOpenChange={this.handleStartOpenChange}
                                             />

                                             <DatePicker
                                                disabledDate={this.disabledEndDate} open={this.state.endOpen}
                                                format="YYYY-MM-DD" placeholder={ENDDATE} style={{ width: '40%' }}
                                                onChange={(date, dateString) => { this.onEndChange(date, dateString, 'cont') }}
                                                onOpenChange={this.handleEndOpenChange}
                                             />
                                          </>)
                                       :
                                       filterContCalenderNode === 'm' ?
                                          <MonthPicker  style={{ width: '40%' }} placeholder={MONTHLY_OPTION}
                                             onChange={(e) => { this.setParamsOneMonthOfYear(moment(e).locale('en').format('MM'), moment(e).format('YYYY'), false, 'cont') }} />
                                       : 
                                       filterContCalenderNode === 'y' ?
                                          <Row gutter={24}>
                                             <Col span={24} style={{width: '40%', float : textAlignValue}}>
                                                <YearPicker showToday={false} style={{ width: '40%', zIndex: 9 }} placeholder={YEARLY_OPTION}
                                                   onChange={(e) => { this.setParamsYear(e, false, 'cont') }} />
                                             </Col>
                                          </Row>
                                       :
                                       filterContCalenderNode === 'mm' ?
                                          <RangePicker
                                             format="YYYY-MM" placeholder={[STARTMONTH, ENDMONTH]}
                                             value={this.state.cont_range_value} mode={this.state.cont_mode}
                                             onChange={(value) => { this.handleChange(value, 'cont') }}
                                             onPanelChange={(value, mode) => { this.handlePanelChange(value, mode, 'cont') }}
                                          />

                                       : ''
                                 }
                              </Col>
                           </Row>
                           <Row gutter={24} style={{ marginTop: '3%' }}>
                              <Col span={24}>
                                 <Select style={{ width: '31.4%' }} onChange={(cont) => { this.handleSelectContractor(cont) }} placeholder={CONTRACTOR}>
                                    {
                                       (is_EN) ?
                                          contractors.length > 0 && contractors.map((cnt, index) => (
                                             <Select.Option key={cnt.id} value={index}>{cnt.name}</Select.Option>
                                          ))
                                          : contractors.length > 0 && contractors.map((cnt, index) => (
                                             <Select.Option style={{textAlign: 'right'}} key={cnt.id} value={index}>{cnt.name_ar}</Select.Option>
                                          ))
                                    }
                                 </Select>
                              </Col>
                           </Row>
                           <Row gutter={24} style={{ marginTop: '20%', marginLeft: '-15%' }}>
                              <Col span={24} style={{ textAlign: 'center' }}>
                                 <Button
                                    key = {'cont_btn'}
                                    type="primary" icon="download" size={'default'}
                                    onClick={() => { this.downloadReport('cont') }}
                                    disabled={this.state.disableDownloadContReport}
                                 >
                                 {this.lang.BUTTON.DOWNLOADREPORT}
                                 </Button>
                              </Col>
                           </Row>
                        </>//End of Contractor report
                     }
                     {  //Finance report
                        reportOptIndex == 1 && <>
                           <Row gutter={24}  dir ={dir} style={{display, zIndex : 999}}>
                              <Col span={8}>
                                 <Select suffixIcon={[<Icon  key={'fnft'} type="filter" />]} style={{ width: '100%' }}
                                    onChange={this.filterfnDataOptionOnchange} placeholder={this.props.language.FILTER_OPTION}>
                                    <Select.Option style={{textAlign: textAlignValue}} value="m" key={3}>{ MONTHLY_OPTION}</Select.Option>
                                    <Select.Option style={{textAlign: textAlignValue}} value="mm" key={4}>{ MONTHL}</Select.Option>
                                    <Select.Option style={{textAlign: textAlignValue}} value="y" key={5}>{ YEARLY_OPTION}</Select.Option>
                                    <Select.Option style={{textAlign: textAlignValue}} value="aa" key={6}>{ ALL}</Select.Option>
                                 </Select>
                              </Col>
                              <Col span={16} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                 {
                                    showFilter &&
                                    filterNode === 'm' ?
                                    <MonthPicker  style={{ width: '40%' }} placeholder={MONTHLY_OPTION}
                                       onChange={(e) => { this.setParamsOneMonthOfYear(moment(e).locale('en').format('MM'), moment(e).format('YYYY'), false, 'fn') }} />
                                    : 
                                    filterNode === 'y' ?
                                       <Row gutter={24}>
                                          <Col span={24} style={{width: '40%', float: textAlignValue}}>
                                             <YearPicker style={{width: '40%' }} placeholder={YEARLY_OPTION}
                                             onChange={(e) => { this.setParamsYear(e, false, 'fn') }} />
                                          </Col>
                                       </Row>      
                                    :
                                    filterNode === 'mm' ?
                                       <RangePicker
                                          format="YYYY-MM" placeholder={[STARTMONTH, ENDMONTH]}
                                          value={this.state.fn_range_value} mode={this.state.fn_mode}
                                          defaultValue ={this.state.fn_range_value}
                                          onChange={(value) => { this.handleChange(value, 'fn') }}
                                          onPanelChange={(value, mode) => { this.handlePanelChange(value, mode, 'fn') }}
                                       />
                                    : ''
                                 }
                              </Col>
                           </Row>
                           <Row gutter={24} style={{ marginTop: '30%', marginLeft: '-15%' }}>
                              <Col span={24} style={{ textAlign: 'center' }}>
                                 <Button
                                    key="fn_btn"
                                    type="primary" icon="download" size={'default'}
                                    onClick={() => { this.downloadReport('fn') }}
                                    disabled={this.state.disableDownloadReport}
                                 >
                                    {this.lang.BUTTON.DOWNLOADREPORT}
                                 </Button>
                              </Col>
                           </Row>  
                        </>
                     }
                     
                     {//Reservation Report:Note in future this needed to refactor too like below
                        reportOptIndex == 2 && <>
                           <Row gutter={24} dir ={dir} style={{display, zIndex : 999}}>
                              <Col span={8}>
                              <Select suffixIcon={[<Icon key={'rsvft'} type="filter" />]} style={{ width: '100%' }}
                                 onChange={(value) => { this.filterResrvDataOptionOnchange(value) }} placeholder={this.props.language.FILTER_OPTION}>
                                 <Select.Option style={{textAlign: textAlignValue}} value="d"   key={1}>{ DATE_OPTION}</Select.Option>
                                 <Select.Option style={{textAlign: textAlignValue}} value="ds"  key={2}>{ DATEL}</Select.Option>
                                 <Select.Option style={{textAlign: textAlignValue}} value="m"   key={3}>{ MONTHLY_OPTION}</Select.Option>
                                 <Select.Option style={{textAlign: textAlignValue}} value="mm"  key={4}>{ MONTHL}</Select.Option>
                                 <Select.Option style={{textAlign: textAlignValue}} value="y"   key={5}>{ YEARLY_OPTION}</Select.Option>
                                 <Select.Option style={{textAlign: textAlignValue}} value="aa"  key={6}>{ ALL}</Select.Option>
                              </Select>
                              </Col>
                              <Col span={16} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                 {
                                    showResrvCalenderFilter &&
                                       filterResrvCalenderNode === 'd' ?
                                       <DatePicker format="YYYY-MM-DD"  style={{ width: '40%' }} placeholder={DATE_OPTION}
                                          onChange={(e) => { this.setParamsOnDate(moment(e).locale('en').format('YYYY-MM-DD'), false, 'resrv') }} />
                                       :
                                       filterResrvCalenderNode === 'ds' ?
                                          (<>
                                             <DatePicker
                                                disabledDate={this.disabledStartDate}
                                                format="YYYY-MM-DD" style={{ width: '40%' }} placeholder={STARTDATE}
                                                onChange={(date, dateString) => { this.onStartChange(date, dateString, 'resrv') }}
                                                onOpenChange={this.handleStartOpenChange}
                                             />
                     
                                             <DatePicker
                                                disabledDate={this.disabledEndDate}
                                                format="YYYY-MM-DD" style={{ width: '40%' }} placeholder={ENDDATE}
                                                onChange={(date, dateString) => { this.onEndChange(date, dateString, 'resrv') }}
                                                open={this.state.endOpen}
                                                onOpenChange={this.handleEndOpenChange}
                                             />
                                          </>)
                     
                                       :
                                          filterResrvCalenderNode === 'm' ?
                                             <MonthPicker style={{width: '40%' }} placeholder={MONTHLY_OPTION} 
                                                onChange={(e) => { this.setParamsOneMonthOfYear(moment(e).locale('en').format('MM'), moment(e).locale('en').format('YYYY'), false, 'resrv') }} />
                                       
                                       : filterResrvCalenderNode === 'y' ?
                                                <Row gutter={24}>
                                                   <Col span={24} style={{width: '40%', float:this.state.textAlignValue}}>
                                                      <YearPicker style={{width: '40%' }} onChange={(e) => { this.setParamsYear(e, false, 'resrv') }} placeholder={this.lang.LABELS.YEARLY_OPTION} />
                                                   </Col>
                                                </Row>
                                       :
                                       filterResrvCalenderNode === 'mm' ?
                                          <RangePicker
                                             format="YYYY-MM" placeholder={[STARTMONTH, ENDMONTH]} 
                                             value={this.state.resrv_range_value} mode={this.state.resrv_mode}
                                             onChange={(value) => { this.handleChange(value, 'resrv') }}
                                             onPanelChange={(value, mode) => { this.handlePanelChange(value, mode, 'resrv') }}
                                          />
                     
                                       : ''
                                 }
                              </Col>
                           </Row>
                           <Row gutter={24} style={{ marginTop: '3%' }}>
                              <Col span={24}>
                                 <Select style={{ width: '31.4%' }} onChange={(ro_index) => { this.handleSelectStatus(ro_index) }} placeholder={STATUS}>
                                    {
                                       (is_EN) ?
                                          this.state.resrv_Options.map((ro, index) => (
                                             <Select.Option key={ro.id} value={index}>
                                                {ro.name.charAt(0).toUpperCase() + ro.name.slice(1).toLowerCase()}
                                             </Select.Option>
                                          ))
                                          : this.state.resrv_Options.map((ro, index) => (
                                             <Select.Option style={{textAlign: 'right'}} key={ro.id} value={index}>{ro.name_ar}</Select.Option>
                                          ))
                                    }
                                 </Select>
                              </Col>
                           </Row>
                           <Row gutter={24} style={{ marginTop: '20%', marginLeft: '-15%' }}>
                              <Col span={24} style={{ textAlign: 'center' }}>
                                 <Button
                                    type="primary" icon="download" size={'default'}
                                    onClick={() => { this.downloadReport('resrv') }}
                                    disabled={this.state.disableDownloadResrvReport}
                                 >
                                    {this.lang.BUTTON.DOWNLOADREPORT}
                                 </Button>
                              </Col>
                           </Row>
                        </>
                     }
                     {//Reservation Generic Report Component as Payment Transaction report
                        reportOptIndex == 3 && 
                        <ReservationGenericReport 
                           {...this.props} 
                           lang = {this.lang}
                           report_type = {'pmtTr'}
                           report_name = {'PaymentTransactionsReport_'}
                           report_url = {'payment_report/generic_report'}
                           resrv_Options = {this.state.resrv_Options}
                           generate_pdf_popup = {this.generate_pdf_popup}
                           getReportBasicData = {this.getReportBasicData}  
                        />
                     }
                     {//Reservation Generic Report Component as Reservation List report
                        reportOptIndex == 4 && 
                        <ReservationGenericReport 
                           {...this.props} 
                           lang = {this.lang}
                           report_type = {'resrvList'}
                           report_name = {'ReservationList_report_'}
                           report_url = {'resrvlist_report/generic_report'}
                           resrv_Options = {this.state.resrv_Options}
                           generate_pdf_popup = {this.generate_pdf_popup}
                           getReportBasicData = {this.getReportBasicData}  
                        />
                     }
                  </Card>
               </Col>
            </Row>
         </Spin> 
      )
   }
}

export default Reportscreen;