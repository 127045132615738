import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, Table, Card, Select, message, Checkbox, Empty} from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const { Option } = Select;
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class RoleUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      role: {},
      assignedScreen: [],
      screens: [],
      selectedScreenToAssign: '',
      updateScreens: [],
      SelectedScreenToRemove: [],
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._ROLE.UPDATE;
    this.msg = props.language._ROLE.MESSAGE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.LABELS.SCREENNAME}`,
        dataIndex: 'name',
        align :'left'
      }: 
      {
        title: `${this.lang.LABELS.SCREENNAME}`,
        dataIndex: 'name_ar',
        align :'right'
      },
      {
        title: `${this.lang.LABELS.EDITABLE}`,
        align : 'center',
        render: (text, record) => (
          <span>
            <Checkbox onChange={this.changeEdit.bind(this, record)} />
          </span>
        )
      },
      {
        title: `${this.lang.LABELS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '20%',
        align : 'center',
        key: 'last_name',
      }
    ]
  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  changeEdit = (e) => {
    // console.log(e)
    // console.log(this.state.assignedScreen)
  }

  componentDidMount() {
    const role_id = localStorage.getItem('roleid');
    if (role_id) {
      this.fetch();
    }
    else{
      this.props.history.push('/dashboard/roles');
    }
  }

  fetch = ()=>{
    const id = localStorage.getItem('roleid');
    const clientId = localStorage.getItem('client_id');
    var screens;
    // console.log('roleid', id)
    // console.log('clientid', clientId)
    let  headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/modulesscreens/get_screens_by_modules/${clientId}`,{headers})
      .then(res => {
        screens = res.data.data
      })
      .catch(err => { console.log('Error occured while fetching Module screens ->',err) })

    // var screens = JSON.parse(localStorage.getItem('screenList'))
    // let temarr = []
    axios.get(`${complete_URL}/roles/${id}`, {headers})
      .then(res => {
        this.setState({ role: res.data[0] })
      })
      .catch(err => { console.log('Error occured while fetching Role Object ->',err) });

    axios.get(`${complete_URL}/screens/get_screens_by_roles/${clientId}/${id}`,{headers})
      .then(result => {
        // console.log(result.data[0])
        this.setState({ assignedScreen: result.data[0] })
      })
    // console.log(screens)
    setTimeout(() => {
      var result1 = screens
      var result2 = this.state.assignedScreen
      var props = '';
      if (localStorage.getItem('lang') === 'EN') {
        props = ['id', 'name'];
      } else {
        props = ['id', 'name_ar'];
      }
      // console.log(result2)

      var result = result1.filter(function (o1) {
        return !result2.some(function (o2) {
          return o1.id === o2.id;
        });
      }).map(function (o) {
        return props.reduce(function (newo, name) {
          newo[name] = o[name];
          return newo;
        }, {});
      });
      setTimeout(() => {
        this.setState({ screens: result })
      }, 500)
    }, 1000);

  }// End of Method

  deleteCategory(record) {
    let tempArray = [...this.state.SelectedScreenToRemove]
    tempArray.push(record)
    let tempArray2 = [...this.state.assignedScreen]
    let array3 = [...this.state.screens]

    for (let a = 0; a <= tempArray2.length - 1; a++) {
      if (tempArray2[a].id === record.id) {
        array3.push(tempArray2[a])
        tempArray2.splice(a, 1)
      }
    }
    this.setState({ SelectedScreenToRemove: tempArray, assignedScreen: tempArray2, screens: array3 })
  }

  handleSelectScreen = (v) => {
    // console.log(v)
    this.setState({ selectedScreenToAssign: v })
  }

  assignScreenAdd = () => {
    let screens = [...this.state.screens]
    let tempArray = [...this.state.assignedScreen]
    let tempArray2 = [...this.state.screens]
    let tempArray3 = [...this.state.updateScreens]
    for (let a = 0; a <= screens.length - 1; a++) {
      if (screens[a].id === this.state.selectedScreenToAssign) {

        tempArray.push(screens[a])
        tempArray3.push(screens[a])
      }
    }
    for (let a = 0; a <= this.state.screens.length - 1; a++) {
      this.state.screens[a].id === this.state.selectedScreenToAssign && tempArray2.splice(a, 1)
    }
    document.getElementsByClassName("ant-select-selection-selected-value").length !== 0
      ?
      document.getElementsByClassName("ant-select-selection-selected-value")[0].innerHTML = ""
      :
      console.log()
    this.setState({ assignedScreen: tempArray, screens: tempArray2, updateScreens: tempArray3 })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let  headers = getHeadersForHttpReq();

        let id = localStorage.getItem('roleid')
        let client_id = localStorage.getItem('client_id')
        axios.put(`${complete_URL}/roles/${id}`, { name: values.name, name_ar: values.name_ar },{headers})
          .then(res => { 
            message.success(this.msg.UPDATESUCCESS)
          })
          .catch(err => {
            message.error(this.msg.UPDATEFAILURE,3);
            console.log('Error occured while updating the Role->',err) 
          })
          // Loop for Creating selected Screen(s) to Role (Role-Screen)
        for (let a = 0; a <= this.state.updateScreens.length - 1; a++) {
          axios.post(`${complete_URL}/rolescreens/add`, {
            role_id: id,
            client_id: client_id,
            screen_id: this.state.updateScreens[a].id,
            user_id: localStorage.getItem('user_id'),
            is_editable : 0
          }, {headers})
            .then(res => { })
            .catch(err => {console.log('Error occured while creating selected Screen(s) to Role (Role-Screen) ->',err) })
        }
        // Loop for deleting unselected Screen(s) to Role (Role-Screen)
        for (let a = 0; a <= this.state.SelectedScreenToRemove.length - 1; a++) {
          let screenId = this.state.SelectedScreenToRemove[a].id
          axios.post(`${complete_URL}/rolescreens/delete`,
            { screen_id: screenId, client_id: client_id, role_id: id }, {headers})
            .then(res => { })
            .catch(err => {console.log('Error occured while deleting unselected Screen(s) to Role (Role-Screen) -->',err) })
        }
        setTimeout(() => { this.props.history.push('/dashboard/roles') })
      }
    });
  }

  // Unnecessary code,, not being called...
  deleteUser = () => {
    let  headers = getHeadersForHttpReq();

    axios.delete(`${complete_URL}/roles/${localStorage.getItem('roleid')}`, { headers})
      .then(res => {
        this.props.history.push('roles')
        message.success(this.msg.DELETESUCCESS);
      })
      .catch(err => { message.error(this.msg.DELETEFAILURE,3) })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/roles">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={14}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/roles">
            <Button style={{margin: '16px auto'}} className="delete" type="" /* onClick={this.deleteUser}*/ title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/roles">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Link to="/dashboard/roles">
            <Button style={{margin: '16px auto'}} className="delete" type="" /*onClick={this.deleteUser}*/ title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let {dir, display, screens, assignedScreen} = this.state;
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              <Row gutter={24} dir= {dir} style={{display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      initialValue: this.state.role.name,
                      rules: [{ required: true, message:  this.msg.INSERTNAMEERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} type="text" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      initialValue: this.state.role.name_ar,
                      rules: [{ required: true, message: this.msg.INSERTNAMEARERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAMEAR} type="text" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.SELECTSCREEN}>
                    <Row gutter={24} dir= {dir} style={{display }}>
                      <Col span={17}>
                        <Select onChange={this.handleSelectScreen} >
                          {
                            (localStorage.getItem('lang') === 'EN') ?
                              screens.length > 0 && screens.map((screen, i) => (
                                <Option  style={{textAlign : 'left'}} value={screen.id} key={screen.id}>{screen.name}</Option>
                              ))
                              :
                              screens.length > 0 && screens.map((screen, i) => (
                                <Option style={{textAlign : 'right'}} value={screen.id} key={screen.id}>{screen.name_ar}</Option>
                              ))
                          }
                        </Select>
                      </Col>
                      <Col span={7}>
                        <Button type="ghost" onClick={this.assignScreenAdd}>{this.lang.BUTTON.ADD}</Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              {
                assignedScreen.length > 0 ?
                <Form.Item>
                  <Row id="clientsTable" gutter={24}>
                    <Col span={24}>
                      <Table columns={this.columns} rowKey="id" dataSource={assignedScreen} />
                    </Col>
                  </Row>
                </Form.Item>
                :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              }
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}
export default RoleUpdate = Form.create({ name: 'normal_login' })(RoleUpdate);