import React from 'react'
import axios from 'axios'
// import ReactDOM from 'react-dom'
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './index.css'
// import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';
import { LocaleProvider, Popover } from 'antd';
import * as egEG from 'antd/lib/locale-provider/ar_EG';
import { Row, Col, message } from 'antd'

if (localStorage.getItem('lang') === 'AR') {
   require('moment/locale/ar');
}
// const Search = Input.Search;
const localizer = BigCalendar.momentLocalizer(moment)
// moment.locale('ar');
const pt = localStorage.getItem('pt');

const complete_URL = API_URL+pt;


class DashBoard extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         myEventsList: [],
         data: [],
         columns: [],
         list: [],
         tableNo: '',
         showTable: false,
         dddd: {},
         pagination: {},
         page: 1,
         size: 3,
         searchValues: {
            amount: '',
            expense_type: '',
            note: '',
         },
         searchValues2: {
            no: '',
            from_date: '',
            to_date: '',
            status: '',
            customer: '',
            banquet: '',
            hall: ''
         }
      }
      // console.log(props)

      this.lang = props.language._EXPENSE.SETUP
      this.lang2 = props.language._RESERVATION.SETUP
      this.lang3 = props.language._RESERVATION.UPDATE.SERVICES.HALLSERVICE
      this.lang4 = props.language._DASHBOARD.VICTORYCHART.X
      this.lang5 = props.language._DASHBOARD.CALENDER.STATUS
   }

   // }
   handleChangePagination = (pagination, filters, sorter) => {
      let pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
         page: pager.current,
         pagination: pager,
      }, () => {
         this.fetches();
      })
   }

   handleChangeSearchValues = (prop) => (e) => {
      let search = { ...this.state.searchValues };
      search[`${prop}`] = e.target.value;
      this.setState({
         searchValues: search
      }, () => {
         this.fetches();
      });
   }

   handleLoad() {
   //   while(docdocument.getElementsByClassName("rbc-event-content").length > 1){
   //      if (document.getElementsByClassName("rbc-event-content").length > 0) {
   //             console.log(document.getElementsByClassName("rbc-event-content").length)
         //    this.handleLoad
         //  }
         //   else {
         //    // do your stuff
         //  }

   //   } 
   }

   componentDidMount() {
     this.getReservationData();

   }

   getReservationData=()=>{
      const headers = getHeadersForHttpReq();
      const client_id = localStorage.getItem('client_id');
      if (client_id) {
      // document.getElementsByTagName("table")[0].className = "dashboardComponent"
      // console.log('aa', document.getElementsByTagName("table")[0])
      let data, data2 = [];
      // axios.get(`${complete_URL}/contractors/getGraphData/${localStorage.getItem('client_id')}`, { headers})
      //    .then(reslt => {
      //       let a = []
      //       a[0] = { title: "Total Expenses " + reslt.data[0].open }
      //       a[1] = { title: "Total Commission " + reslt.data[1].open }
      //       a[2] = { title: "Total Paid Rent " + reslt.data[2].open }
      //       a[3] = { title: "Total  Contractor Amount " + reslt.data[3].open }
      //       this.setState({ data: Object.values(reslt.data), list: a }/*, () => {

      //          console.log(this.state.data)
      //       }*/)
      //    })
      //    .catch(err => {
      //       // console.log(err)
      //    })

      axios.get(`${complete_URL}/reservations/with_payment_check/${client_id}`, {headers})
         .then(result => { // console.log(result);
            data = [...result.data.data[0]]
            for (let i = 0; i <= data.length - 1; i++) {
               if (data[i].status !== 'canceled' ) {
                  data2[i] = {}
                  data2[i] = { title: '', id: '', start: '', end: '', customer_name: '', status: '', customer_phone : '', isSecDepPaid : '', recieved_ammount : '',paid_amount : '' }
                  let start = data[i].from_date.replace(/-/g, ',') + ',' + data[i].from_time.replace(/:/g, ',').slice()
                  start = start.split(',')
                  let end = data[i].to_date.replace(/-/g, ',') + ',' + data[i].to_time.replace(/:/g, ',').slice()
                  end = end.split(',')
                  data2[i].title = (localStorage.getItem('lang') === 'EN') ? data[i].hall_name : data[i].hall_name_ar

                  data2[i].id = data[i].id
                  data2[i].start = new Date(start[0], start[1] - 1, start[2], start[3], start[4], start[5])
                  data2[i].end = new Date(end[0], end[1] - 1, end[2], end[3], end[4], end[5])
                  data[i].uop_name === 'hourly' ? data2[i].allDay = false : data2[i].allDay = true
                  data2[i].customer_name = (localStorage.getItem('lang') === 'EN') ? data[i].customer_name : data[i].customer_name_ar
                  data2[i].status = (data[i].status === 'pending') ? this.lang5.PENDING : (data[i].status === 'reserved') ? this.lang5.RESERVED  :''
                  data2[i].isSecDepPaid = data[i].isSecDepPaid;
                  data2[i].recieved_ammount = data[i].recieved_ammount;
                  data2[i].paid_amount = data[i].paid_amount;
                  data2[i].customer_phone = data[i].customer_phone;
                  data2[i].notes = data[i].notes
               }
            }
            this.setState({ myEventsList: data2 }, () => {
               var div = document.getElementsByClassName("rbc-event-content");
               for (let i = 0; i < div.length; i++) { // console.log(div[i].title)
                  div[i].setAttribute('data-title', div[i].title)
                  div[i].removeAttribute('title')
               }
            
            })
         })
         .catch(err => {
            console.log('Error occured while fetching the Reservations for Dashboard Calender display->',err);
         })
         // findDOMNode()
         // setTimeout(() => {
         //    var div = document.getElementsByClassName("rbc-event-content");
         //    for(let i = 0;  i < div.length; i++ ){
         //       console.log(div[i].title)
         //          div[i].setAttribute('data-title',div[i].title)
         //         div[i].removeAttribute('title')
         //       }

         // },500)
         // div[0].addEventListener("mouseover", function( event ) {   
         //    console.log(event)
         //  }, true);
         window.addEventListener('load', this.handleLoad);
      }
   }//End of Method

   //Extra code.. not being used..
   fetches = () => {
      const headers = getHeadersForHttpReq();
      const id = localStorage.getItem('client_id');
      if (this.state.tableNo === 2) {
         axios.get(`${complete_URL}/expenses/by_clients/table?id=${id}&page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`,
         {headers})
            .then(res => {
               // console.log(res.data);
               let pagination = { ...this.state.pagination };
               pagination.total = res.data.totalElements;
               pagination.pageSize = parseInt(res.data.size);
               var columns = [
                  {
                     title: (
                        <span>{this.lang.TABLEHEADERS.TYPE}
                           {/* <Search
                            placeholder="Search type"
                                    onSearch={value => console.log(value)}
                                    onChange={this.handleChangeSearchValues("expense_type")}
            
                                /> */}
                        </span>
                     ),
                     dataIndex: 'expense_type',
                     key: 'expense_type',
                     width: '25%',
                     sorter: (a, b) => this.compareByAlph(a.expense_type, b.expense_type),
                     render: (text, record) => <button className="link-button" >{text}</button>,
                  },
                  {
                     title: (
                        <span>{this.lang.TABLEHEADERS.AMOUNT}
                           {/* <Search
                                    placeholder="Search amount"
                                    onSearch={value => console.log(value)}
                                    onChange={this.handleChangeSearchValues("amount")}
            
                                /> */}
                        </span>
                     ),
                     dataIndex: 'amount',
                     key: 'amount',
                     width: '25%',
                     sorter: (a, b) => this.compareByAlph(a.amount, b.amount),
                     render: (text, record) => <button className="link-button"  >{text}</button>,

                  },
                  {
                     title: (
                        <span>{this.lang.TABLEHEADERS.NOTE}
                           {/* <Search
                                    placeholder="Search note"
                                    onSearch={value => console.log(value)}
                                    onChange={this.handleChangeSearchValues("note")}
            
                                /> */}
                        </span>
                     ),
                     dataIndex: 'note',
                     key: 'note',
                     width: '25%',
                     sorter: (a, b) => this.compareByAlph(a.note, b.note),
                     render: (text, record) => <button className="link-button"  >{text}</button>,

                  },
               ]
               this.setState({
                  dddd: res.data,
                  pagination: pagination,
                  columns
               }, () => {

               })

               // console.log(this.state.data);
               // console.log(this.state.pagination);
            })
            .catch(err => {

               // console.log(err)
            })
      }
      if (this.state.tableNo === 3) {
         let id = localStorage.getItem('client_id')
         // console.log(`${complete_URL}/reservations/table?id=${id}&page=${this.state.page}&size=${this.state.size}&searchValues2=${JSON.stringify(this.state.searchValues)}`)
         var columns = [
            {
               title: (
                  <span>{this.lang2.TABLEHEADERS.NO}
                     {/* <Search
                      placeholder={`Search ${this.lang2.TABLEHEADERS.NO}`}
                      onSearch={value => console.log(value)}
                      onChange={this.handleChangeSearchValues("no")}
                     
                    /> */}
                  </span>
               ),
               dataIndex: 'no',
               key: 'no',
               width: '20%',
               sorter: (a, b) => this.compareByAlph(a.no, b.no),
               render: (text, record) => <button className="link-button"  >{text}</button>,
            },
            {
               title: (
                  <span>{this.lang2.TABLEHEADERS.CUSTOMER}
                     {/* <Search
                      placeholder={`Search ${this.lang2.TABLEHEADERS.CUSTOMER}`}
                      onSearch={value => console.log(value)}
                      onChange={this.handleChangeSearchValues("customer")}
                     
                    /> */}
                  </span>
               ),
               dataIndex: 'customer_name',
               render: (text, record) => <button className="link-button"  >{text}</button>,
            },
            {
               title: (
                  <span>{this.lang2.TABLEHEADERS.BANQUET}
                     {/* <Search
                      placeholder={`Search ${this.lang2.TABLEHEADERS.BANQUET}`}
                      onSearch={value => console.log(value)}
                      onChange={this.handleChangeSearchValues("banquet")}
                     
                    /> */}
                  </span>
               ),
               dataIndex: 'banquet_name',
               render: (text, record) => <button className="link-button"  >{text}</button>,
            },
            {
               title: (
                  <span>{this.lang2.TABLEHEADERS.HALL}
                     {/* <Search
                      placeholder={`Search ${this.lang2.TABLEHEADERS.HALL}`}
                      onSearch={value => console.log(value)}
                      onChange={this.handleChangeSearchValues("hall")}
                     
                    /> */}
                  </span>
               ),
               dataIndex: 'hall_name',
               render: (text, record) => <button className="link-button"  >{text}</button>,
            },
            {
               title: (
                  <span>{this.lang2.TABLEHEADERS.RENT}
                     {/* <Search
                      placeholder={`Search ${this.lang2.TABLEHEADERS.RENT}`}
                      onSearch={value => console.log(value)}
                      onChange={this.handleChangeSearchValues("hall")}
                     
                    /> */}
                  </span>
               ),
               dataIndex: 'total_rent',
               render: (text, record) => <button className="link-button"  >{text}</button>,
            },
         ];
         axios.get(`${complete_URL}/reservations/table?id=${id}&page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues2)}`,{headers})
            .then(res => {
               // console.log(res.data);
               let pagination = { ...this.state.pagination };
               pagination.total = res.data.totalElements;
               pagination.pageSize = parseInt(res.data.size);
               this.setState({
                  dddd: res.data,
                  pagination: pagination,
                  columns
               }/*, () => console.log(this.state.dddd)*/)

               // console.log(this.state.data);
               // console.log(this.state.pagination);
            })
            .catch(err => {
               // console.log(err)
            })
      }
      if (this.state.tableNo === 1 || this.state.tableNo === 4) {
         const id = localStorage.getItem('client_id');
         axios.get(`${complete_URL}/hallservices/table?id=${id}&page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues2)}`,{headers})

            .then(res => {

               var columns = [
                  {
                     title: `${this.lang3.SETUP.LABELS.SERVICECONTRACTOR}`,
                     dataIndex: 'service_contractor',
                     width: '20%',
                     render: (text, record) => <button className="link-button"   >{text}</button>,
                  },
                  {
                     title: `${this.lang3.SETUP.LABELS.SERVICETYPE}`,
                     dataIndex: 'service_type',
                     width: '25%',
                     render: (text, record) => <button className="link-button"   >{text}</button>,
                  },
                  {
                     title: `${this.lang3.SETUP.LABELS.AMOUNT}`,
                     dataIndex: 'amount',
                     width: '20%',
                     render: (text, record) => <button className="link-button"   >{text}</button>,
                  },
                  {
                     title: `${this.lang3.SETUP.LABELS.COMMISSIONAMOUNT}`,
                     dataIndex: 'commission_amount',
                     width: '20%',
                     render: (text, record) => <button className="link-button"   >{text}</button>,
                  },
               ]
               //   setTimeout(() => {
               // console.log(res.data);
               let pagination = { ...this.state.pagination };
               pagination.total = res.data.totalElements;
               pagination.pageSize = parseInt(res.data.size);

               let dddd = res.data
               // console.log(dddd)
               this.setState({ dddd, columns, pagination }/*, () => {
                  console.log(''this.state.dddd)
                   })
               }*/)
            })
            .catch(err => {
               console.log(err)
            })


      } else {
         this.setState({
            dddd: [],
            columns: []
            // pagination: ,
         })
      }

   }

   clck = (e) => {
   // console.log()
   let st = moment(e.start).diff(moment(new Date()), 'days') 
   let en = moment(e.end).diff(moment(new Date()), 'days') 
   if(st >= 0 && en >= 0 ){
         localStorage.setItem('stTime', e.start)
         localStorage.setItem('enTime', e.end)
         this.props.history.push('/dashboard/addreservation')
   }else{
      message.error("Select Only current Date Or future Date for New Reservations")
   }
   }
   clckEv = (e) => {
      localStorage.setItem("reservationUpdate", e.id);
      this.props.history.push('/dashboard/reservationupdate')
   }
   compareByAlph = (a, b) => {
      if (a > b) { return -1; }
      if (a < b) { return 1; }
      return 0;
   }
   clickedChartBar = (e, w, z, i) => {
      // console.log(w.datum.quarter)
      this.setState({ showTable: true, tableNo: w.datum.quarter, pagination: {} }, () => {
         this.fetches()
      })

   }
   rangeChangeHandler = () => {
      setTimeout(() => {
         this.setState({as:'a'},() => {
            var div = document.getElementsByClassName("rbc-event-content");
            for (let i = 0; i < div.length; i++) { // console.log(div[i].title)
               div[i].setAttribute('data-title', div[i].title)
               div[i].removeAttribute('title')
            }   
         })

      },2000)
   }   
   eventStyleGetter = (event, start, end, isSelected) => {
      // console.log('In Dashboard screen..eventStyleGetter method.. event->',event);
      let backgroundColor = '';
      /*(event.status === 'pending' || event.status === 'قيد الانتظار') ?
      backgroundColor = '#FFF701' :
      (event.status === 'reserved' || event.status === 'محجوز') ?
         backgroundColor = '#4CBB17'
         :  backgroundColor = '#FF0000'*/
      if(event.status === 'pending' || event.status === 'قيد الانتظار')  {
         if (event.isSecDepPaid == 1 || parseFloat( event.paid_amount) > 0) { backgroundColor = '#4CBB17' }
         else {  backgroundColor = '#FFF701' }
      }
      else if (event.status === 'reserved' || event.status === 'محجوز') { backgroundColor = '#FF0000' }

      var style = {
         backgroundColor: backgroundColor,
         borderRadius: '0px',
         opacity: 0.8,
         color: 'black',
         border: '0px',
         display: 'block'
      };
      return {
         style: style
      };
   }
   displayClientInfoPopOver = (data, version)=>{
      
      let content = ''
      if(version === 'EN') {
         content = (
            <div>
              <p>{'Customer Name :'+data.customer_name}</p>
              <p>{'Status :'+ data.status}</p>
              <p>{'Mobile no# :'+ data.customer_phone}</p>
            </div>
          );
      }
      else {
         content = (
            <div>
              <p>{data.customer_name_ar +  ': اسم الزبون '}</p>
              <p>{data.status + ': الحالة '}</p>
              <p>{data.customer_phone + ': التليفون المحمول '}</p>
            </div>
          );
      }
       return(
         <Popover content={content} title="Info">
          
         </Popover>
       )
   }
   render() {
      const tooltips = ({ e }) => {
         return <h1>aaaa</h1>
      }
      const displayClientInfoPopOver = (data, version)=>{
         let content = ''
         if(version === 'EN') {
            content = (
               <div>
                 <p>{'Customer Name :'+data.customer_name}</p>
                 <p>{'Status :'+ data.status}</p>
                 <p>{'Mobile no# :'+ data.customer_phone}</p>
               </div>
             );
         }
         else {
            content = (
               <div>
                 <p>{data.customer_name_ar +  ': اسم الزبون '}</p>
                 <p>{data.status + ': الحالة '}</p>
                 <p>{data.customer_phone + ': التليفون المحمول '}</p>
               </div>
             );
         }
          return(
            <Popover content={content} title="Info">
             
            </Popover>
          )
      }
      if (localStorage.getItem('lang') === 'EN') {
         return (
            <Row gutter={24}>
               <Col span={24}>
                  <div>
                     <BigCalendar
                        style={{ minHeigh: '100%' }}
                        events={this.state.myEventsList}
                        views={['month',]}
                        selectable='ignoreEvents'
                        defaultDate={new Date()}
                        localizer={localizer}
                        startAccessor="start"
                        showMultiDayTimes
                        endAccessor="end"
                        eventPropGetter={(this.eventStyleGetter)}
                        onSelectEvent={this.clckEv}
                        onSelecting={this.clck}
                        onSelectSlot={this.clck}
                        tooltipAccessor={(e) => /*{this.displayClientInfoPopOver(e, 'eng')}*/
                           'Customer Name: ' + e.customer_name + ', Status: ' + e.status + ', Mobile no# :'+ e.customer_phone
                        }
                        onRangeChange={this.rangeChangeHandler}

                        displayClientInfoPopOver = {e=>{displayClientInfoPopOver(e, 'EN')}}
                        components={{
                           tooltipAccessor: tooltips,
                           displayClientInfoPopOver: displayClientInfoPopOver
                        }}
                     />
                  </div>
               </Col>
               {/* <Col span={12}>
                  <VictoryChart
                     domainPadding={10}
                  >
                     <VictoryAxis
                        tickValues={[1, 2, 3, 4]}
                        tickFormat={[this.lang4.CONTRACTORAMOUNT, this.lang4.EXPENSES, this.lang4.TOTALRENT, this.lang4.TOTALCOMMISSION]}
                     />
                     <VictoryAxis
                        dependentAxis
                        tickFormat={(x) => (`${parseInt(x)}`)}
                     />
                     <VictoryBar
                        data={this.state.data}
                        labels={(d) => `${parseInt(d.open)}`}
                        x="quarter"
                        y="earnings"
                        events={[
                           {
                              eventHandlers: {
                                 onClick: (e, w, z, i) => {
                                    this.clickedChartBar(e, w, z, i)
                                 }
                              }
                           }
                        ]}
                     />
                  </VictoryChart>
                  {
                     this.state.showTable &&
                     <Table
                        className='chart-table'
                        size="small"
                        columns={this.state.columns}
                        rowKey="id"
                        dataSource={this.state.dddd.selectedElements}
                        onChange={this.handleChangePagination}
                        pagination={this.state.pagination}
                     />
                     // :
                     //                     <List
                     // itemLayout="horizontal"
                     // dataSource={this.state.list}
                     // renderItem={item => (
                     //   <List.Item className="chart-list">
                     //       <Icon type="arrow-right" />
                     //     <List.Item.Meta
                     //       avatar={ <p></p>}
                     //       title={<p >{item.title}</p>}
                     //     //   description={item.title}
                     //     />
                     //     //   </List.Item>
                     //     // )}
                     //   />
                  }
               </Col> */}
            </Row>
         )
      } else {
         return (
            <LocaleProvider locale={egEG}>
               <Row gutter={24}>
                  {/* <Col span={12}>
                     <VictoryChart
                        domainPadding={30}
                     >
                        <VictoryAxis
                           tickValues={[1, 2, 3, 4]}
                           tickFormat={[this.lang4.CONTRACTORAMOUNT, this.lang4.EXPENSES, this.lang4.TOTALRENT, this.lang4.TOTALCOMMISSION]}
                        />
                        <VictoryAxis
                           dependentAxis
                           tickFormat={(x) => (`${parseInt(x)}`)}
                        />
                        <VictoryBar
                           data={this.state.data}
                           labels={(d) => `${parseInt(d.open)}`}
                           x="quarter"
                           y="earnings"
                           events={[
                              {
                                 eventHandlers: {
                                    onClick: (e, w, z, i) => {
                                       this.clickedChartBar(e, w, z, i)
                                    }
                                 }
                              }
                           ]}
                        />
                     </VictoryChart>
                     {
                        this.state.showTable &&
                        <Table
                           className='chart-table'
                           size="small"
                           columns={this.state.columns}
                           rowKey="id"
                           dataSource={this.state.dddd.selectedElements}
                           onChange={this.handleChangePagination}
                           pagination={this.state.pagination}
                        />
                        // :
                        //                     <List
                        // itemLayout="horizontal"
                        // dataSource={this.state.list}
                        // renderItem={item => (
                        //   <List.Item className="chart-list">
                        //       <Icon type="arrow-right" />
                        //     <List.Item.Meta
                        //       avatar={ <p></p>}
                        //       title={<p >{item.title}</p>}
                        //     //   description={item.title}
                        //     />
                        //     //   </List.Item>
                        //     // )}
                        //   />
                     }
                  </Col> */}
                  <Col span={24}>
                     <div>

                        <BigCalendar
                           style={{ minHeigh: '100%' }}
                           events={this.state.myEventsList}
                           views={['month',]}
                           selectable='ignoreEvents'
                           defaultDate={new Date()}
                           localizer={localizer}
                           startAccessor="start"
                           showMultiDayTimes
                           endAccessor="end"
                           eventPropGetter={(this.eventStyleGetter)}
                           onSelectEvent={this.clckEv}
                           onSelecting={this.clck}
                           onSelectSlot={this.clck}
                           tooltipAccessor={e => /*{this.displayClientInfoPopOver(e, 'arb')}*/
                              ' اسم الزبون:' + e.customer_name + '، الحالة:' + e.status + '،التليفون المحمول :'+e.customer_phone
                           }
                           displayClientInfoPopOver = {e=>{displayClientInfoPopOver(e, 'arb')}}
                           components={{
                              tooltipAccessor: tooltips,
                              displayClientInfoPopOver: displayClientInfoPopOver
                           }}
                        />
                     </div>
                  </Col>
               </Row>
            </LocaleProvider>
         )
      }
   }
}

export default DashBoard