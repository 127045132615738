
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import Statistics from '../../components/statistics/Statistics';
import StatisticsV2 from '../../components/statistics/StatisticsV2';

import RouteNotFound from '../../components/RoutNotFound/index';
import IncomeAdd from '../../components/IncomeAdd';
import Income from '../../components/Income';
import IncomeUpdate from '../../components/IncomeUpdate';

import ClientsAccounts from '../../components/ClientAccount/Clients.jsx';
import ClientsAccountsDetails from '../../components/ClientAccount/ClientAccountDetails.jsx';

import SideMenu from '../../components/SideMenu';
import Navbar from '../../components/Navbar';

import Clients from '../../components/Clients';
import AddClient from '../../components/AddClient';
import ClientUpdate from '../../components/ClientUpdate';

import Users from '../../components/Users';
import AddUser from '../../components/AddUser';
import UserUpdate from '../../components/UserUpdate';

import Screens from '../../components/Screens';
import AddScreen from '../../components/AddScreen';
import ScreensUpdate from '../../components/ScreensUpdate';

import Roles from '../../components/Roles';
import AddRoles from '../../components/AddRole';
import RoleUpdate from '../../components/RoleUpdate';

import ModuleAdd from '../../components/ModuleAdd';
import Modules from '../../components/Modules';
import ModuleUpdate from '../../components/ModuleUpdate';

import ParameterUpdate from '../../components/ParameterUpdate';
import Parameters from '../../components/Parameters';
import ParameterAdd from '../../components/ParameterAdd';

import BanquetAdd from '../../components/BanquetAdd';
import Banquets from '../../components/Banquets';
import BanquetUpdate from '../../components/BanquetUpdate';

import HallAdd from '../../components/HallAdd';
import HallUpdate from '../../components/HallUpdate';
import Halls from '../../components/Halls';

import CustomerAdd from '../../components/CustomerAdd';
import Customers from '../../components/Customers';
import CustomerUpdate from '../../components/CustomerUpdate';

import ReservationAdd from '../../components/ReservationAdd';
import Reservations from '../../components/Reservations';
import ReservationUpdate from '../../components/ReservationUpadte';

import Expenses from '../../components/Expenses';
import ExpenseAdd from '../../components/ExpenseAdd';
import ExpenseUpdate from '../../components/ExpenseUpdate';

import Contractors from '../../components/Contractors/Contractors';
import ContractorAdd from '../../components/ContractorAdd/ContractorAdd';
import ContractorUpdate from '../../components/ContractorUpdate/ContractorUpdate';

import Notifications from '../../components/Notification/Notifications.jsx';
import NotificationAdd from '../../components/NotificationAdd/NotificationAdd.jsx';
import NotificationUpdate from '../../components/NotificationUpdate/NotificationUpdate';

import OfferAdd from '../../components/Offer/OfferAdd';
import OfferUpdate from '../../components/Offer/OfferUpdate';
import Offers from '../../components/Offer/Offers';
import UserSettings from '../../components/UserSettings/UserSettings';
import DashBoard from '../../components/Dashboard';
import ReportScreen from '../../components/ReportScreen';
//New Banquet screens created along with new Contractor feature
//Combined into one Screen
import AllContractorScreensOfClient from '../../components/AllContractorScreensOfClient';
import AssignedContractorPkgs from '../../components/AllContractorScreensOfClient/ContractorPackages';
import ServiceTypeClientAssign from '../../components/ServiceTypeClientAssign'; 
//New Contractor featured screens
//Combined into one Screen
import AllClientScreensOfContractor from '../../components/AllClientScreensOfContractor' 

import ContractorProfile from '../../components/ContractorPkgsProfile/ContractorProfile';
import ContractorPackages from '../../components/ContractorPkgsProfile/ContractorPackages';
import ContPackageAdd from '../../components/ContractorPkgsProfile/ContPackageAdd';
import ContPackageUpdate from '../../components/ContractorPkgsProfile/ContPackageUpdate';

//31Aug2020
import ContractorProfileForBqt from '../../components/Profiles/ContractorProfile';
import BanquetProfileForContractor from '../../components/Profiles/BanquetProfile';

const { Content } = Layout;
class Dashboard extends Component {
  state = {
    collapsed: false,
    data: []
  };

  toggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  }

  clikckedIcon = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }
  
  isLogin = ()=>{
    if (!localStorage.getItem('client_id') && !localStorage.getItem('updateContractor') ) {
      this.props.history.push('/');
    }
  }

  contNotLogin = () => {
    return localStorage.getItem('is_contr') != 1;
  }

  componentDidMount() {
    this.isLogin();
  }

  
  render() {

    return (
      <Router>
        <Layout>
          <SideMenu collapsed={this.state.collapsed} />
          <Layout>
            <Navbar clikckedIcon={this.clikckedIcon} collapsed={this.state.collapsed} language={this.props.language} />
            <Content className="contentCSS" style={{ minHeight: '90vh' }} >
              {
              this.contNotLogin() ?
              <Switch>
                <Route exact={true} path="/dashboard" render={(props) => <DashBoard {...props} language={this.props.language} />} />
                
                <Route path="/dashboard/clients" render={(props) => <Clients {...props} language={this.props.language} />} />
                <Route path="/dashboard/users" render={(props) => <Users {...props} language={this.props.language} />} />
                <Route path="/dashboard/userssettings" render={(props) => <UserSettings {...props} language={this.props.language} />} />
                <Route path="/dashboard/addclient" render={(props) => <AddClient {...props} language={this.props.language} />} />
                <Route path="/dashboard/adduser" render={(props) => <AddUser {...props} language={this.props.language} />} />
                <Route path="/dashboard/screens" render={(props) => <Screens {...props} language={this.props.language} />} />
                <Route path="/dashboard/addscreen" render={(props) => <AddScreen {...props} language={this.props.language} />} />
                <Route path="/dashboard/roles" render={(props) => <Roles {...props} language={this.props.language} />} />
                <Route path="/dashboard/addrole" render={(props) => <AddRoles {...props} language={this.props.language} />} />
                <Route path="/dashboard/roleupdate" render={(props) => <RoleUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/userupdate" render={(props) => <UserUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/clientupdate" render={(props) => <ClientUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/addmodule" render={(props) => <ModuleAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/modules" render={(props) => <Modules {...props} language={this.props.language} />} />
                <Route path="/dashboard/updatemodule" render={(props) => <ModuleUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/updatescreen" render={(props) => <ScreensUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/parameters" render={(props) => <Parameters {...props} language={this.props.language} />} />
                <Route path="/dashboard/updateparameter" render={(props) => <ParameterUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/addparameter" render={(props) => <ParameterAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/addbanquet" render={(props) => <BanquetAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/banquets" render={(props) => <Banquets {...props} language={this.props.language} />} />
                <Route path="/dashboard/updatebanquet" render={(props) => <BanquetUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/addhall" render={(props) => <HallAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/updatehall" render={(props) => <HallUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/halls" render={(props) => <Halls {...props} language={this.props.language} />} />
                <Route path="/dashboard/addcustomer" render={(props) => <CustomerAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/customers" render={(props) => <Customers {...props} language={this.props.language} />} />
                <Route path="/dashboard/updatecustomer" render={(props) => <CustomerUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/addreservation" render={(props) => <ReservationAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/reservations" render={(props) => <Reservations {...props} language={this.props.language} />} />
                <Route path="/dashboard/reservationupdate" render={(props) => <ReservationUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/expenses" render={(props) => <Expenses {...props} language={this.props.language} />} />
                <Route path="/dashboard/expenseAdd" render={(props) => <ExpenseAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/expenseUpdate" render={(props) => <ExpenseUpdate {...props} language={this.props.language} />} />
                
                <Route path="/dashboard/service_types" render={(props) => <ServiceTypeClientAssign {...props} language={this.props.language} />} />
                <Route path="/dashboard/assignedcontractors" render={(props) => <AllContractorScreensOfClient {...props} language={this.props.language} />} />
                <Route path="/dashboard/assignedcontractorpkgs" render={(props) => <AssignedContractorPkgs {...props} language={this.props.language} />} />
                <Route path="/dashboard/contractors" render={(props) => <Contractors {...props} language={this.props.language} />} />
                <Route path="/dashboard/contractorAdd" render={(props) => <ContractorAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/contractorUpdate" render={(props) => <ContractorUpdate {...props} language={this.props.language} />} />
                
                <Route path="/dashboard/statistics" render={(props) => <Statistics {...props} language={this.props.language} />} />
                <Route path="/dashboard/statisticsV2" render={(props) => <StatisticsV2 {...props} language={this.props.language} />} />

                <Route path="/dashboard/notifications" render={(props) => <Notifications {...props} language={this.props.language} />} />
                <Route path="/dashboard/notificationAdd" render={(props) => <NotificationAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/notificationUpdate" render={(props) => <NotificationUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/incomeupdate" render={(props) => <IncomeUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/incomeadd" render={(props) => <IncomeAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/income" render={(props) => <Income {...props} language={this.props.language} />} />
                <Route path="/dashboard/offerupdate" render={(props) => <OfferUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/offeradd" render={(props) => <OfferAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/offers" render={(props) => <Offers {...props} language={this.props.language} />} />
                <Route path="/dashboard/clientaccount" render={(props) => <ClientsAccounts {...props} language={this.props.language} />} />
                <Route path="/dashboard/clientaccountdetails" render={(props) => <ClientsAccountsDetails {...props} language={this.props.language} />} />
                <Route path="/dashboard/reports" render={(props) => <ReportScreen {...props} language={this.props.language} />} />
                <Route path="/dashboard/cont-profile" render={(props) => <ContractorProfileForBqt  {...props} language={this.props.language} />} />
                
                <Route exact={true} render={(props) => <RouteNotFound {...props} language={this.props.language} />} />
              </Switch> 
              :
              <Switch>
                <Route path="/dashboard/userssettings" render={(props) => <UserSettings {...props} language={this.props.language} />} />
                <Route path="/dashboard/assignedclients" render={(props) => <AllClientScreensOfContractor {...props} language={this.props.language} />} />
                
                <Route path="/dashboard/contProfile" render={(props)=> <ContractorProfile {...props} language={this.props.language} />} />
                <Route path="/dashboard/contpackages" render={(props)=> <ContractorPackages {...props} language={this.props.language} />} />
                <Route path="/dashboard/contpackageAdd" render={(props)=> <ContPackageAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/contpackageUpdate" render={(props)=> <ContPackageUpdate {...props} language={this.props.language} />} />

                <Route path="/dashboard/income_contr" render={(props) => <Income {...props} language={this.props.language} />} />
                <Route path="/dashboard/incomeadd" render={(props) => <IncomeAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/incomeupdate" render={(props) => <IncomeUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/expenses_contr" render={(props) => <Expenses {...props} language={this.props.language} />} />
                <Route path="/dashboard/expenseAdd" render={(props) => <ExpenseAdd {...props} language={this.props.language} />} />
                <Route path="/dashboard/expenseUpdate" render={(props) => <ExpenseUpdate {...props} language={this.props.language} />} />
                <Route path="/dashboard/statistics_contr" render={(props) => <Statistics {...props} language={this.props.language} />} />
                <Route path="/dashboard/statisticsV2" render={(props) => <StatisticsV2 {...props} language={this.props.language} />} />

                <Route path="/dashboard/bqt-profile" render={(props) => <BanquetProfileForContractor {...props} language={this.props.language} />} />   


                <Route exact={true} render={(props) => <RouteNotFound {...props} language={this.props.language} />} />
              </Switch>
              }
            </Content>
          </Layout>
        </Layout>
      </Router>
    )
  }
}

export default Dashboard;