import React, { Component } from 'react';
import {  Row,  Col,  Form,  Select,  Card, } from 'antd';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import LanguageChangingDropdown from '../../LanguageChangingDropdown/LanguageChangingDropdown';

const { Option } = Select
let pt = localStorage.getItem('pt')
const complete_URL = API_URL+pt;

class SelectClient extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clientsList: []
    }
    this.lang = props.language._CLIENTSELECTION
  }

  selctedClient = (e) => {
    this.state.clientsList.find((m) => {
      if (m.id === e) {
        axios.get(`${complete_URL}/images/${m.logo_img}`, { responseType: 'blob' })
          .then(res => {
            let reader = new FileReader();
            reader.readAsDataURL(res.data);
            reader.onloadend = () => { //console.log(reader.result)
              localStorage.setItem('client_logo_image', reader.result)
            }
          })
        .catch(err => { console.log(err) /* localStorage.setItem('client_logo_image','')*/     } )
        localStorage.setItem('client_name', m.name)

        return (true)
      }
      return (false)
    })
    localStorage.setItem('client_id', e)
    this.props.history.push('/dashboard')
  }

  componentDidMount() {
    this.fetchClientsListOnEntrence();
  }

  fetchClientsListOnEntrence =()=>{
    if (!localStorage.getItem('user_id') ) {
      this.props.history.push('/');
    }
    else if (localStorage.getItem('user_id') && localStorage.getItem('user_id') !== '00000000-0000-00') { 
        if (localStorage.getItem('is_contr') == '1') {
          this.props.history.push('/dashboard/contpackages');
        }
        else
          this.props.history.push('/dashboard');
    }
    else {
      localStorage.removeItem('client_logo_image');
      const headers = getHeadersForHttpReq();
      axios.get(`${complete_URL}/clients/`,{headers})
        .then(res => { /*console.log(res)*/
          this.setState({ clientsList: res.data });
        })
        .catch(clt_err => { console.log('Error in fetching Clients list->',clt_err) });
    }
  }//End of Method

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <div>
        <h1 style={{ display: 'inline-block' }}>{this.lang.LABELS.TITLE}</h1>
        <LanguageChangingDropdown color='##42AAFF' {...this.props} />
      </div>
    }
    else {
      this.CustomRow = <div>
        <LanguageChangingDropdown color='##42AAFF' {...this.props} />
        <h1 style={{ display: 'inline-block' }}>{this.lang.LABELS.TITLE}</h1>
      </div>
    }
  }

  render() {

    return (
      <Row gutter={12} className="login-back">
        {/* <Col span={8}></Col> */}
        <Col span={24} style={{margin : 'auto', width:'40%', minWidth: '300px', float: 'inherit'}}>
          <Card className="loginFormCSS2">
            {this.CustomRow}
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                <Select placeholder={this.lang.LABELS.TITLE} onChange={this.selctedClient}  >
                  {
                    this.state.clientsList.length > 0 && this.state.clientsList.map(client => (
                      <Option value={client.id} key={client.id}>{client.name}</Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default SelectClient;  