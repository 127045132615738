import React, { Component } from 'react';
import {Row, Col, Form, Select, Empty, Card, Avatar, Icon, Tag, Spin} from 'antd';
import ReactGoogleMap from '../ReactGoogleMap/index';
import axios from 'axios';
import API_URL from '../../constants'
import getHeadersForHttpReq from '../../constants/token';

if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class BanquetProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ct : '',
      bqt_obj : {},
      halls : [],
      contractors : [],
      service_types: [],
      showMap : true,
      img_url : '',
      spin : true,
      spin_msg :'System is Loading..Please wait..',
      dir : 'ltr', 
      display: 'block',
    }

    this.lang = props.language._BANQUET.UPDATE;
    this.lang_hall = props.language._HALL.SETUP;
  }// End of Constructor
  
  setComponentDirection=()=>{
    if(localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    const ct = localStorage.getItem('clntprof');//Client ID of Selected Banquet By Contractor
    if (ct) {
      this.fetchBanquetInfo(ct);
      this.getAssignedServiceTypes(ct);
      this.setComponentDirection();
      this.setState({ct, spin : false});
    }
    else{this.props.history.push('/dashboard/assignedclients');}
  }

  fetchBanquetInfo =(ct)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/banquets/by_clients/${ct}`,{headers})
    .then(bqt_res=>{ //console.log('bqt(s) found->',bqt_res.data.data[0]);
      if (bqt_res.data.success) {
        this.setState({bqt_obj: bqt_res.data.data[0][0]},()=>{
          if (bqt_res.data.data[0][0].hasOwnProperty('id')) {
            //NOTE: Here more than one Banquets can be there, so selecting the first one; 
            this.getBanquetImage(bqt_res.data.data[0][0].image_id);
            this.fetchBanquetHalls(bqt_res.data.data[0][0].id) //Call halls Http Service here 
            this.fetchAssignedContractors(ct,'all');
          }
        });
      }
    })
    .catch(bqt_err=>{
      console.log('Error occured while fetching Banquet Profile data by Client ID->',bqt_err);
    })
  }// End of Method

  fetchBanquetHalls=(bqt_id)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/halls/by_banquet/${bqt_id}`,{headers})
    .then(halls_res=>{ //console.log('Received Halls of banquet->',halls_res.data.data[0])
      if (halls_res.data.success) {
        this.setState({halls : halls_res.data.data[0]})
      }
    })
    .catch(halls_err=>{
      console.log('Error occured while fetching the Halls of Banquet->',halls_err)
    })
  }// End of Method

  fetchAssignedContractors=(ct,srvType)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/cont_clients/get_assigned_cont_by_client/${ct}/${srvType}`,{headers})
    .then(cont_res=>{ //console.log('Assigned Contractors->',cont_res.data.data);
      if(cont_res.data.success){
        this.setState({contractors: cont_res.data.data})
      }
    })
    .catch(cont_err=>{
      console.log('Error occured while fetching assigned Contractors data by Client ID->',cont_err);
    })
  }// End of Method

  getBanquetImage = (image_id)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/images/${image_id}`, { responseType: 'blob' },{headers})
      .then(res => {
        let thiss = this;
        let a;
        let reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          a = reader.result
          thiss.setState({ img_url: a })
        }
      })
      .catch(err => { console.log('Error occured while fetching Banquet Image in Banquet Profile Screen->',err) });
  }
  //New ajax API call for fetching Assigned independent contractor of selected Service Type
  getAssignedServiceTypes = (client_id)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/client_servicetypes/${client_id}`,{headers})
      .then(res => { // console.log('service_type->',res)
        this.setState({ service_types: res.data.data })
      })
      .catch(err => {  console.log('Issue while fetching new service types ->',err) })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let {ct, bqt_obj, halls, contractors, service_types, spin_msg, spin, img_url, dir, display, showMap} = this.state;
    let {NAME, EMAIL, PHONENO, DESCRIPTION, ADDRESS, COUNTRY, CITY, FACILITIES,
       INSTAGRAMURL, CONTACT, GOOGLEMAPADDRESS, ASSOCIATEDHALLS, WORKING } = this.lang.LABELS;
    let { SERVICETYPE } = this.props.language._CONTRACTOR.UPDATE.LABELS;   

    let EN_FLAG = localStorage.getItem('lang') === 'EN';
    let fontStyleT = {fontSize:'14px', color: 'rgba(0, 0, 0, 0.85)'};
    let w = window.innerWidth;
    //1200px, 1200px >= x > 990px, 990px >= x > 768px,  768px >= x > 640px, 640px >= x > 480, 480 >= x > 400, 400 >= x -> never gonna happen?
    if (w < 1280 && w >= 1024) { fontStyleT = {fontSize:'13px'}; }
    else if (w < 1024 && w >= 768) { fontStyleT = {fontSize:'9px'}; }

    let hallStyle = {...fontStyleT,fontWeight:'bold'};
    let NamePadding = EN_FLAG ? {paddingLeft : 0} : {paddingRight : 0};
    let infoBorder = EN_FLAG ? {borderRightStyle :'solid'} : {borderLeftStyle : 'solid'};
    let facltMargin = EN_FLAG ? {marginLeft:'-6%'} : {marginRight:'-6%'};
    let instaStyle = EN_FLAG ? {marginRight:'10px',fontSize:'18px'} : {marginLeft:'10px',fontSize:'18px'};

    let halls_associated =  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    if (halls.length > 0) {
      halls_associated = halls.map((hl,i)=>{
        let color = 'green';
        if (hl.hall_type_name === 'CONFERENCE') {color = 'volcano'}
        return <Row key={'hl-row-'+i} gutter={24} dir = {dir} style={{display}}>
          <Col span={EN_FLAG ? 3:3}><span >{(i+1)+'  :   '}</span></Col>
          <Col span={EN_FLAG ? 7:7} style={NamePadding}><span >{EN_FLAG ? hl.name : hl.name_ar}</span></Col>
          <Col span={EN_FLAG ? 7:6}><span ><Tag color={color} key={'tg'+i}>{EN_FLAG ? hl.hall_type_name : hl.hall_type_name_ar}</Tag></span></Col>
          <Col span={EN_FLAG ? 7:8} style={{textAlign:'center'}}><span >{hl.capacity}</span></Col>
        </Row>
      });
    }

    let contractors_assigned = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    if (contractors.length > 0) {
      contractors_assigned = contractors.map((cnt,i)=>{
        return <Row key={'cnt-row-'+i} gutter={24} dir = {dir} style={{display}}>
                <Col span={EN_FLAG ? 3:3}><span >{(i+1)+'  :   '}</span></Col>
                <Col span={EN_FLAG ? 8:7} style={NamePadding}><span >{EN_FLAG ? cnt.name : cnt.name_ar}</span></Col>
                <Col span={EN_FLAG ? 6:6}><span >{EN_FLAG ? cnt.new_service_type_name : cnt.new_service_type_name_ar}</span></Col>
                <Col span={EN_FLAG ? 7:8}><span >{cnt.map_loc_addr}</span></Col>
              </Row>
      });
    }

    return (
      <Spin tip={spin_msg} spinning={spin}>
        <Row gutter={24} dir = {dir} style={{margin : '20px 0px'}}>
          <Card key={'info_card'} size={'small'} bordered={false}>
            <Row gutter={24} dir= {dir} style={{margin : '20px 0px', display : display }}>
            <Col span={3} style={{paddingRight:'0', paddingLeft:'0'}}>
              <Avatar size={100} icon="user"  src={img_url || '/dummy_image.jpg'} />
            </Col>
            <Col span={21} style={{ marginTop:'3%'}}>
            <Row gutter={24} dir = {dir} style={{display}}>
            <Col span={16} style={infoBorder}>
              <Row gutter={24} dir = {dir} style={{display}}>{/*NAME,EMAIL */}
                <Col span={12}><p style={fontStyleT}>{NAME+' :  '} <span >{EN_FLAG ? bqt_obj.name : bqt_obj.name_ar}</span></p></Col>
                <Col span={12}><p style={fontStyleT}>{DESCRIPTION+' :  '} <span >{bqt_obj.description}</span></p></Col>{/*DESCRIPTION*/}
              </Row>
              <Row gutter={24} dir = {dir} style={{display}}>{/*ADDRESS HEADER*/}
                <Col span={24}><h3 style={{fontWeight:'bold'}}>{ADDRESS}</h3></Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}}>{/*ADDRESS*/}
                <Col span={24}><p style={fontStyleT}>{ADDRESS+' :  '}<span >{EN_FLAG ? bqt_obj.address : bqt_obj.address_ar}</span></p></Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}}>{/*CITY,COUNTRY*/}
                <Col span={12}><p style={fontStyleT}>{CITY+' :  '} <span >{EN_FLAG ? bqt_obj.city_name : bqt_obj.city_name_ar}</span></p></Col>
                <Col span={12}><p style={fontStyleT}>{COUNTRY+' :  '} <span >{EN_FLAG ? bqt_obj.country_name : bqt_obj.country_name_ar}</span></p></Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}}>
                <Col span={24}><h3 style={{fontWeight:'bold'}}>{CONTACT}</h3></Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}}>
                <Col span={12}><p style={fontStyleT}>{EMAIL+' :  '} <span >{bqt_obj.email}</span></p></Col>
                <Col span={12}>
                  {/* <a href={`https://www.instagram.com/${bqt_obj.insta_url}`} target={'_blank'}> */}
                  <p ><Icon type="instagram" title = {bqt_obj.insta_url} style={instaStyle} />
                      {bqt_obj.insta_url}
                  </p>
                  {/* </a> */}
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={24} dir = {dir} style={{display}}>{/*FACILITIES HEADER*/}
                <Col span={24}><h3 style={{fontWeight:'bold'}}>{FACILITIES}</h3></Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}}>{/*FACILITIES*/}
                <Col span={24}>
                  <ul style={facltMargin}>
                    <li key={'f0'}><span >{EN_FLAG ? 'Beach Distance (100 Meters)':' المسافة من الشاطئ (100 متر) '}</span></li>
                    <li key={'f1'}><span >{EN_FLAG ? 'Gym':'الجمنازيوم'}</span></li>
                    <li key={'f2'}><span >{EN_FLAG ? 'Wifi':'واي فاي'}</span></li>
                    <li key={'f3'}><span >{EN_FLAG ? 'Swimming Pool':'حمام السباحة'}</span></li>
                    <li key={'f4'}><span >{EN_FLAG ? 'Shopping Center':'مركز تسوق'}</span></li>
                  </ul>  
                </Col>
              </Row>
            </Col>
            </Row>
            </Col>
            </Row>
          </Card>
        </Row>
        <Row gutter={24} dir = {dir} style={{margin : '10px 0px', display : display }}>
            <Col span={12}>
              <Card key={'card2'} size={'small'} bordered={false}>
                <h3 style={{fontWeight:'bold'}}>{ASSOCIATEDHALLS}</h3>
                <Row key={'hl-row-head'} gutter={24} dir = {dir} style={{display}}>
                  <Col span={EN_FLAG ? 3:3}><span style={hallStyle}>{EN_FLAG ? 'Seq.': 'الرقم'}</span></Col>
                  <Col span={EN_FLAG ? 7:7} style={NamePadding}><span style={hallStyle}>{NAME}</span></Col>
                  <Col span={EN_FLAG ? 7:6}><span style={hallStyle}>{this.props.language._HALL.SETUP.TABLEHEADERS.HALLTYPE}</span></Col>
                  <Col span={EN_FLAG ? 7:8}style={{textAlign:'center'}}><span style={hallStyle}>{this.props.language._HALL.SETUP.TABLEHEADERS.CAPACITY}</span></Col>
                </Row>
                <br/>
                {halls_associated}
                <br/> <hr/> <br/>
                <Row gutter={24} dir = {dir} style={{display}}>
                  <Col span={12}><h3 style={{fontWeight:'bold'}}>{WORKING}</h3></Col>
                  <Col span={12}>
                    <Form.Item label={SERVICETYPE}>
                      {getFieldDecorator('service_type', {
                        rules: [{ required: false, message: ''}],
                      })(
                        <Select style={{width:'100%'}} onChange={(e)=>this.fetchAssignedContractors(ct,e)} placeholder={SERVICETYPE}>
                          {dir === 'ltr' ?
                            service_types.length > 0 && service_types.map(item => (
                              <Select.Option value={item.new_service_type} key={item.id}> { item.name } </Select.Option>
                            )) :
                            service_types.length > 0 && service_types.map(item => (
                              <Select.Option style={{textAlign:'right'}} value={item.new_service_type} key={item.id}> { item.name_ar } </Select.Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                
                <Row key={'cnt-row-head'} gutter={24} dir = {dir} style={{display}}>
                  <Col span={EN_FLAG ? 3:3}><span style={hallStyle}>{EN_FLAG ? 'Seq.': 'الرقم'}</span></Col>
                  <Col span={EN_FLAG ? 8:7} style={NamePadding}><span style={hallStyle}>{NAME}</span></Col>
                  <Col span={EN_FLAG ? 6:6}><span style={hallStyle}>{SERVICETYPE}</span></Col>
                  <Col span={EN_FLAG ? 7:8}><span style={hallStyle}>{GOOGLEMAPADDRESS}</span></Col>
                </Row>
                <br/>
                <div style={{overflow: 'scroll', height : '20vh'}}>{contractors_assigned}</div>
              </Card>
            </Col>
            <Col span={12}>
              <Card key={'card3'} size={'small'} bordered={false} >
                {showMap && 
                  <ReactGoogleMap  
                    {...this.props} 
                    lat = {bqt_obj.lat} 
                    lng = {bqt_obj.lng} 
                    getGeoLocations = {this.getGeoLocations}/*Unnecessary props */
                    is_dynamic = {false}
                  />}
                <p style={{marginTop:'20px',...fontStyleT}}>
                  {GOOGLEMAPADDRESS+' :  '}<span style={{fontWeight:'bold'}}>{bqt_obj.map_loc_addr}</span></p>
              </Card>
            </Col>
          </Row>
      </Spin>
    )
  }
}// End of Component

// export default BanquetProfile;
export default BanquetProfile =  Form.create({ name: 'bqt_profile' })(BanquetProfile);
