import React, { Component } from 'react';
import { Row, Col, Table, Button, message, Input } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import getHeadersForHttpReq from '../../constants/token';
import API_URL from '../../constants'
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Banquets extends Component {
	constructor(props) {
		super(props)
		this.state = {
			// data: {},
      		data: [],
			pagination: {},
			page: 1,
			size: 5,
			// sortField:"name",
			sortField:"updated_at",
			sortOrder:"ascend",
			searchValues: {
				name: '',
				name_ar: '',
				c_r_no: '' ,
				gsm : '' ,
				email : '' ,
				insta_url : '',
				description: '',
				country: '',
				address: '',
				address_ar: '',
				city_name:'',
				city_name_ar:'',
				country_name:'',
				country_name_ar:'',
				orderBy : '',
        column_name :'name'
			}
		}

		this.lang = props.language._BANQUET.SETUP;
		this.msg = props.language._BANQUET.MESSAGE;

		this.columns = [
			(localStorage.getItem('lang') === 'EN') ?
			{
				title: (
					<span>{this.lang.TABLEHEADERS.NAME}
						<Search
							placeholder= {this.lang.TABLEHEADERS.NAME}
							onChange={this.handleChangeSearchValues("name")}
						/>
					</span>
				),
				dataIndex: 'name',
				key: 'name',
				width: '14.29%',
				align : 'left',
				sorter: (a, b) => this.compareByAlph(a.name, b.name),
				render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
		}:
		{
			title: (
				<span>{this.lang.TABLEHEADERS.NAME}
					<Search
						placeholder= {this.lang.TABLEHEADERS.NAME}
						onChange={this.handleChangeSearchValues("name")}
					/>
				</span>
			),
			dataIndex: 'name',
			key: 'name',
			width: '14.29%',
			align : 'right',
			sorter: (a, b) => this.compareByAlph(a.name, b.name),
			render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
	}, 
	(localStorage.getItem('lang') === 'EN') ?
		{
			title: (
				<span>{this.lang.TABLEHEADERS.NAMEAR}
					<Search
						placeholder= {this.lang.TABLEHEADERS.NAMEAR}
						onChange={this.handleChangeSearchValues("name_ar")}
					/>
				</span>
			),
			dataIndex: 'name_ar',
			key: 'name_ar',
			width: '14.29%',
			align : 'left',
			sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
		}:
		{
			title: (
				<span>{this.lang.TABLEHEADERS.NAMEAR}
					<Search
						placeholder= {this.lang.TABLEHEADERS.NAMEAR}
						onChange={this.handleChangeSearchValues("name_ar")}
					/>
				</span>
			),
			dataIndex: 'name_ar',
			key: 'name_ar',
			width: '14.29%',
			align : 'right',
			sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
		},
		(localStorage.getItem('lang') === 'EN') ?
		{
			title: (
				<span>{this.lang.TABLEHEADERS.DESCRIPTION}
					<Search
						placeholder= {this.lang.TABLEHEADERS.DESCRIPTION}
						onChange={this.handleChangeSearchValues("description")}
					/>
				</span>
			),
			dataIndex: 'description',
			key: 'description',
			width: '14.29%',
			align : 'left',
			sorter: (a, b) => this.compareByAlph(a.description, b.description),
		} :
		{
			title: (
				<span>{this.lang.TABLEHEADERS.DESCRIPTION}
					<Search
						placeholder= {this.lang.TABLEHEADERS.DESCRIPTION}
						onChange={this.handleChangeSearchValues("description")}
					/>
				</span>
			),
			dataIndex: 'description',
			key: 'description',
			width: '14.29%',
			align : 'right',
			sorter: (a, b) => this.compareByAlph(a.description, b.description),
		} ,
		(localStorage.getItem('lang') === 'EN') ?
			{
				title: (
					<span>{this.lang.TABLEHEADERS.CITY}
						<Search
							placeholder= {this.lang.TABLEHEADERS.CITY}
							onChange={this.handleChangeSearchValues("city_name")}
						/>
					</span>
				),
				dataIndex: 'city_name',
				key: 'city_name',
				width: '14.29%',
				align : 'left',
				sorter: (a, b) => this.compareByAlph(a.city_name, b.city_name),
			}:
			{
				title: (
					<span>{this.lang.TABLEHEADERS.CITY}
						<Search
							placeholder= {this.lang.TABLEHEADERS.CITY}
							onChange={this.handleChangeSearchValues("city_name_ar")}
						/>
					</span>
				),
				dataIndex: 'city_name_ar',
				key: 'city_name_ar',
				width: '14.29%',
				align : 'right',
				sorter: (a, b) => this.compareByAlph(a.city_name_ar, b.city_name_ar),
			},
		(localStorage.getItem('lang') === 'EN') ?
			{
				title: (
					<span>{this.lang.TABLEHEADERS.COUNTRY}
						<Search
							placeholder= {this.lang.TABLEHEADERS.COUNTRY}
							onChange={this.handleChangeSearchValues("country_name")}
						/>
					</span>
				),
				dataIndex: 'country_name',
				key: 'country_name',
				width: '14.29%',
				align : 'left',
				sorter: (a, b) => this.compareByAlph(a.country_name, b.country_name),
			}:
			{
				title: (
					<span>{this.lang.TABLEHEADERS.COUNTRY}
						<Search
							placeholder= {this.lang.TABLEHEADERS.COUNTRY}
							onChange={this.handleChangeSearchValues("country_name_ar")}
						/>
					</span>
				),
				dataIndex: 'country_name_ar',
				key: 'country_name_ar',
				width: '14.29%',
				align : 'right',
				sorter: (a, b) => this.compareByAlph(a.country_name_ar, b.country_name_ar),
			},
		(localStorage.getItem('lang') === 'EN') ?
		{
			title: (
				<span>{this.lang.TABLEHEADERS.ADDRESS}
					<Search
						placeholder= {this.lang.TABLEHEADERS.ADDRESS}
						onChange={this.handleChangeSearchValues("address")}
					/>
				</span>
			),
			dataIndex: 'address',
			key: 'address',
			width: '14.29%',
			align : 'left',
			sorter: (a, b) => this.compareByAlph(a.address, b.address),
		}:
		{
			title: (
				<span>{this.lang.TABLEHEADERS.ADDRESS}
					<Search
						placeholder= {this.lang.TABLEHEADERS.ADDRESS}
						onChange={this.handleChangeSearchValues("address")}
					/>
				</span>
			),
			dataIndex: 'address',
			key: 'address',
			width: '14.29%',
			align : 'right',
			sorter: (a, b) => this.compareByAlph(a.address, b.address),
		},
		{
			title: `${this.lang.TABLEHEADERS.ACTION}`,
			render: (text, record) => (
				<span>
					<Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
				</span>
			),
			width: '8%',
			align : 'center',
			key: 'last_name',
		}
	];
	}

	compareByAlph = (a, b) => {
		if (a > b) { return -1; }
		if (a < b) { return 1; }
		return 0;
	}

	handleNavigation = (record) => {
		/*console.log(this.props)*/
		localStorage.setItem('updateBqt', JSON.stringify(record))
		this.props.history.push('/dashboard/updatebanquet')
	}

	handleChangePagination = (pagination, filters, sorter) => {
		const pager = { ...this.state.pagination };
		pager.current = pagination.current;
		this.setState({ pagination: pager, pageNumber: pagination.current });

		if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
			if (this.state.pageNumber  !== pager.current) {
				this.fetch({	page: pagination.current });
			}
		} 
		else {
			this.fetch({
				results: pagination.pageSize,
				page: pagination.current,
				sortField: sorter.field,
				sortOrder: sorter.order,
				...filters,
			});
		}
		/*let pager = { ...this.state.pagination };
		pager.current = pagination.current;

		let searchValues = { ...this.state.searchValues}
    searchValues.column_name = sorter.field
    searchValues.orderBy = sorter.order
		this.setState({
			page: pager.current,
			pagination: pager,
			searchValues
		}, () => {
			this.fetch();
		})*/

	}//End of Method

	handleChangeSearchValues = (prop) => (e) => {
		let search = { ...this.state.searchValues };
		search[`${prop}`] = e.target.value;
		this.setState({
			searchValues: search
		}, () => {
			this.fetch();
		});
	}

	deleteCategory = (record) => {
		axios.delete(`${complete_URL}/banquets/${record.id}`)
			.then(res => {
				message.success(this.msg.DELETEBANQUETSUCCESS);
				// console.log(res)
				this.fetch()
			})
			.catch(err => {
				console.log('Error occured while deleting the Banquet->',err)
				message.error(this.msg.DELETEBANQUETFAILURE,2);
			})
	}

	componentDidMount() {
		this.fetch()
	}

	fetch = (params={}) => {
		const client_id = localStorage.getItem('client_id');
		const headers = getHeadersForHttpReq();
      
		let page,sortOrder ;
		if (params.page) { page = params.page - 1 }
		else { page = 0;}

		if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
		else { sortOrder = 'DESC' }
		
		let data ={
			client_id : client_id,
			name: this.state.searchValues.name || '',
			name_ar: this.state.searchValues.name_ar || '',
			c_r_no: this.state.searchValues.c_r_no || '' ,
			gsm : this.state.searchValues.gsm || '' ,
			email : this.state.searchValues.email || '' ,
			insta_url : this.state.searchValues.insta_url || '',
			description: this.state.searchValues.description || '',
			country: this.state.searchValues.country || '',
			address: this.state.searchValues.address || '',
			address_ar: this.state.searchValues.address_ar || '',
			city_name: this.state.searchValues.city_name || '',
			city_name_ar: this.state.searchValues.city_name_ar || '',
			country_name: this.state.searchValues.country_name || '',
			country_name_ar: this.state.searchValues.country_name_ar || '',
				
			page,
			sortColumn: params.sortField || this.state.sortField ,
			sortOrder,
			size:this.state.size,
			orderBy: '',
			column_name: 'no'
      }
      // console.log('Sending data to api ->',data);
      axios.post(`${complete_URL}/banquets/pagination_table`,data, {headers})
      .then(response => {
        // console.log('response of exp new pagination ->',response)
        let pagination = {};
        Object.assign(pagination,{
          ...this.state.pagination,
          total : response.data.totalElements,
          current : page +1,
          pageSize : this.state.size
        });
        this.setState({ data: response.data.data, pagination })
        // console.log('Expense data-> ',response);
      })
      .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })
    
		/*axios.get(`${complete_URL}/banquets/by_clients/table?id=${client_id}&page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`)
			.then(res => {
				// console.log('banquests ->',res.data);
				let pagination = { ...this.state.pagination };
				pagination.total = res.data.totalElements;
				pagination.pageSize = parseInt(res.data.size);
				this.setState({
					data: res.data,
					pagination: pagination,
				})
			})
			.catch(err => { console.log(err) })*/
	}

	componentWillMount() {
		if (localStorage.getItem('lang') === 'EN') {
			this.CustomRow = <Row id="clientTableHeader">
				<Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
				<Col span={13}>
					<Link to="/dashboard/addbanquet">
						<Button type="primary">{this.lang.BUTTON.ADD}</Button>
					</Link>
				</Col>
			</Row>
		}
		else {
			this.CustomRow = <Row id="clientTableHeader">
				<Col span={13}>
					<Link to="/dashboard/addbanquet">
						<Button type="primary">{this.lang.BUTTON.ADD}</Button>
					</Link>
				</Col>
				<Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
			</Row>
		}
	}

	render() {
		return (
			<>
				{this.CustomRow}
				<Row id="clientsTable">
					<Col span={2}></Col>
					<Col span={20}>
						<Table
							columns={this.columns}
							rowKey="id"
							// dataSource={this.state.data.selectedElements}
							dataSource={this.state.data}
							onChange={this.handleChangePagination}
							pagination={this.state.pagination}
						/>,
					</Col>
				</Row>
			</>
		)
	}
}

export default Banquets;  