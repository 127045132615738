import React, { Component } from "react";
import SelectBox from "devextreme-react/select-box";
import DropDownBox from "devextreme-react/drop-down-box";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

import { Item } from "devextreme-react/form";
import DataGrid, {
  Selection,
  Paging,
  FilterRow,
  Scrolling,
  Column,
  Editing,
  Popup,
  DxForm,
  Lookup,
  RequiredRule,
  PatternRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import {
  DatePicker,
  Row,
  Col,
  Form,
  Button,
  Card,
  message,
  Select,
  TimePicker,
  Modal,
  Spin,
  Icon,
  Divider,
  InputNumber,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import API_URL from "../../constants";
import getHeadersForHttpReq from "../../constants/token";
// import printJS from "print-js";
import CreateCustomer from "./CreateShotCustomer";
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const confirm = Modal.confirm;
const pt = localStorage.getItem("pt");
const complete_URL = API_URL + pt;
const headers = getHeadersForHttpReq();

const gridDataSource = new CustomStore({
  key: "id",
  loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  load: () => {
    return axios
      .get(`${complete_URL}/customers/${localStorage.getItem("client_id")}`, {
        headers,
      })
      .then((response) => {
        console.log(response.data.data);
        return response.data.data;
      })
      .catch((err) => {
        console.log("Error occured while fetching Customers data ->", err);
      });
  },
  insert: (values) => {
    values.user_id = localStorage.getItem("user_id");
    values.client_id = localStorage.getItem("client_id");
    values.birthdate = "1985-01-01";
    values.name =
      typeof values.name === "undefined" ? values.name_ar : values.name;

    return axios.post(`${complete_URL}/customers/add`, values, { headers });
    // .then((response) => {
    //   this.setState({
    //     gridBoxValue: response.data.data.id,
    //     isGridBoxOpened: false,
    //   });
    // });
  },
});
if (localStorage.getItem("lang") === "AR") {
  require("../css/global-css.css");
}

class ReservationAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      banquets: [],
      cities: [],
      gender: [],
      halls: [],
      customers: [],
      startValue: null,
      endValue: null,
      endOpen: false,
      timeStartOpen: false,
      timeEtartOpen: false,
      timeEndOpen: false,
      event_types: [],
      currentDate: moment(new Date()),
      hallPrice: [],
      createCustomerOpen: false,
      uom: true,
      selectedCustomerID: "",
      dir: "ltr",
      display: "block",
      gridBoxValue: [3],
      isGridBoxOpened: false,
    };
    this.lang = props.language._RESERVATION.CREATE;
    this.customerLang = props.language._CUSTOMER.SETUP;
    this.rtlEnabled = localStorage.getItem("lang") === "AR" ? true : false;
    this.msg = props.language._RESERVATION.MESSAGE;
    this.cust_msg = props.language._CUSTOMER.MESSAGE;

    this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
    this.dataGridOnSelectionChanged =
      this.dataGridOnSelectionChanged.bind(this);
    this.onRowInserted = this.onRowInserted.bind(this);

    this.dataGridRender = this.dataGridRender.bind(this);
    this.onGridBoxOpened = this.onGridBoxOpened.bind(this);
  }

  setComponentDirection = () => {
    if (localStorage.getItem("lang") === "EN") {
      this.setState({ dir: "ltr", display: "block" });
    } else {
      this.setState({ dir: "rtl", display: "flex" });
    }
  };

  disabledStartDate = (startValue) => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  selectHall = (v) => {
    let headers = getHeadersForHttpReq();
    axios
      .get(`${complete_URL}/hallprices/reservation/${v}`, { headers })
      .then((result) => {
        //console.log('received hallPrice->',result.data.data[0])
        this.setState({ hallPrice: result.data.data[0] });
      })
      .catch((err) => {
        console.log("Error occured while fetching Unit of Measure ->".err);
      });
  };

  onChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleOk = (values) => {
    const headers = getHeadersForHttpReq();
    values.user_id = localStorage.getItem("user_id");
    values.client_id = localStorage.getItem("client_id");
    values.birthdate = "1985-01-01";
    values.ph_alt_num = "";
    axios
      .post(`${complete_URL}/customers/add`, values, { headers })
      .then((res) => {
        if (res.data.success) {
          message.success(this.cust_msg.ADDSUCCESS);
          // console.log(res.data.id)
          this.getCustomers();
          this.setState({
            createCustomerOpen: false,
            selectedCustomerID: res.data.data.id.substr(0, 16),
          });
        } else {
          message.error(this.cust_msg.ADDFAILURE, 2);
        }
      })
      .catch((err) => {
        message.error(this.cust_msg.ADDFAILURE, 2);
        console.log("Error occured while creating new Customer->", err);
      });
  };

  handleCancel = () => {
    this.setState({ createCustomerOpen: false });
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open, timeStartOpen: true });
  };

  componentDidMount() {
    this.getBanquets();
    this.getGenderes();
    this.getEventTypes();

    if (localStorage.getItem("stTime") && localStorage.getItem("enTime")) {
      this.setState({
        startValue: moment(localStorage.getItem("stTime")),
        endValue: moment(localStorage.getItem("enTime")),
      });
      setTimeout(() => {
        localStorage.removeItem("stTime");
        localStorage.removeItem("enTime");
      }, 3000);
    }

    this.setComponentDirection();
  }

  dataGridRender() {
    const gridColumns = [
      this.customerLang.TABLEHEADERS.NAMEAR,
      this.customerLang.TABLEHEADERS.IDCARDNO,
      this.customerLang.TABLEHEADERS.PHONENO,
    ];
    return (
      <DataGrid
        dataSource={gridDataSource}
        rtlEnabled={this.rtlEnabled}
        hoverStateEnabled={true}
        selectedRowKeys={this.state.gridBoxValue}
        onSelectionChanged={this.dataGridOnSelectionChanged}
        onRowInserted={this.onRowInserted}
        height="100%"
      >
        <Column
          dataField="name"
          caption={this.customerLang.TABLEHEADERS.NAME}
          dataType="string"
          visible={false}
          width="auto"
        />
        <Column
          dataField="name_ar"
          caption={this.customerLang.TABLEHEADERS.NAMEAR}
          dataType="string"
          width="auto"
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="ph_num"
          caption={this.customerLang.TABLEHEADERS.PHONENO}
          dataType="number"
          width="auto"
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="ph_alt_num"
          caption={this.customerLang.TABLEHEADERS.ALTERNATEPHONENO}
          dataType="number"
        />
        <Column
          dataField="id_card"
          caption={this.customerLang.TABLEHEADERS.IDCARDNO}
          dataType="number"
          width="auto"
        ></Column>
        <Column
          dataField="email"
          caption={this.customerLang.TABLEHEADERS.EMAIL}
          visible={false}
        ></Column>

        <Column
          dataField="gender"
          caption={this.customerLang.TABLEHEADERS.GENDER}
          visible={false}
        >
          <RequiredRule />

          <Lookup
            dataSource={this.genderDataSource}
            displayExpr={this.rtlEnabled ? "name_ar" : "name"}
            valueExpr="id"
          />
        </Column>
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
        <Editing
          mode="popup"
          allowUpdating={false}
          allowAdding={true}
          allowDeleting={false}
        >
          <Popup
            title={this.lang.LABELS.CUSTOMERTITLE}
            showTitle={true}
            width={700}
            height={525}
          />
          <DxForm>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField="name" />

              <Item dataField="name_ar" />
              <Item dataField="ph_num" />

              <Item dataField="ph_alt_num" />
              <Item dataField="id_card" />

              <Item dataField="email" />

              <Item dataField="gender" />
            </Item>
          </DxForm>
        </Editing>
      </DataGrid>
    );
  }
  syncDataGridSelection(e) {
    this.setState({
      gridBoxValue: e.value,
    });
  }

  dataGridOnSelectionChanged(e) {
    this.setState({
      gridBoxValue: e.selectedRowKeys,
      isGridBoxOpened: false,
    });
  }
  onRowInserted(e) {}
  gridBoxDisplayExpr(item) {
    console.log(item);
    return item && `${item.name_ar} (${item.ph_num})`;
  }

  onGridBoxOpened(e) {
    if (e.name === "opened") {
      this.setState({
        isGridBoxOpened: e.value,
      });
    }
  }
  getGenderes = () => {
    const headers = getHeadersForHttpReq();
    axios
      .get(`${complete_URL}/parameters/get_gender`, { headers })
      .then((res) => {
        this.setState({ gender: res.data.data[0] });
        this.genderDataSource = new ArrayStore({
          data: res.data.data[0],
          key: "id",
        });
        console.log(this.genderDataSource);
      })
      .catch((err) => {
        console.log("Error occured while fetching GENDERS ->", err);
      });
  };

  getBanquets = () => {
    const client_id = localStorage.getItem("client_id");
    if (client_id) {
      const headers = getHeadersForHttpReq();
      axios
        .get(`${complete_URL}/banquets/by_clients/${client_id}`, { headers })
        .then((res) => {
          // console.log(res.data.data[0])
          let selectedBqt = "";
          if (res.data.data[0].length === 1) {
            selectedBqt = res.data.data[0][0].id;
            // eslint-disable-next-line
            this.props.form.setFieldsValue({ banquet: selectedBqt });
          }
          this.setState(
            { banquets: res.data.data[0], currentDate: moment(new Date()) },
            () => {
              if (res.data.data[0].length === 1) {
                this.selectBanquet(selectedBqt);
              }
            }
          );
        })
        .catch((err) => {
          console.log("Error occured while fetching Banquets ->", err);
        });
    } else {
      message.error(this.msg.LOGINERROR, 2);
    }
  };

  getEventTypes = () => {
    const headers = getHeadersForHttpReq();
    axios
      .get(`${complete_URL}/parameters/event_type`, { headers })
      .then((res) => {
        this.setState(
          {
            event_types: res.data.data[0],
          } /*, () => { console.log(this.state.event_types) }*/
        );
      })
      .catch((err) => {
        console.log("Error occured while fetching Event Types ->", err);
      });
  };

  selectBanquet = (v) => {
    const headers = getHeadersForHttpReq();
    axios
      .get(`${complete_URL}/halls/by_banquet/${v}`, { headers })
      .then((res) => {
        // console.log('halls',res.data.data[0]);
        this.setState({ halls: res.data.data[0] }, () => {
          if (res.data.data[0].length === 1) {
            // eslint-disable-next-line
            this.props.form.setFieldsValue({ hall: res.data.data[0][0].id });
          }
        });
      })
      .catch((err) => {
        console.log(
          "Error occured while fetching Halls of selected Banquet->",
          err
        );
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const headers = getHeadersForHttpReq();
        let user_id = localStorage.getItem("user_id");
        // if (localStorage.getItem('lang') === 'EN') {

        localStorage.setItem("customer_id", this.state.gridBoxValue[0]);
        values.status = "pending";
        values.customer = this.state.gridBoxValue[0];
        values.from_date = moment(values.from_date)
          .locale("en")
          .format("YYYY-MM-DD");
        values.to_date = moment(values.to_date)
          .locale("en")
          .format("YYYY-MM-DD");
        values.reservation_date = moment(values.reservation_date)
          .locale("en")
          .format("YYYY-MM-DD");
        values.from_time = moment(values.from_time)
          .locale("en")
          .format("HH:mm:ss");
        values.to_time = moment(values.to_time).locale("en").format("HH:mm:ss");
        values.lang = "en";
        const _self = this;
        // } else {
        //   values.status = 'قيد الانتظار'
        //   values.from_date = moment(values.from_date).locale('ar').format('YYYY-MM-DD')
        //   values.to_date = moment(values.to_date).locale('ar').format('YYYY-MM-DD')
        //   values.reservation_date = moment(values.reservation_date).locale('ar').format('YYYY-MM-DD')
        //   values.from_time = moment(values.from_time).locale('ar').format('HH:mm:ss')
        //   values.to_time = moment(values.to_time).locale('ar').format('HH:mm:ss')
        //   values.lang = 'ar'
        // }
        values.client_id = localStorage.getItem("client_id");
        values.user_id = user_id;
        let redirect = this.props.history;
        // console.log(values)
        axios
          .post(`${complete_URL}/reservations/add`, values, { headers })
          .then((res) => {
            if (res.data.success === true) {
              this.setState({ loading: true });
              // console.log(res.data.data.id)
              message.success(_self.msg.RESRVADDSUCCESS);
              localStorage.setItem("reservationUpdate", res.data.data.id);
              // console.log(res.data)
              setTimeout(() => {
                // Commit code ~ Generate + Download Reservation contract report 24Feb22
                /*axios(
                  {
                    url: `${complete_URL}/reservations/contract/report`,
                    method: 'GET',
                    params: {
                      customer: res.data.data.customer,
                      reservation_date: res.data.data.reservation_date,
                      amount: res.data.data.total_ammount,
                      to_date: res.data.data.to_date,
                      hall: res.data.data.hall,
                      lang:localStorage.getItem('lang'),
                      client_id: localStorage.getItem('client_id'),
                    },
                    headers,
                    responseType: 'blob',
                  })
                  .then(resu => {

                    var reader = new FileReader();
                    reader.readAsDataURL(resu.data);
                    reader.onloadend = () => {
                      // console.log(reader.result)
                      // console.log(resu.data.type.split('/')[1])
                      let doc = reader.result
                      const link = document.createElement('a');
                      link.href = doc;
                      link.setAttribute('download', `contract.docx`); //or any other extension
                      document.body.appendChild(link);
                      link.click();
                    }
                  })*/
                redirect.push("/dashboard/reservationupdate");
              }, 600);
            } else if (res.data.success === "confirm") {
              confirm({
                title: _self.msg.RESRVEXISTSPENDING, //res.data.message,
                onOk() {
                  values.forceWrite = true;
                  axios
                    .post(`${complete_URL}/reservations/add`, values, {
                      headers,
                    })
                    .then((resu) => {
                      _self.setState({ loading: true });
                      if (resu.data.success === true) {
                        // console.log(resu.data.data)
                        message.success(_self.msg.RESRVADDSUCCESS);
                        localStorage.setItem(
                          "reservationUpdate",
                          resu.data.data.id
                        );
                        setTimeout(() => {
                          // Commit code ~ Generate + Download Reservation contract report 24Feb22
                          /*
                          axios(
                            {
                              url: `${complete_URL}/reservations/contract/report`,
                              method: 'GET',
                              params: {
                                customer: resu.data.data.customer,
                                reservation_date: resu.data.data.reservation_date,
                                amount: resu.data.data.total_ammount,
                                to_date: resu.data.data.to_date,
                                hall: resu.data.data.hall,
                                client_id: localStorage.getItem('client_id'),
                                lang:localStorage.getItem('lang')
                              },
                              headers,
                              responseType: 'blob'
                            })
                            .then(resua => {
                              let reader = new FileReader();
                              reader.readAsDataURL(resua.data);
                              reader.onloadend = () => {
                                // console.log(reader.result)
                                // console.log(resua.data.type.split('/')[1])
                                let doc = reader.result
                                const link = document.createElement('a');
                                link.href = doc;
                                link.setAttribute('download', `contract.docx`); //or any other extension
                                document.body.appendChild(link);
                                link.click();
                              }
                            })*/
                          redirect.push("/dashboard/reservationupdate");
                        }, 600);
                      } else {
                        _self.setState({ loading: false });
                        message.error(
                          _self.msg.RESRVADDFAILURE,
                          2 /*resu.data.message*/
                        );
                      }
                    });
                },
                onCancel() {
                  // console.log('Cancel');
                },
              });
            } else {
              _self.setState({ loading: false });
              message.error(_self.msg.RESRVADDFAILURE, 2 /*res.data.message*/);
              // console.log(res)
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            message.error(_self.msg.RESRVADDFAILURE, 2);
            console.log("Error occured while creating the Reservation->", err);
          });
      } //End of if error not found
    });
  };

  onSearch = (v) => {
    const headers = getHeadersForHttpReq();
    let data = {
      id_card: v,
      id: localStorage.getItem("client_id"),
    };
    axios
      .post(`${complete_URL}/customers/getcustomers`, data, { headers })
      .then((result) => {
        console.log(result.data.data);
        this.setState({ customers: result.data.data });
      })
      .catch((err) => {
        console.log("Error occured while fetching Customers data ->", err);
      });
  };

  componentWillMount() {
    if (localStorage.getItem("lang") === "EN") {
      this.CustomRow = (
        <Row className="top-header" gutter={24}>
          <Col span={5}>
            <Link to="/dashboard/reservations">
              <Button type="danger">
                <Icon type="arrow-left" />
              </Button>
            </Link>
          </Col>
          <Col span={12}>
            <h1 style={{ textAlign: "center" }}>{this.lang.LABELS.TITLE}</h1>
          </Col>
          <Col className="btn-box" span={6} style={{ display: "flex" }}>
            <Button
              style={{ margin: "16px auto" }}
              type=""
              htmlType="submit"
              title="save"
            >
              <Icon type="save" theme="outlined" style={{ color: "green" }} />
            </Button>
            <Link to="/dashboard/reservations">
              <Button style={{ margin: "16px auto" }} title="delete">
                <Icon type="delete" theme="outlined" style={{ color: "red" }} />
              </Button>
            </Link>
          </Col>
        </Row>
      );
    } else {
      this.CustomRow = (
        <Row className="top-header" gutter={24}>
          <Col span={4}>
            <Link to="/dashboard/reservations">
              <Button type="danger">
                <Icon type="arrow-left" />
              </Button>
            </Link>
          </Col>
          <Col span={15}>
            <h1 style={{ textAlign: "center" }}>{this.lang.LABELS.TITLE}</h1>
          </Col>
          <Col className="btn-box" span={5} style={{ display: "flex" }}>
            <Link to="/dashboard/reservations">
              <Button style={{ margin: "16px auto" }} title="delete">
                <Icon
                  type="delete"
                  theme="outlined"
                  style={{ color: "green" }}
                />
              </Button>
            </Link>
            <Button
              style={{ margin: "16px auto" }}
              type=""
              htmlType="submit"
              title="save"
            >
              <Icon type="save" theme="outlined" style={{ color: "red" }} />
            </Button>
          </Col>
        </Row>
      );
    }
  }

  handleUOPchange = (a, e) => {
    if (this.state.hallPrice.length > 0) {
      let uop_obj = this.state.hallPrice.filter((item) => item.id === e.key);
      if (uop_obj) {
        this.setState(
          {
            uom: uop_obj[0].uop_name.toLowerCase() === "daily" ? false : true,
          } /*,()=>{
          console.log('this.state.uom ->',this.state.uom);
        }*/
        );
      }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let {
      dir,
      display,
      banquets,
      halls,
      hallPrice,
      customers,
      event_types,
      gender,
    } = this.state;

    return (
      <Spin indicator={antIcon} spinning={this.state.loading}>
        <Row gutter={12} style={{ marginTop: "50px" }}>
          <Col span={4}></Col>
          <Col span={16}>
            <Card>
              <Form onSubmit={this.handleSubmit}>
                {this.CustomRow}
                <Row gutter={24} dir={dir} style={{ display }}>
                  <Col span={8}>
                    <Form.Item label={this.lang.LABELS.BANQUET}>
                      {getFieldDecorator("banquet", {
                        rules: [
                          {
                            required: true,
                            message: this.msg.SELECTBANQUETERROR,
                          },
                        ],
                      })(
                        <Select
                          onChange={this.selectBanquet}
                          placeholder={this.lang.LABELS.BANQUET}
                        >
                          {localStorage.getItem("lang") === "EN"
                            ? banquets.length > 0 &&
                              banquets.map((banquet) => (
                                <Select.Option
                                  key={banquet.id}
                                  value={banquet.id}
                                >
                                  {banquet.name}
                                </Select.Option>
                              ))
                            : banquets.length > 0 &&
                              banquets.map((banquet) => (
                                <Select.Option
                                  style={{ textAlign: "right" }}
                                  key={banquet.id}
                                  value={banquet.id}
                                >
                                  {banquet.name_ar}
                                </Select.Option>
                              ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={this.lang.LABELS.HALL}>
                      {getFieldDecorator("hall", {
                        rules: [
                          { required: true, message: this.msg.SELECTHALLERROR },
                        ],
                      })(
                        <Select
                          placeholder={this.lang.LABELS.HALL}
                          onChange={this.selectHall}
                        >
                          {localStorage.getItem("lang") === "EN"
                            ? halls.length > 0 &&
                              halls.map((hall) => (
                                <Select.Option key={hall.id} value={hall.id}>
                                  {hall.name}
                                </Select.Option>
                              ))
                            : halls.length > 0 &&
                              halls.map((hall) => (
                                <Select.Option
                                  style={{ textAlign: "right" }}
                                  key={hall.id}
                                  value={hall.id}
                                >
                                  {hall.name_ar}
                                </Select.Option>
                              ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={this.lang.LABELS.UOP}>
                      {getFieldDecorator("hallPrice", {
                        rules: [
                          { required: true, message: this.msg.SELECTUOPERROR },
                        ],
                      })(
                        <Select
                          placeholder={this.lang.LABELS.UOP}
                          onChange={(a, e) => this.handleUOPchange(a, e)}
                        >
                          {localStorage.getItem("lang") === "EN"
                            ? hallPrice.length > 0 &&
                              hallPrice.map((uop_item) => (
                                <Select.Option
                                  key={uop_item.id}
                                  value={uop_item.uop}
                                >
                                  {uop_item.uop_name}
                                </Select.Option>
                              ))
                            : hallPrice.length > 0 &&
                              hallPrice.map((uop_item) => (
                                <Select.Option
                                  style={{ textAlign: "right" }}
                                  key={uop_item.id}
                                  value={uop_item.uop}
                                >
                                  {uop_item.uop_name_ar}
                                </Select.Option>
                              ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} dir={dir} style={{ display }}>
                  {/* <Col span={12}>
                    <SelectBox
                      placeholder={this.lang.LABELS.CUSTOMER}
                      dataSource={customers}
                      displayExpr="name_ar"
                      valueExpr="id"
                      searchEnabled={true}
                      searchMode="contains"
                      searchExpr={[
                        "name_ar",
                        "id_card",
                        "name",
                        "ph_alt_num",
                        "ph_num",
                      ]}
                      searchTimeout={200}
                      minSearchLength={0}
                      showDataBeforeSearch={true}
                    />
                  </Col> */}
                  {/* <Col span={12}>
                    <DropDownBox
                      value={this.state.gridBoxValue}
                      opened={this.state.isGridBoxOpened}
                      valueExpr="id"
                      deferRendering={false}
                      displayExpr={this.gridBoxDisplayExpr}
                      placeholder="Select a value..."
                      showClearButton={true}
                      dataSource={this.gridDataSource}
                      onValueChanged={this.syncDataGridSelection}
                      onOptionChanged={this.onGridBoxOpened}
                      contentRender={this.dataGridRender}
                    />
                  </Col> */}
                  <Col span={12}>
                    <Form.Item
                      label={this.lang.LABELS.CUSTOMER}
                      name="customer"
                    >
                      <DropDownBox
                        name="customer"
                        id="customer"
                        value={this.state.gridBoxValue}
                        opened={this.state.isGridBoxOpened}
                        valueExpr="id"
                        deferRendering={false}
                        displayExpr={this.gridBoxDisplayExpr}
                        placeholder="Select a value..."
                        showClearButton={true}
                        dataSource={gridDataSource}
                        onValueChanged={this.syncDataGridSelection}
                        onOptionChanged={this.onGridBoxOpened}
                        contentRender={this.dataGridRender}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={this.lang.LABELS.RESERVATIONDATE}>
                      {getFieldDecorator("reservation_date", {
                        initialValue: this.state.currentDate,
                        rules: [
                          {
                            required: true,
                            message: this.msg.INSERTRESRVDATEERROR,
                          },
                        ],
                      })(<DatePicker style={{ width: "100%" }} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} dir={dir} style={{ display }}>
                  <Col span={6}>
                    <Form.Item label={this.lang.LABELS.FROMDATE}>
                      {getFieldDecorator("from_date", {
                        initialValue: this.state.startValue,
                        rules: [
                          {
                            required: true,
                            message: this.msg.INSERTFROMDATEERROR,
                          },
                        ],
                      })(
                        <DatePicker
                          disabledDate={this.disabledStartDate}
                          format="YYYY-MM-DD"
                          placeholder={this.lang.LABELS.FROMDATE}
                          onChange={this.onStartChange}
                          onOpenChange={this.handleStartOpenChange}
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={this.lang.LABELS.TODATE}>
                      {getFieldDecorator("to_date", {
                        initialValue: this.state.endValue,
                        rules: [
                          {
                            required: true,
                            message: this.msg.INSERTTODATEERROR,
                          },
                        ],
                      })(
                        <DatePicker
                          disabledDate={this.disabledEndDate}
                          format="YYYY-MM-DD"
                          placeholder={this.lang.LABELS.TODATE}
                          onChange={this.onEndChange}
                          open={this.state.endOpen}
                          onOpenChange={this.handleEndOpenChange}
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={this.lang.LABELS.FROMTIME}>
                      {getFieldDecorator("from_time", {
                        rules: [
                          {
                            required: this.state.uom,
                            message: this.msg.INSERTFROMTIMEERROR,
                          },
                        ],
                      })(
                        <TimePicker
                          disabled={!this.state.uom}
                          use12Hours
                          placeholder={this.lang.LABELS.FROMTIME}
                          format="h:mm a"
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={this.lang.LABELS.TOTIME}>
                      {getFieldDecorator("to_time", {
                        rules: [
                          {
                            required: this.state.uom,
                            message: this.msg.INSERTTOTIMEERROR,
                          },
                        ],
                      })(
                        <TimePicker
                          disabled={!this.state.uom}
                          use12Hours
                          placeholder={this.lang.LABELS.TOTIME}
                          format="h:mm a"
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} dir={dir} style={{ display }}>
                  <Col span={12}>
                    <Form.Item label={this.lang.LABELS.EVENTTYPE}>
                      {getFieldDecorator("event_type", {
                        rules: [
                          {
                            required: true,
                            message: this.msg.SELECTEVENTTYPEERROR,
                          },
                        ],
                      })(
                        <Select placeholder={this.lang.LABELS.EVENTTYPE}>
                          {localStorage.getItem("lang") === "EN"
                            ? event_types.length > 0 &&
                              event_types.map((event) => (
                                <Select.Option key={event.id} value={event.id}>
                                  {event.name}
                                </Select.Option>
                              ))
                            : event_types.length > 0 &&
                              event_types.map((event) => (
                                <Select.Option
                                  style={{ textAlign: "right" }}
                                  key={event.id}
                                  value={event.id}
                                >
                                  {event.name_ar}
                                </Select.Option>
                              ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={this.lang.LABELS.NOOFPERSONS}>
                      {getFieldDecorator("no_person", {
                        rules: [
                          {
                            required: true,
                            message: this.msg.INSERTNOOFPERSONSERROR,
                          },
                        ],
                      })(
                        <InputNumber
                          min={0}
                          placeholder={this.lang.LABELS.NOOFPERSONS}
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col span={4}></Col>
        </Row>
        <Modal
          visible={this.state.createCustomerOpen}
          title={this.lang.LABELS.CUSTOMERTITLE}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={false}
        >
          <CreateCustomer
            gender={gender}
            language={this.props.language}
            handleCancel={this.handleCancel}
            handleOk={this.handleOk}
          />
        </Modal>
      </Spin>
    );
  }
}

export default ReservationAdd = Form.create({ name: "resrv_form" })(
  ReservationAdd
);
