import React from "react";
import {
  Row,
  Col,
  Form,
  Icon,
  Button,
  InputNumber,
  DatePicker,
  Table,
  Select,
  message,
  Spin,
} from "antd";
import axios from "axios";
import API_URL from "../../constants/index";
import getHeadersForHttpReq from "../../constants/token";
import getHeadersForPDFreport from "../../constants/reportHeaders";
import moment from "moment";
// import FormItem from 'antd/lib/form/FormItem';
const pt = localStorage.getItem("pt");
const complete_URL = API_URL + pt;

if (localStorage.getItem("lang") === "AR") {
  require("../css/global-css.css");
}

class HallPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resrv_id: props.resrv_id,
      hallDocumentsList: false,
      paymenntLines: "setup",
      tdata: [],
      payment_type: [],
      payment_category: [],
      parameters: [],
      paymenntLinesData: [],
      contractors: [],
      payment_date: moment(new Date()).locale("en").format("YYYY-MM-DD"),
      canCombReport: false,
      payment_Records_List: [],
      // sortField:"payment_type",
      sortField: "updated_at",
      sortOrder: "ascend",
      pagination: {},
      page: 1,
      size: 5,
      searchValues: {
        cont_name: "",
      },
      disable_payment_transaction: true,
      update_Counter: 0,
      add_transaction_loading: false,
      spin: false,
      dir: "ltr",
      display: "block",
    };

    this.lang = props.language._RESERVATION.UPDATE.PAYMENTS.HALLPAYMENT;
    this.msg = props.language._RESERVATION.UPDATE.PAYMENTS.HALLPAYMENT.MESSAGE;
    //We can add the record of Hall Rent with id of '00000000-0000-00' for efficeint code (avoiding loop, extra code etc)
    this.columns = [
      localStorage.getItem("lang") === "EN"
        ? {
            // title: `${this.lang.SETUP.LABELS.PAYMENTCATEGORY}`,
            title: `${this.lang.SETUP.LABELS.CONTRACTORNAME}`,
            dataIndex: "contractor_id",
            align: "left",
            render: (text, record) => (
              <button
                className="link-button"
                onClick={this.handlePriceNavigation.bind(this, record)}
              >
                {text == "00000000-0000-00"
                  ? `${this.lang.SETUP.LABELS.HALLRENT}`
                  : this.state.contractors.length > 0 &&
                    this.state.contractors.filter((i) => i.id == text).length >
                      0 &&
                    this.state.contractors.length > 0 &&
                    this.state.contractors.filter((i) => i.id == text).length >
                      0 &&
                    this.state.contractors.filter((i) => i.id == text)[0].name}
              </button>
            ),
          }
        : {
            // title: `${this.lang.SETUP.LABELS.PAYMENTCATEGORY}`,
            title: `${this.lang.SETUP.LABELS.CONTRACTORNAME}`,
            dataIndex: "contractor_id",
            align: "right",
            render: (text, record) => (
              <button
                className="link-button"
                onClick={this.handlePriceNavigation.bind(this, record)}
              >
                {text == "00000000-0000-00"
                  ? `${this.lang.SETUP.LABELS.HALLRENT}`
                  : this.state.contractors.length > 0 &&
                    this.state.contractors.filter((i) => i.id == text).length >
                      0 &&
                    this.state.contractors.length > 0 &&
                    this.state.contractors.filter((i) => i.id == text).length >
                      0 &&
                    this.state.contractors.filter((i) => i.id == text)[0]
                      .name_ar}
              </button>
            ),
          },
      // {
      //   title: `${this.lang.SETUP.LABELS.PAYMENTCATEGORY}`,
      //   dataIndex: 'payment_category',
      //   render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      // },
      localStorage.getItem("lang") === "EN"
        ? {
            title: `${this.lang.SETUP.LABELS.PAYMENTTYPE}`,
            dataIndex: "payment_type",
            align: "left",
            render: (text, record) => (
              <button
                className="link-button"
                onClick={this.handlePriceNavigation.bind(this, record)}
              >
                {text}
              </button>
            ),
          }
        : {
            title: `${this.lang.SETUP.LABELS.PAYMENTTYPE}`,
            dataIndex: "payment_type_ar",
            align: "right",
            render: (text, record) => (
              <button
                className="link-button"
                onClick={this.handlePriceNavigation.bind(this, record)}
              >
                {text}
              </button>
            ),
          },
      localStorage.getItem("lang") === "EN"
        ? {
            title: `${this.lang.SETUP.LABELS.PAMOUNT}`,
            dataIndex: "amount",
            align: "center",
            render: (text, record) => (
              <button
                className="link-button"
                onClick={this.handlePriceNavigation.bind(this, record)}
              >
                {text}
              </button>
            ),
          }
        : {
            title: `${this.lang.SETUP.LABELS.PAMOUNT}`,
            dataIndex: "amount",
            align: "right",
            render: (text, record) => (
              <button
                className="link-button"
                onClick={this.handlePriceNavigation.bind(this, record)}
              >
                {text}
              </button>
            ),
          },
      localStorage.getItem("lang") === "EN"
        ? {
            title: `${this.lang.SETUP.LABELS.TAMOUNT}`,
            dataIndex: "total_amount",
            align: "center",
            render: (text, record) => (
              <button
                className="link-button"
                onClick={this.handlePriceNavigation.bind(this, record)}
              >
                {text}
              </button>
            ),
          }
        : {
            title: `${this.lang.SETUP.LABELS.TAMOUNT}`,
            dataIndex: "total_amount",
            align: "right",
            render: (text, record) => (
              <button
                className="link-button"
                onClick={this.handlePriceNavigation.bind(this, record)}
              >
                {text}
              </button>
            ),
          },
      // {
      //   title: `${this.lang.SETUP.LABELS.ACTION}`,
      //   render: (text, record) => (
      //     <span>
      //       <Button type="primary" onClick={this.deleteDoc.bind(this, record)}>{this.lang.SETUP.BUTTON.DELETE}</Button>
      //     </span>
      //   ),
      //   width: '30%',
      //   key: 'last_name',
      // }
    ];
    this.columnsPayments = [
      localStorage.getItem("lang") === "EN"
        ? {
            title: `${this.lang.SETUP.LABELS.DATE}`,
            // title: 'Date',
            // dataIndex: 'created_at',
            dataIndex: "payment_date",
            align: "left",
            // render: (text, record) => <button className="link-button">{text != '0000-00-00' ? moment(text).format('YYYY-MM-DD') : '0000-00-00'}</button>,
          }
        : {
            title: `${this.lang.SETUP.LABELS.DATE}`,
            // title: 'Date',
            // dataIndex: 'created_at',
            dataIndex: "payment_date",
            align: "right",
            // render: (text, record) => <button className="link-button">{text != '0000-00-00' ? moment(text).format('YYYY-MM-DD') : '0000-00-00'}</button>,
          },
      localStorage.getItem("lang") === "EN"
        ? {
            title: `${this.lang.SETUP.LABELS.PAYMENTCATEGORY}`,
            // title: 'payment category',
            dataIndex: "payment_category",
            align: "center",
            // render: (text, record) => <button className="link-button" >{localStorage.getItem('lang') == 'EN' ? this.state.payment_category.find(i => i.id == text).name : this.state.payment_category.find(i => i.id == text).name_ar}</button>,
            /*We can get payment_category_name ,payment_category_name_ar directly from backend by adding JOIN on Parameter table as well on the basis of this id */
            render: (text, record) => (
              <div>
                {this.state.payment_category.find((i) => i.id == text).name}
              </div>
            ),
          }
        : {
            title: `${this.lang.SETUP.LABELS.PAYMENTCATEGORY}`,
            // title: 'payment category',
            dataIndex: "payment_category",
            align: "right",
            // render: (text, record) => <button className="link-button" >{localStorage.getItem('lang') == 'EN' ? this.state.payment_category.find(i => i.id == text).name : this.state.payment_category.find(i => i.id == text).name_ar}</button>,
            /*We can get payment_category_name ,payment_category_name_ar directly from backend by adding JOIN on Parameter table as well on the basis of this id */
            render: (text, record) => (
              <div>
                {this.state.payment_category.find((i) => i.id == text).name_ar}
              </div>
            ),
          },
      // {
      //   title: `${this.lang.SETUP.LABELS.PAYMENTCATEGORY}`,
      //   dataIndex: 'payment_category',
      //   render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      // },
      // {
      //   title: `${this.lang.SETUP.LABELS.PAYMENTTYPE}`,
      //   dataIndex: 'payment_type',
      //   render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      // },
      localStorage.getItem("lang") === "EN"
        ? {
            title: `${this.lang.SETUP.LABELS.PAMOUNT}`,
            dataIndex: "ammount",
            align: "center",
            // render: (text, record) => <button className="link-button" >{text}</button>,
          }
        : {
            title: `${this.lang.SETUP.LABELS.PAMOUNT}`,
            dataIndex: "ammount",
            align: "right",
            // render: (text, record) => <button className="link-button" >{text}</button>,
          } /*,
      {
        title: `${this.lang.SETUP.LABELS.TAMOUNT}`,
        dataIndex: 'total_amount'
        ,
        render: (text, record) => <button className="link-button" >{text}</button>,
      },*/,
      // {
      //   title: `${this.lang.SETUP.LABELS.ACTION}`,
      //   render: (text, record) => (
      //     <span>
      //       <Button type="primary" onClick={this.deleteDoc.bind(this, record)}>{this.lang.SETUP.BUTTON.DELETE}</Button>
      //     </span>
      //   ),
      //   width: '30%',
      //   key: 'last_name',
      // }
    ];
  }

  setComponentDirection = () => {
    if (localStorage.getItem("lang") === "EN") {
      this.setState({ dir: "ltr", display: "block" });
    } else {
      this.setState({ dir: "rtl", display: "flex" });
    }
  };

  generate_Receipt_Selection = (records) => {
    for (let i = 0; i < records.length; i++) {
      if (records[i].payment_type == "contractor") {
        let contractor = this.state.contractors.filter(
          (ct) => ct.id == records[i].contractor_id
        );
        Object.assign(records[i], {
          contractor_name: contractor[0].name,
          contractor_name_ar: contractor[0].name_ar,
        });
      } else {
        Object.assign(records[i], {
          contractor_name: "Hall Rent",
          contractor_name_ar: "Hall Rent",
        });
      }
    }
    this.setState({ payment_Records_List: records }, () => {
      if (this.state.payment_Records_List.length > 0) {
        this.setState({ canCombReport: true });
      } else {
        this.setState({ canCombReport: false });
      }
      // console.log('payment_Records_List->',this.state.payment_Records_List);
    });
  };

  download_Receipt = () => {
    this.setState({ spin: true });
    let opt = "en";
    let client_id = localStorage.getItem("client_id");
    if (client_id) {
      if (localStorage.getItem("lang") == "AR") {
        opt = "-ar";
      }
      let api_main_part = API_URL.split("/")[2];
      let newReportData = this.props.getCompleteReportData();
      let req_data = {
        api_main_part,
        opt,
        client_id,
        port: pt,
        reservation_data: newReportData,
        data: this.state.payment_Records_List,
      };
      const headers2 = getHeadersForPDFreport();
      axios
        .get(`${complete_URL}/reservation_payments/export_payment_receipt/`, {
          responseType: "arraybuffer",
          headers: headers2,
          params: { data: JSON.stringify(req_data) },
        })
        .then((response) => {
          //  console.log('Response from hallpayments->',response)
          this.setState({ spin: false });
          if (response.status == 200) {
            const blob = new Blob([response.data], { type: "application/pdf" }); //make available for download
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            let version = opt == "en" ? "Eng" : "Arb";
            link.download = "Payment_Receipt_" + version + ".pdf";
            /*link.click();*/
            link.target = "_blank";
            window.open(link);
          } else if (response.status == 204) {
            message.error(this.msg.PAYMENTDATANOTFOUND, 2);
            console.log(
              "Error found while generating the Payment Receipt->",
              response
            );
          }
        })
        .catch((err) => {
          this.setState({ spin: false });
          message.error(this.msg.RECEIPTGENERATEERROR, 2);
          console.log(err);
        });
    } //End of if client_id
  };

  //NO NEED OF THIS METHOD AS PAYMENT TRANSACTION OR EITHER ADDED OR REVERSED TO KEEP TRANSACTION FLOW BALANCED
  deleteDoc = (record) => {
    axios
      .delete(`${complete_URL}/hallpayments/${record.id}`)
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.message);
          this.reload();
          this.fetch();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  componentDidMount() {
    this.setComponentDirection();
    this.getPaymentTypes(); //Constants i.e Hall Rent or Contractor
    this.getPaymentCategories(); //Constants i.e Reverse or pay
    // this.getContractors();//Constants i.e Contractors are called only once
    this.getAssignedpContByClient();
    this.fetch();
    this.setState({
      parent_resrv_Counter: this.props.parent_resrv_Counter,
      parent_hall_pmt_Counter: this.props.parent_hall_pmt_Counter,
      parent_hall_srv_Counter: this.props.parent_hall_srv_Counter,
      parent_hall_pkg_Counter: this.props.parent_hall_pkg_Counter,
      parent_hall_doc_Counter: this.props.parent_hall_doc_Counter,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.parent_hall_pkg_Counter > this.state.parent_hall_pkg_Counter
    ) {
      // this.getAllPayments();
      this.fetch();
      this.setState({
        parent_hall_pkg_Counter: prevProps.parent_hall_pkg_Counter,
      });
      // console.log('componentDidUpdate called based on props changed in Hallpayment');
    }
  }

  handlePriceNavigation = (record) => {
    let disable_payment_transaction = true;
    const headers = getHeadersForHttpReq();
    axios
      .get(`${complete_URL}/hallpayments/by_id/${record.id}`, { headers })
      .then((pkg_pmt_res) => {
        if (parseFloat(pkg_pmt_res.data.data.total_amount) > 0) {
          disable_payment_transaction = false;
        }
        this.setState(
          {
            hallDocumentsList: "false",
            paymentObject: pkg_pmt_res.data.data,
            disable_payment_transaction: disable_payment_transaction,
          },
          () => {
            this.getPaymentLines(); //console.log('Selected Package for payment->',this.state.paymentObject);
          }
        );
      })
      .catch((pkg_pmt_err) => {
        message.error("Unable to fetch the Payment Section Record!!");
        console.log(
          "Error found while fetching the Payment Section->",
          pkg_pmt_err
        );
      });
  };

  getPaymentLines = () => {
    //console.log('Payment obj->',this.state.paymentObject);
    const headers = getHeadersForHttpReq();
    axios
      .get(
        `${complete_URL}/hallpaymentLines/paymentlines_by_parent/${this.state.paymentObject.id}`,
        { headers }
      )
      .then((pmt_lines_res) => {
        this.setState({ paymenntLinesData: pmt_lines_res.data.data });
      })
      .catch((pmt_lines_err) => {
        message.error(this.msg.PAYMENTLINESNOTFOUND);
        console.log("Error while fetching Payment lines->", pmt_lines_err);
      });
  };

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      pageNumber: pagination.current,
    });

    if (
      sorter.field == this.state.sortField &&
      sorter.order == this.state.sortOrder
    ) {
      if (this.state.pageNumber !== pager.current) {
        this.fetch({ page: pagination.current });
      }
    } else {
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }
  }; //End of Method

  fetch = (params = {}) => {
    const client_id = localStorage.getItem("client_id");
    const headers = getHeadersForHttpReq();

    let page, sortOrder;
    if (params.page) {
      page = params.page - 1;
    } else {
      page = 0;
    }

    if (params.sortOrder == "ascend") {
      sortOrder = "ASC";
    } else {
      sortOrder = "DESC";
    }

    let data = {
      client_id: client_id,
      resrv_id: this.state.resrv_id || "",

      page,
      sortColumn: params.sortField || this.state.sortField,
      sortOrder,
      size: this.state.size,
      orderBy: "",
      column_name: "no",
    };
    // console.log('Sending data to api ->',data);
    axios
      .post(`${complete_URL}/hallpayments/pagination_table`, data, { headers })
      .then((response) => {
        // console.log('response of exp new pagination ->',response)
        let pagination = {};
        Object.assign(pagination, {
          ...this.state.pagination,
          total: response.data.totalElements,
          current: page + 1,
          pageSize: this.state.size,
        });
        this.setState({ tdata: response.data.data, pagination });
        // console.log('Expense data-> ',response);
      })
      .catch((err) => {
        console.log(
          "Error occured while fetching pagination data new method ->",
          err
        );
      });

    //this.getPaymentTypes();//Constants i.e Hall Rent or Contractor
    //this.getPaymentCategories();//Constants i.e Reverse or pay
    // this.getParameters();

    /*this.getAllPayments();*/ //Old payment method
    //this.getContractors();//Constants i.e Contractors are called only once
  };

  //Old method of fetching all payments. can remove as it is replaced by new pagination logic
  getAllPayments = () => {
    let res_id = this.props.reservationId.substring(0, 16);
    if (res_id) {
      const headers = getHeadersForHttpReq();
      axios
        .get(`${complete_URL}/hallpayments/by_reservations/${res_id}`, {
          headers,
        })
        .then((res) => {
          setTimeout(() => {
            let payment_data = res.data.data; //console.log('payment_data->',payment_data)
            this.setState({ tdata: payment_data });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      message.error(this.msg.RESRVNOTFOUND, 2);
    }
  };

  //Old method for fetching the Contractors(Before Contractor feature)
  getContractors = () => {
    const headers = getHeadersForHttpReq();
    axios
      .get(`${complete_URL}/contractors/a/cont_all/`, { headers })
      .then((res) => {
        // console.log('contractors->',res.data.data)
        this.setState({ contractors: res.data.data });
      })
      .catch((err) => {
        console.log(
          "Error occured while fetching the Service Contractors->",
          err
        );
      });
  };
  //New ajax API call for fetching Assigned independent contractors( of selected Service Type)
  getAssignedpContByClient = () => {
    const headers = getHeadersForHttpReq();
    const client_id = localStorage.getItem("client_id");
    axios
      .get(`${complete_URL}/contractors/independent/${client_id}`, { headers })
      .then((res) => {
        // console.log('ind contractors->',res.data.data);
        this.setState({ contractors: res.data.data });
      })
      .catch((err) => {
        console.log("Error occured while fetching Assigned Contractors->", err);
      });
  };

  getPaymentTypes = () => {
    const headers = getHeadersForHttpReq();
    axios
      .get(`${complete_URL}/parameters/get_byParameter/PAYMENT_TYPE`, {
        headers,
      })
      .then((result) => {
        this.setState({ payment_type: result.data.data[0] });
      })
      .catch((err) => {
        console.log("Error occured while fetching the Payment Types->", err);
      });
  };

  getPaymentCategories = () => {
    const headers = getHeadersForHttpReq();
    axios
      .get(`${complete_URL}/parameters/get_byParameter/PAYMENT_CATEGORY`, {
        headers,
      })
      .then((result) => {
        this.setState({ payment_category: result.data.data[0] });
      })
      .catch((err) => {
        console.log(
          "Error occured while fetching the Payment Categories->",
          err
        );
      });
  };

  //Unnecessary method, need to be removed..
  getParameters = () => {
    const headers = getHeadersForHttpReq();
    axios
      .get(`${complete_URL}/parameters/`, { headers })
      .then((result) => {
        console.log("Parameters are ->", result.data.data[0]);
        this.setState({ parameters: result.data.data[0] });
      })
      .catch((err) => {
        console.log("Error occured while fetching the Parameters->", err);
      });
  };

  reload = () => {
    this.props.reloadPage();
  };

  handleDate = (d) => {
    this.setState({ payment_date: d });
  };

  addPaymentLines = () => {
    /*console.log('this.refs.amount.inputNumberRef.currentValue->',this.refs.amount.inputNumberRef.currentValue)
    console.log(' this.refs.payment_category.rcSelect.state.value[0] ->', this.refs.payment_category.rcSelect.state.value[0] )
    console.log('this.state.payment_date->',this.state.payment_date)*/
    if (
      this.refs.amount.inputNumberRef.currentValue &&
      this.refs.payment_category.rcSelect.state.value[0] &&
      this.state.payment_date
    ) {
      this.setState({ add_transaction_loading: true });
      let data = {
        parent: this.state.paymentObject.id,
        payment_type: this.state.paymentObject.payment_type_id,
        payment_category: this.refs.payment_category.rcSelect.state.value[0],
        payment_date: this.state.payment_date,
        ammount: this.refs.amount.inputNumberRef.currentValue,
        user: localStorage.getItem("user_id"),
        reservation_id: this.props.reservation_id,
        hall: this.props.hallId,
        lang: localStorage.getItem("lang"),
        client_id: localStorage.getItem("client_id"),
      };
      const headers = getHeadersForHttpReq();
      axios
        .post(`${complete_URL}/hallpaymentLines/add`, data, { headers })
        .then((res) => {
          // console.log(res)
          if (res.data.success) {
            this.handlePriceNavigation(this.state.paymentObject);
            //this.getAllPayments();//Reloading the Payment Table data
            this.fetch();
            //Counter increment as to track the component change so that other component may also re_render
            this.setState({
              paymenntLines: "setup",
              update_Counter: this.state.update_Counter + 1,
              add_transaction_loading: false,
            });
            //Since Child Component is updated already here (this updation of paymentObject should be done in componentDidUpdate method)
            //Now we should tell parent that tell other Children to re_render if they need
            this.props.updateChildCounterByParent(
              "parent_hall_pmt_Counter",
              this.props.parent_hall_pmt_Counter + 1
            );
            message.success(
              this.msg.PAYMENTLINEADDSUCCESS /*res.data.message*/
            );
          } else {
            this.setState({ add_transaction_loading: false });
            if (res.data.code === "003") {
              message.error(this.msg.INSERTAMOUNTEXCEEDERROR, 3);
            } else if (res.data.code === "004") {
              message.error(this.msg.INSERTAMOUNTSHORTERROR, 3);
            } else
              message.error(
                this.msg.PAYMENTLINEADDFAILURE /*res.data.message*/
              );
          }
        })
        .catch((pmt_line_err) => {
          this.setState({ add_transaction_loading: false });
          if (pmt_line_err.data.code === "001") {
            message.error(this.msg.PAYMENTADDFAILURE, 3);
          } else if (pmt_line_err.data.code === "002") {
            message.error(this.msg.PAYMENTLINEADDFAILURE, 3);
          } else if (pmt_line_err.data.code === "005") {
            message.error(this.msg.PAYMENTNOTFOUND, 3);
          } else if (pmt_line_err.data.code === "006") {
            message.error(this.msg.BANQUETNOTFOUND, 3);
          } else if (pmt_line_err.data.code === "007") {
            message.error(this.msg.HALLNOTFOUND, 3);
          } else
            message.error(this.msg.PAYMENTLINEADDFAILURE /*res.data.message*/);
          // message.error('Unable to add the payment! Please try again');
          console.log(
            "Error occured while adding Payment Transaction->",
            pmt_line_err
          );
        });
    } else {
      message.error(this.msg.INSERTCOMPLETEDATAERROR, 2);
    }
  };
  //EXTRA METHOD.. NOT IN USE
  handleSubmitHallPaymentLine = (e) => {
    e.preventDefault();
    // console.log('in hall payment... handleSubmitHallPaymentLine method called!')
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.reservation_id = this.props.reservationId;
        values.reservation_id = this.props.reservation_id;
        values.hall = this.props.hallId;
        values.user_id = localStorage.getItem("user_id");
        values.amount = 0;
        const headers = getHeadersForHttpReq();
        axios
          .post(`${complete_URL}/hallpayments/add`, values, { headers })
          .then((result) => {
            if (result.data.success) {
              // console.log('hallpayment added in hall payment... handleSubmitHallPaymentLine..');
              message.success(result.data.message);
              this.reload();
              this.fetch();
            } else {
              message.error(result.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  componentWillMount() {
    if (localStorage.getItem("lang") === "EN") {
      this.CustomRow = (
        <Row>
          <Col span={4}>
            <Button
              type="danger"
              title="back"
              onClick={() => {
                this.setState({ hallDocumentsList: false });
              }}
            >
              <Icon type="arrow-left" />
            </Button>
          </Col>
          <Col span={5}></Col>
          <Col span={6}>
            <h1 style={{ textAlign: "center", fontSize: "18px" }}>
              {this.lang.CREATE.LABELS.TITLE}
            </h1>
          </Col>
          <Col span={5}></Col>
          <Col span={4}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                title="save"
                style={{ float: "right" }}
              >
                <Icon type="save" theme="filled" />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      );

      this.CustomRow2 = (
        <Row id="clientTableHeader">
          <Col span={9}>
            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {this.lang.SETUP.LABELS.TITLE}
            </p>
          </Col>
          <Col span={13}>
            {this.props.status !== "canceled" && (
              <Button
                type="primary"
                style={{ float: "right" }}
                onClick={() => {
                  this.setState({ hallDocumentsList: true });
                }}
              >
                {this.lang.SETUP.BUTTON.ADD}
              </Button>
            )}
          </Col>
          <Col span={2}></Col>
        </Row>
      );

      this.CustomRow3 = (
        <Row>
          <Col span={4}>
            <Button
              type="danger"
              onClick={() => {
                this.setState({ hallDocumentsList: false }, () => this.fetch());
              }}
            >
              <Icon type="arrow-left" />
            </Button>
          </Col>
          <Col span={3}></Col>
          <Col span={8}>
            <h1 style={{ fontSize: "18px" }}>
              {" "}
              {this.lang.UPDATE.LABELS.TITLE}
            </h1>
          </Col>
          <Col span={5}></Col>
          <Col span={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <Icon type="save" />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      );
    } else {
      this.CustomRow = (
        <Row>
          <Col span={4}>
            <Button
              type="danger"
              title="back"
              onClick={() => {
                this.setState({ hallDocumentsList: false });
              }}
            >
              <Icon type="arrow-left" />
            </Button>
          </Col>
          <Col span={5}></Col>
          <Col span={6}>
            <h1 style={{ textAlign: "center", fontSize: "18px" }}>
              {this.lang.CREATE.LABELS.TITLE}
            </h1>
          </Col>
          <Col span={5}></Col>
          <Col span={4}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                title="save"
                style={{ float: "right" }}
              >
                <Icon type="save" theme="filled" />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      );

      this.CustomRow2 = (
        <Row id="clientTableHeader">
          <Col span={13}>
            {this.props.status !== "canceled" && (
              <Button
                type="primary"
                style={{ float: "right" }}
                onClick={() => {
                  this.setState({ hallDocumentsList: true });
                }}
              >
                {this.lang.SETUP.BUTTON.ADD}
              </Button>
            )}
          </Col>
          <Col span={9}>
            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bolder",
              }}
            >
              {this.lang.SETUP.LABELS.TITLE}
            </p>
          </Col>
          <Col span={2}></Col>
        </Row>
      );

      this.CustomRow3 = (
        <Row>
          <Col span={4}>
            <Button
              type="danger"
              onClick={() => {
                this.setState({ hallDocumentsList: false });
              }}
            >
              <Icon type="arrow-left" />
            </Button>
          </Col>
          <Col span={3}></Col>
          <Col span={8}>
            <h1 style={{ fontSize: "18px" }}>
              {this.lang.UPDATE.LABELS.TITLE}
            </h1>
          </Col>
          <Col span={5}></Col>
          <Col span={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <Icon type="save" />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      );
    }
  }

  handleSubmitHallPaymentLineUpdate = () => {};

  render() {
    const { getFieldDecorator } = this.props.form;
    let {
      dir,
      display,
      hallDocumentsList,
      contractors,
      payment_category,
      payment_type,
      tdata,
      pagination,
    } = this.state;

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.generate_Receipt_Selection(selectedRows);
      },
    };

    return (
      <Spin tip={this.lang.SETUP.LABELS.SPINMESSAGE} spinning={this.state.spin}>
        <>
          {hallDocumentsList === true ? (
            <Form
              onSubmit={this.handleSubmitHallPaymentLine}
              style={{ marginTop: "20px" }}
            >
              {this.CustomRow}
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.PAYMENTCATEGORY}>
                    {getFieldDecorator("payment_category", {
                      rules: [{ required: true, message: "" }],
                    })(
                      <Select>
                        {payment_category.length > 0 &&
                          payment_category.map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                              {localStorage.getItem("lang") === "EN"
                                ? item.name
                                : item.name_ar}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.PAYMENTTYPE}>
                    {getFieldDecorator("payment_type", {
                      rules: [
                        {
                          required: true,
                          message: "Please Select Unit of Measure",
                        },
                      ],
                    })(
                      <Select>
                        {payment_type.length > 0 &&
                          payment_type.map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                              {localStorage.getItem("lang") === "EN"
                                ? item.name
                                : item.name_ar}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : hallDocumentsList === false ? (
            //View Hall Payment Screen
            <>
              {/* {this.CustomRow2} */}
              {this.lang.SETUP._DIRECTION === "ltr" && (
                <Row gutter={24}>
                  <Col span={15}></Col>
                  <Col
                    span={4}
                    style={{ paddingLeft: "3.2%", paddingTop: "1%" }}
                  >
                    <Button
                      type="primary"
                      icon="download"
                      size={"default"}
                      disabled={!this.state.canCombReport}
                      onClick={() => {
                        this.download_Receipt();
                      }}
                    >
                      {this.lang.SETUP.BUTTON.RECEIPTBUTTON}
                    </Button>
                  </Col>
                </Row>
              )}
              {this.lang.SETUP._DIRECTION === "rtl" && (
                <Row gutter={24}>
                  <Col span={6} style={{ paddingTop: "1%" }}>
                    <Button
                      type="primary"
                      icon="download"
                      size={"default"}
                      disabled={!this.state.canCombReport}
                      onClick={() => {
                        this.download_Receipt();
                      }}
                    >
                      {this.lang.SETUP.BUTTON.RECEIPTBUTTON}
                    </Button>
                  </Col>
                  <Col span={15}></Col>
                </Row>
              )}

              <Row id="clientsTable">
                {/* <Col span={2}></Col> */}
                <Col span={23}>
                  <Table
                    rowSelection={rowSelection}
                    columns={this.columns}
                    rowKey="id"
                    onChange={this.handleChangePagination}
                    pagination={pagination}
                    dataSource={tdata}
                    style={{ marginTop: "10px" }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            //Update Hall Payment Screen
            <Form
              onSubmit={this.handleSubmitHallPaymentLineUpdate.bind(this)}
              style={{ marginTop: "20px" }}
            >
              {this.CustomRow3}
              {localStorage.getItem("lang") == "EN" && (
                <Row gutter={24}>
                  {contractors.length > 0 &&
                    contractors.filter(
                      (i) => i.id == this.state.paymentObject.contractor_id
                    ).length > 0 && (
                      <Col span={6}>
                        <Form.Item
                          label={this.lang.SETUP.LABELS.CONTRACTORNAME}
                        >
                          <p>
                            {contractors.length > 0 &&
                              contractors.filter(
                                (i) =>
                                  i.id == this.state.paymentObject.contractor_id
                              ).length > 0 &&
                              contractors.filter(
                                (i) =>
                                  i.id == this.state.paymentObject.contractor_id
                              )[0].name}
                          </p>
                        </Form.Item>
                      </Col>
                    )}
                  <Col span={6}>
                    <Form.Item label={this.lang.SETUP.LABELS.PAYMENTTYPE}>
                      <p>{this.state.paymentObject.payment_type}</p>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={this.lang.SETUP.LABELS.PAMOUNT}>
                      <p>{this.state.paymentObject.amount}</p>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={this.lang.SETUP.LABELS.TAMOUNT}>
                      <p>{this.state.paymentObject.total_amount}</p>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {localStorage.getItem("lang") != "EN" && (
                <Row gutter={24}>
                  {(contractors.length === 0 ||
                    contractors.filter(
                      (i) => i.id == this.state.paymentObject.contractor_id
                    ).length === 0) && <Col span={6}></Col>}
                  <Col span={6}>
                    <Form.Item label={this.lang.SETUP.LABELS.TAMOUNT}>
                      <p>{this.state.paymentObject.total_amount}</p>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={this.lang.SETUP.LABELS.PAMOUNT}>
                      <p>{this.state.paymentObject.amount}</p>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={this.lang.SETUP.LABELS.PAYMENTTYPE}>
                      <p>{this.state.paymentObject.payment_type_ar}</p>
                    </Form.Item>
                  </Col>
                  {contractors.length > 0 &&
                    contractors.filter(
                      (i) => i.id == this.state.paymentObject.contractor_id
                    ).length > 0 && (
                      <Col span={6}>
                        <Form.Item
                          label={this.lang.SETUP.LABELS.CONTRACTORNAME}
                        >
                          <p>
                            {contractors.length > 0 &&
                              contractors.filter(
                                (i) =>
                                  i.id == this.state.paymentObject.contractor_id
                              ).length > 0 &&
                              contractors.filter(
                                (i) =>
                                  i.id == this.state.paymentObject.contractor_id
                              )[0].name_ar}
                          </p>
                        </Form.Item>
                      </Col>
                    )}
                </Row>
              )}
              <Row>
                <Row>
                  {this.state.paymenntLines == "setup" ? (
                    <Button
                      disabled={this.state.disable_payment_transaction}
                      style={{ float: "right", marginBottom: "10px" }}
                      onClick={() => this.setState({ paymenntLines: "create" })}
                    >
                      {this.lang.UPDATE.BUTTON.ADDPAYMENT}
                    </Button>
                  ) : (
                    <Button
                      style={{ float: "left", marginBottom: "10px" }}
                      onClick={() => this.setState({ paymenntLines: "setup" })}
                    >
                      {this.lang.UPDATE.BUTTON.BACK}
                    </Button>
                  )}
                </Row>
                <Row>
                  {this.state.paymenntLines == "setup" ? (
                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={24}>
                        <Table
                          rowKey="id"
                          dataSource={this.state.paymenntLinesData}
                          columns={this.columnsPayments}
                        />
                      </Col>
                    </Row>
                  ) : this.state.paymenntLines == "create" ? (
                    <>
                      <Row gutter={24} dir={dir} style={{ display }}>
                        {/* <Form> */}
                        <Col span={8}>
                          <Form.Item
                            label={this.lang.SETUP.LABELS.PAYMENTAMOUNT}
                          >
                            <InputNumber
                              ref="amount"
                              min={0.0}
                              style={{ width: "100%" }}
                              placeholder={this.lang.SETUP.LABELS.PAYMENTAMOUNT}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label={this.lang.SETUP.LABELS.PAYMENTCATEGORY}
                          >
                            <Select
                              ref="payment_category"
                              style={{ width: "100%" }}
                              placeholder={
                                this.lang.SETUP.LABELS.PAYMENTCATEGORY
                              }
                            >
                              {localStorage.getItem("lang") === "EN"
                                ? payment_category.length > 0 &&
                                  payment_category.map((item) => (
                                    <Select.Option
                                      style={{ textAlign: "left" }}
                                      key={item.id}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </Select.Option>
                                  ))
                                : payment_category.length > 0 &&
                                  payment_category.map((item) => (
                                    <Select.Option
                                      style={{ textAlign: "right" }}
                                      key={item.id}
                                      value={item.id}
                                    >
                                      {item.name_ar}
                                    </Select.Option>
                                  ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label={this.lang.SETUP.LABELS.PAYMENTDATE}>
                            {getFieldDecorator("payment_date", {
                              rules: [
                                {
                                  required: true,
                                  message: this.msg.INSERTPAYMENTDATEERROR,
                                },
                              ],
                            })(
                              <DatePicker
                                value={this.state.payment_date}
                                onChange={(d) =>
                                  this.handleDate(
                                    moment(d).locale("en").format("YYYY-MM-DD")
                                  )
                                }
                                type="text"
                                placeholder={this.lang.SETUP.LABELS.PAYMENTDATE}
                                style={{ width: "100%" }}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        {/* <Col span={24}>
                                <Form.Item >
                                  <Button loading={this.state.add_transaction_loading} onClick={this.addPaymentLines} style={{ width: '100%' }}>{this.lang.UPDATE.BUTTON.ADD}</Button>
                                </Form.Item>
                              </Col> */}
                        {/* </Form> */}
                      </Row>
                      <Row gutter={24} dir={dir} style={{ display }}>
                        <Col span={24}>
                          <Form.Item>
                            <Button
                              loading={this.state.add_transaction_loading}
                              onClick={this.addPaymentLines}
                              style={{ width: "100%" }}
                            >
                              {this.lang.UPDATE.BUTTON.ADD}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row>update</Row>
                  )}
                </Row>
              </Row>
            </Form>
          )}
        </>
      </Spin>
    );
  }
}

export default HallPayments = Form.create({ name: "hall_payment_form" })(
  HallPayments
);
