import React, { Fragment, Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Row, Col, Card, Collapse } from 'antd'
import API_URL from '../../constants'
import ClientDetails from './ClientDetails'
import ClientAccountMonthWise from './ClientAccountMonthWise'
import ClientAccountReservations from "./ClientAccountReservations"
import ClientAccountPayments from './ClientAccountPayments'
const pt = localStorage.getItem('pt');
const { Panel } = Collapse;

class Client_Account extends Component {
    constructor(props) {
        super(props)
        this.state = {
            client: [],
            clientAccount:{}
        }
        this.lang = props.language._CLIENT_ACCOUNT.DETAILS
    }
    componentDidMount() {
        this.fetchClientData()
        let data = {
            "header": "الرقم الضريبي 120000154454", 
            "footer": "الاسعار تشمل القيمة المضافة\n",
            "orderNo": "ORD638",
            "floorName": "false",
            "tableName": "false",
            "printer": "EPSON TM-T20II Receipt",
            "lines": [
                {
                    "name": "برجر",
                    "nameAr": "برجر",
                    "qty": 1
                }
            ]
        }
    }
    fetchClientData = () => {
        let id = localStorage.getItem('clientaccount')
        axios.get(`${API_URL}${pt}/clients/${id}`)
            .then(res => {
                this.setState({ client: res.data.data })
            })
            .catch(err => { console.log(err) })
            axios.get(`${API_URL}${pt}/clientpayments/get_client_acount/?client=${id}`)
            .then(res => {
                if(res.data.success){
                    this.setState({clientAccount:res.data.data})
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        return (
            <Fragment>
                <Row style={{ marginTop: '50px' }}>
                    <Col span={4}></Col>
                    <Col span={16}>
                        <Card>
                            <ClientDetails clientAccount={this.state.clientAccount} client={this.state.client} language={this.lang} />
                            <Collapse defaultActiveKey={['1']} onChange={() =>{}}>
                                <Panel header={this.lang.TABS_TITLE.CLIENT_ACCOUNT_PAYMENTS} key="1">
                                    <ClientAccountPayments fetchClientData={this.fetchClientData}  client={this.state.client.id} language={this.lang.CLIENT_ACCOUNT_PAYMENTS}/>
                                </Panel>
                                <Panel header={this.lang.TABS_TITLE.CLIENT_ACCOUNT_MONTHI_WISE} key="2">
                                    <ClientAccountMonthWise client={this.state.client.id} language={this.lang}/>
                                </Panel>
                                <Panel header={this.lang.TABS_TITLE.CLIENT_ACCOUNT_RESERVATIONS} key="3">
                                    <ClientAccountReservations client={this.state.client.id} language={this.lang}/>
                                </Panel>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

export default Client_Account