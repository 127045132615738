import React, { Component } from 'react';
import { Row, Col, Table, Button, message, Input } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Halls extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // data: {},
      data: [],
      pagination: {},
      page: 1,
      size: 5,
      // sortField:"name",
			sortField:"updated_at",
      sortOrder:"ascend",
      searchValues: {
        name: '',
        name_ar: '',
        hall_type_name: '',
        hall_type_name_ar: '',
        uom_name: '',
        sec_deposit: '',
        banquet_name: '',
        banquet_name_ar : '',
        area: '',
        capacity: '',
        chairs_cnt: '',
        orderBy : '',
        column_name :'name'
      }
    }
    this.lang = props.language._HALL.SETUP;
    this.msg = props.language._HALL.MESSAGE;
    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      } : 
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAMEAR}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAMEAR}
              onChange={this.handleChangeSearchValues("name_ar")}
            />
          </span>
        ),
        dataIndex: 'name_ar',
        key: 'name_ar',
        width: '15%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_arname),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      } : 
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAMEAR}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAMEAR}
              onChange={this.handleChangeSearchValues("name_ar")}
            />
          </span>
        ),
        dataIndex: 'name_ar',
        key: 'name_ar',
        width: '15%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_arname),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.BANQUETNAME}
              <Search
                placeholder={this.lang.TABLEHEADERS.BANQUETNAME}
                onChange={this.handleChangeSearchValues("banquet_name")}
              />
            </span>
          ),
          dataIndex: 'banquet_name',
          key: 'banquet_name',
          width: '15%',
          align : 'left',
          sorter: (a, b) => this.compareByAlph(a.banquet_name, b.banquet_name),
        }
        :
        {
          title: (
            <span>{this.lang.TABLEHEADERS.BANQUETNAME}
              <Search
                placeholder={this.lang.TABLEHEADERS.BANQUETNAME}
                onChange={this.handleChangeSearchValues("banquet_name_ar")}
              />
            </span>
          ),
          dataIndex: 'banquet_name_ar',
          key: 'banquet_name_ar',
          width: '15%',
          align : 'right',
          sorter: (a, b) => this.compareByAlph(a.banquet_name, b.banquet_name),
        },
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.HALLTYPE}
              <Search
                placeholder={this.lang.TABLEHEADERS.HALLTYPE}
                onChange={this.handleChangeSearchValues("hall_type_name")}
              />
            </span>
          ),
          dataIndex: 'hall_type_name',
          key: 'hall_type_name',
          width: '15%',
          align : 'left',
          sorter: (a, b) => this.compareByAlph(a.hall_type_name, b.hall_type_name),
        }
        :
        {
          title: (
            <span>{this.lang.TABLEHEADERS.HALLTYPE}
              <Search
                placeholder={this.lang.TABLEHEADERS.HALLTYPE}
                onChange={this.handleChangeSearchValues("hall_type_name_ar")}
              />
            </span>
          ),
          dataIndex: 'hall_type_name_ar',
          key: 'hall_type_name_ar',
          width: '15%',
          align : 'right',
          sorter: (a, b) => this.compareByAlph(a.hall_type_name, b.hall_type_name),
        }
      ,
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.CAPACITY}
            <Search
              placeholder={this.lang.TABLEHEADERS.CAPACITY}
              onChange={this.handleChangeSearchValues("capacity")}
            />
          </span>
        ),
        dataIndex: 'capacity',
        key: 'capacity',
        width: '18%',
        align : 'center',
        sorter: (a, b) => this.compareByAlph(a.capacity, b.capacity),
        render: (text, record) =><div style={{textAlign :'center'}}><p>{text}</p></div>,
      } :
      {
        title: (
          <span>{this.lang.TABLEHEADERS.CAPACITY}
            <Search
              placeholder={this.lang.TABLEHEADERS.CAPACITY}
              onChange={this.handleChangeSearchValues("capacity")}
            />
          </span>
        ),
        dataIndex: 'capacity',
        key: 'capacity',
        width: '18%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.capacity, b.capacity),
        render: (text, record) =><div style={{textAlign :'center'}}><p>{text}</p></div>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.CHAIR}
            <Search
              placeholder={this.lang.TABLEHEADERS.CHAIR}
              onChange={this.handleChangeSearchValues("chairs_cnt")}
            />
          </span>
        ),
        dataIndex: 'chairs_cnt',
        key: 'chairs_cnt',
        width: '12%',
        align : 'center',
        sorter: (a, b) => this.compareByAlph(a.chairs_cnt, b.chairs_cnt),
        render: (text, record) =><div style={{textAlign :'center'}}><p>{text}</p></div>,
      } :
      {
        title: (
          <span>{this.lang.TABLEHEADERS.CHAIR}
            <Search
              placeholder={this.lang.TABLEHEADERS.CHAIR}
              onChange={this.handleChangeSearchValues("chairs_cnt")}
            />
          </span>
        ),
        dataIndex: 'chairs_cnt',
        key: 'chairs_cnt',
        width: '12%',
        align: 'right',
        sorter: (a, b) => this.compareByAlph(a.chairs_cnt, b.chairs_cnt),
        render: (text, record) =><div style={{textAlign :'center'}}><p>{text}</p></div>,
      } ,
      // {
      //   title: (
      //     <span>Arabic Name
      //       <Search
      //         placeholder="Search arabic name"
      //         onSearch={value => console.log(value)}
      //         onChange={this.handleChangeSearchValues("name_ar")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'name_ar',
      //   key: 'name_ar',
      //   width: '15%',
      //   sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
      // },

      // {
      //   title: (
      //     <span>Unit of Measure
      //       <Search
      //         placeholder="Search unit of measure"
      //         onSearch={value => console.log(value)}
      //         onChange={this.handleChangeSearchValues("uom_name")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'uom_name',
      //   key: 'uom_name',
      //   width: '15%',
      //   sorter: (a, b) => this.compareByAlph(a.uom_name, b.uom_name),
      // },
      // {
      //   title: (
      //     <span>Security Deposit
      //       <Search
      //         placeholder="Search sec. deposite"
      //         onSearch={value => console.log(value)}
      //         onChange={this.handleChangeSearchValues("sec_deposit")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'sec_deposit',
      //   key: 'sec_deposit',
      //   width: '15%',
      //   sorter: (a, b) => this.compareByAlph(a.sec_deposit, b.sec_deposit),
      // },
      // {
      //   title: (
      //     <span>Area
      //       <Search
      //         placeholder="Search area"
      //         onSearch={value => console.log(value)}
      //         onChange={this.handleChangeSearchValues("area")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'area',
      //   key: 'area',
      //   width: '15%',
      //   sorter: (a, b) => this.compareByAlph(a.area, b.area),
      // },

      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '8%',
        align : 'center',
        key: 'last_name',
      }
    ];
  }

  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    localStorage.setItem('hallToUpdate', JSON.stringify(record))
    this.props.history.push('/dashboard/updatehall')

  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      pageNumber: pagination.current
    });

    if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
      if (this.state.pageNumber  !== pager.current) {
        this.fetch({
          page: pagination.current,
        });
      }
    } else {
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }
    /*let pager = { ...this.state.pagination };
    pager.current = pagination.current;

    let searchValues = { ...this.state.searchValues}
    searchValues.column_name = sorter.field
    searchValues.orderBy = sorter.order

    this.setState({
      page: pager.current,
      pagination: pager,
      searchValues
    }, () => {
      this.fetch();
    })*/

  }//End of Method

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/halls/${record.id}`,{headers})
      .then(res => {  //console.log('Hall delete res.data ->',res.data);
        if (res.data.success) {
          message.success(this.msg.HALLDELETESUCCESS);
          this.fetch();
        }
        else if(res.data.code === '001'){
          message.error(res.data.message,3);
        }
        else
          message.error(this.msg.HALLDELETEFAILURE,3);
      })
      .catch(err => {
        if(err.data.code === '001'){
          message.error(err.data.message,3);
        }
        else{
          message.error(this.msg.HALLDELETEFAILURE,3);
          console.log('Error occured while deleting Hall->',err);
        }
      });
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = (params={}) => {
    const headers = getHeadersForHttpReq();
    const client_id = localStorage.getItem('client_id');
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else { sortOrder = 'DESC' }
    
    let data = {
      client_id : client_id,
      name: this.state.searchValues.name || '',
      name_ar: this.state.searchValues.name_ar || '',
      hall_type_name: this.state.searchValues.hall_type_name || '',
      hall_type_name_ar: this.state.searchValues.hall_type_name_ar || '',
      uom_name: this.state.searchValues.uom_name || '',
      sec_deposit: this.state.searchValues.sec_deposit || '',
      banquet_name: this.state.searchValues.banquet_name || '',
      banquet_name_ar : this.state.searchValues.banquet_name_ar || '',
      area: this.state.searchValues.area || '',
      capacity: this.state.searchValues.capacity || '',
      chairs_cnt: this.state.searchValues.chairs_cnt || '',
      
      page,
      sortColumn: params.sortField || this.state.sortField ,
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'no'
    }
    // console.log('Sending data to api ->',data);
    axios.post(`${complete_URL}/halls/pagination_table`,data, {headers})
    .then(response => {
      // console.log('response of exp new pagination ->',response)
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      this.setState({ data: response.data.data, pagination })
      // console.log('Expense data-> ',response);
    })
    .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })
    /*axios.get(`${complete_URL}/halls/table?id=${client_id}&page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`,{headers})
      .then(res => {
        // console.log(res.data);
        let pagination = { ...this.state.pagination };
        pagination.total = res.data.totalElements;
        pagination.pageSize = parseInt(res.data.size);
        this.setState({
          data: res.data,
          pagination: pagination,
        })
      })
      .catch(err => { console.log(err) })*/
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={6}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
        <Col span={16}>
          <Link to="/dashboard/addhall">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={6}>
          <Link to="/dashboard/addhall">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
        <Col span={17}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
      </Row>
    }
  }

  render() {
    return (
        <>
          {this.CustomRow}
          <Row id="clientsTable" style={{ overflow: 'scroll' }}>
            {/* <Col span={2}></Col>
              <Col span={20}> */}
              <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                <Table
                  columns={this.columns}
                  rowKey="id"
                  // dataSource={this.state.data.selectedElements}
                  dataSource={this.state.data}
                  onChange={this.handleChangePagination}
                  pagination={this.state.pagination}
                />
              </Col>
          </Row>
        </>
    )
  }
}
export default Halls;