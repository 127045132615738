import React, { Component } from 'react';
import { Table, Row, Col, Form, Icon, Button, Input, Card, message } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class ModuleUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      screens: [],
      moduleData: {},
      assignedScreen: [],
      updateScreens: [],
      selectedScreenToAssign: '',
      SelectedScreenToRemove: []
    }
    this.lang = props.language._MODULE.UPDATE

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.LABELS.SCREENNAME}`,
          dataIndex: 'name',
        } : {
          title: `${this.lang.LABELS.SCREENNAME}`,
          dataIndex: 'name_ar',
        },
      {
        title: `${this.lang.LABELS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '30%',
        key: 'last_name',
      }]
  }
  componentDidMount() {
    const headers = getHeadersForHttpReq();
    let moduleData = JSON.parse(localStorage.getItem('updateModule'))
    // console.log(moduleData)
    var screens = []
    axios.get(`${complete_URL}/screens`,{headers})
      .then(res => { /*console.log(res.data)*/
        screens = res.data
      })
      .catch(err => { console.log(err) })

    // var clientId = localStorage.getItem('client_id')

    // let temarr = []
    axios.get(`${complete_URL}/screens/get_screens_by_modules/${moduleData.id}`, {headers})
      .then(result => {
        this.setState({ assignedScreen: result.data[0], screens: screens })
      })
    setTimeout(() => {
      var result1 = screens
      var result2 = this.state.assignedScreen
      var props = ['id', 'name'];
      // console.log(result1)
      // console.log(result2)
      var result = result1.filter(function (o1) {
        // filter out (!) items in result2
        return !result2.some(function (o2) {
          return o1.id === o2.id;          // assumes unique id
        });
      }).map(function (o) {
        // use reduce to make objects with only the required properties
        // and map to apply this to the filtered array as a whole
        return props.reduce(function (newo, name) {
          newo[name] = o[name];
          return newo;
        }, {});
      });

      setTimeout(() => {
        this.setState({ screens: result })
      }, 500)
    }, 500)
    setTimeout(() => {
      this.setState({ screens, moduleData })
    }, 500)

  }
  deleteCategory(record) {
    let tempArray = [...this.state.SelectedScreenToRemove]
    tempArray.push(record)
    let tempArray2 = [...this.state.assignedScreen]
    let array3 = [...this.state.screens]

    for (let a = 0; a <= tempArray2.length - 1; a++) {
      if (tempArray2[a].id === record.id) {
        array3.push(tempArray2[a])
        tempArray2.splice(a, 1)
      }
    }
    this.setState({ SelectedScreenToRemove: tempArray, assignedScreen: tempArray2, screens: array3 })
  }
  seclectScreen = (v) => {
    // console.log(v)
    this.setState({ selectedScreenToAssign: v })
  }
  assignScreenAdd = () => {
    var screens = JSON.parse(localStorage.getItem('screenList'))
    let tempArray = [...this.state.assignedScreen]
    let tempArray2 = [...this.state.screens]
    let tempArray3 = [...this.state.updateScreens]
    for (let a = 0; a <= screens.length - 1; a++) {
      if (screens[a].id === this.state.selectedScreenToAssign) {

        tempArray.push(screens[a])
        tempArray3.push(screens[a])
      }
    }
    for (let a = 0; a <= this.state.screens.length - 1; a++) {
      this.state.screens[a].id === this.state.selectedScreenToAssign && tempArray2.splice(a, 1)
    }
    document.getElementsByClassName("ant-select-selection-selected-value").length > 0
      ?
      document.getElementsByClassName("ant-select-selection-selected-value")[0].innerHTML = ""
      :
      console.log()
    this.setState({ assignedScreen: tempArray, screens: tempArray2, updateScreens: tempArray3 })
    setTimeout(() => { console.log(this.state) }, 1000)
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const headers = getHeadersForHttpReq();
      let valuesUpdate = { ...this.state.moduleData }
      let user_id = localStorage.getItem('user_id')
      values.user_id = user_id
      // console.log(values)
      axios.put(`${complete_URL}/modules/${valuesUpdate.id}`, values,{headers})
        .then(res => {
          // console.log(res)
        })
        .catch(err => {
          // console.log(err)
        })
      for (let a = 0; a <= this.state.SelectedScreenToRemove.length - 1; a++) {
        let screenId = this.state.SelectedScreenToRemove[a].id
        axios.post(`${complete_URL}/modulesscreens/delete`,
          { screen_id: screenId, module_id: valuesUpdate.id }, {headers})
          .then(res => {
            console.log(res)
          })
          .catch(err => {
            console.log(err)
          })
      }
      setTimeout(() => { this.props.history.push('/dashboard/modules') })
    })
  }

  deleteUser = () => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/modules/${JSON.parse(localStorage.getItem('updateModule')).id}`,{headers})
      .then(res => {
        this.props.history.push('modules')
        message.success('Module Has been Deleted')
      })
      .catch(err => {
        message.error('Module can not be Deleted')
      })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={7}>
          <Link to="/dashboard/modules">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={7}>
          <h1>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={10}>
          <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Button className="delete" type="" onClick={this.deleteUser} title="delete"><Icon type="delete" theme="filled" /></Button>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col className="btn-box" span={10}>
          <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Button className="delete" type="" onClick={this.deleteUser} title="delete"><Icon type="delete" theme="filled" /></Button>
        </Col>
        <Col span={7}>
          <h1>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col span={7}>
          <Link to="/dashboard/modules">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      initialValue: this.state.moduleData.name,
                      rules: [{ required: true, message: 'Please input your Name!' }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} />
                    )}

                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      initialValue: this.state.moduleData.name_ar,
                      rules: [{ required: false, message: 'Please input your Arabic Name!' }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NAME} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.DESCRIPTION}>
                    {getFieldDecorator('description', {
                      initialValue: this.state.moduleData.description,
                      rules: [{ required: false, message: 'Please input your Arabic Name!' }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.DESCRIPTION} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/* <Form.Item>
                                {getFieldDecorator('screen', {
                                    rules: [{ required: false, message: 'Please input your password!' }],
                                })(
                                    <Row gutter={8}>
                                        <Col span={4}>Select Screens:</Col>
                                        <Col span={12}>
                                            <Select onChange={this.seclectScreen}>
                                                {
                                                    this.state.screens.map(screen => (
                                                        <Select.Option key={screen.id}  value={screen.id}>{screen.name}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Col>
                                        <Col span={4}>
                                            <Button type="ghost" onClick={this.assignScreenAdd}>Add</Button>
                                        </Col>
                                    </Row>
                                )}
                            </Form.Item> */}
              {
                this.state.assignedScreen.length > 0 &&
                <Form.Item>
                  <Row id="clientsTable">
                    <Col span={24}>
                      <Table columns={this.columns} rowKey="id" dataSource={this.state.assignedScreen} />,
                    </Col>
                  </Row>
                </Form.Item>
              }
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row >
    )
  }
}

export default ModuleUpdate = Form.create({ name: 'normal_login' })(ModuleUpdate);