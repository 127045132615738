import React, { Component, } from 'react';
import { Table, Select, Row, Avatar, Col, Form, Icon, Button, Input, Card, message ,InputNumber , Switch} from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios'
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class ClientUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      client: {},
      imgfile: {},
      assignedModules: [],
      modules: [],
      selectedModuleToAssign: '',
      updateModules: [],
      SelectedModulesToRemove: [],
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._CLIENT.UPDATE;
    this.msg = props.language._CLIENT.MESSAGE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.LABELS.MODULENAME}`,
        dataIndex: 'name',
        align :'left'
      }: 
      {
        title: `${this.lang.LABELS.MODULENAME}`,
        dataIndex: 'name_ar',
        align :'right'
      },
      {
        title: `${this.lang.LABELS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '30%',
        align : 'center',
        key: 'last_name',
      }
    ]
  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    this.fetch();
    this.setComponentDirection();
  }
  //?? Delete method defination is only to remove from State Array at frontend not from backend 
  deleteCategory = (record) => {
    let tempArray = [...this.state.SelectedModulesToRemove]
    tempArray.push(record)
    let tempArray2 = [...this.state.assignedModules]
    let array3 = [...this.state.modules]

    for (let a = 0; a <= tempArray2.length - 1; a++) {
      if (tempArray2[a].id === record.id) {
        array3.push(tempArray2[a])
        tempArray2.splice(a, 1)
      }
    }
    this.setState({ SelectedModulesToRemove: tempArray, assignedModules: tempArray2, modules: array3 })
  }

  fetch = () => {
    let  headers = getHeadersForHttpReq();

    let id = localStorage.getItem('updateClientId')
    axios.get(`${complete_URL}/clients/${id}`,{headers})
      .then(res => { /*console.log(res.data.data)*/
        this.setState({ client: res.data.data })
      })
      .catch(err => { console.log(err) })
    axios.get(`${complete_URL}/modules/`,{headers})
      .then(res => { //console.log('In ClientUpdate screen~ Received Modules ->',res.data);
        this.setState({ modules: res.data })
      })
      .catch(err => { console.log(err) })
    axios.get(`${complete_URL}/modules/get_modules_by_clients/${id}`,{headers})
      .then(res => { /*console.log(res.data[0])*/
        this.setState({ assignedModules: res.data[0] })
      })
      .catch(err => { console.log(err) })
    setTimeout(() => {
     axios.get(`${complete_URL}/images/${this.state.client.logo_img}`, { responseType: 'blob' },{headers})
        .then(res => {
          let a = { ...this.state.client }
          var reader = new FileReader();
          reader.readAsDataURL(res.data);
          reader.onloadend = () => {
            a.logo_url = reader.result
          }
          /*console.log(a)*/
          setTimeout(() => {
            this.setState({ client: a })
          })
        })
        .catch(err => { console.log(err) })
    }, 600)

    
    setTimeout(() => {
      var result1 = this.state.modules
      var result2 = this.state.assignedModules
      var props = []
      if (localStorage.getItem('lang') === 'EN') {
        props = ['id', 'name'];
      } else {
        props = ['id', 'name_ar'];
      }
      var result = result1.filter(function (o1) {
        return !result2.some(function (o2) {
          return o1.id === o2.id;
        });
      }).map(function (o) {
        return props.reduce(function (newo, name) {
          newo[name] = o[name];
          return newo;
        }, {});
      });
      setTimeout(() => {
        this.setState({ modules: result })
      }, 300)
    }, 500)
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      var id = localStorage.getItem('updateClientId')
      values.logo_img = this.state.client.imgUrl
      values.iscommission = values.iscommission ? 1 : 0
      // console.log(values)
      const headers = getHeadersForHttpReq();

      axios.put(`${complete_URL}/clients/${id}`, values,{headers})
        .then(res => { /*console.log(res)*/
          this.props.history.push('/dashboard/clients')
        })
        .then(err => { console.log('Error occured while updating the Client->',err) })

      for (let i = 0; i <= this.state.assignedModules.length - 1; i++) {
        let data = {
          client_id: id,
          module_id: this.state.assignedModules[i].id,
          user_id: localStorage.getItem('user_id'),
          created_by: localStorage.getItem('user_id'),
          updated_by: localStorage.getItem('user_id')
        }
        /*console.log(data)*/
        axios.post(`${complete_URL}/moduleclients/register`, data, {headers})
          .then(res => {
            // console.log(res)
          })
          .catch(mod_cl_err => {
            console.log('Error occured while adding the Modules to Client->',mod_cl_err);
          })
      }
      for (let a = 0; a <= this.state.SelectedModulesToRemove.length - 1; a++) {
        let moduleId = this.state.SelectedModulesToRemove[a].id
        /*console.log(moduleId, '  ', id)*/
        
        axios.post(`${complete_URL}/moduleclients/delete`,
          { client_id: id, module_id: moduleId }, {headers})
          .then(res => { /*console.log(res)*/
          })
          .catch(err => { console.log(err) })
      }
      setTimeout(() => { this.props.history.push('/dashboard/clients') })
    })
  }

  handleImageUpload = (e) => {
    e.preventDefault();
    /*console.log(e)*/
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      var file = e.currentTarget.files[0];
      let fileName = file.name;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (file.size <= 1024 * 1024 * 2 && /png|jpe?g/i.test(extension)) {
        var a = { ...this.state.client }
        let imgdata = new FormData();
        imgdata.append('file', file)
        imgdata.append('client_id', this.state.client.id)
        imgdata.append('client_name', this.state.client.name)
        let headers = {
          'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
          'Content-Type': 'multipart/form-data',
        }
        let _self = this;
        axios.post(`${complete_URL}/images/add`, imgdata, { responseType: 'blob' }, {headers})
          .then(res => {
            let reader = new FileReader();
            /*console.log(res)*/
            reader.readAsDataURL(res.data);
            reader.onloadend = () => {
              a.logo_url = reader.result;
              _self.setState({ client: a });
            }
            // setTimeout(() => {
            //   this.setState({ client: a, }) }
            //   , 500)
            message.success(this.msg.IMAGEADDSUCCESS);
          })
          .catch(err => { 
            console.log('Error occured while uploading the Client Image->',err) });
            message.error(this.msg.IMAGEADDFAILURE,3);
            
      }//End of file size & extenssion check
    }//End of check file exists
  }

  seclectModule = (v) => {
    /*console.log(v)*/
    this.setState({ selectedModuleToAssign: v })
  }

  assignModuleAdd = () => {
    let modules = this.state.modules
    let tempArray = [...this.state.assignedModules]
    let tempArray2 = [...modules]
    let tempArray3 = [...this.state.updateModules]
    for (let a = 0; a <= modules.length - 1; a++) {
      if (modules[a].id === this.state.selectedModuleToAssign) {
        tempArray.push(modules[a])
        tempArray3.push(modules[a])
      }
    }
    for (let a = 0; a <= this.state.modules.length - 1; a++) {
      this.state.modules[a].id === this.state.selectedModuleToAssign && tempArray2.splice(a, 1)
    }
    document.getElementsByClassName("ant-select-selection-selected-value").length !== 0
      ?
      document.getElementsByClassName("ant-select-selection-selected-value")[0].innerHTML = ""
      :
      console.log()
    this.setState({ assignedModules: tempArray, modules: tempArray2, updateModules: tempArray3 })
    setTimeout(() => {
      console.log(/*this.state*/)
    }, 1000)
  }
  // Extra method not being called
  deleteUser = () => {
    const client_id = localStorage.getItem('updateClientId');
    if(client_id) {
      const headers = getHeadersForHttpReq();

      axios.delete(`${complete_URL}/clients/${localStorage.getItem('updateClientId')}`,{headers})
        .then(res => { /*console.log(res)*/
          message.success(this.msg.DELETESUCCESS);
          this.props.history.push('clients')
        })
        .catch(err => {
          console.log('Error occured in deleting the Client account->',err);
          message.error(this.msg.DELETEFAILURE,3);
        })
    }
    else{ message.error(this.msg.CLIENTNOTFOUND,3); }
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/clients">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={14}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/clients">
            <Button style={{margin: '16px auto'}} className="delete" type="" /*onClick={this.deleteUser}*/ title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/clients">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to="/dashboard/clients">
            <Button style={{margin: '16px auto'}} className="delete" type="" /*onClick={this.deleteUser} */ title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      initialValue: this.state.client.name,
                      rules: [{ required: true, message:  this.msg.INSERTNAMEERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} type="text" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.DESCRIPTION}>
                    {getFieldDecorator('description', {
                      initialValue: this.state.client.description,
                      rules: [{ required: true, message: this.msg.INSERTDESCERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.DESCRIPTION} type="text" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.EMAIL}>
                    {getFieldDecorator('email', {
                      initialValue: this.state.client.email,
                      rules: [{ required: true, message: this.msg.INSERTEMAILERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.EMAIL} type="email" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ADDRESS}>
                    {getFieldDecorator('address', {
                      initialValue: this.state.client.address,
                      rules: [{ required: true, message: this.msg.INSERTADDRESSERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.ADDRESS} type="text" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.SELECTMODULE}>
                    {getFieldDecorator('screen', {
                      rules: [{ required: false, message: 'Please select the Module!' }],
                    })(
                      <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                        <Col span={17}>
                          {
                            <Select onChange={this.seclectModule} placeholder ={this.lang.LABELS.SELECTMODULE}>
                              {
                                (localStorage.getItem('lang') === 'EN') ?
                                this.state.modules.length > 0 && 
                                    this.state.modules.map(item => (
                                          <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                    )) :
                                this.state.modules.length > 0 && 
                                    this.state.modules.map(item => (
                                          <Select.Option style={{textAlign:'right'}} key={item.id} value={item.id}>{item.name_ar}</Select.Option>
                                    ))       
                              }
                            </Select>

                            // (localStorage.getItem('lang') === 'EN') ?
                            //   <Select onChange={this.seclectModule}>
                            //     {
                            //       this.state.modules.length > 0 && this.state.modules.map(item => (
                            //         <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            //       ))
                            //     }
                            //   </Select>
                            //   : <Select onChange={this.seclectModule}>
                            //     {
                            //       this.state.modules.length > 0 && this.state.modules.map(item => (
                            //         <Select.Option key={item.id} value={item.id}>{item.name_ar}</Select.Option>
                            //       ))
                            //     }
                            //   </Select>
                          }
                        </Col>
                        <Col span={7}>
                          <Button type="ghost" onClick={this.assignModuleAdd}>{this.lang.BUTTON.ADD}</Button>
                        </Col>
                      </Row>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {
                this.state.assignedModules.length > 0 &&
                <Form.Item>
                  <Row id="clientsTable">
                    <Col span={24}>
                      <Table columns={this.columns} rowKey="id" dataSource={this.state.assignedModules} />
                    </Col>
                  </Row>
                </Form.Item>
              }
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={24}>
                  <Form.Item>
                    <p>{this.lang.LABELS.FILEUPLOAD}</p>
                    {getFieldDecorator('file', {
                      rules: [{ required: false, message: this.msg.IMAGETYPEERROR }],
                    })(
                      <Row>
                        <Avatar size={80} src={this.state.client.logo_url || '/logo.png'} alt="log here" />
                        <Input type="file" id="file" className="file" onChange={this.handleImageUpload} />
                      </Row>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.COMMISSION}>
                    {getFieldDecorator('commission', {
                      initialValue: this.state.client.commission,
                      rules: [{ required: false, message: this.msg.INSERTCOMMAMOUNTERROR }],
                    })(
                      <InputNumber  min={0} max={100} style={{width:'100%'}} placeholder={this.lang.LABELS.COMMISSION} />

                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ISCOMMISSION}>
                    {getFieldDecorator('iscommission', {
                      valuePropName: 'checked',
                      initialValue: this.state.client.iscommission == 1 ? true : false,
                      rules: [{ required: false, message: '' }],
                    })(
                      <Switch  placeholder={this.lang.LABELS.ISCOMMISSION} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={5}></Col>
      </Row>
      
    )
  }
}

export default ClientUpdate = Form.create({ name: 'normal_login' })(ClientUpdate);