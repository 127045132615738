import React, { Component } from 'react'
import { Row, Col,InputNumber, Form, Icon, Button, Input, Card, Radio, message, Collapse,Select, Table} from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios'
import API_URL from '../../constants'
import getHeadersForHttpReq from '../../constants/token';
import TextArea from 'antd/lib/input/TextArea';
const Panel = Collapse.Panel;
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class NotificationUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notification_type:[],
      message_type:[],
      notification_schedule_type:[],
      notification: [],
      isSMSDisabled: true,
      isEMAILDisabled: true,
      recipientList: false,
      schedule_type:"true",
      recipients: ''
    }
    this.lang = props.language._NOTIFICATIONS.UPDATE;
    this.msg = props.language._NOTIFICATIONS.MESSAGE;
    this.lang1 = props.language._NOTIFICATIONS.UPDATE.RECIPIENT;
    this.msg1 = props.language._NOTIFICATIONS.UPDATE.RECIPIENT.MESSAGE;


    this.columns = [
      (this.state.isSMSDisabled === true) ?
        (localStorage.getItem('lang') === 'EN') ?
          {
            title: `${this.lang1.SETUP.LABELS.EMAIL}`,
            dataIndex: 'email',
            align : 'left',
            render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
          }:
          {
            title: `${this.lang1.SETUP.LABELS.EMAIL}`,
            dataIndex: 'email',
            align : 'right',
            render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
          }
        :
        (localStorage.getItem('lang') === 'EN') ?
          {
            title: `${this.lang1.SETUP.LABELS.SMS}`,
            dataIndex: 'sms',
            align : 'left',
            render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
          }:
          {
            title: `${this.lang1.SETUP.LABELS.SMS}`,
            dataIndex: 'sms',
            align : 'right',
            render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
          },
        {
          title: `${this.lang1.SETUP.LABELS.ACTION}`,
          align : 'center',
          render: (text, record) => (
            <span>
              <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang1.SETUP.BUTTON.DELETE}</Button>
            </span>
          ),
        }
    ]
  }

  componentDidMount(){
    this.fetch();
    this.setComponentDirection();
  }

  fetch =()=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/message_type`,{headers})
    .then(res => {
      this.setState({message_type:res.data.data})
    })
    .catch(msg_typ_err=>{console.log('Error occured while fetching Message Types->',msg_typ_err)})

    axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/notification_type`,{headers})
    .then(res => {
      this.setState({notification_type:res.data.data})
    })
    .catch(notf_typ_err=>{console.log('Error occured while fetching Notification Types->',notf_typ_err)})

    axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/notification_schedule_type`,{headers})
    .then(res => {
      
      this.setState({notification_schedule_type:res.data.data},() => {
        let id = JSON.parse(localStorage.getItem('updateNotificationId')).id
        axios.get(`${complete_URL}/notifications/by_id/${id}`,{headers})
          .then(res => {
            // console.log(res)
            this.setState({ notification: res.data[0], schedule_type:this.state.notification_schedule_type.find(item => item.id === res.data[0].SCHEDULE_TYPE) ? this.state.notification_schedule_type.find(item => item.id === res.data[0].SCHEDULE_TYPE).code : 'true' },() =>console.log(this.state.schedule_type))  
            if (this.state.notification.NOTIFICATION_TYPE === 'SMS' || this.state.notification.NOTIFICATION_TYPE === 'رسالة قصيرة') {
              this.setState({ isSMSDisabled: false })
            } 
            else if (this.state.notification.NOTIFICATION_TYPE === 'EMAIL' || this.state.notification.NOTIFICATION_TYPE === 'البريد الإلكتروني') {
              this.setState({ isEMAILDisabled: false })
            }
          })
          .catch(err => {
            console.log('Error occured while getting Notifications by ID->',err);
          })
      })
    })
    .catch(notf_schd_typ_err=>{console.log('Error occured while fetching Notification Schedule Types->',notf_schd_typ_err)})

  }

  handleNavigation = (record) => {
    localStorage.setItem('updateRecipient', JSON.stringify(record))
  }

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();
    // let id = JSON.parse(localStorage.getItem('updateRecipient')).id
    const id = record.id
    axios.delete(`${complete_URL}/recipients/${id}`,{headers})
      .then(res => {
        message.success(this.msg1.DELETESUCCESS);
        this.loadRecipients();
      })
      .catch(err => {
        message.error(this.msg1.DELETEFAILURE,3);
        console.log('Error occured while deleting the Receipient record->',err);
      })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let id = JSON.parse(localStorage.getItem('updateNotificationId')).id;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.user_id = localStorage.getItem('user_id');
        const headers = getHeadersForHttpReq();
        axios.put(`${complete_URL}/notifications/${id}`, values,{headers})
          .then(res => {
            if (this.msg.UPDATESUCCESS/*res.data.success*/) {
              message.success(res.data.message)
              this.props.history.push('notifications')
            } else {
              message.error(this.msg.UPDATEFAILURE,3/*res.data.message*/)
            }
          })
          .catch(err => {
            message.error(this.msg.UPDATEFAILURE,3)
            console.log('Error occured while updating the notification->',err)
          })
      }
    })
  }

  deleteUser = () => {
    const headers = getHeadersForHttpReq();
    let id = JSON.parse(localStorage.getItem('updateNotificationId')).id
    axios.delete(`${complete_URL}/notifications/${id}`,{headers})
      .then(res => {
        this.props.history.push('/dashboard/notifications')
        message.success(this.msg.DELETESUCCESS)
      })
      .catch(err => {
        message.error(this.msg.DELETEFAILURE,3);
        console.log('Error occured while deleting the Notification->',err);
      })
  }

  handleNotficationType = (event) => {
    if (event.target.value === 'SMS' || event.target.value === 'رسالة قصيرة') {
      this.setState({ isSMSDisabled: false, isEMAILDisabled: true })
    } else if (event.target.value === 'EMAIL' || event.target.value === 'البريد الإلكتروني') {
      this.setState({ isEMAILDisabled: false, isSMSDisabled: true })
    }
  }

  loadRecipients = () => {
    const headers = getHeadersForHttpReq();
    const id = JSON.parse(localStorage.getItem('updateNotificationId')).id
    axios.get(`${complete_URL}/recipients/${id}`,{headers})
      .then(res => { /*console.log(res.data.data[0])*/
        this.setState({ recipients: res.data.data })
      })
      .catch(err => { console.log(err) })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.AddRecipientHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ recipientList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang1.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.RecipientTableHeader = <Row id="clientTableHeader">
        <Col span={10}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang1.SETUP.LABELS.TITLE}</p></Col>
        <Col span={13}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ recipientList: true }) }}>{this.lang1.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>

      this.CustomRow3 = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ recipientList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang1.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

    } else {
      this.AddRecipientHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ recipientList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang1.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.RecipientTableHeader = <Row id="clientTableHeader">
        <Col span={8}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ recipientList: true }) }}>{this.lang1.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={15}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang1.SETUP.LABELS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.CustomRow3 = <Row>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang1.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ recipientList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
      </Row>
    }

  }

  HandleSubmitRecipient = (e) => {
    e.preventDefault();
    let id = JSON.parse(localStorage.getItem('updateNotificationId')).id
    const headers = getHeadersForHttpReq();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.notification_id = id;
        values.user_id = localStorage.getItem('user_id');
        console.log(values)
        axios.post(`${complete_URL}/recipients/add`, values,{headers})
          .then(res => {
            if (res.data.success) {
              this.loadRecipients();
              message.success(this.msg1.ADDSUCCESS/*res.data.message*/)
              this.setState({ recipientList: false })
            } else {
              message.error(this.msg1.ADDFAILURE,3/*res.data.message*/)
            }
          })
          .catch(err => {
            message.error(this.msg1.ADDFAILURE,3);
            console.log('Error occured while adding new Receipient->',err)
          });
      }
    });
  }

  setComponentDirection=()=>{
    if (localStorage.getItem('lang') === 'EN') {
      this.setState({dir : 'ltr', display: 'block',textAlign : 'left'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex',textAlign : 'right'})
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {
                (localStorage.getItem('lang') === 'EN') ?
                  <Row className="top-header" gutter={24}>
                    <Col span={5}>
                      <Link to="/dashboard/notifications">
                        <Button type="danger"><Icon type="arrow-left" /></Button>
                      </Link>
                    </Col>
                    <Col span={14}>
                      <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
                    </Col>
                    <Col className="btn-box" span={5} style={{display:'flex'}}>
                      <Button style={{margin: '16px auto'}} className="save" type="submit" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
                      <Link to="/dashboard/notifications">
                        <Button style={{margin: '16px auto'}} className="delete" type="" /*onClick={this.deleteUser}*/ title="delete"><Icon type="delete" theme="filled" /></Button>
                      </Link>
                    </Col>
                  </Row>
                  :
                  <Row className="top-header" gutter={24}>
                    <Col span={4}>
                      <Link to="/dashboard/notifications">
                        <Button type="danger"><Icon type="arrow-left" /></Button>
                      </Link>
                    </Col>
                    <Col span={15}>
                      <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
                    </Col>
                    <Col className="btn-box" span={5} style={{display:'flex'}}>
                      <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
                      <Link to="/dashboard/notifications">
                        <Button style={{margin: '16px auto'}} className="delete" type="" /*onClick={this.deleteUser}*/ title="delete"><Icon type="delete" theme="filled" /></Button>
                      </Link>
                    </Col>
                  </Row>
              }

              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NOTIFICATIONTYPE}                             >
                    {getFieldDecorator('notification_type', {
                      initialValue: this.state.notification.NOTIFICATION_TYPE,
                      rules: [{ required: true, message: this.msg.INSERTNOTFTYPEERROR }],
                    })(
                      <Select  placeholder={this.lang.LABELS.NOTIFICATIONTYPE} >
                        {
                          this.state.notification_type.map(item => {
                          return  <Select.Option style={{textAlign: this.state.textAlign}} key={item.id} id={item.id}>
                              {localStorage.getItem('lang') == 'EN' ? item.name : item.name_ar}    
                            </Select.Option>
                          })
                        }
                      </Select>
                     // <Input onChange={this.handleNotficationType} type="text" placeholder={this.lang.LABELS.NOTIFICATIONTYPE} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.MESSAGETYPE}                             >
                    {getFieldDecorator('message_type', {
                      initialValue: this.state.notification.MESSAGE_TYPE,
                      rules: [{ required: true, message: this.msg.INSERTMSGTYPEERROR }],
                    })(
                      <Select  placeholder={this.lang.LABELS.MESSAGETYPE} >
                        {
                          this.state.message_type.map(item => {
                          return  <Select.Option style={{textAlign: this.state.textAlign}} key={item.id} id={item.id}>
                              {localStorage.getItem('lang') == 'EN' ? item.name : item.name_ar}    
                            </Select.Option>
                          })
                        }
                      </Select>
                      //<Input type="text" placeholder={this.lang.LABELS.MESSAGETYPE} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.HEADINGCONTENT}                             >
                    {getFieldDecorator('heading_content', {
                      initialValue: this.state.notification.HEADING_CONTENT,
                      rules: [{ required: true, message: this.msg.INSERTHEADINGERROR }],
                    })(
                      <TextArea type="text" placeholder={this.lang.LABELS.HEADINGCONTENT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.HEADINGCONTENTAR}                             >
                    {getFieldDecorator('heading_content_ar', {
                      initialValue: this.state.notification.HEADING_CONTENT_AR,
                      rules: [{ required: true, message: this.msg.INSERTHEADINGARERROR }],
                    })(
                      <TextArea dir="rtl" type="text" placeholder={this.lang.LABELS.HEADINGCONTENTAR} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.BODYCONTENT}                             >
                    {getFieldDecorator('body_content', {
                      initialValue: this.state.notification.BODY_CONTENT,
                      rules: [{ required: true, message: this.msg.INSERTBODYERROR }],
                    })(
                      <TextArea type="text" placeholder={this.lang.LABELS.BODYCONTENT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.BODYCONTENTAR}                             >
                    {getFieldDecorator('body_content_ar', {
                      initialValue: this.state.notification.BODY_CONTENT_AR,
                      rules: [{ required: true, message: this.msg.INSERTBODYARERROR }],
                    })(
                      <TextArea dir="rtl" type="text" placeholder={this.lang.LABELS.BODYCONTENTAR} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.EMAILRECIPIENT}                             >
                    {getFieldDecorator('email_recipient', {
                      initialValue: this.state.notification.EMAIL_RECIPIENT,
                      rules: [{ required: (this.state.isEMAILDisabled === true) ? false : true, message: 'Please input Hall Name!' }],
                    })(
                      <TextArea disabled={this.state.isEMAILDisabled} type="text" placeholder={this.lang.LABELS.EMAILRECIPIENT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.SMSRECIPIENT}                             >
                    {getFieldDecorator('sms_recipient', {
                      initialValue: this.state.notification.SMS_RECIPIENT,
                      rules: [{ required: (this.state.isSMSDisabled === true) ? false : true, message: 'Please input Hall Name!' }],
                    })(
                      <TextArea disabled={this.state.isSMSDisabled} type="text" placeholder={this.lang.LABELS.SMSRECIPIENT} />
                    )}
                  </Form.Item>
                </Col>
              </Row> */}
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ISCUSTOMER}>
                    {getFieldDecorator('is_customer', {
                      initialValue: (this.state.notification.IS_CUSTOMER === 1) ? true : false,
                      rules: [{ required: true, message: this.msg.SELECTISCUSTOMERERROR }],
                    })(
                      <Radio.Group>
                        <Radio value={true} key={1}>{(localStorage.getItem('lang') === 'EN') ? 'Yes' : 'نعم فعلا'}</Radio>
                        <Radio value={false} key={2}>{(localStorage.getItem('lang') === 'EN') ? 'No' : 'لا'}</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.SCHEDULETPE}    >
                    {getFieldDecorator('notification_schedule_type', {
                      initialValue: this.state.notification.SCHEDULE_TYPE,
                      rules: [{ required: false, message: this.msg.SELECTSCHEDULETYPE }],
                    })(
                      <Select  onChange={(e) =>{this.setState({schedule_type:this.state.notification_schedule_type.find(item => item.id === e).code})}}   placeholder={this.lang.LABELS.SCHEDULETPE} >
                        {
                          this.state.notification_schedule_type.map(item => {
                          return  <Select.Option style={{textAlign: this.state.textAlign}} key={item.id} id={item.id}>
                              {localStorage.getItem('lang') == 'EN' ? item.name : item.name_ar}    
                            </Select.Option>
                          })
                        }
                      </Select>
                     // <Input onChange={this.handleUpperCase} type="text" placeholder={this.lang.LABELS.MESSAGETYPE} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
              <Col span={12}>
                  <Form.Item label={this.lang.LABELS.DAY}>
                    {getFieldDecorator('day', {
                      initialValue: this.state.notification.DAY,
                      rules: [{ required: this.state.schedule_type != "true" ? true : false, message: '' }],
                    })(
                      <InputNumber disabled={this.state.schedule_type == "true" ? true : false}  min={1} max={this.state.schedule_type == "WEEK" ? 7 : this.state.schedule_type == "MONTH" ? 30 : 0} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Collapse onChange={this.loadRecipients} accordion bordered={false} defaultActiveKey={['']} expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
              <Panel header={this.lang1.TITLE} key="1" id='recipient'>
                {
                  //Create Recipients Screen
                  this.state.recipientList === true ?
                    <Form onSubmit={this.HandleSubmitRecipient} style={{ marginTop: '20px' }}>
                      {this.AddRecipientHeader}
                      <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                        <Col span={12}>
                          {
                            this.state.isSMSDisabled === true ?
                              <Form.Item label={this.lang1.CREATE.LABELS.EMAIL}                             >
                                {getFieldDecorator('email', {
                                  rules: [
                                    {
                                      type: 'email',
                                      message: this.msg1.INVALIDEMAILERROR,
                                    },
                                    {
                                      required: true,
                                      message: this.msg1.INSERTEMAILERROR,
                                    },
                                  ],
                                })(
                                  <Input placeholder={this.lang1.CREATE.LABELS.EMAIL} />
                                )}
                              </Form.Item>
                              :
                              <Form.Item label={this.lang1.CREATE.LABELS.SMS}                             >
                                {getFieldDecorator('sms', {
                                  rules: [{ required: true, message: this.msg1.INSERTCONTACTNOERROR }],
                                })(
                                  <Input type="text" placeholder={this.lang1.CREATE.LABELS.SMS} />
                                )}
                              </Form.Item>
                          }
                        </Col>
                        <Col span={12}></Col>
                        
                      </Row>
                    </Form>
                    :
                    //View Hall Discount Screen
                    <div>
                      {this.RecipientTableHeader}
                      <Row id="recipientsTable">
                        {/* <Col span={2}></Col>
                        <Col span={20}> */}
                        <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                          <Table columns={this.columns} rowKey="id" dataSource={this.state.recipients} style={{ marginTop: '10px' }} />,
                        </Col>
                      </Row>
                    </div>
                    // Update part not written/missing... 
                }
              </Panel>
            </Collapse>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default NotificationUpdate = Form.create({ name: 'notification_update_form' })(NotificationUpdate);