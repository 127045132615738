import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, Card, message, Select, Collapse, InputNumber, Switch } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios';
import ReactGoogleMap from '../ReactGoogleMap/index';
import ReactGoogleAutoComplete from '../ReactGoogleAutoComplete/index';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import ContractorPackages from '../ContractorPackages/ContractorPackages';
import Contractor_Client_assign from './Contractor_Client_assign';
// import MyMapComponent from '../GoogleMaps/Maps';
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const Panel = Collapse.Panel;
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class ContractorUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contractor: '',
      country: [],
      cities: [],
      packages: [],
      service_type: [],
      lat : 23.614328   ,//-91, //[-90 , +90]
      lng : 58.545284   ,//-181, // [-180 , +180] //or we can also set location of OMAN..
      showMap : false,
      editGoogleAddr : false,
      dir : 'ltr', 
      display: 'block'
    }
    this.lang = props.language._CONTRACTOR.UPDATE;
    this.lang1 = props.language._CONTRACTOR.UPDATE.PACKAGE;
    this.lang2 = props.language._CONTRACTOR_CLIENT;
    this.msg  = props.language._CONTRACTOR.MESSAGE;
  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = ()=>{
    let strCont = localStorage.getItem('updateContractor');
    if (strCont) {
      const contractor_id = JSON.parse(strCont).id;
      const client_id = localStorage.getItem('client_id');

      if (contractor_id) {
        // this.getServiceTypes(client_id);//Maybe we should fetch all independent ServiceTypes
        this.getIndependentServiceTypes();// This is for Client USER but as after deployment the screen is going to be for Super Admin
        this.getSelectedContractor(contractor_id); 
        this.getCountries();
        this.setComponentDirection();
      }
      else{
        this.props.history.push('/dashboard');
      }
    }
    else{
      this.props.history.push('/dashboard');
    }
    //fetching old Services stored in Parameters Table
    /*axios.get(`${complete_URL}/parameters/get_byParameter/SERVICE_TYPE`)
      .then(result => {
        this.setState({ service_type: result.data.data[0] })
      })
      .catch(err => { console.log(err) })*/
  }
  
  getServiceTypes = (client_id)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/servicetypes/by_client/${client_id}`,{headers})
      .then(res => { // console.log('service_types->',res)
        this.setState({ service_type: res.data.data })
      })
      .catch(err => {  console.log('Issue while fetching service_types ->',err) })
  }

  //Yet to be called soon after making Contractor and ServiceTypes Independent
  getIndependentServiceTypes = ()=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/servicetypes/independent`,{headers})
      .then(res => { // console.log('service_types->',res.data.data);
        this.setState({ service_type: res.data.data })
      })
      .catch(err => {  console.log('Issue while fetching service_types ->',err) })
  }

  getCountries = ()=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/parameters/get_countries`,{headers})
      .then(res => { // console.log('country->',res.data.data[0]);
        this.setState({ country: res.data.data[0] });
      })
      .catch(err => { console.log('Issue while fetching countries ->',err) })
  }
  
  getSelectedContractor =(contractor_id)=>{
    if (contractor_id) {
      const headers = getHeadersForHttpReq();
      axios.get(`${complete_URL}/contractors/by_id/${contractor_id}`,{headers})
        .then(res => {
          this.setState({ contractor: res.data.data[0] })
          //console.log('getSelectedContractor->',res.data.data[0])
          this.selectCountry(res.data.data[0].country)
        })
        .catch(err => { console.log(err) })
    }
    else{console.log('Contractor id not found!!');}
  }

  selectCountry = (country_id) => {
    if (country_id) {
      const headers = getHeadersForHttpReq();
      axios.get(`${complete_URL}/parameters/child_parameter/${country_id}`,{headers})
        .then(res => { // console.log('cities on selecting country',res.data.data[0])
          this.setState({ cities: res.data.data[0] })
        })
        .catch(err => { console.log('Error occured while fetching cities on selecting country',err) })
    }
    else{console.log('Country id not found for fetching cities!');}
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let client_id = localStorage.getItem('client_id');
    let id = JSON.parse(localStorage.getItem('updateContractor')).id;
    const user_id = localStorage.getItem('user_id');

    if (id && client_id) {
      const headers = getHeadersForHttpReq();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          Object.assign(values,{
            user_id : user_id,
            client_id : client_id,
            map_loc_addr : this.state.contractor.map_loc_addr,
            lat : this.state.contractor.lat,
            lng : this.state.contractor.lng ,
            finalize_map : 0
          });
          // console.log('Update contractor send api',values)
          axios.put(`${complete_URL}/contractors/${id}`, values, {headers})
            .then(res => {// console.log('updated cont data from api ->', res.data);
              if (res.data.success) {
                message.success(this.msg.UPDATESUCCESS);
                this.props.history.push('contractors');
              } 
              else {
                message.error(this.msg.UPDATEFAILURE,3);
              }
            })
            .catch(err => {
              console.log('Error occured while updating the Contractor->',err)
              message.error(this.msg.UPDATEFAILURE,3)
            })
        }
      })
    }
    else{ message.error(this.msg.LOGINERROR,3); }
  }

  fetchPackages = () => {
    const id = JSON.parse(localStorage.getItem('updateContractor')).id;
    if (id) {
      const headers = getHeadersForHttpReq();
      axios.get(`${complete_URL}/packages/${id}`, {headers})
        .then(res => { /*console.log('packages->',res.data.data)*/
          this.setState({ packages: res.data.data })
        })
        .catch(err => { console.log('Error occured while fetching contractor packages by contractor id',err) })
    }
    else{console.log('Contractor ID not found for fetching contractor packages');}
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/contractors">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={12}> <h1 style={{textAlign: 'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={6} style={{display: 'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/contractors">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/contractors">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}> <h1 style={{textAlign: 'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to="/dashboard/expenses">
            <Button  style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button  style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  getGeoLocations =(geo_loc_obj)=>{
    if (geo_loc_obj.hasOwnProperty('lat') && geo_loc_obj.hasOwnProperty('lng')) {
      let contractor_obj = {};
      Object.assign(contractor_obj, {...this.state.contractor,lat : geo_loc_obj.lat, lng : geo_loc_obj.lng, map_loc_addr : geo_loc_obj.address } ) ;
      this.setState({contractor : contractor_obj, lat : geo_loc_obj.lat, lng : geo_loc_obj.lng, showMap : true }/*,()=>{
        console.log('this.state.contractor updated ->',this.state.contractor);
      }*/)
    }
  }
  
  handleEditGeoLocation =()=>{
    this.setState({editGoogleAddr : true});
  }

  render() {

    const { getFieldDecorator } = this.props.form;
    let {dir, display, service_type} = this.state;
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NAME}   >
                    {getFieldDecorator('name', {
                      initialValue: this.state.contractor.name,
                      rules: [{ required: true, message: this.msg.INSERTNAMEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}  >
                    {getFieldDecorator('name_ar', {
                      initialValue: this.state.contractor.name_ar,
                      rules: [{ required: true, message: this.msg.INSERTNAMEARERROR }],
                    })(
                      <Input dir='rtl' type="text" placeholder={this.lang.LABELS.NAMEAR} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.EMAIL}>
                    {getFieldDecorator('email', {
                      initialValue: this.state.contractor.email,
                      rules: [{ required: false, message: this.msg.INSERTEMAILERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.EMAIL} type="email" style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.PHONENO}>
                    {getFieldDecorator('ph_num', {
                      initialValue: this.state.contractor.ph_num,
                      rules: [{ required: true, message: this.msg.INSERTPHONENOERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.PHONENO} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ALTERNATEPHONENO}>
                    {getFieldDecorator('ph_alt_num', {
                      initialValue: this.state.contractor.ph_alt_num,
                      rules: [{ required: false }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.ALTERNATEPHONENO} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.IDCARDNO}    >
                    {getFieldDecorator('id_card', {
                      initialValue: this.state.contractor.id_card,
                      rules: [{ required: true, message: this.msg.INSERTIDNOERROR }],
                    })(
                      <Input type="text" readOnly placeholder={this.lang.LABELS.IDCARDNO} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.SERVICETYPE}   >
                    {getFieldDecorator('new_service_type', {
                      initialValue: this.state.contractor.new_service_type,
                      rules: [{ required: true, message: this.msg.SELECTSERVICETYPEERROR }],
                    })(
                      <Select placeholder={this.lang.LABELS.SERVICETYPE}>
                        {
                          dir ==='ltr' ?
                          service_type.length > 0 && service_type.map(parent => (
                            <Select.Option key={parent.id} value={parent.id}>{parent.name}</Select.Option>
                          )) :
                          service_type.length > 0 && service_type.map(parent => (
                            <Select.Option style={{textAlign:'right'}} key={parent.id} value={parent.id}>{parent.name_ar}</Select.Option>
                          )) 
                        }
                      </Select>)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.COMMERCIALREGNO}>
                    {getFieldDecorator('commercial_reg_no', {
                      initialValue: this.state.contractor.commercial_reg_no,
                      rules: [{ required: false, message: this.msg.INSERTCOMMREGNOERROR }],
                    })(
                      <InputNumber placeholder={this.lang.LABELS.COMMERCIALREGNO} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <h1 style={{ fontSize: '24px' }}>{this.lang.LABELS.ADDRESS}</h1>
                </Col>
                <Col span={12}>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.AREA}   >
                    {getFieldDecorator('area', {
                      initialValue: this.state.contractor.area,
                      rules: [{ required: false, message: this.msg.INSERTAREAERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.AREA} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.STREET}  >
                    {getFieldDecorator('street', {
                      initialValue: this.state.contractor.street,
                      rules: [{ required: false, message: this.msg.INSERTSTREETERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.STREET} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.BUILDINGVILLAHOUSE}                             >
                    {getFieldDecorator('building', {
                      initialValue: this.state.contractor.building,
                      rules: [{ required: false, message: this.msg.INSERTBUILDINGERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.BUILDINGVILLAHOUSE} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.FLAT}   >
                    {getFieldDecorator('flat_no', {
                      initialValue: this.state.contractor.flat_no,
                      rules: [{ required: false, message: this.msg.INSERTFLATNOERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.FLAT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NEARESTPLACE}  >
                    {getFieldDecorator('nearest_place', {
                      initialValue: this.state.contractor.nearest_place,
                      rules: [{ required: false, message: this.msg.INSERTNEARESTPLACEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NEARESTPLACE} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.COUNTRY}  >
                    {getFieldDecorator('country', {
                      initialValue: this.state.contractor.country,
                      rules: [{ required: false, message: this.msg.SELECTCOUNTRYERROR }],
                    })(
                      <Select onChange={this.selectCountry} placeholder={ this.lang.LABELS.COUNTRY}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            this.state.country.map(parent => (
                              <Select.Option key={parent.id} value={parent.id}>{parent.name}</Select.Option>
                            ))
                            : this.state.country.map(parent => (
                              <Select.Option style={{textAlign:'right'}} key={parent.id} value={parent.id}>{parent.name_ar}</Select.Option>
                            ))
                        }
                      </Select>)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.CITY}  >
                    {getFieldDecorator('city', {
                      initialValue: this.state.contractor.city,
                      rules: [{ required: false, message: this.msg.SELECTCITYERROR }],
                    })(
                      <Select placeholder={ this.lang.LABELS.CITY} >
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            this.state.cities.map(city => (
                              <Select.Option key={city.id} value={city.id}>{city.name}</Select.Option>
                            ))
                            : this.state.cities.map(city => (
                              <Select.Option style={{textAlign:'right'}} key={city.id} value={city.id}>{city.name_ar}</Select.Option>
                            ))
                        }
                      </Select>)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display , marginBottom : '20px'}}>
                <Col span={16}>
                    <Form.Item label= {this.lang.LABELS.GOOGLEMAPADDRESS}>
                        {getFieldDecorator('map_loc_addr', {
                          initialValue: this.state.contractor.map_loc_addr,
                          rules: [{ required: false, message: '' }],
                        })(
                         
                          this.state.editGoogleAddr ?
                          <ReactGoogleAutoComplete {...this.props} getGeoLocations = {this.getGeoLocations} />
                          :
                          <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                            <Col span = {20}>
                              <Input type="text" value ={this.state.contractor.map_loc_addr} readOnly = {true} placeholder= {this.lang.LABELS.GOOGLEMAPADDRESS} />
                            </Col>
                            <Col span={4}>
                              <Button onClick={()=>this.handleEditGeoLocation()} >{this.lang.LABELS.EDIT}</Button>
                            </Col>
                          </Row>
                          
                        )}
                      </Form.Item>
                    {
                      this.state.showMap && 
                      <ReactGoogleMap  
                        {...this.props} 
                        lat = {this.state.contractor.lat} 
                        lng = {this.state.contractor.lng} 
                        getGeoLocations = {this.getGeoLocations}
                        is_dynamic = {true}
                      />
                    }
                        
                  </Col>
              </Row>
            </Form>
            <Collapse accordion bordered={false} defaultActiveKey={['']}  expandIconPosition={localStorage.getItem('lang')==='EN' ? 'left':'right'}  expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
              <Panel header={this.lang1.TITLE} key="1" id='package'>
                <ContractorPackages {...this.props} />
              </Panel>
              {
                localStorage.getItem('is_contr') != 1 &&
                <Panel header={this.props.language._CONTRACTOR_CLIENT.TITLE} key = '2' id='client_asign'>
                  <Contractor_Client_assign {...this.props} />
                </Panel>
              }
            </Collapse>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default ContractorUpdate = Form.create({ name: 'contractor_update_form' })(ContractorUpdate);