import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, Card, message } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class AddScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._SCREEN.CREATE;
  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang') === 'EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let data = {
          name: values.name,
          name_ar: values.name_ar,
          url: values.url,
          client_id: localStorage.getItem('client_id'),
          user_id : localStorage.getItem('user_id')
        }
        /*console.log(data)*/
        const headers =  getHeadersForHttpReq();

        axios.post(`${complete_URL}/screens/register`, data,{headers})
          .then(res => {
            /*console.log(res)*/
            if (res.data.success) {
              message.success('Screen Has been Added')
              this.props.history.push('/dashboard/screens')
            }
          })
          .catch(scr_reg_err => {
            message.error('Screen Has not been Added')
            console.log('Error occured while registering the Screen->',scr_reg_err)
          })
      }
    });
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={7}>
          <Link to="/dashboard/screens">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={7}>
          <h1>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={10}>
          <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          {/* <Button type="danger"><Icon type="arrow-left" /></Button> */}
          <Link to="/dashboard/screens">
            <Button className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col className="btn-box" span={10}>
          <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          {/* <Button type="danger"><Icon type="arrow-left" /></Button> */}
          <Link to="/dashboard/screens">
            <Button className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
        <Col span={7}>
          <h1>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col span={7}>
          <Link to="/dashboard/screens">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
      </Row>
    }
  }

  componentDidMount() {
    this.setComponentDirection();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              {/* <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}  className="top-header" >
                <Col span={8}>
                  <Link to="/dashboard/screens">
                    <Button type="danger"><Icon type="arrow-left" /></Button>
                  </Link>
                </Col>
                <Col span={8}>
                  <h1>Add Screens</h1>
                </Col>
                <Col span={8}>
                  <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
                </Col>
              </Row> */}
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }} >
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Please input your Name!' }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} type="text" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      rules: [{ required: true, message: 'Please input your arabic name!' }],
                    })(
                      <Input dir='rtl' type="text" placeholder={this.lang.LABELS.NAMEAR} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }} >
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.URL}>
                    {getFieldDecorator('url', {
                      rules: [{ required: true, message: 'Please input your Description!' }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.URL} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}></Col>
              </Row>
              {/* <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [{ required: false, message: 'Please input your Email!' }],
                    })(
                        <Row>
                            <Col span={4}>Email:</Col>
                            <Col span={12}>
                                <Input type="Email" placeholder="Email" />
                            </Col>
                        </Row>

                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('address', {
                        rules: [{ required: false, message: 'Please input your Address!' }],
                    })(
                        <Row>
                            <Col span={4}>Address:</Col>
                            <Col span={12}>
                                <Input  type="Address" placeholder="Address" />
                            </Col>
                        </Row>
                    )}
                </Form.Item> */}
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    );
  }
}

export default AddScreen = Form.create({ name: 'normal_login' })(AddScreen);