import React, { Component } from 'react';
import {Row, Col, Table, Button, message, Input, Modal} from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import ServiceType from './ServiceType';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Contractors extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // data: {},
      data: [],
      pagination: {},
      page: 1,
      size: 5,
      // sortField:"name",
			sortField:"updated_at",
      sortOrder:"ascend",
      searchValues: {
        name: '',
        name_ar: '',
        email: '',
        id_card: '',
        ph_num: '',
        ph_alt_num: '',
        new_service_type_name: '',
        new_service_type_name_ar: '',
        commercial_reg_no: '',
        orderBy : '',
        column_name :'name'
      }
    }
    this.lang = props.language._CONTRACTOR.SETUP;
    this.msg = props.language._CONTRACTOR.MESSAGE;
    this.service_type_lang = props.language._SERVICETYPE;

    this.columns = [
      (localStorage.getItem('lang')==='EN')?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width : '15%',
				align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAMEAR}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAMEAR}
              onChange={this.handleChangeSearchValues("name_ar")}
            />
          </span>
        ),
        dataIndex: 'name_ar',
        key: 'name_ar',
        width : '15%',
				align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang')==='EN')?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.EMAIL}
            <Search
              placeholder={this.lang.TABLEHEADERS.EMAIL}
              onChange={this.handleChangeSearchValues("email")}
            />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        width : '15%',
				align : 'left',
        sorter: (a, b) => this.compareByAlph(a.email, b.email),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.EMAIL}
            <Search
              placeholder={this.lang.TABLEHEADERS.EMAIL}
              onChange={this.handleChangeSearchValues("email")}
            />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        align : 'right',
        width : '15%',
        sorter: (a, b) => this.compareByAlph(a.email, b.email),
      },
      (localStorage.getItem('lang')==='EN')?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.IDCARDNO}
            <Search
              placeholder={this.lang.TABLEHEADERS.IDCARDNO}
              onChange={this.handleChangeSearchValues("id_card")}
            />
          </span>
        ),
        dataIndex: 'id_card',
        key: 'id_card',
        width : '15%',
				align : 'left',
        sorter: (a, b) => this.compareByAlph(a.id_card, b.id_card),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.IDCARDNO}
            <Search
              placeholder={this.lang.TABLEHEADERS.IDCARDNO}
              onChange={this.handleChangeSearchValues("id_card")}
            />
          </span>
        ),
        dataIndex: 'id_card',
        key: 'id_card',
        align : 'right',
        width : '15%',
        sorter: (a, b) => this.compareByAlph(a.id_card, b.id_card),
      },
      (localStorage.getItem('lang')==='EN')?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.SERVICETYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.SERVICETYPE}
              onChange={this.handleChangeSearchValues("new_service_type_name")}
            />
          </span>
        ),
        dataIndex: 'new_service_type_name',
        key: 'new_service_type_name',
        width : '15%',
				align : 'left',
        sorter: (a, b) => this.compareByAlph(a.new_service_type_name, b.new_service_type_name),
      } :
      {
        title: (
          <span>{this.lang.TABLEHEADERS.SERVICETYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.SERVICETYPE}
              onChange={this.handleChangeSearchValues("new_service_type_name_ar")}
            />
          </span>
        ),
        dataIndex: 'new_service_type_name_ar',
        key: 'new_service_type_name_ar',
        align : 'right',
        width : '15%',
        sorter: (a, b) => this.compareByAlph(a.new_service_type_name_ar, b.new_service_type_name_ar),
      },
      (localStorage.getItem('lang')==='EN')?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.COMMERCIALREGNO}
            <Search
              placeholder={this.lang.TABLEHEADERS.COMMERCIALREGNO}
              onChange={this.handleChangeSearchValues("commercial_reg_no")}
            />
          </span>
        ),
        dataIndex: 'commercial_reg_no',
        key: 'commercial_reg_no',
        width : '15%',
				align : 'left',
        sorter: (a, b) => this.compareByAlph(a.commercial_reg_no, b.commercial_reg_no),
      } :
      {
        title: (
          <span>{this.lang.TABLEHEADERS.COMMERCIALREGNO}
            <Search
              placeholder={this.lang.TABLEHEADERS.COMMERCIALREGNO}
              onChange={this.handleChangeSearchValues("commercial_reg_no")}
            />
          </span>
        ),
        dataIndex: 'commercial_reg_no',
        key: 'commercial_reg_no',
        align : 'right',
        width : '15%',
        sorter: (a, b) => this.compareByAlph(a.commercial_reg_no, b.commercial_reg_no),
      },
      // {
      //   title: (
      //     <span>{this.lang.TABLEHEADERS.PHONENO}
      //       <Search
      //         placeholder={`Search ${this.lang.TABLEHEADERS.PHONENO}`}
      //         onSearch={value => console.log(value)}
      //         onChange={this.handleChangeSearchValues("ph_num")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'ph_num',
      //   key: 'ph_num',
      //   sorter: (a, b) => this.compareByAlph(a.ph_num, b.ph_num),
      // },
      // {
      //   title: (
      //     <span>Alternative Phone No.
      //     <Search
      //         placeholder="Search alternative phone no."
      //         onSearch={value => console.log(value)}
      //         onChange={this.handleChangeSearchValues("ph_alt_num")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'ph_alt_num',
      //   key: 'ph_alt_num',
      //   sorter: (a, b) => this.compareByAlph(a.ph_alt_num, b.ph_alt_num),
      // },
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        key: 'last_name',
        width : '7%',
        align:'center'
    }];
  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }//End of Method

  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    localStorage.setItem('updateContractor', JSON.stringify(record))
    this.props.history.push('/dashboard/contractorUpdate')
  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager, pageNumber: pagination.current });

    if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
      if (this.state.pageNumber  !== pager.current) {
        this.fetch({ page: pagination.current, });
      }
    } 
    else {
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }

    // let pager = { ...this.state.pagination };
    // pager.current = pagination.current;
    
    // let searchValues = { ...this.state.searchValues}
    // searchValues.column_name = sorter.field
    // searchValues.orderBy = sorter.order

    // this.setState({
    //   page: pager.current,
    //   pagination: pager,
    //   searchValues
    // }, () => {
    //   this.fetch();
    // })
    
  }//End of method

  handleChangeSearchValues = (val) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${val}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/contractors/${record.id}`,{headers})
      .then(res => { // console.log(res)
        // message.success(res.data.message)
        if (res.data.success) {
          this.fetch();
          message.success(this.msg.DELETESUCCESS);
        }
        else if (res.data.code === '001') {
          message.warn(this.msg.DELETEDEPENDENCYERROR,5);
        }
        else{ message.error(this.msg.DELETEFAILURE,3); }
      })
      .catch(err => {
        console.log('Error occured while deleting the Contractor->',err)
        message.error(this.msg.DELETEFAILURE,3);
      });
  }//End of method

  componentDidMount() {
    this.fetch();
    this.setComponentDirection();
  }

  fetch = (params={}) => {
    const client_id = localStorage.getItem('client_id');
    let headers = getHeadersForHttpReq();
      
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else { sortOrder = 'DESC' }
    
    let data ={
      client_id : client_id,//As these are now independent --> CLient ID won't be necessary to send 
      name: this.state.searchValues.name || '',
      name_ar: this.state.searchValues.name_ar || '',
      email: this.state.searchValues.email || '',
      id_card: this.state.searchValues.id_card || '',
      ph_num: this.state.searchValues.ph_num || '',
      ph_alt_num: this.state.searchValues.ph_alt_num || '',
      new_service_type_name: this.state.searchValues.new_service_type_name || '',
      new_service_type_name_ar: this.state.searchValues.new_service_type_name_ar || '',
      commercial_reg_no: this.state.searchValues.commercial_reg_no || '',
      
      page,
      sortColumn: params.sortField || this.state.sortField ,
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'name'
    }
      // axios.post(`${complete_URL}/contractors/pagination_table`,data, {headers})
      axios.post(`${complete_URL}/contractors/pagination_table_ind`,data, {headers})
      .then(response => { // console.log('response of exp new pagination ->',response)
        let pagination = {};
        Object.assign(pagination,{
          ...this.state.pagination,
          total : response.data.totalElements,
          current : page +1,
          pageSize : this.state.size
        });

        // let pagination = { ...this.state.pagination };
        // pagination.total = response.data.totalElements;
        // pagination.current = page +1;
        // pagination.pageSize = this.state.size;
        this.setState({ data: response.data.data, pagination })
        // console.log('Expense data-> ',response);
      })
      .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })
    
    // axios.get(`${complete_URL}/contractors/table?id=${client_id}&page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`)
    //   .then(res => {
    //     // console.log('Contractors res.data is.. ',res.data);

    //     let pagination = { ...this.state.pagination };
    //     pagination.total = res.data.totalElements;
    //     pagination.pageSize = parseInt(res.data.size);

    //     this.setState({
    //       data: res.data,
    //       pagination: pagination,
    //     })
    //   })
    //   .catch(err => { console.log(err) })

  } //End of method
  
  displayServiceModel = () => {
    this.setState({ modelVisible: true })
  }
  handleServiceTypeOk = () => {
    this.setState({ modelVisible: false })
  }
  handleServiceTypeCancel = () => {
    this.setState({ modelVisible: false })     
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={8}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
        <Col span={13}>
          <Link to="/dashboard/contractorAdd">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
          <Button onClick={this.displayServiceModel} style={{ marginRight: '12px' }} type="primary">{this.lang.BUTTON.SERVICETYPES}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>
    }
    else {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={1}></Col>
        <Col span={13} >
          <Link to="/dashboard/contractorAdd">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
          <Button onClick={this.displayServiceModel} style={{ marginLeft: '12px' }} type="primary">{this.lang.BUTTON.SERVICETYPES}</Button>
        </Col>
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>
    }
  }

  render() {
    return (
        <>
          {this.CustomRow}
          <Row>
            <Modal 
              // centered={true} // Committed b/c it interfares in localizations (in Arabic mode)
              title={this.service_type_lang.CREATE.LABELS.INSERTTITLE}
              visible={this.state.modelVisible} 
              onOk={this.handleServiceTypeOk} 
              onCancel={this.handleServiceTypeCancel} 
              width = {'50%'}
              footer = {false}
            >
              <ServiceType  {...this.props} />
            </Modal>
          </Row>
          <Row id="clientsTable" style={{ overflow: 'scroll' }}>
            <Col span={23} style={{margin : '0 25px 14px 25px'}}>
              <Table
                columns={this.columns}
                rowKey="id"
                // dataSource={this.state.data.selectedElements}
                dataSource={this.state.data}
                onChange={this.handleChangePagination}
                pagination={this.state.pagination}
              />,
            </Col>
          </Row>
        </>
    )
  }
}
export default Contractors;