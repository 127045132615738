import React, { Component } from 'react';
import { Radio, DatePicker, Row, Col, Form, Icon, Button, Input, Card, message, Select, } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
class CustomerUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: '',
      country: [],
      cities: [],
      gender: [],
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._CUSTOMER.UPDATE;
    this.msg =  props.language._CUSTOMER.MESSAGE;
  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }  
  }

  componentDidMount() {
    let cust = localStorage.getItem('updatecustomer');
    let cust_obj = JSON.parse(cust);
    if (cust_obj.id) {
      this.getCustomerObj();
      this.getCountries();
      this.getGenders();
      this.setComponentDirection();
    }
    else {this.props.history.push('customers');}
    /*
      const headers = getHeadersForHttpReq();
      axios.get(`${complete_URL}/customers/by_id/${cust_obj.id}`,{headers})
      .then(res => {
        this.setState({ customer: res.data.data[0] }) //console.log(res.data.data[0])
        this.seclectCountry(res.data.data[0].country)
      })
      .catch(err => { console.log(err) })

    axios.get(`${complete_URL}/parameters/get_countries`,{headers})
      .then(res => {
        this.setState({ country: res.data.data[0] })
      })
      .catch(err => { console.log(err) })
    axios.get(`${complete_URL}/parameters/get_gender`,{headers})
      .then(res => {
        this.setState({ gender: res.data.data[0] })
      })
      .catch(err => { console.log(err) })*/
  }

  getCustomerObj= ()=>{
    let cust = localStorage.getItem('updatecustomer');
    let cust_obj = JSON.parse(cust);
    if (cust_obj.id) {
      const headers = getHeadersForHttpReq();
      axios.get(`${complete_URL}/customers/by_id/${cust_obj.id}`,{headers})
        .then(res => {
          this.setState({ customer: res.data.data[0] }) //console.log(res.data.data[0])
          this.seclectCountry(res.data.data[0].country)
        })
        .catch(err => { console.log('Error occured while fetching selected Customer data ->',err) })
    }
  }// End of Method

  getCountries = ()=>{
    let  headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/parameters/get_countries`,{headers})
      .then(res => {
        this.setState({ country: res.data.data[0] })
        /*console.log(res.data.data[0]);*/
      })
      .catch(err => { console.log('Error occured while fetching stored Countries ->',err) })
  }

  getGenders =()=>{
    let  headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/parameters/get_gender`,{headers})
      .then(res => {
        this.setState({ gender: res.data.data[0] })
      })
      .catch(err => { console.log('Error occured while fetching stored Genders ->',err) })
  }

  seclectCountry = (v) => {
    let  headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/parameters/child_parameter/${v}`,{headers})
      .then(res => { /*console.log(res.data.data[0])*/
        this.setState({ cities: res.data.data[0] })
      })
      .catch(err => { console.log(err) })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let a = JSON.parse(localStorage.getItem('updatecustomer'))
    this.props.form.validateFields((err, values) => {
      if (!err) { 
        values.user_id = localStorage.getItem('user_id');
        values.client_id = localStorage.getItem('client_id');
        const headers = getHeadersForHttpReq();
        // console.log(values)
        axios.put(`${complete_URL}/customers/${a.id}`, values, {headers})
          .then(res => {
            if (res.data.success) {
              message.success(this.msg.UPDATESUCCESS)
              this.props.history.push('customers')
            } 
            else {
              message.error(this.msg.UPDATEFAILURE,3)
            }
          })
          .catch(err => {
            console.log('Error occured while updating the Customer record->',err)
            message.error(this.msg.UPDATEFAILURE,3);
          });
      }
    })
  }
  // Should not be using here
  deleteUser = () => {
    const headers = getHeadersForHttpReq();
    let id = JSON.parse(localStorage.getItem('updatecustomer')).id
    axios.delete(`${complete_URL}/customers/${id}`,{headers})
      .then(res => {
        this.props.history.push('/dashboard/customers')
        message.success('Customers Has been Deleted')
      })
      .catch(err => {
        message.error('Customers can not be Deleted')
      })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/customers">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={12}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={6} style={{display:'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          {/* <Button style={{margin: '16px auto'}} className="delete" type="" onClick={this.deleteUser} title="delete"><Icon type="delete" theme="filled" /></Button> */}
          <Link to="/dashboard/customers">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/customers">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          {/* <Button className="delete" type="" onClick={this.deleteUser} title="delete"><Icon type="delete" theme="filled" /></Button> */}
          <Link to="/dashboard/customers">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let {dir, display, country, cities, gender} = this.state;

    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
             
              <Row gutter={24} dir= {dir} style={{display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NAME}                             >
                    {getFieldDecorator('name', {
                      initialValue: this.state.customer.name,
                      rules: [{ required: true, message:  this.msg.INSERTNAMEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}                             >
                    {getFieldDecorator('name_ar', {
                      initialValue: this.state.customer.name_ar,
                      rules: [{ required: true, message: this.msg.INSERTNAMEARERROR }],
                    })(
                      <Input dir='rtl' type="text" placeholder={this.lang.LABELS.NAMEAR} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.EMAIL}>
                    {getFieldDecorator('email', {
                      initialValue: this.state.customer.email,
                      rules: [{ required: false, message: this.msg.INSERTEMAILERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.EMAIL} type="email" style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.PHONENO}>
                    {getFieldDecorator('ph_num', {
                      initialValue: this.state.customer.ph_num,
                      rules: [{ required: true, message: this.msg.INSERTPHONENOERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.PHONENO} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ALTERNATEPHONENO}>
                    {getFieldDecorator('ph_alt_num', {
                      initialValue: this.state.customer.ph_alt_num,
                      rules: [{ required: false }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.ALTERNATEPHONENO} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.IDCARDNO}>
                    {getFieldDecorator('id_card', {
                      initialValue: this.state.customer.id_card,
                      rules: [{ required: true, message: this.msg.INSERTIDNOERROR }],
                    })(
                      <Input type="text" disabled placeholder={this.lang.LABELS.IDCARDNO} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.DATEBIRTH}>
                    {getFieldDecorator('birthdate', {
                      initialValue: moment(this.state.customer.birthdate),
                      rules: [{ required: true, message: this.msg.INSERTDOBERROR }],
                    })(
                      <DatePicker placeholder={this.lang.LABELS.DATEBIRTH} format={'DD/MM/YYYY'} />

                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>

                  <Form.Item label={this.lang.LABELS.GENDER}>
                    {getFieldDecorator('gender', {
                      initialValue: this.state.customer.gender,
                      rules: [{ required: true, message: this.msg.SELECTGENDERERROR }],
                    })(
                      <Radio.Group  >
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            gender.length > 0 && gender.map(item => (
                              <Radio placeholder={this.lang.LABELS.GENDER} value={item.id} key={item.id} >{item.name}</Radio>
                            ))
                            :
                            gender.length > 0 && gender.map(item => (
                              <Radio placeholder={this.lang.LABELS.GENDER} value={item.id} key={item.id} >{item.name_ar}</Radio>
                            ))
                        }
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display }}>
                <Col span={24}>
                  <h1 style={{ fontSize: '24px' }}>{this.lang.LABELS.ADDRESS}</h1>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.AREA}                              >
                    {getFieldDecorator('area', {
                      initialValue: this.state.customer.area,
                      rules: [{ required: false, message:  this.msg.INSERTAREAERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.AREA} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.STREET}                             >
                    {getFieldDecorator('street', {
                      initialValue: this.state.customer.street,
                      rules: [{ required: false, message: this.msg.INSERTSTREETERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.STREET} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.BUILDINGVILLAHOUSE}                             >
                    {getFieldDecorator('building', {
                      initialValue: this.state.customer.building,
                      rules: [{ required: false, message: this.msg.INSERTBUILDINGERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.BUILDINGVILLAHOUSE} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.FLAT}                             >
                    {getFieldDecorator('flat_no', {
                      initialValue: this.state.customer.flat_no,
                      rules: [{ required: false, message:  this.msg.INSERTFLATNOERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.FLAT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NEARESTPLACE}                             >
                    {getFieldDecorator('nearest_place', {
                      initialValue: this.state.customer.nearest_place,
                      rules: [{ required: false, message: this.msg.INSERTNEARESTPLACEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NEARESTPLACE} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.COUNTRY}   >
                    {getFieldDecorator('country', {
                      initialValue: this.state.customer.country,
                      rules: [{ required: false, message:  this.msg.SELECTCOUNTRYERROR }],
                    })(
                      <Select onChange={this.seclectCountry} placeholder={`Select ${this.lang.LABELS.COUNTRY}`}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            country.length > 0 && country.map(parent => (
                              <Select.Option key={parent.id} value={parent.id}>{parent.name}</Select.Option>
                            ))
                            :
                            country.length > 0 && country.map(parent => (
                              <Select.Option style={{textAlign:'right'}} key={parent.id} value={parent.id}>{parent.name_ar}</Select.Option>
                            ))
                        }
                      </Select>)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.CITY}   >
                    {getFieldDecorator('city', {
                      initialValue: this.state.customer.city,
                      rules: [{ required: false, message:  this.msg.SELECTCITYERROR }],
                    })(
                      <Select placeholder={this.lang.LABELS.CITY}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            cities.length > 0 && cities.map(city => (
                              <Select.Option key={city.id} value={city.id}>{city.name}</Select.Option>
                            ))
                            :
                            cities.length > 0 && cities.map(city => (
                              <Select.Option style={{textAlign:'right'}} key={city.id} value={city.id}>{city.name_ar}</Select.Option>
                            ))
                        }
                      </Select>)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default CustomerUpdate = Form.create({ name: 'customer_update_form' })(CustomerUpdate);