import React, { Component } from 'react';
import { Row, Col, Table, Button, message, Input, DatePicker, Modal } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import ExpenseType from './ExpenseType';
// import moment from 'moment'
const Search = Input.Search;
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class Expenses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modelVisible: false,
      // data: {},
      data: [],
      pagination: {},
      page: 1,
      size: 10,
      sortField:"date",
      sortOrder:"descend",
      searchValues: {
        amount: '',
        expense_type: '',
        expense_type_ar: '',
        note: '',
        bill: '',
        no: '',
        date: '',
        to_date: '',
        from_date: '',
        orderBy: '',
        column_name: 'no'
      },
      startValue: null,
      endValue: null,
      endOpen: false,
      flag: true,
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._EXPENSE.SETUP;
    this.msg = props.language._EXPENSE.MESSAGE;
    this.expense_type_lang = props.language._EXPENSETYPE;

    this.columns_ORIGINAL = [
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NO}
            <Search
              placeholder="Search amount"
              onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("no")}

            />
          </span>
        ),
        dataIndex: 'no',
        key: 'no',
        sorter: (a, b) => this.compareByAlph(a.no, b.no),
      },
      {
        title: (
          <span>{this.lang.TABLEHEADERS.DATE}
            <DatePicker placeholder={this.lang.TABLEHEADERS.DATE} onChange={this.onChangeDate("date")} />
          </span>
        ),
        dataIndex: 'date',
        key: 'date',
        // width: '14.29%',
        sorter: (a, b) => this.compareByAlph(a.date, b.date),
      },
      {
        title: (
          <span>{this.lang.TABLEHEADERS.TYPE}
            <Search
              placeholder="Search type"
              onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("expense_type")}

            />
          </span>
        ),
        dataIndex: 'expense_type',
        key: 'expense_type',
        sorter: (a, b) => this.compareByAlph(a.expense_type, b.expense_type),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      {
        title: (
          <span>{this.lang.TABLEHEADERS.AMOUNT}
            <Search
              placeholder="Search amount"
              onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("amount")}

            />
          </span>
        ),
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a, b) => this.compareByAlph(a.amount, b.amount),
      },
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NOTE}
            <Search
              placeholder="Search note"
              onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("note")}

            />
          </span>
        ),
        dataIndex: 'note',
        key: 'note',
        sorter: (a, b) => this.compareByAlph(a.note, b.note),
      },
      {
        title: (
          <span>Bill no.
          <Search
              placeholder="Bill No"
              onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("bill")}

            />
          </span>
        ),
        dataIndex: 'BILL_NO',
        key: 'BILL_NO',
        sorter: (a, b) => this.compareByAlph(a.note, b.note),
      },
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        key: 'action',
    }
    ];

    this.columns1 = [
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.NO}
            <Search
              placeholder={this.lang.TABLEHEADERS.NO}
              // onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("no")}
            />
          </span>
        ),
        dataIndex: 'no',
        key: 'no',
        width: '15%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.no, b.no),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NO}
          <Search
            placeholder={this.lang.TABLEHEADERS.NO}
            // onSearch={value => console.log('')}
            onChange={this.handleChangeSearchValues("no")}
          />
        </span>
      ),
      dataIndex: 'no',
      key: 'no',
      width: '15%',
      align : 'right',
      sorter: (a, b) => this.compareByAlph(a.no, b.no),
      },
        /*{
          title: (
            <span>{this.lang.TABLEHEADERS.DATE}
            <Search
              placeholder="Search By Date"
              onSearch={value => console.log(value)}
              onChange={this.handleChangeSearchValues("date")}
  
            />
          </span>
        ),
        dataIndex: 'date',
        key: 'date',
        width: '15%',
        sorter: (a, b) => this.compareByAlph(a.date, b.date),
        render:(text) => {
         return moment(text).format('YYYY-MM-DD')
        }
      }*/
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.DATE}
            <DatePicker placeholder={this.lang.TABLEHEADERS.DATE} onChange={this.onChangeDate("date")} />
          </span>
        ),
        dataIndex: 'date',
        key: 'date',
        width: '15%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.date, b.date),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.DATE}
            <DatePicker placeholder={this.lang.TABLEHEADERS.DATE} onChange={this.onChangeDate("date")} />
          </span>
        ),
        dataIndex: 'date',
        key: 'date',
        width: '15%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.date, b.date),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.TYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.TYPE}
              // onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("expense_type")}
            />
          </span>
        ),
        dataIndex: 'expense_type',
        key: 'expense_type',
        width: '15%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.expense_type, b.expense_type),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.TYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.TYPE}
              // onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("expense_type")}
            />
          </span>
        ),
        dataIndex: 'expense_type',
        key: 'expense_type',
        width: '15%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.expense_type, b.expense_type),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }, 
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.AMOUNT}
            <Search
              placeholder={this.lang.TABLEHEADERS.AMOUNT}
              // onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("amount")}
            />
          </span>
        ),
        dataIndex: 'amount',
        key: 'amount',
        width: '14%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.amount, b.amount),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.AMOUNT}
            <Search
              placeholder={this.lang.TABLEHEADERS.AMOUNT}
              // onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("amount")}
            />
          </span>
        ),
        dataIndex: 'amount',
        key: 'amount',
        width: '14%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.amount, b.amount),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NOTE}
            <Search
              placeholder={this.lang.TABLEHEADERS.NOTE}
              // onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("note")}
            />
          </span>
        ),
        dataIndex: 'note',
        key: 'note',
        width: '17%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.note, b.note),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NOTE}
            <Search
              placeholder={this.lang.TABLEHEADERS.NOTE}
              // onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("note")}
  
            />
          </span>
        ),
        dataIndex: 'note',
        key: 'note',
        width: '17%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.note, b.note),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.BILLNO}
            <Search
              placeholder={this.lang.TABLEHEADERS.BILLNO}
              onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("bill")}
  
            />
          </span>
        ),
        dataIndex: 'BILL_NO',
        key: 'BILL_NO',
        width: '15%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.note, b.note),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.BILLNO}
            <Search
              placeholder={this.lang.TABLEHEADERS.BILLNO}
              // onSearch={value => console.log('')}
              onChange={this.handleChangeSearchValues("bill")}
  
            />
          </span>
        ),
        dataIndex: 'BILL_NO',
        key: 'BILL_NO',
        width: '15%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.note, b.note),
      },
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '15%',
        align : 'center',
        key: 'action',
      }
    ];  
  }

  setComponentDirection=()=>{
    if (localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block',textAlignValue : 'left'});
    }
    else {
      this.setState({dir : 'rtl', display: 'flex',textAlignValue : 'right'});
    }
  }

  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    localStorage.setItem('updateExpense', JSON.stringify(record))
    this.props.history.push('/dashboard/expenseUpdate')
  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      pageNumber: pagination.current,
      sortOrder:sorter.order,
      sortField: sorter.field
    },()=>{

      // if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
      //   if (this.state.pageNumber  !== pager.current) {
      //     this.fetch({
      //       page: pagination.current,
      //     });
      //   }
      // } else {
        this.fetch({
          results: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
      // }
    });

    // if (document.getElementsByClassName('ant-calendar-date').length < 1) {
    //   let pager = { ...this.state.pagination };
    //   pager.current = pagination.current;

    //   let searchValues = { ...this.state.searchValues }
    //   searchValues.column_name = sorter.field
    //   searchValues.orderBy = sorter.order

    //   this.setState({
    //     page: pager.current,
    //     pagination: pager,pager,
    //     pageNumber: pagination.current,
    //     searchValues
    //   }, () => {
    //     // console.log('column name in Expense screen ->',searchValues.column_name);
    //     this.fetch();
    //     // console.log('sssssssssssss',this.state.searchValues)
    //   })
    // }
  }//End of method

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }

  onChangeDate = (type) => (date, dateString) => {
    if (type === 'date') {
      let temp = { ...this.state.searchValues };
      temp.date = dateString;
      this.setState({
        searchValues: temp
      }, () => {
        this.fetch();
      })
    }
  };

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/expenses/${record.id}`,{headers})
      .then(res => {// console.log(res)
        message.success(this.msg.DELETESUCCESS);
        this.fetch()
      })
      .catch(err => {
        message.error(this.msg.DELETEFAILURE,3)
        console.log('Error occured while deleting Expense->',err)
      })
  }

  componentDidMount() {
    this.fetch();
    this.setComponentDirection();
  }

  fetch = (params={}) => {
    const client_id = localStorage.getItem('client_id');
    let headers = getHeadersForHttpReq();
      
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else if (params.sortOrder == 'descend') { sortOrder = 'DESC' }
    else { sortOrder = 'DESC' }
    
    let data ={
      client_id ,
      amount: this.state.searchValues.amount || '',
      expense_type: this.state.searchValues.expense_type || '',
      expense_type_ar: this.state.searchValues.expense_type_ar || '',
      note: this.state.searchValues.note || '',
      bill: this.state.searchValues.bill || '',
      no: this.state.searchValues.no || '',
      date: this.state.searchValues.date || '',
      to_date: this.state.searchValues.to_date || '',
      from_date: this.state.searchValues.from_date || '',

      page,
      sortColumn: params.sortField || this.state.sortField || 'date',
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'no'
    }
    let is_contr = localStorage.getItem('is_contr');
    if ( is_contr != 1) {
      Object.assign(data,{ contractor_id : null, is_contr : 0});
    }
    else{
      const contractor_id = JSON.parse(localStorage.getItem('updateContractor')).id;
      Object.assign(data,{ contractor_id, client_id : null, is_contr : 1});
    }
    // console.log('Sending data to api ->',data);
    axios.post(`${complete_URL}/expenses/pagination_table`,data, {headers})
    .then(response => { // console.log('response of exp new pagination ->',response)
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      this.setState({ data: response.data.data, pagination });
      // console.log('Expense data-> ',response);
    })
    .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) });
    /*axios.get(`${complete_URL}/expenses/by_clients/table?id=${id}&page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`)
      .then(res => {
        let pagination = { ...this.state.pagination };
        pagination.total = res.data.totalElements;
        pagination.pageSize = parseInt(res.data.size);
        this.setState({
          data: res.data,
          pagination: pagination,
        })
        
      })
      .catch(err => { console.log(err) })*/
  }

  displayExpenseModel = () => {
    this.setState({ modelVisible: true })
  }

  handleExpenseTypeOk = () => {
    this.setState({ modelVisible: false })
  }

  handleExpenseTypeCancel = () => {
    this.setState({ modelVisible: false })     
  }
  
  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row id="clientTableHeader" gutter={24}>
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
        <Col span={13}>
          <Link to="/dashboard/expenseAdd">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
          <Button onClick={this.displayExpenseModel} style={{ marginRight: '12px' }} type="primary">{this.lang.BUTTON.EXPENSETYPES}</Button>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={13}>
          <Link to="/dashboard/expenseAdd">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
          <Button onClick={this.displayExpenseModel} style={{ marginLeft: '12px' }} type="primary">{this.lang.BUTTON.EXPENSETYPES}</Button>
        </Col>
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
      </Row>
    }
  }

  handleToFromDate=(opt)=>{
    if (opt=='tf')
      this.fetch();
    else{
      let search_obj = Object.assign( this.state.searchValues,{ to_date: '', from_date: ''});
      this.setState({flag: true,startValue:'',endValue:'', searchValues: search_obj},()=>{ this.fetch(); })
    }  
  }

  disabledStartDate = (startValue) => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  }
  
  disabledEndDate = (endValue) => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  }

  onStartChange = (date, dateString) => {
    this.setState({ 'startValue': date },
    () => {
       if (this.state.startValue && this.state.endValue) {
          let search_obj = Object.assign( this.state.searchValues,
            { to_date: moment(this.state.endValue).locale('en').format('YYYY-MM-DD'),
              from_date: moment(this.state.startValue).locale('en').format('YYYY-MM-DD')
            }); 
            this.setState({flag: false, searchValues: search_obj})
       }
    })
  }
  
  onEndChange = (date, dateString) => {
    this.setState({ 'endValue': date },
       () => {
          if (this.state.endValue && this.state.startValue) {
            let search_obj = Object.assign( this.state.searchValues,
              { to_date: moment(this.state.endValue).locale('en').format('YYYY-MM-DD'),
                from_date: moment(this.state.startValue).locale('en').format('YYYY-MM-DD')
              });
            this.setState({flag: false, searchValues: search_obj})
          }
       })
  }
  
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  }
  
  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open});
  }

  render() {
    let { dir, display, modelVisible, data, pagination } = this.state;
    const columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NO}
          <Search
            placeholder={this.lang.TABLEHEADERS.NO}
            // onSearch={value => console.log('')}
            onChange={this.handleChangeSearchValues("no")}
          />
        </span>
      ),
      dataIndex: 'no',
      key: 'no',
      width: '15%',
      align : 'left',
      sorter: (a, b) => a.no.length - b.no.length,
      sortOrder: this.state.sortField === "no" && this.state.sortOrder,
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.NO}
        <Search
          placeholder={this.lang.TABLEHEADERS.NO}
          // onSearch={value => console.log('')}
          onChange={this.handleChangeSearchValues("no")}
        />
      </span>
    ),
    dataIndex: 'no',
    key: 'no',
    width: '15%',
    align : 'right',
    sorter: (a, b) => a.no.length - b.no.length,
    sortOrder: this.state.sortField === "no" && this.state.sortOrder,
    },
      /*{
        title: (
          <span>{this.lang.TABLEHEADERS.DATE}
          <Search
            placeholder="Search By Date"
            onSearch={value => console.log(value)}
            onChange={this.handleChangeSearchValues("date")}

          />
        </span>
      ),
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      sorter: (a, b) => this.compareByAlph(a.date, b.date),
      render:(text) => {
       return moment(text).format('YYYY-MM-DD')
      }
    }*/
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.DATE}
          <DatePicker placeholder={this.lang.TABLEHEADERS.DATE} onChange={this.onChangeDate("date")} />
        </span>
      ),
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      align : 'left',
      sorter: (a, b) => a.date.length - b.date.length,
      sortOrder: this.state.sortField === "date" && this.state.sortOrder,
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.DATE}
          <DatePicker placeholder={this.lang.TABLEHEADERS.DATE} onChange={this.onChangeDate("date")} />
        </span>
      ),
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      align : 'right',
      sorter: (a, b) => a.date.length - b.date.length,
      sortOrder: this.state.sortField === "date" && this.state.sortOrder,
    },
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.TYPE}
          <Search
            placeholder={this.lang.TABLEHEADERS.TYPE}
            // onSearch={value => console.log('')}
            onChange={this.handleChangeSearchValues("expense_type")}
          />
        </span>
      ),
      dataIndex: 'expense_type',
      key: 'expense_type',
      width: '15%',
      align : 'left',
      sorter: (a, b) => a.expense_type.length - b.expense_type.length,
      sortOrder: this.state.sortField === "expense_type" && this.state.sortOrder,
      render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.TYPE}
          <Search
            placeholder={this.lang.TABLEHEADERS.TYPE}
            // onSearch={value => console.log('')}
            onChange={this.handleChangeSearchValues("expense_type_ar")}
          />
        </span>
      ),
      dataIndex: 'expense_type_ar',
      key: 'expense_type_ar',
      width: '15%',
      align : 'right',
      sorter: (a, b) => a.expense_type_ar.length - b.expense_type_ar.length,
      sortOrder: this.state.sortField === "expense_type_ar" && this.state.sortOrder,
      render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
    }, 
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.AMOUNT}
          <Search
            placeholder={this.lang.TABLEHEADERS.AMOUNT}
            // onSearch={value => console.log('')}
            onChange={this.handleChangeSearchValues("amount")}
          />
        </span>
      ),
      dataIndex: 'amount',
      key: 'amount',
      width: '14%',
      align : 'left',
      sorter: (a, b) => a.amount.length - b.amount.length,
      sortOrder: this.state.sortField === "amount" && this.state.sortOrder,
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.AMOUNT}
          <Search
            placeholder={this.lang.TABLEHEADERS.AMOUNT}
            // onSearch={value => console.log('')}
            onChange={this.handleChangeSearchValues("amount")}
          />
        </span>
      ),
      dataIndex: 'amount',
      key: 'amount',
      width: '14%',
      align : 'right',
      sorter: (a, b) => a.amount.length - b.amount.length,
      sortOrder: this.state.sortField === "amount" && this.state.sortOrder,
    },
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.NOTE}
          <Search
            placeholder={this.lang.TABLEHEADERS.NOTE}
            // onSearch={value => console.log('')}
            onChange={this.handleChangeSearchValues("note")}
          />
        </span>
      ),
      dataIndex: 'note',
      key: 'note',
      width: '17%',
      align : 'left',
      sorter: (a, b) => a.note.length - b.note.length,
      sortOrder: this.state.sortField === "note" && this.state.sortOrder,
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.NOTE}
          <Search
            placeholder={this.lang.TABLEHEADERS.NOTE}
            // onSearch={value => console.log('')}
            onChange={this.handleChangeSearchValues("note")}

          />
        </span>
      ),
      dataIndex: 'note',
      key: 'note',
      width: '17%',
      align : 'right',
      sorter: (a, b) => a.note.length - b.note.length,
      sortOrder: this.state.sortField === "note" && this.state.sortOrder,
    },
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.BILLNO}
          <Search
            placeholder={this.lang.TABLEHEADERS.BILLNO}
            onSearch={value => console.log('')}
            onChange={this.handleChangeSearchValues("bill")}

          />
        </span>
      ),
      dataIndex: 'BILL_NO',
      key: 'BILL_NO',
      width: '15%',
      align : 'left',
      sorter: (a, b) => a.BILL_NO.length - b.BILL_NO.length,
      sortOrder: this.state.sortField === "BILL_NO" && this.state.sortOrder,
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.BILLNO}
          <Search
            placeholder={this.lang.TABLEHEADERS.BILLNO}
            // onSearch={value => console.log('')}
            onChange={this.handleChangeSearchValues("bill")}

          />
        </span>
      ),
      dataIndex: 'BILL_NO',
      key: 'BILL_NO',
      width: '15%',
      align : 'right',
      sorter: (a, b) => a.BILL_NO.length - b.BILL_NO.length,
      sortOrder: this.state.sortField === "BILL_NO" && this.state.sortOrder,
    },
    {
      title: `${this.lang.TABLEHEADERS.ACTION}`,
      render: (text, record) => (
        <span>
          <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
        </span>
      ),
      width: '15%',
      align : 'center',
      key: 'action',
    } ]; 

    return (
        <>
          {this.CustomRow}
          <Row gutter={24} dir= {dir} style={{display, marginBottom:'20px'}}>
            <Col span={5}> </Col>
            <Col span={6}>
              <DatePicker
                disabledDate={this.disabledStartDate}
                format="YYYY-MM-DD"
                style={{ width: '100%' }}
                placeholder={this.lang.TABLEHEADERS.STARTDATE}
                onChange={(date, dateString) => { this.onStartChange(date, dateString) }}
                onOpenChange={this.handleStartOpenChange}
              />
            </Col>
            <Col span={6}>
              <DatePicker
                disabledDate={this.disabledEndDate}
                format="YYYY-MM-DD"
                style={{ width: '100%' }}
                placeholder={this.lang.TABLEHEADERS.ENDDATE}
                onChange={(date, dateString) => { this.onEndChange(date, dateString) }}
                open={this.state.endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </Col>
            <Col span={5}>
              <Button disabled={this.state.flag} onClick={() => {this.handleToFromDate('tf')} }>{this.lang.BUTTON.SEARCH}</Button>
              <Button style={{marginLeft:'12px', marginRight: '12px'}} onClick={() => {this.handleToFromDate('r')}}>{this.lang.BUTTON.REFRESH}</Button>
            </Col>
          </Row>
          <Row>
            <Modal 
              title={this.expense_type_lang.CREATE.LABELS.INSERTTITLE}
              visible={modelVisible} 
              onOk={this.handleExpenseTypeOk} 
              onCancel={this.handleExpenseTypeCancel}  
              width = {'50%'}
              footer = {false}
            >
              <ExpenseType  {...this.props} />
            </Modal>
          </Row>

          <Row id="clientsTable">
            {/* <Col span={2}></Col>
            <Col span={20}> */}
            <Col span={23} style={{margin : '0 25px 14px 25px'}}>
              <Table
                columns={columns}
                rowKey="id"
                // dataSource={this.state.data.selectedElements}
                dataSource={data}
                onChange={this.handleChangePagination}
                pagination={pagination}
              />
            </Col>
          </Row>
        </>
    )
  }
}

export default Expenses;  