import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, Card, message, InputNumber, DatePicker, Switch, Select } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import moment from 'moment';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
const TextArea = Input.TextArea;

if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class OfferAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      screens: [],
      assignedScreen: [],
      updateScreens: [],
      selectedScreenToAssign: '',
      SelectedScreenToRemove: [],
      banquets: [],
      halls: [],
      hallPrice: [],
      contractors: [],
      packagesData : [],
      offerOption: 0,
      comm_status : 1,
      discountOption : 0,
      offerOptions: [{'id':0,'name':'Package','name_ar':'حزمة من القاعات'},{'id':1,'name':'Hall','name_ar':'قاعه داخلية'}],
      discountOptions: [{'id':0,'name':'Percentage','name_ar':'بنسبة مئوية'},{'id':1,'name':'Amount','name_ar':'حسب الكمية'}],
      uom:true,
      status:0,//IMPORTANT when status is 1 means Offer is finalized, no further updation allowed
      dir : 'ltr', 
      display: 'block',
      startValue: null,
      endValue: null,
      endOpen: false,
    }
    this.lang = props.language._OFFER;

  }

  setComponentDirection=()=>{
    if(localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    this.setComponentDirection();
    this.getAssignedContByClient();
    this.fetchBanquets();
  }
  //unncessary code
  deleteCategory(record) {
    let tempArray = [...this.state.SelectedScreenToRemove]
    tempArray.push(record)
    let tempArray2 = [...this.state.assignedScreen]
    let array3 = [...this.state.screens]

    for (let a = 0; a <= tempArray2.length - 1; a++) {
      if (tempArray2[a].id === record.id) {
        array3.push(tempArray2[a])
        tempArray2.splice(a, 1)
      }
    }
    this.setState({ SelectedScreenToRemove: tempArray, assignedScreen: tempArray2, screens: array3 })
  }
  //unncessary code
  seclectScreen = (v) => {
    this.setState({ selectedScreenToAssign: v })
  }
  //unncessary code
  assignScreenAdd = () => {
    let screens = JSON.parse(localStorage.getItem('screenList'))
    let tempArray = [...this.state.assignedScreen]
    let tempArray2 = [...this.state.screens]
    let tempArray3 = [...this.state.updateScreens]
    for (let a = 0; a <= screens.length - 1; a++) {
      if (screens[a].id === this.state.selectedScreenToAssign) {

        tempArray.push(screens[a])
        tempArray3.push(screens[a])
      }
    }
    for (let a = 0; a <= this.state.screens.length - 1; a++) {
      this.state.screens[a].id === this.state.selectedScreenToAssign && tempArray2.splice(a, 1)
    }
    document.getElementsByClassName("ant-select-selection-selected-value").length !== 0
      ?
      document.getElementsByClassName("ant-select-selection-selected-value")[0].innerHTML = ""
      :
      console.log()
    this.setState({ assignedScreen: tempArray, screens: tempArray2, updateScreens: tempArray3 })
  setTimeout(() => { /*console.log(this.state)*/ }, 1000)
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let client_id = localStorage.getItem('client_id')
    let user_id = localStorage.getItem('user_id')
    this.props.form.validateFields((err, values) => {
      if (!err) {
        Object.assign(values,{
          client_id : client_id,
          user_id : user_id,
          from_date : moment(values.from_date).locale('en').format('YYYY-MM-DD'),
          to_date : moment(values.to_date).locale('en').format('YYYY-MM-DD'),
          from_time : '00:00:00',
          to_time : '00:00:00',
          contractor : this.state.offerOption == 0  ? values.contractor : null,
          package : this.state.offerOption == 0  ?  values.package : null,
          banquet : this.state.offerOption == 1  ?  values.banquet : null,
          hall : this.state.offerOption == 1  ? values.hall : null,
          comm_status : values.comm_status ? 1 : 0,
          status : values.status ? 1 : 0 
        });
        const headers = getHeadersForHttpReq();
        axios.post(`${complete_URL}/offers/add`, values,{headers})
          .then(res => {
            if(res.data.success){
              message.success(this.lang.MESSAGE.ADDSUCCESS);
              this.props.history.push('offers');
            }
            else if(res.data.code === '001'){
              console.log('Error occured while creating new offer->',res.data);
              message.error(res.data.message,4);
            }
            else{
              console.log('Error occured while creating new offer->',res.data);
              message.error(this.lang.MESSAGE.ADDFAILURE,4);
            }
          })
          .catch(err => {
            console.log('Error occured while creating new offer->',err);
            message.error(this.lang.MESSAGE.ADDFAILURE,4);
          });
      }
    });
  }

  selectBanquet = (v) => {
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/halls/by_banquet/${v}`,{headers})
      .then(res => {
        this.setState({ halls: res.data.data[0] });
      })
      .catch(err => { console.log(err) });
  }

  selectHall = (v) => {
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/hallprices/reservation/${v}`,{headers})
      .then(result => {
         this.setState({ hallPrice: result.data.data[0] });
      })
      .catch(err => { console.log('Error occured while fetching the Hall Prices->',err); });
  }
  //Old method for fetching the Contractors(Before Contractor feature) 
  getContractors =()=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/contractors/fetch_by_clientId/${localStorage.getItem('client_id')}`,{headers})
    .then(res => { this.setState({ contractors: res.data.data}/*,()=>{console.log('Recv data of contractors in offer',this.state.contractors)}*/)
    })
    .catch(err => { console.log(err) });
  }

  //New ajax API call for fetching Assigned independent contractors( of selected Service Type)
  getAssignedContByClient=()=>{
    const headers = getHeadersForHttpReq();
    const client_id = localStorage.getItem('client_id');
    axios.get(`${complete_URL}/contractors/independent/${client_id}`,{headers})
      .then(res => { // console.log('ind contractors->',res.data.data);
        this.setState({ contractors: res.data.data });
      })
      .catch(err => { console.log('Error occured while fetching Assigned Contractors->',err) });
  }

  fetchPackageDataByContractorId = (contractorId) => {
    const headers = getHeadersForHttpReq();
     axios.get(`${complete_URL}/contractorpackages/fetch_by_contractor/${contractorId}`,{headers})
      .then(res => {
        this.setState({ packagesData: res.data.data });
      })
      .catch(err => { console.log('Error occured while fetching packagesData by_contractor->',err) });
  }

  handleOfferTypeSelection =(val)=>{
    this.setState({offerOption:val});
  }

  handleDiscountTypeSelection =(val)=>{
    this.setState({discountOption:val},()=>{
      if (val == 0) {
        this.props.form.setFieldsValue({ discount_amount : 0.00});
      }
      else {
        this.props.form.setFieldsValue({ discount  : 0.00});
      }
    });
  }
  
  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/offers">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={12}> <h1 style={{textAlign: 'center'}}>{this.lang.CREATE.LABELS.TITLE}</h1> </Col>
        <Col className="btn-box" span={6} style={{display: 'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/offers">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/offers">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}> <h1 style={{textAlign: 'center'}}>{this.lang.CREATE.LABELS.TITLE}</h1> </Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to="/dashboard/offers">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  fetchBanquets = () =>{
    const headers = getHeadersForHttpReq();
    const client_id = localStorage.getItem('client_id');
    if (client_id) {
      axios.get(`${complete_URL}/banquets/by_clients/${client_id}`,{headers})
        .then(res => {
          this.setState({ banquets: res.data.data[0] });
        })
        .catch(err => { console.log('Error occured while fetching Client banquets->',err); });
    }
  }
  
  disabledStartDate = (startValue) => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  }

  disabledEndDate = (endValue) => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  }

  onChange = (field, value) => {
    this.setState({ [field]: value});
  }

  onStartChange = (value) => {
    this.onChange('startValue', value);
  }

  onEndChange = (value) => {
    this.onChange('endValue', value);
  }

  handleStartOpenChange = (open) => {
    // console.log(open)
    if (!open) {
      this.setState({ endOpen: true });
    }
  }

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open});
  }

    render() {
    const { getFieldDecorator } = this.props.form;
    let { dir, display, contractors, packagesData, banquets, halls, offerOption,
          offerOptions, discountOptions } = this.state;
    return (
       
        <Row gutter={12} style={{ marginTop: '50px' }}>
          <Col span={4}></Col>
          <Col span={16} >
            <Card>
              <Form onSubmit={this.handleSubmit}>
                {this.CustomRow}
                <Row gutter={24} dir= {dir} style={{display}}>
                  <Col span={12}>
                    <Form.Item label={this.lang.CREATE.LABELS.NAME}>
                      {getFieldDecorator('name', {
                        rules: [{ required: true, message: this.lang.MESSAGE.INSERTNAMEERROR }],
                      })(
                        <Input placeholder={this.lang.CREATE.LABELS.NAME} type="text" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={this.lang.CREATE.LABELS.NAMEAR}>
                      {getFieldDecorator('name_ar', {
                        rules: [{ required: false, message:  this.lang.MESSAGE.INSERTNAMEARERROR }],
                      })(
                        <Input type="text" placeholder={this.lang.CREATE.LABELS.NAMEAR} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} dir= {dir} style={{display}}>
                  <Col span={6}>
                  <Form.Item label= {this.lang.CREATE.LABELS.OFFERTYPE} >
                        {getFieldDecorator('offerOption', {
                          initialValue : this.state.offerOptions[0].id,
                          rules: [{ required: true, message: this.lang.MESSAGE.SELECTOFFERTYPEERROR }],
                        })(
                        <Select onChange={(e)=>this.handleOfferTypeSelection(e)} placeholder= {this.lang.CREATE.LABELS.OFFERTYPE}>
                            {
                            (localStorage.getItem('lang') === 'EN') ?
                               offerOptions.map(offer => (
                                <Select.Option key={offer.id} value={offer.id}>{offer.name}</Select.Option>
                              ))
                              : offerOptions.map(offer => (
                                <Select.Option style={{textAlign:'right'}} key={offer.id} value={offer.id}>{offer.name_ar}</Select.Option>
                              ))
                          }
                          </Select>)}
                      </Form.Item>
                  </Col>
                  {offerOption == '0' && 
                    <>
                    <Col span={6}>
                      <Form.Item label= {this.lang.CREATE.LABELS.CONTRACTOR} >
                        {getFieldDecorator('contractor', {
                          // initialValue: this.state.contractors.length ? this.state.contractors[0].id : '',
                          rules: [{ required: true, message: this.lang.MESSAGE.SELECTCONTRACTORERROR }],
                        })(
                        <Select onChange={this.fetchPackageDataByContractorId} placeholder= {this.lang.CREATE.LABELS.CONTRACTOR}>
                            {
                            (localStorage.getItem('lang') === 'EN') ?
                              contractors.length > 0 && contractors.map(contractor => (
                                <Select.Option key={contractor.id} value={contractor.id}>{contractor.name}</Select.Option>
                              ))
                              : contractors.length > 0 && contractors.map(contractor => (
                                <Select.Option style={{textAlign:'right'}} key={contractor.id} value={contractor.id}>{contractor.name_ar}</Select.Option>
                              ))
                          }
                          </Select>)}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label= {this.lang.CREATE.LABELS.PACKAGE}>
                          {getFieldDecorator('package', {
                            // initialValue: this.state.packagesData.length ? this.state.packagesData[0].id : '',
                            rules: [{ required: true, message: this.lang.MESSAGE.SELECTPACKAGEERROR }],
                          })(
                          <Select placeholder= {this.lang.CREATE.LABELS.PACKAGE} >
                              {
                              (localStorage.getItem('lang') === 'EN') ?
                                  packagesData.length > 0 && packagesData.map(pkg => (
                                  <Select.Option key={pkg.id} value={pkg.id}>{pkg.type}</Select.Option>
                                ))
                                : packagesData.length > 0 && packagesData.map(pkg => (
                                  <Select.Option style={{textAlign:'right'}} key={pkg.id} value={pkg.id}>{pkg.type}</Select.Option>
                                ))
                            }
                            </Select>)}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label= {this.lang.CREATE.LABELS.COMMSTATUS}>
                      {getFieldDecorator('comm_status', { 
                        valuePropName: 'checked', initialValue:this.state.comm_status == 1 ? true : false })(<Switch />)}
                      </Form.Item>
                    </Col>
                    </>
                  }
                  {offerOption == '1' && 
                    <>
                    <Col span={6}>
                      <Form.Item label= {this.lang.CREATE.LABELS.BANQUET} >
                        {getFieldDecorator('banquet', {
                          // initialValue: this.state.banquets.length ? this.state.banquets[0].id : '',
                          rules: [{ required: true, message: this.lang.MESSAGE.SELECTBANQUETERROR }],
                        })(
                        <Select onChange={this.selectBanquet} placeholder= {this.lang.CREATE.LABELS.BANQUET}>
                            {
                            (localStorage.getItem('lang') === 'EN') ?
                              banquets.length > 0 && banquets.map(banquet => (
                                <Select.Option key={banquet.id} value={banquet.id}>{banquet.name}</Select.Option>
                              ))
                              : banquets.length > 0 && banquets.map(banquet => (
                                <Select.Option style={{textAlign:'right'}}  key={banquet.id} value={banquet.id}>{banquet.name_ar}</Select.Option>
                              ))
                          }
                          </Select>)}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label= {this.lang.CREATE.LABELS.HALL}>
                        {getFieldDecorator('hall', {
                          // initialValue: this.state.halls.length ? this.state.halls[0].id : '',
                          rules: [{ required: true, message: this.lang.MESSAGE.SELECTHALLERROR }],
                        })(
                        <Select onChange={this.selectHall}  placeholder= {this.lang.CREATE.LABELS.HALL}>
                            {
                            (localStorage.getItem('lang') === 'EN') ?
                              halls.length > 0 && halls.map(hall => (
                                <Select.Option key={hall.id} value={hall.id}>{hall.name}</Select.Option>
                              ))
                              : halls.length > 0 && halls.map(hall => (
                                <Select.Option style={{textAlign:'right'}}  key={hall.id} value={hall.id}>{hall.name_ar}</Select.Option>
                              ))
                          }
                          </Select>)}
                      </Form.Item>
                    </Col>
                    </>
                  }
                  
                </Row>
                <Row gutter={24} dir= {dir} style={{display}}>
                
                  <Col span={8}>
                    <Form.Item label= {this.lang.CREATE.LABELS.FROMDATE}>
                      {getFieldDecorator('from_date', {
                        rules: [{ required: true, message: this.lang.MESSAGE.INSERTFROMDATEERROR }],
                      })(
                        // <DatePicker type="text" placeholder="From Date" style={{ width: '100%' }} />
                        <DatePicker
                              disabledDate={this.disabledStartDate}
                              format="YYYY-MM-DD"
                              placeholder={this.lang.CREATE.LABELS.FROMDATE}
                              onChange={this.onStartChange}
                              onOpenChange={this.handleStartOpenChange}
                              style={{ width: '100%' }} 
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label= {this.lang.CREATE.LABELS.TODATE}>
                      {getFieldDecorator('to_date', {
                        rules: [{ required: true, message: this.lang.MESSAGE.INSERTTODATEERROR }],
                      })(
                        // <DatePicker type="text" placeholder="To Date" style={{ width: '100%' }}/>
                        <DatePicker
                              disabledDate = {this.disabledEndDate}
                              format = "YYYY-MM-DD"
                              placeholder = {this.lang.CREATE.LABELS.TODATE}
                              onChange = {this.onEndChange}
                              open={this.state.endOpen}
                              onOpenChange={this.handleEndOpenChange}
                              style={{ width: '100%' }} 
                        />
                      )}
                    </Form.Item>
                  </Col>
                  
                  <Col span={8}>
                    {/* <Form.Item label={this.lang.CREATE.LABELS.DESCRIPTION}>
                      {getFieldDecorator('description', {
                        rules: [{ required: false, message: 'Please input Offer\'s Description!' }],
                      })(
                        <Input type="text" placeholder={this.lang.CREATE.LABELS.DESCRIPTION} />
                      )}
                    </Form.Item> */}
                    <Form.Item label= {this.lang.CREATE.LABELS.FINALIZESTATUS}>
                      {getFieldDecorator('status', { 
                        valuePropName: 'checked', initialValue:this.state.status == 1 ? true : false })(<Switch />)}
                    </Form.Item>
                  </Col>
                  
                </Row>
                <Row gutter={24} dir= {dir} style={{display}}>
                  <Col span={8}>
                    <Form.Item label= {this.lang.CREATE.LABELS.DISCOUNTTYPE} >
                        {getFieldDecorator('discountOption', {
                          initialValue:this.state.discountOptions[0].id,
                          rules: [{ required: true, message: this.lang.MESSAGE.SELECTDISCOUNTTYPEERROR }],
                        })(
                          <Select onChange={(e)=>this.handleDiscountTypeSelection(e)} placeholder= {this.lang.CREATE.LABELS.DISCOUNTTYPE}>
                            {
                            (localStorage.getItem('lang') === 'EN') ?
                               discountOptions.map(discount => (
                                <Select.Option key={discount.id} value={discount.id}>{discount.name}</Select.Option>
                              ))
                              : discountOptions.map(discount => (
                                <Select.Option style={{textAlign:'right'}} key={discount.id} value={discount.id}>{discount.name_ar}</Select.Option>
                              ))
                            }
                          </Select>)}
                      </Form.Item>
                  </Col>
                  <Col span={8}>
                      <Form.Item label= {this.lang.CREATE.LABELS.DISCOUNTPERCENTAGE}>
                        {getFieldDecorator('discount', {
                          initialValue: 0,
                          rules: [{ required: true, message: this.lang.MESSAGE.INSERTPERCENTERROR }],
                        })(
                        <InputNumber disabled = {this.state.discountOption === 1 } type="text" max ={100.00} min={0.00} placeholder={this.lang.CREATE.LABELS.DISCOUNTPERCENTAGE} style={{width:'100%'}}/>
                        )}
                      </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label= {this.lang.CREATE.LABELS.DISCOUNTAMOUNT}>
                      {getFieldDecorator('discount_amount', {
                        initialValue: 0,
                        rules: [{ required: true, message: this.lang.MESSAGE.INSERTAMOUNTERROR }],
                      })(
                      <InputNumber disabled = {this.state.discountOption === 0 } type="text" min={0.00} placeholder={this.lang.CREATE.LABELS.DISCOUNTAMOUNT} style={{width:'100%'}}/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} dir= {dir} style={{display}}>
                  <Col span={24}>
                    <Form.Item label={this.lang.CREATE.LABELS.DESCRIPTION}>
                      {getFieldDecorator('description', {
                        rules: [{ required: false, message: this.lang.MESSAGE.INSERTDESCRIPTIONERROR }],
                      })(
                        <TextArea style={{ width: '100%' }} placeholder={this.lang.CREATE.LABELS.DESCRIPTION} autoSize={{ minRows: 2, maxRows: 6 }} />
                      )}
                    </Form.Item>
                  </Col>
              </Row>
              </Form>
            </Card>
          </Col>
          <Col span={4}></Col>
        </Row>

    )
  }
}

export default OfferAdd = Form.create({ name: 'offer_form' })(OfferAdd);