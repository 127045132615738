import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button,Switch, Input, message, Table, Avatar, Select, InputNumber, } from 'antd'
import axios from 'axios';
import { Link } from 'react-router-dom'
import API_URL from '../../constants';
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class ContractorPackages extends Component {
    constructor(props) {
      super(props)
      this.state = {
        packagesData: [],
        dir : 'ltr', 
        display: 'block'
      }
      this.lang = props.language._CONTRACTOR.UPDATE.PACKAGE;
      this.msg = props.language._CONTRACTOR.UPDATE.PACKAGE.MESSAGE;
  
      this.columns = [
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.SETUP.LABELS.PACKAGETYPE}`,
          dataIndex: 'type',
          render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
        }:
        {
          title: `${this.lang.SETUP.LABELS.PACKAGETYPE}`,
          dataIndex: 'type',
          align:'right',
          render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.SETUP.LABELS.PACKAGEPRICE}`,
          dataIndex: 'price',
          render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
        }:
        {
          title: `${this.lang.SETUP.LABELS.PACKAGEPRICE}`,
          dataIndex: 'price',
          align:'right',
          render: (text, record) => <button  className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.SETUP.LABELS.COMMISSIONPERCENT}`,
          dataIndex: 'commission_percentage',
          render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
        }:
        {
          title: `${this.lang.SETUP.LABELS.COMMISSIONPERCENT}`,
          dataIndex: 'commission_percentage',
          align:'right',
          render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
          dataIndex: 'commission_amount',
          render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
        }:
        {
          title: `${this.lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
          dataIndex: 'commission_amount',
          align:'right',
          render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.SETUP.LABELS.ISACTIVE}`,
          dataIndex: 'is_active',
          render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text===1? 'Yes':'No'}</button>,
        }:
        {
          title: `${this.lang.SETUP.LABELS.ISACTIVE}`,
          dataIndex: 'is_active',
          align:'right',
          render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text===1? 'نعم':'لا'}</button>,
        },
        {
          title: `${this.lang.SETUP.LABELS.ACTION}`,
          align :'center',
          render: (text, record) => (
            <span>
              <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.SETUP.BUTTON.DELETE}</Button>
            </span>
          ),
      }
      ]
    }//End of Constructor

    
    handleNavigation = (record) => {
        localStorage.setItem('updatePackage', JSON.stringify(record))
        this.props.history.push('/dashboard/contpackageUpdate')
    }

    deleteCategory = (record) => {
        const id = record.id
        if (id) {
        axios.delete(`${complete_URL}/contractorpackages/${id}`)
        .then(res => { //console.log('res->',res);
            // message.success('Packages Has been deleted')
            if (res.data.success) {
            message.success(this.msg.DELETESUCCESS);
            this.fetchPackageData();
            }
            else if(res.data.code === '003'){
            message.error(this.msg.DATANOTFOUNDERROR,3);
            }
            else{
            message.error(this.msg.DELETEFAILURE,3);
            }
        })
        .catch(err => {
            console.log('Issue while deleting the Contractor Package ->',err);
            message.error(this.msg.DELETEFAILURE,3);
        })
        }
        else{
        message.error(this.msg.DATANOTFOUNDERROR,3);
        }
    }//End of Delete Method

    fetchPackageData = async () => {
      let cont_obj = localStorage.getItem('updateContractor');
        let id = JSON.parse(cont_obj).id;
        await axios.get(`${complete_URL}/contractorpackages/fetch_by_contractor/${id}`)
        .then(res => { // console.log('packagesData->',res.data.data[0])
            this.setState({ packagesData: res.data.data })
        })
        .catch(err => { console.log('Error occured while fetching Contractor Packages->',err) })
    }

    componentDidMount(){
        // let userid = localStorage.getItem('user_id');
        this.fetchPackageData();
        // let reservationByOwner = this.getIsOwnerBooleanCheck(userid);
    }

    componentWillMount(){
        if (localStorage.getItem('lang') === 'EN') {
          this.CustomRow = <Row id="clientTableHeader">
            <Col span={8}><p>{this.lang.SETUP.LABELS.TITLE}</p></Col>
            <Col span={10}></Col>
            <Col span={4}>
              {/* <Link to="/dashboard/contProfile">
                <Button type="primary">{'Contractor Profile'}</Button>
              </Link> */}
              <Link to="/dashboard/contpackageAdd">
                <Button type="primary">{this.lang.SETUP.BUTTON.ADD}</Button>
              </Link>
            </Col>
            <Col span={1}></Col>
          </Row>
        }
        else {
          this.CustomRow = <Row id="clientTableHeader">
            <Col span={1}></Col>
            <Col span={6} >
              {/* <Link to="/dashboard/contProfile">
                <Button type="primary">{'Contractor Profile'}</Button>
              </Link> */}
              <Link to="/dashboard/contpackageAdd">
                <Button type="primary">{this.lang.SETUP.BUTTON.ADD}</Button>
              </Link>
            </Col>
            <Col span={6}></Col>
            <Col span={9}><p>{this.lang.SETUP.LABELS.TITLE}</p></Col>
            <Col span={1}></Col>
          </Row>
        }

    }

    render(){
        return (
          <>
            {this.CustomRow}
            <Row id="packagesTable">
              <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                  <Table 
                    columns={this.columns} 
                    rowKey="id" 
                    dataSource={this.state.packagesData} 
                    style={{ marginTop: '10px' }} 
                   />
              </Col>
            </Row>
          </>
        )
    }

}//End of Class
export default ContractorPackages;