import React, { Component } from 'react';
import { Row, Col, Table, Button, message, Input} from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Screens extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // data: {},
      data: [],
      sortField:"name",
      sortOrder:"ascend",
      pagination: {},
      page: 1,
      size: 5,
      searchValues: {
        name: '',
        name_ar: '',
        url: ''
      }
    }
    this.lang = props.language._SCREEN.SETUP
    this.columns = [
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={`Search ${this.lang.TABLEHEADERS.NAME}`}
              onSearch={value => console.log(value)}
              onChange={this.handleChangeSearchValues("name")}

            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width: '25%',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,

      },
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAMEAR}
            <Search
              placeholder={`Search ${this.lang.TABLEHEADERS.NAMEAR}`}
              onSearch={value => console.log(value)}
              onChange={this.handleChangeSearchValues("name_ar")}
            />
          </span>
        ),
        dataIndex: 'name_ar',
        key: 'name_ar',
        width: '25%',
        sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,

      },
      {
        title: (
          <span>{this.lang.TABLEHEADERS.URL}
            <Search
              placeholder={`Search ${this.lang.TABLEHEADERS.URL}`}
              onSearch={value => console.log(value)}
              onChange={this.handleChangeSearchValues("url")}

            />
          </span>
        ),
        dataIndex: 'url',
        key: 'url',
        width: '25%',
        sorter: (a, b) => this.compareByAlph(a.url, b.url),
      }, {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        key: 'action',
        width: '25%',
    }];
  }

  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    localStorage.setItem('updateScreen', record.id)
    this.props.history.push('/dashboard/updatescreen')
  }

  handleChangePagination = (pagination, filters, sorter) => {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
         pagination: pager,
         pageNumber: pagination.current
      });

      if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
         if (this.state.pageNumber  !== pager.current) {
         this.fetch({
            page: pagination.current,
         });
         }
      } else {
         this.fetch({
         results: pagination.pageSize,
         page: pagination.current,
         sortField: sorter.field,
         sortOrder: sorter.order,
         ...filters,
         });
      }

    /*let pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      page: pager.current,
      pagination: pager,
    }, () => {
      this.fetch();
    })*/

  }//End of method

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/screens/${record.id}`, {headers})
      .then(res => { /*console.log(res)*/
        message.success('Screen Has been Deleted');
        this.fetch();
      })
      .catch(err => {
        message.error('Screen can not be Deleted');
        console.log('Error occured while deleting the Screen ->',err);
      })
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = (params={}) => {
    const headers = getHeadersForHttpReq();
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else { sortOrder = 'DESC' }
    
    let data ={
    //   client_id : client_id,
      name: this.state.searchValues.name || '',
      name_ar: this.state.searchValues.name_ar || '',
      url: this.state.searchValues.url || '',
      
      page,
      sortColumn: params.sortField || this.state.sortField ,
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'name'
    }
    // console.log('Sending data to api ->',data);
    axios.post(`${complete_URL}/screens/pagination_table`,data, {headers})
    .then(response => {
      // console.log('response of exp new pagination ->',response)
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      this.setState({ data: response.data.data, pagination })
      // console.log('Expense data-> ',response);
    })
    .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })


    /*axios.get(`${complete_URL}/screens/table/?page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`,{headers})
      .then(res => { //console.log(res.data);
        let pagination = { ...this.state.pagination };
        pagination.total = res.data.totalElements;
        pagination.pageSize = parseInt(res.data.size);
        this.setState({
          data: res.data,
          pagination: pagination,
        })
      })
      .catch(err => { console.log(err) })*/

  }//End of method

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
        <Col span={13}>
          <Link to="/dashboard/addscreen">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={13}>
          <Link to="/dashboard/addscreen">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
      </Row>
    }
  }

  render() {
    return (
        <>
          {this.CustomRow}
          <Row id="clientsTable">
            <Col span={2}></Col>
            <Col span={20}>
              <Table
                columns={this.columns}
                rowKey="id"
                // dataSource={this.state.data.selectedElements}
                dataSource={this.state.data}
                onChange={this.handleChangePagination}
                pagination={this.state.pagination}
              />
            </Col>
          </Row>
        </>
    )
  }
}

export default Screens;