import React from 'react';
import {/*  Table,*/Row, Col, Form, Icon, Button, InputNumber, Table, Input, message,Spin } from 'antd'
import axios from 'axios'
import API_URL from '../../constants/index'
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class HallDocuments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hall_id : props.hall_id, 
      hallDocumentsList: false,
      file: '',
      tdata: [],
      data: [],
      sortField:"name",
      sortOrder:"ascend",
      pagination: {},
      page: 1,
      size: 5,
      upload: false,
      name: '',
      searchValues :{
        name: '',
        mimetype: ''
      },
      spin : false,
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._HALL.UPDATE.HALLDOCUMENT;
    this.msg = props.language._HALL.MESSAGE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.NAME}`,
        dataIndex: 'name',
        align : 'center',
        width : '45%',
        render: (text, record) =><div style={{textAlign :'center'}}><p>{text}</p></div>,
      } :
      {
        title: `${this.lang.SETUP.LABELS.NAME}`,
        dataIndex: 'name',
        align : 'right',
        width : '60%',
        render: (text, record) => <div style={{textAlign :'right'}}><p>{text}</p></div>,

      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.DOCUMENT}`,
        dataIndex: 'doc' ,
        width : '36%',
        align : 'center',
        render: (text, record) =><div style={{textAlign :'center'}}> <Button onClick={this.downloadDoc.bind(this, record.documentid)}>{this.lang.SETUP.LABELS.DOWNLOAD}</Button></div>,
      } :
      {
        title: `${this.lang.SETUP.LABELS.DOCUMENT}`,
        dataIndex: 'doc' ,
        width : '20%',
        align : 'right',
        render: (text, record) => <div style={{textAlign :'right'}}><Button onClick={this.downloadDoc.bind(this, record.documentid)}>{this.lang.SETUP.LABELS.DOWNLOAD}</Button></div>,
      } ,
      {
        title: `${this.lang.SETUP.LABELS.ACTION}`,
        width : '18%',
        align : 'center',
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteDoc.bind(this, record)}>{this.lang.SETUP.BUTTON.DELETE}</Button>
          </span>
        ),
        key: 'last_name',
      }]
  }
  
  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    this.fetch();
    this.setComponentDirection();
  }
  
  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
         pagination: pager,
         pageNumber: pagination.current
      });

      if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
        if (this.state.pageNumber  !== pager.current) {
          this.fetch({ page: pagination.current });
        }
      } 
      else {
         this.fetch({
         results: pagination.pageSize,
         page: pagination.current,
         sortField: sorter.field,
         sortOrder: sorter.order,
         ...filters,
         });
      }
  }//End of Method

  fetch = (params={}) => {
    // Important Check : Necessary to send Client ID or not??
    // const client_id = localStorage.getItem('client_id');
    const headers =  getHeadersForHttpReq();
    
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else { sortOrder = 'DESC' }
    
    let data ={
      // client_id : client_id,
      hall_id : this.state.hall_id ,
      name : this.state.searchValues.name || '',
      mimetype : this.state.searchValues.mimetype || '',

      page,
      sortColumn: params.sortField || this.state.sortField ,
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'no'
    }
    // console.log('Sending data to api ->',data);
    axios.post(`${complete_URL}/halldocuments/pagination_table`,data, {headers})
    .then(response => {
      // console.log('response of exp new pagination ->',response)
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      this.setState({ data: response.data.data, pagination })
      // console.log('Expense data-> ',response);
    })
    .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })


    /*axios.get(`${complete_URL}/halldocuments/${this.props.hall_id}`)
      .then(res => {
        let a = [...res.data.data]
        setTimeout(() => {
          this.setState({ tdata: a }, () => {
            // console.log(this.state.tdata)
          })
        })
      })
      .catch(err => { console.log(err) })*/

  }//End of Method

  handleSubmitHallDocumentUpdate = () => { }

  downloadDoc = (documentid) => {
    // console.log(documentid)
    axios.get(`${complete_URL}/images/${documentid}`, { responseType: 'blob' })
      .then(results => {
        let doc
        var reader = new FileReader();
        reader.readAsDataURL(results.data);
        reader.onloadend = () => {
          doc = reader.result
          const link = document.createElement('a');
          link.href = doc;
          link.setAttribute('download', `Hall-Document.${results.data.type.split('/')[1]}`); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  nameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  handleSubmitHallDocument = (e) => {
    e.preventDefault();
    let imgdata = new FormData(); 
    imgdata.append('file', this.state.file)
    imgdata.append('name', this.state.name)
    imgdata.append('user_id', localStorage.getItem('user_id'))
    imgdata.append('hall_id', JSON.parse(localStorage.getItem('hallToUpdate')).id)
    const headers = {
      'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
      'Content-Type': 'multipart/form-data'
    }

    if (this.state.upload) {
      this.setState({ spin : true });
      axios.post(`${complete_URL}/images/hall_documents/add`, imgdata, { responseType: 'blob' }, headers)
        .then(res => {
          let reader = new FileReader();
          reader.readAsDataURL(res.data);
          this.setState({spin:false, hallDocumentsList: false,file: '', upload:false },()=>{
            message.success(this.msg.HALLDOCADDSUCCESS,2);
            this.fetch();
          })
        })
        .catch(err => {
          console.log('Error occured while uploading hall doc->',err)
          this.setState({spin:false },()=>{
            message.error(this.msg.HALLDOCADDFAILURE,2);
          });
        })
    } //may used or nay be removed
    else {
      message.error(this.msg.HALLDOCUPLOADERROR,2);
    }
  }

  handleImageUpload = (e) => {
    e.preventDefault();
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      let file = e.currentTarget.files[0];
      let fileName = file.name;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (file.size <= 1024 * 1024 * 2 && /png|xlsx|pdf|odt|docx|jpe?g/i.test(extension)) {
        // console.log(extension)
        this.setState({ file: file, upload: true } )
      } 
      else {
        this.setState({file: '', upload:false},()=>{
          message.error(this.msg.HALLDOCTYPEERROR,2);
        });
      }
    }
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.AddDocHeaderRow = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ hallDocumentsList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4} style={{textAlign:'center'}}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.TableHeaderRow = <Row id="clientTableHeader">
        <Col span={10}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.LABELS.TITLE}</p></Col>
        <Col span={13}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ hallDocumentsList: true }) }}>{this.lang.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>
      this.UpdateDocHeaderRow = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ hallDocumentsList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
    else {
      this.AddDocHeaderRow = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ hallDocumentsList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.TableHeaderRow = <Row id="clientTableHeader">
        <Col span={6}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ hallDocumentsList: true }) }}>{this.lang.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={17}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.LABELS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateDocHeaderRow = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ hallDocumentsList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
  }
  
  deleteDoc = (record) => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/halldocuments/${record.id}`,{headers})
      .then(res => {
        if (res.data.success) {
          message.success(this.msg.HALLDOCDELETESUCCESS,2);
          this.fetch();
        } 
        else {
          message.error(this.msg.HALLDOCDELETEFAILURE,2);
        }
      })
      .catch(err => {
        console.log('Error occured while deleting the Hall Document->',err);
        message.error(this.msg.HALLDOCDELETEFAILURE,2);
      });
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Spin tip={this.msg.UPLOADDOCTIP} spinning = {this.state.spin}>
        {
          this.state.hallDocumentsList === true ?
            <Form onSubmit={this.handleSubmitHallDocument} style={{ marginTop: '20px' }}>
              {this.AddDocHeaderRow}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item label={this.lang.CREATE.LABELS.HALLDOCUMENTNAME}>
                  {getFieldDecorator('hall_doc_name', {
                      rules: [{ required: true, message: this.msg.INSERTGALLERYIMAGENAMEERROR }],
                    })( 
                      <Input type="text" placeholder={this.lang.CREATE.LABELS.HALLDOCUMENTNAME} onChange={this.nameChange} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <p>{this.lang.CREATE.LABELS.FILEUPLOAD}</p>
                  <Form.Item label= {this.lang.CREATE.LABELS.HALLDOCUMENTUPLOAD}>
                    {getFieldDecorator('hall_doc', {
                      rules: [{ required: true, message: this.msg.HALLDOCUPLOADERROR }],
                    })(
                      <Input type="file" onChange={this.handleImageUpload} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            : this.state.hallDocumentsList === false ?
              //View Hall Documents Screen
              <>
                {this.TableHeaderRow}
                <Row id="clientsTable">
                  {/* <Col span={2}></Col>
                  <Col span={20}> */}
                  <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                    <Table 
                      columns={this.columns} 
                      rowKey="id" 
                      // dataSource={this.state.tdata}
                      dataSource={this.state.data}
                      onChange={this.handleChangePagination}
                      pagination={this.state.pagination}  
                      style={{ marginTop: '10px' }} 
                    />,
                    </Col>
                </Row>
              </>
              //Update Hall Documents Screen
              // But this seems unnecessary code; should be removed
              :
              <Form onSubmit={this.handleSubmitHallDocumentUpdate.bind(this)} style={{ marginTop: '20px' }}>
                {this.UpdateDocHeaderRow}
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item label={this.lang.UPDATE.LABELS.PRICE}>
                      {getFieldDecorator('rice', {
                        rules: [{ required: true, message: 'Please input Hall Discount!' }],
                      })(
                        <InputNumber placeholder="Enter Hall Discount" style={{ width: '100%' }} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={this.lang.UPDATE.LABELS.UOM}>
                      {getFieldDecorator('uop', {
                        rules: [{ required: true, message: 'Please Select Unit of Measure' }],
                      })(
                        <input type="text" />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
        }
      </Spin>
    )
  }
}

export default HallDocuments = Form.create({ name: 'hallDocs_form' })(HallDocuments);