import React from 'react';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import SelectClient from './SelectClient'
import './style.css'

let select_screen;
if (localStorage.getItem('lang') === 'EN') {
  select_screen = SelectClient;
}
else {
  select_screen = (props) => <DirectionProvider direction={DIRECTIONS.RTL}>
    <div>
      <SelectClient language={props.language} {...props} />
    </div>
  </DirectionProvider>
}

export default select_screen