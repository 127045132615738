import React, { Component } from 'react';
import { Row, Col, Form, Button,Icon,InputNumber, message, Input, Spin, List} from 'antd'
import axios from 'axios'
import API_URL from '../../constants'
import getHeadersForHttpReq from '../../constants/token';

const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class RuleTitleUpdate extends Component {
  constructor(props) {
		super(props)
		this.state = {
      rule_parents: [],
      rules: [],
      ruleLines : [],
      rules_seq_nos : [],
      rulestypes_seq_nos : [],
      selectedRuleTypeObj : {},
      updateRuleTitleObj : {},
      max_rule_seq_no : 0,
      max_seq_no : 0,
      screen_opt:'update',
      loading1: false,
      loading2: false,
      updTitleLoading: false,
      spin_msg : '',
      spin:true,
      dir : 'ltr', 
      display: 'block',
    }
    
    this.lang = props.lang;
    this.refArrayEn=[];
    this.refArrayAr=[];
    this.refArraySeq =[];
    this.refArray=[];
  }
  
  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  deleteCategory =(record,route)=>{
    let msg;
    const headers = getHeadersForHttpReq();
    if(route === 'rules') {
      this.setState({spin_msg : this.lang.MESSAGE.DELETERULETIP, spin :true})
      msg = this.lang.MESSAGE.DELETERULESUCCESS;
    }
    else if(route === 'rulestypes') {
      this.setState({spin_msg : this.lang.MESSAGE.DELETERULETITLETIP, spin :true})
      msg = this.lang.MESSAGE.DELETERULETITLESUCCESS;
    }
    axios.delete(`${complete_URL}/${route}/${record.id}`,{headers})
      .then(res => {
        if(res.data.success){  
          message.success(msg ,2);
          if(route === 'rules') {
            this.getRulesByRuleType(this.state.updateRuleTitleObj.id);
            this.getAllSeqNoByTitleID('rules', 'rules_seq_nos');
            this.setState({ spin :false})
          }
          else {
            this.fetch();
            this.setState({ spin :false})
          }
        }
        else if(res.data.code ==='001'){
          this.setState({ spin :false})
          message.error(this.lang.MESSAGE.DELETEDEPENDENCYERROR ,2);
        }
      })
      .catch(err => { 
        this.setState({ spin :false})
        console.log('Error occured while deleting the hall price->',err);
        if(route === 'rules') {
          message.error(this.lang.MESSAGE.DELETERULEFAILURE ,2);
        }
        else if(route === 'rulestypes') {
          message.error(this.lang.MESSAGE.DELETERULETITLEFAILURE ,2);
        }
      })
  }

  componentDidMount() {
    this.fetchRulesDataOfTitle();
    this.setComponentDirection();
  }

  fetchRulesDataOfTitle =()=>{
    let titleObj = JSON.parse(localStorage.getItem('updRuleTitle'));
    // this.getRuleParents('CUST_RULE');
    this.fetchRuleTitleObjData(titleObj.id);   
    this.getRulesByRuleType(titleObj.id);
    this.getAllSeqNoByTitleID('rules', 'rules_seq_nos');
    this.getAllSeqNosOfTitles('rulestypes', 'rulestypes_seq_nos');
    this.setState({ spin :false});
  }

  resetRefArrays=()=>{
    this.refArrayEn=[];
    this.refArrayAr=[];
    this.refArraySeq =[];
    this.refArray=[];
  }
  
  componentWillMount(){
    if(localStorage.getItem('lang') === 'EN') {
      this.CreateRulesHeader = <Row>
        <Col span={4} style={{textAlign:'center'}}>
          <Button type="danger" onClick={() => { this.setState({ screen_opt: 'table' },()=>{this.resetRefArrays(); }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={16} style={{textAlign:'center'}}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.CREATE.LABELS.TITLETYPE}</h1>
        </Col>
        <Col span={4} style={{textAlign:'center'}}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.RulesTableHeader = <Row id="clientTableHeader">
        <Col span={9}><p style={{ float: 'left', marginLeft: '5%', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.TABLEHEADERS.TITLE}</p></Col>
        <Col span={14}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ screen_opt: 'add' }) }}>{this.lang.BUTTON.ADDTITLE}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateRulesHeader = <Row>
        <Col span={4} style={{textAlign:'center'}}>
          <Button type="danger" onClick={() => { this.setState({ screen_opt: 'table',ruleLines:[] },()=>{this.resetRefArrays();this.props.openTitleTableByParent(); }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={16} >
          <h1 style={{ fontSize: '22px',textAlign:'center' }}>{this.lang.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={4} style={{textAlign:'center'}}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
    else {
      
      this.CreateRulesHeader = <Row>
        <Col span={4} style={{textAlign:'center'}}>
          <Button type="danger" onClick={() => { this.setState({ screen_opt: 'table' }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={4}></Col>
        <Col span={8} style={{textAlign:'center'}}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.CREATE.LABELS.TITLETYPE}</h1>
        </Col>
        <Col span={4}></Col>
        <Col span={4} style={{textAlign:'center'}}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.RulesTableHeader = <Row id="clientTableHeader">
        <Col span={5}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ screen_opt: 'add' }) }}>{this.lang.BUTTON.ADDTITLE}</Button>
        </Col>
        <Col span={9}></Col>
        <Col span={8}><p style={{ textAlign: 'right', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.TABLEHEADERS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateRulesHeader = <Row>
        <Col span={4} style={{textAlign:'center'}}>
          <Button type="danger" onClick={() => { this.setState({ screen_opt: 'table',ruleLines:[] },()=>{this.resetRefArrays();this.props.openTitleTableByParent(); }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={16} style={{textAlign:'center'}}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={4} style={{textAlign:'center'}}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }

  }
  
  /*
  getRuleParents=(param_type)=>{
    const headers = getHeadersForHttpReq();

    axios.get(`${complete_URL}/parameters/by_param_type/${param_type}`,{headers})
      .then(res => { // console.log('rule_parents->',res.data.data)
        this.setState({ rule_parents : res.data.data[0] } )
      })
      .catch(err => { console.log('Error occured while fetching Rule Type->',err) })
  }*/

  getRulesByRuleType=(rule_type_id)=>{
    const headers = getHeadersForHttpReq();

    axios.get(`${complete_URL}/rules/by_rule_type_id/${rule_type_id}`,{headers})
      .then(res => {  //console.log('rules->',res.data.data)
        this.setState({ rules : res.data.data } )
      })
      .catch(err => { console.log('Error occured while fetching Rules->',err) })
  }

  fetchRuleTitleObjData = (id)=>{
    if (id) {
      const headers = getHeadersForHttpReq();
      axios.get(`${complete_URL}/rulestypes/by_id/${id}`,{headers})
        .then(res => {  //console.log('RuleTitleObjData->',res.data.data[0])
          this.setState({ updateRuleTitleObj : res.data.data[0] } )
        })
        .catch(err => { console.log('Error occured while fetching Rules->',err) })
    }
    else{
      this.props.openTitleTableByParent();
    }
  }//End of Method
  
  checkAllUniqueSeqNo=(add_new_seq_no)=>{
    
    let { rules_seq_nos } = this.state;
    //Check from saved Title seq nos
    for (let i = 0; i < this.refArraySeq.length; i++) {
      if (this.refArrayEn[i] && this.refArrayAr[i] && this.refArraySeq[i]) {

        let seq_no = this.refArraySeq[i].inputNumberRef.input.ariaValueNow ;
        //refArr --->add_new_seq_no
        if ( parseFloat(add_new_seq_no)=== parseFloat(seq_no)) {
          return false;
        }//End of add_new_seq_no check

        for (let j = 0; j < rules_seq_nos.length; j++) {
          if (parseFloat(rules_seq_nos[j].seq_no) === parseFloat(seq_no)) {
            return false;
          }
        }//End of Loop of Stored Seq no(s)#
        
        //Check from new Title seq nos in refArray
        for (let k = 0; k < this.refArraySeq.length; k++) {
          if (this.refArrayEn[k] && this.refArrayAr[k] && this.refArraySeq[k]) {
            let seq_no2 = this.refArraySeq[k].inputNumberRef.input.ariaValueNow ;
            if ( parseFloat(seq_no2)=== parseFloat(seq_no) && k !== i) {
              return false;
            }
          }
        }//End of Loop of refArray Seq no(s) duplicates check#
      }
    }//End of Loop of refArray Seq no(s)
    for (let x = 0; x < rules_seq_nos.length; x++) {
      if ( parseFloat(add_new_seq_no)=== parseFloat( rules_seq_nos[x].seq_no)) {
        return false;
      }//End of add_new_seq_no check
    }

    return true;
  }

  checkUpdateTitleUniqueSeqNo=(seq_no3)=>{
    const title_id = this.state.updateRuleTitleObj.id;
    let {rulestypes_seq_nos } = this.state;
    for (let i = 0; i < rulestypes_seq_nos.length; i++) {
      if (parseFloat(seq_no3) === parseFloat(rulestypes_seq_nos[i].seq_no) && rulestypes_seq_nos[i].id !== title_id) {
        return false;
      }
    }
    return true;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const user_id = localStorage.getItem('user_id')
    this.props.form.validateFields(['seq_no1','description1','description_ar1'],(err, values) => {
      if (!err) {
        this.setState({ loading2 : true });
        let noRepeatFlag = this.checkAllUniqueSeqNo(values.seq_no1);
        if (!noRepeatFlag) {
          this.setState({loading2 : false});
          message.info(this.lang.MESSAGE.SEQNOREPEATERROR,3);
        }
        else{
          let multiRules = [];

          for (let j = 0; j < this.refArrayEn.length; j++) {
            if (this.refArrayEn[j] && this.refArrayAr[j] && this.refArraySeq[j]) {
              let d = this.refArrayEn[j].state.value ? this.refArrayEn[j].state.value : '';
              let dr = this.refArrayAr[j].state.value ? this.refArrayAr[j].state.value : '';

              let seq_no = this.refArraySeq[j].inputNumberRef.input.ariaValueNow ; 
              let description = d ? d :  dr;
              let description_ar = dr ? dr : d ;
              if ( description.length > 0 && description_ar.length > 0 ) {//We can't afford empty Rule
                let obj = { description , description_ar, seq_no : seq_no};
                multiRules.push(obj);            
              }
            }
          }//End of Loop of refArrayEn

          let data = {
            rule_type_id : this.state.updateRuleTitleObj.id,
            user_id : user_id,
            seq_no : values.seq_no1,
            description: values.description1,
            description_ar: values.description_ar1,
            multiRules : JSON.stringify(multiRules)
          } 
          let headers = getHeadersForHttpReq();
          
          axios.post(`${complete_URL}/rules/add`, data,{headers})
            .then(res => {
              if (res.data.success) {
                this.props.form.setFieldsValue({seq_no1 : ''});
                this.props.form.setFieldsValue({description1 : ''});
                this.props.form.setFieldsValue({description_ar1 : ''});
                this.resetRefArrays();
                this.getRulesByRuleType(this.state.updateRuleTitleObj.id);
                this.getAllSeqNoByTitleID('rules', 'rules_seq_nos');
                this.setState({ loading2 : false, ruleLines : [] });
                message.success(this.lang.MESSAGE.ADDRULESUCCESS,2);
              }
              else{
                this.setState({ loading2 : false});
                if(res.data.code==='001') {
                  message.error(this.lang.MESSAGE.DATANOTFOUND,3);
                }
                else{
                  message.error(this.lang.MESSAGE.ADDRULEFAILURE,3)
                }
              }
            })
            .catch(err => {
              console.log('Error occured while adding the Rule Desciption->',err);
              this.setState({ loading2 : false,},()=>{
                message.error(this.lang.MESSAGE.ADDRULEFAILURE,2);
              })
            })
        }//End of no repeat Seq no(s)
      }//End of if error not found
    })
  }//End of Method

  
  handleSubmitUpdateTitle=(e)=>{
    e.preventDefault();
    const user_id = localStorage.getItem('user_id');
    const client_id = localStorage.getItem('client_id');
    this.props.form.validateFields(['rule_title3','rule_title_ar3','seq_no3','title_desc3'],(err, values) => {
      if (!err) {
        this.setState({loading1 : true});
        let noRepeatFlag = this.checkUpdateTitleUniqueSeqNo(values.seq_no3);
        if (!noRepeatFlag) {
          this.setState({loading1 : false});
          message.info(this.lang.MESSAGE.SEQNOREPEATERROR,3);
        }
        else{
          const data = {
            id: this.state.updateRuleTitleObj.id,
            client_id: client_id,
            user_id : user_id, 
            bqt_id : this.props.bqt_id,
            // rule_parent: this.state.updateRuleTitleObj.rule_parent,
            rule_title: values.rule_title3 ? values.rule_title3: '',
            rule_title_ar: values.rule_title_ar3 ? values.rule_title_ar3: '',
            seq_no : values.seq_no3,
            title_desc : values.title_desc3 ? values.title_desc3 : '',
          }
          let  headers = getHeadersForHttpReq();
          
          axios.put(`${complete_URL}/rulestypes/update`, data,{headers})
            .then(res => {
              this.setState({ loading1 : false })
              if (res.data.success) {
                message.success(this.lang.MESSAGE.UPDATERULETITLESUCCESS,2);
                this.props.form.setFieldsValue({rule_title3: ''});
                this.props.form.setFieldsValue({rule_title_ar3: ''});
                this.props.form.setFieldsValue({seq_no3: ''});
                this.props.form.setFieldsValue({title_desc3: ''});
                // this.setState({screen_opt: 'table'});
                this.props.openTitleTableByParent();
              }
              else{
                  message.error(this.lang.MESSAGE.UPDATERULETITLEFAILURE,3)
              }
            })
            .catch(err => {
              console.log('Error occured while adding the Rule Title->',err);
              this.setState({ loading1 : false,},()=>{
                message.error(this.lang.MESSAGE.UPDATERULETITLEFAILURE,2);
              })
            })
        }
      }//End of if error not found
    })
  }

  removeAddedRuleLine=(index)=>{
    if ( this.state.ruleLines.length === 1 ) {
      this.resetRefArrays();
      this.setState({ruleLines:[]});
    }
    else{
      this.refArraySeq = this.props.deleteItemFromArrayByParent(this.refArraySeq, index);
      this.refArrayEn = this.props.deleteItemFromArrayByParent(this.refArrayEn, index);
      this.refArrayAr = this.props.deleteItemFromArrayByParent(this.refArrayAr, index);
      this.refArray = this.props.deleteItemFromArrayByParent(this.refArray, index);
      
      let oldLines = Object.assign([],this.state.ruleLines);
      oldLines.splice(index,1);
      this.setState({ruleLines : oldLines },()=>{
        if (oldLines.length === 0) {//Confirm to remove all refs
          this.resetRefArrays();
        }
      });
    }
  }//End of removeAddedTitleLine


  addRuleLine=()=>{
    let { ruleLines , max_rule_seq_no } = this.state;

    let line = 
      <div key={'row_main_2'+ruleLines.length} ref= {(node)=>{this.refArray.push(node)}} >
      <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
          <Col span={23}></Col>
          <Col span={1}>
      <Icon type="close" onClick={() => {this.removeAddedRuleLine(ruleLines.length)}} style={{fontSize: '18px'}} title = {this.lang.BUTTON.DELETE} />
          </Col>
      </Row>
      <Row gutter={24} key={'row_en_2'+ruleLines.length} dir= {this.state.dir} style={{display : this.state.display,marginBottom:'12px' }}>
        <Col span={5}>
          <InputNumber min={0} defaultValue={(parseFloat(max_rule_seq_no) + ruleLines.length + 2)} ref= {(node)=>{this.refArraySeq.push(node)}} />
        </Col>
        <Col span={19}>
          <Input.TextArea required rows={2} dir={'ltr'} ref= {(node)=>{this.refArrayEn.push(node)}} placeholder={this.lang.LABELS.RULE} />
        </Col>
        </Row>
      <Row gutter={24} key={'row_ar_2'+ruleLines.length} dir= {this.state.dir} style={{display : this.state.display,marginBottom:'12px' }}>
      <Col span={5}></Col>
        <Col span={19}>
          <Input.TextArea required rows={2} dir={'rtl'} ref= {(node)=>{this.refArrayAr.push(node)}} placeholder={this.lang.LABELS.RULEAR} />
        </Col>
      </Row>
      <br/>
      </div>

    let oldLines = Object.assign([], ruleLines);
    this.setState({ruleLines : oldLines.concat(line) })
  }//End of Method
  

  getAllSeqNoByTitleID = (route, key)=>{
    const headers = getHeadersForHttpReq();
    const titleObj = JSON.parse(localStorage.getItem('updRuleTitle'));
    if (titleObj) {
      axios.get(`${complete_URL}/${route}/all_seq_no/${titleObj.id}`,{headers})
        .then(res => {  //console.log('all_seq_no ->',res.data.data)
          let max_rule_seq_no = this.props.getMaxSeqNoByParent(res.data.data);
          this.setState({ [key] : res.data.data, max_rule_seq_no : parseFloat(max_rule_seq_no).toFixed(1) } )
        })
        .catch(err => { console.log('Error occured while fetching all seq no->',err) })
    }
  }

  getAllSeqNosOfTitles = (route, key)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/${route}/all_seq_no`,{headers})
      .then(res => {  //console.log('all_seq_no ->',res.data.data)
        let max_seq_no = this.props.getMaxSeqNoByParent(res.data.data)
        this.setState({ [key] : res.data.data, max_seq_no : parseFloat(max_seq_no).toFixed(1) } )
      })
      .catch(err => { console.log('Error occured while fetching all seq no->',err) })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    let is_EN = localStorage.getItem('lang') === 'EN';

    let {rules, ruleLines, max_rule_seq_no } = this.state;

  return (
    <Row gutter={24}>
      <Col span={24}>
      {
        this.state.screen_opt === 'update' &&
          <Spin tip={this.state.spin_msg} spinning = {this.state.spin}>
          <Form onSubmit={this.handleSubmitUpdateTitle} style={{ marginTop: '20px' }}>{/*Title addition */}
          <>
            {this.UpdateRulesHeader}
            
            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
              {/* <Col span={9}>
                <Form.Item label={this.lang.LABELS.RULEPARENTNAME}>
                  {getFieldDecorator('rule_parent3', {
                    initialValue: this.state.updateRuleTitleObj.rule_parent,
                    rules: [{ required: true, message: this.lang.MESSAGE.INSERTNAMEERROR }],
                  })(
                    <Select disabled={true} placeholder={this.lang.LABELS.RULEPARENTNAME} >
                      {
                        (localStorage.getItem('lang') === 'EN') ?
                          rule_parents.length > 0 && rule_parents.map(rp => (
                            <Select.Option key={rp.id} value={rp.id}>{rp.name}</Select.Option>
                          ))
                          :rule_parents.length > 0 &&  rule_parents.map(rp => (
                            <Select.Option style={{textAlign:'right'}} key={rp.id} value={rp.id}>{rp.name_ar}</Select.Option>
                          ))
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col> */}
              <Col span={24}>
                <Form.Item label={this.lang.LABELS.RULETITLE}>
                  {getFieldDecorator('rule_title3', {
                    initialValue: this.state.updateRuleTitleObj.rule_title,
                    rules: [{ required: is_EN, message: this.lang.MESSAGE.INSERTTITLEERROR }],
                  })(
                     <Input.TextArea rows={2} dir={'ltr'} placeholder={this.lang.LABELS.RULETITLE} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
              <Col span={24}>
                <Form.Item label={this.lang.LABELS.RULETITLEAR}>
                  {getFieldDecorator('rule_title_ar3', {
                    initialValue: this.state.updateRuleTitleObj.rule_title_ar,
                    rules: [{ required: !is_EN, message: this.lang.MESSAGE.INSERTTITLEERROR }],
                  })(
                    <Input.TextArea rows={2}  dir={'rtl'} placeholder={this.lang.LABELS.RULETITLEAR} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
            <Col span={5}>
              <Form.Item label={this.lang.LABELS.SEQ_NO}>
                {getFieldDecorator('seq_no3', {
                  initialValue: this.state.updateRuleTitleObj.seq_no,
                  rules: [{ required: true, message: this.lang.MESSAGE.INSERTSEQERROR }],
                })(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
              <Col span={19}>
              <Form.Item label={this.lang.LABELS.DESCRIPTION}>
                {getFieldDecorator('title_desc3', {
                  initialValue: this.state.updateRuleTitleObj.title_desc,
                  rules: [{ required: false, message: this.lang.MESSAGE.INSERTTITLEDESCERROR }],
                })(
                  <Input.TextArea rows={2} dir= {this.state.dir} placeholder={this.lang.LABELS.DESCRIPTION} />
                )}
              </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
            <Col span={8}></Col>
            <Col span={8}>
              <Button type="primary" block htmlType="submit" loading={this.state.loading1}>
                {this.lang.BUTTON.UPDATETITLE} 
              </Button>
            </Col>
            <Col span={8}></Col>
            </Row>
          </>
          </Form>
          <br />
          <hr /> {/*Divider */}
          <Form onSubmit={this.handleSubmit} style={{ marginTop: '20px' }}>
            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>{/*Headings of Rule(s) addition */}
              <Col span={8}></Col>
                  <Col span={8} style={{textAlign:'center'}}><h1 style={{ fontSize: '22px' }}>{this.lang.CREATE.LABELS.TITLE}</h1></Col>
              <Col span={8}></Col>
            </Row>

            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
              <Col span={5}>
              <Form.Item label={this.lang.LABELS.SEQ_NO}>
                {getFieldDecorator('seq_no1', {
                  initialValue: (parseFloat(max_rule_seq_no) + 1),
                  rules: [{ required: true, message: this.lang.MESSAGE.INSERTSEQERROR }],
                })(
                  <InputNumber min={0} step={0.1} style={{ width: '100%' }}/>
                )}
              </Form.Item>
            </Col>
              <Col span={19}>
                <Form.Item label={this.lang.LABELS.RULE}>
                  {getFieldDecorator('description1', {
                    rules: [{ required: is_EN, message: this.lang.MESSAGE.INSERTDESCRIPTIONERROR }],
                  })(
                    <Input.TextArea dir={'ltr'} rows={3} placeholder={this.lang.LABELS.RULE} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
              <Col span={5} style={{ marginTop: '50px' }}>
                <Button style={{backgroundColor:'#28a745', color:'white'}} onClick={() =>this.addRuleLine()}> {this.lang.BUTTON.ADD}  </Button>
              </Col>  
              <Col span={19}>
                <Form.Item label={this.lang.LABELS.RULEAR}>
                  {getFieldDecorator('description_ar1', {
                    rules: [{ required: !is_EN, message: this.lang.MESSAGE.INSERTDESCRIPTIONERROR }],
                  })(
                    <Input.TextArea dir={'rtl'} rows={3} placeholder={this.lang.LABELS.RULEAR} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {ruleLines}
            <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
              <Col span={8}></Col>
              <Col span={8}>
                <Button type="primary" block htmlType="submit" loading={this.state.loading2}>
                  {this.lang.BUTTON.SAVE}  
                </Button>
              </Col>
              <Col span={8} ></Col>
            </Row>
          </Form>
          <br />
          <h1 style={{ fontSize: '22px' }}>{this.lang.LABELS.RULES}</h1>{/*Label of Rules */}
          <hr/>
          <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
            <Col span={is_EN ? 3 : 4}><p style={{ color:'#000',fontSize: '13px', fontWeight: 'bolder' }}>{this.lang.LABELS.SEQ_NO}</p> </Col>
            <Col span={is_EN ? 21 : 20}><p style={{ color:'#000',fontSize: '13px', fontWeight: 'bolder'}}>{is_EN ? this.lang.LABELS.RULE : this.lang.LABELS.RULEAR}</p> </Col>
          </Row>
          <hr/>
          <List
            rowKey = {'id'}
            itemLayout="horizontal"
            dataSource={rules}
            renderItem={(item, index )=> (
          
            <List.Item  actions={[<Button type="primary" onClick={() => {this.deleteCategory(item,'rules')}}>{this.lang.BUTTON.DELETE}</Button>]}>
                <List.Item.Meta
                  // title={is_EN ? this.state.updateRuleTitleObj.rule_title : this.state.updateRuleTitleObj.rule_title_ar}
                  // description = {is_EN ? item.description : item.description_ar}
                  description = {
                    <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                      <Col span={is_EN ? 3 : 4}><p style={{ color:'#000', textAlign:'center' }}>{item.seq_no}</p> </Col>
                      <Col span={is_EN ? 21 : 20}><p style={{ color:'#000'}}>{is_EN ? item.description : item.description_ar}</p> </Col>
                    </Row>
                  }
                />
              </List.Item>
            )}
          />
        </Spin>
      }
      </Col>
    </Row>
    );
  }//End of RENDER
}//End of Component

export default RuleTitleUpdate = Form.create({ name: 'normal_login' })(RuleTitleUpdate);
