import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, Card, message, Table } from 'antd'
// import { Link } from 'react-router-dom'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const TextArea = Input.TextArea;
const Search = Input.Search;
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class IncomeType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      IncomeTypeFormVisible: false,
      IncomeTypeTableVisible: false,
      IncomeTypeUpdateVisible : false,
      // data: {},
      data: [],
      sortField:"name",
      sortOrder:"ascend",
      pagination: {},
      page: 1,
      size: 5,
      searchValues: {
        name: '',
        name_ar: '',
        description: '',
        orderBy: '',
        column_name: 'name'
      },
      incomeTypeObj : {  },
      dir : 'ltr', 
      display: 'block'
    }
    this.lang = props.language._INCOMETYPE;
    this.msg = props.language._INCOMETYPE.MESSAGE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (<span>{this.lang.SETUP.TABLEHEADERS.NAME}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.NAME}
                    onChange={this.handleChangeSearchValues("name")}
                  />
                </span>
        ),
        dataIndex: 'name',
        key: 'name',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: ( <span>{this.lang.SETUP.TABLEHEADERS.NAME}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.NAME}
                    onChange={this.handleChangeSearchValues("name")}
                  />
                </span>
        ),
        dataIndex: 'name',
        key: 'name',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: ( <span>{this.lang.SETUP.TABLEHEADERS.NAMEAR}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.NAMEAR}
                    onChange={this.handleChangeSearchValues("name_ar")}
                  />
                </span>
        ),
        dataIndex: 'name_ar',
        key: 'name_ar',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: ( <span>{this.lang.SETUP.TABLEHEADERS.NAMEAR}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.NAMEAR}
                    onChange={this.handleChangeSearchValues("name_ar")}
                  />
                </span>
        ),
        dataIndex: 'name_ar',
        key: 'name_ar',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: ( <span>{this.lang.SETUP.TABLEHEADERS.DESCRIPTION}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.DESCRIPTION}
                    onChange={this.handleChangeSearchValues("description")}
                  />
                </span>
        ),
        dataIndex: 'description',
        key: 'description',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.description, b.description),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: ( <span>{this.lang.SETUP.TABLEHEADERS.DESCRIPTION}
                  <Search
                    placeholder={this.lang.SETUP.TABLEHEADERS.DESCRIPTION}
                    onChange={this.handleChangeSearchValues("description")}
                  />
                </span>
        ),
        dataIndex: 'description',
        key: 'description',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.description, b.description),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      {
        title: `${this.lang.SETUP.BUTTON.DELETE}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.SETUP.BUTTON.DELETE}</Button>
          </span>
        ),
        key: 'action',
        align : 'center',
      }
    ];
  }

  setComponentDirection=()=>{
    if (localStorage.getItem('lang')==='EN') {
       this.setState({dir : 'ltr', display: 'block'});
     }
     else {
       this.setState({dir : 'rtl', display: 'flex'});
     }
  }

  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    this.setState({ incomeTypeObj : record,
                    IncomeTypeUpdateVisible : true,
                    IncomeTypeFormVisible : false })
  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
         pagination: pager,
         pageNumber: pagination.current
      });

      if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
        if (this.state.pageNumber  !== pager.current) {
          this.fetch({ page: pagination.current });
        }
      } 
      else {
         this.fetch({
         results: pagination.pageSize,
         page: pagination.current,
         sortField: sorter.field,
         sortOrder: sorter.order,
         ...filters,
         });
      }

    /*let pager = { ...this.state.pagination };
    pager.current = pagination.current;

    let searchValues = { ...this.state.searchValues }
    searchValues.column_name = sorter.field
    searchValues.orderBy = sorter.order

    this.setState({
      page: pager.current,
      pagination: pager,
      searchValues
    }, () => {   this.fetch();})*/
  
  }//End of method

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }

  HandleIncomeTypeFormVisibility = () => {
    this.setState({ IncomeTypeFormVisible: !this.state.IncomeTypeFormVisible },()=>{
      if(this.state.IncomeTypeFormVisible){
        this.setState({IncomeTypeUpdateVisible : false});
      }
    })
  }

  HandleIncomeTypeUpdateFormVisibility = () => {
    this.setState({ IncomeTypeUpdateVisible : !this.state.IncomeTypeUpdateVisible })
  }

  HandleIncomeTypeTableVisibility = () => {
    this.setState({ IncomeTypeTableVisible: !this.state.IncomeTypeTableVisible })
  }

  componentDidMount() {
    const client_id = localStorage.getItem('client_id');
    this.getIncomeTypes(client_id);
    this.fetch();
    this.setComponentDirection();
  }

  fetch = (params={}) => {
    const headers = getHeadersForHttpReq();
    const client_id = localStorage.getItem('client_id');
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else { sortOrder = 'DESC' }
    
    let data ={
      client_id : client_id,
      name: this.state.searchValues.name || '',
      name_ar: this.state.searchValues.name_ar || '',
      description: this.state.searchValues.description || '',
      
      page,
      sortColumn: params.sortField || this.state.sortField ,
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'name'
    }
    // console.log('Sending data to api ->',data);
    axios.post(`${complete_URL}/incometypes/pagination_table`,data, {headers})
    .then(response => {
      // console.log('response of exp new pagination ->',response)
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      this.setState({ data: response.data.data, pagination })
      // console.log('incometypes data-> ',response);
    })
    .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })

    /*axios.get(`${complete_URL}/incometypes/by_clients/table?id=${client_id}&page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`,{headers})
      .then(res => {
        let pagination = { ...this.state.pagination };
        pagination.total = res.data.totalElements;
        pagination.pageSize = parseInt(res.data.size);
        this.setState({
          data: res.data,
          pagination: pagination,
        })
      })
      .catch(err => {  console.log(err)  })*/
      
  }

  getIncomeTypes = (client_id)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/incometypes/by_client/${client_id}`,{headers})
    .then(res => { // console.log('income_types->',res)
      this.setState({ income_type: res.data.data })
    })
    .catch(err => { console.log('Issue while fetching income_types ->',err) })
  }

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/incometypes/${record.id}`,{headers})
      .then(res => { //message.success('Income Type Has been deleted') // console.log(res)
        if(res.data.success){
          message.success(this.lang.MESSAGE.DELETESUCCESS);
          this.fetch()
        }
        else if (res.data.code === '001') {
          message.error(this.lang.MESSAGE.DELETEDEPENDENCYFAILURE,3)
        }
        else{
          message.error(this.lang.MESSAGE.DELETEFAILURE);
        }
      })
      .catch(err => {
        message.error(this.lang.MESSAGE.DELETEFAILURE);
        console.log('Error occured while deleting the Income Type->',err)
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['name','name_ar','description'],(err, values) => {
      if (!err) {
        values.user_id = localStorage.getItem('user_id')
        values.client_id = localStorage.getItem('client_id')
        // console.log(values)
        // if(values.name && values.name_ar ) {
          const headers = getHeadersForHttpReq();
          axios.post(`${complete_URL}/incometypes/add`, values,{headers})
            .then(res => {
              if (res.data.success) {
                message.success(this.lang.MESSAGE.ADDSUCCESS);
                this.fetch();
                values = null;
                this.props.form.resetFields();
              } 
              else { message.error(this.lang.MESSAGE.ADDFAILURE,3); }
            })
            .catch(err => {
              message.error(this.lang.MESSAGE.ADDFAILURE,3);
              console.log('Error occured while adding new Income type ->',err);
            });
        // }
        // else{
        //   message.error(this.lang.MESSAGE.DATAERROR,2);
        // }
      }
      // else{
      //   message.error(this.lang.MESSAGE.ADDFAILURE,3);
      //   console.log('Error occured while inserting new Income type->',err);
      // }
    });
  }//End of method

  handleNameChange = (rule, value, callback)=>{
    if(value ) {
      if(this.state.income_type.length > 0){
        let exists_income = this.state.income_type.filter(income_typ => income_typ.name.replace(/\s/g, '') === value.replace(/\s/g, ''));
        try {
          if( Object.keys(exists_income).length > 0 ){          
            rule.message = this.lang.MESSAGE.NAMEEXISTSERROR;
            throw new Error(this.lang.MESSAGE.NAMEEXISTSERROR);
            callback()
          }
          callback();
        } catch (err) {
          callback(err);
        }
      }
      callback();
    }//End of if Value input
    else{
      try {
          rule.message = this.lang.MESSAGE.INSERTNAMEERROR;
          throw new Error(this.lang.MESSAGE.INSERTNAMEERROR);
          callback();
        } catch (err) {
          callback(err);
        }
    }
  }

  handleNameArChange = (rule, value, callback)=>{
    if(value ) {
      if(this.state.income_type.length > 0){
      let exists_income = this.state.income_type.filter(income_typ => income_typ.name_ar.replace(/\s/g, '') === value.replace(/\s/g, ''));
        try {
          if( Object.keys(exists_income).length > 0 ){          
            rule.message =  this.lang.MESSAGE.NAMEAREXISTSERROR;
            throw new Error( this.lang.MESSAGE.NAMEAREXISTSERROR);
            callback()
          }
          callback();
        } catch (err) {
          callback(err);
        }
      }
      callback();
    }
    else{
      try {
        rule.message = this.lang.MESSAGE.INSERTNAMEARERROR;
        throw new Error(this.lang.MESSAGE.INSERTNAMEARERROR);
        callback();
      } catch (err) {
        callback(err);
      }
    }
  }
  
  handleUpdateSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.user_id = localStorage.getItem('user_id');
        values.client_id = localStorage.getItem('client_id');
        const headers = getHeadersForHttpReq();
        axios.put(`${complete_URL}/incometypes/${this.state.incomeTypeObj.id}`, values, {headers})
          .then(res => {
            if (res.data.success) {
              message.success(this.lang.MESSAGE.UPDATESUCCESS);
              this.fetch();
            } 
            else { message.error(this.lang.MESSAGE.UPDATEFAILURE,3); }
          })
          .catch(err => {
            message.error(this.lang.MESSAGE.UPDATEFAILURE,3);
            console.log('Error occured while updating Income Type->',err);
          })
          this.props.form.resetFields();
          e.target.reset();
          this.setState({incomeTypeObj :  {  name : '',name_ar : '', description : '' } }, ()=>{
            this.HandleIncomeTypeUpdateFormVisibility();  
          })
      }
    });
  }//End of method

  componentWillMount() {
     
      this.ModalHeader = <Row gutter={24}>
        <Col span={12} style={{ paddingLeft: '15px', marginBottom: '10px' }}>
          <Button 
            onClick={this.HandleIncomeTypeTableVisibility} 
            style={{ float: 'left', marginLeft : '-5%' }} 
            type="primary">{this.lang.SETUP.BUTTON.DISPLAYINCOMETYPES}
          </Button>
        </Col>
        <Col span={12} style={{ paddingRight: 0, marginBottom: '10px' }}>
          <Button 
            onClick={this.HandleIncomeTypeFormVisibility} 
            style={{ float: 'right' }} 
            type="primary">
              {this.lang.SETUP.BUTTON.ADD}
          </Button>
        </Col>
      </Row>
      this.InsertIncomeTypeHeader = <Row gutter={24} className="top-header" style={{ paddingTop: 0, paddingBottom: 0 }} >
        <Col span={3} >
          <Button onClick={this.HandleIncomeTypeFormVisibility} type="danger"><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={14}>
          <h1  style={{ textAlign: 'center' }}>{this.lang.CREATE.LABELS.INSERTTITLE}</h1>
        </Col>
        <Col span={6} className="btn-box" style={{ marginTop: '-24px', marginRight: '12px', float: "right", display: 'flex' }} >
          <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Button style={{marginLeft: '20px'}} onClick={this.HandleIncomeTypeFormVisibility} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
        </Col>
      </Row>
      this.UpdateIncomeTypeHeader = <Row gutter={24} className="top-header" style={{ paddingTop: 0, paddingBottom: 0 }} >
      <Col span={3} >
          <Button onClick={this.HandleIncomeTypeFormVisibility} type="danger"><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={14}>
          <h1  style={{ textAlign: 'center' }}>{this.lang.CREATE.LABELS.UPDATETITLE}</h1>
        </Col>
        <Col span={6} className="btn-box" style={{ marginTop: '-24px', marginRight: '12px', float: "right", display: 'flex' }} >
          <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Button style={{marginLeft: '20px'}} onClick={this.HandleIncomeTypeFormVisibility} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
        </Col>
    </Row>
    
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let titleAreaStyle = {textAlign:"center", marginTop: '-1.5%', marginBottom: '-2%', fontSize: '23px', fontWeight : '400', color: 'black'};
    let { dir, display, data, pagination, IncomeTypeFormVisible,
      IncomeTypeTableVisible, IncomeTypeUpdateVisible } = this.state;
      // console.log('lang, dir, display ->',localStorage.getItem('lang'),dir, display);

    return (
      <>
        {this.ModalHeader}
        {IncomeTypeFormVisible  &&
          <Card style={{ marginLeft: '-12px', marginRight: '-12px' }}>
            <Form id = {'incomeTypeInsertId'} onSubmit={this.handleSubmit}>
              {this.InsertIncomeTypeHeader}
              <Row gutter={24} dir = {dir} style={{display}} >
                <Col span={12} style={{marginTop : '-10px',marginBottom : '-10px' }}>
                  <Form.Item label={this.lang.SETUP.TABLEHEADERS.NAME}                             >
                    {getFieldDecorator('name', {
                      rules: [{ required: true, validator : (rule,value, callback)=> this.handleNameChange(rule,value, callback) , message: this.lang.MESSAGE.INSERTNAMEERROR }],
                    })(
                      <Input type="text" placeholder = {this.lang.SETUP.TABLEHEADERS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} style={{marginTop : '-10px',marginBottom : '-10px' }}>
                  <Form.Item label= {this.lang.SETUP.TABLEHEADERS.NAMEAR} >
                    {getFieldDecorator('name_ar', {
                      rules: [{ required: false, validator : (rule,value, callback)=> this.handleNameArChange(rule,value, callback) , message: this.lang.MESSAGE.INSERTNAMEARERROR }],
                    })(
                      <Input type="text" placeholder= {this.lang.SETUP.TABLEHEADERS.NAMEAR} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}} >
                <Col span={24} style={{marginTop : '-10px',marginBottom : '-10px' }}>
                  <Form.Item label={this.lang.SETUP.TABLEHEADERS.DESCRIPTION} >
                    {getFieldDecorator('description', {
                      rules: [{ required: false, message: this.lang.MESSAGE.INSERTDESCRIPTIONERROR }],
                    })(
                      <TextArea type="note" id = 'description_id' placeholder={this.lang.SETUP.TABLEHEADERS.DESCRIPTION} style={{ width: '100%' }}  autoSize={{ minRows: 2, maxRows: 6 }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        }
        {IncomeTypeUpdateVisible  &&
          <Card style={{ marginLeft: '-12px', marginRight: '-12px' }}>
            <Form  onSubmit={this.handleUpdateSubmit}>
              {this.UpdateIncomeTypeHeader}
              <Row gutter={24} dir = {dir} style={{display}} >
                <Col span={12} style={{marginTop : '-10px',marginBottom : '-10px' }}>
                  <Form.Item label={this.lang.SETUP.TABLEHEADERS.NAME} >
                    {getFieldDecorator('name_Update', {
                      initialValue: this.state.incomeTypeObj.name,
                      rules: [{ required: true, validator : (rule,value, callback)=> this.handleNameChange(rule,value, callback) , message: this.lang.MESSAGE.INSERTNAMEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.SETUP.TABLEHEADERS.NAME}  />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} style={{marginTop : '-10px',marginBottom : '-10px' }}>
                  <Form.Item label={this.lang.SETUP.TABLEHEADERS.NAMEAR}>
                    {getFieldDecorator('name_ar_Update', {
                      initialValue: this.state.incomeTypeObj.name_ar,
                      rules: [{ required: false,  validator : (rule,value, callback)=> this.handleNameArChange(rule,value, callback) , message: this.lang.MESSAGE.INSERTNAMEARERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.SETUP.TABLEHEADERS.NAMEAR} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}} >
                <Col span={24} style={{marginTop : '-10px',marginBottom : '-10px' }}>
                  <Form.Item label={this.lang.SETUP.TABLEHEADERS.DESCRIPTION}>
                    {getFieldDecorator('description_Update', {
                      initialValue: this.state.incomeTypeObj.description,
                      rules: [{ required: false, message: this.lang.MESSAGE.INSERTDESCRIPTIONERROR }],
                    })(
                      <TextArea type="note" placeholder={this.lang.SETUP.TABLEHEADERS.DESCRIPTION} style={{ width: '100%' }}  autoSize={{ minRows: 2, maxRows: 6 }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        }  
        {IncomeTypeTableVisible &&
          <Card style={{marginTop : '1%', marginLeft: '-12px', marginRight: '-12px' }} >
            <Row gutter={24}>
              <Col span={24} style={titleAreaStyle}>
                <p>{this.lang.TITLE}</p>
              </Col>
            </Row>
            <Row gutter={24} id="clientsTable" dir= {dir}>
              <Col span={24} >
                  <Table
                    size={'small'}
                    columns={this.columns}
                    rowKey="id"
                    // dataSource={this.state.data.selectedElements}
                    dataSource={data}
                    onChange={this.handleChangePagination}
                    pagination={pagination}
                  />
              </Col>
            </Row>
          </Card>
        }
      </>
    )
  }
}

export default IncomeType = Form.create({ name: 'income_types_form' })(IncomeType);