import React, { Component } from 'react';
import {Row, Col, Form, Icon, Button, Input, InputNumber, 
  Card, message, DatePicker, Select, Collapse } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import ExpenseDocuments from '../ExpenseDocuments';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Panel = Collapse.Panel;
const TextArea = Input.TextArea;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class ExpenseUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expense_type: [],
      expense: {},
      img: ''
    };

    this.lang = props.language._EXPENSE;
    this.msg = props.language._EXPENSE.MESSAGE;
    //Extra Columns.. not being used..
    this.columns = [
      {
        title: 'From Date',
        dataIndex: 'from_date',
        render: (text, record) => <button className="link-button"
          onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      {
        title: 'To Date',
        dataIndex: 'to_date',
      },
      {
        title: ' Discount',
        dataIndex: 'discount',
      },
      {
        title: 'Actions',
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>DELETE</Button>
          </span>
        ),
        width: '30%',
        key: 'last_name',
      }
    ];

    //Extra Columns.. not being used..
    this.colms_price = [
      {
        title: 'Price',
        dataIndex: 'price',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,

      },
      {
        title: 'Unit Of Price',
        dataIndex: 'uop_name',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,

      },
      {
        title: 'Actions',
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deletePrice.bind(this, record)}>DELETE</Button>
          </span>
        ),
        width: '30%',
        key: 'last_name',
      }
    ]
  }

  //Extra method.. not being used..
  handlePriceNavigation = () => { }

  componentDidMount() {
    const client_id = localStorage.getItem('client_id');
    this.fetch();
    this.getExpenseTypes(client_id);  
  }

  fetch = () => {
    const expense_id = JSON.parse(localStorage.getItem('updateExpense')).id
    this.getSelectedExpense(expense_id);
  }

  getSelectedExpense=(expense_id)=>{
    if (expense_id) {
      axios.get(`${complete_URL}/expenses/by_id/${expense_id}`)
        .then(res => {
          this.setState({ expense: res.data.data[0] })
          // console.log(res.data.data[0])
          // this.selectExpense(res.data.data[0].expense_id)
        })
        .catch(err => { console.log('Error occured while fetching selected Expense data->',err) });
    }
    else{message.error(this.lang.MESSAGE.EXPENSENOTFOUND,3);  }
  }

  getExpenseTypes = (client_id)=>{
    if (client_id) {
      axios.get(`${complete_URL}/expensetypes/by_client/${client_id}`)
        .then(res => { // console.log('expense_types->',res)
          this.setState({ expense_type: res.data.data })
        })
        .catch(err => {  console.log('Issue while fetching expense_types ->',err) })
    }
    else{ message.error(this.lang.MESSAGE.LOGINERROR,3);  }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    
    this.props.form.validateFields((err, values) => {
      if (!err) { 
        if (parseFloat(values.amount) > 0) {
        const headers = getHeadersForHttpReq();
        const user_id = localStorage.getItem('user_id');
        const client_id = localStorage.getItem('client_id');
        let is_contr = localStorage.getItem('is_contr');
        let a = JSON.parse(localStorage.getItem('updateExpense'));
        let d = moment(values.date).locale('en').format('YYYY-MM-DD');

        if (is_contr != 1) {
          Object.assign(values,{user_id, client_id, contractor_id : null, is_contr : 0, date : d});
        }
        else{
          const contractor_id = JSON.parse(localStorage.getItem('updateContractor')).id;
          Object.assign(values,{user_id, contractor_id, client_id : null, is_contr : 1, date : d});
        }
        axios.put(`${complete_URL}/expenses/${a.id}`, values, {headers})
          .then(res => {
            if (res.data.success) {
              message.success(this.msg.UPDATESUCCESS/*res.data.message*/);
              let exp_route = is_contr != 1 ?  'expenses' : 'expenses_contr';
              this.props.history.push(exp_route);
            } 
            else {
              message.error(this.msg.UPDATEFAILURE,3/*res.data.message*/)
            }
          })
          .catch(err => {
            message.error(this.msg.UPDATEFAILURE,3);
            console.log('Error occured while updating the Expense Record->',err);
          })
        } //End of positive value
        else{
         message.error(this.props.language.INSERTPOSITIVEAMOUNTWARN,3);
        }
      }//End of no error
     
    })
  }

  componentWillMount() {
    let nav_route = '/dashboard/expenses';
    if (localStorage.getItem('is_contr') == 1){
      nav_route = '/dashboard/expenses_contr'
    }
    
    if (localStorage.getItem('lang') === 'EN') {
      this.ExpenseUpdateHeader = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to = { nav_route }>
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={12}> <h1 style={{textAlign: 'center'}}>{this.lang.UPDATE.EXPENSEUPDATE.LABELS.TITLE}</h1> </Col>
        <Col className="btn-box" span={5} style={{display: 'contents'}}>
          <Link to = { nav_route }>
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
    else {
      this.ExpenseUpdateHeader = <Row className="top-header" gutter={24}>
        <Col span={3}>
          <Link to = { nav_route }>
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign: 'center'}}>{this.lang.UPDATE.EXPENSEUPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={6}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to = { nav_route }>
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let { dir, display, expense_type } = this.state;

    // console.log('this.lang ->',this.lang);

    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}> </Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.ExpenseUpdateHeader}
                 
              <Row gutter={24} dir = {dir} style={{display}} >
                <Col span={12}>
                  <Form.Item label={this.lang.UPDATE.EXPENSEUPDATE.LABELS.AMOUNT}>
                    {getFieldDecorator('amount', {
                      initialValue: this.state.expense.amount,
                      rules: [{ required: true, message: this.lang.MESSAGE.INSERTAMOUNTERROR }],
                    })(
                      <InputNumber min={0} style={{width: '100%' }} placeholder={this.lang.UPDATE.EXPENSEUPDATE.LABELS.AMOUNT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label= {this.lang.UPDATE.EXPENSEUPDATE.LABELS.BILLNO}>
                    {
                        getFieldDecorator('bill', {
                          initialValue: this.state.expense.BILL_NO,
                          rules: [{ required: true, message: this.lang.MESSAGE.INSERTBILLNOERROR }],
                        })(
                        <Input type="text" placeholder={this.lang.UPDATE.EXPENSEUPDATE.LABELS.BILLNO}/>
                        )
                    }
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}} >
                <Col span={12}>
                  <Form.Item label={this.lang.UPDATE.EXPENSEUPDATE.LABELS.EXPENSE}>
                  { getFieldDecorator('expense', {
                    initialValue: this.state.expense.new_expense_type_id,
                    rules: [{ required: true, message: this.lang.MESSAGE.SELECTEXPENSEERROR }],
                    })(
                      <Select onChange={this.selectExpense} placeholder={this.lang.UPDATE.EXPENSEUPDATE.LABELS.EXPENSE}>
                        {
                          localStorage.getItem('lang') ==='EN' ?
                            expense_type.length > 0 && expense_type.map(item => (
                              <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            )) :
                            expense_type.length > 0 && expense_type.map(item => (
                              <Select.Option style={{textAlign:'right'}} key={item.id} value={item.id}>{item.name_ar}</Select.Option>
                            )) 
                        }
                      </Select>)
                  }
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.UPDATE.EXPENSEUPDATE.LABELS.DATE}                             >
                    {getFieldDecorator('date', {
                      initialValue: moment (this.state.expense.date),
                      rules: [{ required: true, message: this.lang.MESSAGE.INSERTDATEERROR }],
                    })( 
                      <DatePicker style={{width:'100%'}} />
                      )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}} >
                <Col span={24}>
                  <Form.Item label={this.lang.UPDATE.EXPENSEUPDATE.LABELS.NOTE}>
                    {getFieldDecorator('note', {
                      initialValue: this.state.expense.note,
                      rules: [{ required: false, message: this.lang.MESSAGE.INSERTNOTEERROR }],
                    })(
                      <TextArea
                        placeholder={this.lang.UPDATE.EXPENSEUPDATE.LABELS.NOTE}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            

              {/* COMMIT BY KELASH 30JAN2020 */}
              {/* <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={this.lang.UPDATE.EXPENSEUPDATE.LABELS.AMOUNT}>
                    {getFieldDecorator('amount', {
                      initialValue: this.state.expense.amount,
                      rules: [{ required: true, message: this.lang.MESSAGE.INSERTAMOUNTERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.UPDATE.EXPENSEUPDATE.LABELS.AMOUNT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.UPDATE.EXPENSEUPDATE.LABELS.NOTE}>
                    {getFieldDecorator('note', {
                      initialValue: this.state.expense.note,
                      rules: [{ required: false, message: this.lang.MESSAGE.INSERTNOTEERROR }],
                    })(
                      <TextArea

                        placeholder={this.lang.UPDATE.EXPENSEUPDATE.LABELS.NOTE}
                        autosize={{ minRows: 2, maxRows: 6 }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={this.lang.UPDATE.EXPENSEUPDATE.LABELS.EXPENSE}>
                  {getFieldDecorator('expense', {
                    initialValue: this.state.expense.new_expense_type_id,
                    rules: [{ required: true, message: 'Please Select Expense!' }],
                    })(
                      <Select onChange={this.selectExpense} placeholder='Select Expense'>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            this.state.expense_type.map(parent => (
                              <Select.Option key={parent.id} value={parent.id}>{parent.name}</Select.Option>
                            ))
                            : this.state.expense_type.map(parent => (
                              <Select.Option key={parent.id} value={parent.id}>{parent.name_ar}</Select.Option>
                            ))
                        }
                      </Select>)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
              <Col span={12}>
                  <Form.Item label="Date"                             >
                    {getFieldDecorator('date', {
                      initialValue:moment(this.state.expense.date),
                      rules: [{ required: true, message: 'Please Select Expense!' }],
                    })( 
                      <DatePicker format="YYYY-MM-DD"/>
                      )}
                  </Form.Item>
                </Col>
              </Row> */}


              {/* <Form.Item>
                <Row style={{ marginTop: '-20px' }}>
                  <Col span={16}>
                    <p>Upload files under 2MB, and only in png/jpg/jpeg formats</p>
                  </Col>
                </Row>
                {getFieldDecorator('file', {
                  rules: [{ required: false, message: this.firstNameEmptyError }],
                })(
                  <Row gutter={24}>
                    <Col span={4}></Col>
                    <Col span={8}>
                      <Avatar size={80} src={this.state.img || '/logo.png'} alt="log here" />
                    </Col>
                    <Col span={12}>
                      <Input type="file" id="files" className="files" onChange={this.handleImageUpload} />

                    </Col>
                  </Row>
                )}
              </Form.Item> */}
            </Form>
            <Collapse
              accordion bordered={false} 
              expandIconPosition={dir === 'ltr' ? 'left':'right'}
              expandIcon={({ isActive }) =>
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
              {/* <Panel header="Expense Discount" key="1" >
                {
                  //Create Expense Discount Screen
                  this.state.discountList === true ?
                    <Form onSubmit={this.handleSubmitExpenseDiscount} style={{ marginTop: '20px' }}>
                      <Row>
                        <Col span={4}>
                          <Button type="danger" onClick={() => { this.setState({ discountList: false }) }}><Icon type="arrow-left" /></Button>
                        </Col>
                        <Col span={5}></Col>
                        <Col span={6}>
                          <h1 style={{ fontSize: '24px' }}> Expense Discount</h1>
                        </Col>
                        <Col span={5}></Col>
                        <Col span={4}>
                          <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button"> </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col span={8}>
                          <Form.Item label="From Date"                             >
                            {getFieldDecorator('from_date', {
                              setFieldValue: startValue,
                              rules: [{ required: true, message: 'Please input start Date!' }],
                            })(
                              <DatePicker
                                disabledDate={this.disabledStartDate}
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="Start"
                                onChange={this.onStartChange}
                                onOpenChange={this.handleStartOpenChange}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label=" To Date">

                            {getFieldDecorator('to_date', {
                              setFieldValue: endValue,
                              rules: [{ required: true, message: 'Please input End Date!' }],
                            })(
                              <DatePicker
                                disabledDate={this.disabledEndDate}
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="End"
                                onChange={this.onEndChange}
                                open={endOpen}
                                onOpenChange={this.handleEndOpenChange}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Discount">

                            {getFieldDecorator('discount', {
                              setFieldValue: endValue,
                              rules: [{ required: true, message: 'Please input Expense Discount!' }],
                            })(
                              <InputNumber placeholder="Enter Expense Discount" style={{ width: '100%' }} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                    : this.state.discountList === false ?
                      //View Expense Discount Screen
                      <div>
                        <Row id="clientTableHeader">
                          <Col span={9}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Expense Discount Setup</p></Col>
                          <Col span={13}>
                            <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ discountList: true }) }}>+ Add Expense Discount</Button>
                          </Col>
                          <Col span={2}></Col>
                        </Row>
                        <Row id="clientsTable">
                          <Col span={2}></Col>
                          <Col span={20}>
                            <Table columns={this.columns} rowKey="id" dataSource={this.state.discounts} style={{ marginTop: '10px' }} />,
                                    </Col>
                        </Row>
                      </div>
                      //Update Expense Discount Screen
                      :
                      <Form onSubmit={this.handleSubmitExpenseDiscountUpdate.bind(this)} style={{ marginTop: '20px' }}>
                        <Row>
                          <Col span={4}>
                            <Button type="danger" onClick={() => { this.setState({ discountList: false }) }}><Icon type="arrow-left" /></Button>
                          </Col>
                          <Col span={3}></Col>
                          <Col span={8}>
                            <h1 style={{ fontSize: '18px' }}> Expense Discount Update</h1>
                          </Col>
                          <Col span={5}></Col>
                          <Col span={4}>
                            <Form.Item>
                              <Button type="primary" htmlType="submit" className="login-form-button"> </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={8}>
                            <Form.Item label="From Date"                             >
                              {getFieldDecorator('from_date', {
                                initialValue: this.state.updateExpenseDiscount.from_date,
                                setFieldValue: startValue,
                                rules: [{ required: true, message: 'Please input start Date!' }],
                              })(
                                <DatePicker
                                  disabledDate={this.disabledStartDate}
                                  showTime
                                  format="YYYY-MM-DD HH:mm:ss"
                                  placeholder="Start"
                                  onChange={this.onStartChange}
                                  onOpenChange={this.handleStartOpenChange}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label=" To Date">
                              {getFieldDecorator('to_date', {
                                initialValue: this.state.updateExpenseDiscount.to_date,
                                setFieldValue: endValue,
                                rules: [{ required: true, message: 'Please input End Date!' }],
                              })(
                                <DatePicker
                                  disabledDate={this.disabledEndDate}
                                  showTime
                                  format="YYYY-MM-DD HH:mm:ss"
                                  placeholder="End"
                                  onChange={this.onEndChange}
                                  open={endOpen}
                                  onOpenChange={this.handleEndOpenChange}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label="Discount">
                              {getFieldDecorator('discount', {
                                initialValue: this.state.updateExpenseDiscount.discount,
                                setFieldValue: endValue,
                                rules: [{ required: true, message: 'Please input Expense Discount!' }],
                              })(
                                <InputNumber placeholder="Enter Expense Discount" style={{ width: '100%' }} />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                }
              </Panel>
              <Panel header="Expense Price" key="2" > 
                {
                  //Create Expense Price Screen
                  this.state.priceList === true ?
                    <Form onSubmit={this.handleSubmitExpensePrice} style={{ marginTop: '20px' }}>
                      <Row>
                        <Col span={4}>
                          <Button type="danger" onClick={() => { this.setState({ priceList: false }) }}><Icon type="arrow-left" /></Button>
                        </Col>
                        <Col span={5}></Col>
                        <Col span={6}>
                          <h1 style={{ fontSize: '24px' }}> Expense Price</h1>
                        </Col>
                        <Col span={5}></Col>
                        <Col span={4}>
                          <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button"> </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col span={8}>

                          <Form.Item label="Price">
                            {getFieldDecorator('rice', {
                              rules: [{ required: true, message: 'Please input Expense Price!' }],
                            })(
                              <InputNumber step={0.1} placeholder="Enter Expense Price" style={{ width: '100%' }} />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Umit Of Price">
                            {getFieldDecorator('uop', {
                              rules: [{ required: true, message: 'Please Select Unit of Measure' }],
                            })(
                              <Select onChange={this.seclectPrend} disabled={this.state.is_parent}>
                                {
                                  this.state.uop.map(price => (
                                    <Select.Option key={price.id} value={price.id}>{price.name}</Select.Option>
                                  ))
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                    : this.state.priceList === false ?
                      //View Expense Price Screen
                      <div>
                        <Row id="clientTableHeader">
                          <Col span={9}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>Expense Price Setup</p></Col>
                          <Col span={13}>
                            <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ priceList: true }) }}>+ Add Price </Button>
                          </Col>
                          <Col span={2}></Col>
                        </Row>
                        <Row id="clientsTable">
                          <Col span={2}></Col>
                          <Col span={20}>
                            <Table columns={this.colms_price} rowKey="id" dataSource={this.state.prices} style={{ marginTop: '10px' }} />,
                                    </Col>
                        </Row>
                      </div>
                      //Update Expense Price Screen
                      :
                      <Form onSubmit={this.handleSubmitExpensePriceUpdate.bind(this)} style={{ marginTop: '20px' }}>
                        <Row>
                          <Col span={4}>
                            <Button type="danger" onClick={() => { this.setState({ priceList: false }) }}><Icon type="arrow-left" /></Button>
                          </Col>
                          <Col span={3}></Col>
                          <Col span={8}>
                            <h1 style={{ fontSize: '18px' }}> Expense Price Update</h1>
                          </Col>
                          <Col span={5}></Col>
                          <Col span={4}>
                            <Form.Item>
                              <Button type="primary" htmlType="submit" className="login-form-button"> </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>

                          <Col span={8}>
                            <Form.Item label="Price">

                              {getFieldDecorator('rice', {
                                initialValue: this.state.updateExpensePrice.price,
                                rules: [{ required: true, message: 'Please input Expense Discount!' }],
                              })(
                                <InputNumber placeholder="Enter Expense Discount" style={{ width: '100%' }} />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label="Umit Of Price">
                              {getFieldDecorator('uop', {
                                initialValue: this.state.updateExpensePrice.uop,
                                rules: [{ required: true, message: 'Please Select Unit of Measure' }],
                              })(
                                <Select onChange={this.seclectPrend} disabled={this.state.is_parent}>
                                  {
                                    this.state.uop.map(price => (
                                      <Select.Option key={price.id} value={price.id}>{price.name}</Select.Option>
                                    ))
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                }
              </Panel>*/}
              <Panel header={this.lang.UPDATE.EXPENSEDOCUMENT.TITLE} key="3" >
                <ExpenseDocuments expense_id={this.state.expense.id} {...this.props} />
              </Panel>
            </Collapse>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default ExpenseUpdate = Form.create({ name: 'expense_form_update' })(ExpenseUpdate);