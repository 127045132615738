import React, { Component } from 'react'
import { Row, Col, Form, Icon, Button, Input, Card, Select, message, Checkbox } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class ParameterAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      parent: [],
      selectedParentId: null,
      is_parent: false,
      dir : 'ltr', 
      display: 'block',
      textAlign : 'left'
    }
    this.lang = props.language._PARAMETER.CREATE;
    this.msg = props.language._PARAMETER.MESSAGE;

  }
  
  componentDidMount() {
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/parameters/get_parent_parameter`,{headers})
      .then(res => {
        this.setState({ parent: res.data.data[0] });
      })
      .catch(err => {  console.log('Error occured while fetching the Parameters->',err) })

      this.setComponentDirection();
  }
  
  setComponentDirection=()=>{
    if (localStorage.getItem('lang') === 'EN') {
      this.setState({dir : 'ltr', display: 'block',textAlign : 'left'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex',textAlign : 'right'})
    }
  }

  handleSelectParent = (v) => {
    // console.log(v)
    this.setState({ selectedParentId: v })
  }

  onChangeCheckbox = (e) => {
    let a = !this.state.is_parent
    this.setState({ is_parent: a })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let user_id = localStorage.getItem('user_id')
    this.props.form.validateFields((err, values) => {
      if (!err) {
      values.user_id = user_id
      values.parent_id = this.state.selectedParentId
      this.state.is_parent ? values.is_parent = 1 : values.is_parent = 0
      const headers = getHeadersForHttpReq();
      axios.post(`${complete_URL}/parameters/add`, values,{headers})
        .then(res => { /*console.log(res)*/
          message.success(this.msg.ADDSUCCESS/*res.data.message*/)
          this.props.history.push('parameters')
        })
        .catch(err => {
          message.error(this.msg.ADDFAILURE,3);
          console.log('Error occured while ading new Parameter->',err);
        });
      }
    });
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/parameters">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={14}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/parameters">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/parameters">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Link to="/dashboard/parameters">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let {dir, display, parent} = this.state;
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              {/* <Row gutter={24} dir= {dir} style={{display}} className="top-header" >
                <Col span={8}>
                  <Link to="/dashboard/parameters">
                    <Button type="danger"><Icon type="arrow-left" /></Button>
                  </Link>
                </Col>
                <Col span={8}>
                  <h1>Add Parameter</h1>
                </Col>
                <Col span={8}>
                  <Button type="primary" htmlType="submit" ><Icon type="save" />
                    Save
                </Button>
                </Col>
              </Row> */}
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: this.msg.INSERTNAMEERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      rules: [{ required: false, message: this.msg.INSERTNAMEARERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NAMEAR} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.CODE}>
                    {getFieldDecorator('code', {
                      rules: [{ required: true, message: this.msg.INSERTCODEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.CODE} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.TYPE}>
                    {getFieldDecorator('type', {
                      rules: [{ required: true, message: this.msg.INSERTTYPEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.TYPE} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ISPARENT}>
                    <Checkbox checked = {this.state.is_parent} onChange={this.onChangeCheckbox} >{this.lang.LABELS.ISPARENT} </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.SELECTPARENT}>
                    {getFieldDecorator('parent', {
                      rules: [{ required: false, message: this.msg.SELECTPARENTERROR }],
                    })(
                      <Select onChange={this.handleSelectParent} disabled={this.state.is_parent}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            parent.length > 0 && parent.map(pt => (
                              <Select.Option key={pt.id} value={pt.id}>{pt.name}</Select.Option>
                            ))
                            :
                            parent.length > 0 && parent.map(pt => (
                              <Select.Option style={{textAlign:'right'}} key={pt.id} value={pt.id}>{pt.name_ar}</Select.Option>
                            ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}> </Row>
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row >
    )

  }
}

export default ParameterAdd = Form.create({ name: 'parameter_form' })(ParameterAdd);