import React from 'react';

import { Menu, Dropdown, Icon } from 'antd';
import { withRouter } from "react-router-dom";
import './LanguageChangingDropdown.css'

class LanguageChangingDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      flagURL : '/american_flag.png'
    }
  }
  handleChangeLanguage = (languageToChange) => {
    if (languageToChange === 'ar') {
      localStorage.setItem('lang', 'AR');
      localStorage.setItem('flagURL', '/omani_flag.png');
      this.props.history.go('/');
    }
    else if (languageToChange === 'en') {
      localStorage.setItem('lang', 'EN');
      localStorage.setItem('flagURL', '/american_flag.png');
      this.props.history.go('/');
    }
  }
 
  render() {
    // console.log(this.props)
    const menu = (
      <Menu>
        <Menu.Item onClick={this.handleChangeLanguage.bind(this, 'en')}>
          English
        </Menu.Item>
        <Menu.Item onClick={this.handleChangeLanguage.bind(this, 'ar')}>
          Arabic
        </Menu.Item>
      </Menu>
    );
    return (
      <div defaultValue="language" style={{ marginRight: '10px', float: this.props.language.LOCALE === 'ar' ? "left" : "right" }}>
        <Dropdown overlay={menu}>
          <a className="ant-dropdown-link" href="#0">
            <img src={localStorage.getItem('flagURL') || '/american_flag.png'} width={'40px'} />
            {/* <Icon type="global" theme="outlined" style={{ color: `${this.props.color}` }} className="icon" /><span></span> */}
          </a>
        </Dropdown>
      </div>
    );
  }
}


export default withRouter(LanguageChangingDropdown)



