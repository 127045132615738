import React, { Component } from 'react';
import axios from 'axios';
import API_URL from '../../constants'; 
import getHeadersForPDFreport from '../../constants/reportHeaders';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import {  Select, Row, Col, DatePicker, Icon, Button, Spin, message } from 'antd';

import YearPicker from "react-year-picker";
const { RangePicker, MonthPicker } = DatePicker;

// const { MonthPicker } = DatePicker

if (localStorage.getItem('lang') === 'AR') {
   require('moment/locale/ar');
   require('./arabic.css');
   require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

export default class ReservationListReport extends Component {
    constructor(props) {
       super(props);
       this.state = {
        searchValues: {
            status : 'all',
            date: '',
            from_date: '',
            to_date: '',
            month: '',
            year: '',
            month1: '',
            month2: '',
            year1: '',
            year2: '',
            yearOnly: '',
            allData: ''
        },
        resrv_Options : props.resrv_Options, 
        filterNode: '',
        showFilter: false,
        disableDownloadReport: true,
        mode: ['month', 'month'],
        range_value: [moment(new Date(), 'YYYY/MM'), moment(new Date(), 'YYYY/MM')],
        spin: false,
        spinningMessage: '',
        spinningMessage: '',
        startValue: null,
        endValue: null,
        endOpen: false,
        dir : 'ltr', 
        display: 'block',
        textAlign : 'left',
        range_value: [moment(new Date(), 'YYYY/MM'), moment(new Date(), 'YYYY/MM')],
      }
      this.lang = props.language._REPORTS;
    }
    
    setComponentDirection =()=>{
        if (localStorage.getItem('lang')==='EN') {
            this.setState({dir : 'ltr', display: 'block', textAlign : 'left'});
        }
        else {
            this.setState({dir : 'rtl', display: 'flex', textAlign : 'right'});
        }
    }

    componentDidMount() {
        this.setComponentDirection();
    }

    filterDurationOptionOnchange = (val) => {
       let resetObj = { status : 'all', date: '', from_date: '', to_date: '',
                        month: '', year: '', month1: '', month2: '', year1: '', year2: '',
                        yearOnly: '', allData: val == 'aa' ? 'Yes' : ''
                      }
       let new_report_obj = Object.assign(this.state.searchValues, resetObj);
       this.setState({  searchValues: new_report_obj,
                        disableDownloadReport : val == 'aa' ? false : true,
                        filterNode: val, showFilter: true,
       });
    } //End of Method filterDurationOptionOnchange 
    
    handleSelectStatus = (statusOpt_index, report_type) => {
        let statusObj = this.state.resrv_Options[statusOpt_index];
        if(statusOpt_index > -1 && statusOpt_index < this.state.resrv_Options.length) {
            let {date, from_date, to_date, month, year, month1, month2, year1, year2, yearOnly, allData} = this.state.searchValues;
            let reportFlag = true;
            if ((date)||(from_date && to_date)||(month && year)||(month1 && month2 && year1 && year2)||(yearOnly)||(allData) ) {
                reportFlag = false;
            }
            let newResrvObj = Object.assign(this.state.searchValues, { status : statusObj.name });
            this.updateReportFilters(newResrvObj, reportFlag);
        }//End of Index Validation
    }//End of Method
    
    disabledStartDate = (startValue) => {
        const endValue = this.state.endValue;
        if (!startValue || !endValue) {
            return false;
        }
            return startValue.valueOf() > endValue.valueOf();
    }

    disabledEndDate = (endValue) => {
        const startValue = this.state.startValue;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    }

    onStartChange = (date, dateString, report_type) => {
        this.setState({ 'startValue': date },
        () => {
        if (this.state.startValue && this.state.endValue) {
            let st = moment(dateString).locale('en').format('YYYY-MM-DD');
            let ed = moment(this.state.endValue).locale('en').format('YYYY-MM-DD');
            this.setParamsDatesDuration(st, ed, false, report_type);
        }
        })
    }

    onEndChange = (date, dateString, report_type) => {
        this.setState({ 'endValue': date },
        () => {
            if (this.state.startValue && this.state.endValue) {
                let st = moment(this.state.startValue).locale('en').format('YYYY-MM-DD');
                let ed = moment(dateString).locale('en').format('YYYY-MM-DD');
                this.setParamsDatesDuration(st, ed, false, report_type)
            }
        })
    }

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }

    handlePanelChange = (value, mode, report_type) => {
        let month1 = moment(value[0]).locale('en').format('MM');
        let year1 = moment(value[0]).locale('en').format('YYYY');
  
        let month2 = moment(value[1]).locale('en').format('MM');
        let year2 = moment(value[1]).locale('en').format('YYYY');
        if (month1 && month2 && year1 && year2) {
            this.setState({
                range_value: value,
                mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
              });
            this.setParamsBetweenMonths(month1, month2, year1, year2, false, this.props.report_type);
        }//End of if Range months and years found
    };

    generateGenericReport=(req_data, file_name)=>{
        if(this.props.report_url){
            const headers2 = getHeadersForPDFreport();
            const user_name= localStorage.getItem('user_name');
            Object.assign(req_data, { status: this.state.searchValues.status, user_name });
            // axios({url: `${complete_URL}/resrvlist_report/generic_report`,
            axios({url: `${complete_URL}/${this.props.report_url}`, headers: { ...headers2 },
                    method: 'post', data: req_data, responseType: 'arraybuffer'
            })
            .then(response => {
                this.setState({ spin: false });
                if (response.data.byteLength > 0) {
                    this.props.generate_pdf_popup( response.data, file_name);
                }
                else if (response.statusText === 'Provided incomplete data') {
                    if (this.props.report_type === 'resrvList') {
                        message.error(this.lang.MESSAGE.RESRVLISTREPORTDATANOTPROVIDEDERROR,3);
                    }
                    else if (this.props.report_type === 'pmtTr') {
                        message.error(this.lang.MESSAGE.PMTTRREPORTDATANOTPROVIDEDERROR,3);
                    }
                }
                else if (response.statusText === 'No Content' || response.statusText === '[object Object]') {
                    if (this.props.report_type === 'resrvList') {
                        message.error(this.lang.MESSAGE.RESRVLISTREPORTALLDATANOTFOUNDERROR,3);
                    }
                    else if (this.props.report_type === 'pmtTr') {
                        message.error(this.lang.MESSAGE.PMTTRREPORTDATEDATANOTFOUNDERROR,3);
                    }
                } 
                else {  message.error(response.statusText,3); }
            })
            .catch(err => {
                this.setState({ spin: false });
                if (this.props.report_type === 'resrvList') {
                    message.error(this.lang.MESSAGE.RESRVLISTREPORTGENERATEFAILURE/*err.message*/,3);
                }
                else if (this.props.report_type === 'pmtTr') {
                    message.error(this.lang.MESSAGE.PMTTRREPORTGENERATEFAILURE/*err.message*/,3);
                }
                console.log('Error occured while fetching '+this.props.report_name,err);
            });
        }//End of report_url
    }//End of Reservation list Generic Method

    downloadOnDateData = (date, report_type) => {
        let req_data = this.props.getReportBasicData(); 
        if (req_data.client_id) {
            Object.assign(req_data, {date });
            let file_name = this.props.report_name + date + '.pdf';
            this.generateGenericReport(req_data, file_name);
        }//End of ClientID exists
        else { message.error(this.lang.MESSAGE.LOGINERROR,3);  }
    }

    downloadBetweenDatesData = (date1, date2, report_type) => {
        let req_data = this.props.getReportBasicData(); 
        if (req_data.client_id) { 
            Object.assign(req_data, {date1, date2 });
            let file_name = this.props.report_name + date1+'_to_'+date2+ '.pdf';
            this.generateGenericReport(req_data, file_name);
        }//End of ClientID exists
        else { message.error(this.lang.MESSAGE.LOGINERROR,3);  }
    }//End of Method

    downloadOneMonthOfYearData = (month, year, report_type) => {
        let req_data = this.props.getReportBasicData(); 
        if (req_data.client_id) { 
            Object.assign(req_data, {month, year });
            let file_name = this.props.report_name + month + '_' + year+ '.pdf';
            this.generateGenericReport(req_data, file_name);
        }//End of ClientID exists
        else { message.error(this.lang.MESSAGE.LOGINERROR,3);  }
    }

    downloadBetweenMonthsData = (month1, month2, year1, year2, report_type) => {
        let req_data = this.props.getReportBasicData(); 
        if (req_data.client_id) { 
            Object.assign(req_data, {month1, month2, year1, year2 });
            let file_name = this.props.report_name + month1 + '_' + year1 + '_to_' + month2 + '_' + year2+ '.pdf';
            this.generateGenericReport(req_data, file_name);
        }//End of ClientID exists
        else { message.error(this.lang.MESSAGE.LOGINERROR,3);  }
    }

    downloadYearOnlyData = (yearOnly, report_type) => {
        let req_data = this.props.getReportBasicData(); 
        if (req_data.client_id) { 
            Object.assign(req_data, {yearOnly });
            let file_name = this.props.report_name + yearOnly+ '.pdf';
            this.generateGenericReport(req_data, file_name);
        }//End of ClientID exists
        else { message.error(this.lang.MESSAGE.LOGINERROR,3); }
    }

    downloadAllData = (report_type) => {
        let req_data = this.props.getReportBasicData(); 
        if (req_data.client_id) { 
            let file_name = this.props.report_name +'_allData.pdf';
            this.generateGenericReport(req_data, file_name);
        }//End of ClientID exists
        else { message.error(this.lang.MESSAGE.LOGINERROR,3); }
    }

    updateReportFilters = (searchValues, disableDownloadReport)=> {
        this.setState({searchValues, disableDownloadReport});
    }//End of Method

    setParamsOnDate = (date, resetOpt, report_type) => {
        if (date) {
            let new_report_obj = Object.assign(this.state.searchValues, { date: date });
            this.updateReportFilters(new_report_obj, resetOpt); 
        }
    }//End of Method

    setParamsDatesDuration = (from_date, to_date, resetOpt, report_type) => {
        if(from_date && to_date) {
            let new_report_obj = Object.assign(this.state.searchValues, { from_date: from_date, to_date: to_date });
            this.updateReportFilters(new_report_obj, resetOpt); 
        }
    }

    setParamsOneMonthOfYear = (month, year, resetOpt, report_type) => {
        let new_report_obj = Object.assign(this.state.searchValues, { month: month, year: year });
        this.updateReportFilters(new_report_obj, resetOpt); 
    }//End of Method

    setParamsBetweenMonths = (month1, month2, year1, year2, resetOpt, report_type) => {
        let new_report_obj = Object.assign(this.state.searchValues, { month1: month1, month2: month2, year1: year1, year2: year2 });
        this.updateReportFilters(new_report_obj, resetOpt); 
    }//End of Method

    setParamsYear = (yearOnly, resetOpt, report_type) => {
        let new_report_obj = Object.assign(this.state.searchValues, { yearOnly: yearOnly });
        this.updateReportFilters(new_report_obj, resetOpt); 
    }

    downloadReport = (report_type) => {
        let spinningMessage = '';
        if (report_type === 'resrvList') {
            spinningMessage = this.lang.MESSAGE.RESRVLISTDOWNLOADREPORTTIP;
        }//End of Reservation Choice
        else if (report_type === 'pmtTr') {
            spinningMessage = this.lang.MESSAGE.PMTTRDOWNLOADREPORTTIP;
        }//End of Reservation Choice
        this.setState({ spinningMessage , spin: true });
        let { date, from_date, to_date, month, year, month1, month2, year1, year2, yearOnly, allData } = this.state.searchValues;
        if (date ) {
            this.downloadOnDateData(date, report_type);
            this.setParamsOnDate('', true, report_type);
        }
        else if (from_date && to_date ) {
            this.downloadBetweenDatesData(from_date, to_date, report_type);
            this.setParamsDatesDuration('', '', true, report_type);
        }
        else if (month && year) {
            this.downloadOneMonthOfYearData(month, year, report_type);
            this.setParamsOneMonthOfYear('', '', true, report_type);
        }
        else if (month1 && month2 && year1 && year2) {
            this.downloadBetweenMonthsData(month1, month2, year1, year2, report_type);
            this.setParamsBetweenMonths('', '', '', '', true, report_type);
        }
        else if (yearOnly) {
            this.downloadYearOnlyData(yearOnly, report_type);
            this.setParamsYear('', true, report_type);
        }
        else if (allData) {
            this.downloadAllData(report_type);
            this.setState({
            searchValues: {
                status : 'all', date: '', from_date: '', to_date: '',
                month: '', year: '', month1: '', month2: '', year1: '', year2: '',
                yearOnly: '', allData: ''
                },
                disableDownloadReport: true,
            });
        }//End of if all data option selected
    }//End of downloadReport Method

    render() {
      const is_EN = localStorage.getItem('lang') === 'EN';
      const report_type = this.props.report_type;
      let {showFilter, filterNode, resrv_Options, spinningMessage, spin, dir, display, textAlign} = this.state;
      let {DATE_OPTION,DATEL,MONTHLY_OPTION,MONTHL,YEARLY_OPTION,ALL,
            STARTDATE,ENDDATE,STARTMONTH,ENDMONTH,STATUS } = this.props.lang.LABELS;

      return (
        <Spin tip={spinningMessage} spinning={spin}>
            <Row gutter={24} dir ={dir} style={{display : display, zIndex : 999}}>
                <Col span={8}>
                    <Select suffixIcon={[<Icon key={report_type+'ft'} type="filter" />]} style={{ width: '100%' }}
                    onChange={(value) => { this.filterDurationOptionOnchange(value) }} placeholder={this.props.language.FILTER_OPTION}>
                    <Select.Option style={{textAlign}} value="d"   key={1}>{ DATE_OPTION}</Select.Option>
                    <Select.Option style={{textAlign}} value="ds"  key={2}>{ DATEL}</Select.Option>
                    <Select.Option style={{textAlign}} value="m"   key={3}>{ MONTHLY_OPTION}</Select.Option>
                    <Select.Option style={{textAlign}} value="mm"  key={4}>{ MONTHL}</Select.Option>
                    <Select.Option style={{textAlign}} value="y"   key={5}>{ YEARLY_OPTION}</Select.Option>
                    <Select.Option style={{textAlign}} value="aa"  key={6}>{ ALL}</Select.Option>
                    </Select>
                </Col>
                <Col span={16} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {
                    showFilter &&
                        filterNode === 'd' ?
                            <DatePicker 
                                format="YYYY-MM-DD"   style={{ width: '40%' }}   placeholder={ DATE_OPTION} 
                                onChange={(e) => { this.setParamsOnDate(moment(e).locale('en').format('YYYY-MM-DD'), false, report_type) }} 
                            />
                        :
                        filterNode === 'ds' ?
                            (<>
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    format="YYYY-MM-DD" style={{ width: '40%' }} placeholder={ STARTDATE}
                                    onChange={(date, dateString) => { this.onStartChange(date, dateString, report_type) }}
                                    onOpenChange={this.handleStartOpenChange}
                                />
        
                                <DatePicker
                                    disabledDate={this.disabledEndDate} open={this.state.endOpen}
                                    format="YYYY-MM-DD" style={{ width: '40%' }}  placeholder={ ENDDATE}
                                    onChange={(date, dateString) => { this.onEndChange(date, dateString, report_type) }}
                                    onOpenChange={this.handleEndOpenChange}
                                />
                            </>)
        
                            :
                            filterNode === 'm' ?
                                <MonthPicker style={{width: '40%' }} placeholder={ MONTHLY_OPTION} 
                                    onChange={(e) => { this.setParamsOneMonthOfYear(moment(e).locale('en').format('MM'), moment(e).locale('en').format('YYYY'), false, report_type) }} 
                                />
                                : filterNode === 'y' ?
                                <Row gutter={24}>
                                    <Col span={24} style={{width: '40%', float:textAlign}}>
                                        <YearPicker style={{width: '40%' }} placeholder={ YEARLY_OPTION} 
                                            onChange={(e) => { this.setParamsYear(e, false, report_type) }} 
                                        />
                                    </Col>
                                </Row>
                                :
                                filterNode === 'mm' ?
                                    <RangePicker
                                        format="YYYY-MM" placeholder={[ STARTMONTH,  ENDMONTH]}
                                        value={this.state.range_value} mode={this.state.mode}
                                        onChange={(value) => { this.handleChange(value, report_type) }}
                                        onPanelChange={(value, mode) => { this.handlePanelChange(value, mode, report_type) }}
                                    />
                                    : ''
                    }
                </Col>
            </Row>
            {/*Reservation Status selection*/}
            <Row gutter={24} style={{ marginTop: '3%' }}>
                <Col span={24}>
                    <Select style={{ width: '31.4%' }} onChange={(ro_index) => { this.handleSelectStatus(ro_index,report_type) }} placeholder={STATUS}>
                    {(is_EN) ?
                      resrv_Options.map((ro, index) => (
                        <Select.Option key={ro.id} value={index}>
                            {ro.name.charAt(0).toUpperCase() + ro.name.slice(1).toLowerCase()}
                        </Select.Option>
                    ))
                    : resrv_Options.map((ro, index) => (
                        <Select.Option style={{textAlign: 'right'}} key={ro.id} value={index}>{ro.name_ar}</Select.Option>
                    ))}
                    </Select>
                </Col>
            </Row>
            {/*DOWNLOADREPORT button*/}
            <Row gutter={24} style={{ marginTop: '20%', marginLeft: '-15%' }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Button
                        key = {this.props.report_type+'_btn'}
                        type="primary" icon="download" size={'default'}
                        onClick={() => { this.downloadReport(report_type) }}
                        disabled={this.state.disableDownloadReport}
                    >
                        {this.props.lang.BUTTON.DOWNLOADREPORT}
                    </Button>
                </Col>
            </Row>
        </Spin>
      )
    }
}