import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button,Switch, Input, message, Table, Avatar, Select, InputNumber, } from 'antd'
import axios from 'axios';
import API_URL from '../../constants';
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

// const Search = Input.Search;

class ContractorUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      packageList: '1',
      getPackagesByReservation: {},
      image: '',
      package:{},
      packagesData: [],
      commissionOptions: [{'id':0,'name':'Percentage','name_ar':'بنسبة مئوية'},{'id':1,'name':'Amount','name_ar':'حسب الكمية'}],
      commissionOption : 0,
      dir : 'ltr', 
      display: 'block'
    }
    this.lang1 = props.language._CONTRACTOR.UPDATE.PACKAGE;
    this.msg = props.language._CONTRACTOR.UPDATE.PACKAGE.MESSAGE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang1.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'type',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang1.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'type',
        align:'right',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang1.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang1.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        align:'right',
        render: (text, record) => <button  className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang1.SETUP.LABELS.COMMISSIONPERCENT}`,
        dataIndex: 'commission_percentage',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang1.SETUP.LABELS.COMMISSIONPERCENT}`,
        dataIndex: 'commission_percentage',
        align:'right',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang1.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang1.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        align:'right',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang1.SETUP.LABELS.ISACTIVE}`,
        dataIndex: 'is_active',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text===1? 'Yes':'No'}</button>,
      }:
      {
        title: `${this.lang1.SETUP.LABELS.ISACTIVE}`,
        dataIndex: 'is_active',
        align:'right',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text===1? 'نعم':'لا'}</button>,
      },
      {
        title: `${this.lang1.SETUP.LABELS.ACTION}`,
        align :'center',
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang1.SETUP.BUTTON.DELETE}</Button>
          </span>
        ),
    }
    ]
  }

  handleNavigation = (record) => {
    localStorage.setItem('updatePackage', JSON.stringify(record))
    this.fetchPackage().then(() => {
      this.setState({
        packageList: '3'
      })
    });
  }

  deleteCategory = (record) => {
    const id = record.id
    if (id) {
    axios.delete(`${complete_URL}/contractorpackages/${id}`)
      .then(res => { //console.log('res->',res);
        // message.success('Packages Has been deleted')
        if (res.data.success) {
          message.success(this.msg.DELETESUCCESS);
          this.fetchPackageData();
        }
        else if(res.data.code === '003'){
          message.error(this.msg.DATANOTFOUNDERROR,3);
        }
        else{
          message.error(this.msg.DELETEFAILURE,3);
        }
      })
      .catch(err => {
        console.log('Issue while deleting the Contractor Package ->',err);
        message.error(this.msg.DELETEFAILURE,3);
      })
    }
    else{
      message.error(this.msg.DATANOTFOUNDERROR,3);
    }
  }//End of Delete Method

  handleImageUpload = async (e) => {
    e.preventDefault();
    // console.log(e)
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      var file = e.currentTarget.files[0];
      let fileName = file.name;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (file.size <= 1024 * 1024 * 2 && /png|jpe?g/i.test(extension)) {
        var a;
        let imgdata = new FormData();
        imgdata.append('file', file)
        imgdata.append('client_id', localStorage.getItem('client_id'))
        // console.log(JSON.parse(localStorage.getItem('updatePackage')).id)
        imgdata.append('package_id', JSON.parse(localStorage.getItem('updatePackage')).id)
        // imgdata.append('client_name', this.state.client.name)
        let headers = {
          'Content-Type': 'multipart/form-data',
          'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token")

          
        }
        
        await axios.post(`${complete_URL}/images/package/add`, imgdata, { responseType: 'blob' }, {headers})
        .then(res => {
          var reader = new FileReader();
          reader.readAsDataURL(res.data);
          reader.onloadend = () => {
            a = reader.result
            console.log(a)
              this.setState({
                image: a,
              })
            }
          })
          .catch(err => {
            console.log('Error occured while uploading the contrator package image->',err)
            message.error(this.msg.IMAGEADDFAILURE,3);
          })
        // setTimeout(() => {
        //   this.setState({
        //     image: a,
        //   })
        //   console.log(a)
        // }, 800)
      }
      else{ message.error(this.msg.IMAGETYPEERROR,3); }
    }
  }

  HandleSubmitPackage = (e) => {
    e.preventDefault();
    let id = JSON.parse(localStorage.getItem('updateContractor')).id
    var user_id = localStorage.getItem('user_id')

    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.contractor_id = id;
        values.user_id = user_id;
        // console.log(values)
        axios.post(`${complete_URL}/contractorpackages/add`, values)
          .then(res => {
            if (res.data.success) {
              message.success(this.msg.ADDSUCCESS);
              this.fetchPackageData();
              this.setState({ packageList: '1' })
            } 
            else {
              message.error(this.msg.ADDFAILURE,3);
            }
          })
          .catch(err => {
            console.log('Error occured in creating the Contractor package->',err)
            message.error(this.msg.ADDFAILURE,3)
          })
      }
      else { console.log('Contractor Package Form Error->',err); }
    })
  }

  handleSubmitPackageUpdate = (e) => {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id')
    var id = JSON.parse(localStorage.getItem('updatePackage')).id
    this.props.form.validateFields((err, values) => {
      if (!err) {
        Object.assign(values, {
          user_id : localStorage.getItem('user_id'),
          commission_amount : this.state.commissionOption == 0 ? 0.00 : values.commission_amount,
          commission_percentage : this.state.commissionOption == 1 ? 0.00 : values.commission_percentage,
          is_active : values.is_active ? 1 : 0
        })
        // values.user_id = user_id
        // values.image = this.state.image
        // this.state.commissionOption == 0 ? (values.commission_amount = 0.00): (values.commission_percentage = 0.00)
        // console.log('Values in contractor packages->',values);
        let self = this
        axios.put(`${complete_URL}/contractorpackages/${id}`, values)
          .then(res => {
            if (res.data.success) {
              message.success(this.msg.UPDATESUCCESS);
              self.setState({ packageList: '1' });
              this.fetchPackageData();
            }
            else{ message.error(this.msg.UPDATEFAILURE,3); }
            
          })
          .catch(err => {
            message.error(this.msg.UPDATEFAILURE,3)
            console.log(err)
          })
      }
      else { console.log('ContractorPackage Error->',err); }
    })
  }

  fetchPackage = async () => {
    const id = JSON.parse(localStorage.getItem('updatePackage')).id
    await axios.get(`${complete_URL}/contractorpackages/${id}`)
      .then(res => {
        let comm_opt = -1;
        parseFloat(res.data.data[0].commission_percentage) > 0 ? (comm_opt = 0) : (comm_opt = 1)
        this.setState({ package: res.data.data[0],commissionOption : comm_opt }/*,()=>{
          console.log('this.state.package',res.data.data[0])
        }*/)
      })
      .catch(err => { console.log(err) })

    const image_id = JSON.parse(localStorage.getItem('updatePackage')).image_id
    await axios.get(`${complete_URL}/images/${image_id}`, { responseType: 'blob' })
      .then(res => {
        var reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          this.setState({ image: reader.result }/*, () => console.log(this.state.image)*/)
        }
      })
      .catch(err => { console.log(err) })
  }

  fetchPackageData = async () => {
    let id = JSON.parse(localStorage.getItem('updateContractor')).id
    await axios.get(`${complete_URL}/contractorpackages/fetch_by_contractor/${id}`)
      .then(res => { // console.log('packagesData->',res.data.data[0])
        this.setState({ packagesData: res.data.data })
      })
      .catch(err => { console.log(err) })
  }

  componentDidMount() {
    let userid = localStorage.getItem('user_id');
    this.fetchPackageData();
    let reservationByOwner = this.getIsOwnerBooleanCheck(userid);
    // console.log('In didmount contractor packages->',reservationByOwner);
    if (localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block', reservationByOwner : reservationByOwner.reservationByOwner})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex', reservationByOwner : reservationByOwner.reservationByOwner})
    }
  }
  handleCommissionTypeSelection =(val)=>{
    this.setState({commissionOption:val},()=>{
      if (val == 0) {
        this.props.form.setFieldsValue({ commission_amount : 0.00})
      }
      else {
        this.props.form.setFieldsValue({ commission_percentage  : 0.00})
      }
    });
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.PackageAddHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ packageList: '1' }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang1.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.PackagesTableHeader = <Row id="clientTableHeader">
        <Col span={1}></Col>
        <Col span={8}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang1.SETUP.LABELS.TITLE}</p></Col>
        <Col span={4}></Col>
        <Col span={10}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ packageList: '2' }) }}>{this.lang1.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>

      this.PackageUpdateHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ packageList: '1' }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang1.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
    else {
      this.PackageAddHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ packageList: '1' }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang1.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.PackagesTableHeader = <Row id="clientTableHeader">
        <Col span={6}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ packageList: '2' }) }}>{this.lang1.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={17}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang1.SETUP.LABELS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.PackageUpdateHeader = <Row gutter={24}>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ packageList: '1' }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang1.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
  }
  
  onCommisionchange =()=>{

  }
  getIsOwnerBooleanCheck = (userid)=>{
    let reservationByOwner = {};
    let  headers = {
      'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
      }
    axios.get(`${complete_URL}/users/${userid}`,{headers})
    .then(res => { // console.log('from API call ..reservationByOwner->',res.data.is_owner);
      Object.assign(reservationByOwner , {reservationByOwner : res.data.is_owner});
    })
    .catch(err => { console.log(err) }) 
    return reservationByOwner;
  }

  render() {
    const { getFieldDecorator } = this.props.form
    // console.log('this.lang1 ->',this.lang1);
    return (
      <React.Fragment>
        {
          // {/* View Package Setup Screen */}
          this.state.packageList === '1' ?
            <div>
              {this.PackagesTableHeader}
              <Row id="packagesTable">
              <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                  <Table columns={this.columns} rowKey="id" dataSource={this.state.packagesData} style={{ marginTop: '10px' }} />,
              </Col>
              </Row>
            </div>

            // {/* Create Package Screen */}
            : this.state.packageList === '2' ?
              <Form onSubmit={this.HandleSubmitPackage} style={{ marginTop: '20px' }}>
                {this.PackageAddHeader}
                <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                  <Col span={12}>
                    {
                      <Form.Item label={this.lang1.CREATE.LABELS.PACKAGETYPE}  >
                        {getFieldDecorator('type', {
                          rules: [{ required: true, message: this.msg.INSERTPKGTYPEERROR }]
                        })(
                          <Input placeholder={this.lang1.CREATE.LABELS.PACKAGETYPE} />
                        )}
                      </Form.Item>
                    }
                  </Col>
                  <Col span={12}>
                    {
                      <Form.Item label={this.lang1.CREATE.LABELS.PACKAGEPRICE}  >
                        {getFieldDecorator('price', {
                          rules: [{ required: true, message: this.msg.INSERTPKGPRICEERROR }]
                        })(
                          <Input placeholder={this.lang1.CREATE.LABELS.PACKAGEPRICE} />
                        )}
                      </Form.Item>
                    }
                  </Col>
                </Row>
                <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                  <Col span={12}>
                    {
                      <Form.Item label={this.lang1.CREATE.LABELS.PACKAGEDESCRIPTION} >
                        {getFieldDecorator('description', {
                          rules: [{ required: true, message: this.msg.INSERTPKGDESCERROR }]
                        })(
                          <Input placeholder={this.lang1.CREATE.LABELS.PACKAGEDESCRIPTION} />
                        )}
                      </Form.Item>
                    }
                  </Col>
                  <Col span={12}>
                    {
                      <Form.Item label={this.lang1.CREATE.LABELS.PACKAGEDESCRIPTIONAR}>
                        {getFieldDecorator('description_ar', {
                          rules: [{ required: true, message:  this.msg.INSERTPKGDESCARERROR }]
                        })(
                          <Input dir='rtl'   />
                        )}
                      </Form.Item>
                    }
                  </Col>
                </Row>
                {
                  /*this.state.dir === 'ltr' &&*/
                  <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                    <Col span={8}>
                      <Form.Item label= {this.lang1.CREATE.LABELS.COMMISSIONTYPE}  >
                            {getFieldDecorator('commissionOption', {
                              initialValue:this.state.commissionOptions[this.state.commissionOption].id,
                              rules: [{ required: true, message: this.msg.SELECTCOMMTYPEERROR }],
                            })(
                            <Select onChange={(e)=>this.handleCommissionTypeSelection(e)} placeholder= {this.lang1.CREATE.LABELS.COMMISSIONTYPE}>
                              {
                                (localStorage.getItem('lang') === 'EN') ?
                                  this.state.commissionOptions.map(commission => (
                                    <Select.Option key={commission.id} value={commission.id}>{commission.name}</Select.Option>
                                  )):
                                  this.state.commissionOptions.map(commission => (
                                    <Select.Option style={{textAlign:'right'}} key={commission.id} value={commission.id}>{commission.name_ar}</Select.Option>
                                  ))
                              }
                            </Select>)}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        {
                          <Form.Item  label= {this.lang1.CREATE.LABELS.COMMISSIONPERCENT} >
                            {getFieldDecorator('commission_percentage', {
                              rules: [{ required: this.state.commissionOption == 0 , message: this.msg.INSERTCOMMPERCENTERROR }]
                            })(
                              <InputNumber  placeholder={this.lang1.CREATE.LABELS.COMMISSIONPERCENT}  
                                disabled={this.state.commissionOption == 1} max={100.00} min = {0.00} style={{width:'100%'}}
                              />
                            )}
                          </Form.Item>
                        }
                    </Col>
                    <Col span={8}>
                        {
                          <Form.Item  label={this.lang1.CREATE.LABELS.COMMISSIONAMOUNT}  >
                            {getFieldDecorator('commission_amount', {
                              rules: [{ required: this.state.commissionOption == 1, message: this.msg.INSERTCOMMAMOUNTERROR }]
                            })(
                              <InputNumber  placeholder={this.lang1.CREATE.LABELS.COMMISSIONAMOUNT}
                                disabled={this.state.commissionOption == 0}  style={{width:'100%'}}
                              />
                            )}
                          </Form.Item>
                        }
                    </Col>
                  </Row>
                }
                
              </Form>

              // {/* Update Package Screen */}
              :
              <Form onSubmit={this.handleSubmitPackageUpdate.bind(this)} style={{ marginTop: '20px' }}>
                {this.PackageUpdateHeader}
                <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                  <Col span={12}>
                    {
                      <Form.Item label={this.lang1.UPDATE.LABELS.PACKAGETYPE}   >
                        {getFieldDecorator('type', {
                          initialValue: this.state.package.type && this.state.package.type,
                          rules: [{ required: true, message:  this.msg.INSERTPKGTYPEERROR }]
                        })(
                          <Input placeholder={this.lang1.CREATE.LABELS.PACKAGETYPE} />
                        )}
                      </Form.Item>
                    }
                  </Col>
                  <Col span={12}>
                    {
                      <Form.Item label={this.lang1.UPDATE.LABELS.PACKAGEPRICE}   >
                        {getFieldDecorator('price', {
                          initialValue: this.state.package.price,
                          rules: [{ required: true, message: this.msg.INSERTPKGPRICEERROR }]
                        })(
                          <Input placeholder={this.lang1.CREATE.LABELS.PACKAGEPRICE} />
                        )}
                      </Form.Item>
                    }
                  </Col>
                </Row>
                <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                  <Col span={12}>
                    {
                      <Form.Item label={this.lang1.UPDATE.LABELS.PACKAGEDESCRIPTION}  >
                        {getFieldDecorator('description', {
                          initialValue: this.state.package.description,
                          rules: [{ required: true, message:  this.msg.INSERTPKGDESCERROR }]
                        })(
                          <Input placeholder={this.lang1.CREATE.LABELS.PACKAGEDESCRIPTION} />
                        )}
                      </Form.Item>
                    }
                  </Col>
                  <Col span={12}>
                    {
                      <Form.Item label={this.lang1.UPDATE.LABELS.PACKAGEDESCRIPTIONAR}>
                        {getFieldDecorator('description_ar', {
                          initialValue: this.state.package.description_ar,
                          rules: [{ required: true, message: this.msg.INSERTPKGDESCARERROR }]
                        })(
                          <Input dir= {'rtl'} placeholder={this.lang1.CREATE.LABELS.PACKAGEDESCRIPTIONAR} />
                        )}
                      </Form.Item>
                    }
                  </Col>
                </Row>
                {
                  /*this.state.dir === 'ltr' &&*/
                  <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                    <Col span={8}>
                        <Form.Item label= {this.lang1.CREATE.LABELS.COMMISSIONTYPE} >
                              {getFieldDecorator('commissionOption', {
                                initialValue: this.state.commissionOptions[this.state.commissionOption].id,
                                rules: [{ required: true, message:  this.msg.SELECTCOMMTYPEERROR }],
                              })(
                              <Select onChange={(e)=>this.handleCommissionTypeSelection(e)} placeholder= {this.lang1.CREATE.LABELS.COMMISSIONTYPE}>
                                  {
                                    (localStorage.getItem('lang') === 'EN') ?
                                    this.state.commissionOptions.map(commission => (
                                      <Select.Option key={commission.id} value={commission.id}>{commission.name}</Select.Option>
                                    )):
                                    this.state.commissionOptions.map(commission => (
                                      <Select.Option style={{textAlign:'right'}} key={commission.id} value={commission.id}>{commission.name_ar}</Select.Option>
                                    ))
                                  }
                                </Select>)}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                      {
                        <Form.Item  label= {this.lang1.CREATE.LABELS.COMMISSIONPERCENT} >
                          {getFieldDecorator('commission_percentage', {
                            initialValue: this.state.package.commission_percentage,
                            rules: [{ required: this.state.commissionOption == 0 , message:  this.msg.INSERTCOMMPERCENTERROR }]
                          })(
                            <InputNumber disabled={this.state.commissionOption == 1} placeholder={this.lang1.CREATE.LABELS.COMMISSIONPERCENT}  max={100.00} min = {0.00} style={{width:'100%'}}  />
                          )}
                        </Form.Item>
                      }
                    </Col>
                        
                    <Col span={8}>
                    {
                      <Form.Item  label= {this.lang1.CREATE.LABELS.COMMISSIONAMOUNT} >
                        {getFieldDecorator('commission_amount', {
                          initialValue: this.state.package.commission_amount,
                          rules: [{ required: this.state.commissionOption == 1, message: this.msg.INSERTCOMMAMOUNTERROR  }]
                        })(
                          <InputNumber disabled={this.state.commissionOption == 0} placeholder= {this.lang1.CREATE.LABELS.COMMISSIONAMOUNT} style={{width:'100%'}} />
                        )}
                      </Form.Item>
                    }
                  </Col>
                    
                  </Row>
                }
                
                <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                  <Col span={12}>
                    {
                      <Form.Item label={this.lang1.UPDATE.LABELS.ISACTIVE}>
                          {
                            getFieldDecorator('is_active', 
                              { valuePropName: 'checked', initialValue:this.state.package.is_active == 1 ? true : false })
                              (<Switch disabled = {this.state.reservationByOwner == 0} />)
                          }
                      </Form.Item>
                    }
                  </Col>
                  <Col span={12}>
                    <Form.Item>
                      <p>{this.lang1.CREATE.LABELS.FILEUPLOAD}</p>
                      {getFieldDecorator('file', {
                        rules: [{ required: false, message: this.msg.IMAGETYPEERROR }],
                      })(
                        <Row>
                          <Avatar size={80} src={ this.state.image || '/logo.png'} alt="log here" />
                          <Input type="file" id="file" className="file" onChange={this.handleImageUpload} />
                        </Row>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
        }
      </React.Fragment>
    )
  }
}

export default ContractorUpdate = Form.create({ name: 'normal_login' })(ContractorUpdate);