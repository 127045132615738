import React, { Component } from 'react';
import {  Row,  Col,  Form, InputNumber,  Icon,  Button,  DatePicker,  Input,  Card,  message,  Select } from 'antd'
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const TextArea = Input.TextArea;
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class ExpenseAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expense_type: [],
      today : new Date(),
      dir : 'ltr', 
      display: 'block',
      textAlignValue : 'left'
    }
    this.lang = props.language._EXPENSE;
  }
  
  setComponentDirection=()=>{
    if (localStorage.getItem('lang')==='EN') {
       this.setState({dir : 'ltr', display: 'block',textAlignValue : 'left'});
     }
     else {
       this.setState({dir : 'rtl', display: 'flex',textAlignValue : 'right'});
     }
  }

  componentDidMount() {
    const client_id = localStorage.getItem('client_id');
    this.getExpenseTypes(client_id);
    this.setComponentDirection();
  }

  getExpenseTypes = (client_id)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/expensetypes/by_client/${client_id}`,{headers})
      .then(res => { // console.log('expense_types->',res)
        this.setState({ expense_type: res.data.data })
      })
      .catch(err => {  console.log('Issue while fetching expense_types ->',err) })
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (parseFloat(values.amount) > 0) {
        const headers = getHeadersForHttpReq();
        const user_id = localStorage.getItem('user_id');
        const client_id = localStorage.getItem('client_id');
        let is_contr = localStorage.getItem('is_contr');
        let d = moment(values.date).locale('en').format('YYYY-MM-DD');
        // eslint-disable-next-line
        if (is_contr != 1) {
          Object.assign(values,{user_id, client_id, contractor_id : null, date : d,  is_contr : 0});
        }
        else{
          const contractor_id = JSON.parse(localStorage.getItem('updateContractor')).id ; 
          Object.assign(values,{user_id, contractor_id, client_id : null, date : d, is_contr : 1});
        }
        axios.post(`${complete_URL}/expenses/add`, values, {headers})
          .then(res => {
            if (res.data.success) {
              message.success(this.lang.MESSAGE.ADDSUCCESS/*res.data.message*/)
              // eslint-disable-next-line
              let exp_route = is_contr != 1 ?  'expenses' : 'expenses_contr';
              this.props.history.push(exp_route);
            } 
            else {
              message.error(this.lang.MESSAGE.ADDFAILURE,3/*res.data.message*/)
            }
          })
          .catch(err => {
            message.error(this.lang.MESSAGE.ADDFAILURE,3)
            console.log('Error occured while adding the Expense record->',err)
          });
      } //End of positive value
      else{
        message.error(this.props.language.INSERTPOSITIVEAMOUNTWARN,3);
      }
     }//End of no error
    })
  }

  componentWillMount() {
    let nav_route = '/dashboard/expenses';
    // eslint-disable-next-line
    if (localStorage.getItem('is_contr') == 1){
      nav_route = '/dashboard/expenses_contr'
    }
    
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={7}>
          <Link to= { nav_route }>
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={12}> <h1 style={{textAlign: 'center'}}>{this.lang.CREATE.LABELS.TITLE}</h1> </Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to= { nav_route }>
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to= { nav_route }>
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}> <h1 style={{textAlign: 'center'}}>{this.lang.CREATE.LABELS.TITLE}</h1></Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to= { nav_route }>
            <Button  style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button  style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
        
      </Row>
    }
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    let { dir, display, expense_type } = this.state;

    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              <Row gutter={24} dir = {dir} style={{display}} >
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.AMOUNT}  >
                    {getFieldDecorator('amount', {
                      rules: [{ required: true, message: this.lang.MESSAGE.INSERTAMOUNTERROR }],
                    })(
                      <InputNumber min={0} style={{width:'100%'}} placeholder={this.lang.CREATE.LABELS.AMOUNT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.BILLNO}   >
                    {getFieldDecorator('bill', {
                      rules: [{ required: true, message:  this.lang.MESSAGE.INSERTBILLNOERROR }],
                    })(
                      <Input placeholder={this.lang.CREATE.LABELS.BILLNO}/>
                      )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}} >
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.EXPENSE} >
                    {getFieldDecorator('expense', {
                      rules: [{ required: true, message: this.lang.MESSAGE.SELECTEXPENSEERROR }],
                    })(
                      <Select onChange={this.selectExpense} placeholder={this.lang.CREATE.LABELS.EXPENSE}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            expense_type.length > 0 && expense_type.map(parent => (
                              <Select.Option key={parent.id} value={parent.id}>{parent.name}</Select.Option>
                            ))
                            : expense_type.length > 0 && expense_type.map(parent => (
                              <Select.Option style={{textAlign:'right'}} key={parent.id} value={parent.id}>{parent.name_ar}</Select.Option>
                            ))
                        }
                      </Select>)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.DATE} >
                      {getFieldDecorator('date', {
                        initialValue: moment (this.state.today),
                        rules: [{ required: true, message:  this.lang.MESSAGE.INSERTDATEERROR  }],
                      })( 
                        <DatePicker  style={{width:'100%', fontSize:'14px'}} placeholder={this.lang.CREATE.LABELS.DATE}/>
                        )}
                    </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir = {dir} style={{display}} >
                <Col span={24}>
                  <Form.Item label={this.lang.CREATE.LABELS.NOTE}>
                    {getFieldDecorator('note', {
                      rules: [{ required: false, message: this.lang.MESSAGE.INSERTNOTEERROR }],
                    })(
                      <TextArea style={{ width: '100%' }} placeholder={this.lang.CREATE.LABELS.NOTE} autoSize={{ minRows: 2, maxRows: 6 }} />
                      // <Input type="note" placeholder={this.lang.CREATE.LABELS.NOTE} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default ExpenseAdd = Form.create({ name: 'expense_form' })(ExpenseAdd);