import React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
const RouteNotFound = () => {
    let route = '/dashboard';
    let msg = 'Back to Dashboard screen';
    if (localStorage.getItem('is_contr') == 1) {
        route = '/dashboard/contpackages';
        msg = 'Back to Packages screen'
    }
    return(
        <div style={{ verticalAlign: 'middle !important',textAlign:'center'}}>
        
            <h3>404</h3>
            <p>Sorry, the page you visited does not exist.</p>
                <Button style={{margin:'0 auto'}}>
                    <Link to={route}>   
                        {msg}
                    </Link>
                </Button>
        </div>
    )
}

export default RouteNotFound;