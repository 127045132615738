import React, {Component} from 'react'
import { Row, Col, Form, Icon, Button, InputNumber, DatePicker, Table, message, Spin } from 'antd'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import moment from 'moment';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class HallDiscount extends Component {
  constructor(props) {
    super()
    this.state = {
      hall_id : props.hall_id,  
      discountList: false,
      startValue: null,
      discounts: [],
      data: [],
      sortField:"to_date",
      sortOrder:"ascend",
      pagination: {},
      page: 1,
      size: 5,
      searchValues: {
        to_date: '',
        from_date :'',
      },
      updateHallDiscount: {},
      startValue: null,
      endValue: null,
      endOpen: false,
      dir : 'ltr', 
      display: 'block',
      adiscount_spin : false,
      udiscount_spin : false,
    }
    this.lang = props.lang;
    this.msg = props.msg;

    this.HallDiscountTableColumns = [
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.FROMDATE}`,
          dataIndex: 'from_date',
          render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
        } :
        {
          title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.FROMDATE}`,
          dataIndex: 'from_date',
          align : 'right',
          render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
        } ,
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.TODATE}`,
          dataIndex: 'to_date',
        } :
        {
          title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.TODATE}`,
          dataIndex: 'to_date',
          align : 'right',
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.DISCOUNT}`,
          dataIndex: 'discount',
        } :
        {
          title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.DISCOUNT}`,
          dataIndex: 'discount',
          align : 'right',
        },
        {
          title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.ACTION}`,
          render: (text, record) => (
            <span>
              <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.HALLDISCOUNT.SETUP.BUTTON.DELETE}</Button>
            </span>
          ),
          width: '25%',
          align : 'center',
          key: 'last_name',
        }
    ]

  }//End of Constructor  

  
  handleNavigation = (record) => {
    let data = {
      id: record.id,
      hall_id: record.hall_id,
      created_by: record.created_by,
      updated_by: record.updated_by,
      discount: record.discount,
      from_date: moment(record.from_date),
      to_date: moment(record.to_date),
    }
    // console.log(record)
    this.setState({ updateHallDiscount: data, discountList: 2 })
  }

  getHallDiscounts =(hall_id)=>{ 
    const headers = getHeadersForHttpReq();
    if (hall_id) {
      axios.get(`${complete_URL}/halldiscounts/${hall_id}`,{headers})
      .then(res => { //console.log('prices',res.data.data[0])
        this.setState({ discounts: res.data.data[0] })
      })
      .catch(err => { console.log('Error occured while fetching Halldiscounts->',err) })
    }
  }//End of Method

  fetch = (params={}) => { 
    const client_id = localStorage.getItem('client_id');
    let  headers =  getHeadersForHttpReq();

    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else { sortOrder = 'DESC' }
    
    let data ={
      client_id : client_id,
      hall_id : this.state.hall_id ,
      to_date: this.state.searchValues.to_date || '',
      from_date: this.state.searchValues.from_date || '',

      page,
      sortColumn: params.sortField || this.state.sortField ,
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'no'
    }
    // console.log('Sending data to api ->',data);
    axios.post(`${complete_URL}/halldiscounts/pagination_table`,data, {headers})
    .then(response => {
      // console.log('response of exp new pagination ->',response)
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      this.setState({ data: response.data.data, pagination })
      // console.log('Expense data-> ',response);
    })
    .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })


}//End of Method

  
  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
         pagination: pager,
         pageNumber: pagination.current
      });

      if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
        if (this.state.pageNumber  !== pager.current) {
          this.fetch({ page: pagination.current });
        }
      } 
      else {
         this.fetch({
         results: pagination.pageSize,
         page: pagination.current,
         sortField: sorter.field,
         sortOrder: sorter.order,
         ...filters,
         });
      }

  }//End of Method

  
  deleteCategory = (record) => {
    const headers =  getHeadersForHttpReq();
    
    axios.delete(`${complete_URL}/halldiscounts/${record.id}`,{headers})
      .then(res => {
        message.success(this.msg.HALLDISCOUNTDELETESUCCESS ,2);
        this.fetch()
      })
      .catch(err => { 
        console.log('Error occured while deleting the hall Discount->',err);
        message.error(this.msg.HALLDISCOUNTDELETEFAILURE ,2) 
      })
  }
  
  handleSubmitHallDiscount = (e) => {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id')
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // if (parseFloat(values.discount) === 0 || parseFloat(values.discount) < 0) {
          
        // }
        this.setState({adiscount_spin : true});
        Object.assign(values,{
            user_id : user_id,
            from_date : moment(values.from_date).locale('en').format('YYYY-MM-DD'),
            to_date : moment(values.to_date).locale('en').format('YYYY-MM-DD'),
            hall_id : this.state.hall_id
        });
        // let newValues = {}
        // newValues.from_date = values.from_date.toDate().toString().split('G')[0]
        // newValues.to_date = values.to_date.toDate().toString().split('G')[0]
        // newValues.hall_id = this.state.hall.id
        // newValues.user_id = user_id
        // newValues.discount = values.discount
        // console.log(newValues)moment(values.reservation_date).locale('en').format('YYYY-MM-DD')
        let self = this;
        let  headers = getHeadersForHttpReq();
        
        axios.post(`${complete_URL}/halldiscounts/add`, values,{headers})
          .then(res => {
            if (res.data.success) {
              self.setState({ adiscount_spin : false, discountList: false, },()=>{
                message.success(this.msg.HALLDISCOUNTADDSUCCESS,2);
                this.fetch();
              })
            }
            else{
              self.setState({ adiscount_spin : false,},()=>{
                message.error(this.msg.HALLDISCOUNTADDFAILURE,2);
              })
            }
          })
          .catch(err => {
            console.log('Error occured while adding the Discount in the hall->',err);
            self.setState({ adiscount_spin : false },()=>{
              message.error(this.msg.HALLDISCOUNTADDFAILURE,2);
            })
          })
      }// End of if error not found
    })
  }
  
  handleSubmitHallDiscountUpdate = (e) => {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id')
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({udiscount_spin : true});
        // let newValues = {}
        // newValues.from_date = values.from_date.toDate().toString().split('G')[0]
        // newValues.to_date = values.to_date.toDate().toString().split('G')[0]
        // newValues.hall_id = this.state.hall.id
        // newValues.user_id = user_id
        // newValues.discount = values.discount

        // console.log(this.state.updateHallDiscount.id)
        let self = this;
        let  headers = getHeadersForHttpReq();
        // if (parseFloat(values.discount) === 0 || parseFloat(values.discount) < 0) {
          
        // }
        Object.assign(values,{
            user_id : user_id,
            from_date : moment(values.from_date).locale('en').format('YYYY-MM-DD'),
            to_date : moment(values.to_date).locale('en').format('YYYY-MM-DD'),
            hall_id : this.state.hall_id
        });
        
        axios.put(`${complete_URL}/halldiscounts/update/${this.state.updateHallDiscount.id}`, values, {headers})
          .then(res => {
            if (res.data.success) {
              self.setState({ udiscount_spin : false, discountList: false, },()=>{
                message.success(this.msg.HALLDISCOUNTUPDATESUCCESS,2);
                this.fetch();
              })
            }
            else{
              self.setState({ udiscount_spin : false,},()=>{
                message.error(this.msg.HALLDISCOUNTUPDATEFAILURE,2);
              })
            }
          })
          .catch(err => {
            console.log('Error occured while updating the Discount in the hall->',err);
            self.setState({ udiscount_spin : false },()=>{
              message.error(this.msg.HALLDISCOUNTUPDATEFAILURE,2);
            })
          })
      }
    })
  }

  componentWillMount(){
    if (localStorage.getItem('lang') === 'EN') {
      
      this.CreateHallDiscountHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ discountList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.HALLDISCOUNT.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.HallDiscountTableHeader = <Row id="clientTableHeader">
        <Col span={10}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.HALLDISCOUNT.SETUP.LABELS.TITLE}</p></Col>
        <Col span={13}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ discountList: true }) }}>{this.lang.HALLDISCOUNT.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateHallDiscountHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ discountList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.HALLDISCOUNT.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

    }
    else {
      
      this.CreateHallDiscountHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ discountList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.HALLDISCOUNT.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.HallDiscountTableHeader = <Row id="clientTableHeader">
        <Col span={8}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ discountList: true }) }}>{this.lang.HALLDISCOUNT.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={15}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.HALLDISCOUNT.SETUP.LABELS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateHallDiscountHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ discountList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.HALLDISCOUNT.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      }  
  }

  componentDidMount() {
    // this.getHallDiscounts(this.state.hall_id);
    this.fetch();
    this.setComponentDirection();
  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  disabledStartDate = (startValue) => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  }

  disabledEndDate = (endValue) => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  }

  onChange = (field, value) => {
    this.setState({ [field]: value });
  }

  onStartChange = (value) => {
    this.onChange('startValue', value);
  }

  onEndChange = (value) => {
    this.onChange('endValue', value);
  }

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  }

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { startValue, endValue, endOpen } = this.state;
    
    return (
        <Row gutter={24} >
            <Col span={24}>
            {  
            //Create Hall Discount Screen
            this.state.discountList === true ?
            <Spin tip={this.msg.HALLDISCOUNTADDTIP} spinning = {this.state.adiscount_spin}>
            <Form onSubmit={this.handleSubmitHallDiscount} style={{ marginTop: '20px' }}>
                {this.CreateHallDiscountHeader}
                <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={8}>
                    <Form.Item label={this.lang.HALLDISCOUNT.CREATE.LABELS.FROMDATE}                             >
                    {getFieldDecorator('from_date', {
                        setFieldValue: startValue,
                        rules: [{ required: true, message: this.msg.INSERTDISCOUNTFROMDATEERROR }],
                    })(
                        <DatePicker
                            disabledDate={this.disabledStartDate}
                            // showTime
                            // format="YYYY-MM-DD HH:mm:ss"
                            format="YYYY-MM-DD"
                            placeholder={this.lang.HALLDISCOUNT.CREATE.LABELS.FROMDATE}
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                            style={{ width: '100%' }} 
                        />
                    )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={this.lang.HALLDISCOUNT.CREATE.LABELS.TODATE}>
                    {getFieldDecorator('to_date', {
                        setFieldValue: endValue,
                        rules: [{ required: true, message: this.msg.INSERTDISCOUNTTODATEERROR }],
                    })(
                        <DatePicker
                            disabledDate={this.disabledEndDate}
                            // showTime
                            // format="YYYY-MM-DD HH:mm:ss"
                            format="YYYY-MM-DD"
                            placeholder={this.lang.HALLDISCOUNT.CREATE.LABELS.TODATE}
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                            style={{ width: '100%' }} 
                        />
                    )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={this.lang.HALLDISCOUNT.CREATE.LABELS.DISCOUNT}>
                    {getFieldDecorator('discount', {
                        setFieldValue: endValue,
                        rules: [{ required: true, message: this.msg.INSERTDISCOUNTERROR}],
                    })(
                        <InputNumber min={0.0} placeholder={this.lang.HALLDISCOUNT.CREATE.LABELS.DISCOUNT } style={{ width: '100%' }} />
                    )}
                    </Form.Item>
                </Col>
                </Row>
            </Form>
            </Spin>
            : this.state.discountList === false ?
                //View Hall Discount Screen
                <div>
                {this.HallDiscountTableHeader}
                <Row id="clientsTable">
                    <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                        <Table 
                            columns={this.HallDiscountTableColumns} 
                            rowKey="id" 
                            // dataSource={this.state.discounts}
                            dataSource={this.state.data}
                            onChange={this.handleChangePagination}
                            pagination={this.state.pagination}
                            // style={{ marginTop: '10px' }} 
                        />,
                    </Col>
                </Row>
                </div>
                //Update Hall Discount Screen
                :
                <Spin tip={this.msg.HALLDISCOUNTUPDATETIP} spinning = {this.state.udiscount_spin}>
                <Form onSubmit={this.handleSubmitHallDiscountUpdate.bind(this)} style={{ marginTop: '20px' }}>
                {this.UpdateHallDiscountHeader}
                <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                    <Col span={8}>
                    <Form.Item label={this.lang.HALLDISCOUNT.SETUP.LABELS.FROMDATE} >
                        {getFieldDecorator('from_date', {
                        initialValue: this.state.updateHallDiscount.from_date,
                        setFieldValue: startValue,
                        rules: [{ required: true, message: this.msg.INSERTDISCOUNTFROMDATEERROR }],
                        })(
                        <DatePicker
                            disabledDate={this.disabledStartDate}
                            // showTime
                            // format="YYYY-MM-DD HH:mm:ss"
                            format="YYYY-MM-DD"
                            placeholder={this.lang.HALLDISCOUNT.SETUP.LABELS.FROMDATE}
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                            style={{ width: '100%' }} 
                        />
                        )}
                    </Form.Item>
                    </Col>
                    <Col span={8}>
                    <Form.Item label={this.lang.HALLDISCOUNT.SETUP.LABELS.TODATE}>
                        {getFieldDecorator('to_date', {
                        initialValue: this.state.updateHallDiscount.to_date,
                        setFieldValue: endValue,
                        rules: [{ required: true, message: this.msg.INSERTDISCOUNTTODATEERROR }],
                        })(
                        <DatePicker
                            disabledDate={this.disabledEndDate}
                            // showTime
                            // format="YYYY-MM-DD HH:mm:ss"
                            format="YYYY-MM-DD"
                            placeholder={this.lang.HALLDISCOUNT.SETUP.LABELS.TODATE}
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                            style={{ width: '100%' }} 
                        />
                        )}
                    </Form.Item>
                    </Col>
                    <Col span={8}>
                    <Form.Item label={this.lang.HALLDISCOUNT.SETUP.LABELS.DISCOUNT}>
                        {getFieldDecorator('discount', {
                        initialValue: this.state.updateHallDiscount.discount,
                        setFieldValue: endValue,
                        rules: [{ required: true, message: this.msg.INSERTDISCOUNTERROR }],
                        })(
                        <InputNumber min={0.0} placeholder = {this.lang.HALLDISCOUNT.SETUP.LABELS.DISCOUNT} style={{ width: '100%' }} />
                        )}
                    </Form.Item>
                    </Col>
                </Row>
                </Form>
            </Spin>
    
            }
            </Col>
        </Row>
    )
  }

}//End of Component
export default HallDiscount = Form.create({ name: 'hall_discount' })(HallDiscount);