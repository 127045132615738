import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';
import "chartjs-plugin-labels";
import YearPicker from "react-year-picker";//old calender
// import Calendar from 'react-calendar'; //New Calender 28Oct2020
import moment from 'moment';
// import 'react-calendar/dist/Calendar.css';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import getHeadersForPDFreport from '../../constants/reportHeaders';

import { Select, Row, Col, Card, Checkbox, /*Form, DatePicker,*/Empty, Icon, Button, Spin, message } from 'antd';

function AnnualSummaryReport(props) {
  // console.log('props ->',props);
  const [searchValues, setSearchValues] = useState({yearOnly : null, bqt_id :null, disableDownloadReport : true});
  // const [disableDownloadReport, setDisableDownloadReport]  = useState(false);
  let {EN_FLAG, DOWNLOAD_REPORT} = props.language;
  const [spin, setSpin]  = useState(false);
  const [spinMsg, setSpinMsg]  = useState('');
  const dir = EN_FLAG ? 'ltr':'rtl';
  const display = EN_FLAG ? 'block':'flex';
  const textAlign = EN_FLAG ? 'left' : 'right';
  const [filterNode, setFilterNode] = useState('y');
  const [banquets, setBanquets] = useState([]);
  //Expense part
  const [expense_types, setExpTypes] = useState([]);
  const [custExpTypes, setCustExpTypes] = useState([]);
  const [allExpFlag, setAllExpFlag] = useState(false);
  const [noneExpFlag, setNoneExpFlag] = useState(true);
  //Expense part
  const [income_types, setIncTypes] = useState([]);
  const [custIncTypes, setCustIncTypes] = useState([]);
  const [allIncFlag, setAllIncFlag] = useState(false);
  const [noneIncFlag, setNoneIncFlag] = useState(true);

  const [donutData, setDonutData] = useState({});
  
  const {TITLE} = props.language._STATISTICS.ANNUAL_SUMMARY_REPORT;
  let {MONTHLY_OPTION, MONTHL, /*STARTMONTH, ENDMONTH,*/ YEARLY_OPTION, ALL } = props.language._STATISTICS.FINANCIAL_STATISTICS_CHART;
  let msg = props.language._STATISTICS.MESSAGE;
  const titleSpanStyle = { display: 'flex', flex: 1, justifyContent: 'space-between' };
  const labelStyle = { marginTop: '1%', fontSize: '14px', fontWeight: 600, color: 'black'};
  const pt = localStorage.getItem('pt');
  const complete_URL = API_URL+pt;

  const option = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let dataset = data.datasets[tooltipItem.datasetIndex];
          let meta = dataset._meta[Object.keys(dataset._meta)[0]];
          let total = meta.total;
          let currentValue = dataset.data[tooltipItem.index];
          let percentage = parseFloat((currentValue/total*100).toFixed(2));
          return currentValue + ' (' + percentage + '%)';
        },
        title: function(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        }
      }
    },
    maintainAspectRatio: false,
    responsive: false,
    legend: {
       position: 'top',//'left',
       labels: {
          boxWidth: 25,
       }
    }, 
  };

  useEffect(() => {
    if (searchValues.yearOnly && searchValues.bqt_id) {
      // ajax call fetch doughnut data
      downloadYearOnlyData(searchValues.yearOnly, 'smrd');
      setParamsYear('', true, 'smrd');
    }
  }, [searchValues]);

  // code to run on component didmount
  useEffect(() => {
    const client_id = localStorage.getItem('client_id');
    // let currentYear = new Date().getFullYear();
    getBanquetsByClient(client_id);
    getExpenseTypes(client_id);
    getIncomeTypes(client_id);
    // setParamsYear(currentYear, false, 'smr');
  }, []);

  useEffect(() => {
    if (custExpTypes.length === 0) { setNoneExpFlag(true); }
  }, [custExpTypes]);

  useEffect(() => {
    if (custIncTypes.length === 0) { setNoneIncFlag(true); }
  }, [custIncTypes]);

  useEffect(() => {
    if (allExpFlag) { setCustExpTypes([]); setNoneExpFlag(false); }
  }, [allExpFlag]);

  useEffect(() => {
    if (allIncFlag) { setCustIncTypes([]); setNoneIncFlag(false); }
  }, [allIncFlag]);

  const getBanquetsByClient=(client_id)=>{
    if (client_id) {
      const headers = getHeadersForHttpReq();
      axios.get(`${complete_URL}/banquets/by_clients/${client_id}`, {headers})
        .then(result => { //console.log('banquets in Annual report function',result.data.data[0])
          setBanquets(result.data.data[0]);
          if (result.data.data[0].length > 0) {
            handleChangeBqt(result.data.data[0][0].id);
          }
        })
        .catch(err => { console.log('Error occured while fetching Banquets by client in Annual report screen->',err) });
    }
  }//End of Method
  
  const getExpenseTypes = (client_id)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/expensetypes/by_client/${client_id}`,{headers})
      .then(res => { // console.log('expense_types->',res)
        setExpTypes(res.data.data)
      })
      .catch(err => {  console.log('Issue while fetching expense_types in annual report screen ->',err) })
  }//End of Method

  const getIncomeTypes = (client_id)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/incometypes/by_client/${client_id}`,{headers})
    .then(res => { // console.log('income_types->',res)
      setIncTypes( res.data.data);
    })
    .catch(err => { console.log('Issue while fetching income_types ->',err) })
  }

  const handleChangeBqt = (bqt_id)=>{
    if (bqt_id) { //console.log('banquet id ->', bqt_id)
      let new_report_obj = Object.assign(searchValues, { bqt_id });
      setSearchValues({...new_report_obj});
    }
  } 

  const setParamsYear = (yearOnly, resetOpt, report_type) => {
    if (report_type === 'smr') {
      let new_report_obj = Object.assign(searchValues, { yearOnly: yearOnly, disableDownloadReport: resetOpt });
      setSearchValues({...new_report_obj});
    }
  }//End of useEffect call

  //get JSON objects of selected expense & income types
  const getJSONArrByIDs =()=>{
    let expJSONArr = [], incJSONArr = [];
    if (custExpTypes.length > 0) {
      for (let i = 0; i < expense_types.length; i++){
          if(custExpTypes.includes(expense_types[i].id)){
            expJSONArr.push(expense_types[i]);
          }
      }
    }
    
    if (custIncTypes.length > 0) {
      for (let j = 0; j <income_types.length; j++){
        if(custIncTypes.includes(income_types[j].id)){
          incJSONArr.push(income_types[j]);
        }
      }
    }
    return [expJSONArr, incJSONArr];
  }

  const downloadYearOnlyData = (yearOnly, report_type) => {
    let req_data = props.getReportBasicData();
    if (req_data.client_id) {
      let headers2 = getHeadersForPDFreport();
      let [expJSONArr, incJSONArr] = getJSONArrByIDs();//get JSON objects of selected expense & income types
      Object.assign(req_data, 
        {yearOnly, bqt_id : searchValues.bqt_id, allExpFlag, allIncFlag, noneIncFlag,noneExpFlag, expJSONArr, incJSONArr});
      
      if (report_type === 'smrd') {//Annual summary report Doughnut chart
        let canFetch = searchValues.disableDownloadReport 
                      || (!allExpFlag && !noneExpFlag && custExpTypes.length === 0)
                      || (!allIncFlag && !noneIncFlag && custIncTypes.length === 0)
        if (!canFetch) { 
          const headers = getHeadersForHttpReq();
          setSpin(true);
          setSpinMsg(msg.ASRCREATEDONUTCHARTTIP);
          axios({ url: `${complete_URL}/statistics/annualSummaryData`,
                  method: 'post', data: req_data, headers
          })
          .then(donutResp=>{
              setSpin(false);
              if (donutResp.data.success) {
                if (donutResp.data.data.length > 0) {
                let d = [], c = [], l =[];
                for (let i = 0; i < donutResp.data.data.length; i++) {
                    d.push(donutResp.data.data[i].amount);
                    l.push(EN_FLAG ? donutResp.data.data[i].name : donutResp.data.data[i].name_ar);
                    let clr = "#"+((1<<24)*Math.random()|0).toString(16);
                    c.push(clr);
                } 
                let data_updated = [];
                data_updated.push( Object.assign({},{label :'Annual Summary', data : d, backgroundColor: c}));
                setDonutData({labels : l, datasets : data_updated });
                }
                else{
                  setDonutData({});
                }
              }
          })
          .catch(donut_err=>{
              setSpin(false);
              message.error(msg.ASRDONUTDATAFETCHFAILURE,3);
              console.log('In Annual summary..donut_err->',donut_err)
          });
        }//End of check

      }
      else if (report_type === 'smr') {//Annual summary report
        setSpin(true);
        setSpinMsg(msg.ASRDOWNLOADREPORTTIP);
        axios({
          method: 'post',
          url: `${complete_URL}/annual_report/generic_report`,
          data: req_data,
          responseType: 'arraybuffer',
          headers: { ...headers2 }
       })
        .then(smr_resp=>{
            setSpin(false);
            if (smr_resp.data.byteLength > 0) {
              let file_name = 'AnnualSummaryReport_' + yearOnly + '.pdf';
              props.generate_pdf_popup( smr_resp.data, file_name);
            }
            else { message.error(/*this.msg.EXPYEARDATANOTFOUNDERROR*/JSON.stringify(smr_resp),5);
              console.log('Else part of annual report smr_resp->',smr_resp);
            }
        })
        .catch(smr_err=>{
            console.log('Error occured while fetching smr_err ->',smr_err);
            setSpin(false);
            message.error(/*this.msg.EXPREPORTGENERATEFAILURE*/JSON.stringify(smr_err),5);
        });
      }//End of Annual summary report
    }//End of Cliend ID
  }//End of Method

  const downloadReport =(report_type)=>{
    if (report_type === 'smr') {
      let {yearOnly} = searchValues;
      downloadYearOnlyData(yearOnly, 'smr');
      setParamsYear('', true, 'smr');
    }
  }//End of Method

    return (
      <> 
      <Spin tip={spinMsg} spinning={spin}>
        <Card className="chartClass" key={'smrCard'} style={{height: '730px'}}
            title={[
              <span key={'smrFn'} style={titleSpanStyle} >
                  <h1 >{TITLE}</h1>
                  <Button 
                    type="primary" icon="download" size={'default'}
                    onClick={() => { downloadReport('smr') }} 
                    disabled={searchValues.disableDownloadReport 
                      || (!allExpFlag && !noneExpFlag && custExpTypes.length === 0)
                      || (!allIncFlag && !noneIncFlag && custIncTypes.length === 0)
                    } 
                  >
                    {DOWNLOAD_REPORT}
                  </Button> 
              </span>
            ]}
          >   {/* Year selection */}
            <Row gutter={24} dir = {dir} style={{display, zIndex:11111}}>
              <Col span={12}>
                <Select suffixIcon={[<Icon type="filter" key={'smrIc'} />]}
                    onChange={setFilterNode}
                    style={{ width: '100%' }}
                    key = {'smr'} disabled = {true}
                    placeholder={props.language.FILTER_OPTION}
                    value = {filterNode}
                  >
                    <Select.Option style={{textAlign}} value="m" key={'fn'+1}>{ MONTHLY_OPTION }</Select.Option>
                    <Select.Option style={{textAlign}} value="y" key={'fn'+2}> { YEARLY_OPTION }</Select.Option>
                    <Select.Option style={{textAlign}} value="mm" key={'fn'+3}>{ MONTHL }</Select.Option>
                    <Select.Option style={{textAlign}} value="aa" key={'fn'+5}>{ ALL }</Select.Option>
                </Select>
              </Col>
              <Col span={12}>
                <YearPicker value={new moment(searchValues.yearOnly)} onChange={(e) => { setParamsYear(e, false, 'smr') }} placeholder={YEARLY_OPTION} />
              </Col>
            </Row>
            <br/>{/* Banquet selection */}
            <Row gutter={24} dir = {dir} style={{display}}>
              <Col span={12} style = {labelStyle}>
                {props.language._RESERVATION.CREATE.LABELS.BANQUET+ ':'}
              </Col>
              <Col span={12}>
                <Select placeholder={props.language._HALL.CREATE.LABELS.SELECTBANQUET} 
                        onChange={handleChangeBqt} style={{ width: '100%' }}
                        value = {searchValues.bqt_id}
                  >
                  {
                    EN_FLAG ?
                      banquets.length > 0 && banquets.map(b => (
                        <Select.Option key={b.id} value={b.id}>{b.name}</Select.Option>
                      ))
                      :
                      banquets.length > 0 && banquets.map(b => (
                        <Select.Option style={{textAlign:'right'}} key={b.id} value={b.id}>{b.name_ar}</Select.Option>
                      ))
                  }
                </Select>
              </Col>
            </Row>
            <br/>
            {/* Expense Types selection (Custom or all) */}
            <Row gutter={24} dir = {dir} style={{display}}>
              <Col span={6} style = {labelStyle}>
                {props.language._EXPENSETYPE.TITLE+ ' : '}
              </Col>
              <Col span={7} style={{ marginTop: '1%'}}>
                <Checkbox onChange={(e)=>setAllExpFlag(e.target.checked)} checked = {allExpFlag}>{EN_FLAG ? 'All':'الكل'}</Checkbox>
                <Checkbox onChange={(e)=>setNoneExpFlag(e.target.checked)} checked = {noneExpFlag}>{EN_FLAG ? 'None':'لا شيء'}</Checkbox>
              </Col>
              <Col span={11} >
                <Select placeholder={props.language._EXPENSETYPE.TITLE} 
                        onChange={setCustExpTypes} style={{ width: '100%' }}
                        value = {custExpTypes}
                        // optionLabelProp="label"
                        mode="multiple"
                        disabled = {allExpFlag || noneExpFlag}
                  >
                  {
                    EN_FLAG ?
                      expense_types.length > 0 && expense_types.map(ex => (
                        <Select.Option key={ex.id} value={ex.id}>{ex.name}</Select.Option>
                      ))
                      :
                      expense_types.length > 0 && expense_types.map(ex => (
                        <Select.Option style={{textAlign:'right'}} key={ex.id} value={ex.id}>{ex.name_ar}</Select.Option>
                      ))
                  }
                </Select>
              </Col>
            </Row>
            <br/>
            {/* Income Types selection (Custom or all) */}
            <Row gutter={24} dir = {dir} style={{display}}>
              <Col span={6} style = {labelStyle}>
                {props.language._INCOMETYPE.TITLE+ ' : '}
              </Col>
              <Col span={7} style={{ marginTop: '1%'}}>
                <Checkbox onChange={(e)=>setAllIncFlag(e.target.checked)} checked = {allIncFlag}>{EN_FLAG ? 'All':'الكل'}</Checkbox>
                <Checkbox onChange={(e)=>setNoneIncFlag(e.target.checked)} checked = {noneIncFlag}>{EN_FLAG ? 'None':'لا شيء'}</Checkbox>
              </Col>
              <Col span={11} >
                <Select placeholder={props.language._INCOMETYPE.TITLE} 
                        onChange={setCustIncTypes} style={{ width: '100%' }}
                        value = {custIncTypes}
                        // optionLabelProp="label"
                        mode="multiple"
                        disabled = {allIncFlag || noneIncFlag}
                  >
                  {
                    EN_FLAG ?
                      income_types.length > 0 && income_types.map(inc => (
                        <Select.Option key={inc.id} value={inc.id}>{inc.name}</Select.Option>
                      ))
                      :
                      income_types.length > 0 && income_types.map(inc => (
                        <Select.Option style={{textAlign:'right'}} key={inc.id} value={inc.id}>{inc.name_ar}</Select.Option>
                      ))
                  }
                </Select>
              </Col>
            </Row>
            <br/>
            {/* Donuchart Row */}
            <Row gutter={24} dir = {dir} style={{display}}>
              <Col span={24}>
                {
                  donutData.hasOwnProperty('datasets') ? 
                  <Doughnut 
                    data={donutData}
                    options={option}
                    width = {400}
                    height= {400}
                  /> : 
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
              </Col>
            </Row>
        </Card>
      </Spin> 
      </>
    )

}

export default AnnualSummaryReport/* = Form.create({ name: 'yearly_summary' })(AnnualSummaryReport)*/;