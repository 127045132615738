import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Card, Input, message, Select, InputNumber } from 'antd'
import axios from 'axios';
import { Link } from 'react-router-dom'
import API_URL from '../../constants';
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class ContPackageAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
      getPackagesByReservation: {},
      package:{},
      packagesData: [],
      commissionOptions: [{'id':0,'name':'Percentage','name_ar':'بنسبة مئوية'},{'id':1,'name':'Amount','name_ar':'حسب الكمية'}],
      commissionOption : 0,
      dir : 'ltr', 
      display: 'block'
    }
    this.lang = props.language._CONTRACTOR.UPDATE.PACKAGE;
    this.msg = props.language._CONTRACTOR.UPDATE.PACKAGE.MESSAGE;

  }

  HandleSubmitPackage = (e) => {
    e.preventDefault();
    let id = JSON.parse(localStorage.getItem('updateContractor')).id
    var user_id = localStorage.getItem('user_id')

    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.contractor_id = id;
        values.user_id = user_id;
        // console.log(values)
        axios.post(`${complete_URL}/contractorpackages/add`, values)
          .then(res => {
            if (res.data.success) {
              message.success(this.msg.ADDSUCCESS);
              this.props.history.push('/dashboard/contpackages')
            } 
            else {
              message.error(this.msg.ADDFAILURE,3);
            }
          })
          .catch(err => {
            console.log('Error occured in creating the Contractor package->',err)
            message.error(this.msg.ADDFAILURE,3)
          })
      }
      else { console.log('Contractor Package Form Error->',err); }
    })
  }

  componentDidMount() {
    let userid = localStorage.getItem('user_id');
    
    let reservationByOwner = this.getIsOwnerBooleanCheck(userid);
    // console.log('In didmount contractor packages->',reservationByOwner);
    if (localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block', reservationByOwner : reservationByOwner.reservationByOwner})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex', reservationByOwner : reservationByOwner.reservationByOwner})
    }
  }
  handleCommissionTypeSelection =(val)=>{
    this.setState({commissionOption:val},()=>{
      if (val == 0) {
        this.props.form.setFieldsValue({ commission_amount : 0.00})
      }
      else {
        this.props.form.setFieldsValue({ commission_percentage  : 0.00})
      }
    });
  }

  componentWillMount() {
      
    if (localStorage.getItem('lang') === 'EN') {
        this.PackageAddHeader = <Row className="top-header" gutter={24}>
          <Col span={5}>
            <Link to="/dashboard/contpackages">
              <Button type="danger"><Icon type="arrow-left" /></Button>
            </Link>
          </Col>
          <Col span={12}>
            <h1 style={{textAlign:'center'}}>{this.lang.CREATE.LABELS.TITLE}</h1>
          </Col>
          <Col className="btn-box" span={6} style={{display:'flex'}}>
            <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
            <Link to="/dashboard/contpackages">
              <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
            </Link>
          </Col>
        </Row>
      }
      else {
        this.PackageAddHeader = <Row className="top-header" gutter={24}>
          <Col span={4}>
            <Link to="/dashboard/contpackages">
              <Button type="danger"><Icon type="arrow-left" /></Button>
            </Link>
          </Col>
          <Col span={15}>
            <h1 style={{textAlign:'center'}}>{this.lang.CREATE.LABELS.TITLE}</h1>
          </Col>
          <Col className="btn-box" span={5} style={{display: 'flex'}}>
            <Link to="/dashboard/contpackages">
              <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
            </Link>
            <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          </Col>
        </Row>
      }
    
  }// End of method
  
  onCommisionchange =()=>{

  }
  getIsOwnerBooleanCheck = (userid)=>{
    let reservationByOwner = {};
    let  headers = {
      'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
      }
    axios.get(`${complete_URL}/users/${userid}`,{headers})
    .then(res => { // console.log('from API call ..reservationByOwner->',res.data.is_owner);
      Object.assign(reservationByOwner , {reservationByOwner : res.data.is_owner});
    })
    .catch(err => { console.log(err) }) 
    return reservationByOwner;
  }

  render() {
    const { getFieldDecorator } = this.props.form
    // console.log('this.lang ->',this.lang);
    return (
    <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.HandleSubmitPackage} style={{ marginTop: '20px' }}>
            {this.PackageAddHeader}
                <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                    <Col span={12}>
                    {
                        <Form.Item label={this.lang.CREATE.LABELS.PACKAGETYPE}  >
                        {getFieldDecorator('type', {
                            rules: [{ required: true, message: this.msg.INSERTPKGTYPEERROR }]
                        })(
                            <Input placeholder={this.lang.CREATE.LABELS.PACKAGETYPE} />
                        )}
                        </Form.Item>
                    }
                    </Col>
                    <Col span={12}>
                    {
                        <Form.Item label={this.lang.CREATE.LABELS.PACKAGEPRICE}  >
                        {getFieldDecorator('price', {
                            rules: [{ required: true, message: this.msg.INSERTPKGPRICEERROR }]
                        })(
                            <Input placeholder={this.lang.CREATE.LABELS.PACKAGEPRICE} />
                        )}
                        </Form.Item>
                    }
                    </Col>
                </Row>
                <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                    <Col span={12}>
                    {
                        <Form.Item label={this.lang.CREATE.LABELS.PACKAGEDESCRIPTION} >
                        {getFieldDecorator('description', {
                            rules: [{ required: true, message: this.msg.INSERTPKGDESCERROR }]
                        })(
                            <Input placeholder={this.lang.CREATE.LABELS.PACKAGEDESCRIPTION} />
                        )}
                        </Form.Item>
                    }
                    </Col>
                    <Col span={12}>
                    {
                        <Form.Item label={this.lang.CREATE.LABELS.PACKAGEDESCRIPTIONAR}>
                        {getFieldDecorator('description_ar', {
                            rules: [{ required: true, message:  this.msg.INSERTPKGDESCARERROR }]
                        })(
                            <Input dir='rtl'   />
                        )}
                        </Form.Item>
                    }
                    </Col>
                </Row>
                
                <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                    <Col span={8}>
                        <Form.Item label= {this.lang.CREATE.LABELS.COMMISSIONTYPE}  >
                            {getFieldDecorator('commissionOption', {
                                initialValue:this.state.commissionOptions[this.state.commissionOption].id,
                                rules: [{ required: true, message: this.msg.SELECTCOMMTYPEERROR }],
                            })(
                            <Select onChange={(e)=>this.handleCommissionTypeSelection(e)} placeholder= {this.lang.CREATE.LABELS.COMMISSIONTYPE}>
                                {
                                (localStorage.getItem('lang') === 'EN') ?
                                    this.state.commissionOptions.map(commission => (
                                    <Select.Option key={commission.id} value={commission.id}>{commission.name}</Select.Option>
                                    )):
                                    this.state.commissionOptions.map(commission => (
                                    <Select.Option style={{textAlign:'right'}} key={commission.id} value={commission.id}>{commission.name_ar}</Select.Option>
                                    ))
                                }
                            </Select>)}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        {
                            <Form.Item  label= {this.lang.CREATE.LABELS.COMMISSIONPERCENT} >
                            {getFieldDecorator('commission_percentage', {
                                rules: [{ required: this.state.commissionOption == 0 , message: this.msg.INSERTCOMMPERCENTERROR }]
                            })(
                                <InputNumber  placeholder={this.lang.CREATE.LABELS.COMMISSIONPERCENT}  
                                disabled={this.state.commissionOption == 1} max={100.00} min = {0.00} style={{width:'100%'}}
                                />
                            )}
                            </Form.Item>
                        }
                    </Col>
                    <Col span={8}>
                        {
                            <Form.Item  label={this.lang.CREATE.LABELS.COMMISSIONAMOUNT}  >
                            {getFieldDecorator('commission_amount', {
                                rules: [{ required: this.state.commissionOption == 1, message: this.msg.INSERTCOMMAMOUNTERROR }]
                            })(
                                <InputNumber  placeholder={this.lang.CREATE.LABELS.COMMISSIONAMOUNT}
                                disabled={this.state.commissionOption == 0}  style={{width:'100%'}}
                                />
                            )}
                            </Form.Item>
                        }
                    </Col>
                </Row>
            </Form>
          </Card>
        </Col>
    </Row>
    )
  }
}

export default ContPackageAdd = Form.create({ name: 'normal_login' })(ContPackageAdd);