import React, { Component } from 'react';
import { Row, Col,Table, message, Button, Input, Icon, Spin, Modal } from 'antd'
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';

if (localStorage.getItem('lang') === 'AR') {
    require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Approve_Contractor_Request extends Component{
    constructor(props) {
      super(props)
      this.state = {
          data: [],
          packagesData : [],
          pagination: {},
          page: 1,
          size: 5,
          sortField:"name",
          sortOrder:"ascend",
          searchValues: {
            name: '',
            name_ar: '',
            email: '',
            id_card: '',
            ph_num: '',
            ph_alt_num: '',
            new_service_type_name: '',
            new_service_type_name_ar: '',
            commercial_reg_no: '',
            orderBy : '',
            column_name :'name'
          },
          selected_contractor : {},
          contractor_doc: '00000000-0000-00',
          dir : 'ltr', 
          display: 'block',
          spin : false,
      }

      this.lang = props.language._CLIENT_CONTRACTOR_REQ.SETUP;
      this.msg =  props.language._CLIENT_CONTRACTOR_REQ.MESSAGE;
      this.pkg_lang = props.language._CONTRACTOR.UPDATE.PACKAGE;
  
      this.columns = [
        (localStorage.getItem('lang')==='EN')?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.NAME}
              <Search
                placeholder={this.lang.TABLEHEADERS.NAME}
                onChange={this.handleChangeSearchValues("name")}
              />
            </span>
          ),
          dataIndex: 'name',
          key: 'name',
          width : '15%',
          align : 'left',
          sorter: (a, b) => this.compareByAlph(a.name, b.name)
        }:
        {
          title: (
            <span>{this.lang.TABLEHEADERS.NAMEAR}
              <Search
                placeholder={this.lang.TABLEHEADERS.NAMEAR}
                onChange={this.handleChangeSearchValues("name_ar")}
              />
            </span>
          ),
          dataIndex: 'name_ar',
          key: 'name_ar',
          align : 'right',
          width : '15%',
          sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
          
        },
        (localStorage.getItem('lang')==='EN')?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.EMAIL}
              <Search
                placeholder={this.lang.TABLEHEADERS.EMAIL}
                onChange={this.handleChangeSearchValues("email")}
              />
            </span>
          ),
          dataIndex: 'email',
          key: 'email',
          width : '15%',
          align : 'left',
          sorter: (a, b) => this.compareByAlph(a.email, b.email),
        }:
        {
          title: (
            <span>{this.lang.TABLEHEADERS.EMAIL}
              <Search
                placeholder={this.lang.TABLEHEADERS.EMAIL}
                onChange={this.handleChangeSearchValues("email")}
              />
            </span>
          ),
          dataIndex: 'email',
          key: 'email',
          align : 'right',
          width : '15%',
          sorter: (a, b) => this.compareByAlph(a.email, b.email),
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.PHONENO}
              <Search
                placeholder={this.lang.TABLEHEADERS.PHONENO}
                onChange={this.handleChangeSearchValues("ph_num")}
              />
            </span>
          ),
          dataIndex: 'ph_num',
          key: 'ph_num',
          width : '15%',
          sorter: (a, b) => this.compareByAlph(a.ph_num, b.ph_num),
        }:
        {
          title: (
            <span>{this.lang.TABLEHEADERS.PHONENO}
              <Search
                placeholder={this.lang.TABLEHEADERS.PHONENO}
                onChange={this.handleChangeSearchValues("ph_num")}
              />
            </span>
          ),
          dataIndex: 'ph_num',
          key: 'ph_num',
          width : '15%',
          align : 'right',
          sorter: (a, b) => this.compareByAlph(a.ph_num, b.ph_num),
        },
        
        (localStorage.getItem('lang')==='EN')?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.SERVICETYPE}
              <Search
                placeholder={this.lang.TABLEHEADERS.SERVICETYPE}
                onChange={this.handleChangeSearchValues("new_service_type_name")}
              />
            </span>
          ),
          dataIndex: 'new_service_type_name',
          key: 'new_service_type_name',
          width : '15%',
          align : 'left',
          sorter: (a, b) => this.compareByAlph(a.new_service_type_name, b.new_service_type_name),
        } :
        {
          title: (
            <span>{this.lang.TABLEHEADERS.SERVICETYPE}
              <Search
                placeholder={this.lang.TABLEHEADERS.SERVICETYPE}
                onChange={this.handleChangeSearchValues("new_service_type_name_ar")}
              />
            </span>
          ),
          dataIndex: 'new_service_type_name_ar',
          key: 'new_service_type_name_ar',
          align : 'right',
          width : '15%',
          sorter: (a, b) => this.compareByAlph(a.new_service_type_name_ar, b.new_service_type_name_ar),
        },
        {
          title: `${this.lang.TABLEHEADERS.ACTION}`,
          render: (text, record) => (
            <span>
              <Button type="primary" onClick={this.fetchPackageData.bind(this, record)}>{this.lang.BUTTON.DETAILS}</Button>
            </span>
          ),
          key: 'pkg_btn',
          width : '7%',
          align:'center'
        },
        {
          title: '.',
          render: (text, record) => (
            <span>
              {
                record.status === "Approved"?
                <Button type="primary" disabled = {true}>{this.lang.BUTTON.APPROVED}</Button>
                :
                <Button type="primary" onClick={this.handleApproveReq.bind(this, record)}>{this.lang.BUTTON.APPROVEREQ}</Button>
              }
            </span>
          ),
          key: 'aprv_btn',
          width : '7%',
          align:'center'
        },
      ];

        
      this.pkg_columns = [
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.pkg_lang.SETUP.LABELS.PACKAGETYPE}`,
          dataIndex: 'type',
          width: '15%',
          align:'left',
          render: (text, record) =>  <p>{text}</p>,
        }:
        {
          title: `${this.pkg_lang.SETUP.LABELS.PACKAGETYPE}`,
          dataIndex: 'type',
          width: '15%',
          align:'right',
          render: (text, record) =>  <p>{text}</p>,
        },
        
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.pkg_lang.SETUP.LABELS.DESCRIPTION}`,
          dataIndex: 'description',
          width: '25%',
          align:'left',
          render: (text, record) => <p>{text}</p>,
        }:
        {
          title: `${this.pkg_lang.SETUP.LABELS.DESCRIPTION}`,
          dataIndex: 'description_ar',
          align:'right',
          width: '25%',
          render: (text, record) => <p>{text}</p>,
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.pkg_lang.SETUP.LABELS.PACKAGEPRICE}`,
          dataIndex: 'price',
          width: '15%',
          align:'left',
          render: (text, record) => ( 
            <>
            <p style={{background : record.cpkgng_id ? '#ef9595' : 'transparent', color:'black' }}>{text}</p>
            {record.cpkgng_id &&
              <p style={{background : '#dff0d8', color:'black' }}>{record.neg_price}</p>
            }
            </>
          )
        }:
        {
          title: `${this.pkg_lang.SETUP.LABELS.PACKAGEPRICE}`,
          dataIndex: 'price',
          align:'right',
          width: '15%',
          render: (text, record) =>   ( 
            <>
            <p style={{background : record.cpkgng_id ? '#ef9595' : 'transparent', color:'black' }}>{text}</p>
            {record.cpkgng_id &&
              <p style={{background : '#dff0d8', color:'black' }}>{record.neg_price}</p>
            }
            </>
          )
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONPERCENT}`,
          dataIndex: 'commission_percentage',
          width: '20%',
          align:'left',
          render: (text, record) => ( 
            <>
            <p style={{background : record.cpkgng_id ? '#ef9595' : 'transparent', color:'black' }}>{text}</p>
            {record.cpkgng_id &&
              <p style={{background : '#dff0d8', color:'black' }}>{record.neg_comm_pct}</p>
            }
            </>
          )
        }:
        {
          title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONPERCENT}`,
          dataIndex: 'commission_percentage',
          width: '20%',
          align:'right',
          render: (text, record) =>   ( 
            <>
            <p style={{background : record.cpkgng_id ? '#ef9595' : 'transparent', color:'black' }}>{text}</p>
            {record.cpkgng_id &&
              <p style={{background : '#dff0d8', color:'black' }}>{record.neg_comm_pct}</p>
            }
            </>
          )
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
          dataIndex: 'commission_amount',
          width: '20%',
          align:'left',  
          render: (text, record) =>   ( 
            <>
            <p style={{background : record.cpkgng_id ? '#ef9595' : 'transparent', color:'black' }}>{text}</p>
            {record.cpkgng_id &&
              <p style={{background : '#dff0d8', color:'black' }}>{record.neg_comm_amt}</p>
            }
            </>
          )
        }:
        {
          title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
          dataIndex: 'commission_amount',
          width: '20%',
          align:'right',
          render: (text, record) =>  ( 
            <>
            <p style={{background : record.cpkgng_id ? '#ef9595' : 'transparent', color:'black' }}>{text}</p>
            {record.cpkgng_id &&
              <p style={{background : '#dff0d8', color:'black' }}>{record.neg_comm_amt}</p>
            }
            </>
          )
        },
        // (localStorage.getItem('lang') === 'EN') ?
        // {
        //   title: `${this.pkg_lang.SETUP.LABELS.ISACTIVE}`,
        //   dataIndex: 'is_active',
        //   render: (text, record) => <p>{text===1? 'Yes':'No'}</p>,
        // }:
        // {
        //   title: `${this.pkg_lang.SETUP.LABELS.ISACTIVE}`,
        //   dataIndex: 'is_active',
        //   align:'right',
        //   render: (text, record) => <p>{text===1? 'نعم':'لا'}</p>,
        // }
      ];

    }// End of Approve_Contractor_Request

    downloadDoc = () => {
      let documentid = this.state.contractor_doc;
      if (documentid !== '00000000-0000-00') {
        // console.log(documentid)
        axios.get(`${complete_URL}/images/${documentid}`, { responseType: 'blob' })
          .then(results => {
            let doc
            var reader = new FileReader();
            reader.readAsDataURL(results.data);
            reader.onloadend = () => {
              doc = reader.result
              const link = document.createElement('a');
              link.href = doc;
              link.setAttribute('download', `Request-Document.${results.data.type.split('/')[1]}`); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(err => {console.log('Error occured while downloading the Request  Document ->',err); })
      }
    }// End of Method
    
    setComponentDirection=()=>{
      if(localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
      }
      else {
        this.setState({dir : 'rtl', display: 'flex'})
      }
    }

    compareByAlph = (a, b) => {
        if (a > b) { return -1; }
        if (a < b) { return 1; }
        return 0;
    }

    handleApproveReq = (record)=>{
      this.setState({spin_msg : this.msg.REQAPPROVEBYCLIENTTIP, spin : true});

      const headers = getHeadersForHttpReq();
      let data = {
        client_id : localStorage.getItem('client_id'),
        user_id : localStorage.getItem('user_id'),
        cont_id : record.id,
        ccr_id : record.ccr_id,
        req_by : 0,
        pkgs : JSON.stringify(this.state.packagesData)
      }    
      axios.post(`${complete_URL}/cont_clients_req/approve_cont_client_req`, data, {headers})
      .then(res => { //console.log('cont req res.data->',res.data);
        if (res.data.success) {
          this.setState({modelVisible : false, spin : false, spin_msg :''})
          message.success(this.msg.REQAPPROVEBYCLIENTSUCCESS,2);
          this.fetch();
        } 
        else {
          this.setState({modelVisible : false, spin : false, spin_msg :''},()=>message.error(this.msg.REQAPPROVEBYCLIENTFAILURE,3));
        }
      })
      .catch(err => {
        console.log('Error occured while approving the Request->',err);
        this.setState({modelVisible : false, spin : false, spin_msg :''},()=>message.error(this.msg.REQAPPROVEBYCLIENTFAILURE,3));
      })
    }// End of Method

    handleChangePagination = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({ pagination: pager, pageNumber: pagination.current });
    
        if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
          if (this.state.pageNumber  !== pager.current) {
            this.fetch({ page: pagination.current, });
          }
        } 
        else {
          this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
          });
        }
    }

    handleChangeSearchValues = (prop) => (e) => {
        let search = { ...this.state.searchValues };
        search[`${prop}`] = e.target.value;
        this.setState({
          searchValues: search
        }, () => {
          this.fetch();
        });
    }
  
    fetch = (params={}) => {
      const client_id = localStorage.getItem('client_id');
      const headers = getHeadersForHttpReq();
      let page,sortOrder ;
      if (params.page) { page = params.page - 1 }
      else { page = 0;}

      if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
      else if (params.sortOrder == 'descend') { sortOrder = 'DESC' }
      else { sortOrder = 'ASC' }
      
      let data ={
        client_id : client_id,
        name: this.state.searchValues.name || '',
        name_ar: this.state.searchValues.name_ar || '',
        email: this.state.searchValues.email || '',
        id_card: this.state.searchValues.id_card || '',
        ph_num: this.state.searchValues.ph_num || '',
        ph_alt_num: this.state.searchValues.ph_alt_num || '',
        new_service_type_name: this.state.searchValues.new_service_type_name || '',
        new_service_type_name_ar: this.state.searchValues.new_service_type_name_ar || '',
        commercial_reg_no: this.state.searchValues.commercial_reg_no || '',
        page,
        sortColumn: params.sortField || this.state.sortField ,
        sortOrder,
        size:this.state.size,
        orderBy: '',
        column_name: 'no'
      }
        // console.log('Sending data to api ->',data);
        axios.post(`${complete_URL}/cont_clients_req/get_cont_req_pagination_table`,data, {headers})
        .then(response => {
          //  console.log('response of cont_clients_re pagination response.data.data ->',response.data.data)
          let pagination = {};
          Object.assign(pagination,{
            ...this.state.pagination,
            total : response.data.totalElements,
            current : page +1,
            pageSize : this.state.size
          });
          
          this.setState({ data: response.data.data, pagination })
          // console.log('Expense data-> ',response);
        })
        .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })
      
    }

    componentDidMount(){
      this.fetch();        
      this.setComponentDirection();
    }

    fetchPackageData = async (record) => {
      const cont_id = record.id;
      if (cont_id) {
        localStorage.setItem('contprof',cont_id);
        let cd = record.contractor_doc ? record.contractor_doc : '00000000-0000-00';
        const client_id = localStorage.getItem('client_id');
        const headers = getHeadersForHttpReq();
        // await axios.get(`${complete_URL}/contractorpackages/fetch_by_contractor/${cont_id}`)
        await axios.get(`${complete_URL}/contractorpackages/fetch_pkg_neg_by_contractor/${cont_id}/${client_id}`,{headers})
        .then(res => { // console.log('packagesData->',res.data.data[0])
            this.setState({ packagesData: res.data.data, contractor_doc: cd, selected_contractor : record,  modelVisible : true });
        })
        .catch(err => { console.log('Error occured while fetching Packages from Contractor ID->',err) })
      }
    }
    
    componentWillMount() {
      if (localStorage.getItem('lang') === 'EN') {
        this.CustomRow = <Row id="clientTableHeader">
          <Col span={1}></Col>
          <Col span={10}><p style={{ textAlign: 'left'}}>{this.lang.TABLEHEADERS.APPROVECONTREQTITLE}</p></Col>
          <Col span={12}></Col>
        </Row>
      }
      else {
        this.CustomRow = <Row id="clientTableHeader">
          <Col span={12} > </Col>
          <Col span={10}><p>{this.lang.TABLEHEADERS.APPROVECONTREQTITLE}</p></Col>
          <Col span={1}></Col>
        </Row>
      }
    }

    closePkgsModal = ()=>{
      this.setState({modelVisible : false});
    }

    render() {
      let {data, pagination, packagesData,contractor_doc, dir, display, modelVisible,spin_msg, spin} = this.state;
        return (
            <Spin tip={spin_msg} spinning = {spin}>
              {this.CustomRow}
              <Row>
                <Modal centered
                  title={this.pkg_lang.TITLE}
                  visible={modelVisible} 
                  closable = {true}
                  destroyOnClose={true}
                  onCancel = {this.closePkgsModal}
                  width = {'80%'}
                  footer = {false}
                >
                  <Row id="packagesTable" dir = {dir} display={display} style={{ overflow: 'scroll' }}>
                    {/* <Col span={23} style={{margin : '0 25px 14px 25px'}}> */}
                    <Col span={24} >
                        <Table 
                          rowKey="id" 
                          size={'small'}
                          style={{ marginTop: '10px' }} 
                          columns={this.pkg_columns} 
                          dataSource={packagesData} 
                        />
                    </Col>
                  </Row>
                  <br/>
                  <Row gutter={24} dir = {dir} style={{display}}>
                    <Col span={8}>
                    {
                      contractor_doc !== '00000000-0000-00' ?
                      <a><p style={{fontSize:'15px'}} onClick={this.downloadDoc}>{this.lang.TABLEHEADERS.CONTRACTORDOC}
                        <Icon type="download" title = {this.lang.TABLEHEADERS.CONTRACTORDOC} style={{marginLeft:'10px'}} /> </p></a>
                      :<p style={{fontSize:'15px'}} >{this.lang.TABLEHEADERS.NOTDOCATTACHED}</p>
                    }
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}>
                      <Link to='/dashboard/cont-profile' target="_blank">{this.lang.TABLEHEADERS.PROFILE}</Link>
                    </Col>
                  </Row>
                </Modal>
              </Row>
              <Row id="clientsTable" gutter={24}>{/*Received Requests Table */}
                  <Col span={24}>
                  <Table
                      columns={this.columns}
                      rowKey="id"
                      // style={{marginTop : '10px'}}
                      dataSource={ data }
                      onChange={this.handleChangePagination}
                      pagination={pagination}
                  /> 
                  </Col>
              </Row>

            </Spin>
        )
    }// End of render
  }//End of Component

  export default Approve_Contractor_Request;