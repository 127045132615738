import React, { Component } from 'react';
import { Radio, Row, Col,InputNumber , Form, Icon, Button, Card, Select, message } from 'antd'
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import TextArea from 'antd/lib/input/TextArea';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class NotificationAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notification_type:[],
      message_type:[],
      schedule_type:"true",
      notification_schedule_type:[],
      // isEMAILDisabled: true,
      // isSMSDisabled: true
      dir : 'ltr', 
      display: 'block',
      textAlign : 'left'
    }
    this.lang = props.language._NOTIFICATIONS.CREATE;
    this.msg = props.language._NOTIFICATIONS.MESSAGE;
  }
  componentDidMount(){
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/message_type`,{headers})
    .then(res => {
      this.setState({message_type:res.data.data})
    })
    axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/notification_type`,{headers})
    .then(res => {
      this.setState({notification_type:res.data.data})
    })
    axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/notification_schedule_type`,{headers})
    .then(res => {
      this.setState({notification_schedule_type:res.data.data})
    })
    this.setComponentDirection();
  }

  handleNotficationType = (event) => {
    this.handleUpperCase(event);

    if (event.target.value === 'SMS') {
      this.setState({ isSMSDisabled: false, isEMAILDisabled: true })
    } else if (event.target.value === 'EMAIL') {
      this.setState({ isEMAILDisabled: false, isSMSDisabled: true })
    }
  }

  handleUpperCase = (e) => {
    e.target.value = e.target.value.toUpperCase();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const headers = getHeadersForHttpReq();
        values.user_id = localStorage.getItem('user_id');
        values.client_id = localStorage.getItem('client_id');
        values.lang = (localStorage.getItem('lang') === 'EN') ? 'EN' : 'AR'
        // console.log(values)
        axios.post(`${complete_URL}/notifications/add`, values, {headers})
          .then(res => {
            if (res.data.success) {
              message.success(this.msg.ADDSUCCESS/*res.data.message*/);
              this.props.history.push('notifications')
            } 
            else {
              message.error(this.msg.ADDFAILURE,3/*res.data.message*/)
            }
          })
          .catch(err => {
            message.error(this.msg.ADDFAILURE,3);
            console.log('Error occured while creating new Notification->',err)
          });
      }
    });
  }

  setComponentDirection=()=>{
    if (localStorage.getItem('lang') === 'EN') {
      this.setState({dir : 'ltr', display: 'block',textAlign : 'left'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex',textAlign : 'right'})
    }
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/notifications">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={14}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/notifications">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/notifications">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Link to="/dashboard/notifications">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}

              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NOTIFICATIONTYPE}                             >
                    {getFieldDecorator('notification_type', {
                      rules: [{ required: true, message: this.msg.INSERTNOTFTYPEERROR }],
                    })(
                      <Select  placeholder={this.lang.LABELS.NOTIFICATIONTYPE} >
                        {
                          this.state.notification_type.map(item => {
                          return  <Select.Option style={{textAlign: this.state.textAlign}} key={item.id} id={item.id}>
                              {localStorage.getItem('lang') == 'EN' ? item.name : item.name_ar}    
                            </Select.Option>
                          })
                        }
                      </Select>
                      //<Input onChange={this.handleNotficationType} type="text" placeholder={this.lang.LABELS.NOTIFICATIONTYPE} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.MESSAGETYPE}                             >
                    {getFieldDecorator('message_type', {
                      rules: [{ required: true, message: this.msg.INSERTMSGTYPEERROR }],
                    })(
                      <Select  placeholder={this.lang.LABELS.MESSAGETYPE} >
                        {
                          this.state.message_type.map(item => {
                          return  <Select.Option style={{textAlign: this.state.textAlign}} key={item.id} id={item.id}>
                              {localStorage.getItem('lang') == 'EN' ? item.name : item.name_ar}    
                            </Select.Option>
                          })
                        }
                      </Select>
                     // <Input onChange={this.handleUpperCase} type="text" placeholder={this.lang.LABELS.MESSAGETYPE} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.HEADINGCONTENT}                             >
                    {getFieldDecorator('heading_content', {
                      rules: [{ required: true, message: this.msg.INSERTHEADINGERROR }],
                    })(
                      <TextArea type="text" placeholder={this.lang.LABELS.HEADINGCONTENT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.HEADINGCONTENTAR}                             >
                    {getFieldDecorator('heading_content_ar', {
                      rules: [{ required: true, message: this.msg.INSERTHEADINGARERROR }],
                    })(
                      <TextArea dir="rtl" type="text" placeholder={this.lang.LABELS.HEADINGCONTENTAR} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.BODYCONTENT}                             >
                    {getFieldDecorator('body_content', {
                      rules: [{ required: true, message: this.msg.INSERTBODYERROR }],
                    })(
                      <TextArea type="text" placeholder={this.lang.LABELS.BODYCONTENT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.BODYCONTENTAR}                             >
                    {getFieldDecorator('body_content_ar', {
                      rules: [{ required: true, message: this.msg.INSERTBODYARERROR }],
                    })(
                      <TextArea dir="rtl" type="text" placeholder={this.lang.LABELS.BODYCONTENTAR} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
         
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ISCUSTOMER}>
                    {getFieldDecorator('is_customer', {
                      rules: [{ required: true, message: this.msg.SELECTISCUSTOMERERROR }],
                    })(
                      <Radio.Group>
                        <Radio value={true} key={true}>{(localStorage.getItem('lang') === 'EN') ? 'Yes' : 'نعم فعلا'}</Radio>
                        <Radio value={false} key={false}>{(localStorage.getItem('lang') === 'EN') ? 'No' : 'لا'}</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.SCHEDULETPE}     >
                    {getFieldDecorator('notification_schedule_type', {
                      rules: [{ required: false, message: this.msg.SELECTSCHEDULETYPE }],
                    })(
                      <Select  onChange={(e) =>{this.setState({schedule_type:this.state.notification_schedule_type.find(item => item.id === e).code})}} placeholder={this.lang.LABELS.SCHEDULETPE} >
                        {
                          this.state.notification_schedule_type.map(item => {
                          return  <Select.Option style={{textAlign: this.state.textAlign}} key={item.id} id={item.id}>
                              {localStorage.getItem('lang') == 'EN' ? item.name : item.name_ar}    
                            </Select.Option>
                          })
                        }
                      </Select>
                     // <Input onChange={this.handleUpperCase} type="text" placeholder={this.lang.LABELS.MESSAGETYPE} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.DAY}>
                    {getFieldDecorator('day', {
                      rules: [{ required: this.state.schedule_type != "true" ? true : false, message: this.msg.INSERTDAYERROR }],
                    })(
                      <InputNumber disabled={this.state.schedule_type == "true" ? true : false}  min={1} max={this.state.schedule_type == "WEEK" ? 7 : this.state.schedule_type == "MONTH" ? 30 : 0} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}></Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default NotificationAdd = Form.create({ name: 'notification_form' })(NotificationAdd);