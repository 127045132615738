import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Card, Input, message, Select, InputNumber, Switch, Avatar, Collapse } from 'antd'
import axios from 'axios';
import { Link } from 'react-router-dom'
import API_URL from '../../constants';
//import here
import ContractorPkgSpecialRate from '../ContractorPackages/ContractorPkgSpecialRate';
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const Panel = Collapse.Panel;
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class ContPackageUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
      getPackagesByReservation: {},
      image: '',
      package:{},
      packagesData: [],
      commissionOptions: [{'id':0,'name':'Percentage','name_ar':'بنسبة مئوية'},{'id':1,'name':'Amount','name_ar':'حسب الكمية'}],
      commissionOption : 0,
      dir : 'ltr', 
      display: 'block'
    }
    this.lang = props.language._CONTRACTOR.UPDATE.PACKAGE;
    this.msg = props.language._CONTRACTOR.UPDATE.PACKAGE.MESSAGE;

  }// End of Constructor
  fetchPackage = async () => {
    const id = JSON.parse(localStorage.getItem('updatePackage')).id
    await axios.get(`${complete_URL}/contractorpackages/${id}`)
      .then(res => {
        let comm_opt = -1;
        parseFloat(res.data.data[0].commission_percentage) > 0 ? (comm_opt = 0) : (comm_opt = 1)
        this.setState({ package: res.data.data[0],commissionOption : comm_opt }/*,()=>{
          console.log('this.state.package',res.data.data[0])
        }*/)
      })
      .catch(err => { console.log(err) })

    const image_id = JSON.parse(localStorage.getItem('updatePackage')).image_id
    await axios.get(`${complete_URL}/images/${image_id}`, { responseType: 'blob' })
      .then(res => {
        var reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          this.setState({ image: reader.result }/*, () => console.log(this.state.image)*/)
        }
      })
      .catch(err => { console.log(err) })
  }

  componentDidMount(){
    this.fetchPackage();
    const userid = localStorage.getItem('user_id');
    let reservationByOwner = this.getIsOwnerBooleanCheck(userid);
    // console.log('In didmount contractor packages->',reservationByOwner);
    if (localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block', reservationByOwner : reservationByOwner.reservationByOwner})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex', reservationByOwner : reservationByOwner.reservationByOwner})
    }
  }
  
  getIsOwnerBooleanCheck = (userid)=>{
    let reservationByOwner = {};
    let  headers = {
      'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
      'Content-Type': 'application/json' 
      }
    axios.get(`${complete_URL}/users/${userid}`,{headers})
    .then(res => { // console.log('from API call ..reservationByOwner->',res.data.is_owner);
      Object.assign(reservationByOwner , {reservationByOwner : res.data.is_owner});
    })
    .catch(err => { console.log(err) }) 
    return reservationByOwner;
  }

  componentWillMount(){
    if (localStorage.getItem('lang') === 'EN') {
        this.PackageUpdateHeader = <Row className="top-header" gutter={24}>
          <Col span={5}>
              <Link to="/dashboard/contpackages">
                <Button type="danger"><Icon type="arrow-left" /></Button>
              </Link>
          </Col>
          <Col span={12}> <h1 style={{textAlign: 'center'}}>{this.lang.UPDATE.LABELS.TITLE}</h1>
          </Col>
          <Col className="btn-box" span={6} style={{display: 'flex'}}>
            <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
              <Link to="/dashboard/contpackages">
                <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
              </Link>
          </Col>
        </Row>
      }
      else {
        this.PackageUpdateHeader = <Row className="top-header" gutter={24}>
          <Col span={4}>
            <Link to="/dashboard/contpackages">
              <Button type="danger"><Icon type="arrow-left" /></Button>
            </Link>
          </Col>
          <Col span={15}> <h1 style={{textAlign: 'center'}}>{this.lang.UPDATE.LABELS.TITLE}</h1>
          </Col>
          <Col className="btn-box" span={5} style={{display: 'flex'}}>
            <Link to="/dashboard/contpackages">
              <Button  style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
            </Link>
            <Button  style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          </Col>
        </Row>
      }
  }// End of method
  
  handleImageUpload = async (e) => {
    e.preventDefault();
    // console.log(e)
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      var file = e.currentTarget.files[0];
      let fileName = file.name;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (file.size <= 1024 * 1024 * 2 && /png|jpe?g/i.test(extension)) {
        var a;
        let imgdata = new FormData();
        imgdata.append('file', file)
        imgdata.append('client_id', localStorage.getItem('client_id'))
        // console.log(JSON.parse(localStorage.getItem('updatePackage')).id)
        imgdata.append('package_id', JSON.parse(localStorage.getItem('updatePackage')).id)
        // imgdata.append('client_name', this.state.client.name)
        let headers = {
          'Content-Type': 'multipart/form-data',
          'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token")
        }
        
        await axios.post(`${complete_URL}/images/package/add`, imgdata, { responseType: 'blob' }, {headers})
        .then(res => {
          var reader = new FileReader();
          reader.readAsDataURL(res.data);
          reader.onloadend = () => {
            a = reader.result
            console.log(a)
              this.setState({
                image: a,
              })
            }
          })
          .catch(err => {
            console.log('Error occured while uploading the contrator package image->',err)
            message.error(this.msg.IMAGEADDFAILURE,3);
          })
        // setTimeout(() => {
        //   this.setState({
        //     image: a,
        //   })
        //   console.log(a)
        // }, 800)
      }
      else{ message.error(this.msg.IMAGETYPEERROR,3); }
    }
  }

  handleSubmitPackageUpdate = (e) => {

    e.preventDefault();
    var user_id = localStorage.getItem('user_id')
    var id = JSON.parse(localStorage.getItem('updatePackage')).id
    this.props.form.validateFields((err, values) => {
      if (!err) {
        Object.assign(values, {
          user_id : localStorage.getItem('user_id'),
          commission_amount : this.state.commissionOption == 0 ? 0.00 : values.commission_amount,
          commission_percentage : this.state.commissionOption == 1 ? 0.00 : values.commission_percentage,
          is_active : values.is_active ? 1 : 0
        })
        // values.user_id = user_id
        // values.image = this.state.image
        // this.state.commissionOption == 0 ? (values.commission_amount = 0.00): (values.commission_percentage = 0.00)
        // console.log('Values in contractor packages->',values);
        let self = this
        axios.put(`${complete_URL}/contractorpackages/${id}`, values)
          .then(res => {
            if (res.data.success) {
              message.success(this.msg.UPDATESUCCESS);
              this.props.history.push('/dashboard/contpackages')
            }
            else{ message.error(this.msg.UPDATEFAILURE,3); }
            
          })
          .catch(err => {
            message.error(this.msg.UPDATEFAILURE,3)
            console.log(err)
          })
      }
      else { console.log('ContractorPackage Error->',err); }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    // console.log('this.lang ->',this.lang);
    return (
        <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
        <Form onSubmit={this.handleSubmitPackageUpdate.bind(this)} style={{ marginTop: '20px' }}>
            {this.PackageUpdateHeader}
            <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                <Col span={12}>
                {
                    <Form.Item label={this.lang.UPDATE.LABELS.PACKAGETYPE}   >
                    {getFieldDecorator('type', {
                        initialValue: this.state.package.type && this.state.package.type,
                        rules: [{ required: true, message:  this.msg.INSERTPKGTYPEERROR }]
                    })(
                        <Input placeholder={this.lang.CREATE.LABELS.PACKAGETYPE} />
                    )}
                    </Form.Item>
                }
                </Col>
                <Col span={12}>
                {
                    <Form.Item label={this.lang.UPDATE.LABELS.PACKAGEPRICE}   >
                    {getFieldDecorator('price', {
                        initialValue: this.state.package.price,
                        rules: [{ required: true, message: this.msg.INSERTPKGPRICEERROR }]
                    })(
                        <Input placeholder={this.lang.CREATE.LABELS.PACKAGEPRICE} />
                    )}
                    </Form.Item>
                }
                </Col>
            </Row>
            <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                <Col span={12}>
                {
                    <Form.Item label={this.lang.UPDATE.LABELS.PACKAGEDESCRIPTION}  >
                    {getFieldDecorator('description', {
                        initialValue: this.state.package.description,
                        rules: [{ required: true, message:  this.msg.INSERTPKGDESCERROR }]
                    })(
                        <Input placeholder={this.lang.CREATE.LABELS.PACKAGEDESCRIPTION} />
                    )}
                    </Form.Item>
                }
                </Col>
                <Col span={12}>
                {
                    <Form.Item label={this.lang.UPDATE.LABELS.PACKAGEDESCRIPTIONAR}>
                    {getFieldDecorator('description_ar', {
                        initialValue: this.state.package.description_ar,
                        rules: [{ required: true, message: this.msg.INSERTPKGDESCARERROR }]
                    })(
                        <Input dir= {'rtl'} placeholder={this.lang.CREATE.LABELS.PACKAGEDESCRIPTIONAR} />
                    )}
                    </Form.Item>
                }
                </Col>
            </Row>
            <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
            <Col span={8}>
                <Form.Item label= {this.lang.CREATE.LABELS.COMMISSIONTYPE} >
                        {getFieldDecorator('commissionOption', {
                        initialValue: this.state.commissionOptions[this.state.commissionOption].id,
                        rules: [{ required: true, message:  this.msg.SELECTCOMMTYPEERROR }],
                        })(
                        <Select onChange={(e)=>this.handleCommissionTypeSelection(e)} placeholder= {this.lang.CREATE.LABELS.COMMISSIONTYPE}>
                            {
                            (localStorage.getItem('lang') === 'EN') ?
                            this.state.commissionOptions.map(commission => (
                                <Select.Option key={commission.id} value={commission.id}>{commission.name}</Select.Option>
                            )):
                            this.state.commissionOptions.map(commission => (
                                <Select.Option style={{textAlign:'right'}} key={commission.id} value={commission.id}>{commission.name_ar}</Select.Option>
                            ))
                            }
                        </Select>)}
                </Form.Item>
            </Col>
            <Col span={8}>
                {
                <Form.Item  label= {this.lang.CREATE.LABELS.COMMISSIONPERCENT} >
                    {getFieldDecorator('commission_percentage', {
                    initialValue: this.state.package.commission_percentage,
                    rules: [{ required: this.state.commissionOption == 0 , message:  this.msg.INSERTCOMMPERCENTERROR }]
                    })(
                    <InputNumber disabled={this.state.commissionOption == 1} placeholder={this.lang.CREATE.LABELS.COMMISSIONPERCENT}  max={100.00} min = {0.00} style={{width:'100%'}}  />
                    )}
                </Form.Item>
                }
            </Col>
                
            <Col span={8}>
            {
                <Form.Item  label= {this.lang.CREATE.LABELS.COMMISSIONAMOUNT} >
                {getFieldDecorator('commission_amount', {
                    initialValue: this.state.package.commission_amount,
                    rules: [{ required: this.state.commissionOption == 1, message: this.msg.INSERTCOMMAMOUNTERROR  }]
                })(
                    <InputNumber disabled={this.state.commissionOption == 0} placeholder= {this.lang.CREATE.LABELS.COMMISSIONAMOUNT} style={{width:'100%'}} />
                )}
                </Form.Item>
            }
            </Col>
            
            </Row>
            
            <Row gutter={24} dir={this.state.dir} style={{display : this.state.display}}>
                <Col span={12}>
                {
                    <Form.Item label={this.lang.UPDATE.LABELS.ISACTIVE}>
                        {
                        getFieldDecorator('is_active', 
                            { valuePropName: 'checked', initialValue:this.state.package.is_active == 1 ? true : false })
                            (<Switch disabled = {this.state.reservationByOwner == 0} />)
                        }
                    </Form.Item>
                }
                </Col>
                <Col span={12}>
                <Form.Item>
                    <p>{this.lang.CREATE.LABELS.FILEUPLOAD}</p>
                    {getFieldDecorator('file', {
                    rules: [{ required: false, message: this.msg.IMAGETYPEERROR }],
                    })(
                    <Row>
                        <Avatar size={80} src={ this.state.image || '/logo.png'} alt="log here" />
                        <Input type="file" id="file" className="file" onChange={this.handleImageUpload} />
                    </Row>
                    )}
                </Form.Item>
                </Col>
            </Row>
        </Form>
        <Collapse accordion bordered={false} defaultActiveKey={['']}  expandIconPosition={localStorage.getItem('lang')==='EN' ? 'left':'right'}  expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
          <Panel header={this.props.language._PACKAGESPECIALRATE.TITLE} key="1" id='package'>
            <ContractorPkgSpecialRate  {...this.props} />
          </Panel>
        </Collapse>
        </Card>
        </Col>
    </Row>
    )

}

}// End of Component

export default ContPackageUpdate = Form.create({ name: 'normal_login' })(ContPackageUpdate);
