import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { Layout, Dropdown, Icon, Button, Menu, Avatar } from 'antd'
import LanguageChangingDropdown from '../../LanguageChangingDropdown/LanguageChangingDropdown';
import API_URL from '../../constants/index'
const pt = localStorage.getItem('pt');

const { Header } = Layout;

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false
    }
    this.lang = props.language._NAVBAR.BUTTON

  }

  LogOut = () => {
    localStorage.clear();
    this.props.history.push('/')
    window.location.reload()
  }

  toggle = () => {
    this.setState({ collapsed: !this.state.collapsed, });
  }

  handleChange = (value) => {
    if (value === "arabic") {
      localStorage.setItem('lang', 'AR');
      this.props.history.go('/');
    } 
    else {
      localStorage.setItem('lang', 'EN');
      this.props.history.go('/');
    }
  }

  componentDidMount() {
    // console.log(this.props)
  }

  componentWillMount() {
    const menu = (
      <Menu style={{ top: '18px' }}>
        {
          localStorage.getItem('is_contr') == 1 &&
          <Menu.Item >
            <Link to="/dashboard/contProfile">
            <Icon type="setting" /> {this.lang.CONTRACTOR_PROFILE}
            </Link>
          </Menu.Item>
        }
        <Menu.Item >
          <Link to="/dashboard/userssettings">
          <Icon type="setting" /> {this.lang.SETTINGS}
        </Link>
        </Menu.Item>
        <Menu.Item onClick={this.LogOut}>
        <Icon type="logout" /> {this.lang.LOGOUT}
        </Menu.Item>
      </Menu>
    );
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Header style={{ padding: 0, height: '7%' }}>
        <Icon
          className="trigger"
          style={{
            color: '#fff', fontSize: '24px', marginLeft: '10px',
            float: this.props.language.LOCALE === 'ar' ? "right" : "left"
          }}
          type={this.props.collapsed ? 'menu-fold' : 'menu-unfold'}
          onClick={this.props.clikckedIcon}

        />
        <LanguageChangingDropdown color='white' {...this.props} />
        <Dropdown overlay={menu} >
          <Button style={{ background: 'transparent', border: 'transparent', color: '#fff', float: this.props.language.LOCALE === 'ar' ? "left" : "right" }}>
            <Avatar  icon="user" src={`${API_URL}${pt}/images/${localStorage.getItem('user_image_id')}`} size="large" style={{top:'8px',marginLeft:"5px",marginRight:'5px'}}></Avatar>
            {localStorage.getItem('user_name')}
          </Button>

        </Dropdown>
  
      </Header>
    }
    else {
      this.CustomRow = <Header style={{ padding: 0, height: '7%' }}>
        <Dropdown overlay={menu} >
          <Button style={{ background: 'transparent', border: 'transparent', color: '#fff', float: this.props.language.LOCALE === 'ar' ? "left" : "right" }}>
            <Avatar  icon="user" src={`${API_URL}${pt}/images/${localStorage.getItem('user_image_id')}`} size="large"  style={{top:'8px',marginLeft:"5px",marginRight:'5px'}}></Avatar>
            {localStorage.getItem('user_name')}
          </Button>
        </Dropdown>
        <LanguageChangingDropdown color='white' {...this.props} />
        <Icon
          className="trigger"
          style={{
            color: '#fff', fontSize: '24px', marginLeft: '10px',
            float: this.props.language.LOCALE === 'ar' ? "right" : "left"
          }}
          type={this.props.collapsed ? 'menu-fold' : 'menu-unfold'}
          onClick={this.props.clikckedIcon}
        />
      </Header>
    }
  }

  render() {
    return (
      this.CustomRow
    );
  }
}

export default withRouter(Navbar);