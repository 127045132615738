import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, Card, message, Select, InputNumber,Checkbox } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class ContractorAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      country: [],
      cities: [],
      service_type: [],
      dir : 'ltr', 
      display: 'block'
    }
    this.lang = props.language._CONTRACTOR.CREATE;
    this.msg  = props.language._CONTRACTOR.MESSAGE;
  }
  
  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    const client_id = localStorage.getItem('client_id');
    this.getCountries();  
    // this.getServiceTypes(client_id);
    this.getIndependentServiceTypes();
    this.getUsernamesAndEmails();
    this.setComponentDirection();
      
  }

  getCountries = ()=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/parameters/get_countries`,{headers})
      .then(res => {
        this.setState({ country: res.data.data[0] })
        /*console.log(res.data.data[0]);*/
      })
      .catch(err => { console.log('Error occured while fetching stored Countries ->',err) })
  }

  /*No need to call as now Contractor Screens are going to be Only for Super admin*/
  getServiceTypes = (client_id)=>{
    const headers =  getHeadersForHttpReq();
    axios.get(`${complete_URL}/servicetypes/by_client/${client_id}`, {headers})
      .then(res => { // console.log('service_type->',res)
        this.setState({ service_type: res.data.data })
      })
      .catch(err => {  console.log('Issue while fetching new service types ->',err) })
  }
  
  
  getIndependentServiceTypes = ()=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/servicetypes/independent`,{headers})
      .then(res => { // console.log('service_types->',res)
        this.setState({ service_type: res.data.data })
      })
      .catch(err => {  console.log('Issue while fetching new independent service_types ->',err) });
  }

  seclectCountry = (v) => {
    const headers =  getHeadersForHttpReq();
    axios.get(`${complete_URL}/parameters/child_parameter/${v}`,{headers})
      .then(res => {
        /*console.log(res.data.data[0])*/
        this.setState({ cities: res.data.data[0] })
      })
      .catch(err => { console.log('Error occured while fetching cities on selecting country',err) })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const headers = getHeadersForHttpReq();
        const user_id = localStorage.getItem('user_id');
        values.user_id = user_id;
        values.client_id = localStorage.getItem('client_id');
        // console.log(values)
        axios.post(`${complete_URL}/contractors/add`, values,{headers})
          .then(res => {
            if (res.data.success) {
              message.success(this.msg.ADDSUCCESS);
              this.props.history.push('/dashboard/contractors')
            } 
            else {
              message.error(this.msg.ADDFAILURE,3);
            }
          })
          .catch(err => {
            message.error(this.msg.ADDFAILURE,3);
            console.log('Error occured while creating new Contractor->',err)
          });
      }
    });
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/contractors">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={12}> <h1 style={{textAlign: 'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={6} style={{display: 'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/contractors">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/contractors">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}> <h1 style={{textAlign: 'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to="/dashboard/contractors">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  getUsernamesAndEmails =()=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/users/usernames`,{headers})
      .then(users => { //console.log('username ->',users.data.data);
        this.setState({user_names : users.data.data})
      })
      .catch(users_err => {
        console.log('Error occured while fetching Usernames->',users_err)
      });
  }
  
  createContractorUser = (e)=>{
    this.setState({createUser : e.target.checked})
  }

  handleUsernameChange = (rule, value, callback)=>{
    if(value ) {
      if(this.state.user_names.length > 0){
        let exists_user = this.state.user_names.filter(user => user.username.replace(/\s/g, '') === value.replace(/\s/g, ''));
        try {
          if( Object.keys(exists_user).length > 0 ){          
            rule.message = 'Username already Exists!!';
            throw new Error('Username already exists!');
            callback()
          }
          callback();
         } catch (err) {
           callback(err);
        }
      }
      callback();
    }//End of if Value input
    else{
      try {
        if(!value ) {
            rule.message = 'Kindly input the Username!!';
            throw new Error('Kindly input the Username!');
            callback();
          }
          callback();  
        } catch (err) {
          callback(err);
        }
    }
  }

  handleEmailChange = (rule, value, callback)=>{
    if(value ) {
      if(this.state.user_names.length > 0){
        let exists_email = this.state.user_names.filter(user => user.email.replace(/\s/g, '') === value.replace(/\s/g, ''));
        try {
          if( Object.keys(exists_email).length > 0 ){          
            rule.message = 'Email already Exists!!';
            throw new Error('Email already exists!');
            callback()
          }
          callback();
         } catch (err) {
           callback(err);
        }
      }
      callback();
    }//End of if Value input
    else{
      if (this.state.createUser) {
        try {
          if(!value ) {
              rule.message = this.msg.INSERTEMAILERROR;
              throw new Error(this.msg.INSERTEMAILERROR);
              callback();
            }
            callback();  
          } catch (err) {
            callback(err);
          }
      }
      else
        callback();  
    }//End of Else no Value input
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    let {dir, display, country, cities} = this.state;

    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NAME}   >
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: this.msg.INSERTNAMEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      rules: [{ required: false, message: this.msg.INSERTNAMEARERROR }],
                    })(
                      <Input dir='rtl' placeholder={this.lang.LABELS.NAMEAR} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.EMAIL}>
                    {getFieldDecorator('email', {
                      rules: [{ required: false, message: this.msg.INSERTEMAILERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.EMAIL} type="email" style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.PHONENO}>
                    {getFieldDecorator('ph_num', {
                      rules: [{ required: true, message: this.msg.INSERTPHONENOERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.PHONENO} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ALTERNATEPHONENO}>
                    {getFieldDecorator('ph_alt_num', {
                      rules: [{ required: false }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.ALTERNATEPHONENO} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.IDCARDNO}>
                    {getFieldDecorator('id_card', {
                      rules: [{ required: true, message: this.msg.INSERTIDNOERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.IDCARDNO} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.SERVICETYPE}                             >
                    {getFieldDecorator('new_service_type', {
                      rules: [{ required: true, message: this.msg.SELECTSERVICETYPEERROR }],
                    })(
                      <Select  placeholder={this.lang.LABELS.SERVICETYPE}>
                        {
                          this.state.dir ==='ltr' ?
                          this.state.service_type.map(st => (
                            <Select.Option key={st.id} value={st.id}>{st.name}</Select.Option>
                          )) 
                          :
                          this.state.service_type.map(st => (
                            <Select.Option style={{textAlign:'right'}} key={st.id} value={st.id}>{st.name_ar}</Select.Option>
                          ))
                        }
                      </Select>)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.COMMERCIALREGNO}>
                    {getFieldDecorator('commercial_reg_no', {
                      rules: [{ required: false, message: this.msg.INSERTCOMMREGNOERROR }],
                    })(
                      <InputNumber placeholder={this.lang.LABELS.COMMERCIALREGNO} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item /*label={this.lang.LABELS.AREA}*/   >
                    {getFieldDecorator('createUser', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Checkbox checked={this.state.createUser} onChange={this.createContractorUser}>{this.lang.LABELS.CREATEUSERACCOUNT}</Checkbox>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}> </Col>
              </Row>
              {
                this.state.createUser &&
                <Row gutter={24} dir= {dir} style={{ display }}>
                  <Col span={12}>
                  <Form.Item label= {this.lang.LABELS.USERNAME}>
                    {getFieldDecorator('user_name', {
                      rules: [{ required: true, validator : (rule,value, callback)=> this.handleUsernameChange(rule,value, callback) , message: this.msg.ISERTUSERNAMEERROR }],
                    })(
                      <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.lang.LABELS.USERNAME} />
                    )}
                  </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={this.lang.LABELS.PASSWORD}>
                      {getFieldDecorator('password', {
                        rules: [{ required: true, message: this.msg.ISERTPASSWORDERROR }],
                      })(
                        <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} autoComplete="new-password" placeholder={this.lang.LABELS.PASSWORD} style={{ width: '100%' }} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              }
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <h1 style={{ fontSize: '24px' }}>{this.lang.LABELS.ADDRESS}</h1>
                </Col>
                <Col span={12}>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.AREA}  >
                    {getFieldDecorator('area', {
                      rules: [{ required: false, message: this.msg.INSERTAREAERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.AREA} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.STREET} >
                    {getFieldDecorator('street', {
                      rules: [{ required: false, message: this.msg.INSERTSTREETERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.STREET} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.BUILDINGVILLAHOUSE}                             >
                    {getFieldDecorator('building', {
                      rules: [{ required: false, message: this.msg.INSERTBUILDINGERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.BUILDINGVILLAHOUSE} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.FLAT}  >
                    {getFieldDecorator('flat_no', {
                      rules: [{ required: false, message: this.msg.INSERTFLATNOERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.FLAT} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NEARESTPLACE}  >
                    {getFieldDecorator('nearest_place', {
                      rules: [{ required: false, message: this.msg.INSERTNEARESTPLACEERROR  }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NEARESTPLACE} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.COUNTRY}  >
                    {getFieldDecorator('country', {
                      rules: [{ required: false, message: this.msg.SELECTCOUNTRYERROR }],
                    })(
                      <Select onChange={this.seclectCountry} placeholder={ this.lang.LABELS.COUNTRY }>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            country.length > 0 && country.map(parent => (
                              <Select.Option key={parent.id} value={parent.id}>{parent.name}</Select.Option>
                            ))
                            :
                            country.length > 0 && country.map(parent => (
                              <Select.Option style={{textAlign:'right'}} key={parent.id} value={parent.id}>{parent.name_ar}</Select.Option>
                            ))
                        }
                      </Select>)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.CITY}  >
                    {getFieldDecorator('city', {
                      rules: [{ required: false, message: this.msg.SELECTCITYERROR }],
                    })(
                      <Select placeholder={this.lang.LABELS.CITY}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            cities.length > 0 && cities.map(city => (
                              <Select.Option key={city.id} value={city.id}>{city.name}</Select.Option>
                            ))
                            :
                            cities.length > 0 && cities.map(city => (
                              <Select.Option style={{textAlign:'right'}} key={city.id} value={city.id}>{city.name_ar}</Select.Option>
                            ))
                        }
                      </Select>)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default ContractorAdd = Form.create({ name: 'contractor_form' })(ContractorAdd);