import React, { Component } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Form,
  Icon,
  Button,
  Input,
  Card,
  Select,
  message,
} from "antd";
import { Link } from "react-router-dom";
import API_URL from "../../constants";
import getHeadersForHttpReq from "../../constants/token";
const pt = localStorage.getItem("pt");
const complete_URL = API_URL + pt;
if (localStorage.getItem("lang") === "AR") {
  require("../css/global-css.css");
}
// import Map from '../Map'
class BanquetAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parent: [],
      cities: [],
      selectedParentId: null,
      selectedCity: null,
      img: null,
      dir: "ltr",
      display: "block",
    };
    this.lang = props.language._BANQUET.CREATE;
    this.msg = props.language._BANQUET.MESSAGE;
  }

  setComponentDirection = () => {
    if (localStorage.getItem("lang") === "EN") {
      this.setState({ dir: "ltr", display: "block" });
    } else {
      this.setState({ dir: "rtl", display: "flex" });
    }
  };

  componentDidMount() {
    this.getCountries();
    this.setComponentDirection();
  }

  getCountries = () => {
    const headers = getHeadersForHttpReq();

    axios
      .get(`${complete_URL}/parameters/get_countries`, { headers })
      .then((res) => {
        this.setState({ parent: res.data.data[0] });
      })
      .catch((err) => {
        console.log("Error occured while fetching the Countires ->", err);
      });
  }; //End of Method

  selectParentCountry = (v) => {
    const headers = getHeadersForHttpReq();

    this.setState({ selectedParentId: v });
    axios
      .get(`${complete_URL}/parameters/child_parameter/${v}`, { headers })
      .then((res) => {
        /*console.log(res.data.data[0])*/
        this.setState({ cities: res.data.data[0] });
      })
      .catch((err) => {
        console.log(
          "Error occured while fetching the Child categories by Parent ID ->",
          err
        );
      });
  };

  selectCity = (v) => {
    this.setState({ selectedCity: v });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let user_id = localStorage.getItem("user_id");
    this.props.form.validateFields((err, values) => {
      values.user_id = user_id;
      values.city = this.state.selectedCity;
      values.country = this.state.selectedParentId;
      values.client_id = localStorage.getItem("client_id");
      /*console.log('add Banquet->',values)*/
      const headers = getHeadersForHttpReq();
      axios
        .post(`${complete_URL}/banquets/add`, values, { headers })
        .then((res) => {
          /*console.log(res)*/
          message.success(this.msg.ADDBANQUETSUCCESS /*res.data.message*/);
          this.props.history.push("banquets");
        })
        .catch((err) => {
          console.log("Error occured while adding the Banquet->", err);
          message.success(this.msg.ADDBANQUETFAILURE, 2);
        });
    });
  };

  componentWillMount() {
    if (localStorage.getItem("lang") === "EN") {
      this.CustomRow = (
        <Row className="top-header" gutter={24}>
          <Col span={5}>
            <Link to="/dashboard/banquets">
              <Button type="danger">
                <Icon type="arrow-left" />
              </Button>
            </Link>
          </Col>
          <Col span={14}>
            <h1 style={{ textAlign: "center" }}>{this.lang.LABELS.TITLE}</h1>
          </Col>
          <Col className="btn-box" span={5} style={{ display: "flex" }}>
            <Button
              style={{ margin: "16px auto" }}
              className="save"
              type=""
              htmlType="submit"
              title="save"
            >
              <Icon type="save" theme="filled" />
            </Button>
            <Link to="/dashboard/banquets">
              <Button
                style={{ margin: "16px auto" }}
                className="delete"
                title="delete"
              >
                <Icon type="delete" theme="filled" />
              </Button>
            </Link>
          </Col>
        </Row>
      );
    } else {
      this.CustomRow = (
        <Row className="top-header" gutter={24}>
          <Col span={4}>
            <Link to="/dashboard/banquets">
              <Button type="danger">
                <Icon type="arrow-left" />
              </Button>
            </Link>
          </Col>
          <Col span={15}>
            <h1 style={{ textAlign: "center" }}>{this.lang.LABELS.TITLE}</h1>
          </Col>
          <Col className="btn-box" span={5} style={{ display: "flex" }}>
            <Link to="/dashboard/banquets">
              <Button
                style={{ margin: "16px auto" }}
                className="delete"
                title="delete"
              >
                <Icon type="delete" theme="filled" />
              </Button>
            </Link>
            <Button
              style={{ margin: "16px auto" }}
              className="save"
              type=""
              htmlType="submit"
              title="save"
            >
              <Icon type="save" theme="filled" />
            </Button>
          </Col>
        </Row>
      );
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let { dir, display, parent, cities } = this.state;
    return (
      <Row gutter={12} style={{ marginTop: "50px" }}>
        <Col span={4}></Col>
        <Col span={16}>
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              <Row gutter={24} dir={dir} style={{ display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator("name", {
                      rules: [
                        { required: true, message: this.msg.INSERTNAMEERROR },
                      ],
                    })(<Input placeholder={this.lang.LABELS.NAME} />)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}>
                    {getFieldDecorator("name_ar", {
                      rules: [
                        {
                          required: false,
                          message: this.msg.INSERTNAMEARERROR,
                        },
                      ],
                    })(
                      <Input
                        dir="rtl"
                        type="text"
                        placeholder={this.lang.LABELS.NAMEAR}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.DESCRIPTION}>
                    {getFieldDecorator("description", {
                      rules: [
                        {
                          required: true,
                          message: this.msg.INSERTDESCRIPTIONERROR,
                        },
                      ],
                    })(
                      <Input
                        type="text"
                        placeholder={this.lang.LABELS.DESCRIPTION}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir={dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ADDRESS}>
                    {getFieldDecorator("address", {
                      rules: [
                        {
                          required: true,
                          message: this.msg.INSERTADDRESSERROR,
                        },
                      ],
                    })(
                      <Input
                        type="text"
                        placeholder={this.lang.LABELS.ADDRESS}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ADDRESSAR}>
                    {getFieldDecorator("address_ar", {
                      rules: [
                        {
                          required: true,
                          message: this.msg.INSERTADDRESSARERROR,
                        },
                      ],
                    })(
                      <Input
                        dir="rtl"
                        type="text"
                        placeholder={this.lang.LABELS.ADDRESSAR}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir={dir} style={{ display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.COUNTRY}>
                    {getFieldDecorator("country", {
                      rules: [
                        {
                          required: false,
                          message: this.msg.SELECTCOUNTRYERROR,
                        },
                      ],
                    })(
                      <Select
                        placeholder={this.lang.LABELS.COUNTRY}
                        onChange={this.selectParentCountry}
                      >
                        {localStorage.getItem("lang") === "EN"
                          ? parent.length > 0 &&
                            parent.map((parent) => (
                              <Select.Option key={parent.id} value={parent.id}>
                                {parent.name}
                              </Select.Option>
                            ))
                          : parent.length > 0 &&
                            parent.map((parent) => (
                              <Select.Option
                                style={{ textAlign: "right" }}
                                key={parent.id}
                                value={parent.id}
                              >
                                {parent.name_ar}
                              </Select.Option>
                            ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.CITY}>
                    {getFieldDecorator("city", {
                      rules: [
                        { required: false, message: this.msg.SELECTCITYERROR },
                      ],
                    })(
                      <Select
                        placeholder={this.lang.LABELS.CITY}
                        onChange={this.selectCity}
                      >
                        {localStorage.getItem("lang") === "EN"
                          ? cities.length > 0 &&
                            cities.map((city) => (
                              <Select.Option key={city.id} value={city.id}>
                                {city.name}
                              </Select.Option>
                            ))
                          : cities.length > 0 &&
                            cities.map((city) => (
                              <Select.Option
                                style={{ textAlign: "right" }}
                                key={city.id}
                                value={city.id}
                              >
                                {city.name_ar}
                              </Select.Option>
                            ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.CRNO}>
                    {getFieldDecorator("c_r_no", {
                      rules: [
                        { required: false, message: this.msg.INSERTCRNOERROR },
                      ],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.CRNO} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir={dir} style={{ display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.GSM}>
                    {getFieldDecorator("gsm", {
                      rules: [
                        { required: false, message: this.msg.INSERTGSMERROR },
                      ],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.GSM} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.EMAIL}>
                    {getFieldDecorator("email", {
                      rules: [
                        { required: false, message: this.msg.INSERTEMAILERROR },
                      ],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.EMAIL} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.INSTAGRAMURL}>
                    {getFieldDecorator("insta_url", {
                      rules: [
                        {
                          required: false,
                          message: this.msg.INSERTINSTAGRAMURLERROR,
                        },
                      ],
                    })(
                      <Input
                        type="text"
                        placeholder={this.lang.LABELS.INSTAGRAMURL}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/* <React.fragement>
                            <Map
                                google={this.props.google}
                                center={{lat: 24.946218, lng: 67.005615}}
                                height='300px'
                                zoom={15}
                            />                      
                            </React.fragement> */}
              {/* <Form.Item>
                <Row style={{marginTop:'-20px'}}>
                        <Col span={16}>
                            <p>Upload files under 2MB, and only in png/jpg/jpeg formats</p>
                        </Col>
                    </Row>
                    {getFieldDecorator('file', {
                        rules: [{ required: false, message: this.firstNameEmptyError }],
                    })(
                        <Row>
                        <Col span={4}></Col>
                            <Col span={6}>
                            <Avatar size={80} src={this.state.img || '/logo.png'} alt="log here" />
                            </Col>
                            <Col span={12}>
                                <Input type="file" id="file" className="file" onChange={this.handleImageUpload} />
                            </Col>
                        </Row>

                    )}
                </Form.Item> */}
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    );
  }
}

export default BanquetAdd = Form.create({ name: "normal_login" })(BanquetAdd);
