import React, { Component, Fragment } from 'react'
import { Row, Col, Button,message } from 'antd'
import ClientAccountPaymentsTable from './ClientAccountPaymentsTable'
import AddClientAccountPayments from './AddClientAccountPayments'
import axios from 'axios'
import API_URL from '../../constants/index'
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
class ClientAccountPayments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: true,
            payment_category:[],
            payment_reason:[]
        }
    }
    componentDidMount(){
        let  headers = {
            'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
            'Content-Type': 'application/json' 
            }
        axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/PAYMENT_CATEGORY`,{headers})
        .then(res => {
          this.setState({payment_category:res.data.data})
        })
        this.getpaymentreason()
    }
    getpaymentreason = () =>{
        let  headers = {
            'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
            'Content-Type': 'application/json' 
            }
        axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/PAYMENT_REASON`,{headers})
        .then(res => {
          this.setState({payment_reason:res.data.data})
        })
    }
    addPayment = (data) => {
        axios.post(`${complete_URL}/clientpayments/add/`,data)
        .then(res => {
            if(res.data.success){
                message.success(res.data.message)
                this.props.fetchClientData()
                this.setState({toggle:!this.state.toggle})
            }else{
                message.error(res.data.message)
            }
        })
        .catch(err => {
        })
    }
    toggleFunc = () => {
        this.setState({toggle:!this.state.toggle})
    }
    render() {
        return (
            <Fragment>
                <Row gutter={12}>
                    <Col span={20}>
                    </Col>
                    <Col span={4}>
                        {
                            this.state.toggle  &&
                                <Button onClick={() => { this.toggleFunc() }}>{this.props.language.BUTTON.ADD}</Button>
                        }
                    </Col>
                </Row>
                {
                    this.state.toggle ?
                        <ClientAccountPaymentsTable language={this.props.language.SETUP} client={this.props.client}/>
                        :
                        <AddClientAccountPayments  language={this.props.language.CREATE} getPaymentReasons={() =>this.getpaymentreason()} addPayment={(data) => {this.addPayment(data)}} toggleFunc={this.toggleFunc} payment_reason={this.state.payment_reason} payment_category={this.state.payment_category}/>
                }
            </Fragment>
        )
    }
}

export default ClientAccountPayments