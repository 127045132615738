import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { Layout, Button, Menu, Icon, Row, Col, Form, Input, /*Select,*/ message, Avatar } from 'antd';
// const { Option } = Select;
import API_URL from '../../constants/index';
import getHeadersForHttpReq from '../../constants/token';
const { Content, Sider } = Layout;
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class UserSettings extends Component {
    constructor() {
        super()
        this.state = {
            tabNo: 1,
            user: {},
            oldpass:'',
            newpass:'',
            retype:''
        };
    }

    componentDidMount() {
            this.fetch();
    }
    
    handleImageUpload = async (e) => {
        e.preventDefault();
        if (e.currentTarget.files && e.currentTarget.files[0]) {
        let file = e.currentTarget.files[0];
        let fileName = file.name;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (file.size <= 1024 * 1024 * 2 && /png|jpe?g/i.test(extension)) {
            
            let imgdata = new FormData();
            imgdata.append('file', file)
            imgdata.append('client_id', localStorage.getItem('client_id'))
            imgdata.append('user_id', localStorage.getItem('user_id'))
            imgdata.append('user_id', localStorage.getItem('user_id'))
            const headers = {
                'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
                'Content-Type': 'multipart/form-data'
            }
            await   axios.post(`${complete_URL}/images/user_iamge/add`, imgdata, { responseType: 'blob' }, headers)
            .then(res => {
                var reader = new FileReader();
                reader.readAsDataURL(res.data);
                reader.onloadend = () => {
                    this.fetch();
                }
            })
            .catch(err => { console.log('Error occured while fetching User Image->',err);});
        }//End of size & extension check
        }
    }

    fetch = () => {
        const headers = getHeadersForHttpReq();

        axios.get(`${complete_URL}/users/${localStorage.getItem('user_id')}`,{headers})
        .then((user) => {// console.log(user.data)
            this.setState({ user: user.data });
        })
        .catch(err=>{ console.log("Error occured while fetching selected User's data ->",err)})
    }

    changePassword = (e) => {
        e.preventDefault()
        const headers = getHeadersForHttpReq();

        let data = {
            oldpass:this.state.oldpass,
            newpass:this.state.newpass,
            retype:this.state.retype,
            user_id:this.state.user.id
        }
        axios.post(`${complete_URL}/users/password_change`, data,{headers}) 
        .then( res => {
            if(res.data.success){
                message.success(res.data.message);
            }
            else{
                message.error(res.data.message);
            }
        })
        .catch(err => { console.log('Error occured while updating the Password ->',err) });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const headers = getHeadersForHttpReq();
                values.user_id = localStorage.getItem('user_id');
                
                 axios.put(`${complete_URL}/users/usersettings/${this.state.user.id}`, values, {headers})
                    .then(res => {
                        if(res.data.success){
                            message.success(res.data.message)
                        }
                    })
                    .catch(err => { console.log('Error occured while updating the User info->',err) })
            }
        });
    }
    
    render() {
        let lang = this.props.language._USERSETTINGS;
        // console.log(this.props.language._USERSETTINGS)
        const { getFieldDecorator } = this.props.form;
        // const { autoCompleteResult } = this.state;
        return (
            <Fragment>
                <Layout style={{ margin: '20px', minHeight: '80vh' }}>
                    <Layout style={{ background: '#fff' }}>
                        <Sider style={{ background: '#fff' }}>
                            <Menu theme="light" mode="inline" defaultSelectedKeys={['1']}>
                                <Menu.Item key="1" onClick={() => { this.setState({ tabNo: 1 }) }} >
                                    <Icon type="setting" />
                                    <span className="nav-text">{lang.SETTINGS.TAB_NAME}</span>
                                </Menu.Item>
                                <Menu.Item key="2" onClick={() => { this.setState({ tabNo: 2 }) }} >
                                    <Icon type="lock" />
                                    <span className="nav-text">{lang.PASSWORD_RESET.TAB_NAME}</span>
                                </Menu.Item>
                            </Menu>
                        </Sider>
                        <Content>
                            {
                                this.state.tabNo === 1 ?
                                    <Fragment >
                                        <Form onSubmit={this.handleSubmit} style={{padding:'24px'}} > 

                                            <h1 style={{ textAlign: 'center', fontFamily: 'serif', fontSize: '20px' }}> {lang.SETTINGS.HEADING}</h1>
                                            <Row gutter={24}>
                                                <Col span={12}>
                                                    <Row gutter={24} >
                                                        <Col span={24}>
                                                            <Form.Item label= {lang.SETTINGS.FORM_LABELS.NAME}>
                                                                {getFieldDecorator('name', {
                                                                    initialValue: this.state.user.name,
                                                                },
                                                                )(<Input />)}
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={24}>
                                                        <Col span={24}>
                                                            <Form.Item label= {lang.SETTINGS.FORM_LABELS.USERNAME}>
                                                                {getFieldDecorator('username', {
                                                                    initialValue: this.state.user.username,
                                                                },
                                                                )(<Input disabled />)}
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>

                                                </Col>
                                                <Col span={12}>
                                                    <Row gutter={24}>
                                                        <Col span={24}>
                                                            <Form.Item label= {lang.SETTINGS.FORM_LABELS.SAVEBUTTON}>
                                                                {getFieldDecorator('email', {
                                                                    initialValue: this.state.user.email,
                                                                    
                                                                    rules: [
                                                                        {
                                                                            type: 'email',
                                                                            message: 'The input is not valid E-mail!',
                                                                        },
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input your E-mail!',
                                                                        },
                                                                    ],
                                                                },
                                                                )(<Input />)}
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Item>
                                                            {getFieldDecorator('file', {
                                                                rules: [{ required: false, message: this.firstNameEmptyError }],
                                                            })(
                                                                <Row>
                                                                    <Avatar size={124} icon="user" src={`${complete_URL}/images/${this.state.user.image_id}`} alt="log here"
                                                                     /> 
                                                                    <Input type="file" id="file" className="file" onChange={this.handleImageUpload} />
                                                                </Row>
                                                            )}
                                                        </Form.Item>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                                        {lang.SETTINGS.FORM_LABELS.SAVEBUTTON}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <h1 style={{ textAlign: 'center', fontFamily: 'serif', fontSize: '20px' }}>{lang.PASSWORD_RESET.HEADING}</h1>
                                        <Form onSubmit={this.changePassword}>
                                            <Row gutter={24}>
                                                <Col span={6}></Col>
                                                <Col span={12}>
                                                    <Form.Item label={lang.PASSWORD_RESET.FORM_LABELS.NEWPASS}>
                                                        <Input.Password ref="oldpass" onChange={(e) => {this.setState({oldpass:e.target.value})}}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={6}></Col>
                                                <Col span={12}>
                                                    <Form.Item label={lang.PASSWORD_RESET.FORM_LABELS.OLDPASS}>
                                                        <Input.Password ref="newpass" onChange={(e) => {this.setState({newpass:e.target.value})}}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={6}></Col>
                                                <Col span={12}>
                                                    <Form.Item label={lang.PASSWORD_RESET.FORM_LABELS.RETYPE}>
                                                        <Input.Password ref="retype" onChange={(e) => {this.setState({retype:e.target.value})}}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={6}></Col>
                                                <Col span={12}>
                                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                                        {lang.PASSWORD_RESET.FORM_LABELS.SAVEBUTTON}
                                                    </Button>
                                                </Col>
                                                <Col span={6}></Col>
                                            </Row>
                                        </Form>
                                    </Fragment>
                            }
                        </Content>
                    </Layout>
                </Layout>
            </Fragment>
        )
    }
}

const WrappedUserSettingsForm = Form.create()(UserSettings);

export default withRouter(WrappedUserSettingsForm)