import React, { Component } from 'react';
import { Row, Col, Table, message, Button, Input, InputNumber, Checkbox, Spin, Modal, Tabs, Icon } from 'antd'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';

if (localStorage.getItem('lang') === 'AR') {
    require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;
const { TabPane } = Tabs;

class Contractor_Client_Request extends Component{
  constructor(props) {
      super(props)
      this.state = {
          data: [],
          packagesData: [],
          negPackagesData: [],
          file: null,
          pagination: {},
          page: 1,
          size: 5,
          sortField:"name",
          sortOrder:"ascend",
          searchValues: {
            name: '',
            name_ar :'',
            description :'', 
            email  :'',
            address :''
          },
          modelVisible : false,
          negModelVisible : false,
          cc_id : '',
          ccr_id :'',
          selected_client : {},
          dir : 'ltr', 
          display: 'block',
          spin_msg : '',
          name : '',
          contractor_doc: '00000000-0000-00',
          spin : false,
          upload : false,
      }
      

    this.lang = props.language._CONTRACTOR_CLIENT_REQ.SETUP;
    this.msg = props.language._CONTRACTOR_CLIENT_REQ.MESSAGE;
    this.pkg_lang = props.language._CONTRACTOR.UPDATE.PACKAGE;
    this.pkg_msg = props.language._CONTRACTOR.UPDATE.PACKAGE.MESSAGE;
    
    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width: '17%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width: '17%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.DESCRIPTION}
            <Search
              placeholder={this.lang.TABLEHEADERS.DESCRIPTION}
              onChange={this.handleChangeSearchValues("description")}
            />
          </span>
        ),
        dataIndex: 'description',
        key: 'description',
        width: '17%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.description, b.description),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.DESCRIPTION}
            <Search
              placeholder={this.lang.TABLEHEADERS.DESCRIPTION}
              onChange={this.handleChangeSearchValues("description")}
            />
          </span>
        ),
        dataIndex: 'description',
        key: 'description',
        width: '17%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.description, b.description),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.EMAIL}
            <Search
              placeholder={this.lang.TABLEHEADERS.EMAIL}
              onChange={this.handleChangeSearchValues("email")}
            />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        width: '21%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.email, b.email),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.EMAIL}
            <Search
              placeholder={this.lang.TABLEHEADERS.EMAIL}
              onChange={this.handleChangeSearchValues("email")}
            />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        width: '21%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.email, b.email),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.ADDRESS}
            <Search
              placeholder={this.lang.TABLEHEADERS.ADDRESS}
              onChange={this.handleChangeSearchValues("address")}
            />
          </span>
        ),
        dataIndex: 'address',
        key: 'address',
        width: '17%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.address, b.address),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.ADDRESS}
            <Search
              placeholder={this.lang.TABLEHEADERS.ADDRESS}
              onChange={this.handleChangeSearchValues("address")}
            />
          </span>
        ),
        dataIndex: 'address',
        key: 'address',
        width: '17%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.address, b.address),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.STATUS}
            <Search
              placeholder={this.lang.TABLEHEADERS.STATUS}
              onChange={this.handleChangeSearchValues("status")}
            />
          </span>
        ),
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.address, b.address),
        render : (text, record) =>  record.ccr_id ? <p>{text}</p> : <p>{'--'}</p> 
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.STATUS}
            <Search
              placeholder={this.lang.TABLEHEADERS.STATUS}
              onChange={this.handleChangeSearchValues("status")}
            />
          </span>
        ),
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.status, b.status),
        render : (text, record) =>  record.ccr_id ? <p>{text}</p> : <p>{'--'}</p> 
      },
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.fetchNegociatedPackagesData.bind(this, record)}>{this.lang.BUTTON.DETAILS}</Button>
          </span>
        ),
        key: 'pkg_btn',
        width : '7%',
        align:'center'
      },
      {
        title: '.',
        render: (text, record) => (
          <span>
            {
              record.cc_id ? 
              <Button type="primary" disabled = {true}>{this.lang.BUTTON.ASSIGNED}</Button>
              :
              record.ccr_id ? 
                <Button type="primary" disabled = {true}>{record.req_by == 0 ? this.lang.BUTTON.REQUESTED : this.lang.BUTTON.RECEIVED}</Button>
                :
                <Button type="primary" onClick={this.displayRequestModal.bind(this, record)}>{this.lang.BUTTON.SEND_REQ}</Button>
            }
          </span>
        ),
        width: '13%',
        key: 'last_name',
        align:'center'
      }
    ];

    this.pkg_columns = [//NEGOTIATE
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.NEGOTIATE}`,
        dataIndex: 'nego',
        width: '10%',
        align:'left',
        render: (text, record)=> <Checkbox checked={record.nego == 1} onChange={(e)=>{this.handleChangeNegotiate(e,record)}} />
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.NEGOTIATE}`,
        dataIndex: 'nego',
        width: '10%',
        align:'right',
        render: (text, record)=> <Checkbox checked={record.nego == 1} onChange={(e)=>{this.handleChangeNegotiate(e,record)}} />
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'type',
        width: '15%',
        align:'left',
        render: (text, record) => <p>{text}</p>,
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'type',
        width: '15%',
        align:'right',
        render: (text, record) => <p>{text}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.DESCRIPTION}`,
        dataIndex: 'description',
        width: '22%',
        align:'left',
        render: (text, record) => <p>{text}</p>,
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.DESCRIPTION}`,
        dataIndex: 'description_ar',
        align:'right',
        width: '22%',
        render: (text, record) => <p>{text}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        width: '10%',
        align:'left',
        render: (text, record) =>( 
          <>
          <p>{text}</p>
          <InputNumber 
            value={record.neg_price} min={0} style={{width:'90px'}} 
            onChange={(e) => this.changeQty(e,record,'neg_price')}
            disabled = {record.nego == 0}
          />
          </>
        )
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        align:'right',
        width: '10%',
        render: (text, record) => ( 
          <>
          <p>{text}</p>
          <InputNumber value={record.neg_price} min={0} style={{width:'90px'}} 
          onChange={(e) => this.changeQty(e,record,'neg_price')}
          disabled = {record.nego == 0}
          />
          </>
        )
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONPERCENT}`,
        dataIndex: 'commission_percentage',
        width: '18%',
        align:'left',
        render: (text, record) => ( 
          <>
          <p>{text}</p>
          <InputNumber value={record.neg_comm_pct} min={0} style={{width:'90px'}} 
          onChange={(e) => this.changeQty(e,record,'neg_comm_pct')}
          disabled = {record.nego == 0 || record.neg_comm_amt > 0}
          />
          </>
        )
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONPERCENT}`,
        dataIndex: 'commission_percentage',
        width: '18%',
        align:'right',
        render: (text, record) => ( 
          <>
          <p>{text}</p>
          <InputNumber value={record.neg_comm_pct} min={0} style={{width:'90px'}} 
          onChange={(e) => this.changeQty(e,record,'neg_comm_pct')}
          disabled = {record.nego == 0 || record.neg_comm_amt > 0}
          />
          </>
        )
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        width: '15%',
        align:'left',
        render: (text, record) => ( 
          <>
          <p>{text}</p>
          <InputNumber value={record.neg_comm_amt} min={0} style={{width:'90px'}} 
          onChange={(e) => this.changeQty(e,record,'neg_comm_amt')}
          disabled = {record.nego == 0 || record.neg_comm_pct > 0} 
          />
          </>
        )
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        width: '15%',
        align:'right',
        render: (text, record) =>  ( 
          <>
          <p>{text}</p>
          <InputNumber value={record.neg_comm_amt} min={0} style={{width:'90px'}} 
          onChange={(e) => this.changeQty(e,record,'neg_comm_amt')}
          disabled = {record.nego == 0 || record.neg_comm_pct > 0}
          />
          </>
        )
      }
    ];
    //Copy from above pkg_columns to update Negotiation values~
    this.upd_neg_pkg_columns = [//NEGOTIATE
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.NEGOTIATE}`,
        dataIndex: 'nego',
        width: '10%',
        align:'left',
        render: (text, record)=> <Checkbox checked={record.nego == 1} onChange={(e)=>{this.handleChangeNegotiateStored(e,record)}} />
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.NEGOTIATE}`,
        dataIndex: 'nego',
        width: '10%',
        align:'right',
        render: (text, record)=> <Checkbox checked={record.nego == 1} onChange={(e)=>{this.handleChangeNegotiateStored(e,record)}} />
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'type',
        width: '15%',
        align:'left',
        render: (text, record) => <p>{text}</p>,
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'type',
        width: '15%',
        align:'right',
        render: (text, record) => <p>{text}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.DESCRIPTION}`,
        dataIndex: 'description',
        width: '22%',
        align:'left',
        render: (text, record) => <p>{text}</p>,
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.DESCRIPTION}`,
        dataIndex: 'description_ar',
        align:'right',
        width: '22%',
        render: (text, record) => <p>{text}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        width: '10%',
        align:'left',
        render: (text, record) =>( 
          <>
          <p>{text}</p>
          <InputNumber 
            value={record.neg_price} min={0} style={{width:'90px'}} 
            onChange={(e) => this.changeQtyStored(e,record,'neg_price')}
            disabled = {record.nego == 0}
          />
          </>
        )
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        align:'right',
        width: '10%',
        render: (text, record) => ( 
          <>
          <p>{text}</p>
          <InputNumber value={record.neg_price} min={0} style={{width:'90px'}} 
          onChange={(e) => this.changeQtyStored(e,record,'neg_price')}
          disabled = {record.nego == 0}
          />
          </>
        )
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONPERCENT}`,
        dataIndex: 'commission_percentage',
        width: '18%',
        align:'left',
        render: (text, record) => ( 
          <>
          <p>{text}</p>
          <InputNumber value={record.neg_comm_pct} min={0} style={{width:'90px'}} 
          onChange={(e) => this.changeQtyStored(e,record,'neg_comm_pct')}
          disabled = {record.nego == 0 || record.neg_comm_amt > 0}
          />
          </>
        )
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONPERCENT}`,
        dataIndex: 'commission_percentage',
        width: '18%',
        align:'right',
        render: (text, record) => ( 
          <>
          <p>{text}</p>
          <InputNumber value={record.neg_comm_pct} min={0} style={{width:'90px'}} 
          onChange={(e) => this.changeQtyStored(e,record,'neg_comm_pct')}
          disabled = {record.nego == 0 || record.neg_comm_amt > 0}
          />
          </>
        )
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        width: '15%',
        align:'left',
        render: (text, record) => ( 
          <>
          <p>{text}</p>
          <InputNumber value={record.neg_comm_amt} min={0} style={{width:'90px'}} 
          onChange={(e) => this.changeQtyStored(e,record,'neg_comm_amt')}
          disabled = {record.nego == 0 || record.neg_comm_pct > 0} 
          />
          </>
        )
      }:
      {
        title: `${this.pkg_lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        width: '15%',
        align:'right',
        render: (text, record) =>  ( 
          <>
          <p>{text}</p>
          <InputNumber value={record.neg_comm_amt} min={0} style={{width:'90px'}} 
          onChange={(e) => this.changeQtyStored(e,record,'neg_comm_amt')}
          disabled = {record.nego == 0 || record.neg_comm_pct > 0}
          />
          </>
        )
      }
    ];

    }// End of Contractor_Client_Request

    setComponentDirection=()=>{
      if(localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
      }
      else {
        this.setState({dir : 'rtl', display: 'flex'})
      }
    }

    compareByAlph = (a, b) => {
        if (a > b) { return -1; }
        if (a < b) { return 1; }
        return 0;
    }

    handleChangeNegotiate=(value, record)=>{
      let pkgs = Object.assign([],this.state.packagesData);
      if(value.target.checked === false) {
        Object.assign(pkgs[record.row_no],
          { nego : 0, neg_price : parseFloat(pkgs[record.row_no].price).toFixed(2),
            neg_comm_pct : parseFloat(pkgs[record.row_no].commission_percentage).toFixed(2),
            neg_comm_amt : parseFloat(pkgs[record.row_no].commission_amount).toFixed(2)
          });
      }
      else{
        pkgs[record.row_no].nego = 1;
      }
      this.setState({packagesData : pkgs});
    }// End of Method

    handleChangeNegotiateStored=(value, record)=>{
      let pkgs = Object.assign([],this.state.negPackagesData);
      if(value.target.checked === false) {
        Object.assign(pkgs[record.row_no],
          { nego : 0, neg_price : parseFloat(pkgs[record.row_no].price).toFixed(2),
            neg_comm_pct : parseFloat(pkgs[record.row_no].commission_percentage).toFixed(2),
            neg_comm_amt : parseFloat(pkgs[record.row_no].commission_amount).toFixed(2)
          });
      }
      else{
        pkgs[record.row_no].nego = 1;
      }
      this.setState({negPackagesData : pkgs});
    }// End of Method

    changeQty = (value, record, key)=>{
      let pkgs = Object.assign([],this.state.packagesData);
      Object.assign(pkgs[record.row_no],{ [key] : value});
      this.setState({packagesData : pkgs});
    }// End of method

    changeQtyStored = (value, record, key)=>{
      let pkgs = Object.assign([],this.state.negPackagesData);
      Object.assign(pkgs[record.row_no],{ [key] : value});
      this.setState({negPackagesData : pkgs});
    }// End of method

    handleSendRequest =()=>{
      // console.log('this.state.selected_client ->',this.state.selected_client);
      let c_notes = this.refs.desc_Ref.state.value ? this.refs.desc_Ref.state.value : '--';
      this.setState({spin_msg : this.msg.REQSENDTOCLIENTTIP, spin : true, modelVisible : false});
      const headers = getHeadersForHttpReq();

      let data = {
        lang : localStorage.getItem('lang'),
        client_id : this.state.selected_client.client_id,
        client_email : this.state.selected_client.email,
        user_id : localStorage.getItem('user_id'),
        cont_id : JSON.parse(localStorage.getItem('updateContractor')).id,
        contractor_email : JSON.parse(localStorage.getItem('updateContractor')).email,
        req_by : 'contractor',
        contractor_desc : c_notes,
        pkgs : JSON.stringify(this.state.packagesData)
      }    
      axios.post(`${complete_URL}/cont_clients_req/add`, data, {headers})
      .then(res => { //console.log('cont req res.data->',res.data);
        if(res.data.success) {
          this.handleSubmitDocument();
          this.fetch();
          message.success(this.msg.REQSENDTOCLIENTSUCCESS);
        } 
        else {
          this.setState({modelVisible : false, spin : false},()=>message.error(this.msg.REQSENDTOCLIENTFAILURE,3));
        }
      })
      .catch(err => {
        this.setState({modelVisible : false, spin : false});
        console.log('Error occured by Contractor while sending the Request ->',err)
        if(err.data.code === '002'){
          message.error(this.msg.REQMAILSENDTOCLIENTFAILURE,3);
        }
        else {
          message.error(this.msg.REQSENDTOCLIENTFAILURE,3);
        }
      })
    }// End of Method

    handleUpdateNegRequest=()=>{
      if (this.state.ccr_id && this.state.ccr_id.length > 0) {
        this.setState({spin_msg : this.msg.REQNEGUPDATETIP, negModelVisible : false, spin : true});
        const headers = getHeadersForHttpReq();
        let data = {
          lang : localStorage.getItem('lang'),
          client_id : this.state.selected_client.client_id,
          user_id : localStorage.getItem('user_id'),
          cont_id : JSON.parse(localStorage.getItem('updateContractor')).id,
          pkgs : JSON.stringify(this.state.negPackagesData)
        };    
        axios.post(`${complete_URL}/contractor_neg_pkgrate/update_neg_request`, data, {headers})
        .then(neg_res=>{
          if(neg_res.data.success) {
            this.setState({negModelVisible : false, spin : false},()=>{
              this.fetch();
              message.success(this.msg.REQNEGADDUPDATESUCCESS);
            })
          } 
          else {
            this.setState({modelVisible : false, spin : false},()=>message.error(this.msg.REQNEGADDUPDATEFAILURE,3));
          }
        })
        .catch(neg_err=>{ 
            this.setState({modelVisible : false, spin : false},()=>message.error(this.msg.REQNEGADDUPDATEFAILURE,3));
            console.log('Error occured while updating the Negotiation Request->',neg_err);
        })
      }// End of check if Reqeust is already sent
    }// End of Method

    displayRequestModal =(record)=>{
      this.setState({modelVisible : true, selected_client : record});
    }

    handleChangePagination = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({ pagination: pager, pageNumber: pagination.current });
    
        if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
          if (this.state.pageNumber  !== pager.current) {
            this.fetch({ page: pagination.current, });
          }
        } 
        else {
          this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
          });
        }
    }
    
    handleChangeSearchValues = (prop) => (e) => {
        let search = { ...this.state.searchValues };
        search[`${prop}`] = e.target.value;
        this.setState({
          searchValues: search
        }, () => {
          this.fetch();
        });
    }
  
    fetch = (params={}) => {
      const cont_id = JSON.parse(localStorage.getItem('updateContractor')).id;
      let headers = getHeadersForHttpReq();
      let page,sortOrder ;
      if (params.page) { page = params.page - 1 }
      else { page = 0;}

      if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
      else if (params.sortOrder == 'descend') { sortOrder = 'DESC' }
      else { sortOrder = 'ASC' }
      
      let data ={
        cont_id : cont_id,
        name: this.state.searchValues.name || '',
        name_ar: this.state.searchValues.name_ar || '',
        description : this.state.searchValues.description || '',
        email : this.state.searchValues.email || '',
        address : this.state.searchValues.address || '',
        page,
        sortColumn: params.sortField || this.state.sortField ,
        sortOrder,
        size:this.state.size,
        orderBy: '',
        column_name: 'no'
      }
        // console.log('Sending data to api ->',data);
        // axios.post(`${complete_URL}/cont_clients_req/fetch_client_for_cont_to_reqs_pagination_table`,data, {headers})
        axios.post(`${complete_URL}/cont_clients_req/fetch_un_client_for_cont_to_reqs_pagination_table`,data, {headers})
        .then(response => {
          console.log('response of cont_clients_re pagination response.data.data ->',response.data.data)
          let pagination = {};
          Object.assign(pagination,{
            ...this.state.pagination,
            total : response.data.totalElements,
            current : page +1,
            pageSize : this.state.size
          });
          
          this.setState({ data: response.data.data, pagination, spin : false});
          // console.log('Expense data-> ',response);
        })
        .catch(err => { 
          console.log('Error occured while fetching pagination data new method ->',err); 
          this.setState({spin : false});
        })
    }// End of Method fetch

    getContractorPackagesForNegociation=()=>{
      const cont_id = JSON.parse(localStorage.getItem('updateContractor')).id;
      if (cont_id) {
        const headers = getHeadersForHttpReq();
        axios.get(`${complete_URL}/contractorpackages/fetch_pkgs_for_neg/${cont_id}`,{headers})
        .then(pkgs_res=>{//console.log('Defaults pkgs to be used as Neg pkgs ->',pkgs_res.data.data);
          this.setState({packagesData: pkgs_res.data.data});
        })
        .catch(pkgs_err=>{
          console.log('Error occured while fetching Contractor Packages for negociations->',pkgs_err);
        })
      }
    }// End of Method

    fetchNegociatedPackagesData =async(record)=>{//Yet to apply with Coulmns & then in Table & then diplay Popup for update & then write Update API
      const cont_id = JSON.parse(localStorage.getItem('updateContractor')).id;
      if (cont_id && record.client_id) {
        let cd = record.contractor_doc ? record.contractor_doc : '00000000-0000-00';
        
        const headers = getHeadersForHttpReq();
        await axios.get(`${complete_URL}/contractorpackages/fetch_pkg_neg_by_contractor_upd/${cont_id}/${record.client_id}`,{headers})
        .then(res => {  //console.log('Stored Neg pkgs along with default packagesData->',res.data.data);
            this.setState({ negPackagesData: res.data.data, cc_id : record.cc_id,ccr_id : record.ccr_id,
              contractor_doc: cd, selected_client : record,  negModelVisible : true 
            });
        })
        .catch(err => { console.log('Error occured while fetching Packages from Contractor ID->',err) })
      }// End of If req_data
    }// End of Method

    componentDidMount(){
      this.fetch();
      this.getContractorPackagesForNegociation();
      this.setComponentDirection();
    }
    
    componentWillMount() {
      if (localStorage.getItem('lang') === 'EN') {
        this.CustomRow = <Row id="clientTableHeader">
          <Col span={1}> </Col>
          <Col span={8}><p style={{textAlign:'left'}}>{this.lang.TABLEHEADERS.REQCLIENTTITLE}</p></Col>
          <Col span={14}> </Col>
        </Row>
      }
      else {
        this.CustomRow = <Row id="clientTableHeader">
          <Col span={14} > </Col>
          <Col span={8}><p>{this.lang.TABLEHEADERS.REQCLIENTTITLE}</p></Col>
          <Col span={1}></Col>
        </Row>
      }
    }

    closeModal = (key, value)=>{
      this.setState({[key] : value},()=>{
        if (key === 'modelVisible' && value === false) {
          this.removeDoc();
        }
      });
    }
    
    handleSaveDocForUpload = (e) => {
      e.preventDefault();
      if (e.currentTarget.files && e.currentTarget.files[0]) {
        var file = e.currentTarget.files[0];
        let fileName = file.name;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (file.size <= 1024 * 1024 * 2 && /png|xlsx|pdf|odt|docx|jpe?g/i.test(extension)) {
          // console.log(extension)
          this.setState({ file: file, name:fileName, upload: true } );
        } 
        else {
          this.setState({file: null,name : '', upload:false},()=>{
            message.error(this.msg.REQSENDTDOCTYPEERROR,2);
          });
        }
      }// End of Method File format check
    }// End of Method

    handleSubmitDocument = () => {
      if (this.state.upload && this.state.file) {
        let imgdata = new FormData(); 
        imgdata.append('file', this.state.file);
        imgdata.append('name', this.state.name);
        imgdata.append('req_by', '0');
        imgdata.append('user_id', localStorage.getItem('user_id'));
        imgdata.append('cont_id', JSON.parse(localStorage.getItem('updateContractor')).id);
        imgdata.append('client_id',this.state.selected_client.client_id);
        let headers = {
          'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
          'Content-Type': 'multipart/form-data'
        };
      
        this.setState({ spin : true });
        axios.post(`${complete_URL}/images/cont_client_doc/add`, imgdata, { responseType: 'blob' }, headers)
          .then(res => {
            let reader = new FileReader();
            reader.readAsDataURL(res.data);
            this.setState({spin:false, file: null, upload:false },()=>{
              message.success(this.msg.REQSENDTDOCADDSUCCESS,2);
            })
          })
          .catch(err => {
            console.log('Error occured while uploading Contract doc by Contractor while sending request->',err)
            this.setState({spin:false },()=>{ message.error(this.msg.REQSENDTDOCADDFAILURE,3); });
          })
      } //may used or may be removed
    }//End of Method

    removeDoc=()=>{
      this.setState({file: null, upload:false});
    }

    downloadDoc = () => {
      let documentid = this.state.contractor_doc;
      if (documentid !== '00000000-0000-00') {
        // console.log(documentid)
        axios.get(`${complete_URL}/images/${documentid}`, { responseType: 'blob' })
          .then(results => {
            let doc;
            var reader = new FileReader();
            reader.readAsDataURL(results.data);
            reader.onloadend = () => {
              doc = reader.result
              const link = document.createElement('a');
              link.href = doc;
              link.setAttribute('download', `Request-Document.${results.data.type.split('/')[1]}`); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(err => {console.log('Error occured while downloading the Request Document ->',err); })
      }
    }// End of Method

    render() {
      let { cc_id, ccr_id, spin_msg, spin, negModelVisible, modelVisible, dir, display,
            file, data, pagination, packagesData, negPackagesData} = this.state;

        return (
            <Spin tip={spin_msg} spinning = {spin}>
              {this.CustomRow}
              <Row id="clientsTable" gutter={24}>
                  <Col span={24} >
                  <Table
                      columns={this.columns}
                      rowKey="name"
                      dataSource={data}
                      onChange={this.handleChangePagination}
                      pagination={pagination}
                  /> 
                  </Col>
              </Row>
              {/*SEND REQUEST TO CLIENT MODEL POPUP*/}
              <Modal 
                title={this.lang.BUTTON.SEND_REQ}
                centered
                visible={ modelVisible } 
                closable = {true}
                destroyOnClose={true}
                onCancel = {() =>this.closeModal('modelVisible',false)}
                width = {'85%'}
                footer = {false}
              >
              <>
                <Tabs defaultActiveKey="1" type="card" size={'Large'}>
                  <TabPane tab = {this.lang.BUTTON.NOTES} key="1">
                  <Row gutter={24}>
                    <Col span={24}>
                      <Input.TextArea ref="desc_Ref" rows={12} style={{ width: '100%' }} />
                    </Col>
                  </Row>
                  </TabPane>
                  <TabPane tab = {this.lang.BUTTON.NEGOTIATE} key="2">
                    <Table
                      columns={this.pkg_columns}
                      rowKey="id" 
                      dataSource={packagesData} 
                      style={{ marginTop: '10px' }} 
                    />
                  </TabPane>
                </Tabs>
                <br/>
                <Row gutter={24}>
                  <Col span={8}>
                    <Input type="file" style={{lineHeight: '16px'}} onChange={this.handleSaveDocForUpload} /> 
                  </Col>
                  <Col span={16}>
                    {file &&
                      <><span>{file.name}</span>
                      <Icon type="close" style={{marginLeft:'10px',textAlign:'right'}} onClick={()=>{this.removeDoc()}}/></>
                    }
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24} style={{textAlign: 'center'}}>
                    <Button type="primary" shape="round" onClick={this.handleSendRequest}>{this.lang.BUTTON.SEND_REQ} </Button>
                  </Col>
                </Row>
              </>
              </Modal>
              {/*SEE NEGOTIATED DETAILS MODEL POPUP*/}
              <Modal 
                title={this.lang.BUTTON.DETAILS}
                centered
                visible={ negModelVisible } 
                closable = {true}
                destroyOnClose={true}
                onCancel = {() =>this.closeModal('negModelVisible',false)}
                width = {'85%'}
                footer = {false}
              >
              <Row gutter={24} dir= {dir} style={{ display }}>
              <Col span={24}>
                <Table
                  columns={this.upd_neg_pkg_columns}
                  rowKey="id" 
                  dataSource={negPackagesData} 
                  style={{ marginTop: '10px' }} 
                />
                <br/>
                <Row gutter={24}>
                  <Col span={8}>
                    <Input disabled = {cc_id || (!ccr_id)} type="file" style={{lineHeight: '16px'}} onChange={this.handleSaveDocForUpload} /> 
                  </Col>
                  <Col span={8}>
                    {file &&
                      <><span>{file.name}</span>
                      <Icon type="close" style={{marginLeft:'10px',textAlign:'right'}} onClick={()=>{this.removeDoc()}}/></>
                    }
                  </Col>
                  <Col span={8}>
                    {
                      this.state.contractor_doc !== '00000000-0000-00' ?
                      <a><p style={{fontSize:'15px'}} onClick={this.downloadDoc}>{this.lang.TABLEHEADERS.CONTRACTORDOC}
                        <Icon type="download" title = {this.lang.TABLEHEADERS.CONTRACTORDOC} style={{marginLeft:'10px'}} /> </p></a>
                      :<p style={{fontSize:'15px'}} >{'No Document attached..'}</p>
                    }
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24} style={{textAlign: 'center'}}>
                    <Button type="primary" shape="round" disabled = {cc_id || (!ccr_id)}
                      onClick={this.handleUpdateNegRequest}>
                      {this.lang.BUTTON.UPDATENEG}
                    </Button>
                  </Col>
                </Row>
              </Col>
              </Row>
              </Modal>
            </Spin>
        )
    }// End of render
  }//End of Component

  export default Contractor_Client_Request;