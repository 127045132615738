import React, { Component } from 'react';
import { Row, Col, Table, Button, message, Input, Modal, } from 'antd'
import axios from 'axios'
import API_URL from '../../constants'
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class ContractorsAssignedBqt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // data: {},
      data: [],
      pagination: {},
      page: 1,
      size: 5,
      sortField:"name",
      sortOrder:"ascend",
      searchValues: {
        name: '',
        name_ar: '',
        email: '',
        id_card: '',
        ph_num: '',
        ph_alt_num: '',
        new_service_type_name: '',
        new_service_type_name_ar: '',
        commercial_reg_no: '',
        
      }
    }
    this.lang = props.language._CONTRACTOR.SETUP;

    this.columns = [
      (localStorage.getItem('lang')==='EN')?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAME}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAME}
              onChange={this.handleChangeSearchValues("name")}
            />
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        width : '15%',
				align : 'left',
        sorter: (a, b) => this.compareByAlph(a.name, b.name),
        render: (text, record) => <p>{text}</p>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NAMEAR}
            <Search
              placeholder={this.lang.TABLEHEADERS.NAMEAR}
              onChange={this.handleChangeSearchValues("name_ar")}
            />
          </span>
        ),
        dataIndex: 'name_ar',
        key: 'name_ar',
        align : 'right',
        width : '15%',
        sorter: (a, b) => this.compareByAlph(a.name_ar, b.name_ar),
        render: (text, record) => <p>{text}</p>,
      },
      (localStorage.getItem('lang')==='EN')?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.EMAIL}
            <Search
              placeholder={this.lang.TABLEHEADERS.EMAIL}
              onChange={this.handleChangeSearchValues("email")}
            />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        width : '15%',
				align : 'left',
        sorter: (a, b) => this.compareByAlph(a.email, b.email),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.EMAIL}
            <Search
              placeholder={this.lang.TABLEHEADERS.EMAIL}
              onChange={this.handleChangeSearchValues("email")}
            />
          </span>
        ),
        dataIndex: 'email',
        key: 'email',
        align : 'right',
        width : '15%',
        sorter: (a, b) => this.compareByAlph(a.email, b.email),
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.PHONENO}
            <Search
              placeholder={this.lang.TABLEHEADERS.PHONENO}
              onChange={this.handleChangeSearchValues("ph_num")}
            />
          </span>
        ),
        dataIndex: 'ph_num',
        key: 'ph_num',
        width : '15%',
        sorter: (a, b) => this.compareByAlph(a.ph_num, b.ph_num),
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.PHONENO}
            <Search
              placeholder={this.lang.TABLEHEADERS.PHONENO}
              onChange={this.handleChangeSearchValues("ph_num")}
            />
          </span>
        ),
        dataIndex: 'ph_num',
        key: 'ph_num',
        width : '15%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.ph_num, b.ph_num),
      },
      // (localStorage.getItem('lang')==='EN')?
      // {
      //   title: (
      //     <span>{this.lang.TABLEHEADERS.IDCARDNO}
      //       <Search
      //         placeholder={this.lang.TABLEHEADERS.IDCARDNO}
      //         onChange={this.handleChangeSearchValues("id_card")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'id_card',
      //   key: 'id_card',
      //   width : '15%',
			// 	align : 'left',
      //   sorter: (a, b) => this.compareByAlph(a.id_card, b.id_card),
      // }:
      // {
      //   title: (
      //     <span>{this.lang.TABLEHEADERS.IDCARDNO}
      //       <Search
      //         placeholder={this.lang.TABLEHEADERS.IDCARDNO}
      //         onChange={this.handleChangeSearchValues("id_card")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'id_card',
      //   key: 'id_card',
      //   align : 'right',
      //   width : '15%',
      //   sorter: (a, b) => this.compareByAlph(a.id_card, b.id_card),
      // },
      (localStorage.getItem('lang')==='EN')?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.SERVICETYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.SERVICETYPE}
              onChange={this.handleChangeSearchValues("new_service_type_name")}
            />
          </span>
        ),
        dataIndex: 'new_service_type_name',
        key: 'new_service_type_name',
        width : '15%',
				align : 'left',
        sorter: (a, b) => this.compareByAlph(a.new_service_type_name, b.new_service_type_name),
      } :
      {
        title: (
          <span>{this.lang.TABLEHEADERS.SERVICETYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.SERVICETYPE}
              onChange={this.handleChangeSearchValues("new_service_type_name_ar")}
            />
          </span>
        ),
        dataIndex: 'new_service_type_name_ar',
        key: 'new_service_type_name_ar',
        align : 'right',
        width : '15%',
        sorter: (a, b) => this.compareByAlph(a.new_service_type_name_ar, b.new_service_type_name_ar),
      },
      // (localStorage.getItem('lang')==='EN')?
      // {
      //   title: (
      //     <span>{this.lang.TABLEHEADERS.COMMERCIALREGNO}
      //       <Search
      //         placeholder={this.lang.TABLEHEADERS.COMMERCIALREGNO}
      //         onChange={this.handleChangeSearchValues("commercial_reg_no")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'commercial_reg_no',
      //   key: 'commercial_reg_no',
      //   width : '15%',
			// 	align : 'left',
      //   sorter: (a, b) => this.compareByAlph(a.commercial_reg_no, b.commercial_reg_no),
      // } :
      // {
      //   title: (
      //     <span>{this.lang.TABLEHEADERS.COMMERCIALREGNO}
      //       <Search
      //         placeholder={this.lang.TABLEHEADERS.COMMERCIALREGNO}
      //         onChange={this.handleChangeSearchValues("commercial_reg_no")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'commercial_reg_no',
      //   key: 'commercial_reg_no',
      //   align : 'right',
      //   width : '15%',
      //   sorter: (a, b) => this.compareByAlph(a.commercial_reg_no, b.commercial_reg_no),
      // },
      // {
      //   title: (
      //     <span>{this.lang.TABLEHEADERS.PHONENO}
      //       <Search
      //         placeholder={`Search ${this.lang.TABLEHEADERS.PHONENO}`}
      //         onSearch={value => console.log(value)}
      //         onChange={this.handleChangeSearchValues("ph_num")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'ph_num',
      //   key: 'ph_num',
      //   sorter: (a, b) => this.compareByAlph(a.ph_num, b.ph_num),
      // },
      // {
      //   title: (
      //     <span>Alternative Phone No.
      //     <Search
      //         placeholder="Search alternative phone no."
      //         onSearch={value => console.log(value)}
      //         onChange={this.handleChangeSearchValues("ph_alt_num")}
      //       />
      //     </span>
      //   ),
      //   dataIndex: 'ph_alt_num',
      //   key: 'ph_alt_num',
      //   sorter: (a, b) => this.compareByAlph(a.ph_alt_num, b.ph_alt_num),
      // }
      ,
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.handleNavigation.bind(this, record)}>{props.language._CONTRACTOR.UPDATE.PACKAGE.TITLE}</Button>
          </span>
        ),
        key: 'last_name',
        width : '7%',
        align:'center'
    }];
  }

  setComponentDirection=()=>{
    if(localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    localStorage.setItem('updateContractor', JSON.stringify(record));
    this.props.history.push('/dashboard/assignedcontractorpkgs');
  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager, pageNumber: pagination.current });

    if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
      if (this.state.pageNumber  !== pager.current) {
        this.fetch({ page: pagination.current, });
      }
    } 
    else {
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }
    
  }//End of method

  handleChangeSearchValues = (val) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${val}`] = e.target.value;
    this.setState({ searchValues: search }, () => { this.fetch(); });
  }

  componentDidMount() {
    this.fetch();
    this.setComponentDirection();
  }

  fetch = (params={}) => {
    // console.log('Fetch method Search values Contractors->',this.state.searchValues);
    const client_id = localStorage.getItem('client_id');
    let headers =  getHeadersForHttpReq();
      
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else if (params.sortOrder == 'descend') { sortOrder = 'DESC' }
    else { sortOrder = 'ASC' }

    
    let data ={
      client_id : client_id,
      name: this.state.searchValues.name || '',
      name_ar: this.state.searchValues.name_ar || '',
      email: this.state.searchValues.email || '',
      id_card: this.state.searchValues.id_card || '',
      ph_num: this.state.searchValues.ph_num || '',
      ph_alt_num: this.state.searchValues.ph_alt_num || '',
      new_service_type_name: this.state.searchValues.new_service_type_name || '',
      new_service_type_name_ar: this.state.searchValues.new_service_type_name_ar || '',
      commercial_reg_no: this.state.searchValues.commercial_reg_no || '',
      
      page,
      sortColumn: params.sortField || this.state.sortField ,
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'name'
    }
       
      axios.post(`${complete_URL}/cont_clients/pagination_table_assigned`,data, {headers})
      .then(response => {
        // console.log('response of exp new pagination ->',response)
        let pagination = {};
        Object.assign(pagination,{
          ...this.state.pagination,
          total : response.data.totalElements,
          current : page +1,
          pageSize : this.state.size
        });

        this.setState({ data: response.data.data, pagination });
      })
      .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })

  } //End of method

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={1}></Col>
        <Col span={8}><p style={{ textAlign: 'left'}}>{this.lang.TABLEHEADERS.ASSIGNEDCONTRACTORSTITLE}</p></Col>
        <Col span={14}></Col>
      </Row>
    }
    else {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={14}></Col>
        <Col span={9}><p>{this.lang.TABLEHEADERS.ASSIGNEDCONTRACTORSTITLE}</p></Col>
        <Col span={1}></Col>
      </Row>
    }
  }

  render() {
    return (
        <React.Fragment>
          {this.CustomRow}
          <Row id="clientsTable" >
            <Col span={24}>
              <Table
                columns={this.columns}
                rowKey="id"
                dataSource={this.state.data}
                onChange={this.handleChangePagination}
                pagination={this.state.pagination}
              />,
            </Col>
          </Row>
        </React.Fragment>
    )
  }
}
export default ContractorsAssignedBqt;