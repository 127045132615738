import React, { Component, Fragment } from 'react'
import axios from 'axios'
import API_URL from '../../constants'
import { Input, Table, DatePicker, Select, Row, Col } from 'antd'
import moment from 'moment'
const { MonthPicker } = DatePicker
// eslint-disable-next-line
const Search = Input.Search;
const pt = localStorage.getItem('pt');
class ClientAccountReservations extends Component{
    constructor(props){
        super(props)
        this.state = {
            reservations: [],
            pagination: {},
            page: 1,
            size: 5,
            sortField:'res_no',
            date:"",
            filterType:''
        }
        this.lang = this.props.language
        this.columns = [
            {
                title: (
                  <span>{this.lang.RESERVTION_WISE.TABLEHEADERS.RES_NO}
                  
                  </span>
                ),
                dataIndex: 'res_no',
                key: 'res_no',
                width: '20%',
                sorter:true,
                // sorter: (a, b) => this.compareByAlph(a.email, b.email),
              },
            {
              title: (
                <span>{this.lang.RESERVTION_WISE.TABLEHEADERS.DATE}
               
                </span>
              ),
      
              dataIndex: 'date',
              key: 'date',
              width: '20%',
              sorter:true,
              // sorter: (a, b) => this.compareByAlph(a.name, b.name),
              render: (text, record) => <button className="link-button"
              // onClick={this.handleNavigation.bind(this, record)}
              >
              {text}
            </button>,
            },

            {
              title: (
                <span>{this.lang.RESERVTION_WISE.TABLEHEADERS.CUS_NAME}
             
                </span>
              ),
              dataIndex: 'name',
              key: 'name',
              width: '20%',
              sorter:true,
              // sorter: (a, b) => this.compareByAlph(a.description, b.description),
            },
            {
              title: (
                <span>{this.lang.RESERVTION_WISE.TABLEHEADERS.TOTAL_RENT}
             
                </span>
              ),
              dataIndex: 'res_amount',
              key: 'res_amount',
              width: '20%',
              sorter:true,
              // sorter: (a, b) => this.compareByAlph(a.description, b.description),
            },
            {
              title: (
                <span>{this.lang.RESERVTION_WISE.TABLEHEADERS.TOTAL}
            
                </span>
              ),
              dataIndex: 'total_amount',
              key: 'total_amount',
              width: '20%',
              sorter:true,
              // sorter: (a, b) => this.compareByAlph(a.description, b.description),
            },
        
           
          ];
    }
    handleChangePagination = (pagination, filters, sorter) => {
        let pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          page: pager.current,
          pagination: pager,
          
        }, () => {
          this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
          });
        })
    }
    compareByAlph = (a, b) => {
        if (a > b) { return -1; }
        if (a < b) { return 1; }
        return 0;
      }
    componentDidMount(){
          this.fetch() 
    }
    fetch  = (params={}) =>{ 
      console.log(params)
        let page,sortOrder
      if (params.page) {
          page = params.page - 1
        }
          else{
            page = 0;
        }
        if (params.sortOrder == 'ascend') {
          sortOrder = 'ASC'
        }
        else {
          sortOrder = 'DESC'
        }
        let sortColumn = params.sortField || this.state.sortField 

        axios.get(`${API_URL}${pt}/clientpayments/get_client_acount_resrvtion_wise/?client=${this.props.client}&page=${this.state.page}&size=${this.state.size}&date=${this.state.date}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`)
        .then(res => {
          let pagination = { ...this.state.pagination };
          pagination.total = res.data.totalElements;
          pagination.pageSize = parseInt(res.data.size);
            this.setState({reservations:res.data.selectedElements,pagination: pagination,
            })
        })
        .catch(err => {
            console.log(err)
        })
    }
    render(){
        return(
        <Fragment>
          <Row gutter={24}>
            <Col span={12}>
              <Select placeholder="Filter Option" onChange={(v) => this.setState({filterType:v})} style={{width:'100%'}}>
                  <Select.Option value="m">Monthly</Select.Option>
                  <Select.Option value="d">Date Wise</Select.Option>
              </Select>
            </Col>
            <Col span={12}>
              {this.state.filterType == 'm'  ?
              <MonthPicker  onChange={(e) =>this.setState({
                date: moment(e).isValid() ? moment(e).format('YYYY-MM') : ""
                },() => this.fetch())} placeholder={this.lang.RESERVTION_WISE.SEARCH_DATE} />
              : this.state.filterType == 'd'  ?
              <DatePicker  onChange={(e) =>this.setState({
                date: moment(e).isValid() ? moment(e).format('YYYY-MM-DD') : ""
                },() => this.fetch())} placeholder={this.lang.RESERVTION_WISE.SEARCH_DATE} /> :''
              }
            </Col>
          </Row>
            <Table
                columns={this.columns}
                rowKey="id"
                dataSource={this.state.reservations}
                pagination={this.state.pagination}
                onChange={this.handleChangePagination}
              />
        </Fragment>
        )
    }
}

export default ClientAccountReservations