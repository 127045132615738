import React, { Component } from 'react';
import { /* Avatar,*/  Row,  Col,  Form,  Icon,  Button,  Input,  Card,  message, InputNumber, Switch } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios'
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class AddClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._CLIENT.CREATE;
    this.msg = props.language._CLIENT.MESSAGE;

  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let data = {
          user_id : localStorage.getItem('user_id'),
          name: values.name,
          description: values.description,
          email: values.email,
          address: values.address,
          // created_by: values.name,
          // updated_by: values.name,
          commission: values.commission,
          iscommission: values.iscommission ? 1 : 0,
          // imgUrl: this.state.imgUrl
        };
        const headers = getHeadersForHttpReq();

        axios.post(complete_URL+'/clients/register', data,{headers})
          .then(res => { /*console.log(res)*/
            if (res.data.success) {
              message.success(this.msg.ADDSUCCESS);
              this.props.history.push('/dashboard/clients');
            }
          })
          .catch(clt_err => {
            console.log('Error occured in adding the client->',clt_err)
            message.error(this.msg.ADDFAILURE,3);
          })
        /*console.log('Received values of form: ', values);*/
      }
    });
  }
  //Extra method, not being called.. 
  handleImageUpload = (e) => {
    e.preventDefault();
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      let file = e.currentTarget.files[0];
      let fileName = file.name;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (file.size <= 1024 * 1024 * 2 && /png|jpe?g/i.test(extension)) {
        let imagefile = file;
        let a = { ...this.state.imgUrl }
        a = URL.createObjectURL(imagefile)
        this.setState({ imgUrl: a })
      }
    }
  }

  componentDidMount(){ 
    this.setComponentDirection();
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/clients">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={14}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box"  span={5} style={{display:'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          {/* <Button type="danger"><Icon type="arrow-left" /></Button> */}
          <Link to="/dashboard/clients">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/clients">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to="/dashboard/clients">
            <Button style={{margin: '16px auto'}}  className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}}  className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let {dir, display } = this.state;
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              {/* <Row className="top-header"  gutter={24}>
                <Col span={8}>
                  <Link to="/dashboard/clients">
                    <Button type="danger"><Icon type="arrow-left" /></Button>
                  </Link>
                </Col>
                <Col span={8}>
                  <h1>Add Clients</h1>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
                  </Form.Item>
                </Col>
              </Row> */}
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: this.msg.INSERTNAMEERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.DESCRIPTION}>
                    {getFieldDecorator('description', {
                      rules: [{ required: false, message: this.msg.INSERTDESCERROR }],
                    })(
                      <Input type="Description" placeholder={this.lang.LABELS.DESCRIPTION} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.EMAIL}>
                    {getFieldDecorator('email', {
                      rules: [{ required: false, message: this.msg.INSERTEMAILERROR }],
                    })(
                      <Input type="Email" placeholder={this.lang.LABELS.EMAIL} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ADDRESS}>
                    {getFieldDecorator('address', {
                      rules: [{ required: false, message: this.msg.INSERTADDRESSERROR }],
                    })(
                      <Input type="Address" placeholder={this.lang.LABELS.ADDRESS} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.COMMISSION}>
                    {getFieldDecorator('commission', {
                      rules: [{ required: false, message: this.msg.INSERTCOMMAMOUNTERROR }],
                    })(
                      <InputNumber  min={0} max={100} style={{width:'100%'}} placeholder={this.lang.LABELS.COMMISSION} />

                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.ISCOMMISSION}>
                    {getFieldDecorator('iscommission', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Switch  placeholder={this.lang.LABELS.ISCOMMISSION} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    );
  }
}

export default AddClient = Form.create({ name: 'add_client' })(AddClient);