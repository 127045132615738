import React, { Component } from 'react'
import axios from 'axios'
import API_URL from '../../constants'
import { Input, Table, DatePicker } from 'antd'
import moment from 'moment'
const Search = Input.Search;
const { MonthPicker } = DatePicker
const pt = localStorage.getItem('pt');

class ClientAccountMonthWise extends Component{
    constructor(props){
        super(props)
        this.state = {
            reservations: [],
            pagination: {},
            page: 1,
            size: 5,
            date:"",
            sortField:'date',
            sortOrder:"DESC"
        }
        this.lang = this.props.language;
        this.EN_FLAG = localStorage.getItem('lang') === 'EN';
        this.columns = [
          {
            title: (<span>{this.lang.MONTH_WISE.TABLEHEADERS.DATE}</span> ),
            dataIndex: 'date',
            key: 'date',
            width: '23%',
            align :  this.EN_FLAG ? 'left' : 'right',
            sorter:true,
            // sorter: (a, b) => this.compareByAlph(a.name, b.name),
            render: (text, record) => <button className="link-button"
            // onClick={this.handleNavigation.bind(this, record)}
            >
            {text}
          </button>,
          },
          {
            title: ( <span>{this.lang.MONTH_WISE.TABLEHEADERS.NO}</span> ),
            dataIndex: 'no_res',
            key: 'no_res',
            width: '23%',
            align :  this.EN_FLAG ? 'left' : 'right',
            sorter:true,
            // sorter: (a, b) => this.compareByAlph(a.name, b.name),
            render: (text, record) => <button className="link-button"
            // onClick={this.handleNavigation.bind(this, record)}
            >
            {text}
          </button>,
          },
          {
            title: ( <span>{this.lang.MONTH_WISE.TABLEHEADERS.TOTAL_RENT} </span> ),
            dataIndex: 'total_rent',
            key: 'total_rent',
            width: '20%',
            align :  this.EN_FLAG ? 'left' : 'right',
            sorter:true,
            // sorter: (a, b) => this.compareByAlph(a.description, b.description),
          },
          {
            title: ( <span>{this.lang.MONTH_WISE.TABLEHEADERS.TOTAL} </span> ),
            dataIndex: 'total_amount',
            key: 'total_amount',
            width: '25%',
            align :  this.EN_FLAG ? 'left' : 'right',
            sorter:true,
            // sorter: (a, b) => this.compareByAlph(a.description, b.description),
          },
          // {
          //   title: (
          //     <span>{this.lang.MONTH_WISE.TABLEHEADERS.PAID}
              
          //     </span>
          //   ),
          //   dataIndex: 'pay_amount',
          //   key: 'pay_amount',
          //   width: '20%',
          //   sorter:true,
          //   // sorter: (a, b) => this.compareByAlph(a.email, b.email),
          // },
      ];
    }
    handleChangePagination = (pagination, filters, sorter) => {
        let pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          page: pager.current,
          pagination: pager,
        }, () => {
          this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
          });
        })
    }

    compareByAlph = (a, b) => {
        if (a > b) { return -1; }
        if (a < b) { return 1; }
        return 0;
    }

    componentDidMount(){
        this.fetch(); 
    }

    fetch  = (params={}) =>{
      let page,sortOrder
      if (params.sortOrder == 'ascend') {
        sortOrder = 'ASC'
      }
      else {
        sortOrder = 'DESC'
      }
      let sortColumn = params.sortField || this.state.sortField 
        axios.get(`${API_URL}${pt}/clientpayments/get_client_acount_month_wise/?client=${this.props.client}&page=${this.state.page}&size=${this.state.size}&date=${this.state.date}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`)
        .then(res => {
          let pagination = { ...this.state.pagination };
          pagination.total = res.data.totalElements;
          pagination.pageSize = parseInt(res.data.size);
            this.setState({reservations:res.data.selectedElements,pagination: pagination,
            })
        })
        .catch(err => {
            console.log(err)
        })
    }

    render(){
        return(
          <>
            <MonthPicker style={{marginBottom:'1%'}} onChange={(e) =>this.setState({
              date: moment(e).isValid() ? moment(e).format('MM-YYYY') : ""
              },() => this.fetch())} placeholder={this.lang.MONTH_WISE.SEARCH_DATE} 
            />
            
            <Table
                columns={this.columns}
                rowKey="id"
                dataSource={this.state.reservations}
                pagination={this.state.pagination}
                onChange={this.handleChangePagination}

              />
          </>
        )
    }
}

export default ClientAccountMonthWise;