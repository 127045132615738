import React, { Component } from 'react';
import { /* Table,*/ Row, Col, Form, Icon, Button, Input, Card, message, InputNumber, Select, Avatar, Collapse, DatePicker, Table, Spin } from 'antd'
import moment from 'moment';

import { Link } from 'react-router-dom';
import axios from 'axios';
import HallDiscount from '../HallDiscount';
import HallBookingPrice from '../HallBookingPrice';

import HallDocuments from '../HallDocuments';
import HallImages from '../HallImages';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
const Panel = Collapse.Panel;

if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class HallUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hall_type: [],
      uom: [],
      days: [],
      banquets: [],
      uop: [],
      hall: {},
      img: '',
      discountList: false,
      priceList: false,
      startValue: null,
      discounts: [],
      prices: [],
      updateHallDiscount: '',
      updateHallPrice: '',
      endValue: null,
      endOpen: false,
      dir : 'ltr', 
      display: 'block',
      adiscount_spin : false,
      udiscount_spin : false,
      aprice_spin : false,
      uprice_spin : false,
    }
    this.lang = props.language._HALL.UPDATE;
    this.msg = props.language._HALL.MESSAGE;

    this.HallDiscountTableColumns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.FROMDATE}`,
        dataIndex: 'from_date',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      } :
      {
        title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.FROMDATE}`,
        dataIndex: 'from_date',
        align : 'right',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      } ,
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.TODATE}`,
        dataIndex: 'to_date',
      } :
      {
        title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.TODATE}`,
        dataIndex: 'to_date',
        align : 'right',
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.DISCOUNT}`,
        dataIndex: 'discount',
      } :
      {
        title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.DISCOUNT}`,
        dataIndex: 'discount',
        align : 'right',
      },
      {
        title: `${this.lang.HALLDISCOUNT.SETUP.LABELS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.HALLDISCOUNT.SETUP.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '25%',
        align : 'center',
        key: 'last_name',
      }
    ]

    this.colms_price = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.PRICE}`,
        dataIndex: 'price',
        width: '18%',
        align : 'center',
        render: (text, record) =><div style={{textAlign:'center'}}> <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button></div>,
      }:
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.PRICE}`,
        dataIndex: 'price',
        align : 'right',
        width: '27%',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.UOM}`,
        dataIndex: 'uop_name',
        width: '30%',
        align : 'center',
        render: (text, record) =><div style={{textAlign:'center'}}><button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button></div> ,
      } :
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.UOM}`,
        dataIndex: 'uop_name_ar',
        align : 'right',
        width: '27%',
        render: (text, record) =><div style={{textAlign:'center',paddingLeft : '40%'}}><button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>{text}</button></div>,
      } ,
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.DAY}`,
        dataIndex: 'day_name',
        width: '30%',
        align : 'center',
        render: (text, record) =><div style={{textAlign:'center'}}><button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>
           { text
            //  this.state.days.filter( item => item.id == text)[0].name
             }
          </button></div>,
      } :
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.DAY}`,
        dataIndex: 'day_name_ar',
        width: '27%',
        align : 'right',
        render: (text, record) => <button className="link-button" onClick={this.handlePriceNavigation.bind(this, record)}>
           { text
            //  this.state.days.filter( item => item.id == text)[0].name
             }
          </button>,
      } ,
      {
        title: `${this.lang.HALLPRICE.SETUP.LABELS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deletePrice.bind(this, record)}>{this.lang.HALLPRICE.SETUP.BUTTON.DELETE}</Button>
          </span>
        ),
        width: '22%',
        align : 'center',
        key: 'last_name',
      }
    ]
  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  deletePrice = (record) => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/hallprices/${record.id}`,{headers})
      .then(res => {
        message.success(this.msg.HALLPRICEDELETESUCCESS ,2);
        this.fetch()
      })
      .catch(err => { 
        console.log('Error occured while deleting the hall price->',err);
        message.error(this.msg.HALLPRICEDELETEFAILURE ,2);
      });
  }

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/halldiscounts/${record.id}`,{headers})
      .then(res => {
        message.success(this.msg.HALLDISCOUNTDELETESUCCESS ,2);
        this.fetch()
      })
      .catch(err => { 
        console.log('Error occured while deleting the hall Discount->',err);
        message.error(this.msg.HALLDISCOUNTDELETEFAILURE ,2); 
      });
  }

  handleNavigation = (record) => {
    let data = {
      id: record.id,
      hall_id: record.hall_id,
      created_by: record.created_by,
      updated_by: record.updated_by,
      discount: record.discount,
      from_date: moment(record.from_date),
      to_date: moment(record.to_date),
    }
    // console.log(record)
    this.setState({ updateHallDiscount: data, discountList: 2 })
  }

  handlePriceNavigation = (record) => {
    let data = {
      id: record.id,
      hall_id: record.hall_id,
      uop: record.uop,
      // created_by: record.created_by,
      // updated_by: record.updated_by,
      price: record.price,
      day: record.day,
      day_name : record.day_name,
      day_name_ar : record.day_name_ar
    }
    // console.log(record)

    this.setState({ updateHallPrice: data, priceList: 2 })
  }

  componentDidMount() {
    this.fetch();
    this.setComponentDirection();
  }
  
  getHallDataByParameter =(key)=>{ 
    const headers = getHeadersForHttpReq();
    if (key) {
      axios.get(`${complete_URL}/parameters/${key}`, {headers})
      .then(result => { //console.log(result)
        this.setState({ [key] : result.data.data[0] })
      })
      .catch(err => { console.log('Error occured while fetching '+key,err) })
    }
  }//End of Method

  getBanquetsByClient=()=>{
    const client_id = localStorage.getItem('client_id');
    const headers =  getHeadersForHttpReq();
    axios.get(`${complete_URL}/banquets/by_clients/${client_id}`,{headers})
      .then(result => { /*console.log(result.data.data[0])*/
        this.setState({ banquets: result.data.data[0] })
      })
      .catch(err => { console.log('Error occured while fetching the Banquets->',err); });
  }

  fetch = () => {
    this.setState({ hall: JSON.parse(localStorage.getItem('hallToUpdate')) },()=>{
      if (this.state.hall.hasOwnProperty('id')) {
        this.getHallPrices(this.state.hall.id);
        this.getHallDiscounts(this.state.hall.id);
      }
      if (this.state.hall.hasOwnProperty('image_id')) {
        this.getHallImage(this.state.hall.image_id);
      }
      this.getBanquetsByClient();
      this.getHallDataByParameter('uom');  
      this.getHallDataByParameter('hall_type');  
      this.getHallDataByParameter('uop');
      this.getHallBookingDays();
      // const hall_image_id =  JSON.parse(localStorage.getItem('hallToUpdate')).image_id;
      // this.getHallImage(hall_image_id);
      // const hall_id = JSON.parse(localStorage.getItem('hallToUpdate')).id;
      // this.getHallDiscounts(hall_id);
      // this.getHallPrices(hall_id);
    });

    /*axios.get(`${complete_URL}/banquets/by_clients/${client_id}`,{headers})
      .then(result => { //console.log(result.data.data[0])
        this.setState({ banquets: result.data.data[0] })
      })
      .catch(err => { console.log(err) })

    axios.get(`${complete_URL}/parameters/uom`, {headers})
      .then(result => { //console.log(result)
        this.setState({ uom: result.data.data[0] })
      })
      .catch(err => { console.log(err) })*/
        
    

    /*axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/HALL_PRICE_DAY`,{headers})
      .then(result => { //console.log('days',result.data.data)
        this.setState({ days: result.data.data})
      })
      .catch(err => {  console.log(err) })*/

    /*axios.get(`${complete_URL}/parameters/hall_type`,{headers})
      .then(result => { //console.log(result)
        this.setState({ hall_type: result.data.data[0] })
      })
      .catch(err => { console.log(err) })*/

    /*axios.get(`${complete_URL}/parameters/uop`,{headers})
      .then(result => { //console.log(result)
        this.setState({ uop: result.data.data[0] })
      })
      .catch(err => { console.log(err) })*/
    

    /*axios.get(`${complete_URL}/images/${hall_image_id}`, 
    {       'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
      responseType: 'blob' })
      .then(res => {
        let a
        var reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          a = reader.result
        }
        setTimeout(() => {
          this.setState({ img: a })
        }, 500)

      })
      .catch(err => { console.log(err) }) */

    // console.log(this.state.hall)

    /*axios.get(`${complete_URL}/halldiscounts/${JSON.parse(localStorage.getItem('hallToUpdate')).id}`,{headers})
      .then(res => { //console.log(res.data.data[0])
        this.setState({ discounts: res.data.data[0] })
      })
      .catch(err => { console.log(err) })*/

    //  console.log(JSON.parse(localStorage.getItem('hallToUpdate')).id)
    

    /*axios.get(`${complete_URL}/hallprices/${JSON.parse(localStorage.getItem('hallToUpdate')).id}`,{headers})
      .then(res => { //console.log('prices',res.data.data[0])
        this.setState({ prices: res.data.data[0] })
      })
      .catch(err => { console.log(err) })*/
  }
  //New Child component 'HallBookingPrice' is created so this will not be called
  getHallPrices =(hall_id)=>{ 
    const headers = getHeadersForHttpReq();
    if (hall_id) {
      axios.get(`${complete_URL}/hallprices/${hall_id}`,{headers})
      .then(res => { //console.log('prices',res.data.data[0])
        this.setState({ prices: res.data.data[0] });
      })
      .catch(err => { console.log('Error occured while fetching HallPrices->',err) });
    }
  }//End of Method

  //New Child component 'HallDiscount' is created so this will not be called
  getHallDiscounts =(hall_id)=>{ 
    const headers = getHeadersForHttpReq();
    if (hall_id) {
      axios.get(`${complete_URL}/halldiscounts/${hall_id}`,{headers})
      .then(res => { //console.log('prices',res.data.data[0])
        this.setState({ discounts: res.data.data[0] })
      })
      .catch(err => { console.log('Error occured while fetching Halldiscounts->',err) })
    }
  }//End of Method

  //New Child component 'HallBookingPrice' is created so this will not be called
  getHallBookingDays =()=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/HALL_PRICE_DAY`,{headers})
    .then(result => { //console.log('days',result.data.data)
      this.setState({ days: result.data.data})
    })
    .catch(err => {  console.log('Error occured while fetching Hall booking days ->',err) })
  }//End of Method

  getHallImage =(hall_image_id)=>{
    // console.log('hall_image_id ->',hall_image_id);
    if (hall_image_id) { 
      axios.get(`${complete_URL}/images/${hall_image_id}`, 
        {  'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
            responseType : 'blob' 
        })
          .then(res => {
            let a;
            let reader = new FileReader();
            reader.readAsDataURL(res.data);
            reader.onloadend = () => {
              a = reader.result
            }
            setTimeout(() => {
              this.setState({ img: a })
            }, 500)

          })
          .catch(err => { console.log('Error occured while fetching HallImage ->',err) })
    }
  }//End of Method

  disabledStartDate = (startValue) => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  }

  disabledEndDate = (endValue) => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  }

  onChange = (field, value) => {
    this.setState({ [field]: value });
  }

  onStartChange = (value) => {
    this.onChange('startValue', value);
  }

  onEndChange = (value) => {
    this.onChange('endValue', value);
  }

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  }

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  }

  handleImageUpload = (e) => {
    e.preventDefault();
    // console.log(e)
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      var file = e.currentTarget.files[0];
      let fileName = file.name;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (file.size <= 1024 * 1024 * 2 && /png|jpe?g/i.test(extension)) {
        var a;
        let imgdata = new FormData();
        imgdata.append('file', file)
        imgdata.append('client_id', localStorage.getItem('client_id'))
        imgdata.append('hall_id', JSON.parse(localStorage.getItem('hallToUpdate')).id)
        const headers = {
          'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
          'Content-Type': 'multipart/form-data'
        }
        let thiss = this
        axios.post(`${complete_URL}/images/hall/add`, imgdata, { responseType: 'blob' }, headers)
          .then(res => {
            let reader = new FileReader();
            reader.readAsDataURL(res.data);
            reader.onloadend = () => {
              a = reader.result
              thiss.setState({ img: a });
            }
            message.success(this.msg.HALLIMAGEADDSUCCESS ,2)
          })
          .catch(err => { 
            console.log('Error occured while uploading the Hall Image->',err);
            message.success(this.msg.HALLIMAGEADDFAILURE ,2)
          })

        setTimeout(() => {
          this.setState({ img: a })
          // console.log(a)
        }, 800)
      }
      else{
        message.error(this.lang.HALLUPDATE.LABELS.FILEUPLOAD ,2);
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.user_id = localStorage.getItem('user_id');
        values.id = JSON.parse(localStorage.getItem('hallToUpdate')).id
        console.log('values ->',values);
        const headers = getHeadersForHttpReq();
        
        axios.put(`${complete_URL}/halls/${values.id}`, values, {headers})
          .then(res => {//console.log('Hall update res.data ->',res.data);
            if (res.data.success) {
              message.success(this.msg.HALLUPDATESUCCESS,2);
              this.props.history.push('halls');
            }
            else{
              message.error(this.msg.HALLUPDATEFAILURE,2);
            }
          })
          .catch(err => {
            console.log('Error occured while updating the Hall record->',err);
            message.error(this.msg.HALLUPDATEFAILURE,2);
          });
      }
    });
  }
  //New Child component 'HallDiscount' is created so this will not be called
  handleSubmitHallDiscount = (e) => {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id')
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({adiscount_spin : true});
        let newValues = {}
        newValues.from_date = values.from_date.toDate().toString().split('G')[0]
        newValues.to_date = values.to_date.toDate().toString().split('G')[0]
        newValues.hall_id = this.state.hall.id
        newValues.user_id = user_id
        newValues.discount = values.discount
        // console.log(newValues)
        let self = this
        const headers = getHeadersForHttpReq();
        
        axios.post(`${complete_URL}/halldiscounts/add`, newValues,{headers})
          .then(res => {
            if (res.data.success) {
              self.setState({ adiscount_spin : false, discountList: false, },()=>{
                message.success(this.msg.HALLDISCOUNTADDSUCCESS,2);
                this.fetch();
              })
            }
            else{
              self.setState({ adiscount_spin : false,},()=>{
                message.error(this.msg.HALLDISCOUNTADDFAILURE,2);
              })
            }
          })
          .catch(err => {
            console.log('Error occured while adding the Discount in the hall->',err);
            self.setState({ adiscount_spin : false },()=>{
              message.error(this.msg.HALLDISCOUNTADDFAILURE,2);
            })
          });
      }
    })
  }
  //New Child component 'HallBookingPrice' is created so this will not be called
  handleSubmitHallPrice = (e) => {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id')
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({aprice_spin : true});
        let newValues = {}
        newValues.price = values.rice
        newValues.uop = values.uop
        newValues.day = values.day
        newValues.hall_id = this.state.hall.id
        newValues.user_id = user_id
        // console.log(newValues)
        let self = this
        const headers = getHeadersForHttpReq();
        
        axios.post(`${complete_URL}/hallprices/add`, newValues,{headers})
          .then(res => {
            if (res.data.success) {
              self.setState({ aprice_spin : false, priceList: false, },()=>{
                message.success(this.msg.HALLPRICEADDSUCCESS,2);
                this.fetch();
              })
            }
            else{
              self.setState({ aprice_spin : false,},()=>{
                message.error(this.msg.HALLPRICEADDFAILURE,2);
              })
            }
          })
          .catch(err => {
            console.log('Error occured while adding the Price in the hall->',err);
            self.setState({ aprice_spin : false,},()=>{
              message.error(this.msg.HALLPRICEADDFAILURE,2);
            });
          });
      }
    });
  }
  //New Child component 'HallDiscount' is created so this will not be called
  handleSubmitHallDiscountUpdate = (e) => {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id')
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({udiscount_spin : true});
        let newValues = {}
        newValues.from_date = values.from_date.toDate().toString().split('G')[0]
        newValues.to_date = values.to_date.toDate().toString().split('G')[0]
        newValues.hall_id = this.state.hall.id
        newValues.user_id = user_id
        newValues.discount = values.discount
        // console.log(this.state.updateHallDiscount.id)
        let self = this
        const headers = getHeadersForHttpReq();
        
        axios.put(`${complete_URL}/halldiscounts/update/${this.state.updateHallDiscount.id}`, newValues, {headers})
          .then(res => {
            if (res.data.success) {
              self.setState({ udiscount_spin : false, discountList: false, },()=>{
                message.success(this.msg.HALLDISCOUNTUPDATESUCCESS,2);
                this.fetch();
              })
            }
            else{
              self.setState({ udiscount_spin : false,},()=>{
                message.error(this.msg.HALLDISCOUNTUPDATEFAILURE,2);
              });
            }
          })
          .catch(err => {
            console.log('Error occured while updating the Discount in the hall->',err);
            self.setState({ udiscount_spin : false },()=>{
              message.error(this.msg.HALLDISCOUNTUPDATEFAILURE,2);
            });
          });
      }
    })
  }
  //New Child component 'HallBookingPrice' is created so this will not be called
  handleSubmitHallPriceUpdate = (e) => {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id')
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({uprice_spin : true});
        let newValues = {}
        newValues.hall_id = this.state.hall.id
        newValues.user_id = user_id
        newValues.uop = values.uop
        newValues.day = values.day
        newValues.price = values.rice
        // console.log(this.state.updateHallPrice.id)
        let self = this
        const headers = getHeadersForHttpReq();
        
        axios.put(`${complete_URL}/hallprices/update/${this.state.updateHallPrice.id}`, newValues , {headers})
          .then(res => {
            if (res.data.success) {
              self.setState({ uprice_spin : false, priceList: false, },()=>{
                message.success(this.msg.HALLPRICEUPDATESUCCESS,2);
                this.fetch();
              })
            }
            else{
              self.setState({ uprice_spin : false,},()=>{
                message.error(this.msg.HALLPRICEUPDATEFAILURE,2);
              });
            }
          })
          .catch(err => {
            console.log('Error occured while updating the Price in the hall->',err);
            self.setState({ uprice_spin : false,},()=>{
              message.error(this.msg.HALLPRICEUPDATEFAILURE,2);
            });
          });
      }
    })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/halls">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={12}><h1 style={{textAlign: 'center'}}>{this.lang.HALLUPDATE.LABELS.TITLE}</h1></Col>
        <Col className="btn-box" span={6} style={{display: 'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/halls">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>

      this.CreateHallDiscountHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ discountList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.HALLDISCOUNT.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.HallDiscountTableHeader = <Row id="clientTableHeader">
        <Col span={10}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.HALLDISCOUNT.SETUP.LABELS.TITLE}</p></Col>
        <Col span={13}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ discountList: true }) }}>{this.lang.HALLDISCOUNT.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateHallDiscountHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ discountList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.HALLDISCOUNT.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.CreateHallPriceHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ priceList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.HALLPRICE.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.HallPriceTableHeader = <Row id="clientTableHeader">
        <Col span={9}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.HALLPRICE.SETUP.LABELS.TITLE}</p></Col>
        <Col span={14}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ priceList: true }) }}>{this.lang.HALLPRICE.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateHallPriceHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ priceList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.HALLPRICE.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4} >
          <Link to="/dashboard/halls">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}><h1 style={{textAlign: 'center'}}>{this.lang.HALLUPDATE.LABELS.TITLE}</h1></Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to="/dashboard/halls">
            <Button  style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>

      this.CreateHallDiscountHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ discountList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.HALLDISCOUNT.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.HallDiscountTableHeader = <Row id="clientTableHeader">
        <Col span={8}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ discountList: true }) }}>{this.lang.HALLDISCOUNT.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={15}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.HALLDISCOUNT.SETUP.LABELS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateHallDiscountHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ discountList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.HALLDISCOUNT.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.CreateHallPriceHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ priceList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ fontSize: '24px' }}>{this.lang.HALLPRICE.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.HallPriceTableHeader = <Row id="clientTableHeader">
        <Col span={8}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ priceList: true }) }}>{this.lang.HALLPRICE.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={15}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.HALLPRICE.SETUP.LABELS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.UpdateHallPriceHeader = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ priceList: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}>{this.lang.HALLPRICE.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let { dir, display, banquets, hall_type, uom,
      startValue, endValue, endOpen } = this.state;

    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              <Row gutter = {24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.NAME}                             >
                    {getFieldDecorator('name', {
                      initialValue: this.state.hall.name,
                      rules: [{ required: true, message: this.msg.INSERTNAMEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.HALLUPDATE.LABELS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      initialValue: this.state.hall.name_ar,
                      rules: [{ required: true, message: this.msg.INSERTNAMEARERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.HALLUPDATE.LABELS.NAMEar} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter = {24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.SELECTBANQUET}>
                    {getFieldDecorator('banquet_id', {
                      initialValue: this.state.hall.banquet_id,
                      rules: [{ required: true, message:  this.msg.SELECTBANQUETERROR }],
                    })(
                      <Select onChange={this.seclectPrend} disabled={this.state.is_parent}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            banquets.length > 0 && banquets.map(banquet => (
                              <Select.Option key={banquet.id} value={banquet.id}>{banquet.name}</Select.Option>
                            ))
                            :
                            banquets.length > 0 && banquets.map(banquet => (
                              <Select.Option key={banquet.id} value={banquet.id}>{banquet.name_ar}</Select.Option>
                            ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.CHAIR}>
                    {getFieldDecorator('chairs_cnt', {
                      initialValue: this.state.hall.chairs_cnt,
                      rules: [{ required: true, message: this.msg.INSERTCHAIRSERROR }],
                    })(
                      <InputNumber step={0.1} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter = {24} dir= {dir} style={{display}}>
                <Col span={8}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.CAPACITY}>
                    {getFieldDecorator('capacity', {
                      initialValue: this.state.hall.capacity,
                      rules: [{ required: true, message:  this.msg.INSERTCAPICITYERROR }],
                    })(
                      <InputNumber step={0.1} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.SECURITYDEPOSITE}>
                    {getFieldDecorator('sec_dep', {
                      initialValue: this.state.hall.sec_dep,
                      rules: [{ required: true, message: this.msg.INSERTSECDEPOSITEERROR }],
                    })(
                      <InputNumber step={0.1} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.EXPDAYS}>
                    {getFieldDecorator('exp_days', {
                      initialValue: this.state.hall.exp_days,
                      rules: [{ required: true, message: this.msg.INSERTEXPDAYSERROR }],
                    })(
                      <InputNumber placeholder={this.lang.HALLUPDATE.LABELS.EXPDAYS} min={1} max={10000000000} step={1} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter = {24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.HALLTYPE}>
                    {getFieldDecorator('hall_type', {
                      initialValue: this.state.hall.hall_type,
                      rules: [{ required: true, message: this.msg.SELECTHALLTYPEERROR }],
                    })(
                      <Select onChange={this.seclectPrend} disabled={this.state.is_parent}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            hall_type.length > 0 && hall_type.map(type => (
                              <Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>
                            ))
                            : hall_type.length > 0 && hall_type.map(type => (
                              <Select.Option style={{textAlign:'right'}} key={type.id} value={type.id}>{type.name_ar}</Select.Option>
                            ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.UOM}>
                    {getFieldDecorator('uom', {
                      initialValue: this.state.hall.uom,
                      rules: [{ required: true, message: this.msg.SELECTUOPERROR }],
                    })(
                      <Select onChange={this.seclectPrend} disabled={this.state.is_parent}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            uom.length > 0 && uom.map(uom_item => (
                              <Select.Option key={uom_item.id} value={uom_item.id}>{uom_item.name}</Select.Option>
                            ))
                            : uom.length > 0 && uom.map(uom_item => (
                              <Select.Option style={{textAlign:'right'}} key={uom_item.id} value={uom_item.id}>{uom_item.name_ar}</Select.Option>
                            ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter = {24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.AREA}>
                    {getFieldDecorator('area', {
                      initialValue: this.state.hall.area,
                      rules: [{ required: true, message: this.msg.INSERTAREAERROR }],
                    })(
                      <InputNumber step={0.1} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.DEADLINE}>
                    {getFieldDecorator('deadline', {
                      initialValue: this.state.hall.deadline,
                      rules: [{ required: true, message: this.msg.INSERTDEADLINESERROR }],
                    })(
                      <InputNumber placeholder={this.lang.HALLUPDATE.LABELS.DEADLINE} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.DESCRIPTION} >
                    {getFieldDecorator('hall_desc', {
                      initialValue: this.state.hall.hall_desc,
                      rules: [{ required: false, message: '' }],
                    })(
                      <Input.TextArea placeholder={this.lang.HALLUPDATE.LABELS.DESCRIPTION} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.HALLUPDATE.LABELS.DESCRIPTIONAR}>
                    {getFieldDecorator('hall_desc_ar', {
                      initialValue: this.state.hall.hall_desc_ar,
                      rules: [{ required: false, message: '' }],
                    })(
                      <Input.TextArea  dir = {'rtl'} placeholder={this.lang.HALLUPDATE.LABELS.DESCRIPTIONAR} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter = {24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <p>{this.lang.HALLUPDATE.LABELS.FILEUPLOAD}</p>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    {getFieldDecorator('file', {
                      rules: [{ required: false, message: this.msg.UPLOADHALLIMAGE }],
                    })(
                      <div>
                        <Row gutter={24}>
                          <Col span={7}></Col>
                          <Col span={10}>
                            <img size={80} style={{width:'80%'}} src={ this.state.img || '/dummy_image.jpg'} alt="Image here" />
                          </Col>
                          <Col span={7}></Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={7}></Col>
                          <Col span={10}>
                            <Input type="file" style={{width: '35% !important'}} id="files" className="file" onChange={this.handleImageUpload} />
                          </Col>
                          <Col span={7}></Col>
                        </Row>
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Collapse accordion
              expandIconPosition={dir ==='ltr' ? 'left':'right'}
              onChange={(e) => {
                const link = document.createElement('a');
                if (parseInt(e) === 1) {
                  link.href = '#halldiscount';
                  // console.log(link)
                }
                else if (parseInt(e) === 2) {
                  link.href = '#hallprice';
                  // console.log(link)
                }
                else if (parseInt(e) === 3) {
                  link.href = '#halldoc';
                  // console.log(link)
                }
                document.body.appendChild(link);
                link.click();
              }}
              bordered={false}
              expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
              <Panel header={this.lang.HALLDISCOUNT.TITLE} key="1" id='halldiscount'>
                <HallDiscount lang={ this.lang} msg ={this.msg} hall_id={this.state.hall.id} {...this.props} />
                {
                  /*
                  //Create Hall Discount Screen
                  this.state.discountList === true ?
                    <Spin tip={this.msg.HALLDISCOUNTADDTIP} spinning = {this.state.adiscount_spin}>
                    <Form onSubmit={this.handleSubmitHallDiscount} style={{ marginTop: '20px' }}>
                      {this.CreateHallDiscountHeader}
                      <Row gutter={24} dir= {dir} style={{display}}>
                        <Col span={8}>
                          <Form.Item label={this.lang.HALLDISCOUNT.CREATE.LABELS.FROMDATE}                             >
                            {getFieldDecorator('from_date', {
                              setFieldValue: startValue,
                              rules: [{ required: true, message: this.msg.INSERTDISCOUNTFROMDATEERROR }],
                            })(
                              <DatePicker
                                disabledDate={this.disabledStartDate}
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder={this.lang.HALLDISCOUNT.CREATE.LABELS.FROMDATE}
                                onChange={this.onStartChange}
                                onOpenChange={this.handleStartOpenChange}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label={this.lang.HALLDISCOUNT.CREATE.LABELS.TODATE}>
                            {getFieldDecorator('to_date', {
                              setFieldValue: endValue,
                              rules: [{ required: true, message: this.msg.INSERTDISCOUNTTODATEERROR }],
                            })(
                              <DatePicker
                                disabledDate={this.disabledEndDate}
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder={this.lang.HALLDISCOUNT.CREATE.LABELS.TODATE}
                                onChange={this.onEndChange}
                                open={endOpen}
                                onOpenChange={this.handleEndOpenChange}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label={this.lang.HALLDISCOUNT.CREATE.LABELS.DISCOUNT}>

                            {getFieldDecorator('discount', {
                              setFieldValue: endValue,
                              rules: [{ required: true, message: this.msg.INSERTDISCOUNTERROR}],
                            })(
                              <InputNumber placeholder={this.lang.HALLDISCOUNT.CREATE.LABELS.DISCOUNT } style={{ width: '100%' }} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                    </Spin>
                    : this.state.discountList === false ?
                      //View Hall Discount Screen
                      <div>
                        {this.HallDiscountTableHeader}
                        <Row id="clientsTable">
                          
                          <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                            <Table columns={this.HallDiscountTableColumns} rowKey="id" dataSource={this.state.discounts} style={{ marginTop: '10px' }} />,
                          </Col>
                        </Row>
                      </div>
                      //Update Hall Discount Screen
                      :
                      <Spin tip={this.msg.HALLDISCOUNTUPDATETIP} spinning = {this.state.udiscount_spin}>
                      <Form onSubmit={this.handleSubmitHallDiscountUpdate.bind(this)} style={{ marginTop: '20px' }}>
                        {this.UpdateHallDiscountHeader}
                        <Row gutter={24} dir= {dir} style={{display}}>
                          <Col span={8}>
                            <Form.Item label={this.lang.HALLDISCOUNT.SETUP.LABELS.FROMDATE} >
                              {getFieldDecorator('from_date', {
                                initialValue: this.state.updateHallDiscount.from_date,
                                setFieldValue: startValue,
                                rules: [{ required: true, message: this.msg.INSERTDISCOUNTFROMDATEERROR }],
                              })(
                                <DatePicker
                                  disabledDate={this.disabledStartDate}
                                  showTime
                                  format="YYYY-MM-DD HH:mm:ss"
                                  placeholder={this.lang.HALLDISCOUNT.SETUP.LABELS.FROMDATE}
                                  onChange={this.onStartChange}
                                  onOpenChange={this.handleStartOpenChange}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label={this.lang.HALLDISCOUNT.SETUP.LABELS.TODATE}>
                              {getFieldDecorator('to_date', {
                                initialValue: this.state.updateHallDiscount.to_date,
                                setFieldValue: endValue,
                                rules: [{ required: true, message: this.msg.INSERTDISCOUNTTODATEERROR }],
                              })(
                                <DatePicker
                                  disabledDate={this.disabledEndDate}
                                  showTime
                                  format="YYYY-MM-DD HH:mm:ss"
                                  placeholder={this.lang.HALLDISCOUNT.SETUP.LABELS.TODATE}
                                  onChange={this.onEndChange}
                                  open={endOpen}
                                  onOpenChange={this.handleEndOpenChange}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label={this.lang.HALLDISCOUNT.SETUP.LABELS.DISCOUNT}>
                              {getFieldDecorator('discount', {
                                initialValue: this.state.updateHallDiscount.discount,
                                setFieldValue: endValue,
                                rules: [{ required: true, message: this.msg.INSERTDISCOUNTERROR }],
                              })(
                                <InputNumber placeholder = {this.lang.HALLDISCOUNT.SETUP.LABELS.DISCOUNT} style={{ width: '100%' }} />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                */
                }
              </Panel>
              <Panel header={this.lang.HALLPRICE.TITLE} key="2" id="hallprice">
                <HallBookingPrice  lang={ this.lang} msg ={this.msg} hall_id={this.state.hall.id} {...this.props} />

                {
                  //Create Hall Price Screen
                  // this.state.priceList === true ?
                  // <Spin tip={this.msg.HALLPRICEADDTIP} spinning = {this.state.aprice_spin}>
                  //   <Form onSubmit={this.handleSubmitHallPrice} style={{ marginTop: '20px' }}>
                  //     {this.CreateHallPriceHeader}
                  //     <Row gutter={24} dir= {dir} style={{display}}>
                  //       <Col span={8}>
                  //         <Form.Item label={this.lang.HALLPRICE.CREATE.LABELS.PRICE}>
                  //           {getFieldDecorator('rice', {
                  //             rules: [{ required: true, message: this.msg.INSERTPRICEERROR }],
                  //           })(
                  //             <InputNumber step={0.1} placeholder= {this.lang.HALLPRICE.CREATE.LABELS.PRICE} style={{ width: '100%' }} />
                  //           )}
                  //         </Form.Item>
                  //       </Col>
                  //       <Col span={8}>
                  //         <Form.Item label={this.lang.HALLPRICE.CREATE.LABELS.UOM}>
                  //           {getFieldDecorator('uop', {
                  //             rules: [{ required: true, message: this.msg.SELECTUOPERROR }],
                  //           })(
                  //             <Select onChange={this.seclectPrend} disabled={this.state.is_parent}>
                  //               {
                  //                 (localStorage.getItem('lang') === 'EN') ?
                  //                   this.state.uop.map(uop_item => (
                  //                     <Select.Option key={uop_item.id} value={uop_item.id}>{uop_item.name}</Select.Option>
                  //                   ))
                  //                   : this.state.uop.map(uop_item => (
                  //                     <Select.Option style={{textAlign:'right'}} key={uop_item.id} value={uop_item.id}>{uop_item.name_ar}</Select.Option>
                  //                   ))
                  //               }
                  //             </Select>
                  //           )}
                  //         </Form.Item>
                  //       </Col>
                  //       <Col span={8}>
                  //         <Form.Item label= {this.lang.HALLPRICE.CREATE.LABELS.DAY}>
                  //           {getFieldDecorator('day', {
                  //             rules: [{ required: true, message: this.msg.SELECTDAYERROR }],
                  //           })(
                  //             <Select onChange={this.seclectPrend} disabled={this.state.is_parent} placeholder= {this.lang.HALLPRICE.CREATE.LABELS.DAY}>
                  //               {
                  //                 (localStorage.getItem('lang') === 'EN') ?
                  //                   this.state.days.map(day_item => (
                  //                     <Select.Option key={day_item.id} value={day_item.id}>{day_item.name}</Select.Option>
                  //                   ))
                  //                   : this.state.days.map(day_item => (
                  //                     <Select.Option style={{textAlign:'right'}} key={day_item.id} value={day_item.id}>{day_item.name_ar}</Select.Option>
                  //                   ))
                  //               }
                  //             </Select>
                  //           )}
                  //         </Form.Item>
                  //       </Col>
                  //     </Row>
                  //   </Form>
                  //   </Spin>
                  //   : this.state.priceList === false ?
                  //     //View Hall Price Screen
                  //     <div>
                  //       {this.HallPriceTableHeader}
                  //       <Row id="clientsTable">
                  //         <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                  //           <Table columns={this.colms_price} rowKey="id" dataSource={this.state.prices} style={{ marginTop: '10px' }} />,
                  //         </Col>
                  //       </Row>
                  //     </div>
                  //     //Update Hall Price Screen
                  //     :
                  //     <Spin tip={this.msg.HALLPRICEUPDATETIP} spinning = {this.state.uprice_spin}>
                  //     <Form onSubmit={this.handleSubmitHallPriceUpdate.bind(this)} style={{ marginTop: '20px' }}>
                  //       {this.UpdateHallPriceHeader}
                  //       <Row gutter={24} dir= {dir} style={{display}}>
                  //         <Col span={8}>
                  //           <Form.Item label={this.lang.HALLPRICE.UPDATE.LABELS.PRICE}>
                  //             {getFieldDecorator('rice', {
                  //               initialValue: this.state.updateHallPrice.price,
                  //               rules: [{ required: true, message: this.msg.INSERTPRICEERROR }],
                  //             })(
                  //               <InputNumber placeholder={this.lang.HALLPRICE.UPDATE.LABELS.PRICE} style={{ width: '100%' }} />
                  //             )}
                  //           </Form.Item>
                  //         </Col>
                  //         <Col span={8}>
                  //           <Form.Item label={this.lang.HALLPRICE.UPDATE.LABELS.UOM}>
                  //             {getFieldDecorator('uop', {
                  //               initialValue: this.state.updateHallPrice.uop,
                  //               rules: [{ required: true, message: this.msg.SELECTUOPERROR }],
                  //             })(
                  //               <Select onChange={this.seclectPrend} disabled={this.state.is_parent}>
                  //                 {
                  //                   (localStorage.getItem('lang') === 'EN') ?
                  //                     this.state.uop.map(uop_item => (
                  //                       <Select.Option key={uop_item.id} value={uop_item.id}>{uop_item.name}</Select.Option>
                  //                     ))
                  //                     : this.state.uop.map(uop_item => (
                  //                       <Select.Option style={{textAlign:'right'}} key={uop_item.id} value={uop_item.id}>{uop_item.name_ar}</Select.Option>
                  //                     ))
                  //                 }
                  //               </Select>
                  //             )}
                  //           </Form.Item>
                  //         </Col>
                  //         <Col span={8}>
                  //         <Form.Item label={this.lang.HALLPRICE.UPDATE.LABELS.DAY}>
                  //           {getFieldDecorator('day', {
                  //             initialValue: this.state.updateHallPrice.day,
                  //             rules: [{ required: true, message: this.msg.SELECTDAYERROR }],
                  //           })(
                  //             <Select onChange={this.seclectPrend} disabled={this.state.is_parent}>
                  //               {
                  //                 (localStorage.getItem('lang') === 'EN') ?
                  //                   this.state.days.map(day_item => (
                  //                     <Select.Option key={day_item.id} value={day_item.id}>{day_item.name}</Select.Option>
                  //                   ))
                  //                   : this.state.days.map(day_item => (
                  //                     <Select.Option style={{textAlign:'right'}}  key={day_item.id} value={day_item.id}>{day_item.name_ar}</Select.Option>
                  //                   ))
                  //               }
                  //             </Select>
                  //           )}
                  //         </Form.Item>
                  //       </Col>
                  //       </Row>
                  //     </Form>
                  //   </Spin>
                }
              </Panel>
              <Panel header = {this.lang.HALLDOCUMENT.TITLE} key="3" id="hallDocument">
                <HallDocuments hall_id={this.state.hall.id} {...this.props} />
              </Panel>
              <Panel header = {this.lang.HALLIMAGE.TITLE} key = '4' id = "hallImage">
                <HallImages hall_id={this.state.hall.id} {...this.props}/>
              </Panel>
            </Collapse>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row >
    )
  }
}

export default HallUpdate = Form.create({ name: 'hall_update_form' })(HallUpdate);