import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Input, Card, message } from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class ModuleAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      screens: [],
      assignedScreen: [],
      updateScreens: [],
      selectedScreenToAssign: '',
      SelectedScreenToRemove: []
    }
    this.lang = props.language._MODULE.CREATE

    this.columns = [{
      title: 'Screen Name',
      dataIndex: 'name',
    },
    {
      title: 'Actions',
      render: (text, record) => (
        <span>
          <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>DELETE</Button>
        </span>
      ),
      width: '30%',
      key: 'last_name',
    }]
  }
  componentDidMount() {
    let screens = JSON.parse(localStorage.getItem('screenList'))
    this.setState({ screens })

  }
  deleteCategory(record) {
    let tempArray = [...this.state.SelectedScreenToRemove]
    tempArray.push(record)
    let tempArray2 = [...this.state.assignedScreen]
    let array3 = [...this.state.screens]

    for (let a = 0; a <= tempArray2.length - 1; a++) {
      if (tempArray2[a].id === record.id) {
        array3.push(tempArray2[a])
        tempArray2.splice(a, 1)
      }
    }
    this.setState({ SelectedScreenToRemove: tempArray, assignedScreen: tempArray2, screens: array3 })
  }
  seclectScreen = (v) => {
    // console.log(v)
    this.setState({ selectedScreenToAssign: v })
  }
  assignScreenAdd = () => {
    let screens = JSON.parse(localStorage.getItem('screenList'))
    let tempArray = [...this.state.assignedScreen]
    let tempArray2 = [...this.state.screens]
    let tempArray3 = [...this.state.updateScreens]
    for (let a = 0; a <= screens.length - 1; a++) {
      if (screens[a].id === this.state.selectedScreenToAssign) {

        tempArray.push(screens[a])
        tempArray3.push(screens[a])
      }
    }
    for (let a = 0; a <= this.state.screens.length - 1; a++) {
      this.state.screens[a].id === this.state.selectedScreenToAssign && tempArray2.splice(a, 1)
    }
    document.getElementsByClassName("ant-select-selection-selected-value").length !== 0
      ?
      document.getElementsByClassName("ant-select-selection-selected-value")[0].innerHTML = ""
      :
      console.log()
    this.setState({ assignedScreen: tempArray, screens: tempArray2, updateScreens: tempArray3 })
    setTimeout(() => { console.log(this.state) }, 1000)
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const client_id = localStorage.getItem('client_id')
    const user_id = localStorage.getItem('user_id')
    const headers = getHeadersForHttpReq();
    this.props.form.validateFields((err, values) => {
      values.client_id = client_id
      values.user_id = user_id
      axios.post(`${complete_URL}/modules/register`, values, {headers})
        .then(res => { /*console.log(res)*/
          message.success('Module Has Been Created')
          this.props.history.push('modules')
        })
        .catch(err => {
          message.error('Module Has not Created')
          console.log(err)
        })
    })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={7}>
          <Link to="/dashboard/modules">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={7}>
          <h1>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={10}>
          <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          {/* <Button type="danger"><Icon type="arrow-left" /></Button> */}
          <Link to="/dashboard/modules">
            <Button className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col className="btn-box" span={10}>
          <Button className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          {/* <Button type="danger"><Icon type="arrow-left" /></Button> */}
          <Link to="/dashboard/modules">
            <Button className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
        <Col span={7}>
          <h1>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col span={7}>
          <Link to="/dashboard/modules">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (

      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              {/* // <Row gutter={12} style={{ marginTop: '50px' }}>
      //   <Col span={4}></Col>
      //   <Col span={16} >
      //     <Card>
      //       <Form onSubmit={this.handleSubmit}>
      //         <Row className="top-header">
      //           <Col span={4}>
      //             <Link to="/dashboard/modules">
      //               <Button type="danger"><Icon type="arrow-left" /></Button>
      //             </Link>
      //           </Col>
      //           <Col span={5}></Col>
      //           <Col span={6}>
      //             <h1 >Add Module</h1>
      //           </Col>
      //           <Col span={5}></Col>
      //           <Col span={4}>
      //             <Form.Item>
      //               <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
      //             </Form.Item>
      //           </Col>
      //         </Row> */}


              {/* <Row gutter={24} className="top-header" >
                <Col span={8}>
                  <Link to="/dashboard/modules">
                    <Button type="danger"><Icon type="arrow-left" /></Button>
                  </Link>
                </Col>
                <Col span={8}>
                  <h1>Add Module</h1>
                </Col>
                <Col span={8}>
                  <Button type="primary" htmlType= "submit"><Icon type="save" /></Button>
                </Col>
              </Row> */}
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Please input your Name!' }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} type="text" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}>
                    {getFieldDecorator('arname', {
                      rules: [{ required: false, message: 'Please input your Arabic Name!' }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NAMEAR} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.DESCRIPTION}>
                    {getFieldDecorator('description', {
                      rules: [{ required: false, message: 'Please input your Description!' }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.DESCRIPTION} />
                    )}
                  </Form.Item>
                </Col>
                {/* <Form.Item>
                                {getFieldDecorator('screen', {
                                    rules: [{ required: true, message: 'Please input your password!' }],
                                })(
                                    <Row gutter={8}>
                                        <Col span={4}>Select Screens:</Col>
                                        <Col span={12}>
                                            <Select onChange={this.seclectScreen}>
                                                {
                                                    this.state.screens.map( screen => (
                                                        <Select.Option key={screen.id} value={screen.id}>{screen.name}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Col>
                                        <Col span={4}>
                                            <Button type="ghost" onClick={this.assignScreenAdd}>Add</Button>
                                        </Col>
                                    </Row>
                                )}
                            </Form.Item> */}
                {/* <Form.Item>
                                <Row id="clientsTable">
                                    <Col span={24}>
                                        <Table columns={this.columns} rowKey="id" dataSource={this.state.assignedScreen} />,
                                    </Col>
                                </Row>
                            </Form.Item> */}
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default ModuleAdd = Form.create({ name: 'normal_login' })(ModuleAdd);