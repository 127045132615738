import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button,Switch, Input, message, Table, Avatar, Select, InputNumber, Spin, } from 'antd'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

class ContractorPkgSpecialRate  extends Component {
  constructor(props) {
    super(props)
    this.state = {
      screenlistno : '1', //1 =>Table, 2 => Add new, 3 => Update selected
      data: [],
      clients : [],
      package_rate:{},
      pagination: {},
      page: 1,
      size: 5,
      sortField:"client_name",
      sortOrder:"ascend",
      searchValues:{
        client_name : '',
        client_name_ar :'',
        price :'',
        commission_percentage : '',
        commission_amount : ''
      },
      commissionOptions: [{'id':0,'name':'Percentage','name_ar':'بنسبة مئوية'},{'id':1,'name':'Amount','name_ar':'حسب الكمية'}],
      commissionOption : 0,
      addSpin : false,
      updateSpin : false,
      deleteSpin : false,
      dir : 'ltr', 
      display: 'block'
    }
    this.lang = props.language._PACKAGESPECIALRATE;
    this.msg = props.language._PACKAGESPECIALRATE.MESSAGE;
    
    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.CLIENTNAME}`,
        dataIndex: 'client_name',
        key: 'client_name',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.CLIENTNAMEAR}`,
        dataIndex: 'client_name_ar',
        key: 'client_name_ar',
        align:'right',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        key: 'price',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        key: 'price',
        align:'right',
        render: (text, record) => <button  className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.COMMISSIONPERCENT}`,
        dataIndex: 'commission_percentage',
        key: 'commission_percentage',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.COMMISSIONPERCENT}`,
        dataIndex: 'commission_percentage',
        key: 'commission_percentage',
        align:'right',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        key: 'commission_amount',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.COMMISSIONAMOUNT}`,
        dataIndex: 'commission_amount',
        key: 'commission_amount',
        align:'right',
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      {
        title: `${this.lang.SETUP.LABELS.ACTION}`,
        align :'center',
        key:'action_btn',
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.SETUP.BUTTON.DELETE}</Button>
          </span>
        ),
      }
    ];

  }//End of Constructor

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }//End of Method
  
  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = async(record)=>{
    localStorage.setItem('updatePkgRate',JSON.stringify(record));
    await this.fetchSelectedPkgRate()
  }
  
  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager, pageNumber: pagination.current });

    if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
      if (this.state.pageNumber  !== pager.current) {
        this.fetch({ page: pagination.current, });
      }
    } 
    else {
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }
  }//End of method

  handleChangeSearchValues = (val) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${val}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => { this.fetch(); });
  }

  //Get selected pkg rate record, maybe for update screen
  fetchSelectedPkgRate =async ()=>{
    let rate_obj = localStorage.getItem('updatePkgRate');
    if (rate_obj) {
      const pkg_rate_id = JSON.parse(rate_obj).id;
      const headers = getHeadersForHttpReq();
      await axios.get(`${complete_URL}/contractorpkgrate/get_cont_pkg_rate_by_id/${pkg_rate_id}`,{headers})
      .then(pkg_rate_res=>{ //console.log('pkg_rate_res.data.data->',pkg_rate_res.data.data);
        let comm_opt = -1;
        parseFloat(pkg_rate_res.data.data[0].commission_percentage) > 0 ? (comm_opt = 0) : (comm_opt = 1)
        this.setState({package_rate : pkg_rate_res.data.data[0],commissionOption : comm_opt, screenlistno: '3'})
      })
      .catch(pkg_rate_err=>{
        console.log('Error occured while fetching selected Contractor package rate->',pkg_rate_err)
      })
    }
    else{
      message.error(this.msg.DATANOTFOUNDERROR,3);
    }
  }

  deleteCategory =(record)=>{
    this.setState({deleteSpin : true});
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/contractorpkgrate/${record.id}`,{headers})
      .then(res => {// console.log(res)
        this.fetch();
        this.fetchClients();
        this.setState({deleteSpin : false});
        message.success(this.msg.DELETESUCCESS);
      })
      .catch(err => {
        this.setState({deleteSpin : false});
        message.error(this.msg.DELETEFAILURE,3);
        console.log('Error occured while deleting Contractor package rate->',err)
      })
  }

  handleSubmitPkgSpecialRate = (e)=>{
    e.preventDefault();
    this.props.form.validateFields(['client_id','price2','commission_percentage2','commission_amount2'],(err, values) => {
      if (!err) {
        this.setState({addSpin : true})
        const self = this;
        const headers = getHeadersForHttpReq();
        const pkg_stringify_obj = localStorage.getItem('updatePackage');
        if (pkg_stringify_obj) {
          const cont_pkg_id = (JSON.parse(pkg_stringify_obj)).id;
          const user_id = localStorage.getItem('user_id');
          Object.assign(values,{ cont_pkg_id : cont_pkg_id, user_id : user_id })
          // console.log(values)
          axios.post(`${complete_URL}/contractorpkgrate/add`, values,{headers})
            .then(res => {
              if (res.data.success) {
                message.success(self.msg.ADDSUCCESS);
                self.fetch();
                self.fetchClients();
                self.setState({ addSpin : false, screenlistno : '1' })
              } 
              else {
                message.error(self.msg.ADDFAILURE,3);
                self.setState({ addSpin : false})
              }
            })
            .catch(err => {
              console.log('Error occured in creating the Contractor package rate->',err)
              self.setState({ addSpin : false})
              message.error(self.msg.ADDFAILURE,3)
            })
        }
        else{ message.error(self.props.language.LOGINERROR,3); }
      }//End of if not err
      else { 
        console.log('Contractor Package Rate Form Error->',err); 
        this.setState({ addSpin : false});
      }
    })
  }// End of method
  
  handleSubmitPkgSpecialRateUpdate = (e)=>{
    e.preventDefault();
    this.props.form.validateFields(['client_id3','price3','commission_percentage3','commission_amount3'],(err, values) => {
      if (!err) {
        this.setState({updateSpin : true})
        const self = this;
        const headers = getHeadersForHttpReq();
        const pkg_stringify_obj = localStorage.getItem('updatePackage');
        if (pkg_stringify_obj && this.state.package_rate.id) {
          const cont_pkg_id = (JSON.parse(pkg_stringify_obj)).id;
          const user_id = localStorage.getItem('user_id');
          Object.assign(values,{ cont_pkg_id : cont_pkg_id, user_id : user_id })
          // console.log(values)
          axios.put(`${complete_URL}/contractorpkgrate/${this.state.package_rate.id}`, values,{headers})
            .then(res => {
              if (res.data.success) {
                message.success(self.msg.ADDSUCCESS);
                self.fetch();
                self.fetchClients();
                self.setState({ updateSpin : false, screenlistno : '1' })
              } 
              else {
                message.error(self.msg.ADDFAILURE,3);
                self.setState({ updateSpin : false})
              }
            })
            .catch(err => {
              console.log('Error occured in creating the Contractor package rate->',err)
              self.setState({ updateSpin : false})
              message.error(self.msg.ADDFAILURE,3)
            })
        }
        else{ message.error(self.props.language.LOGINERROR,3); }
      }//End of if not err
      else { 
        console.log('Contractor Package Rate Form Error->',err); 
        this.setState({ addSpin : false});
      }
    })

  }// End of method

  fetch = (params={})=>{
    const headers = getHeadersForHttpReq();
    const pkg_stringify_obj = localStorage.getItem('updatePackage');
    if (pkg_stringify_obj) {
      const pkg_id = (JSON.parse(pkg_stringify_obj)).id;
      
      let page,sortOrder ;
      if (params.page) { page = params.page - 1 }
      else { page = 0;}

      if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
      else { sortOrder = 'DESC' }
      
      let data ={
        cont_pkg_id : pkg_id,
        client_name : this.state.searchValues.client_name || '', 
        client_name_ar : this.state.searchValues.client_name_ar || '',
        price : this.state.searchValues.price || '',
        commission_percentage : this.state.searchValues.commission_percentage || '',
        commission_amount : this.state.searchValues.commission_amount || '',

        page,
        sortColumn: params.sortField || this.state.sortField ,
        sortOrder,
        size:this.state.size,
        orderBy: '',
        column_name: 'client_name'
      }
      // console.log('Sending data to api ->',data);
      axios.post(`${complete_URL}/contractorpkgrate/pagination_table`,data, {headers})
      .then(response => {
        // console.log('response of exp new pagination ->',response)
        let pagination = {};
        Object.assign(pagination,{
          ...this.state.pagination,
          total : response.data.totalElements,
          current : page +1,
          pageSize : this.state.size
        });

        this.setState({ data: response.data.data, pagination })
      })
      .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })
  
    }
    else{
      message.error(this.props.language.LOGINERROR,3);
    }
  }//End of method

  //Should be called everytime when Pkg rate added or updated
  fetchClients=()=>{
    const headers = getHeadersForHttpReq();
    const pkg_stringify_obj = localStorage.getItem('updatePackage');
    if (pkg_stringify_obj) {
      const pkg_id = (JSON.parse(pkg_stringify_obj)).id;
      axios.get(`${complete_URL}/contractorpkgrate/getclientsbypkgid/${pkg_id}`,{headers})
      .then(clients_res=>{ //console.log('clients_res.data.data ->',clients_res.data.data);
        this.setState({clients : clients_res.data.data})
      })
      .catch(clients_err=>{
        console.log('Error occured while fetching clients with help of pkg ID->',clients_err);
      })
    }
    else{
      message.error(this.props.language.LOGINERROR,3);
    }
  }//End of method

  componentDidMount(){
    this.setComponentDirection();
    this.fetchClients();
    this.fetch();
  }

  componentWillMount(){
    if (localStorage.getItem('lang') === 'EN') {
      this.PkgRateAddHeader = <Row>
        <Col span={1}></Col>
        <Col span={3}>
          <Button type="danger" onClick={() => { this.setState({ screenlistno: '1' }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={15}>
          <h1 style={{ fontSize: '24px', textAlign:'center' }}>{this.lang.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={1}></Col>
        <Col span={3}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.PkgRateTableHeader = <Row id="clientTableHeader">
        <Col span={1}></Col>
        <Col span={13}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.LABELS.TITLE}</p></Col>
        <Col span={9}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ screenlistno: '2' }) }}>{this.lang.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={1}></Col>
      </Row>

      this.PkgRateUpdateHeader = <Row>
        <Col span={1}></Col>
        <Col span={3}>
          <Button type="danger" onClick={() => { this.setState({ screenlistno: '1',package_rate:{} }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={15}>
          <h1 style={{ fontSize: '18px', textAlign:'center'  }}>{this.lang.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={1}></Col>
        <Col span={3}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
    else {
      this.PkgRateAddHeader = <Row>
        <Col span={3}>
          <Button type="danger" onClick={() => { this.setState({ screenlistno: '1' }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={2}></Col>
        <Col span={12}>
          <h1 style={{ fontSize: '24px', textAlign:'center' }}>{this.lang.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={2}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.PkgRateTableHeader = <Row id="clientTableHeader">
        <Col span={1}></Col>
        <Col span={8}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ screenlistno: '2' }) }}>{this.lang.SETUP.BUTTON.ADD}</Button>
        </Col>
        <Col span={14}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.LABELS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.PkgRateUpdateHeader = <Row gutter={24}>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ screenlistno: '1' }) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={10}>
          <h1 style={{ fontSize: '18px', textAlign:'center'  }}>{this.lang.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={2}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }

  }

  render(){
    const { getFieldDecorator } = this.props.form;
    // console.log('render..this.state.clients',this.state.clients)
    let {data, pagination, dir, display} = this.state;

    return(
      <>
      {
        this.state.screenlistno === '1' &&
        <Spin tip={this.msg.DELETEPKGRATETIP} spinning={this.state.deleteSpin}>
          {this.PkgRateTableHeader}
          <Row id="packagesTable">
            <Col span={23} style={{margin : '0 25px 14px 25px'}}>
                <Table 
                  size = {'small'}
                  columns={this.columns} 
                  rowKey='client_name' 
                  dataSource={data}
                  onChange={this.handleChangePagination}
                  pagination={pagination} 
                />
            </Col>
          </Row>
        </Spin>
      }
      {
        this.state.screenlistno === '2' &&
        <Spin tip={this.msg.PKGRATEADDTIP} spinning={this.state.addSpin}>
        <Form onSubmit={this.handleSubmitPkgSpecialRate} style={{marginTop:'20px'}}>
          {this.PkgRateAddHeader}
          <Row gutter={24} dir= {dir} style={{ display }}>
            <Col span={12}>
              <Form.Item label={this.lang.CREATE.LABELS.CLIENT}  >
                {getFieldDecorator('client_id', {
                  rules: [{ required: true, message: this.msg.SELECTCLIENT }],
                })(
                    <Select placeholder={ this.lang.CREATE.LABELS.CLIENT} style={{ width: '100%' }} >
                    {
                        (localStorage.getItem('lang') === 'EN') ?
                        this.state.clients.map((ct,index) => (
                            <Select.Option disabled={ct.cpr_id ? true : false} style={{textAlign:'left'}} key={'ct_'+index} value={ct.client_id} >{ct.name}</Select.Option>
                        ))
                        : this.state.clients.map((ct,index) => (
                            <Select.Option disabled={ct.cpr_id ? true : false} style={{textAlign:'right'}} key={'ctr_'+index} value={ct.client_id} >{ct.name_ar}</Select.Option>
                        ))
                    }
                    </Select>)}
              </Form.Item>
            </Col>
            <Col span={12}>
              {
                <Form.Item label={this.lang.CREATE.LABELS.PACKAGEPRICE}  >
                  {getFieldDecorator('price2', {
                    rules: [{ required: true, message: this.msg.INSERTPKGPRICEERROR }]
                  })(
                    <Input placeholder={this.lang.CREATE.LABELS.PACKAGEPRICE} />
                  )}
                </Form.Item>
              }
            </Col>
          </Row>
          <Row gutter={24} dir= {dir} style={{ display }}>
              <Col span={8}>
                {
                <Form.Item label= {this.lang.CREATE.LABELS.COMMISSIONTYPE}  >
                      {getFieldDecorator('commissionOption', {
                        initialValue:this.state.commissionOptions[this.state.commissionOption].id,
                        rules: [{ required: true, message: this.msg.SELECTCOMMTYPEERROR }],
                      })(
                      <Select onChange={(e)=>this.handleCommissionTypeSelection(e)} placeholder= {this.lang.CREATE.LABELS.COMMISSIONTYPE}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            this.state.commissionOptions.map(commission => (
                              <Select.Option key={commission.id} value={commission.id}>{commission.name}</Select.Option>
                            )):
                            this.state.commissionOptions.map(commission => (
                              <Select.Option style={{textAlign:'right'}} key={commission.id} value={commission.id}>{commission.name_ar}</Select.Option>
                            ))
                        }
                      </Select>)}
                  </Form.Item>
                }
              </Col>
              <Col span={8}>
                  {
                    <Form.Item  label= {this.lang.CREATE.LABELS.COMMISSIONPERCENT} >
                      {getFieldDecorator('commission_percentage2', {
                        rules: [{ required: this.state.commissionOption == 0 , message: this.msg.INSERTCOMMPERCENTERROR }]
                      })(
                        <InputNumber  placeholder={this.lang.CREATE.LABELS.COMMISSIONPERCENT}  
                          disabled={this.state.commissionOption == 1} max={100.00} min = {0.00} style={{width:'100%'}}
                        />
                      )}
                    </Form.Item>
                  }
              </Col>
              <Col span={8}>
                  {
                    <Form.Item  label={this.lang.CREATE.LABELS.COMMISSIONAMOUNT}  >
                      {getFieldDecorator('commission_amount2', {
                        rules: [{ required: this.state.commissionOption == 1, message: this.msg.INSERTCOMMAMOUNTERROR }]
                      })(
                        <InputNumber  placeholder={this.lang.CREATE.LABELS.COMMISSIONAMOUNT}
                          disabled={this.state.commissionOption == 0}  style={{width:'100%'}}
                        />
                      )}
                    </Form.Item>
                  }
              </Col>
            </Row>
        </Form>
        </Spin>
      }
      {
        this.state.screenlistno === '3' &&
        <Spin tip={this.msg.PKGRATEUPDATETIP} spinning={this.state.updateSpin}>
          <Form onSubmit={this.handleSubmitPkgSpecialRateUpdate.bind(this)} style={{marginTop:'20px'}}>
            {this.PkgRateUpdateHeader}
            <Row gutter={24} dir= {dir} style={{ display }}>
              <Col span={12}>
              {
                <Form.Item label={this.lang.UPDATE.LABELS.CLIENT}  >
                {getFieldDecorator('client_id3', {
                  initialValue: this.state.package_rate.client_id,
                  rules: [{ required: true, message: this.msg.SELECTCLIENT }],
                })(
                    <Select placeholder={ this.lang.UPDATE.LABELS.CLIENT} style={{ width: '100%' }}>
                    {
                        (localStorage.getItem('lang') === 'EN') ?
                        this.state.clients.map((ct,index) => (
                          <Select.Option disabled={(ct.cpr_id && ct.client_id != this.state.package_rate.client_id) ? true : false} style={{textAlign:'left'}} key={'ct_'+index} value={ct.client_id} >{ct.name}</Select.Option>
                        ))
                        : 
                        this.state.clients.map((ct,index) => (
                            <Select.Option disabled={(ct.cpr_id && ct.client_id != this.state.package_rate.client_id) ? true : false} style={{textAlign:'right'}} key={'ctr_'+index} value={ct.client_id} >{ct.name_ar}</Select.Option>
                        ))
                    }
                    </Select>)}
                </Form.Item>
              }
              </Col>
              <Col span={12}>
                {
                  <Form.Item label={this.lang.UPDATE.LABELS.PACKAGEPRICE}   >
                    {getFieldDecorator('price3', {
                      initialValue: this.state.package_rate.price,
                      rules: [{ required: true, message: this.msg.INSERTPKGPRICEERROR }]
                    })(
                      <Input placeholder={this.lang.UPDATE.LABELS.PACKAGEPRICE} />
                    )}
                  </Form.Item>
                }
              </Col>
            </Row>
            
            <Row gutter={24} dir= {dir} style={{ display }}>
              <Col span={8}>
                  <Form.Item label= {this.lang.UPDATE.LABELS.COMMISSIONTYPE} >
                        {getFieldDecorator('commissionOption', {
                          initialValue: this.state.commissionOptions[this.state.commissionOption].id,
                          rules: [{ required: true, message:  this.msg.SELECTCOMMTYPEERROR }],
                        })(
                        <Select onChange={(e)=>this.handleCommissionTypeSelection(e)} placeholder= {this.lang.UPDATE.LABELS.COMMISSIONTYPE}>
                            {
                              (localStorage.getItem('lang') === 'EN') ?
                              this.state.commissionOptions.map(commission => (
                                <Select.Option key={commission.id} value={commission.id}>{commission.name}</Select.Option>
                              )):
                              this.state.commissionOptions.map(commission => (
                                <Select.Option style={{textAlign:'right'}} key={commission.id} value={commission.id}>{commission.name_ar}</Select.Option>
                              ))
                            }
                          </Select>)}
                  </Form.Item>
              </Col>
              <Col span={8}>
                {
                  <Form.Item  label= {this.lang.UPDATE.LABELS.COMMISSIONPERCENT} >
                    {getFieldDecorator('commission_percentage3', {
                      initialValue: this.state.package_rate.commission_percentage,
                      rules: [{ required: this.state.commissionOption == 0 , message:  this.msg.INSERTCOMMPERCENTERROR }]
                    })(
                      <InputNumber disabled={this.state.commissionOption == 1} placeholder={this.lang.UPDATE.LABELS.COMMISSIONPERCENT}  max={100.00} min = {0.00} style={{width:'100%'}}  />
                    )}
                  </Form.Item>
                }
              </Col>
                  
              <Col span={8}>
              {
                <Form.Item  label= {this.lang.UPDATE.LABELS.COMMISSIONAMOUNT} >
                  {getFieldDecorator('commission_amount3', {
                    initialValue: this.state.package_rate.commission_amount,
                    rules: [{ required: this.state.commissionOption == 1, message: this.msg.INSERTCOMMAMOUNTERROR  }]
                  })(
                    <InputNumber disabled={this.state.commissionOption == 0} placeholder= {this.lang.UPDATE.LABELS.COMMISSIONAMOUNT} style={{width:'100%'}} />
                  )}
                </Form.Item>
              }
            </Col>
              
            </Row>
          </Form>
        </Spin>
      }
      </>
    )
  }

}//End of Component

export default ContractorPkgSpecialRate = Form.create({ name: 'cont_pkg_special_rate' })(ContractorPkgSpecialRate);
