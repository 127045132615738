import React from 'react';
import Login from './Login'
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import './style.css'

let login_screen;
if (localStorage.getItem('lang') === 'EN') {
  login_screen = Login;
}
else {
  login_screen = (props) => <DirectionProvider direction={DIRECTIONS.RTL}>
    <div>
      <Login language={props.language} {...props} />
    </div>
  </DirectionProvider>
}

export default login_screen