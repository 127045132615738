import React, { Component } from 'react';
import {Row,  Col,  Form,  Icon,  Button,  Input,  Card,  message,  InputNumber,  Select} from 'antd'
import { Link } from 'react-router-dom'
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class HallAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      screens: [],
      assignedScreen: [],
      updateScreens: [],
      selectedScreenToAssign: '',
      SelectedScreenToRemove: [],
      hall_type: [],
      uom: [],
      banquets: [],
      // uop: [],
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._HALL.CREATE;
    this.msg = props.language._HALL.MESSAGE;
    
  }

  componentDidMount() {
    this.getBanquetsByClient();
    this.getHallDataByParameter('uom');  
    this.getHallDataByParameter('hall_type');  
    // this.getHallDataByParameter('uop');
    this.setComponentDirection();
  }

  getBanquetsByClient=()=>{
    const client_id = localStorage.getItem('client_id'); 
    if (client_id) {
      const headers = getHeadersForHttpReq();

      axios.get(`${complete_URL}/banquets/by_clients/${client_id}`, {headers})
        .then(result => { //console.log('Client Banquets ->',result.data.data[0]);
          this.setState({ banquets: result.data.data[0] });
        })
        .catch(err => { console.log('Error occured while fetching Banquets by client',err) });
    }
  }
  
  getHallDataByParameter =(key)=>{ 
    const headers = getHeadersForHttpReq();
    if (key) {
      axios.get(`${complete_URL}/parameters/${key}`, {headers})
      .then(result => { //console.log(result)
        this.setState({ [key] : result.data.data[0] })
      })
      .catch(err => { console.log('Error occured while fetching '+key,err) })
    }
  }//End of Method

  //Extra unncessary method here, not being called..
  deleteCategory(record) {
    let tempArray = [...this.state.SelectedScreenToRemove]
    tempArray.push(record)
    let tempArray2 = [...this.state.assignedScreen]
    let array3 = [...this.state.screens]

    for (let a = 0; a <= tempArray2.length - 1; a++) {
      if (tempArray2[a].id === record.id) {
        array3.push(tempArray2[a])
        tempArray2.splice(a, 1)
      }
    }
    this.setState({ SelectedScreenToRemove: tempArray, assignedScreen: tempArray2, screens: array3 })
  }
  //Extra unncessary method here, not being called..
  seclectScreen = (v) => {
    // console.log(v)
    this.setState({ selectedScreenToAssign: v })
  }
  //Extra unncessary method here, not being called..
  assignScreenAdd = () => {
    var screens = JSON.parse(localStorage.getItem('screenList'))
    let tempArray = [...this.state.assignedScreen]
    let tempArray2 = [...this.state.screens]
    let tempArray3 = [...this.state.updateScreens]
    for (let a = 0; a <= screens.length - 1; a++) {
      if (screens[a].id === this.state.selectedScreenToAssign) {

        tempArray.push(screens[a])
        tempArray3.push(screens[a])
      }
    }
    for (let a = 0; a <= this.state.screens.length - 1; a++) {
      this.state.screens[a].id === this.state.selectedScreenToAssign && tempArray2.splice(a, 1)
    }
    document.getElementsByClassName("ant-select-selection-selected-value").length !== 0
      ?
      document.getElementsByClassName("ant-select-selection-selected-value")[0].innerHTML = ""
      :
      console.log()
    this.setState({ assignedScreen: tempArray, screens: tempArray2, updateScreens: tempArray3 })
    setTimeout(() => { console.log(/*this.state*/) }, 1000)
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id')
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const headers = getHeadersForHttpReq();
        values.user_id = user_id;
        values.client_id = localStorage.getItem('client_id');
        // console.log(values)
        axios.post(`${complete_URL}/halls/add`, values, {headers})
          .then(res => {
            message.success(this.msg.HALLADDSUCCESS);
            this.props.history.push('halls')
          })
          .catch(err => {
            message.error(this.msg.HALLADDFAILURE);
            console.log('Error occured while adding the Hall->',err)
          })
      }
    })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/halls">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={12}> <h1 style={{textAlign: 'center'}}>{this.lang.LABELS.TITLE}</h1></Col>
        <Col className="btn-box" span={6} style={{display: 'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/halls">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/halls">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}><h1 style={{textAlign: 'center'}}>{this.lang.LABELS.TITLE}</h1> </Col>
        <Col className="btn-box" span={5}  style={{display: 'flex'}}>
          <Link to="/dashboard/halls">
            <Button  style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }//End of Method


  render() {
    const { getFieldDecorator } = this.props.form
    const {dir, display, banquets, hall_type, uom } = this.state;
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}                             >
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: this.msg.INSERTNAMEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      rules: [{ required: true, message: this.msg.INSERTNAMEARERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NAMEAR} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.SELECTBANQUET}>
                    {getFieldDecorator('banquet_id', {
                      rules: [{ required: true, message: this.msg.SELECTBANQUETERROR }],
                    })(
                      <Select placeholder={`Select ${this.lang.LABELS.SELECTBANQUET}`} onChange={this.seclectPrend} disabled={this.state.is_parent}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            banquets.length > 0 && banquets.map(banquet => (
                              <Select.Option key={banquet.id} value={banquet.id}>{banquet.name}</Select.Option>
                            ))
                            :
                            banquets.length > 0 && banquets.map(banquet => (
                              <Select.Option style={{textAlign:'right'}} key={banquet.id} value={banquet.id}>{banquet.name_ar}</Select.Option>
                            ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} >
                  <Form.Item label={this.lang.LABELS.CHAIR}>
                    {getFieldDecorator('chairs_cnt', {
                      rules: [{ required: true, message: this.msg.INSERTCHAIRSERROR }],
                    })(
                      <InputNumber  placeholder={this.lang.LABELS.CHAIR} min={0} max={10000000000} step={1} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.CAPACITY}>
                    {getFieldDecorator('capacity', {
                      rules: [{ required: true, message: this.msg.INSERTCAPICITYERROR }],
                    })(
                      <InputNumber placeholder={this.lang.LABELS.CAPACITY} min={0} max={1000000000} step={1} style={{ width: '100%' }} />

                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.SECURITYDEPOSITE}>
                    {getFieldDecorator('sec_dep', {
                      rules: [{ required: true, message: this.msg.INSERTSECDEPOSITEERROR }],
                    })(
                      <InputNumber placeholder={this.lang.LABELS.SECURITYDEPOSITE} min={0} max={10000000000} step={0.1} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.EXPDAYS}>
                    {getFieldDecorator('exp_days', {
                      rules: [{ required: true, message: this.msg.INSERTEXPDAYSERROR }],
                    })(
                      <InputNumber placeholder={this.lang.LABELS.EXPDAYS} min={1} max={10000000000} step={1} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.HALLTYPE}>
                    {getFieldDecorator('hall_type', {
                      rules: [{ required: true, message: this.msg.SELECTHALLTYPEERROR }],
                    })(
                      <Select placeholder={this.lang.LABELS.HALLTYPE} onChange={this.seclectPrend} disabled={this.state.is_parent}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            hall_type.length > 0 && hall_type.map(type => (
                              <Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>
                            ))
                            : hall_type.length > 0 && hall_type.map(type => (
                              <Select.Option style={{textAlign:'right'}} key={type.id} value={type.id}>{type.name_ar}</Select.Option>
                            ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.UOM}>
                    {getFieldDecorator('uom', {
                      rules: [{ required: true, message: this.msg.SELECTUOPERROR }],
                    })(
                      <Select placeholder= {this.lang.LABELS.UOM} onChange={this.seclectPrend} disabled={this.state.is_parent}>
                        {
                          (localStorage.getItem('lang') === 'EN') ?
                            uom.length > 0 && uom.map(uom => (
                              <Select.Option key={uom.id} value={uom.id}>{uom.name}</Select.Option>
                            ))
                            : uom.length > 0 && uom.map(uom => (
                              <Select.Option style={{textAlign:'right'}} key={uom.id} value={uom.id}>{uom.name_ar}</Select.Option>
                            ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.AREA}>
                    {getFieldDecorator('area', {
                      rules: [{ required: true, message: this.msg.INSERTAREAERROR }],
                    })(
                      <InputNumber placeholder={this.lang.LABELS.AREA} min={0} max={100000} step={0.1} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.DEADLINE}>
                    {getFieldDecorator('deadline', {
                      rules: [{ required: true, message: this.msg.INSERTDEADLINESERROR }],
                    })(
                      <InputNumber placeholder={this.lang.LABELS.DEADLINE} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.DESCRIPTION} >
                    {getFieldDecorator('hall_desc', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Input.TextArea placeholder={this.lang.LABELS.DESCRIPTION} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.DESCRIPTIONAR}>
                    {getFieldDecorator('hall_desc_ar', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Input.TextArea dir = {'rtl'} placeholder={this.lang.LABELS.DESCRIPTIONAR} style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default HallAdd = Form.create({ name: 'normal_login' })(HallAdd);