import React, { Component } from 'react';
import { Row, Col, Tabs } from 'antd';

// import ContractorsAssignedBqt from '../ContractorsAssignedBqt';
// import ClientRequests from '../ClientRequests';
// import ApproveContractorRequest from '../ApproveContractorRequest';


import ContractorsAssignedBqt from './ContractorsAssignedBqt';
import ClientRequests from './ClientRequests';
import ApproveContractorRequest from './ApproveContractorRequest';

const { TabPane } = Tabs;


class AllContractorScreensOfClient extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
    
  }

  render() {
    // const { getFieldDecorator } = this.props.form;
    let is_EN = localStorage.getItem('lang') === 'EN';

    return (
      // <Spin tip={this.state.spin_msg} spinning={this.state.spin}>
        <> 
        {/* <Row gutter={24} style={{ marginTop: '50px' }}>
          <Col span={1}></Col>
          <Col span={22}><h2>{'Service Providers'}</h2></Col>
        </Row> */}
        <Row gutter={24} style={{ marginTop: '10px' }}>
        {/* <Col span={1}></Col> */}
          <Col span={23} style={{margin : '0 25px 14px 25px'}}>
            <Tabs defaultActiveKey="1" type="card" size={'Large'}>
              <TabPane tab = {is_EN ? 'Assigned' :  'تعيين' } key="1">
                <ContractorsAssignedBqt {...this.props} />
              </TabPane>
              <TabPane tab = {is_EN ? 'Send Requests' : 'ارسل طلبات' } key="2">
                <ClientRequests {...this.props} />
              </TabPane>
              <TabPane tab = {is_EN ? 'Received Requests' : 'الطلبات الواردة' } key="3">
                <ApproveContractorRequest {...this.props} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        </>
      //  </Spin> 
    )
  }
}

export default AllContractorScreensOfClient
// export default AllContractorScreensOfClient = Form.create({ name: 'cont_of_clients' })(AllContractorScreensOfClient);
