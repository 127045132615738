import React, { Component } from 'react'
import { Row, Col, Form, Icon, Button, Input, Card, message } from 'antd'
import { Link } from 'react-router-dom'
import API_URL from '../../constants';
import axios from 'axios';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

class ParameterUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      parent: [],
      parameter: {},
      selectedParentId: null, 
      display: 'block',
      textAlign : 'left'
 
    }

    this.lang = props.language._PARAMETER.UPDATE;
    this.msg = props.language._PARAMETER.MESSAGE;
  }

  componentDidMount() {
    this.setState({ parameter: JSON.parse(localStorage.getItem('updateParameterId')) })
    this.setComponentDirection();
  }
  
  setComponentDirection=()=>{
    if (localStorage.getItem('lang') === 'EN') {
      this.setState({dir : 'ltr', display: 'block',textAlign : 'left'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex',textAlign : 'right'})
    }
  }

  handleSelectParent = (v) => {
    // console.log(v)
    this.setState({ selectedParentId: v })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let user_id = localStorage.getItem('user_id')
    this.props.form.validateFields((err, values) => {
      values.user_id = user_id
      values.parent_id = this.state.selectedParentId
      // console.log(values)
      const headers = getHeadersForHttpReq();
      axios.put(`${complete_URL}/parameters/${JSON.parse(localStorage.getItem('updateParameterId')).id}`, values, {headers})
        .then(res => { /*console.log(res)*/
          message.success(this.msg.UPDATESUCCESS/*res.data.message*/)
          this.props.history.push('parameters')
        })
        .catch(err => {
          console.log('Error occured while updating the Parameter->',err);
          message.error(this.msg.UPDATEFAILURE,3);
        })
    })
  }

  deleteUser = () => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/parameters/${JSON.parse(localStorage.getItem('updateParameterId')).id}`,{headers})
      .then(res => {
        this.props.history.push('parameters')
        message.success(this.msg.DELETESUCCESS);
      })
      .catch(err => {
        message.error(this.msg.DELETEFAILURE,3);
        console.log('Error occured while ',err)
      })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/parameters">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={14}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/parameters">
            <Button style={{margin: '16px auto'}} className="delete" type="" /*onClick={this.deleteUser}*/ title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
      <Col span={4}>
        <Link to="/dashboard/parameters">
          <Button type="danger"><Icon type="arrow-left" /></Button>
        </Link>
      </Col>
        <Col span={15}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Link to="/dashboard/parameters">
            <Button style={{margin: '16px auto'}} className="delete" type="" /*onClick={this.deleteUser}*/ title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let {dir, display} = this.state;
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      initialValue: this.state.parameter.name,
                      rules: [{ required: true, message: this.msg.INSERTNAMEERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} type="text" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      initialValue: this.state.parameter.name_ar,
                      rules: [{ required: false, message: this.msg.INSERTNAMEARERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NAMEAR} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.CODE}>
                    {getFieldDecorator('code', {
                      initialValue: this.state.parameter.code,
                      rules: [{ required: true, message: this.msg.INSERTCODEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.CODE} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.TYPE}>
                    {getFieldDecorator('type', {
                      initialValue: this.state.parameter.type,
                      rules: [{ required: true, message: this.msg.INSERTTYPEERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.TYPE} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/* <Form.Item>
                                {getFieldDecorator('parent', {
                                    rules: [{ required: false, message: 'Please input your password!' }],
                                })(
                                    <Row gutter={8}>
                                        <Col span={4}>Select Parent:</Col>
                                        <Col span={12}>
                                            <Select onChange={this.handleSelectParent}>
                                                {
                                                    this.state.parent.map(parent => (
                                                        <Select.Option key={parent.id} value={parent.id}>{parent.name}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Col>
                                    </Row>
                                )}
                            </Form.Item> */}
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}

export default ParameterUpdate = Form.create({ name: 'parameter_update_form' })(ParameterUpdate);