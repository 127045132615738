import React, { Component } from 'react';
import { Row, Col, Form, Icon, Button, Switch, Input, Table, Card, Select, message } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}

const { Option } = Select
class UserUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      user: {},
      assignedRoles: [],
      selectedRoleToAssign: {},
      updateroles: [],
      SelectedRoleToRemove: [],
      dir : 'ltr', 
      display: 'block',
    };

    this.lang = props.language._USER.UPDATE;
    this.msg = props.language._USER.MESSAGE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: `${this.lang.TABLEHEADERS.ROLENAME}`,
          dataIndex: 'name',
          align :'left'
        }
        : {
          title: `${this.lang.TABLEHEADERS.ROLENAME}`,
          dataIndex: 'name_ar',
          align :'right'
        },
        {
          title: `${this.lang.TABLEHEADERS.ACTION}`,
          render: (text, record) => (
            <span>
              <Button type="primary" onClick={this.deleteRoles.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
            </span>
          ),
          width: '30%',
          align : 'center',
          key: 'last_name',
        }
    ];
  }
  
  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    this.fetch();
    this.setComponentDirection();
  }

  handleSelectRole = (e) => {
    this.setState({ selectedRoleToAssign: e })
  }

  deleteRoles = (record) => {
    let tempArray = [...this.state.SelectedRoleToRemove]
    tempArray.push(record)
    let tempArray2 = [...this.state.assignedRoles]
    let array3 = [...this.state.roles]
    for (let a = 0; a <= tempArray2.length - 1; a++) {
      if (tempArray2[a].id === record.id) {
        array3.push(tempArray2[a])
        tempArray2.splice(a, 1)
      }
    }
    this.setState({ SelectedRoleToRemove: tempArray, assignedRoles: tempArray2, roles: array3 })
  }

  assignRoleAdd = () => {
    var tempArray = [...this.state.assignedRoles]
    var tempArray2 = [...this.state.roles]
    var tempArray3 = [...this.state.updateroles]
    for (let i = 0; i <= this.state.roles.length - 1; i++) {
      if (this.state.roles[i].id === this.state.selectedRoleToAssign) {
        tempArray.push(this.state.roles[i])
        tempArray3.push(this.state.roles[i])

      }
    }
    for (let a = 0; a <= this.state.roles.length - 1; a++) {
      this.state.roles[a].id === this.state.selectedRoleToAssign && tempArray2.splice(a, 1)
    }
    document.getElementsByClassName("ant-select-selection-selected-value").length !== 0
      ?
      document.getElementsByClassName("ant-select-selection-selected-value")[0].innerHTML = ""
      :
      console.log()
    this.setState({ assignedRoles: tempArray, roles: tempArray2, updateroles: tempArray3 })
  }

  fetch = () => {
    // let temarr = []
    const headers = getHeadersForHttpReq();
    const userid = localStorage.getItem('userid');//This User ID is of selected User record, not Looged in User
    const client_id = localStorage.getItem('client_id');

    axios.get(`${complete_URL}/users/${userid}`,{headers})
      .then(res => {
        this.setState({ user: res.data })
      })
      .catch(us_err => { console.log("Error occured while fetching selected User's data ->",us_err) });

    
    let data = { id: client_id };
    axios.post(`${complete_URL}/roles/by_clients`, data,{ headers})
      .then(res => { /*console.log(res)*/
        this.setState({ roles: res.data });
      })
      .catch(c_r_err => { console.log('Error occured while fetching available Roles for this Client ->',c_r_err); });

    axios.get(`${complete_URL}/roles/by_users/${userid}`, { headers })
      .then(res => { /*console.log(res.data[0])*/
        this.setState({ assignedRoles: res.data[0] })
      })
      .catch(asg_r_err => { console.log('Error occured while fetching assigned Role of selected User ->',asg_r_err); });

    setTimeout(() => {
      var result1 = this.state.roles
      var result2 = this.state.assignedRoles
      var props = '';
      if (localStorage.getItem('lang') === 'EN') {
        props = ['id', 'name'];
      } else {
        props = ['id', 'name_ar'];
      }

      var result = result1.filter(function (o1) {
        // filter out (!) items in result2
        return !result2.some(function (o2) {
          return o1.id === o2.id;          // assumes unique id
        });
      }).map(function (o) {
        // use reduce to make objects with only the required properties
        // and map to apply this to the filtered array as a whole
        return props.reduce(function (newo, name) {
          newo[name] = o[name];
          return newo;
        }, {});
      });
      // console.log(result)
      this.setState({ roles: result })
    }, 500)
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let userid = localStorage.getItem('userid')//Note: this is is of selected User record 
        // console.log(values)
        let data = {
          user_id : localStorage.getItem('user_id'),//This is for tracking the Transaction by whome
          name: values.name,
          username: values.username,
          email: values.email,
          is_owner : values.is_owner == true ? 1 : 0,
        }
        const headers = getHeadersForHttpReq();

        axios.put(`${complete_URL}/users/${userid}`, data,{ headers})
          .then(res => { /*console.log(res)*/ })
          .catch(err => { console.log('Error  occured while updating the User data ->',err); });

        for (let a = 0; a <= this.state.updateroles.length - 1; a++) {
          let data = {
            user_id: localStorage.getItem('userid'),
            role_id: this.state.updateroles[a].id,
            client_id: localStorage.getItem('client_id'),
            name: localStorage.getItem('userid')
          }
          // console.log(data)
          axios.post(`${complete_URL}/userroles/add`, data,{headers})
          .then(r_a_res => { /*console.log(res)*/ })
          .catch(r_a_err => { console.log('Error occured while adding the Role to selected User ->',r_a_err); });

        }
        //Annoynamous code ?? Found on 24-Dec-2019 06:02PM
        for (let a = 0; a <= this.state.SelectedRoleToRemove.length - 1; a++) {
          let roleId = this.state.SelectedRoleToRemove[a].id;
          let delete_data = { 
            role_id: roleId, 
            client_id: localStorage.getItem('client_id'), 
            user_id: localStorage.getItem('userid') 
          };
          axios.post(`${complete_URL}/userroles/delete`,delete_data ,{headers})
            .then(r_res => { /*console.log(r_res)*/ })
            .catch(r_err => { console.log('Error occured while deleting the Role assigned to User->',r_err) })
        }
      }
    })
    setTimeout(() => { this.props.history.push('/dashboard/users') })
  }

  deleteUser = () => {
    const headers = getHeadersForHttpReq();

    axios.delete(`${complete_URL}/users/${localStorage.getItem('userid')}`, {headers})
      .then(res => {
        this.props.history.push('users')
        message.success(this.msg.DELETESUCCESS);
      })
      .catch(err => {
        console.log('Error occured in deleting the User account->',err);
        message.error(this.msg.DELETEFAILURE,3);
      })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/users">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={14}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/users">
            <Button style={{margin: '16px auto'}} className="delete" type="" /*onClick={this.deleteUser}*/ title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/users">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Link to="/dashboard/users">
            <Button style={{margin: '16px auto'}} className="delete" type="" /*onClick={this.deleteUser}*/ title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      initialValue: this.state.user.name,
                      rules: [{ required: true, message: this.msg.INSERTNAMEERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} type="text" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.USERNAME}>
                    {getFieldDecorator('username', {
                      initialValue: this.state.user.username,
                      rules: [{ required: true, message: this.msg.INSERTUSERNAMEERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.USERNAME} type="text" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.EMAIL}>
                    {getFieldDecorator('email', {
                      initialValue: this.state.user.email,
                      rules: [{ required: true, message: this.msg.INSERTEMAILERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.EMAIL} type="email" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label= {this.lang.LABELS.ISOWNER}>
                    {getFieldDecorator('is_owner', {
                      valuePropName: 'checked', valuePropName: 'checked',
                      initialValue:this.state.user.is_owner == 1 ? true : false})(<Switch />)}
                  </Form.Item>
                </Col>
              </Row>
              {/* <Form.Item>
                                <Row>
                                    <Col span={4}>Password:</Col>
                                    <Col span={12}>
                                        {getFieldDecorator('password', {
                                            initialValue: this.state.user.password,
                                            rules: [{ required: true, message: 'Please input your Password!' }],
                                        })(
                                            <Input placeholder="Password" type="password" />
                                        )}
                                    </Col>
                                </Row>
                            </Form.Item> */}
              <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                <Col span={12}>
                  <Form.Item label={this.lang.LABELS.SELECTROLE}>
                    <Row gutter={24} dir= {this.state.dir} style={{display : this.state.display }}>
                      <Col span={17}>
                        <Select onChange={this.handleSelectRole} >
                          {
                            (localStorage.getItem('lang') === 'EN') ?
                              this.state.roles.map((role, i) => (
                                <Option id="aaa" style={{textAlign:'left'}} value={role.id} key={role.id}>{role.name}</Option>
                              ))
                              :
                              this.state.roles.map((role, i) => (
                                <Option id="aaa" style={{textAlign:'right'}} value={role.id} key={role.id}>{role.name_ar}</Option>
                              ))
                          }
                        </Select>
                      </Col>
                      <Col span={7}>
                        <Button type="ghost" onClick={this.assignRoleAdd}>{this.lang.BUTTON.ADD}</Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              {
                this.state.assignedRoles.length > 0 &&
                <Form.Item>
                  <Row id="clientsTable">
                    <Col span={24}>
                      <Table columns={this.columns} rowKey="id" dataSource={this.state.assignedRoles} />,
                    </Col>
                  </Row>
                </Form.Item>
              }
            </Form>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}
export default UserUpdate = Form.create({ name: 'normal_login' })(UserUpdate);