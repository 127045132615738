import React from 'react'
import { Radio, Row, Col, Form, Button, InputNumber, Input } from 'antd'

class CreateReasonForPayment extends React.Component {
    createReason = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.handleOk(values)
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <React.Fragment>
                <Form onSubmit={this.createReason}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="Name">
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: 'Please insert Customer Name' }],
                                })(
                                    <Input placeholder="Name" />

                                )}
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item label="Arabic Name">
                                {getFieldDecorator('name_ar', {
                                    rules: [{ required: true, message: 'Please insert Customer Name in Arabic' }],
                                })(
                                    <Input placeholder="Arabic" />

                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="Code">
                                {getFieldDecorator('code', {
                                    rules: [{ required: true, message: 'Please insert Code' }],
                                })(
                                    <Input style={{ width: '100%' }} placeholder="Code" />

                                )}
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Button block primary className="save" type="" onClick={this.props.handleCancel} title="save" style={{ background: "#FF1E04", borderColor: "#FF1E04", color: "#fff" }}>Cancel</Button>

                        </Col>
                        <Col span={12}>
                            <Button block primary className="save" type="" htmlType="submit" title="save" style={{ background: "#FD5C7D", borderColor: "#FD5C7D", color: "#fff" }}>Add</Button>
                        </Col>
                    </Row>

                </Form>
            </React.Fragment>
        )
    }
}

export default Form.create({ name: 'user' })(CreateReasonForPayment);