import React from 'react';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import DashboardEn from './DashboardEn';
// import DashboardAr from './DashboardAr';
import './style.css'

let Dashboard;
if (localStorage.getItem('lang') === 'EN') {
	Dashboard = DashboardEn;
}
else {
	Dashboard = (props) => <DirectionProvider direction={DIRECTIONS.RTL}>
		<>
			<DashboardEn language={props.language} {...props} />
		</>
	</DirectionProvider>
}

export default Dashboard;