import React, { Component } from 'react';
import { Row, Col, Table, Select, Button, message, Input, DatePicker, Icon, Spin } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import getHeadersForPDFreport from '../../constants/reportHeaders';
import screenNavCheck from '../../constants/screenNavCheck';
import moment from 'moment';
import YearPicker from "react-year-picker";

const { RangePicker, MonthPicker } = DatePicker;
const Search = Input.Search;
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
const report_url = 'reservation_reports/by_duration_data_client_id';
const report_name = 'Reservation_report_';
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
// const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

class Reservations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //table data start
      data:[],
      pagination: {},
      page: 1,
      size: 5,
      sortField:'no',
      sortOrder:'descend',
      no: '',
      from_date: '',
      to_date: '',
      status: '',
      customer_name: '',
      customer_name_ar: '',
      banquet_name: '',
      banquet_name_ar: '',
      hall_name_ar: '',
      ph_num:'',
      hall_name: '',
        //table data ends
      searchValues_resrv_Report : {
        date: '', from_date: '', to_date: '',
        month: '', year: '', yearOnly: '',
        month1: '',year1: '', status :'all',
        month2: '', year2: '', allData: '',
        status : 'all',
     },
     filterResrvCalenderNode: '',
      mode: ['date', 'date'],
      startValue: null,
      endValue: null,
      endOpen: false,
      canCombReport :false,
      showResrvCalenderFilter : false,
      disableDownloadResrvReport: true,
      resrv_range_value: [],
      resrv_mode: ['month', 'month'],
      spin : false,
      spinningMessage : "Generating Report...",
      spin_msg : 'Generating Combined Reservation Report',
      spin_msg_ar : 'توليد تقرير الحجز المدمج',
      spin_email_msg : 'Sending Reservation Report to Recipients!',
      spin_email_msg_ar : "إرسال تقرير الحجز إلى المستلمين",
      dir : 'ltr', 
      display: 'block',
    }

    this.lang = props.language._RESERVATION.SETUP;
    this.msg = props.language._RESERVATION.MESSAGE;

    this.columns1 = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NO}
            <Search
              placeholder={this.lang.TABLEHEADERS.NO}
              onChange={this.handleChangeSearchValues("no")}
              name="no"
            />
          </span>
        ),
        dataIndex: 'no',
        key: 'no',
        width:'10%',
        sorter: (a, b) => this.compareByAlph(a.no, b.no),
        render: (text, record) =><div style={{margin:'0 20%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NO}
            <Search
              placeholder={this.lang.TABLEHEADERS.NO}
              onChange={this.handleChangeSearchValues("no")}
              name="no"
            />
          </span>
        ),
        dataIndex: 'no',
        key: 'no',
        width:'10%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.no, b.no),
        render: (text, record) =><div style={{margin:'0 20%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
      },
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.CUSTOMER}
              <Search
                placeholder={this.lang.TABLEHEADERS.CUSTOMER}
                onChange={this.handleChangeSearchValues("customer_name")}
                name="customer_name"
              />
            </span>
          ),
          dataIndex: 'customer_name',
          render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
        }
        : {
          title: (
            <span>{this.lang.TABLEHEADERS.CUSTOMER}
              <Search
                placeholder={this.lang.TABLEHEADERS.CUSTOMER}
                onChange={this.handleChangeSearchValues("customer_name_ar")}
                name="customer_name_ar"
              />
            </span>
          ),
          dataIndex: 'customer_name_ar',
          align : 'right',
          render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
        },
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.CUSTOMER_PHONE}
              <Search
                placeholder={this.lang.TABLEHEADERS.CUSTOMER_PHONE}
                onChange={this.handleChangeSearchValues("ph_num")}
                name="ph_num"
              />
            </span>
          ),
          dataIndex: 'ph_num',
          width:'13%',
          render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
        }
        : {
          title: (
            <span>{this.lang.TABLEHEADERS.CUSTOMER_PHONE}
              <Search
                placeholder={this.lang.TABLEHEADERS.CUSTOMER_PHONE}
                onChange={this.handleChangeSearchValues("ph_num")}
                name="ph_num"
              />
            </span>
          ),
          dataIndex: 'ph_num',
          width:'13%',
          align : 'right',
          render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text }</button></div>,
        },
        (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.STATUS}
              <Search
                placeholder={this.lang.TABLEHEADERS.STATUS}
                onChange={this.handleChangeSearchValues("status")}
                name="status"
              />
            </span>
          ),
          dataIndex: 'status',
          key: 'status',
          width:'10.5%',
          render: (text, record) =><div style={{margin:'0 7%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text=== 'reserved' ? 'RESERVED' : text === 'pending' ? 'PENDING' : 'CANCELED'}</button></div>,
        }
        :
        {
          title: (
            <span>{this.lang.TABLEHEADERS.STATUS}
              <Search
                placeholder={this.lang.TABLEHEADERS.STATUS}
                onChange={this.handleChangeSearchValues("status")}
                name="status"
              />
            </span>
          ),
          dataIndex: 'status',
          key: 'status',
          align : 'right',
          width:'11%',
          render: (text, record) =><div style={{margin:'0 7%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text=== 'reserved' ? 'محجوز' : text === 'pending' ? 'قيد الانتظار' : 'ألغيت'}</button></div>,
        },
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.BANQUET}
              <Search
                placeholder={this.lang.TABLEHEADERS.BANQUET}
                onChange={this.handleChangeSearchValues("banquet_name")}
                name="banquet_name"
              />
            </span>
          ),
          dataIndex: 'banquet_name',
          width:'12%',
          render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
        }
        : {
          title: (
            <span>{this.lang.TABLEHEADERS.BANQUET}
              <Search
                placeholder={this.lang.TABLEHEADERS.BANQUET}
                onChange={this.handleChangeSearchValues("banquet_name_ar")}
                name="banquet_name_ar"
              />
            </span>
          ),
          dataIndex: 'banquet_name_ar',
          align : 'right',
          width:'12%',
          render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
        },
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.HALL}
              <Search
                name="hall_name"
                placeholder={this.lang.TABLEHEADERS.HALL}
                onChange={this.handleChangeSearchValues("hall_name")}
              />
            </span>
          ),
          dataIndex: 'hall_name',
          width:'12%',
          render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
        }
        : {
          title: (
            <span>{this.lang.TABLEHEADERS.HALL}
              <Search
                name="hall_name_ar"
                placeholder={this.lang.TABLEHEADERS.HALL}
                onChange={this.handleChangeSearchValues("hall_name_ar")}
              />
            </span>
          ),
          dataIndex: 'hall_name_ar',
          align : 'right',
          width:'12%',
          render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
        },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title: (
          <span>{this.lang.TABLEHEADERS.FROMDATE}
            <DatePicker
              onChange={(e) => this.setState({from_date:e},() => {this.fetch()})}
              placeholder={this.lang.TABLEHEADERS.FROMDATE}
            />
          </span>
        ),
        dataIndex: 'from_date',
        key: 'from_date',
        width : '13%',
        sorter: (a, b) => this.compareByAlph(a.from_date, b.from_date),
        render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.FROMDATE}
            <DatePicker
              onChange={(e) => this.setState({from_date:e},() => {this.fetch()})}
              placeholder={this.lang.TABLEHEADERS.FROMDATE}
            />
          </span>
        ),
        dataIndex: 'from_date',
        key: 'from_date',
        align : 'right',
        width : '13%',
        sorter: (a, b) => this.compareByAlph(a.from_date, b.from_date),
        render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
      },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title: (
          <span>{this.lang.TABLEHEADERS.TODATE}
            <DatePicker
              onChange={(e) => this.setState({to_date:e},() => {this.fetch()})}
              placeholder={this.lang.TABLEHEADERS.TODATE}
            />
          </span>
        ),
        dataIndex: 'to_date',
        key: 'to_date',
        width : '13%',
        sorter: (a, b) => this.compareByAlph(a.to_date, b.to_date),
        render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.TODATE}
            <DatePicker
              onChange={(e) => this.setState({to_date:e},() => {this.fetch()})}
              placeholder={this.lang.TABLEHEADERS.TODATE}
            />
          </span>
        ),
        dataIndex: 'to_date',
        key: 'to_date',
        align : 'right',
        width : '13%',
        sorter: (a, b) => this.compareByAlph(a.to_date, b.to_date),
        render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
      }
    ];
  }

screenPermission =()=>{
  let module = localStorage.getItem('lang') === 'EN' ? 'Banquet Module':"إعدادات القاعه";
  let canAccess = screenNavCheck(module,'/reservations',localStorage.getItem('lang'));
  
  if (!canAccess) {
    canAccess = screenNavCheck(module,'/statistics',localStorage.getItem('lang'));
    if (canAccess) {
      this.props.history.push('/dashboard/statistics');
    }
    
  }
}

compareByAlph = (a, b) => {
  if (a > b) { return -1; }
  if (a < b) { return 1; }
  return 0;
}

onChangeDate = (type) => (date, dateString) => {
  // console.log(date, dateString);
  if (type === 'from_date') {
    let temp = { ...this.state.searchValues };
    temp.from_date = dateString;
    this.setState({ searchValues: temp }, () => { this.fetch(); })
  } 
  else if (type === 'to_date') {
    let temp2 = { ...this.state.searchValues };
    temp2.to_date = dateString;
    this.setState({ searchValues: temp2 }, () => { this.fetch(); })
  }
};

handleNavigation(record) {
  localStorage.setItem("reservationUpdate", record.id);
  this.props.history.push('/dashboard/reservationupdate')
}

handleChangePagination = (pagination, filters, sorter) => {
  const pager = { ...this.state.pagination };
  pager.current = pagination.current;
  this.setState({
    pagination: pager,
    pageNumber: pagination.current,
    sortOrder:sorter.order,
    sortField: sorter.field
  },()=>{

    // if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
    //   if (this.state.pageNumber  !== pager.current) {
    //     this.fetch({
    //       page: pagination.current,
    //     });
    //   }
    // } else {
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    // }
  });
}

handleChangeSearchValues = (prop) => (e) => {
  this.setState({[e.target.name]:e.target.value}, () => {
    this.fetch()
  })
}

componentDidMount() {
  this.screenPermission();
  this.fetch();
  /*
  const headers = getHeadersForHttpReq();
  axios.get(`${complete_URL}/customers/customers_list/${localStorage.getItem("client_id")}`, {headers})
  .then(res => {
    this.setState({customers:res.data.data})
  })
  .catch(cust_err=>{ 
    console.log('Error occured while fetching Customers data ->',cust_err);
  });*/

}//End of Method


// Should not call it,
deleteCategory = (record) => {
  const headers = getHeadersForHttpReq();

  axios.delete(`${complete_URL}/reservations/${record.id}`,{headers})
    .then(res => {// console.log(res)
      message.success(res.data.message,2)
      this.fetch();
    })
    .catch(err => {
      message.error('Reservation cannot be deleted',2)
      console.log('Error occured while deleting the Reservation ->',err)
    });
}

fetch = (params={}) => {
  const headers = getHeadersForHttpReq();
  const client = localStorage.getItem('client_id')
  
  let page,sortOrder
  
  if (params.page) { page = params.page - 1 }
  else { page = 0;}
  // if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
  // else { sortOrder = 'DESC' }

  if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
  else if (params.sortOrder == 'descend') { sortOrder = 'DESC' }
  else { sortOrder = 'DESC' }

  let data = {
    "client":client,
    "no": this.state.no || "",
    "from_date": this.state.from_date || "",
    "to_date": this.state.to_date || "",
    "status": this.state.status || "",
    "customer_name": this.state.customer_name || "",
    "customer_name_ar": this.state.customer_name_ar || "",
    "banquet_name": this.state.banquet_name || "",
    "banquet_name_ar": this.state.banquet_name_ar || "",
    "hall_name_ar": this.state.hall_name_ar || "",
    "hall_name": this.state.hall_name || "",
    "ph_num": this.state.ph_num || "",
    page,
    sortColumn: params.sortField || this.state.sortField || 'no',
    sortOrder,
    size:this.state.size
  }
  axios.post(`${complete_URL}/reservations/pagination_table`,data, {headers})
  .then(response => {
    let pagination = { ...this.state.pagination };
    pagination.total = response.data.totalElements;
    pagination.current = page +1;
    pagination.pageSize = this.state.size;
    this.setState({
      data: response.data.data,
      pagination,
    })
    // console.log('Reservations data-> ',response);
  })
  .catch(err => { console.log('Error occured while fetching Reservations->',err) })
}//End of Method

disabledStartDate = (startValue) => {
const endValue = this.state.endValue;
if (!startValue || !endValue) {
  return false;
}
return startValue.valueOf() > endValue.valueOf();
}

disabledEndDate = (endValue) => {
  const startValue = this.state.startValue;
  if (!endValue || !startValue) {
    return false;
  }
  return endValue.valueOf() <= startValue.valueOf();
}

onStartChange = (date, dateString, report_type) => {
  this.setState({ 'startValue': date },
    () => {
      if (this.state.startValue && this.state.endValue) {
        let st = moment(dateString).locale('en').format('YYYY-MM-DD');
        let ed = moment(this.state.endValue).locale('en').format('YYYY-MM-DD');
        this.setParamsDatesDuration(st, ed, false, report_type);
      }
  });
}

onEndChange = (date, dateString, report_type) => {
  this.setState({ 'endValue': date },
     () => {
        if (this.state.endValue && this.state.startValue) {
          let st = moment(this.state.startValue).locale('en').format('YYYY-MM-DD');
          let ed = moment(dateString).locale('en').format('YYYY-MM-DD');
          this.setParamsDatesDuration(st, ed, false, report_type);
        }
     });
}

handleChange = (value, report_type) => {
  // console.log('handleChange ->',value,report_type);
  if (report_type === 'resrv') {
     this.setState({ resrv_range_value: value });
  }
  // console.log('RangePicker ->',value);
};

handlePanelChange = (value, mode, report_type) => {
  let month1 = moment(value[0]).locale('en').format('MM');
  let year1 = moment(value[0]).locale('en').format('YYYY');

  let month2 = moment(value[1]).locale('en').format('MM');
  let year2 = moment(value[1]).locale('en').format('YYYY');
  if (month1 && month2 && year1 && year2) {
      if (report_type === 'resrv') {
        this.setState({
          resrv_range_value: value,
          resrv_mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
        });
        this.setParamsBetweenMonths(month1, month2, year1, year2, false, 'resrv');
     }
  }//End of if Range months and years found
};

handleStartOpenChange = (open) => {
  if (!open) {
    this.setState({ endOpen: true });
  }
}

handleEndOpenChange = (open) => {
  this.setState({ endOpen: open});
}
// Old method; Not to be used anymore!
downloadCombinedReservationReport = ()=>{
  this.setState({spinningMessage:'Generating Report...',spin : true})
  let opt = 'en';
  let client_id = localStorage.getItem('client_id');
  if(client_id){
    if(localStorage.getItem('lang') == 'AR'){opt = '-ar'}
    let api_main_part = API_URL.split('/')[2];
    let req_data = {
      api_main_part : api_main_part,
      port : pt,
      client_id : client_id,
      opt : opt,
      from_date : moment(this.state.startValue).locale('en').format('YYYY-MM-DD'),
      to_date : moment(this.state.endValue).locale('en').format('YYYY-MM-DD'),
    }
    axios.get(`${complete_URL}/reservation_reports/export_reserv_duration/`, {
      responseType: 'arraybuffer',
      headers: { 
        'Accept': 'application/pdf',
        'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
      },
      params: { data: JSON.stringify(req_data) }
   })
   .then(response => {
      if(response.status == 200){        
        const blob = new Blob([response.data],{type:'application/pdf'});//make available for download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let version = opt == 'en' ? 'Eng' : 'Arb';
        link.download = 'Reservation_report_'+req_data.from_date+'_'+req_data.to_date+'_'+version+'.pdf';
        link.click();
      }
      else if (response.status == 204){
        message.error("Reservations not found in provided duration!!",2);
      }
      this.setState({spin : false})
   })
   .catch(err => {
      this.setState({spin : false})
      message.error("Problem in generating Report!!",2);
      console.log(err)
   })
  }//End of if client_id
}

sendReservationReportMail = ()=>{
  this.setState({spinningMessage:'Sending Emails',spin : true})
  let opt = 'en';
  let client_id = localStorage.getItem('client_id');
  if(client_id && this.state.startValue && this.state.endValue){
    if(localStorage.getItem('lang') == 'AR'){opt = '-ar'}
    let api_main_part = API_URL.split('/')[2];
    let req_data = {
      api_main_part : api_main_part,
      client_id : client_id,
      opt : opt,
      from_date : moment(this.state.startValue).locale('en').format('YYYY-MM-DD'),
      to_date : moment(this.state.endValue).locale('en').format('YYYY-MM-DD'),
    };
    const headers =  getHeadersForHttpReq();
    axios.get(`${complete_URL}/reservation_reports/send_report_email/${JSON.stringify(req_data)}`, {headers})
    .then(response => { // console.log('Email response->',response)
      this.setState({spin : false})
      if(response.status == 200){        
        message.success('Email has sent to related Recipients!',2)
      }
      else if (response.status == 204) { //console.log('Email response->',response);
        message.error(response.statusText,2)
      }
    })
    .catch(email_err=>{
      console.log('email_err->',email_err)
      this.setState({spin : false})
      message.error("Problem in sending Emails!!",2);
    })
  }//End of if client_id
}

filterResrvDataOptionOnchange = (val) => {
  let resetObj = {
        date: '', from_date: '', to_date: '',
        month: '', year: '', yearOnly: '',
        month1: '',year1: '', status :'all',
        month2: '', year2: '',
        allData: val == 'aa' ? 'Yes' : ''
  }
  let new_report_obj = Object.assign(this.state.searchValues_resrv_Report, resetObj)//check reset
  this.setState({
    searchValues_resrv_Report: new_report_obj,
    filterResrvCalenderNode: val,
    showResrvCalenderFilter: true,
    disableDownloadResrvReport : val == 'aa' ? false : true
  });
}

setParamsOnDate = (date, disableDownloadResrvReport, report_type) => {
  if (date) {
     if (report_type === 'resrv') {
        let new_report_obj =  {...this.state.searchValues_resrv_Report, date };
        this.setState({ disableDownloadResrvReport, searchValues_resrv_Report: new_report_obj });
     }//End of if resrv
  }
}//End of Method

setParamsDatesDuration = (from_date, to_date, disableDownloadResrvReport, report_type) => {
  if (from_date && to_date) {
      if (report_type === 'resrv') {
        let new_report_obj = {...this.state.searchValues_resrv_Report, from_date: from_date, to_date: to_date };
        this.setState({ disableDownloadResrvReport, searchValues_resrv_Report: new_report_obj });
     }//End of if Resrv
  }
}

setParamsOneMonthOfYear = (month, year, disableDownloadResrvReport, report_type) => {
  if (report_type === 'resrv') {
     let new_report_obj = {...this.state.searchValues_resrv_Report, month: month, year: year };
     this.setState({ disableDownloadResrvReport, searchValues_resrv_Report: new_report_obj }); 
  }//End of if resrv
}//End of Method

setParamsBetweenMonths = (month1, month2, year1, year2, disableDownloadResrvReport, report_type) => {
   if (report_type === 'resrv') {
     let new_report_obj = {...this.state.searchValues_resrv_Report, month1: month1, month2: month2, year1: year1, year2: year2 };
     this.setState({ disableDownloadResrvReport, searchValues_resrv_Report: new_report_obj }); 
  }
}//End of Method

setParamsYear = (yearOnly, disableDownloadResrvReport, report_type) => {
  if (report_type === 'resrv') {
     let new_report_obj = {...this.state.searchValues_resrv_Report, yearOnly: yearOnly };
     this.setState({ disableDownloadResrvReport, searchValues_resrv_Report: new_report_obj }); 
  }
}//End of Method

generate_pdf_popup = (data, file_name)=>{
  const blob = new Blob([data], { type: 'application/pdf' });//make available for download
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = file_name;
  /*link.click();*/
  link.target = '_blank';
  window.open(link);
}

generateGenericReport=(req_data, file_name)=>{
  if(report_url){
      let headers2 = getHeadersForPDFreport();
      let user_name= localStorage.getItem('user_name');
      Object.assign(req_data, { status: this.state.searchValues_resrv_Report.status, user_name });
      
      axios({url: `${complete_URL}/${report_url}`, headers: { ...headers2 },
              method: 'post', data: req_data, responseType: 'arraybuffer'
      })
      .then(response => {
          this.setState({ spin: false });
          if (response.data.byteLength > 0 && response.status ==200) {
            this.generate_pdf_popup( response.data, file_name);
          }
          else if (response.statusText === 'Provided incomplete data') {
            message.error(this.props.language._REPORTS.MESSAGE.RESRVDATANOTPROVIDEDERROR,3);
          }
          else if (response.status ==201){
            if(response.statusText === 'Reservations not found in this duration!!' && req_data.opt === '-ar'){
              message.error( 'التحفظات غير موجودة في هذه المدة',2);
            }
            else
              message.error(response.statusText,3);
          }
          else if (response.statusText === 'No Content' || response.statusText === '[object Object]') {
                  message.error(this.props.language._REPORTS.MESSAGE.RESRVDATANOTFOUNDERROR,3);
          } 
          else {  message.error(response.statusText,3); }
      })
      .catch(res_err => {
          this.setState({ spin: false });
          console.log('Error occured while generating resrv_report->',res_err);
          if (res_err.statusText === 'Reservations not found in this duration!!') {
            message.error(this.lang.MESSAGE.RESRVDATANOTFOUNDERROR,3);
          }
          else if (res_err.statusText === 'Provided incomplete data!!') {
              message.error(this.lang.MESSAGE.RESRVREPORTDATANOTPROVIDEDERROR,3);
          }
          else if (res_err.statusText === 'No Content' || res_err.statusText === '[object Object]') {
                  message.error(this.lang.MESSAGE.RESRVDATANOTFOUNDERROR,3);
          } 
          else
            message.error(this.props.language._REPORTS.MESSAGE.RESRVREPORTGENERATEFAILURE/*err.message*/,3);
      });
  }//End of report_url
}//End of Reservation list Generic Method

getReportBasicData = ()=>{
  let opt = 'en';
  const client_id = localStorage.getItem('client_id');
  const user_id = localStorage.getItem('user_id');
  const user_name= localStorage.getItem('user_name');

  if (localStorage.getItem('lang') == 'AR') { opt = '-ar' }
  let api_main_part = API_URL.split('/')[2];
  let req_data = {
     api_main_part, port : pt, client_id, opt,
     show_rules : false, user_name, user_id
  }
  if (localStorage.getItem('is_contr') != 1) {
     Object.assign(req_data,{ is_contr : 0});
  } 
  return req_data;
}

downloadOnDateData = (date, report_type) => {
  let req_data = this.getReportBasicData();
  if (req_data.client_id) {
      Object.assign(req_data, {date });
      let file_name = report_name + date + '.pdf';
      this.generateGenericReport(req_data, file_name);
  }//End of ClientID exists
  else { message.error(this.props.language.LOGINERROR,3);  }
}

downloadBetweenDatesData = (from_date, to_date, report_type) => {
  let req_data = this.getReportBasicData(); 
  if (req_data.client_id) { 
      Object.assign(req_data, {from_date, to_date });
      let file_name = report_name + from_date+'_to_'+to_date+ '.pdf';
      this.generateGenericReport(req_data, file_name);
  }//End of ClientID exists
  else { message.error(this.props.language.LOGINERROR,3);  }
}//End of Method

downloadOneMonthOfYearData = (month, year, report_type) => {
  let req_data = this.getReportBasicData(); 
  if (req_data.client_id) { 
      Object.assign(req_data, {month, year });
      let file_name = report_name + month + '_' + year+ '.pdf';
      this.generateGenericReport(req_data, file_name);
  }//End of ClientID exists
  else { message.error(this.props.language.LOGINERROR,3);  }
}

downloadBetweenMonthsData = (month1, month2, year1, year2, report_type) => {
  let req_data = this.getReportBasicData(); 
  if (req_data.client_id) { 
      Object.assign(req_data, {month1, month2, year1, year2 });
      let file_name = report_name + month1 + '_' + year1 + '_to_' + month2 + '_' + year2+ '.pdf';
      this.generateGenericReport(req_data, file_name);
  }//End of ClientID exists
  else { message.error(this.props.language.LOGINERROR,3);  }
}

downloadYearOnlyData = (yearOnly, report_type) => {
  let req_data = this.getReportBasicData(); 
  if (req_data.client_id) { 
      Object.assign(req_data, {yearOnly });
      let file_name = report_name + yearOnly+ '.pdf';
      this.generateGenericReport(req_data, file_name);
  }//End of ClientID exists
  else { message.error(this.props.language.LOGINERROR,3); }
}

downloadAllData = (report_type) => {
  let req_data = this.getReportBasicData(); 
  if (req_data.client_id) { 
      let file_name = report_name +'allData.pdf';
      this.generateGenericReport(req_data, file_name);
  }//End of ClientID exists
  else { message.error(this.props.language.LOGINERROR,3); }
}

downloadCombinedReservationReportUpdated = ( report_type)=>{
  let msg = this.state.spin_msg;
  if (localStorage.getItem('lang') == 'AR') { msg = this.state.spin_msg_ar };
  this.setState({ spinningMessage: msg, spin: true })
      let { status,date, from_date, to_date, month, year, month1, month2, year1, year2, yearOnly, allData } = this.state.searchValues_resrv_Report;
      if (date ) {
        this.downloadOnDateData(date, report_type);
        this.setParamsOnDate('', true, report_type);
      }
      else if (from_date && to_date ) {
          this.downloadBetweenDatesData(from_date, to_date, report_type);
          this.setParamsDatesDuration('', '', true, report_type);
      }
      else if (month && year) {
         this.downloadOneMonthOfYearData(month, year, report_type);
         this.setParamsOneMonthOfYear('', '', true, report_type);
      }
      else if (month1 && month2 && year1 && year2) {
         this.downloadBetweenMonthsData(month1, month2, year1, year2, report_type);
         this.setParamsBetweenMonths('', '', '', '', true, report_type);
      }
      else if (yearOnly) {
         this.downloadYearOnlyData(yearOnly, report_type);
         this.setParamsYear('', true, report_type);
      }
      else if (allData) {
         //PIE chart data is aleady displayed on Select Action listener
         this.downloadAllData(report_type);
         this.setState({
          searchValues_resrv_Report: {
            date: '', from_date: '', to_date: '',
            month: '', year: '', yearOnly: '',
            month1: '',year1: '', status,
            month2: '', year2: '', allData: ''
            },
            disableDownloadResrvReport: true,
         })
      }
}//End of Method

sendReservationReportMailUpdated =(report_type)=>{
  let req_data = this.getReportBasicData(); 
  let spinningMessage = this.state.spin_email_msg;
  if (req_data.client_id) {
    const headers = getHeadersForHttpReq();
    if (req_data.opt === '-ar') { spinningMessage = this.state.spin_email_msg_ar; }
    this.setState({spin : true, spinningMessage });
    if (report_type === 'resrv') {
      Object.assign(req_data, this.state.searchValues_resrv_Report);
      axios({ url: `${complete_URL}/reservation_reports/send_report_email_duration`,
        method: 'post', data: req_data, headers
      })
      .then(email_response => {  //console.log('Email response->',email_response)
        this.setState({spin : false})
        if(email_response.status == 200 && email_response.statusText === 'Notification sent to all Recipients Email'){
          if (req_data.opt === '-ar') {
            let m = 'تم إرسال الإخطار إلى جميع رسائل البريد الإلكتروني للمستلم';
            message.success( m,3);
          }
          else{ message.success(email_response.statusText,3);}
          
        }
        else if (email_response.status ==204){
          if(email_response.statusText === 'Reservations not found in this duration!!'){
            if (req_data.opt === '-ar') {
              let m = 'التحفظات غير موجودة في هذه المدة';
              message.error( m,3);
            }
            else{ message.error(email_response.statusText,3);}
          }
          else if (email_response.statusText === 'Notification Found but Recipients Email does not exists!'){
            if (req_data.opt === '-ar') {
              let m = "تم العثور على الإشعار ولكن البريد الإلكتروني للمستلمين غير موجود";
              message.error( m,3);
            }
            else{ message.error(email_response.statusText,3);} 
          }
          else if (email_response.statusText === 'Notification does not exists'){
            if (req_data.opt === '-ar') {
              let m = "الإخطار غير موجود";
              message.error( m,3);
            }
            else{ message.error(email_response.statusText,3);} 
          }
        }
        else  { //console.log('Email response->',response);
          message.error(email_response.statusText,2)
        }
      })
      .catch(email_err=>{
        console.log('Error occured while sending combined report by mail err->',email_err)
        this.setState({spin : false})
        message.error(email_err.statusText,3);
      });
    }
  }//End of ClientID exists
  else { message.error(this.msg.LOGINERROR,2); }
}

componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row gutter={24} id="clientTableHeader">
        <Col span={7}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
        {/* <Col span={7}></Col> */}
        <Col span={16} style={{paddingRight:'3%'}}>
          <Link to="/dashboard/addreservation">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
        <Col span={1}></Col>
      </Row>
    }
    else {
      this.CustomRow = <Row gutter={24} id="clientTableHeader">
        <Col span={2}></Col>
        <Col span={4}>
          <Link to="/dashboard/addreservation">
            <Button type="primary" style={{margin: '0 auto'}}>{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
        <Col span={17} style={{float:"right", marginRight:'2%'}}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
      </Row>
    }
}

render() {
  const columns = 
  [
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.NO}
          <Search
            placeholder={this.lang.TABLEHEADERS.NO}
            onChange={this.handleChangeSearchValues("no")}
            name="no"
          />
        </span>
      ),
      dataIndex: 'no',
      key: 'no',
      width:'10%',
      sorter: (a, b) => a.no.length - b.no.length,
      sortOrder: this.state.sortField === "no" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 20%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.NO}
          <Search
            placeholder={this.lang.TABLEHEADERS.NO}
            onChange={this.handleChangeSearchValues("no")}
            name="no"
          />
        </span>
      ),
      dataIndex: 'no',
      key: 'no',
      width:'10%',
      align : 'right',
      sorter: (a, b) => a.no.length - b.no.length,
      sortOrder: this.state.sortField === "no" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 20%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    },
    (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.CUSTOMER}
            <Search
              placeholder={this.lang.TABLEHEADERS.CUSTOMER}
              onChange={this.handleChangeSearchValues("customer_name")}
              name="customer_name"
            />
          </span>
        ),
        dataIndex: 'customer_name',
        sorter: (a, b) => a.customer_name.length - b.customer_name.length,
        sortOrder: this.state.sortField === "customer_name" && this.state.sortOrder,
        render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
      } : 
      {
      title: (
        <span>{this.lang.TABLEHEADERS.CUSTOMER}
          <Search
            placeholder={this.lang.TABLEHEADERS.CUSTOMER}
            onChange={this.handleChangeSearchValues("customer_name_ar")}
            name="customer_name_ar"
          />
        </span>
      ),
      dataIndex: 'customer_name_ar',
      align : 'right',
      sorter: (a, b) => a.customer_name_ar.length - b.customer_name_ar.length,
      sortOrder: this.state.sortField === "customer_name_ar" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    },
    (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.CUSTOMER_PHONE}
            <Search
              placeholder={this.lang.TABLEHEADERS.CUSTOMER_PHONE}
              onChange={this.handleChangeSearchValues("ph_num")}
              name="ph_num"
            />
          </span>
        ),
        dataIndex: 'ph_num',
        width:'13%',
        sorter: (a, b) => a.ph_num.length - b.ph_num.length,
        sortOrder: this.state.sortField === "ph_num" && this.state.sortOrder,
        render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    }
    : {
      title: (
        <span>{this.lang.TABLEHEADERS.CUSTOMER_PHONE}
          <Search
            placeholder={this.lang.TABLEHEADERS.CUSTOMER_PHONE}
            onChange={this.handleChangeSearchValues("ph_num")}
            name="ph_num"
          />
        </span>
      ),
      dataIndex: 'ph_num',
      width:'13%',
      align : 'right',
      sorter: (a, b) => a.ph_num.length - b.ph_num.length,
      sortOrder: this.state.sortField === "ph_num" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text }</button></div>,
    },
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.STATUS}
          <Search
            placeholder={this.lang.TABLEHEADERS.STATUS}
            onChange={this.handleChangeSearchValues("status")}
            name="status"
          />
        </span>
      ),
      dataIndex: 'status',
      key: 'status',
      width:'10.5%',
      sorter: (a, b) => a.status.length - b.status.length,
      sortOrder: this.state.sortField === "status" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 7%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text=== 'reserved' ? 'RESERVED' : text === 'pending' ? 'PENDING' : 'CANCELED'}</button></div>,
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.STATUS}
          <Search
            placeholder={this.lang.TABLEHEADERS.STATUS}
            onChange={this.handleChangeSearchValues("status")}
            name="status"
          />
        </span>
      ),
      dataIndex: 'status',
      key: 'status',
      align : 'right',
      width:'11%',
      sorter: (a, b) => a.status.length - b.status.length,
      sortOrder: this.state.sortField === "status" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 7%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text=== 'reserved' ? 'محجوز' : text === 'pending' ? 'قيد الانتظار' : 'ألغيت'}</button></div>,
    },
    (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.BANQUET}
            <Search
              placeholder={this.lang.TABLEHEADERS.BANQUET}
              onChange={this.handleChangeSearchValues("banquet_name")}
              name="banquet_name"
            />
          </span>
        ),
        dataIndex: 'banquet_name',
        width:'12%',
        sorter: (a, b) => a.banquet_name.length - b.banquet_name.length,
        sortOrder: this.state.sortField === "banquet_name" && this.state.sortOrder,
        render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    }
    : {
      title: (
        <span>{this.lang.TABLEHEADERS.BANQUET}
          <Search
            placeholder={this.lang.TABLEHEADERS.BANQUET}
            onChange={this.handleChangeSearchValues("banquet_name_ar")}
            name="banquet_name_ar"
          />
        </span>
      ),
      dataIndex: 'banquet_name_ar',
      align : 'right',
      width:'12%',
      sorter: (a, b) => a.banquet_name_ar.length - b.banquet_name_ar.length,
      sortOrder: this.state.sortField === "banquet_name_ar" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    },
    (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.HALL}
            <Search
              name="hall_name"
              placeholder={this.lang.TABLEHEADERS.HALL}
              onChange={this.handleChangeSearchValues("hall_name")}
            />
          </span>
        ),
        dataIndex: 'hall_name',
        width:'12%',
        sorter: (a, b) => a.hall_name.length - b.hall_name.length,
        sortOrder: this.state.sortField === "hall_name" && this.state.sortOrder,
        render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    }
    : {
      title: (
        <span>{this.lang.TABLEHEADERS.HALL}
          <Search
            name="hall_name_ar"
            placeholder={this.lang.TABLEHEADERS.HALL}
            onChange={this.handleChangeSearchValues("hall_name_ar")}
          />
        </span>
      ),
      dataIndex: 'hall_name_ar',
      align : 'right',
      width:'12%',
      sorter: (a, b) => a.hall_name_ar.length - b.hall_name_ar.length,
      sortOrder: this.state.sortField === "hall_name_ar" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 4%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    },
    (localStorage.getItem('lang') === 'EN') ?  
    {
      title: (
        <span>{this.lang.TABLEHEADERS.FROMDATE}
          <DatePicker
            onChange={(e) => this.setState({from_date:e},() => {this.fetch()})}
            placeholder={this.lang.TABLEHEADERS.FROMDATE}
          />
        </span>
      ),
      dataIndex: 'from_date',
      key: 'from_date',
      width : '13%',
      sorter: (a, b) => a.from_date.length - b.from_date.length,
      sortOrder: this.state.sortField === "from_date" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.FROMDATE}
          <DatePicker
            onChange={(e) => this.setState({from_date:e},() => {this.fetch()})}
            placeholder={this.lang.TABLEHEADERS.FROMDATE}
          />
        </span>
      ),
      dataIndex: 'from_date',
      key: 'from_date',
      align : 'right',
      width : '13%',
      sorter: (a, b) => a.from_date.length - b.from_date.length,
      sortOrder: this.state.sortField === "from_date" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    },
    (localStorage.getItem('lang') === 'EN') ?  
    {
      title: (
        <span>{this.lang.TABLEHEADERS.TODATE}
          <DatePicker
            onChange={(e) => this.setState({to_date:e},() => {this.fetch()})}
            placeholder={this.lang.TABLEHEADERS.TODATE}
          />
        </span>
      ),
      dataIndex: 'to_date',
      key: 'to_date',
      width : '13%',
      sorter: (a, b) => a.to_date.length - b.to_date.length,
      sortOrder: this.state.sortField === "to_date" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.TODATE}
          <DatePicker
            onChange={(e) => this.setState({to_date:e},() => {this.fetch()})}
            placeholder={this.lang.TABLEHEADERS.TODATE}
          />
        </span>
      ),
      dataIndex: 'to_date',
      key: 'to_date',
      align : 'right',
      width : '13%',
      sorter: (a, b) => a.to_date.length - b.to_date.length,
      sortOrder: this.state.sortField === "to_date" && this.state.sortOrder,
      render: (text, record) =><div style={{margin:'0 10%'}}><button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button></div>,
    }
  ];

    return (
        <Spin tip={this.state.spinningMessage} spinning = {this.state.spin}>
          {this.CustomRow}
          {
            this.lang._DIRECTION === 'ltr' &&
          <Row gutter={24} style={{margin : '0 25px 14px 25px'}}>
            {/* <Col span={2}></Col> */}
            <Col span={4}>
              <Select suffixIcon={[<Icon key={'resrv2'} type="filter" />]}
                  onChange={(value) => { this.filterResrvDataOptionOnchange(value) }}
                  style={{ width: '100%' }}
                  placeholder={this.props.language.FILTER_OPTION}>
                  <Select.Option value="d"  key={'1a'}>{this.lang.REPORT.DATE_OPTION}</Select.Option>
                  <Select.Option value="ds" key={'2a'}>{this.lang.REPORT.DATEL}</Select.Option>
                  <Select.Option value="m"  key={'3a'}>{this.lang.REPORT.MONTHLY_OPTION}</Select.Option>
                  <Select.Option value="y"  key={'4a'}>{this.lang.REPORT.YEARLY_OPTION}</Select.Option>
                  <Select.Option value="mm" key={'5a'}>{this.lang.REPORT.MONTHL}</Select.Option>
                  <Select.Option value="aa" key={'6a'}>{this.lang.REPORT.ALL}</Select.Option>
              </Select>
           </Col>
            <Col span={11} style={{zIndex:99}}>
            <span>
              {
                this.state.showResrvCalenderFilter &&
                    this.state.filterResrvCalenderNode === 'd' ?
                    <DatePicker format="YYYY-MM-DD" onChange={(e) => { this.setParamsOnDate(moment(e).locale('en').format('YYYY-MM-DD'), false, 'resrv') }} placeholder={this.lang.REPORT.DATE_OPTION} style={{ width: '70%' }} />
                    :
                    this.state.filterResrvCalenderNode === 'ds' ?
                      (<>
                          <DatePicker
                            disabledDate={this.disabledStartDate}
                            format="YYYY-MM-DD"
                            style={{ width: '49%' }}
                            placeholder={this.lang.REPORT.STARTDATE}
                            onChange={(date, dateString) => { this.onStartChange(date, dateString, 'resrv') }}
                            onOpenChange={this.handleStartOpenChange}
                          />

                          <DatePicker
                            disabledDate={this.disabledEndDate}
                            format="YYYY-MM-DD"
                            style={{ width: '49%' }}
                            placeholder={this.lang.REPORT.ENDDATE}
                            onChange={(date, dateString) => { this.onEndChange(date, dateString, 'resrv') }}
                            open={this.state.endOpen}
                            onOpenChange={this.handleEndOpenChange}
                          />
                      </>)

                      :
                      this.state.filterResrvCalenderNode === 'm' ?
                          <MonthPicker style={{width: '49%' }} placeholder={this.lang.REPORT.MONTHLY_OPTION} onChange={(e) => { this.setParamsOneMonthOfYear(moment(e).locale('en').format('MM'), moment(e).locale('en').format('YYYY'), false, 'resrv') }} />
                          : this.state.filterResrvCalenderNode === 'y' ?
                            <YearPicker style={{width: '49%' }} onChange={(e) => { this.setParamsYear(e, false, 'resrv') }} placeholder={this.lang.REPORT.YEARLY_OPTION} />
                            :
                            this.state.filterResrvCalenderNode === 'mm' ?
                                <RangePicker
                                  placeholder={[this.lang.REPORT.STARTMONTH, this.lang.REPORT.ENDMONTH]}
                                  format="YYYY-MM"
                                  value={this.state.resrv_range_value}
                                  mode={this.state.resrv_mode}
                                  onChange={(value) => { this.handleChange(value, 'resrv') }}
                                  onPanelChange={(value, mode) => { this.handlePanelChange(value, mode, 'resrv') }}
                                />

                                : ''
              }
              </span>

              {/* <DatePicker
                disabledDate={this.disabledStartDate}
                format="YYYY-MM-DD"
                placeholder={this.lang.REPORT.STARTDATE}
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
                style={{ width: '48%' }} 
              /> 
              <DatePicker
                disabledDate={this.disabledEndDate}
                format="YYYY-MM-DD"
                placeholder={this.lang.REPORT.ENDDATE}
                onChange={this.onEndChange}
                open={this.state.endOpen}
                onOpenChange={this.handleEndOpenChange}
                style={{ width: '48%' }} 
              /> */}
            </Col>
            <Col span={9}>
              <Button   
                type="primary" 
                style={{marginLeft:'6%'}} 
                disabled = {this.state.disableDownloadResrvReport} 
                // onClick={()=>{this.downloadCombinedReservationReport()}} 
                onClick={()=>{this.downloadCombinedReservationReportUpdated('resrv')}}
                icon="download" 
                size={'default'}>
                               {this.props.language.DOWNLOAD_REPORT}
              </Button>
            
              <Button  
                type="primary"                 
                style={{marginLeft:'6%'}} 
                disabled ={this.state.disableDownloadResrvReport} 
                // onClick={()=>{this.sendReservationReportMail()}} 
                onClick={()=>{this.sendReservationReportMailUpdated('resrv')}} 
                icon="mail" 
                size={'default'}>
                                {this.props.language.SEND_EMAIL}
              </Button>
            </Col>
          </Row>
          }
          {
            this.lang._DIRECTION === 'rtl' &&
          <Row gutter={24} style={{margin : '0 25px 14px 25px'}}>
            <Col span={9} style={{textAlign: 'right'}}>
              <Button   
                type="primary" 
                // style={{marginLeft:'5px', marginRight:'4%'}}
                disabled = {this.state.disableDownloadResrvReport} 
                // onClick={()=>{this.downloadCombinedReservationReport()}} 
                onClick={()=>{this.downloadCombinedReservationReportUpdated('resrv')}}
                icon="download" 
                size={'default'}>
                               {this.props.language.DOWNLOAD_REPORT}
              </Button>
            
              <Button  
                type="primary"                 
                style={{marginLeft:'5px', marginRight:'4%'}} 
                disabled ={this.state.disableDownloadResrvReport} 
                // onClick={()=>{this.sendReservationReportMail()}} 
                onClick={()=>{this.sendReservationReportMailUpdated('resrv')}} 
                icon="mail" 
                size={'default'}>
                                {this.props.language.SEND_EMAIL}
              </Button>
            </Col>
            {/* <Col span={2}></Col> */}
            
            <Col span={10} style={{zIndex:99}}>
            <span>
              {
                this.state.showResrvCalenderFilter &&
                    this.state.filterResrvCalenderNode === 'd' ?
                    <DatePicker format="YYYY-MM-DD" onChange={(e) => { this.setParamsOnDate(moment(e).locale('en').format('YYYY-MM-DD'), false, 'resrv') }} placeholder={this.lang.REPORT.DATE_OPTION} style={{ width: '70%' }} />
                    :
                    this.state.filterResrvCalenderNode === 'ds' ?
                      (<React.Fragment>
                          <DatePicker
                            disabledDate={this.disabledStartDate}
                            format="YYYY-MM-DD"
                            style={{ width: '49%' }}
                            placeholder={this.lang.REPORT.STARTDATE}
                            onChange={(date, dateString) => { this.onStartChange(date, dateString, 'resrv') }}
                            onOpenChange={this.handleStartOpenChange}
                          />

                          <DatePicker
                            disabledDate={this.disabledEndDate}
                            format="YYYY-MM-DD"
                            style={{ width: '49%' }}
                            placeholder={this.lang.REPORT.ENDDATE}
                            onChange={(date, dateString) => { this.onEndChange(date, dateString, 'resrv') }}
                            open={this.state.endOpen}
                            onOpenChange={this.handleEndOpenChange}
                          />
                      </React.Fragment>)

                      :
                      this.state.filterResrvCalenderNode === 'm' ?
                          <MonthPicker style={{width: '49%' }} placeholder={this.lang.REPORT.MONTHLY_OPTION} onChange={(e) => { this.setParamsOneMonthOfYear(moment(e).locale('en').format('MM'), moment(e).locale('en').format('YYYY'), false, 'resrv') }} />
                          : this.state.filterResrvCalenderNode === 'y' ?
                            <YearPicker style={{width: '49%' }} onChange={(e) => { this.setParamsYear(e, false, 'resrv') }} placeholder={this.lang.REPORT.YEARLY_OPTION} />
                            :
                            this.state.filterResrvCalenderNode === 'mm' ?
                                <RangePicker
                                  placeholder={[this.lang.REPORT.STARTMONTH, this.lang.REPORT.ENDMONTH]}
                                  format="YYYY-MM"
                                  value={this.state.resrv_range_value}
                                  mode={this.state.resrv_mode}
                                  onChange={(value) => { this.handleChange(value, 'resrv') }}
                                  onPanelChange={(value, mode) => { this.handlePanelChange(value, mode, 'resrv') }}
                                />

                                : ''
              }
              </span>

              {/* <DatePicker
                disabledDate={this.disabledStartDate}
                format="YYYY-MM-DD"
                placeholder={this.lang.REPORT.STARTDATE}
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
                style={{ width: '48%' }} 
              /> 
              <DatePicker
                disabledDate={this.disabledEndDate}
                format="YYYY-MM-DD"
                placeholder={this.lang.REPORT.ENDDATE}
                onChange={this.onEndChange}
                open={this.state.endOpen}
                onOpenChange={this.handleEndOpenChange}
                style={{ width: '48%' }} 
              /> */}
            </Col>
            <Col span={5}>
              <Select suffixIcon={[<Icon key={'resrv'} type="filter" />]}
                  onChange={(value) => { this.filterResrvDataOptionOnchange(value) }}
                  style={{ width: '100%' }}
                  placeholder = {this.props.language.FILTER_OPTION}>
                  <Select.Option style={{textAlign:'right'}} value="d"  key={11}>{this.lang.REPORT.DATE_OPTION}</Select.Option>
                  <Select.Option style={{textAlign:'right'}} value="ds" key={22}>{this.lang.REPORT.DATEL}</Select.Option>
                  <Select.Option style={{textAlign:'right'}} value="m"  key={33}>{this.lang.REPORT.MONTHLY_OPTION}</Select.Option>
                  <Select.Option style={{textAlign:'right'}} value="y"  key={44}>{this.lang.REPORT.YEARLY_OPTION}</Select.Option>
                  <Select.Option style={{textAlign:'right'}} value="mm" key={55}>{this.lang.REPORT.MONTHL}</Select.Option>
                  <Select.Option style={{textAlign:'right'}} value="aa" key={66}>{this.lang.REPORT.ALL}</Select.Option>
              </Select>
           </Col>
          </Row>
          }
          <Row id="clientsTable">
            <Col span={23} style={{margin : '0 25px 14px 25px'}}>
              <Table
                columns={columns}
                rowKey="no"
                dataSource={this.state.data}
                onChange={this.handleChangePagination}
                pagination={this.state.pagination}
              />
            </Col>
          </Row>
        </Spin>
    )
  }
}

export default Reservations;