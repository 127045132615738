import React, { Component } from 'react';
import { Row, Col, Table, Button, message, Input, DatePicker, Modal } from 'antd'
import { Link } from 'react-router-dom';
import axios from 'axios';
// import moment from 'moment'
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
import IncomeType from './IncomeType';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Incomes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modelVisible: false,
      // data: {},
      data: [],
      pagination: {},
      page: 1,
      size: 5,
      // sortField:"no",
			sortField:"date",
      sortOrder:"ascend",
      searchValues: {
        no:'',
        amount: '',
        income_type: '',
        income_type_ar: '',
        note: '',
        bill:'',
        date:'',
        orderBy : '',
        column_name :'no'
      },
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._INCOME.SETUP;
    this.income_type_lang = props.language._INCOMETYPE;
    this.msg = props.language._INCOME.MESSAGE;

    this.columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NO}
          <Search
            placeholder={this.lang.TABLEHEADERS.NO}
            onChange={this.handleChangeSearchValues("no")}
          />
        </span>
      ),
      dataIndex: 'no',
      key: 'no',
      width: '15%',
      align : 'left',
      sorter: (a, b) => this.compareByAlph(a.no, b.no),
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.NO}
        <Search
          placeholder={this.lang.TABLEHEADERS.NO}
          onChange={this.handleChangeSearchValues("no")}
        />
      </span>
    ),
    dataIndex: 'no',
    key: 'no',
    width: '15%',
    align : 'right',
    sorter: (a, b) => this.compareByAlph(a.no, b.no),
    },
      /*{
        title: (
          <span>{this.lang.TABLEHEADERS.DATE}
          <Search
            placeholder="Search By Date"
            onChange={this.handleChangeSearchValues("date")}

          />
        </span>
      ),
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      sorter: (a, b) => this.compareByAlph(a.date, b.date),
      render:(text) => {
       return moment(text).format('YYYY-MM-DD')
      }
    }*/
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.DATE}
          <DatePicker placeholder={this.lang.TABLEHEADERS.DATE} onChange={this.onChangeDate("date")} />
        </span>
      ),
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      align : 'left',
      sorter: (a, b) => this.compareByAlph(a.date, b.date),
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.DATE}
          <DatePicker placeholder={this.lang.TABLEHEADERS.DATE} onChange={this.onChangeDate("date")} />
        </span>
      ),
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      align : 'right',
      sorter: (a, b) => this.compareByAlph(a.date, b.date),
    },
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.TYPE}
          <Search
            placeholder={this.lang.TABLEHEADERS.TYPE}
            onChange={this.handleChangeSearchValues("income_type")}
          />
        </span>
      ),
      dataIndex: 'income_type',
      key: 'income_type',
      width: '15%',
      align : 'left',
      sorter: (a, b) => this.compareByAlph(a.income_type, b.income_type),
      render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.TYPE}
          <Search
            placeholder={this.lang.TABLEHEADERS.TYPE}
            onChange={this.handleChangeSearchValues("income_type_ar")}
          />
        </span>
      ),
      dataIndex: 'income_type_ar',
      key: 'income_type_ar',
      width: '15%',
      align : 'right',
      sorter: (a, b) => this.compareByAlph(a.income_type_ar, b.income_type_ar),
      render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
    }, 
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.AMOUNT}
          <Search
            placeholder={this.lang.TABLEHEADERS.AMOUNT}
            onChange={this.handleChangeSearchValues("amount")}
          />
        </span>
      ),
      dataIndex: 'amount',
      key: 'amount',
      width: '14%',
      align : 'left',
      sorter: (a, b) => this.compareByAlph(a.amount, b.amount),
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.AMOUNT}
          <Search
            placeholder={this.lang.TABLEHEADERS.AMOUNT}
            onChange={this.handleChangeSearchValues("amount")}
          />
        </span>
      ),
      dataIndex: 'amount',
      key: 'amount',
      width: '14%',
      align : 'right',
      sorter: (a, b) => this.compareByAlph(a.amount, b.amount),
    },
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.NOTE}
          <Search
            placeholder={this.lang.TABLEHEADERS.NOTE}
            onChange={this.handleChangeSearchValues("note")}
          />
        </span>
      ),
      dataIndex: 'note',
      key: 'note',
      width: '17%',
      align : 'left',
      sorter: (a, b) => this.compareByAlph(a.note, b.note),
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.NOTE}
          <Search
            placeholder={this.lang.TABLEHEADERS.NOTE}
            onChange={this.handleChangeSearchValues("note")}
          />
        </span>
      ),
      dataIndex: 'note',
      key: 'note',
      width: '17%',
      align : 'right',
      sorter: (a, b) => this.compareByAlph(a.note, b.note),
    },
    (localStorage.getItem('lang') === 'EN') ?
    {
      title: (
        <span>{this.lang.TABLEHEADERS.BILLNO}
          <Search
            placeholder={this.lang.TABLEHEADERS.BILLNO}
            onChange={this.handleChangeSearchValues("bill")}
          />
        </span>
      ),
      dataIndex: 'BILL_NO',
      key: 'BILL_NO',
      width: '15%',
      align : 'left',
      sorter: (a, b) => this.compareByAlph(a.BILL_NO, b.BILL_NO),
    }:
    {
      title: (
        <span>{this.lang.TABLEHEADERS.BILLNO}
          <Search
            placeholder={this.lang.TABLEHEADERS.BILLNO}
            onChange={this.handleChangeSearchValues("bill")}
          />
        </span>
      ),
      dataIndex: 'BILL_NO',
      key: 'BILL_NO',
      width: '15%',
      align : 'right',
      sorter: (a, b) => this.compareByAlph(a.BILL_NO, b.BILL_NO),
    },
    {
      title: `${this.lang.TABLEHEADERS.ACTION}`,
      render: (text, record) => (
        <span>
          <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
        </span>
      ),
      width: '8%',
      align : 'center',
      key: 'action',
    }
    ];
  }

  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }//End of Method

  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    localStorage.setItem('updateIncome', JSON.stringify(record))
    this.props.history.push('/dashboard/incomeUpdate')
  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      pageNumber: pagination.current
    });

    if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
      if (this.state.pageNumber  !== pager.current) {
        this.fetch({
          page: pagination.current,
        });
      }
    } else {
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }
    /*let pager = { ...this.state.pagination };
    pager.current = pagination.current;
    
    let searchValues = { ...this.state.searchValues}
    searchValues.column_name = sorter.field
    searchValues.orderBy = sorter.order

    this.setState({
      page: pager.current,
      pagination: pager,
      searchValues
    }, () => {
      this.fetch();
    })*/

  }//End of method

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
    });
  }
  
  onChangeDate = (type) => (date, dateString) => {
    if (type === 'date') {
      let temp = { ...this.state.searchValues };
      temp.date = dateString;
      this.setState({
        searchValues: temp
      }, () => {
        this.fetch();
      })
    }
  };

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/incomes/${record.id}`,{headers})
      .then(res => {// console.log(res)
        message.success(this.msg.DELETESUCCESS);
        this.fetch()
      })
      .catch(err => {
        message.error(this.msg.DELETEFAILURE,3);
        console.log('Error occured while deleting the Income->',err)
      })
  }

  componentDidMount() {
    this.fetch();
    this.setComponentDirection();
  }

  fetch = (params={}) => {
    const headers = getHeadersForHttpReq();
    const client_id = localStorage.getItem('client_id');
    let page,sortOrder ;
      if (params.page) { page = params.page - 1 }
      else { page = 0;}

      if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
      else { sortOrder = 'DESC' }
      
      let data ={
        client_id ,
        amount: this.state.searchValues.amount || '',
        income_type: this.state.searchValues.income_type || '',
        income_type_ar: this.state.searchValues.income_type_ar || '',
        note: this.state.searchValues.note || '',
        bill: this.state.searchValues.bill || '',
        no: this.state.searchValues.no || '',
        date: this.state.searchValues.date || '',
        page,
        sortColumn: params.sortField || this.state.sortField ,
        sortOrder,
        size:this.state.size,
        orderBy: '',
        column_name: 'no'
      }
      let is_contr = localStorage.getItem('is_contr');
      if ( is_contr != 1) {
        Object.assign(data,{contractor_id : null, is_contr : 0})
      }
      else{
        const contractor_id = JSON.parse(localStorage.getItem('updateContractor')).id
        Object.assign(data,{contractor_id, client_id : null, is_contr : 1})
      }
      // console.log('Sending data to api ->',data);
      axios.post(`${complete_URL}/incomes/pagination_table`,data, {headers})
      .then(response => { // console.log('response of exp new pagination ->',response)
        let pagination = {};
        Object.assign(pagination,{
          ...this.state.pagination,
          total : response.data.totalElements,
          current : page +1,
          pageSize : this.state.size
        });
        this.setState({ data: response.data.data, pagination })
        // console.log('Expense data-> ',response);
      })
      .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })

    /*axios.get(`${complete_URL}/incomes/by_clients/table?id=${client_id}&page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`,{headers})
      .then(res => {
        // console.log(res.data);
        let pagination = { ...this.state.pagination };
        pagination.total = res.data.totalElements;
        pagination.pageSize = parseInt(res.data.size);
        this.setState({
          data: res.data,
          pagination: pagination,
        })
      })
      .catch(err => { console.log(err) })*/

  }//End of Method

  displayIncomeModel = () => {
    this.setState({ modelVisible: true })
  }

  handleIncomeTypeOk = () => {
    this.setState({ modelVisible: false })
  }
  
  handleIncomeTypeCancel = () => {
    this.setState({ modelVisible: false })     
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col> 
        <Col span={13}>
          <Link to="/dashboard/incomeadd">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
          <Button onClick={this.displayIncomeModel} style={{ marginRight: '12px' }} type="primary">{this.lang.BUTTON.INCOMETYPES}</Button>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={13}>
          <Link to="/dashboard/incomeadd">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button> 
          </Link>
          <Button onClick={this.displayIncomeModel} style={{ marginRight: '12px' }} type="primary">{this.lang.BUTTON.INCOMETYPES}</Button>
        </Col>
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
      </Row>
    }
  }

  render() {
    // console.log('this.props->',this.props);
    let { modelVisible, data, pagination } = this.state;

    return (
        <>
          {this.CustomRow}
          {/* <Row gutter={24} >
            <Col span={20} > */}
            <Modal 
              // centered={true} // Committed b/c it interferes in localizations (in Arabic mode)
              title={this.income_type_lang.TITLE} 
              visible={modelVisible} 
              onOk={this.handleIncomeTypeOk} 
              onCancel={this.handleIncomeTypeCancel} 
              width = {'50%'}
              footer = {false}
            >
              <IncomeType {...this.props} />
            </Modal>
            {/* </Col>
            <Col span={4}></Col>
          </Row> */}
          <Row id="clientsTable">
            {/* <Col span={2}></Col>
            <Col span={20}> */}
            <Col span={23} style={{margin : '0 25px 14px 25px'}}>
              <Table
                columns={this.columns}
                rowKey="id"
                // dataSource={this.state.data.selectedElements}
                dataSource={data}
                onChange={this.handleChangePagination}
                pagination={pagination}
              />
            </Col>
          </Row>
        </>
    )
  }
}

export default Incomes;