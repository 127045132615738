import React, { Component } from 'react';
import { Row, Col, Table, Button, message, Input } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../constants';
import getHeadersForHttpReq from '../../constants/token';
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;

const Search = Input.Search;

class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notification_type:[],
      message_type:[],
      // data: {},
      data: [],
      sortField:"ntf_type_name",
      sortOrder:"ascend",
      pagination: {},
      page: 1,
      size: 5,
      searchValues: {
        ntf_type_name : '',
        ntf_type_name_ar : '',
        msg_type_name: '',
        msg_type_name_ar: '',
        notification_type: '',
        message_type: '',
        email_content: '',
        email_content_ar: '',
        sms_content: '',
        sms_content_ar: '',
        email_recipient: '',
        sms_recipient: '',
        is_customer: '',
        client_id : ''
      }
    }
    this.lang = props.language._NOTIFICATIONS.SETUP;
    this.msg = props.language._NOTIFICATIONS.MESSAGE;
    

    this.columnsOLD = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NOTIFICATIONTYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.NOTIFICATIONTYPE}
              onChange={this.handleChangeSearchValues("notification_type")}
            />
          </span>
        ),
        dataIndex: 'NOTIFICATION_TYPE',
        key: 'NOTIFICATION_TYPE',
        width: '20%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.NOTIFICATION_TYPE, b.NOTIFICATION_TYPE),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{
            this.state.notification_type.length > 0 &&
            this.state.notification_type.filter(item => item.id == text).length > 0 &&
            localStorage.getItem('lang') == 'EN' ? 
             this.state.notification_type.filter(item => item.id == text)[0].name
              :
              localStorage.getItem('lang') == 'AR' ? 
             this.state.notification_type.filter(item => item.id == text)[0].name_ar
             :''
          }</button>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NOTIFICATIONTYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.NOTIFICATIONTYPE}
              onChange={this.handleChangeSearchValues("notification_type")}
            />
          </span>
        ),
        dataIndex: 'NOTIFICATION_TYPE',
        key: 'NOTIFICATION_TYPE',
        width: '20%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.NOTIFICATION_TYPE, b.NOTIFICATION_TYPE),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{
            this.state.notification_type.length > 0 &&
            this.state.notification_type.filter(item => item.id == text).length > 0 &&
            localStorage.getItem('lang') == 'EN' ? 
             this.state.notification_type.filter(item => item.id == text)[0].name
              :
              localStorage.getItem('lang') == 'AR' ? 
             this.state.notification_type.filter(item => item.id == text)[0].name_ar
             :''
          }</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.MESSAGETYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.MESSAGETYPE}
              onChange={this.handleChangeSearchValues("message_type")}
            />
          </span>
        ),
        dataIndex: 'MESSAGE_TYPE',
        key: 'MESSAGE_TYPE',
        width: '20%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.MESSAGE_TYPE, b.MESSAGE_TYPE),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{
          this.state.message_type.length > 0 &&
          this.state.message_type.filter(item => item.id == text).length > 0 &&
          localStorage.getItem('lang') == 'EN' ? 
           this.state.message_type.filter(item => item.id == text)[0].name
            :
            localStorage.getItem('lang') == 'AR' ? 
           this.state.message_type.filter(item => item.id == text)[0].name_ar
           :''
        }</button>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.MESSAGETYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.MESSAGETYPE}
              onChange={this.handleChangeSearchValues("message_type")}
            />
          </span>
        ),
        dataIndex: 'MESSAGE_TYPE',
        key: 'MESSAGE_TYPE',
        width: '20%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.MESSAGE_TYPE, b.MESSAGE_TYPE),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{
          this.state.message_type.length > 0 &&
          this.state.message_type.filter(item => item.id == text).length > 0 &&
          localStorage.getItem('lang') == 'EN' ? 
           this.state.message_type.filter(item => item.id == text)[0].name
            :
            localStorage.getItem('lang') == 'AR' ? 
           this.state.message_type.filter(item => item.id == text)[0].name_ar
           :''
        }</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.HEADINGCONTENT}
              <Search
                placeholder={this.lang.TABLEHEADERS.HEADINGCONTENT}
                onChange={this.handleChangeSearchValues("sms_content")}
              />
            </span>
          ),
          dataIndex: 'HEADING_CONTENT',
          key: 'HEADING_CONTENT',
          align : 'left',
          width: '20%',
          sorter: (a, b) => this.compareByAlph(a.HEADING_CONTENT, b.HEADING_CONTENT),
        }
        : {
          title: (
            <span>{this.lang.TABLEHEADERS.HEADINGCONTENTAR}
              <Search
                placeholder={this.lang.TABLEHEADERS.HEADINGCONTENTAR}
                onChange={this.handleChangeSearchValues("sms_content_ar")}
              />
            </span>
          ),
          dataIndex: 'HEADING_CONTENT_AR',
          key: 'HEADING_CONTENT_AR',
          width: '22%',
          align : 'right',
          sorter: (a, b) => this.compareByAlph(a.HEADING_CONTENT_AR, b.HEADING_CONTENT_AR),
        },
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.BODYCONTENT}
              <Search
                placeholder={this.lang.TABLEHEADERS.BODYCONTENT}
                onChange={this.handleChangeSearchValues("email_content")}
              />
            </span>
          ),
          dataIndex: 'BODY_CONTENT',
          key: 'BODY_CONTENT',
          align : 'left',
          width: '20%',
          sorter: (a, b) => this.compareByAlph(a.BODY_CONTENT, b.BODY_CONTENT),
        }: 
        {
          title: (
            <span>{this.lang.TABLEHEADERS.BODYCONTENTAR}
              <Search
                placeholder={this.lang.TABLEHEADERS.BODYCONTENTAR}
                onChange={this.handleChangeSearchValues("email_content_ar")}
              />
            </span>
          ),
          dataIndex: 'BODY_CONTENT_AR',
          key: 'BODY_CONTENT_AR',
          align : 'right',
          width: '20%',
          sorter: (a, b) => this.compareByAlph(a.BODY_CONTENT_AR, b.BODY_CONTENT_AR),
        },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title: (
          <span>{this.lang.TABLEHEADERS.ISCUSTOMER}
            <Search
              placeholder={this.lang.TABLEHEADERS.ISCUSTOMER}
              onChange={this.handleChangeSearchValues("is_customer")}
            />
          </span>
        ),
        dataIndex: 'IS_CUSTOMER',
        key: 'IS_CUSTOMER',
        width: '13.5%',
        align : 'center',
        sorter: (a, b) => this.compareByAlph(a.IS_CUSTOMER, b.IS_CUSTOMER),
        render: (text, record) => <p>{text===1? 'Yes':'No'}</p>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.ISCUSTOMER}
            <Search
              placeholder={this.lang.TABLEHEADERS.ISCUSTOMER}
              onChange={this.handleChangeSearchValues("is_customer")}
            />
          </span>
        ),
        dataIndex: 'IS_CUSTOMER',
        key: 'IS_CUSTOMER',
        width: '12.5%',
        align : 'center',
        sorter: (a, b) => this.compareByAlph(a.IS_CUSTOMER, b.IS_CUSTOMER),
        render: (text, record) => <p>{text===1? 'نعم':'لا'}</p>,
      },
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        align : 'center',
        width: '7%',
      }
    ];
    this.columns =[
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NOTIFICATIONTYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.NOTIFICATIONTYPE}
              onChange={this.handleChangeSearchValues("ntf_type_name")}
            />
          </span>
        ),
        dataIndex: 'ntf_type_name',
        key: 'ntf_type_name',
        width: '20%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.ntf_type_name, b.ntf_type_name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.NOTIFICATIONTYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.NOTIFICATIONTYPE}
              onChange={this.handleChangeSearchValues("ntf_type_name_ar")}
            />
          </span>
        ),
        dataIndex: 'ntf_type_name_ar',
        key: 'ntf_type_name_ar',
        width: '20%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.ntf_type_name_ar, b.ntf_type_name_ar),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: (
          <span>{this.lang.TABLEHEADERS.MESSAGETYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.MESSAGETYPE}
              onChange={this.handleChangeSearchValues("msg_type_name")}
            />
          </span>
        ),
        dataIndex: 'msg_type_name',
        key: 'msg_type_name',
        width: '20%',
        align : 'left',
        sorter: (a, b) => this.compareByAlph(a.msg_type_name, b.msg_type_name),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: (
          <span>{this.lang.TABLEHEADERS.MESSAGETYPE}
            <Search
              placeholder={this.lang.TABLEHEADERS.MESSAGETYPE}
              onChange={this.handleChangeSearchValues("msg_type_name_ar")}
            />
          </span>
        ),
        dataIndex: 'msg_type_name_ar',
        key: 'msg_type_name_ar',
        width: '20%',
        align : 'right',
        sorter: (a, b) => this.compareByAlph(a.msg_type_name_ar, b.msg_type_name_ar),
        render: (text, record) => <button className="link-button" onClick={this.handleNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.HEADINGCONTENT}
              <Search
                placeholder={this.lang.TABLEHEADERS.HEADINGCONTENT}
                onChange={this.handleChangeSearchValues("sms_content")}
              />
            </span>
          ),
          dataIndex: 'heading_content',
          key: 'heading_content',
          align : 'left',
          width: '20%',
          sorter: (a, b) => this.compareByAlph(a.heading_content, b.heading_content),
        }
        : {
          title: (
            <span>{this.lang.TABLEHEADERS.HEADINGCONTENTAR}
              <Search
                placeholder={this.lang.TABLEHEADERS.HEADINGCONTENTAR}
                onChange={this.handleChangeSearchValues("sms_content_ar")}
              />
            </span>
          ),
          dataIndex: 'heading_content_ar',
          key: 'heading_content_ar',
          width: '22%',
          align : 'right',
          sorter: (a, b) => this.compareByAlph(a.heading_content_ar, b.heading_content_ar),
        },
      (localStorage.getItem('lang') === 'EN') ?
        {
          title: (
            <span>{this.lang.TABLEHEADERS.BODYCONTENT}
              <Search
                placeholder={this.lang.TABLEHEADERS.BODYCONTENT}
                onChange={this.handleChangeSearchValues("email_content")}
              />
            </span>
          ),
          dataIndex: 'body_content',
          key: 'body_content',
          align : 'left',
          width: '20%',
          sorter: (a, b) => this.compareByAlph(a.body_content, b.body_content),
        }: 
        {
          title: (
            <span>{this.lang.TABLEHEADERS.BODYCONTENTAR}
              <Search
                placeholder={this.lang.TABLEHEADERS.BODYCONTENTAR}
                onChange={this.handleChangeSearchValues("email_content_ar")}
              />
            </span>
          ),
          dataIndex: 'body_content_ar',
          key: 'body_content_ar',
          align : 'right',
          width: '20%',
          sorter: (a, b) => this.compareByAlph(a.body_content_ar, b.body_content_ar),
        },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title: this.lang.TABLEHEADERS.ISCUSTOMER,
        dataIndex: 'is_customer',
        key: 'is_customer',
        width: '13.5%',
        align : 'center',
        sorter: (a, b) => this.compareByAlph(a.is_customer, b.is_customer),
        render: (text, record) => <p>{text===1? 'Yes':'No'}</p>,
      }:
      {
        title: this.lang.TABLEHEADERS.ISCUSTOMER ,
        dataIndex: 'is_customer',
        key: 'is_customer',
        width: '12.5%',
        align : 'center',
        sorter: (a, b) => this.compareByAlph(a.is_customer, b.is_customer),
        render: (text, record) => <p>{text===1? 'نعم':'لا'}</p>,
      },
      {
        title: `${this.lang.TABLEHEADERS.ACTION}`,
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deleteCategory.bind(this, record)}>{this.lang.BUTTON.DELETE}</Button>
          </span>
        ),
        align : 'center',
        width: '7%',
      }
    ];
  }


  compareByAlph = (a, b) => {
    if (a > b) { return -1; }
    if (a < b) { return 1; }
    return 0;
  }

  handleNavigation = (record) => {
    localStorage.setItem('updateNotificationId', JSON.stringify(record))
    this.props.history.push('/dashboard/notificationUpdate')
  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
         pagination: pager,
         pageNumber: pagination.current
      });

      if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
        if (this.state.pageNumber  !== pager.current) {
          this.fetch({ page: pagination.current });
        }
      } 
      else {
         this.fetch({
         results: pagination.pageSize,
         page: pagination.current,
         sortField: sorter.field,
         sortOrder: sorter.order,
         ...filters,
         });
      }
    
    /*let pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      page: pager.current,
      pagination: pager,
    }, () => {
      this.fetch();
    })*/

  }//End of Method

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState({
      searchValues: search
    }, () => {
      this.fetch();
      // console.log(this.state.searchValues)
    });
  }

  deleteCategory = (record) => {
    const headers = getHeadersForHttpReq();
    axios.delete(`${complete_URL}/notifications/${record.id}`,{headers})
      .then(res => {
        message.success(this.msg.DELETESUCCESS);
        this.props.history.push('/dashboard/notifications')
      })
      .catch(err => {
        console.log('Error occured while deleting the notification ->',err);
        message.error(this.msg.DELETEFAILURE,3);
      })
  }

  componentDidMount() {
    this.fetch();
    
    /*axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/message_type`,{headers})
    .then(res => {
      this.setState({message_type:res.data.data})
    })
    axios.get(`${complete_URL}/parameters/get_parameter_by_para_type/notification_type`,{headers})
    .then(res => {
      this.setState({notification_type:res.data.data})
    })*/

  }//End of Method

  fetch = (params={}) => {
    const client_id = localStorage.getItem('client_id');
    const headers = getHeadersForHttpReq();
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else { sortOrder = 'DESC' }
    
    let data ={
      client_id : client_id,
      name: this.state.searchValues.name || '',
      ntf_type_name :  this.state.searchValues.ntf_type_name || '',
      ntf_type_name_ar :  this.state.searchValues.ntf_type_name_ar || '',
      msg_type_name:  this.state.searchValues.msg_type_name || '',
      msg_type_name_ar:  this.state.searchValues.msg_type_name_ar || '',
      notification_type:  this.state.searchValues.notification_type || '',
      message_type:  this.state.searchValues.message_type || '',
      email_content:  this.state.searchValues.email_content || '',
      email_content_ar:  this.state.searchValues.email_content_ar || '',
      sms_content:  this.state.searchValues.sms_content || '',
      sms_content_ar:  this.state.searchValues.sms_content_ar || '',
      email_recipient:  this.state.searchValues.email_recipient || '',
      sms_recipient:  this.state.searchValues.sms_recipient || '',
      is_customer:  this.state.searchValues.is_customer || '', 
      
      page,
      sortColumn: params.sortField || this.state.sortField ,
      sortOrder,
      size:this.state.size,
      orderBy: '',
      column_name: 'no'
    }
    // console.log('Sending data to api ->',data);
    axios.post(`${complete_URL}/notifications/pagination_table`,data, {headers})
    .then(response => {
      // console.log('response of exp new pagination ->',response)
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      this.setState({ data: response.data.data, pagination })
      // console.log('Expense data-> ',response);
    })
    .catch(err => { console.log('Error occured while fetching pagination data new method ->',err) })


    /*axios.get(`${complete_URL}/notifications/table/?page=${this.state.page}&size=${this.state.size}&searchValues=${JSON.stringify(this.state.searchValues)}`,{headers})
      .then(res => { // console.log(res.data);
        let pagination = { ...this.state.pagination };
        pagination.total = res.data.totalElements;
        pagination.pageSize = parseInt(res.data.size);
        this.setState({
          data: res.data,
          pagination: pagination,
        }) 
      })
      .catch(err => { console.log(err) })*/

  }//End of Method

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
        <Col span={13}>
          <Link to="/dashboard/notificationAdd">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row id="clientTableHeader">
        <Col span={13}>
          <Link to="/dashboard/notificationAdd">
            <Button type="primary">{this.lang.BUTTON.ADD}</Button>
          </Link>
        </Col>
        <Col span={9}><p>{this.lang.TABLEHEADERS.TITLE}</p></Col>
      </Row>
    }
  }

  render() {
    return ( 
        <>
          {this.CustomRow}
          <Row id="clientsTable">
            <Col span={2}></Col>
            <Col span={20}>
              <Table
                columns={this.columns}
                rowKey="id"
                // dataSource={this.state.data.selectedElements}
                dataSource={this.state.data}
                onChange={this.handleChangePagination}
                pagination={this.state.pagination}
              />,
            </Col>
          </Row>
        </> 
    )
  }
}

export default Notifications;