import React from 'react'
import { Row, Col, Form, Icon, Button, Input, Card, Select, message, Avatar,Collapse, Switch } from 'antd'
import { Link } from 'react-router-dom'
import getHeadersForHttpReq from '../../constants/token';
import API_URL from '../../constants';
import axios from 'axios';
import ReactGoogleMap from '../ReactGoogleMap/index';
import ReactGoogleAutoComplete from '../ReactGoogleAutoComplete/index';
import Rules from './Rules';

const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const Panel = Collapse.Panel;

class BanquetUpdate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      img: '',
      banquet: {},
      parent: [],
      cities: [],
      lat : 23.614328   ,//-91, //[-90 , +90]
      lng : 58.545284   ,//-181, // [-180 , +180] // we set location of OMAN..
      showMap : true,
      editGoogleAddr : false,
      dir : 'ltr', 
      display: 'block',
    }
    this.lang = props.language._BANQUET.UPDATE;
    this.msg  = props.language._BANQUET.MESSAGE;
  }
  
  setComponentDirection=()=>{
    if(localStorage.getItem('lang')==='EN') {
      this.setState({dir : 'ltr', display: 'block'})
    }
    else {
      this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  componentDidMount() {
    let bqtUpd = localStorage.getItem('updateBqt');
    let bqtObj = JSON.parse(bqtUpd);
    if (bqtObj.hasOwnProperty('id')) {
      this.setState({ banquet: JSON.parse(bqtUpd) },()=>{
        this.getBanquetImage(bqtObj.image_id);
        this.getCountries();
        this.setComponentDirection(); 
      });
    }
    else{ this.props.history.push('/dashboard/banquets'); }
  }

  getBanquetImage = (image_id)=>{
    const headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/images/${image_id}`, { responseType: 'blob' },{headers})
      .then(res => {
        var thiss = this
        let a
        var reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          a = reader.result
          thiss.setState({ img: a })
        }
      })
      .catch(err => { console.log('Error occured while fetching Banquet Image in Banquet Update Screen->',err) });
  }

  getCountries=()=>{
    const headers = getHeadersForHttpReq();

    axios.get(`${complete_URL}/parameters/get_countries`,{headers})
      .then(res => {
        this.setState({ parent: res.data.data[0] },()=>{
          this.selectParentCountry(this.state.banquet.country);
        })
      })
      .catch(err => { console.log('Error occured while fetching Countires in Banquet Update Screen->',err) });
  }

  selectParentCountry = (v) => {
    const headers = getHeadersForHttpReq();

    this.setState({ selectedParentId: v })
    axios.get(`${complete_URL}/parameters/child_parameter/${v}`,{headers})
      .then(res => { /*console.log(res.data.data[0])*/
        this.setState({ cities: res.data.data[0] })
      })
      .catch(err => { console.log('Error occured while fetching Cities of Selected Country ->',err) })
  }

  selectCity = (v) => {
    this.setState({ selectedCity: v })
  }

  handleImageUpload = async (e) => {
    e.preventDefault();
    /*console.log(e)*/
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      var file = e.currentTarget.files[0];
      let fileName = file.name;
      let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (file.size <= 1024 * 1024 * 2 && /png|jpe?g/i.test(extension)) {
        let a;
        var thiss = this
        let imgdata = new FormData();
        imgdata.append('file', file)
        imgdata.append('client_id', localStorage.getItem('client_id'))
        imgdata.append('bqt_id', JSON.parse(localStorage.getItem('updateBqt')).id)
        // imgdata.append('client_name', this.state.client.name)
        let headers = {
          'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
          'Content-Type': 'multipart/form-data'
        }
        await  axios.post(`${complete_URL}/images/bqt/add`, imgdata, { responseType: 'blob' }, headers)
        .then(res => {
          let reader = new FileReader();
          reader.readAsDataURL(res.data);
          reader.onloadend = () => {
            a = reader.result
            thiss.setState({img: a}/*,()=>{console.log(this.state.img)}*/)
          }
        })
        .catch(err => {
          console.log('Error occured while uploading the Banquet Image->',err);
          message.error(this.msg.BANQUETIMAGEADDFAILURE,3);
        })
      }
      else{
        message.error(this.msg.BANQUETIMAGETYPEERROR,3)
      }
    }
  }
  // Unnecessary code
  seclectCity = (v) => {
    this.setState({ selectedCity: v })
  }
  //Unnecessary code,, not being used.. 
  // deleteUser = () => {
  //   let  headers = {
  //     'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
  //     'Content-Type': 'application/json' 
  //     }
  //   let id = JSON.parse(localStorage.getItem('updateBqt')).id
  //   axios.delete(`${complete_URL}/banquets/${id}`,{headers})
  //     .then(res => {
  //       this.props.history.push('banquets')
  //       message.success(this.msg.DELETEBANQUETSUCCESS);
  //     })
  //     .catch(err => {
  //       message.error(this.msg.DELETEBANQUETFAILURE,3);
  //     })
  // }

  handleSubmit = (e) => {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id')
    this.props.form.validateFields((err, values) => {
      Object.assign(values,{
        user_id : user_id,
        map_loc_addr : this.state.banquet.map_loc_addr,
        lat : this.state.banquet.lat,
        lng : this.state.banquet.lng ,
        finalize_map : 0
      });
      // values.user_id = user_id
      // values.city = this.state.selectedCity
      // values.country = this.state.selectedParentId
      // values.parent_id = this.state.selectedParentId
      // console.log('update banquet values->',values)

      const headers = getHeadersForHttpReq();

      axios.put(`${complete_URL}/banquets/${JSON.parse(localStorage.getItem('updateBqt')).id}`, values,{headers})
        .then(res => {
          /*console.log(res)*/
          message.success(this.msg.UPDATEBANQUETSUCCESS);
          this.props.history.push('banquets')
        })
        .catch(err => {
          console.log('Error occured while updating the Banquet ->',err);
          message.error(this.msg.UPDATEBANQUETFAILURE,3);
          // message.error(err)
        })
    })
  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={5}>
          <Link to="/dashboard/banquets">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={14}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display:'flex'}}>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
          <Link to="/dashboard/banquets">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link> 
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row className="top-header" gutter={24}>
        <Col span={4}>
          <Link to="/dashboard/banquets">
            <Button type="danger"><Icon type="arrow-left" /></Button>
          </Link>
        </Col>
        <Col span={15}>
          <h1 style={{textAlign:'center'}}>{this.lang.LABELS.TITLE}</h1>
        </Col>
        <Col className="btn-box" span={5} style={{display: 'flex'}}>
          <Link to="/dashboard/banquets">
            <Button style={{margin: '16px auto'}} className="delete" title="delete"><Icon type="delete" theme="filled" /></Button>
          </Link>
          <Button style={{margin: '16px auto'}} className="save" type="" htmlType="submit" title="save" ><Icon type="save" theme="filled" /></Button>
        </Col>
      </Row>
    }
  }

  getGeoLocations =(geo_loc_obj)=>{
    // console.log('geo_loc_obj ->',geo_loc_obj);
    if (geo_loc_obj.hasOwnProperty('lat') && geo_loc_obj.hasOwnProperty('lng')) {
      let bqt_obj = {};
      Object.assign(bqt_obj, {...this.state.banquet,lat : geo_loc_obj.lat, lng : geo_loc_obj.lng, map_loc_addr : geo_loc_obj.address } ) ;
      this.setState({banquet : bqt_obj, lat : geo_loc_obj.lat, lng : geo_loc_obj.lng, showMap : true }/*,()=>{
        console.log('this.state.banquet updated ->',this.state.banquet);
      }*/)
    }
  }

  handleEditGeoLocation =()=>{
    this.setState({editGoogleAddr : true});
  }

  render() {
    
    const { getFieldDecorator } = this.props.form;
    let {dir, display, parent, cities } = this.state;
    return (
      <Row gutter={12} style={{ marginTop: '50px' }}>
        <Col span={4}></Col>
        <Col span={16} >
          <Card>
            <Form onSubmit={this.handleSubmit}>
              {this.CustomRow}
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NAME}>
                    {getFieldDecorator('name', {
                      initialValue: this.state.banquet.name,
                      rules: [{ required: true, message:  this.msg.INSERTNAMEERROR }],
                    })(
                      <Input placeholder={this.lang.LABELS.NAME} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.NAMEAR}>
                    {getFieldDecorator('name_ar', {
                      initialValue: this.state.banquet.name_ar,
                      rules: [{ required: false, message: this.msg.INSERTNAMEARERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.NAMEAR} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.DESCRIPTION}>
                    {getFieldDecorator('description', {
                      initialValue: this.state.banquet.description,
                      rules: [{ required: true, message: this.msg.INSERTDESCRIPTIONERROR }],
                    })(
                      <Input type="text" placeholder={this.lang.LABELS.DESCRIPTION} />

                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={12}>
                    <Form.Item label={this.lang.LABELS.ADDRESS}>
                      {getFieldDecorator('address', {
                        initialValue: this.state.banquet.address,
                        rules: [{ required: true, message: this.msg.INSERTADDRESSERROR }],
                      })(
                        <Input type="text" placeholder={this.lang.LABELS.ADDRESS} />
                      )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label= {this.lang.LABELS.ADDRESSAR}>
                      {getFieldDecorator('address_ar', {
                        initialValue: this.state.banquet.address_ar,
                        rules: [{ required: true, message: this.msg.INSERTADDRESSARERROR }],
                      })(
                        <Input type="text" placeholder= {this.lang.LABELS.ADDRESSAR} />
                      )}
                    </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.COUNTRY}>
                    {getFieldDecorator('country', {
                        initialValue: this.state.banquet.country,
                        rules: [{ required: false, message: this.msg.SELECTCOUNTRYERROR }],
                      })(
                        <Select placeholder={this.lang.LABELS.COUNTRY} onChange={this.selectParentCountry} disabled>
                          {
                            (localStorage.getItem('lang') === 'EN') ?
                              parent.length > 0 && parent.map(parent => (
                                <Select.Option key={parent.id} value={parent.id}>{parent.name}</Select.Option>
                              ))
                              : parent.length > 0 && parent.map(parent => (
                                <Select.Option style={{textAlign:'right'}} key={parent.id} value={parent.id}>{parent.name_ar}</Select.Option>
                              ))
                          }
                        </Select>
                      )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={this.lang.LABELS.CITY}>
                    {getFieldDecorator('city', {
                        initialValue: this.state.banquet.city,
                        rules: [{ required: false, message: this.msg.SELECTCITYERROR }],
                      })(
                        <Select placeholder={this.lang.LABELS.CITY} onChange={this.selectCity} disabled>
                          {
                            (localStorage.getItem('lang') === 'EN') ?
                              cities.length > 0 && cities.map(city => (
                                <Select.Option key={city.id} value={city.id}>{city.name}</Select.Option>
                              ))
                              :  cities.length > 0 && cities.map(city => (
                                <Select.Option style={{textAlign:'right'}} key={city.id} value={city.id}>{city.name_ar}</Select.Option>
                              ))
                          }
                        </Select>
                      )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label= {this.lang.LABELS.CRNO} >
                    {getFieldDecorator('c_r_no', {
                      initialValue: this.state.banquet.c_r_no,
                      rules: [{ required: false, message: this.msg.INSERTCRNOERROR }],
                    })(
                      <Input type="text" placeholder = {this.lang.LABELS.CRNO}  />
                    )}
                  </Form.Item>
                </Col>                
              </Row>
              <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={8}>
                  <Form.Item label= {this.lang.LABELS.GSM}>
                    {getFieldDecorator('gsm', {
                      initialValue: this.state.banquet.gsm,
                      rules: [{ required: false, message: this.msg.INSERTGSMERROR }],
                    })(
                      <Input type="text" placeholder= {this.lang.LABELS.GSM} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label=  {this.lang.LABELS.EMAIL}>
                    {getFieldDecorator('email', {
                      initialValue: this.state.banquet.email,
                      rules: [{ required: false, message: this.msg.INSERTEMAILERROR }],
                    })(
                      <Input type="text" placeholder=  {this.lang.LABELS.EMAIL} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label= {this.lang.LABELS.INSTAGRAMURL} >
                    {getFieldDecorator('insta_url', {
                      initialValue: this.state.banquet.insta_url,
                      rules: [{ required: false, message: this.msg.INSERTINSTAGRAMURLERROR }],
                    })(
                      <Input type="text" placeholder= {this.lang.LABELS.INSTAGRAMURL} />
                    )}
                  </Form.Item>
                </Col>    
              </Row>
              {/* <React.fragement>
                            <Map
                                google={this.props.google}
                                center={{lat: 24.946218, lng: 67.005615}}
                                height='300px'
                                zoom={15}
                            />                      
                            </React.fragement> */}

              <Row gutter={24} dir= {dir} style={{ display, marginBottom:'5px' }}>
                <Col span={8}>
                  <Form.Item>
                    <p>{this.lang.LABELS.FILEUPLOAD}</p>
                    {getFieldDecorator('file', {
                      rules: [{ required: false, message: this.firstNameEmptyError }],
                    })(
                      <Row>
                        <Avatar size={80} src={this.state.img || '/logo.png'} alt="log here" />
                        <Input type="file" id="file" className="file" onChange={this.handleImageUpload} />
                      </Row>
                    )}
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label= {this.lang.LABELS.GOOGLEMAPADDRESS}>
                      {getFieldDecorator('map_loc_addr', {
                        initialValue: this.state.banquet.map_loc_addr,
                        rules: [{ required: false, message: '' }],
                      })(
                        
                        this.state.editGoogleAddr ?
                        <ReactGoogleAutoComplete {...this.props} getGeoLocations = {this.getGeoLocations} />
                        :
                        <Row gutter={24} dir= {dir} style={{ display }}>
                          <Col span = {20}>
                            <Input type="text" value ={this.state.banquet.map_loc_addr} readOnly = {true} placeholder= {this.lang.LABELS.GOOGLEMAPADDRESS} />
                          </Col>
                          <Col span={4}>
                            <Button onClick={()=>this.handleEditGeoLocation()} >{this.lang.LABELS.EDIT}</Button>
                          </Col>
                        </Row>
                      )}
                    </Form.Item>
                  {
                    this.state.showMap && 
                    <ReactGoogleMap  
                      {...this.props} 
                      lat = {this.state.banquet.lat} 
                      lng = {this.state.banquet.lng}  
                      getGeoLocations = {this.getGeoLocations}
                      is_dynamic = {true}
                    />
                  }
                      
                </Col>
                {/* 
                  The valid range of latitude in degrees is -90 and +90 for the southern and northern hemisphere 
                  respectively. Longitude is in the range -180 and +180 specifying coordinates west and east of the 
                  Prime Meridian, respectively.
                */}
              </Row>
              {/* <Row gutter={24} dir= {dir} style={{ display }}>
                <Col span={24}>
                      <ReactGoogleMap {...this.props}  />
                </Col>
              </Row> */}

              {/* <Form.Item>
                <Row style={{ marginTop: '-20px' }}>
                  <Col span={16}>
                    <p>Upload files under 2MB, and only in png/jpg/jpeg formats</p>
                  </Col>
                </Row>
                {getFieldDecorator('file', {
                  rules: [{ required: false, message: this.firstNameEmptyError }],
                })(
                  <Row>
                    <Col span={4}></Col>
                    <Col span={6}>
                      <Avatar size={80} src={this.state.img || '/logo.png'} alt="log here" />
                    </Col>
                    <Col span={12}>
                      <Input type="file" id="file" className="file" onChange={this.handleImageUpload} />
                    </Col>
                  </Row>
                )}
              </Form.Item> */}
            </Form>
            <Collapse accordion bordered={false} defaultActiveKey={['']}  expandIconPosition={localStorage.getItem('lang')==='EN' ? 'left':'right'}  expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
              <Panel header={this.props.language._BANQUET.SETUP.TABLEHEADERS.RULETITLE} key="1" id='package'>
                {
                  this.state.banquet ? 
                  <Rules {...this.props} bqt_id = {this.state.banquet.id} lang = {this.props.language._RULE} /> : null
                }
              </Panel>
            </Collapse>
          </Card>
        </Col>
        <Col span={4}></Col>
      </Row>
    )
  }
}
export default BanquetUpdate = Form.create({ name: 'normal_login' })(BanquetUpdate);