import React from 'react'
import {Spin, Row, Col, Form, Icon, Button,Checkbox, InputNumber, Table, Select, message, Avatar } from 'antd'
import axios from 'axios'
import API_URL from '../../constants/index';
import getHeadersForHttpReq from '../../constants/token';
if (localStorage.getItem('lang') === 'AR') {
  require('../css/global-css.css');
}
const pt = localStorage.getItem('pt');
const complete_URL = API_URL+pt;


class HallPackages extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      IsPackagesList: '1',
      hall_pkgs_parent_data: [],
      service_types: [],
      service_type: '',
      new_service_type: '',
      service_contractor: [],
      package_type: [],
      add_package_type_data: [],
      package_price: '',
      image: '',
      package: {},
      isSingleRow: false,
      tableSingleRow: [],
      selectedRowKeys: [],
      update_Counter : 0,
      spin : false,
      dir : 'ltr', 
      display: 'block',
      // sortField:"new_service_type_name",
			sortField:"updated_at",
      sortOrder: "ascend",//'descend',
      pagination: {},
      page: 1,
      size: 5,
      searchValues :{
        new_service_type_name:''
      },
      hpp_payment_info : { },
      resrv_id : props.resrv_id,
    }
    
    this.lang = props.language._RESERVATION.UPDATE.PACKAGES.HALLPACKAGE;
    this.msg = props.language._RESERVATION.UPDATE.PACKAGES.HALLPACKAGE.MESSAGE;

    this.hall_pkgs_parent_columns = [
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.SERVICETYPE}`,
        // dataIndex: 'service_type_name',
        dataIndex : 'new_service_type_name',
        width: '30%',
        align : 'left',
        render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.SERVICETYPE}`,
        // dataIndex: 'service_type_name',
        dataIndex : 'new_service_type_name_ar',
        align : 'right',
        render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.SERVICECONTRACTOR}`,
        dataIndex: 'service_contractor_name',
        width: '30%',
        align : 'left',
        render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.SERVICECONTRACTOR}`,
        dataIndex: 'service_contractor_name_ar',
        align : 'right',
        render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation.bind(this, record)}>{text}</button>,
      },
      {
        title: `${this.lang.SETUP.LABELS.TOTALAMOUNT}`,
        dataIndex: 'total_amount',
        width: '20%',
        align : 'center',
        render: (text, record) => <div style={{textAlign:"center"}}><button className="link-button" onClick={this.handlePackageNavigation.bind(this, record)}>{text}</button></div>,
      },
      {
        title: `${this.lang.SETUP.LABELS.ACTION}`,
        key: 'last_name',
        width: '20%',
        align : 'center',
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.deletePackage.bind(this, record)}>{this.lang.SETUP.BUTTON.DELETE}</Button>
          </span>
        ),
      }
    ]

    this.pkg_types_columnsOld = [
      {
        title: `${this.lang.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'type',
        width: '25%',
        // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === "EN") ?
        {
          title: `${this.lang.SETUP.LABELS.PACKAGEDESCRIPTION}`,
          dataIndex: 'description',
          width: '20%',
          // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
        }
        : {
          title: `${this.lang.SETUP.LABELS.PACKAGEDESCRIPTION}`,
          dataIndex: 'description_ar',
          width: '20%',
          // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
        },
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        width: '20%',
        // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
      },
      {
        title: "QTY",
        dataIndex: 'qty',
        width: '20%',
        render: (text, record) =>
        <InputNumber value={text} onChange={(e) => this.changeQty(e,record)}/>,
      },
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEIMAGE}`,
        dataIndex: 'image_id',
        width: '20%',
        render: (text, record) =>  <Avatar size={80} src={`${complete_URL}/images/${text}`} alt="package image here" />
      },
    ]
    //This column will be used on re-selecting the same service type and same contractor
    this.pkg_types_columns = [
      {
        title: '*',
        dataIndex: 'selected',
        width: '5%',
        align : 'center',
        render: (text, record)=> <Checkbox checked={record.selected == 1 ? true : false} onChange={(e)=>{this.changePkgTypeSelection(e,record)}} />
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'package_type',
        width: '18%',
        align : 'left',
        // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'package_type',
        width: '18%',
        align : 'right',
        // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === "EN") ?
        {
          title: `${this.lang.SETUP.LABELS.PACKAGEDESCRIPTION}`,
          dataIndex: 'description',
          width: '25%',
          align : 'left',
          // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
        }
        : {
          title: `${this.lang.SETUP.LABELS.PACKAGEDESCRIPTION}`,
          dataIndex: 'description_ar',
          width: '25%',
          align : 'right',
          // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
        },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        width: '7.5%',
        align : 'left',
        render: (text, record) => <p>{text}</p>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        width: '11%',
        align : 'right',
        render: (text, record) => <p style={{textAlign:'center'}}>{text}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title: `${this.lang.SETUP.LABELS.QUANTITY}`,
        dataIndex: 'qty',
        width: '11%',
        align : 'center',
        render: (text, record) => <InputNumber value={text} min={0} style={{width:'90px'}} onChange={(e) => this.changeQty(e,record)}/>,
      }:
      {
        title:  `${this.lang.SETUP.LABELS.QUANTITY}`,
        dataIndex: 'qty',
        width: '11%',
        align : 'right',
        render: (text, record) => <InputNumber value={text} min={0}  style={{width:'90px'}} onChange={(e) => this.changeQty(e,record)}/>,
      },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title:  `${this.lang.SETUP.LABELS.TOTALAMOUNT}`,
        dataIndex: 'total_amount',
        width: '14%',
        align : 'center',
        render: (text, record) => <p style={{textAlign:'center'}}>{record.qty * record.price}</p>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.TOTALAMOUNT}`,
        dataIndex: 'total_amount',
        width: '14%',
        align : 'right',
        render: (text, record) => <p style={{textAlign:'center'}}>{record.qty * record.price}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEIMAGE}`,
        dataIndex: 'image_id',
        width: '20%',
        align : 'center',
        render: (text, record) => <Avatar size={80} src={`${complete_URL}/images/${text}`} alt="package image here" />
      }:
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEIMAGE}`,
        dataIndex: 'image_id',
        width: '20%',
        align : 'right',
        render: (text, record) => <Avatar size={80} src={`${complete_URL}/images/${text}`} alt="package image here" />
      } 
    ]
    // This column is used on selecting any of package
    this.pkg_types_columns2 = [
      {
        title: '*',
        dataIndex: 'selected',
        width: '5%',
        align : 'center',
        render: (text, record)=> <Checkbox checked={record.selected == 1 ? true : false} onChange={(e)=>{this.changePkgTypeSelection(e,record)}} />
      },
      (localStorage.getItem('lang') === 'EN') ?
      {
        title: `${this.lang.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'package_type',
        width: '18%',
        align : 'left',
        // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.PACKAGETYPE}`,
        dataIndex: 'package_type',
        width: '18%',
        align : 'right',
        // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
      },
      (localStorage.getItem('lang') === "EN") ?
        {
          title: `${this.lang.SETUP.LABELS.PACKAGEDESCRIPTION}`,
          dataIndex: 'description',
          width: '25%',
          align : 'left',
          // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
        }
        : {
          title: `${this.lang.SETUP.LABELS.PACKAGEDESCRIPTION}`,
          dataIndex: 'description_ar',
          width: '25%',
          align : 'right',
          // render: (text, record) => <button className="link-button" onClick={this.handlePackageNavigation1.bind(this, record)}>{text}</button>,
        },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        width: '7.5%',
        align : 'left',
        render: (text, record) => <p>{text}</p>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEPRICE}`,
        dataIndex: 'price',
        width: '11%',
        align : 'right',
        render: (text, record) => <p style={{textAlign:'center'}}>{text}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title: `${this.lang.SETUP.LABELS.QUANTITY}`,
        dataIndex: 'qty',
        width: '11%',
        align : 'center',
        render: (text, record) => <InputNumber value={text} min={0} style={{width:'90px'}} onChange={(e) => this.changeQty(e,record)}/>,
      }:
      {
        title:  `${this.lang.SETUP.LABELS.QUANTITY}`,
        dataIndex: 'qty',
        width: '11%',
        align : 'right',
        render: (text, record) => <InputNumber value={text} min={0}  style={{width:'90px'}} onChange={(e) => this.changeQty(e,record)}/>,
      },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title:  `${this.lang.SETUP.LABELS.TOTALAMOUNT}`,
        dataIndex: 'total_amount',
        width: '14%',
        align : 'center',
        render: (text, record) => <p style={{textAlign:'center'}}>{record.qty * record.price}</p>,
      }:
      {
        title: `${this.lang.SETUP.LABELS.TOTALAMOUNT}`,
        dataIndex: 'total_amount',
        width: '14%',
        align : 'right',
        render: (text, record) => <p style={{textAlign:'center'}}>{record.qty * record.price}</p>,
      },
      (localStorage.getItem('lang') === 'EN') ?  
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEIMAGE}`,
        dataIndex: 'image_id',
        width: '20%',
        align : 'center',
        render: (text, record) => <Avatar size={80} src={`${complete_URL}/images/${text}`} alt="package image here" />
      }:
      {
        title: `${this.lang.SETUP.LABELS.PACKAGEIMAGE}`,
        dataIndex: 'image_id',
        width: '20%',
        align : 'right',
        render: (text, record) => <Avatar size={80} src={`${complete_URL}/images/${text}`} alt="package image here" />
      } 
    ]
  }

  
  setComponentDirection =()=>{
    if (localStorage.getItem('lang')==='EN') {
        this.setState({dir : 'ltr', display: 'block'})
    }
    else {
        this.setState({dir : 'rtl', display: 'flex'})
    }
  }

  calculateUpdatedTotalBill = ()=>{
    let updTotalBill = 0.0;
    for (let j = 0; j < this.state.add_package_type_data.length; j++){
      let item = this.state.add_package_type_data[j];
      updTotalBill = updTotalBill + (parseFloat(item.qty) * parseFloat(item.price));
    }
    return parseFloat(updTotalBill).toFixed(2);

  }//End of Method

  changeQuantityProcess = async(pkg_type_record , msg)=>{
    // console.log(pkg_type_record);
    if(pkg_type_record.hasOwnProperty('qty') && pkg_type_record.hasOwnProperty('selected')){
      let updTotalBill = this.calculateUpdatedTotalBill();
      if(updTotalBill < parseFloat(this.state.hpp_payment_info.amount)) {
        message.error(this.msg.PKGUPDATERESTRICTION,3);
      }
      else{
        const headers = getHeadersForHttpReq();
        axios.post(`${complete_URL}/hallpackages/updateQuantity`, pkg_type_record, {headers})
        .then( (upd_qty_res) => {
          // All pkg types to be update because this current row must be updated
          if (msg === 'simple unselect') { message.success(this.msg.UNSELECTPKGTYPESUCCESS); }
          // else{ message.success('Package Type quantity has been updated!'); }
          /*this.reload();
          this.fetch();
          // this.handlePackageNavigation(JSON.parse(localStorage.getItem('updatePackage')));
          this.updatePackageParentInfo();
          this.props.re_render_prnt('render_msg','renderHallPayment');*/
          this.getPkgsPaymentInfo();
          this.setState({update_Counter : this.state.update_Counter + 1})
        })
        .catch(upd_qty_err=>{
          if (msg === 'simple unselect') { message.error(this.msg.UNSELECTPKGTYPEFAILURE,2); }
          else{ message.error(this.msg.QTYUPDATEFAILURE,2); } 
          console.log('Update quantity Process error->',upd_qty_err);
        })
      }//End of check total_bill become less than paid amount
    }//End of check key values exists
  }//End of method
  // rowSelection object indicates the need for row selection
  
  changeQty = async (e, record) => {
      // console.log(record)
      if (e >= 0) {
      let  updated_pkg_type = Object.assign(record,{ qty: e})
      if(updated_pkg_type.selected == 1){
        await this.changeQuantityProcess(updated_pkg_type,'qty update');    
        /*axios.post(`${complete_URL}/hallpackages/updateQuantity`, updated_pkg_type).then( res => {
        })
        await  this.fetch()
        await this.handlePackageNavigation(JSON.parse(localStorage.getItem('updatePackage')))*/
      }
      else{
        Object.assign(record,{ qty: 0});//Important as InputNumber Element increments it without mentioning it into element in Textfeild
        message.error(this.msg.PKGADDRESTRICTION,2);
        return false;
      }
    }
    else{ message.error(this.msg.INSERTPOSITIVEAMOUNTERROR,2); }
  }
  
  changePkgTypeSelection = async (value, record)=>{
    //console.log('selected pkg type->',record);
    if(value.target.checked === false){
      if (parseInt(record.qty) == 0) {//If Qty is zero then on pkg unselection, there won't be change in price as well Total amount
        let updatedRecord = Object.assign(record,{selected : 0, qty: 0})
            
        await this.changeQuantityProcess(updatedRecord, 'simple unselect'); 
      }
      else{
        //Process of Balancing the Paid amount...
        let deducting_amount = parseFloat(record.price) * parseInt(record.qty);//Amount to be deducted after unselection..
        let update_pkg_obj = JSON.parse(localStorage.getItem('updatePackage'));
        if (update_pkg_obj) {
          axios({
            method:'GET',
            url: complete_URL + '/hallpayments/by_res_srv_cont',
            params:{ res_id : update_pkg_obj.reservation_id, new_service_type : update_pkg_obj.new_service_type , service_contractor : update_pkg_obj.service_contractor }
          })
          .then(async(res) => { //console.log('Payment record->',res.data.data[0]);
            
            let paid_amount_diff = parseFloat(res.data.data[0].total_amount) - parseFloat(res.data.data[0].amount);
            // console.log('paid_amount_diff->',paid_amount_diff, ' and deducting_amount->',deducting_amount);
            if (deducting_amount <= paid_amount_diff) {
              // console.log('unSelectPossible');
              let updatedRecord = Object.assign(record,{selected : 0, qty: 0})
              await this.changeQuantityProcess(updatedRecord, 'simple unselect'); 
            }
            else{ message.error(this.msg.PKGREMOVERESTRICTION,2); }
          })
          .catch(err => { console.log('Error occured while fetching Total amount data for comparison purpose ->',err) })
        }//Selected Package found
        else{ message.error(this.msg.PKGNOTFOUNDERROR,2); } 
      }//Else Quantity greater than 0
    }
    else {
      Object.assign(record,{selected : 1})
      axios.put(`${complete_URL}/hallpackages/select`,record)
        .then(res => {
          /*this.reload()
          this.fetch();
          // this.handlePackageNavigation(JSON.parse(localStorage.getItem('updatePackage')))
          this.updatePackageParentInfo();*/
          this.setState({update_Counter : this.state.update_Counter + 1})
          message.success(this.msg.SELECTPKGTYPESUCCESS);
        })
        .catch(err => {
          message.error(this.msg.SELECTPKGTYPEFAILURE,2);
          console.log('update the Package err->',err);
        })
    }
    
  }//End of Method

  updatePackageParentInfo =()=>{
    const pkg_prnt_id = JSON.parse(localStorage.getItem('updatePackage')).id;
    axios({ method:'GET', url:`${complete_URL}/hallpackagesprent/byId/`,
      params:{ pkg_prnt_id }
    })
    .then(res => { /*console.log('updated new pkg parent->',res.data.data[0]);*/
        this.setState({ package: res.data.data[0] })
      })
    .catch(err => { console.log('Error occured while fetching Pkg Parent info->',err) })
  }
  //Fetch payment info of selected Contractor 
  getPkgsPaymentInfo =()=>{
    let update_pkg_obj = JSON.parse(localStorage.getItem('updatePackage'));//hpp json obj
    if (update_pkg_obj) {
      axios({ method:'GET', url: complete_URL + '/hallpayments/by_res_srv_cont',
        params:{ res_id : update_pkg_obj.reservation_id, 
                 new_service_type : update_pkg_obj.new_service_type , 
                 service_contractor : update_pkg_obj.service_contractor }
      })
      .then(hpp_pmt_res => { //console.log('hpp_payment_info ->',hpp_pmt_res.data.data[0]);
        this.setState({hpp_payment_info : hpp_pmt_res.data.data[0]});
      })
      .catch(err=>{
        console.log('Error occured while fetching payment info of Hall packages combined payment',err);
      });
    }
  }// End of Method

  /*getServices = () => {
    axios.get(`${complete_URL}/parameters/get_byParameter/SERVICE_TYPE`)
    .then(result => {
      this.setState({ service_types: result.data.data[0] })
    })
    .catch(err => {
      console.log(err)
    })
  }*/
  getServiceTypes = (client_id)=>{
    let  headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/servicetypes/by_client/${client_id}`,{headers})
      .then(res => { // console.log('service_type->',res)
        this.setState({ service_types: res.data.data });
      })
      .catch(err => {  console.log('Issue while fetching new service types ->',err) })
  }

  //New ajax API call for fetching Assigned independent contractor of selected Service Type
  getAssignedServiceTypes = (client_id)=>{
    let  headers = getHeadersForHttpReq();
    axios.get(`${complete_URL}/client_servicetypes/${client_id}`,{headers})
      .then(res => { // console.log('service_type->',res);
        this.setState({ service_types: res.data.data });
      })
      .catch(err => {  console.log('Issue while fetching new service types ->',err); });
  }
  
  getContractors = (val) => {
    this.setState({
      service_contractor: [],
      package: {},
      isSingleRow: false,
      service_type:val
    })
    // console.log(`clientid${localStorage.getItem('client_id')}`)
    // axios.get(`${complete_URL}/contractors/fetch_by_service?type=${val}&&client=${localStorage.getItem('client_id')}`)
    axios.get(`${complete_URL}/contractors/fetch_by_service?type=${val}`)
    .then((res) => {
      if (res.data.success) {
        // console.log(res.data)
        this.setState({ service_contractor: res.data.data })
      } 
      else { message.error(res.data.message) }
    })
    .catch(err => { message.error(err.message) })
  }

  //New ajax API call for fetching Assigned independent contractor of selected Service Type
  getIndServiceContractor =(service_type_id)=>{ 
    if(service_type_id){ //console.log('service_type_id ->',service_type_id);
      const client_id = localStorage.getItem('client_id');
      let  headers = getHeadersForHttpReq();
      this.setState({
        service_contractor: [],
        package: {},
        isSingleRow: false,
        service_type: service_type_id,
        new_service_type : service_type_id,
      },()=>{
        axios.get(`${complete_URL}/contractors/fetch_by_ind_servicetype_id/${service_type_id}/${client_id}`,{headers})
        .then((res) => {
          if (res.data.success) { // console.log(res.data)
            this.setState({ service_contractor: res.data.data })
          } 
          else { message.error(res.data.message); }
        })
        .catch(err => { 
          console.log('Error occured while fetching independent/ assigned Contractors of selected assigned Service Type->',err);
          message.error(err.message,3); 
        });
      });
    }
  }// End of Method

  getPackages = async (val, options) => {
    const user_id = localStorage.getItem('user_id');
    const client_id = localStorage.getItem('client_id');

    if (user_id) {
      if (val) {
        let alreadyServiceContractorAdded = this.state.hall_pkgs_parent_data.filter(hpp=>hpp.service_contractor === val);
        if (alreadyServiceContractorAdded.length > 0) {
          this.handlePackageNavigation(alreadyServiceContractorAdded[0]);
        }
        else{
        let add_Conf_Obj = {any_pkg_added : false, pmt_not_created : true, parent_id : ''};
        let payment_Obj = {reservation_id:this.props.reservationId, user_id:  user_id ,service_contractor : ''};

        // axios.get(complete_URL + '/contractorpackages/fetch_by_contractor/' + val)//18MAY2020 copied on 28July20
        axios.get(complete_URL + '/contractorpackages/fetch_by_contractor_upd/' + val+'/'+client_id) //18MAY2020 copied on 28July20
        .then((ress) => {        
          if (ress.data.success) {
            let avbl_pkg_types = [...ress.data.data];
            if(avbl_pkg_types.length > 0){
              
              let data = {
                reservation_id: this.props.reservationId,
                service_type:   this.state.service_type,
                user_id:        user_id,
                service_contractor :   val,
                new_service_type : this.state.service_type
              }
        
                axios.post(complete_URL + '/hallpackagesprent/add',data)
                .then( async (res) =>{
              
                  localStorage.setItem('updatePackage', JSON.stringify(res.data))
                  
                  for(let cont_pkgs_index =0; cont_pkgs_index < avbl_pkg_types.length ; cont_pkgs_index++){
                    // if (avbl_pkg_types[cont_pkgs_index].is_active == 1) {
                      let p_obj = avbl_pkg_types[cont_pkgs_index];
                      let datasend = { 
                        qty : 0,
                        user_id:  user_id,
                        package_type : p_obj.id,
                        reservation_id: this.props.reservationId,
                        parent_id: res.data.data.id,
                        is_active : p_obj.is_active,
                        description: p_obj.description,
                        description_ar: p_obj.description_ar,
                        service_type : res.data.data.service_type ? res.data.data.service_type : '',
                        new_service_type : res.data.data.new_service_type,
                        image_id : p_obj.image_id ? p_obj.image_id: '00000000-0000-00',
                        service_contractor : res.data.data.service_contractor,
                        price: (p_obj.new_price ? p_obj.new_price : p_obj.price),
                        commission_percentage: (p_obj.new_commission_percentage ? p_obj.new_commission_percentage : p_obj.commission_percentage),
                        commission_amount: (p_obj.new_commission_amount ? p_obj.new_commission_amount : p_obj.commission_amount),
                        cpr_id : p_obj.cpr_id ? p_obj.cpr_id : ''
                      }//End of Hall Package JSON obj
                
                      axios.post(complete_URL + '/hallpackages/add', datasend)
                      .then( async(result) => {
                        if (result.data.success) {
                          Object.assign(add_Conf_Obj , {any_pkg_added : true, parent_id : datasend.parent_id});
                          Object.assign(payment_Obj, {service_contractor : datasend.service_contractor});
                          message.success(this.msg.ADDPKGTYPESUCCESS/*result.data.message*/)
                        } 
                        // else { message.error(this.msg.ADDPKGTYPEFAILURE,2); }
                          if( add_Conf_Obj.any_pkg_added &&  add_Conf_Obj.pmt_not_created){
                            Object.assign(add_Conf_Obj , {pmt_not_created : false});
                            
                            await axios.post(complete_URL + '/hallpayments/createHallPaymen_forContractor',datasend)
                            /*.then(pmt_res=>{

                            })
                            .catch(pmt_err=>{
                              if (pmt_err.data.code === '001') {
                                message.error(this.msg.HALLPAYMENTADDFAILURE);
                              }
                              else if (pmt_err.data.code === '002') {
                                message.error(this.msg.GETPAYMENTTYPEFAILURE);
                              }
                              else 
                                message.error(this.msg.HALLPAYMENTADDFAILURE);
                            })*/
                            await axios({
                              method:'GET',
                              url: complete_URL + '/hallpackagesprent/byReservationId/',
                              params:{
                                id:this.props.reservationId
                              }
                            })
                            .then(res => {
                              this.setState({ hall_pkgs_parent_data: res.data.data }, () => {
                                /*console.log(this.state.hall_pkgs_parent_data)
                                console.log(this.state.hall_pkgs_parent_data.filter(item => item.id == datasend.parent_id.substr(0,16)))*/
                                this.handlePackageNavigation(this.state.hall_pkgs_parent_data.filter(item => item.id == datasend.parent_id.substr(0,16))[0])
                              })
                            })
                            .catch(hp_err => {
                              console.log('Error occured while fetching hall Packages total record->',hp_err);
                              message.error(this.msg.GETPKGPARENTFAILURE,2);
                            })
                          }//End of checking/waiting last adding pkg
                              // if(cont_pkgs_index == 0){
                              //   axios.post(`${complete_URL}/hallpayments/createHallPaymen_forContractor`,datasend)
                              // }
                        
                      })
                      .catch(hpk_err => {
                        console.log('Error occured in creating the Hall Package->',hpk_err);
                        message.error(this.msg.ADDPKGTYPEFAILURE,2);
                      })
                    //}//End of Check if Contractor Package is Active to add as Hall Package
                  }//End of Loop
                    
                })
                .catch(hpp_err => {
                  console.log('Error occured in creating Hall Package Parent ->',hpp_err);
                  message.error(this.msg.ADDPKGPARENTFAILURE,2);
                })
                  
                this.setState({
                  package_type: avbl_pkg_types,
                  add_package_type_data: avbl_pkg_types
                }/*, () => {console.log(this.state.add_package_type_data)}*/)
                let id = this.state.package_type[0].image_id
                axios.get(complete_URL + '/images/'+ id , { responseType: 'blob' })
                .then(res => {
                  let a
                    var reader = new FileReader();
                    reader.readAsDataURL(res.data);
                    reader.onloadend = () => {
                      a = reader.result
                    }
                    setTimeout(() => {
                      this.setState({ image: a })
                    }, 500)
      
                })
                .catch(err => {
                  // console.log(err)
                })
            }
            else{message.error(this.msg.CONTPKGSNOTFOUND,2);} 
          } 
          else { message.error(this.msg.FETCHCONTPKGSFAILURE,2/*ress.data.message*/); }
          })
          .catch(err => {
            console.log('Error occured while fetching Contractor Packages->',err)
            message.error(this.msg.FETCHCONTPKGSFAILURE,2);
          })

        }//End of else added pkgs of Contractor
      }//End of Contractor Id
      else{ message.error(this.msg.CONTRACTORNOTFOUND,2); }
    }
    else{ message.error(this.msg.LOGINERROR,2) }
  }//End of Method

  //Old method of  fetching all selected packages, not to be use anymore..
  getPackagesByReservation = () => {
    let id = this.props.reservationId
    /*axios.get(`${complete_URL}/hallpackages/by_reservations/${id}`)
    .then(res => { })
    .catch(err => { console.log(err) })*/

    axios({
      method:'GET',
      url:`${complete_URL}/hallpackagesprent/byReservationId/`,
      params:{ id }
    })
    .then(res => {
        setTimeout(() => {
          this.setState({ hall_pkgs_parent_data: res.data.data }/*, () => {
             console.log('recv hall packages by resv id->',res.data)
          }*/)
        })
      })
      .catch(err => {
        message.error(this.msg.GETPKGPARENTFAILURE,2);
        console.log("Fetching Reservation's packages error->",err);
      })
  }
  //Method is not being used..
  getPackage = async (record) => {
    // console.log(this.state.service_types)
    let id = record.package_type_id
    await axios.get(`${complete_URL}/hallpackages/${id}`)
      .then(async res => {
        let temp = []
        temp.push(res.data.data)
        // console.log(temp)
        setTimeout(async () => {
          this.setState({ tableSingleRow: temp })
        })
        // console.log(this.state.package)

        let image_id = res.data.data.image_id

        axios.get(`${complete_URL}/images/${image_id}`, { responseType: 'blob' })
          .then(res => {
            let a
            var reader = new FileReader();
            reader.readAsDataURL(res.data);
            reader.onloadend = () => {
              a = reader.result
            }
            setTimeout(() => {
              this.setState({ image: a })
            }, 500)

          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err)
      })


  }

  deletePackage = (pkg_prnt_record) => {
    this.setState({spin:true})
    if (parseFloat(pkg_prnt_record.total_amount) > 0) {
      this.setState({spin:false})
      message.error(this.msg.DELETEPKGTYPERESTRICTION,2);
    }
    else{ //console.log('pkg_prnt_record to be deleted->',pkg_prnt_record)
    const headers =  getHeadersForHttpReq();
    let data = {  
                id : pkg_prnt_record.id,
                reservation_id : pkg_prnt_record.reservation_id,
                // new_service_type : pkg_prnt_record.new_service_type , No need of New Service type as its foreign key service_contractor is enough
                service_contractor : pkg_prnt_record.service_contractor
              };
      
    axios.post(`${complete_URL}/hallpackagesprent/delete`, data,{headers})
      .then(res => {
        // console.log(res.data)
        if (res.data.success) {
          this.setState({update_Counter : this.state.update_Counter + 1, spin:false});
          message.success(this.msg.DELETEPKGTYPESUCCESS);
          // this.fetch();
          // this.reload();
        } 
        else {
          this.setState({spin:false});
          message.error(res.data.message);
        }
      })
      .catch(err => {
        this.setState({spin:false});
        console.log('Error occured while deleting the Complete package record->',err);
        if (err.data.code == '006') {
          message.error(this.msg.DELETEPKGPARENTFAILURE,2);
        }
        else if (err.data.code == '005') {
          message.error(this.msg.DELETEPKGTYPEFAILURE,2);
        }
        else if (err.data.code == '004') {
          message.error(this.msg.DELETEHALLPAYMENTFAILURE,2);
        }
        else
          message.error(err.message);
      })
    }
  }

  handlePackageNavigation = async (record) => {
  // console.log('hpp item~Contractor selected ->',record);
  const headers = getHeadersForHttpReq();
  let hpp_data = {
    contractor_id: record.service_contractor,
    parent_id: (record.id).substring(0, 16),
    user_id: localStorage.getItem('user_id'),
    client_id : localStorage.getItem('client_id'),
    reservation_id: this.props.reservationId,
    service_type: record.service_type,
    new_service_type: record.new_service_type,
  }
  // await  axios.post(`${complete_URL}/hallpackages/add_if_not_added`,hpp_data);
  await  axios.post(`${complete_URL}/hallpackages/add_if_not_added_upd`,hpp_data, headers)
  .then((rrr) => {/*console.log('in hallPackages..handlePackageNavigation method..respose rrr->',rrr)*/})
  .catch(eerr =>{console.log('Error occured while adding non-added pkgs (if any) ->',eerr)})

    let a = []
    if(record){
      localStorage.setItem('updatePackage', JSON.stringify(record))
    }
    // localStorage.setItem('updatePackage', JSON.stringify(record))
    // console.log('hpp id ->',JSON.parse(localStorage.getItem('updatePackage')).id);
    await axios.get(`${complete_URL}/hallpackages/by_parent/${record.id}`)
    .then(res => {
      this.setState({add_package_type_data : res.data.data}
        /*,() => {console.log('hall pkgs ->',this.state.add_package_type_data)}*/)
      res.data.data.map((item,i) => {
        if(item.selected == 1) {
          a.push(i)
        }
      })
    })
    .catch( err => {
      console.log('Error occured while fetching hall packages by hall package parent ID->',err);
      message.error(this.msg.GETPKGTYPESFAILURE,2);
    })
    this.setState({
      selectedRowKeys:a,
      package: JSON.parse(localStorage.getItem('updatePackage')),
      IsPackagesList: '3',
      isSingleRow: true,
      update_Counter : this.state.update_Counter + 1,//to reload this component as well as tell other related components to reload
    },()=>{
      this.getPkgsPaymentInfo(); // 29Oct2020
    })
    // console.log('handlePackageNavigation method..selected package->',JSON.parse(localStorage.getItem('updatePackage')));
  }// End of Method

  // handlePackageNavigation1 = (record) => {
  //   console.log(record)
  //   localStorage.setItem('updateSelectedPackage', JSON.stringify(record));
  // }

  reload = () => {
    this.props.reloadPage();
  }
  //seems unnecessary method; not being used..
  handleSubmitPackage = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log(values)
        values.user_id = localStorage.getItem('user_id')
        values.reservation_id = this.props.reservationId
        values.hall = this.props.hallId
        // console.log(localStorage.getItem('updateSelectedPackage'))
        values.package_type = JSON.parse(localStorage.getItem('updateSelectedPackage')).id
        values.package_price = JSON.parse(localStorage.getItem('updateSelectedPackage')).price
        // console.log('')
      }
    })
  }

  handleSubmitPackageUpdate = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      values.user_id = localStorage.getItem('user_id')
      let updateSelectedPackage = JSON.parse(localStorage.getItem('updateSelectedPackage'))
      // console.log(updateSelectedPackage)
      if (!err) {
        updateSelectedPackage.map( item => (
          axios.put(`${complete_URL}/hallpackages/select`,item)
            .then(res => {
              this.fetch();
              // this.handlePackageNavigation()
              this.handlePackageNavigation(JSON.parse(localStorage.getItem('updatePackage')))
              message.success(this.msg.SELECTPKGTYPESUCCESS,/*'Package Has Been Updated'*/)
            })
            .catch(err => {
              message.error(this.msg.SELECTPKGTYPEFAILURE,/*'Package update failed'*/);
              console.log('Error occured while updating/seleting the package->',err)
            })

        ))
      }
    })
  }

  handleAddPackageClick = () => {
    this.setState({ IsPackagesList: '2', add_package_type_data: [], service_types: [], service_contractor: [] });
    // this.getServices();
    const client_id = localStorage.getItem('client_id');
    // this.getServiceTypes(client_id);  
    this.getAssignedServiceTypes(client_id); 
  }

  handleChangePagination = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
         pagination: pager,
         pageNumber: pagination.current
      });

      if (sorter.field == this.state.sortField && sorter.order == this.state.sortOrder) {
        if (this.state.pageNumber  !== pager.current) {
          this.fetch({ page: pagination.current });
        }
      } 
      else {
         this.fetch({
         results: pagination.pageSize,
         page: pagination.current,
         sortField: sorter.field,
         sortOrder: sorter.order,
         ...filters,
         });
      }
  }//End of Method

  fetch = (params={}) => {
    const client_id = localStorage.getItem('client_id');
    let  headers =  getHeadersForHttpReq();
    
    let page,sortOrder ;
    if (params.page) { page = params.page - 1 }
    else { page = 0;}

    if (params.sortOrder == 'ascend') { sortOrder = 'ASC' }
    else if (params.sortOrder == 'descend') { sortOrder = 'DESC' }
    else { sortOrder = 'ASC' }
    
    let data ={
      client_id : client_id,
      resrv_id : this.state.resrv_id || '',

      page,
      sortColumn: params.sortField || this.state.sortField || 'new_service_type_name',
      sortOrder,
      size:this.state.size
    }
    // console.log('Sending data to api ->',data);
    axios.post(`${complete_URL}/hallpackagesprent/pagination_table`,data, {headers})
    .then(response => {  //console.log('response of pkg parent pagination ->',response.data.data)
      let pagination = {};
      Object.assign(pagination,{
        ...this.state.pagination,
        total : response.data.totalElements,
        current : page +1,
        pageSize : this.state.size
      });
      this.setState({ hall_pkgs_parent_data : response.data.data, pagination });
      
    })
    .catch(err => { 
      message.error(this.msg.GETPKGPARENTFAILURE,3);
      console.log('Error occured while fetching pagination data new method ->',err); 
    })

    //Old method of  fetching all Contractors along with Service Types (also package parent table data), new pagination logic is written above
    // this.getPackagesByReservation();
  }

  componentDidMount() {
    // this.getServices();
    const client_id = localStorage.getItem('client_id');
    // this.getServiceTypes(client_id);  
    this.getAssignedServiceTypes(client_id);
    this.fetch();
    this.setState({
      parent_resrv_Counter : this.props.parent_resrv_Counter,
      parent_hall_pmt_Counter : this.props.parent_hall_pmt_Counter,
      parent_hall_srv_Counter : this.props.parent_hall_srv_Counter,
      parent_hall_pkg_Counter : this.props.parent_hall_pkg_Counter,
      parent_hall_doc_Counter : this.props.parent_hall_doc_Counter
    });
    this.setComponentDirection();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    if (prevState.update_Counter < this.state.update_Counter) {
      this.reload();
      this.fetch();
      //this.handlePackageNavigation(JSON.parse(localStorage.getItem('updatePackage')));
      this.updatePackageParentInfo();
      this.props.updateChildCounterByParent('parent_hall_pkg_Counter', (this.props.parent_hall_pkg_Counter + 1));
    }
    if (prevProps.parent_hall_pmt_Counter > this.state.parent_hall_pmt_Counter) {
      this.updatePackageParentInfo();
      this.setState({parent_hall_pmt_Counter : prevProps.parent_hall_pmt_Counter});
      // console.log('componentDidUpdate called based on props changed in HallPackages');
    }

  }

  componentWillMount() {
    if (localStorage.getItem('lang') === 'EN') {
      this.CustomRow = <Row>
        <Col span={4}>
          <Button type="danger" title="back" onClick={() => { this.setState({ IsPackagesList: '1', add_package_type_data: [] }, () =>    this.fetch()) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ textAlign: 'center', fontSize: '18px' }}>{this.lang.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit" title="save" style={{ float: "right" }}><Icon type="save" theme="filled" /></Button>
          </Form.Item>
        </Col>
      </Row>

      this.hall_pkgs_parent_Header = <Row id="clientTableHeader">
        <Col span={9}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.LABELS.TITLE}</p></Col>
        <Col span={13}>
          {
            this.props.status !== 'canceled' &&
            <Button type="primary" style={{ float: 'right' }} onClick={this.handleAddPackageClick}>{this.lang.SETUP.BUTTON.ADD}</Button>
          }
        </Col>
        <Col span={2}></Col>
      </Row>

      this.CustomRow3 = <Row>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ IsPackagesList: '1', isSingleRow: false }, () =>    this.fetch()) }}><Icon type="arrow-left" /></Button>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}> {this.lang.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
      </Row>
    }
    else {
      this.CustomRow = <Row>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit" title="save" style={{ float: "right" }}><Icon type="save" theme="filled" /></Button>
          </Form.Item>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <h1 style={{ textAlign: 'center', fontSize: '18px' }}>{this.lang.CREATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Button type="danger" title="back" onClick={() => { this.setState({ IsPackagesList: '1' }) }}><Icon type="arrow-left" /></Button>
        </Col>
      </Row>

      this.hall_pkgs_parent_Header = <Row id="clientTableHeader">
        <Col span={1}></Col>
        <Col span={6}>
          {
            this.props.status !== 'canceled' &&
            <Button type="primary" style={{ float: 'right' }} onClick={() => { this.setState({ IsPackagesList: '2' }) }}>{this.lang.SETUP.BUTTON.ADD}</Button>
          }
        </Col>
        <Col span={16}><p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bolder' }}>{this.lang.SETUP.LABELS.TITLE}</p></Col>
        <Col span={1}></Col>
      </Row>

      this.CustomRow3 = <Row>
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit"><Icon type="save" /></Button>
          </Form.Item>
        </Col>
        <Col span={3}></Col>
        <Col span={8}>
          <h1 style={{ fontSize: '18px' }}> {this.lang.UPDATE.LABELS.TITLE}</h1>
        </Col>
        <Col span={5}></Col>
        <Col span={4}>
          <Button type="danger" onClick={() => { this.setState({ IsPackagesList: '1', isSingleRow: false }) }}><Icon type="arrow-left" /></Button>
        </Col>
      </Row>
    }
  }

  render() {
    
    const { getFieldDecorator } = this.props.form;
    let { dir, display, spin, IsPackagesList, service_types, service_contractor, 
          pagination, hall_pkgs_parent_data, add_package_type_data } = this.state;
    
    return (
      <Spin tip= {this.msg.DELETEPACKAGETIP} spinning = {spin}>
      <React.Fragment>
        {
          //View Hall Package Screen
          IsPackagesList === '1' ?
            <>
              {this.hall_pkgs_parent_Header}
              <Row gutter={24} id="hall_pkgs_parent_table">
                <Col span={24}>
                  <Table   
                    style={{ marginTop: '10px' }}  
                    rowKey="id" 
                    columns={this.hall_pkgs_parent_columns}
                    dataSource={hall_pkgs_parent_data}
                    onChange={this.handleChangePagination}
                    pagination={pagination}
                  />
                </Col>
              </Row>
            </>
            : 
          IsPackagesList === '2' ?
            <Form onSubmit={this.handleSubmitPackage} style={{ marginTop: '20px' }}>
              {this.CustomRow}
              <Row gutter={24} dir={dir} style={{display}}>
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.SERVICETYPE}>
                    {getFieldDecorator('service_type', {
                      // initialValue: (localStorage.getItem('lang') === "EN") ? this.state.service_types.name : this.state.service_types.name_ar,
                      rules: [{ required: true, message: this.msg.SELECTSERVICETYPEERROR }],
                    })(
                      <Select onChange={this.getIndServiceContractor}>
                        {
                          dir === 'ltr' ?
                          service_types.length > 0 && service_types.map(item => (
                            <Select.Option value={item.new_service_type} key={item.id}> { item.name } </Select.Option>
                          )) :
                          service_types.length > 0 && service_types.map(item => (
                            <Select.Option value={item.new_service_type} key={item.id} style={{textAlign:'right'}}> { item.name_ar } </Select.Option>
                          ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={this.lang.CREATE.LABELS.COMPANYNAME}>{/* SERVICECONTRACTOR*/}
                    {getFieldDecorator('service_contractor', {
                      // initialValue: (localStorage.getItem('lang') === "EN") ? this.state.service_contractor.name : this.state.service_contractor.name_ar,
                      rules: [{ required: true, message: this.msg.SELECTCONTRACTORERROR  }],
                    })(
                      <Select onChange={this.getPackages}>
                        {
                          dir === 'ltr' ?
                          service_contractor.length > 0 && service_contractor.map(item => (
                            <Select.Option value={item.id} key={item.id}> { item.name } </Select.Option>
                          )) :
                          service_contractor.length > 0 && service_contractor.map(item => (
                            <Select.Option style={{textAlign:'right'}} value={item.id} key={item.id}> { item.name_ar } </Select.Option>
                          )) 
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/* Till not being used.. Maybe used to display already selected Hall packges??*/}
              <Row gutter={24}  id="add_package_type_table" >
                  {/* <Row id="add_package_type_table"> */}
                    <Col span={24}>
                      <Table  
                        rowKey="id"  
                        style={{ marginTop: '10px' }} 
                        columns={this.pkg_types_columns} /*rowSelection={rowSelection}*/ 
                        dataSource={add_package_type_data} 
                      />
                    </Col>
                  {/* </Row> */}
              </Row>
            </Form>

              //Update Hall Package Screen
              :
              <Form onSubmit={this.handleSubmitPackageUpdate.bind(this)} style={{ marginTop: '20px' }}>
                {this.CustomRow3}
                {/* Contractor + New Service Type Data + Total amount from HPP*/}
                <Row gutter={24} dir={dir} style={{display}}>
                  <Col span={8}>
                    <Form.Item  
                      label={this.lang.CREATE.LABELS.SERVICETYPE}>
                        {dir === 'ltr' ? this.state.package.new_service_type_name : this.state.package.new_service_type_name_ar}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item  label={this.lang.CREATE.LABELS.SERVICECONTRACTOR}>
                      {dir === 'ltr' ? this.state.package.service_contractor_name : this.state.package.service_contractor_name_ar}
                    </Form.Item>
                  </Col>               
                  <Col span={8}>
                      <Form.Item label={this.lang.CREATE.LABELS.TOTALAMOUNT}>{this.state.package.total_amount}</Form.Item>
                  </Col>
                </Row>
                
                 {/* The Table consisting package types is displayed after any of packages is selected.. */}
                <Row gutter={24}>
                    <Row id="add_package_type_table2">
                      <Col span={24}>
                        <Table  
                          rowKey="id"  
                          style={{ marginTop: '10px' }} 
                          columns={this.pkg_types_columns2} /*rowSelection={rowSelection}*/  
                          dataSource={add_package_type_data}
                        />
                    </Col>
                    </Row>
                </Row>
              </Form>
        }
      </React.Fragment>
      </Spin>)
  }
}

export default HallPackages = Form.create({ name: 'hall_pkgs_form' })(HallPackages);