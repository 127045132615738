import React from "react";
import axios from "axios";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./index.css";
// import './progressbar.css'
import { VictoryBar, VictoryChart, VictoryAxis } from "victory";
import moment from "moment";
// import * as egEG from 'antd/lib/locale-provider/ar_EG';
import {
  Select,
  Row,
  Col,
  Table,
  Card,
  Progress,
  DatePicker,
  Icon,
  Button,
  Spin,
  message,
} from "antd";
import AnnualSummaryReport from "./AnnualSummaryReport";

import "react-datetime/css/react-datetime.css";

import { Pie } from "react-chartjs-2";
// import 'chart.piecelabel.js';

import "chartjs-plugin-labels";

import YearPicker from "react-year-picker"; //old calender
// import Calendar from 'react-calendar'; //New Calender 28Oct2020
import API_URL from "../../constants";
import getHeadersForHttpReq from "../../constants/token";
import getHeadersForPDFreport from "../../constants/reportHeaders";

const { RangePicker, MonthPicker } = DatePicker;

if (localStorage.getItem("lang") === "AR") {
  require("moment/locale/ar");
  require("./arabic.css");
  require("../css/global-css.css");
}
const pt = localStorage.getItem("pt");
const complete_URL = API_URL + pt;

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CardData: [
        { total: 120 },
        { total: 120 },
        { total: 120 },
        { total: 120 },
      ],
      cardData: [],
      myEventsList: [],
      filterNode: "m",
      filterPieChartNode: "m",
      filterContCalenderNode: "",
      filterResrvCalenderNode: "",
      showFilter: true,
      showPieChartFilter: true,
      showContCalenderFilter: false,
      showResrvCalenderFilter: false,
      data: [], //For BarChart
      assigned_clientsList: [],
      columns: [],
      list: [], //Not being used
      contractors: [], //After commiting other reports,, this is also not being used
      piChartCompleteData: { labels: [], datasets: [], backgroundColor: [] },
      random_colors: [
        "#9DA485",
        "#94f75e",
        "#E0DE21",
        "#9ED7F8",
        "#E5E7E9 ",
        "#e1bff5",
        "#f0bbbb",
        "#2FF9C8",
        "#A1F2D2",
        "#1499B7",
        "#3333FF",
        "#FF8000",
      ],
      resrv_Options: [
        { id: 0, name: "pending", name_ar: "قيد الانتظار" },
        { id: 1, name: "reserved", name_ar: "محجوز" },
        { id: 2, name: "canceled", name_ar: "ألغيت" },
        { id: 3, name: "all", name_ar: "جميع" },
      ],
      tableNo: "",
      showTable: false,
      dddd: {},
      pagination: {},
      page: 1,
      size: 3,
      ReportOptions: [
        { id: 0, name: "Contractor Report", name_ar: "تقرير  مقدم الخدمات " },
        { id: 1, name: "Reservations Report", name_ar: "تقرير الحجز" },
      ],
      reportOptIndex: 0,
      searchValues: {
        amount: "",
        expense_type: "",
        note: "",
      },
      searchValues2: {
        no: "",
        from_date: "",
        to_date: "",
        status: "",
        customer: "",
        banquet: "",
        hall: "",
      },
      searchValues_fn_Report: {
        selectedClient: "",
        month: "",
        year: "",
        month1: "",
        year1: "",
        month2: "",
        year2: "",
        yearOnly: "",
        allData: "",
      },
      searchValues_PieChart: {
        month: "",
        year: "",
        month1: "",
        year1: "",
        month2: "",
        year2: "",
        yearOnly: "",
        allData: "",
      },
      searchValues_cont_Report: {
        serv_cont_name: "",
        serv_cont_name_ar: "",
        date: "",
        from_date: "",
        to_date: "",
        month: "",
        year: "",
        yearOnly: "",
        month1: "",
        year1: "",
        cont_id: "",
        month2: "",
        year2: "",
        allData: "",
      },
      progressbar: false,
      searchValues_resrv_Report: {
        date: "",
        from_date: "",
        to_date: "",
        month: "",
        year: "",
        yearOnly: "",
        month1: "",
        year1: "",
        status: "all",
        month2: "",
        year2: "",
        allData: "",
      },
      disableDownloadReport: true,
      disableDownloadContReport: true,
      disableDownloadResrvReport: true,
      disableDownloadPieReport: true,
      reportDate: new Date(),
      fn_mode: ["month", "month"],
      cont_mode: ["month", "month"],
      pieChart_mode: ["month", "month"],
      resrv_mode: ["month", "month"],
      resrv_range_value: [],
      fn_range_value: [],
      cont_range_value: [],
      pieChart_range_value: [],
      spin: false,
      spinningMessage: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      dir: "ltr",
      display: "block",
      textAlignValue: "left",
    };

    this.lang = props.language._EXPENSE.SETUP;
    this.lang2 = props.language._RESERVATION.SETUP;
    this.lang3 = props.language._RESERVATION.UPDATE.SERVICES.HALLSERVICE;
    this.lang4 = props.language._DASHBOARD.VICTORYCHART.X;
    this.msg = props.language._STATISTICS.MESSAGE;
    this.report_lang_msg = props.language._REPORTS.MESSAGE;
  }

  setComponentDirection = () => {
    if (localStorage.getItem("lang") === "EN") {
      this.setState({ dir: "ltr", display: "block", textAlignValue: "left" });
    } else {
      this.setState({ dir: "rtl", display: "flex", textAlignValue: "right" });
    }
  };

  disabledStartDate = (startValue) => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onStartChange = (date, dateString, report_type) => {
    this.setState({ startValue: date }, () => {
      if (this.state.startValue && this.state.endValue) {
        let st = moment(dateString).locale("en").format("YYYY-MM-DD");
        let ed = moment(this.state.endValue).locale("en").format("YYYY-MM-DD");
        this.setParamsDatesDuration(st, ed, false, report_type);
      }
    });
  };

  onEndChange = (date, dateString, report_type) => {
    this.setState({ endValue: date }, () => {
      if (this.state.endValue && this.state.startValue) {
        let st = moment(this.state.startValue)
          .locale("en")
          .format("YYYY-MM-DD");
        let ed = moment(dateString).locale("en").format("YYYY-MM-DD");
        this.setParamsDatesDuration(st, ed, false, report_type);
      }
    });
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  filterfnDataOptionOnchange = (v) => {
    let searchValues_fn_Report = {
      selectedClient: this.state.searchValues_fn_Report.selectedClient,
      month: "",
      year: "",
      month1: "",
      year1: "",
      month2: "",
      year2: "",
      yearOnly: "",
      allData: v == "aa" ? "Yes" : "",
    };
    this.setState(
      {
        searchValues_fn_Report,
        disableDownloadReport: v == "aa" ? false : true,
        filterNode: v,
        showFilter: true,
      },
      () => {
        this.state.filterNode == "aa" &&
          this.downloadAllData("barChart"); /*this.fetchesGraphDataAlltime()*/
      }
    );
  };

  filterPieChartDataOptionOnchange = (val) => {
    let searchValues_PieChart = {
      month: "",
      year: "",
      month1: "",
      year1: "",
      month2: "",
      year2: "",
      yearOnly: "",
      allData: val == "aa" ? "Yes" : "",
    };
    this.setState(
      {
        searchValues_PieChart,
        disableDownloadPieReport: val == "aa" ? false : true,
        filterPieChartNode: val,
        showPieChartFilter: true,
      },
      () => {
        if (this.state.filterPieChartNode == "aa") {
          this.downloadAllData("pie");
        }
      }
    );
  };

  filterContDataOptionOnchange = (val) => {
    let { serv_cont_name, serv_cont_name_ar } =
      this.state.searchValues_cont_Report;
    let disableDownloadContReport = val == "aa" ? false : true;
    if (!serv_cont_name || !serv_cont_name_ar) {
      disableDownloadContReport = true;
    }
    let resetObj = {
      date: "",
      from_date: "",
      to_date: "",
      month: "",
      year: "",
      month1: "",
      year1: "",
      month2: "",
      year2: "",
      yearOnly: "",
      allData: val == "aa" ? "Yes" : "",
    };
    let new_report_obj = Object.assign(
      this.state.searchValues_cont_Report,
      resetObj
    );
    this.setState({
      disableDownloadContReport,
      searchValues_cont_Report: new_report_obj,
      filterContCalenderNode: val,
      showContCalenderFilter: true,
    });
  };
  //Not using right now, as Feature is hidden/code is committed
  filterResrvDataOptionOnchange = (val) => {
    // if (val) {
    let resetObj = {
      date: "",
      from_date: "",
      to_date: "",
      month: "",
      year: "",
      yearOnly: "",
      month1: "",
      year1: "",
      status: "all",
      month2: "",
      year2: "",
      allData: "",
    };
    let new_report_obj = Object.assign(
      this.state.searchValues_resrv_Report,
      resetObj
    );
    this.setState({
      searchValues_resrv_Report: new_report_obj,
      disableDownloadResrvReport: val == "aa" ? false : true,
      filterResrvCalenderNode: val,
      showResrvCalenderFilter: true,
    });
    // }
  }; //End of Method filterResrvDataOptionOnchange

  /* Extra pagination method, it is not being called */
  handleChangePagination = (pagination, filters, sorter) => {
    let pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState(
      {
        page: pager.current,
        pagination: pager,
      },
      () => {
        this.fetches();
      }
    );
  };

  handleChangeSearchValues = (prop) => (e) => {
    let search = { ...this.state.searchValues };
    search[`${prop}`] = e.target.value;
    this.setState(
      {
        searchValues: search,
      },
      () => {
        this.fetches();
      }
    );
  };

  changeBarChartAmountDir = () => {
    // console.log('node->',document.getElementsByClassName('VictoryContainer')[0].childNodes[0].childNodes[1].childNodes[0]);
    document
      .getElementsByClassName("VictoryContainer")[0]
      .childNodes[0].childNodes[1].childNodes[0].setAttribute("x1", 400);
    document
      .getElementsByClassName("VictoryContainer")[0]
      .childNodes[0].childNodes[1].childNodes[0].setAttribute("x2", 400);
    if (
      document.getElementsByClassName("VictoryContainer")[0].childNodes[0]
        .childNodes[1].childNodes[1]
    ) {
      document
        .getElementsByClassName("VictoryContainer")[0]
        .childNodes[0].childNodes[1].childNodes[1].childNodes[2].childNodes[0].setAttribute(
          "x",
          420
        );
    }
    if (
      document.getElementsByClassName("VictoryContainer")[0].childNodes[0]
        .childNodes[1].childNodes[2]
    ) {
      document
        .getElementsByClassName("VictoryContainer")[0]
        .childNodes[0].childNodes[1].childNodes[2].childNodes[2].childNodes[0].setAttribute(
          "x",
          420
        );
    }
    // document.getElementsByClassName('VictoryContainer')[0].childNodes[0].childNodes[1].childNodes[2].childNodes[2].childNodes[0].setAttribute('x', 420)
    if (
      document.getElementsByClassName("VictoryContainer")[0].childNodes[0]
        .childNodes[1].childNodes[3]
    ) {
      document
        .getElementsByClassName("VictoryContainer")[0]
        .childNodes[0].childNodes[1].childNodes[3].childNodes[2].childNodes[0].setAttribute(
          "x",
          420
        );
    }
    if (
      document.getElementsByClassName("VictoryContainer")[0].childNodes[0]
        .childNodes[1].childNodes[4]
    ) {
      document
        .getElementsByClassName("VictoryContainer")[0]
        .childNodes[0].childNodes[1].childNodes[4].childNodes[2].childNodes[0].setAttribute(
          "x",
          420
        );
    }
    if (
      document.getElementsByClassName("VictoryContainer")[0].childNodes[0]
        .childNodes[1].childNodes[5]
    ) {
      document
        .getElementsByClassName("VictoryContainer")[0]
        .childNodes[0].childNodes[1].childNodes[5].childNodes[2].childNodes[0].setAttribute(
          "x",
          420
        );
    }
    if (
      document.getElementsByClassName("VictoryContainer")[0].childNodes[0]
        .childNodes[1].childNodes[6]
    ) {
      document
        .getElementsByClassName("VictoryContainer")[0]
        .childNodes[0].childNodes[1].childNodes[6].childNodes[2].childNodes[0].setAttribute(
          "x",
          420
        );
    }
    if (
      document.getElementsByClassName("VictoryContainer")[0].childNodes[0]
        .childNodes[1].childNodes[7]
    ) {
      document
        .getElementsByClassName("VictoryContainer")[0]
        .childNodes[0].childNodes[1].childNodes[7].childNodes[2].childNodes[0].setAttribute(
          "x",
          420
        );
    }
    if (
      document.getElementsByClassName("VictoryContainer")[0].childNodes[0]
        .childNodes[1].childNodes[8]
    ) {
      document
        .getElementsByClassName("VictoryContainer")[0]
        .childNodes[0].childNodes[1].childNodes[8].childNodes[2].childNodes[0].setAttribute(
          "x",
          420
        );
    }
    if (
      document.getElementsByClassName("VictoryContainer")[0].childNodes[0]
        .childNodes[1].childNodes[9]
    ) {
      document
        .getElementsByClassName("VictoryContainer")[0]
        .childNodes[0].childNodes[1].childNodes[9].childNodes[2].childNodes[0].setAttribute(
          "x",
          420
        );
    }
  };

  componentDidMount() {
    let today = new Date();
    let currentMonth = today.getMonth() + 1;
    let currentYear = today.getFullYear();
    if (this.props.language.AR_FLAG) {
      this.changeBarChartAmountDir();
    }

    if (localStorage.getItem("is_contr") == 1) {
      const contractor_id = JSON.parse(
        localStorage.getItem("updateContractor")
      ).id;
      this.getAssignedClients(contractor_id);
      // setTimeout(this.setParamsOneMonthOfYear,1000,currentMonth, currentYear, false, 'fn');
    } else this.setParamsOneMonthOfYear(currentMonth, currentYear, false, "fn");

    this.setParamsOneMonthOfYear(currentMonth, currentYear, false, "pie");
    // this.getCurrentPreviousAndCompleteStatistics(currentMonth, currentYear, client_id);
    this.getCurrentPreviousAndCompleteStatisticsUpdated(
      currentMonth,
      currentYear
    );
    // this.getContractors();
    // this.getAssignedContByClient();//Not using right now, as Feature is hidden/code is committed
    this.setComponentDirection();
  } //End of DidMount

  //New Generic HTTP Service for Barchart of all duration filters + Both Client & Contractor
  fetchBarGraphDataGenericService = (req_data) => {
    const headers = getHeadersForHttpReq();
    axios({
      url: `${complete_URL}/statistics/genericStatistics`,
      method: "post",
      data: req_data,
      headers,
    })
      .then((barChart_res) => {
        this.setState({ data: Object.values(barChart_res.data) });
      })
      .catch((barChart_err) => {
        console.log(
          "Error occured while fetching generic Statistic BarChart->",
          barChart_err
        );
        message.error(this.msg.BARCHARTFETCHDATAERROR, 3);
      });
  }; //End of Method

  // Old fetching Statistics method before Contractor Feature; not to be called.
  getCurrentPreviousAndCompleteStatistics = (month, year, client_id) => {
    if (client_id) {
      let headers = getHeadersForHttpReq();

      axios
        .get(
          `${complete_URL}/statistics/statistics/${month}/${year}/${client_id}`,
          { headers }
        )
        .then((result) => {
          // console.log('In Statistics..DidMount.. month->',month,'/',year,'result->',result)
          let this_month_rent_1 = parseFloat(
            result.data[4].thismonth[0].totalRent == null
              ? "0"
              : result.data[4].thismonth[0].totalRent
          );
          let this_month_t_comm_1 = parseFloat(
            result.data[0].thismonth[0].totalCommission == null
              ? "0"
              : result.data[0].thismonth[0].totalCommission
          );
          let this_month_income_1 = parseFloat(
            result.data[5].thismonth[0].totalIncome == null
              ? "0"
              : result.data[5].thismonth[0].totalIncome
          );
          //let this_month_t_cont_amt_1 = parseFloat(result.data[0].thismonth[0].totalContractorAmount == null ? '0' : result.data[0].thismonth[0].totalContractorAmount);
          let this_month_t_exp_1 = parseFloat(
            result.data[1].thismonth[0].totalExpense == null
              ? "0"
              : result.data[1].thismonth[0].totalExpense
          );

          // let totalProfit1 = parseFloat(this_month_rent_1 + this_month_t_comm_1) - parseFloat( this_month_t_cont_amt_1 + this_month_t_exp_1  )
          // let totalProfit1 = parseFloat((this_month_rent_1 + this_month_t_comm_1 + this_month_t_cont_amt_1) - this_month_t_exp_1  ).toFixed(2)
          let totalProfit1 = parseFloat(
            this_month_rent_1 +
              this_month_t_comm_1 +
              this_month_income_1 -
              this_month_t_exp_1
          ).toFixed(2);

          let total_rent_3 = parseFloat(
            result.data[4].total[0].totalRent == null
              ? "0"
              : result.data[4].total[0].totalRent
          );
          let total_t_comm_3 = parseFloat(
            result.data[0].total[0].totalCommission == null
              ? "0"
              : result.data[0].total[0].totalCommission
          );
          let total_income_3 = parseFloat(
            result.data[5].total[0].totalIncome == null
              ? "0"
              : result.data[5].total[0].totalIncome
          );
          //let total_t_cont_amt_3 = parseFloat(result.data[0].total[0].totalContractorAmount == null ? '0' : result.data[0].total[0].totalContractorAmount);
          let total_t_exp_3 = parseFloat(
            result.data[1].total[0].totalExpense == null
              ? "0"
              : result.data[1].total[0].totalExpense
          );

          // let totalProfit3 = parseFloat(total_rent_3 + total_t_comm_3) - parseFloat(total_t_cont_amt_3 + total_t_exp_3 )
          // let totalProfit3 = parseFloat((total_rent_3 + total_t_comm_3 + total_t_cont_amt_3) - total_t_exp_3 ).toFixed(2)
          let totalProfit3 = parseFloat(
            total_rent_3 + total_t_comm_3 + total_income_3 - total_t_exp_3
          ).toFixed(2);

          let last_month_rent_2 = parseFloat(
            result.data[4].lastmonth[0].totalRent == null
              ? "0"
              : result.data[4].lastmonth[0].totalRent
          );
          let last_month_t_comm_2 = parseFloat(
            result.data[0].lastmonth[0].totalCommission == null
              ? "0"
              : result.data[0].lastmonth[0].totalCommission
          );
          let this_month_income_2 = parseFloat(
            result.data[5].lastmonth[0].totalIncome == null
              ? "0"
              : result.data[5].lastmonth[0].totalIncome
          );
          //let last_month_t_cont_amt_2 = parseFloat(result.data[0].lastmonth[0].totalContractorAmount == null ? '0' : result.data[0].lastmonth[0].totalContractorAmount);
          let last_month_t_exp_2 = parseFloat(
            result.data[1].lastmonth[0].totalExpense == null
              ? "0"
              : result.data[1].lastmonth[0].totalExpense
          );

          // let totalProfit2 = parseFloat(last_month_rent_2 + last_month_t_comm_2) - parseFloat(last_month_t_cont_amt_2 + last_month_t_exp_2)
          // let totalProfit2 = parseFloat((last_month_rent_2 + last_month_t_comm_2 + last_month_t_cont_amt_2) - last_month_t_exp_2).toFixed(2)
          let totalProfit2 = parseFloat(
            last_month_rent_2 +
              last_month_t_comm_2 +
              this_month_income_2 -
              last_month_t_exp_2
          ).toFixed(2);

          let d = [...result.data];
          d[6] = {
            thismonth: totalProfit1,
            lastmonth: totalProfit2,
            total: totalProfit3,
          };
          // console.log(d)
          this.setState({ cardData: d }, () => {
            console.log("Old.. In Statistics..DidMount..cardData->", d);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      message.error(this.msg.LOGINERROR, 3);
    }
  };
  //Latest new fetching Statistics method
  getCurrentPreviousAndCompleteStatisticsUpdated = (month, year) => {
    const headers = getHeadersForHttpReq();
    const user_id = localStorage.getItem("user_id");
    const client_id = localStorage.getItem("client_id");
    let data = { month, year, client_id, user_id };
    if (localStorage.getItem("is_contr") != 1) {
      Object.assign(data, { is_contr: 0 });
    } else {
      const contractor_id = JSON.parse(
        localStorage.getItem("updateContractor")
      ).id;
      Object.assign(data, { contractor_id: contractor_id, is_contr: 1 });
    }
    axios
      .post(`${complete_URL}/statistics/statistics_upd`, data, { headers })
      .then((cardsDataRes) => {
        let d = cardsDataRes.data;
        let this_month_rent_1 = 0,
          this_month_t_comm_1 = 0,
          this_month_cont_amount_1 = 0,
          this_month_income_1 = 0,
          this_month_t_exp_1 = 0,
          totalProfit1 = 0;
        let last_month_rent_2 = 0,
          last_month_t_comm_2 = 0,
          last_month_cont_amount_2 = 0,
          last_month_income_2 = 0,
          last_month_t_exp_2 = 0,
          totalProfit2 = 0;
        let total_rent_3 = 0,
          total_t_comm_3 = 0,
          total_cont_amount_3 = 0,
          total_income_3 = 0,
          total_t_exp_3 = 0,
          totalProfit3 = 0;
        if (localStorage.getItem("is_contr") != 1) {
          this_month_rent_1 = parseFloat(
            d[4].thismonth[0].totalRent == null
              ? "0"
              : d[4].thismonth[0].totalRent
          );
          this_month_t_comm_1 = parseFloat(
            d[0].thismonth[0].totalCommission == null
              ? "0"
              : d[0].thismonth[0].totalCommission
          );
          this_month_income_1 = parseFloat(
            d[5].thismonth[0].totalIncome == null
              ? "0"
              : d[5].thismonth[0].totalIncome
          );
          this_month_t_exp_1 = parseFloat(
            d[1].thismonth[0].totalExpense == null
              ? "0"
              : d[1].thismonth[0].totalExpense
          );

          totalProfit1 = parseFloat(
            this_month_rent_1 +
              this_month_t_comm_1 +
              this_month_income_1 -
              this_month_t_exp_1
          ).toFixed(2);

          last_month_rent_2 = parseFloat(
            d[4].lastmonth[0].totalRent == null
              ? "0"
              : d[4].lastmonth[0].totalRent
          );
          last_month_t_comm_2 = parseFloat(
            d[0].lastmonth[0].totalCommission == null
              ? "0"
              : d[0].lastmonth[0].totalCommission
          );
          last_month_income_2 = parseFloat(
            d[5].lastmonth[0].totalIncome == null
              ? "0"
              : d[5].lastmonth[0].totalIncome
          );
          last_month_t_exp_2 = parseFloat(
            d[1].lastmonth[0].totalExpense == null
              ? "0"
              : d[1].lastmonth[0].totalExpense
          );

          totalProfit2 = parseFloat(
            last_month_rent_2 +
              last_month_t_comm_2 +
              last_month_income_2 -
              last_month_t_exp_2
          ).toFixed(2);

          total_rent_3 = parseFloat(
            d[4].total[0].totalRent == null ? "0" : d[4].total[0].totalRent
          );
          total_t_comm_3 = parseFloat(
            d[0].total[0].totalCommission == null
              ? "0"
              : d[0].total[0].totalCommission
          );
          total_income_3 = parseFloat(
            d[5].total[0].totalIncome == null ? "0" : d[5].total[0].totalIncome
          );
          total_t_exp_3 = parseFloat(
            d[1].total[0].totalExpense == null
              ? "0"
              : d[1].total[0].totalExpense
          );

          totalProfit3 = parseFloat(
            total_rent_3 + total_t_comm_3 + total_income_3 - total_t_exp_3
          ).toFixed(2);
        } else {
          this_month_cont_amount_1 = parseFloat(
            d[0].thismonth[0].totalContractorAmount == null
              ? "0"
              : d[0].thismonth[0].totalContractorAmount
          );
          this_month_t_comm_1 = parseFloat(
            d[0].thismonth[0].totalCommission == null
              ? "0"
              : d[0].thismonth[0].totalCommission
          );
          this_month_income_1 = parseFloat(
            d[5].thismonth[0].totalIncome == null
              ? "0"
              : d[5].thismonth[0].totalIncome
          );
          this_month_t_exp_1 = parseFloat(
            d[1].thismonth[0].totalExpense == null
              ? "0"
              : d[1].thismonth[0].totalExpense
          );

          totalProfit1 = parseFloat(
            this_month_cont_amount_1 +
              this_month_income_1 -
              this_month_t_exp_1 -
              this_month_t_comm_1
          ).toFixed(2);

          last_month_cont_amount_2 = parseFloat(
            d[0].lastmonth[0].totalContractorAmount == null
              ? "0"
              : d[0].lastmonth[0].totalContractorAmount
          );
          last_month_t_comm_2 = parseFloat(
            d[0].lastmonth[0].totalCommission == null
              ? "0"
              : d[0].lastmonth[0].totalCommission
          );
          last_month_income_2 = parseFloat(
            d[5].lastmonth[0].totalIncome == null
              ? "0"
              : d[5].lastmonth[0].totalIncome
          );
          last_month_t_exp_2 = parseFloat(
            d[1].lastmonth[0].totalExpense == null
              ? "0"
              : d[1].lastmonth[0].totalExpense
          );

          totalProfit2 = parseFloat(
            last_month_cont_amount_2 +
              last_month_income_2 -
              last_month_t_exp_2 -
              last_month_t_comm_2
          ).toFixed(2);

          total_cont_amount_3 = parseFloat(
            d[0].total[0].totalContractorAmount == null
              ? "0"
              : d[0].total[0].totalContractorAmount
          );
          total_t_comm_3 = parseFloat(
            d[0].total[0].totalCommission == null
              ? "0"
              : d[0].total[0].totalCommission
          );
          total_income_3 = parseFloat(
            d[5].total[0].totalIncome == null ? "0" : d[5].total[0].totalIncome
          );
          total_t_exp_3 = parseFloat(
            d[1].total[0].totalExpense == null
              ? "0"
              : d[1].total[0].totalExpense
          );

          totalProfit3 = parseFloat(
            total_cont_amount_3 +
              total_income_3 -
              total_t_exp_3 -
              total_t_comm_3
          ).toFixed(2);
        }
        d[6] = {
          thismonth: totalProfit1,
          lastmonth: totalProfit2,
          total: totalProfit3,
        };
        this.setState(
          {
            cardData: d,
          } /*,()=>{console.log('New.. In Statistics..DidMount..cardData->',d)}*/
        );
      })
      .catch((cardsDataErr) => {
        console.log(
          "Error occured while fetching Dashboard cards data->",
          cardsDataErr
        );
      });
  }; //End of method new

  //unnecessary code
  filterChart = (e) => {
    // let month = e.locale('en').format('M');
    // let year = e.locale('en').format('YYYY');
    // console.log(month)
    // this.fetchesGraphData(month, year)
  };

  fetchPieChartData = (req_data) => {
    const headers = getHeadersForHttpReq();
    axios({
      url: `${complete_URL}/expenses/piechart_by_client_id`,
      method: "post",
      data: req_data,
      headers,
    })
      .then((pieChart_response) => {
        this.setState({ spin: false });
        if (pieChart_response.data.data.length > 0) {
          let d = [],
            c = [],
            l = [];
          for (let i = 0; i < pieChart_response.data.data.length; i++) {
            d.push(pieChart_response.data.data[i].amount.toFixed(2));
            l.push(
              localStorage.getItem("lang") == "EN"
                ? pieChart_response.data.data[i].expense_type_name
                : pieChart_response.data.data[i].expense_type_name_ar
            );
            let clr = "#" + (((1 << 24) * Math.random()) | 0).toString(16);
            c.push(clr);
            // c.push(this.state.random_colors[i]);
          }
          let data_updated = [];
          data_updated.push(
            Object.assign(
              {},
              { label: "Expenses", data: d, backgroundColor: c }
            )
          );
          this.setState(
            {
              piChartCompleteData: { labels: l, datasets: data_updated },
            } /*,()=>{
               console.log('this.state.piChartCompleteData->',this.state.piChartCompleteData)
            }*/
          );
        } else {
          message.error(this.msg.EXPPIEDATANOTFOUNDERROR, 3);
        }
      })
      .catch((pieChart_err) => {
        this.setState({ spin: false });
        message.error(this.msg.EXPPIEDATAFETCHFAILURE, 3);
        console.log(
          "In Statistics..fetchPieChartData..pieChart_err->",
          pieChart_err
        );
      });
  }; //End of method

  generateExpenseReportGeneric = (req_data, file_name) => {
    const headers2 = getHeadersForPDFreport();
    axios({
      url: `${complete_URL}/expense_report/by_data_client_id`,
      headers: headers2,
      method: "post",
      data: req_data,
      responseType: "arraybuffer",
    })
      .then((exp_pie_report_response) => {
        this.setState({ spin: false });
        if (exp_pie_report_response.data.byteLength > 0) {
          this.generate_pdf_popup(exp_pie_report_response.data, file_name);
        } else if (
          exp_pie_report_response.statusText === "Provided incomplete data!!"
        ) {
          message.error(this.msg.EXPREPORTDATANOTPROVIDEDERROR, 3);
        } else {
          message.error(
            this.msg.EXPREPORTGENERATEFAILURE,
            3 /*'Expense Data not found in this month duration'*/
          );
        }
      })
      .catch((exp_pie_report_err) => {
        this.setState({ spin: false });
        console.log(
          "Error occured while fetching exp_pie_report_err ->",
          exp_pie_report_err
        );
        message.error(this.msg.EXPREPORTGENERATEFAILURE, 3);
      });
  }; //End of Expense Report Generic method

  generate_pdf_popup = (data, file_name) => {
    const blob = new Blob([data], { type: "application/pdf" }); //make available for download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = file_name;
    /*link.click();*/
    link.target = "_blank";
    window.open(link);
  };
  // ContractorReport is Hidden feature Ajax generic request
  generateContractorReportGeneric = (req_data, file_name, dataNotFoundMsg) => {
    const { cont_id, serv_cont_name, serv_cont_name_ar } =
      this.state.searchValues_cont_Report;
    Object.assign(req_data, { cont_id, serv_cont_name, serv_cont_name_ar });
    const headers2 = getHeadersForPDFreport();
    axios({
      url: complete_URL + "/contractor_reports/generic_report",
      headers: headers2,
      method: "post",
      data: req_data,
      responseType: "arraybuffer",
    })
      .then((response) => {
        this.setState({ spin: false });
        if (response.data.byteLength > 0) {
          this.generate_pdf_popup(response.data, file_name);
        } else if (
          response.statusText ===
          "Required input not found to generate Contractor report!!"
        ) {
          message.error(this.report_lang_msg.CONTREPORTDATANOTPROVIDEDERROR, 3);
        } else if (response.statusText === "No Content") {
          message.error(dataNotFoundMsg, 3);
        } else {
          message.error(response.statusText);
        }
      })
      .catch((err) => {
        this.setState({ spin: false });
        message.error(
          this.report_lang_msg.CONTREPORTGENERATEFAILURE /*err.message*/,
          3
        );
        console.log("Error occured while fetching Contractor report->", err);
      });
  }; //End of Contractor Generic Method

  // ReservationReport is Hidden feature Ajax generic request
  generateReservationReportGeneric = (req_data, file_name) => {
    Object.assign(req_data, {
      status: this.state.searchValues_resrv_Report.status,
      show_rules: false,
    });
    const headers2 = getHeadersForPDFreport();
    axios({
      url: complete_URL + "/reservation_reports/by_duration_data_client_id",
      headers: headers2,
      method: "post",
      data: req_data,
      responseType: "arraybuffer",
    })
      .then((resrv_response) => {
        this.setState({ spin: false });
        if (resrv_response.data.byteLength > 0) {
          this.generate_pdf_popup(resrv_response.data, file_name);
        } else {
          message.error(
            this.report_lang_msg.RESRVREPORTGENERATEFAILURE,
            3 /*resrv_response.statusText*/
          );
        }
      })
      .catch((res_err) => {
        this.setState({ spin: false });
        console.log("in ReportScreen.. resrv report..res_err->", res_err);
        if (
          res_err.statusText === "Reservations not found in this duration!!"
        ) {
          message.error(this.report_lang_msg.RESRVDATANOTFOUNDERROR, 3);
        } else if (res_err.statusText === "Provided incomplete data!!") {
          message.error(
            this.report_lang_msg.RESRVREPORTDATANOTPROVIDEDERROR,
            3
          );
        } else if (
          res_err.statusText === "No Content" ||
          res_err.statusText === "[object Object]"
        ) {
          message.error(this.report_lang_msg.RESRVDATANOTFOUNDERROR, 3);
        } else {
          message.error(this.report_lang_msg.RESRVREPORTGENERATEFAILURE, 3);
        }
      });
  }; //End of Reservation Generic Method

  getReportBasicData = () => {
    let opt = "en";
    const client_id = localStorage.getItem("client_id");
    const user_name = localStorage.getItem("user_name");
    const user_id = localStorage.getItem("user_id");

    if (localStorage.getItem("lang") == "AR") {
      opt = "-ar";
    }
    let api_main_part = API_URL.split("/")[2];
    let req_data = {
      api_main_part,
      port: pt,
      opt,
      user_name,
      client_id,
      user_id,
    };
    if (localStorage.getItem("is_contr") != 1) {
      Object.assign(req_data, { is_contr: "0" });
    } else {
      const contractor_id = JSON.parse(
        localStorage.getItem("updateContractor")
      ).id;
      const selectedClient = this.state.searchValues_fn_Report.selectedClient;
      Object.assign(req_data, { contractor_id, selectedClient, is_contr: "1" });
    }
    return req_data;
  }; //End of Method

  downloadOnDateData = (date, report_type) => {
    let req_data = this.getReportBasicData();
    if (req_data.client_id) {
      const headers2 = getHeadersForPDFreport();
      const duration = date + "";
      const version = req_data.opt == "en" ? "Eng" : "Arb";
      Object.assign(req_data, { date });
      if (report_type === "fn") {
        message.success("Feature to be added!!");
      } else if (report_type === "cont") {
        let file_name =
          "Contractor_report_" + duration + "_" + version + ".pdf";
        this.generateContractorReportGeneric(
          req_data,
          file_name,
          this.report_lang_msg.CONTREPORTDATEDATANOTFOUNDERROR
        );
      } else if (report_type === "resrv") {
        let file_name =
          "Reservation_report_" + duration + "_" + version + ".pdf";
        this.generateReservationReportGeneric(req_data, file_name);
      }
    } //End of ClientID exists
    else {
      message.error(this.msg.LOGINERROR, 3);
    }
  }; //End of Method

  downloadBetweenDatesData = (date1, date2, report_type) => {
    let req_data = this.getReportBasicData();
    if (req_data.client_id) {
      const headers2 = getHeadersForPDFreport();
      const duration = date1 + "_to_" + date2;
      const version = req_data.opt == "en" ? "Eng" : "Arb";
      Object.assign(req_data, { date1, date2 });
      if (report_type === "fn") {
        message.error("Feature to be added soon!!");
      } else if (report_type === "cont") {
        let file_name =
          "Contractor_report_" + duration + "_" + version + ".pdf";
        this.generateContractorReportGeneric(
          req_data,
          file_name,
          this.report_lang_msg.CONTREPORTDATESDATANOTFOUNDERROR
        );
      } else if (report_type === "resrv") {
        let file_name =
          "Reservation_report_" + duration + "_" + version + ".pdf";
        this.generateReservationReportGeneric(req_data, file_name);
      } //End of reservation_reports
    } //End of ClientID exists
    else {
      message.error(this.msg.LOGINERROR, 3);
    }
  }; //End of Method

  downloadOneMonthOfYearData = (month, year, report_type) => {
    let req_data = this.getReportBasicData();
    if (req_data.client_id) {
      const headers2 = getHeadersForPDFreport();
      const duration = month + "_" + year;
      const version = req_data.opt == "en" ? "Eng" : "Arb";
      Object.assign(req_data, { month, year });
      if (report_type === "fn") {
        this.setState({ reportDate: moment(`${year}-${month}`) });
        axios
          .get(`${complete_URL}/finance_reports/export_pdf_month/`, {
            responseType: "arraybuffer",
            headers: {
              ...headers2,
              /*'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
               'Accept': 'application/pdf' */
            },
            params: { data: JSON.stringify(req_data) },
          })
          .then((response) => {
            this.setState({ spin: false });
            if (response.data.byteLength > 0) {
              let file_name = "Finance_report_" + duration + ".pdf";
              this.generate_pdf_popup(response.data, file_name);
            } else if (
              response.statusText ===
              "Required input not found to generate Finance report!!"
            ) {
              message.error(this.msg.FINCREPORTDATANOTPROVIDEDERROR, 3);
            } else {
              message.error(response.statusText);
            }
          })
          .catch((err) => {
            this.setState({ spin: false });
            message.error(this.msg.FINCREPORTGENERATEFAILURE /*err.message*/);
            console.log(err);
          });
      } //End of Finance request
      else if (report_type === "barChart") {
        this.fetchBarGraphDataGenericService(req_data);
      } else if (report_type === "pie") {
        this.fetchPieChartData(req_data);
      } else if (report_type === "pie_report") {
        let file_name = "Expense_report_" + duration + "_" + version + ".pdf";
        this.generateExpenseReportGeneric(req_data, file_name);
      } else if (report_type === "cont") {
        let file_name =
          "Contractor_report_" + duration + "_" + version + ".pdf";
        this.generateContractorReportGeneric(
          req_data,
          file_name,
          this.report_lang_msg.CONTREPORTMONTHDATANOTFOUNDERROR
        );
      } else if (report_type === "resrv") {
        let file_name =
          "Reservation_report_" + duration + "_" + version + ".pdf";
        this.generateReservationReportGeneric(req_data, file_name);
      }
    } //End of ClientID exists
    else {
      message.error(this.msg.LOGINERROR, 3);
    }
  }; //End of Method

  downloadBetweenMonthsData = (month1, month2, year1, year2, report_type) => {
    let req_data = this.getReportBasicData();
    if (req_data.client_id) {
      const headers2 = getHeadersForPDFreport();
      const duration = month1 + "_" + year1 + "_to_" + month2 + "_" + year2;
      const version = req_data.opt == "en" ? "Eng" : "Arb";
      Object.assign(req_data, { month1, month2, year1, year2 });
      if (report_type === "fn") {
        axios
          .get(`${complete_URL}/finance_reports/export_pdf_months/`, {
            responseType: "arraybuffer",
            headers: {
              ...headers2,
              /*'Authorization': localStorage.getItem("tokenType") + " " + localStorage.getItem("token"),
               'Accept': 'application/pdf' */
            },
            params: { data: JSON.stringify(req_data) },
          })
          .then((response) => {
            this.setState({ spin: false });
            if (response.data.byteLength > 0) {
              let file_name = "Finance_report_" + duration + ".pdf";
              this.generate_pdf_popup(response.data, file_name);
            } else if (
              response.statusText ===
              "Required input not found to generate Finance report!!"
            ) {
              message.error(this.msg.FINCREPORTDATANOTPROVIDEDERROR, 3);
            } else {
              message.error(response.statusText);
            }
          })
          .catch((err) => {
            this.setState({ spin: false });
            message.error(this.msg.FINCREPORTGENERATEFAILURE /*err.message*/);
            console.log(err);
          });
      } //End of Finance request
      else if (report_type === "barChart") {
        this.fetchBarGraphDataGenericService(req_data);
      } else if (report_type === "pie") {
        this.fetchPieChartData(req_data);
      } else if (report_type === "pie_report") {
        let file_name = "Expense_report_" + duration + "_" + version + ".pdf";
        this.generateExpenseReportGeneric(req_data, file_name);
      } else if (report_type === "cont") {
        let file_name =
          "Contractor_report_" + duration + "_" + version + ".pdf";
        this.generateContractorReportGeneric(
          req_data,
          file_name,
          this.report_lang_msg.CONTREPORTMONTHSDATANOTFOUNDERROR
        );
      } else if (report_type === "resrv") {
        let file_name =
          "Reservation_report_" + duration + "_" + version + ".pdf";
        this.generateReservationReportGeneric(req_data, file_name);
      }
    } //End of ClientID exists
    else {
      message.error(this.msg.LOGINERROR, 3);
    }
  }; //End of Method

  downloadYearOnlyData = (yearOnly, report_type) => {
    let req_data = this.getReportBasicData();
    if (req_data.client_id) {
      const headers2 = getHeadersForPDFreport();
      const duration = yearOnly;
      const version = req_data.opt == "en" ? "Eng" : "Arb";
      Object.assign(req_data, { yearOnly });
      if (report_type === "fn") {
        axios
          .get(`${complete_URL}/finance_reports/export_pdf_year/`, {
            responseType: "arraybuffer",
            headers: { ...headers2 },
            params: { data: JSON.stringify(req_data) },
          })
          .then((response) => {
            this.setState({ spin: false });
            if (response.data.byteLength > 0) {
              let file_name = "Finance_report_complete_" + duration + ".pdf";
              this.generate_pdf_popup(response.data, file_name);
            } else if (
              response.statusText ===
              "Required input not found to generate Finance report!!"
            ) {
              message.error(this.msg.FINCREPORTDATANOTPROVIDEDERROR, 3);
            } else {
              message.error(response.statusText);
            }
          })
          .catch((err) => {
            this.setState({ spin: false });
            message.error(this.msg.FINCREPORTGENERATEFAILURE /*err.message*/);
            console.log(
              "Error occured while generating Finance yearly report ->",
              err
            );
          });
      } //End of Finance request
      else if (report_type === "barChart") {
        this.fetchBarGraphDataGenericService(req_data);
      } else if (report_type === "pie") {
        this.fetchPieChartData(req_data);
      } else if (report_type === "pie_report") {
        let file_name = "Expense_report_" + duration + "_" + version + ".pdf";
        this.generateExpenseReportGeneric(req_data, file_name);
      } else if (report_type === "cont") {
        let file_name =
          "Contractor_report_" + duration + "_" + version + ".pdf";
        this.generateContractorReportGeneric(
          req_data,
          file_name,
          this.report_lang_msg.CONTREPORTYEARDATANOTFOUNDERROR
        );
      } else if (report_type === "resrv") {
        let file_name =
          "Reservation_report_" + duration + "_" + version + ".pdf";
        this.generateReservationReportGeneric(req_data, file_name);
      }
    } //End of ClientID exists
    else {
      message.error(this.msg.LOGINERROR, 3);
    }
  }; //End of Method

  downloadAllData = (report_type) => {
    let req_data = this.getReportBasicData();
    if (req_data.client_id) {
      const headers2 = getHeadersForPDFreport();
      const version = req_data.opt == "en" ? "Eng" : "Arb";
      Object.assign(req_data, { allData: "yes" });
      if (report_type === "fn") {
        axios
          .get(`${complete_URL}/finance_reports/export_pdf_completeData/`, {
            responseType: "arraybuffer",
            headers: { ...headers2 },
            params: { data: JSON.stringify(req_data) },
          })
          .then((response) => {
            this.setState({ spin: false });
            if (response.data.byteLength > 0) {
              this.generate_pdf_popup(
                response.data,
                "Finance_report_all_data.pdf"
              );
            } else if (
              response.statusText ===
              "Required input not found to generate Finance report!!"
            ) {
              message.error(this.msg.FINCREPORTDATANOTPROVIDEDERROR, 3);
            } else {
              message.error(response.statusText);
            }
          })
          .catch((err) => {
            this.setState({ spin: false });
            message.error(this.msg.FINCREPORTGENERATEFAILURE /*err.message*/);
            console.log("Error occured while fetching Finance report->", err);
          });
      } //End of Finance request
      else if (report_type === "barChart") {
        this.fetchBarGraphDataGenericService(req_data);
      } else if (report_type === "pie") {
        this.fetchPieChartData(req_data);
      } else if (report_type === "pie_report") {
        let file_name = "Expense_report_all_data_" + version + ".pdf";
        this.generateExpenseReportGeneric(req_data, file_name);
      } else if (report_type === "cont") {
        let file_name = "Contractor_report_all_data_" + version + ".pdf";
        this.generateContractorReportGeneric(
          req_data,
          file_name,
          this.report_lang_msg.CONTREPORTALLDATANOTFOUNDERROR
        );
      } else if (report_type === "resrv") {
        let file_name = "Reservation_report_all_data_" + version + ".pdf";
        this.generateReservationReportGeneric(req_data, file_name);
      }
    } //End of ClientID exists
    else {
      message.error(this.msg.LOGINERROR, 3);
    }
  }; //End of Method

  setParamsOnDate = (date, resetOpt, report_type) => {
    if (date) {
      if (report_type === "fn") {
        let new_report_obj = Object.assign(this.state.searchValues_fn_Report, {
          date: date,
        });
        this.setState({
          searchValues_fn_Report: new_report_obj,
          disableDownloadReport: resetOpt,
        });
      } //End of if Fin
      else if (report_type === "cont") {
        if (!resetOpt) {
          let { serv_cont_name, serv_cont_name_ar } =
            this.state.searchValues_cont_Report;
          if (!serv_cont_name || !serv_cont_name_ar) {
            resetOpt = true;
          }
        }
        let new_report_obj = Object.assign(
          this.state.searchValues_cont_Report,
          { date: date }
        );
        this.setState({
          searchValues_cont_Report: new_report_obj,
          disableDownloadContReport: resetOpt,
        });
      } //End of if Cont
      else if (report_type === "resrv") {
        let new_report_obj = Object.assign(
          this.state.searchValues_resrv_Report,
          { date: date }
        );
        this.setState({
          searchValues_resrv_Report: new_report_obj,
          disableDownloadResrvReport: resetOpt,
        });
      } //End of if resrv
    }
  }; //End of Method

  setParamsDatesDuration = (from_date, to_date, resetOpt, report_type) => {
    if (from_date && to_date) {
      if (report_type === "fn") {
        let new_report_obj = Object.assign(this.state.searchValues_fn_Report, {
          from_date: from_date,
          to_date: to_date,
        });
        this.setState({
          searchValues_fn_Report: new_report_obj,
          disableDownloadReport: resetOpt,
        });
      } //End of if Fin
      else if (report_type === "cont") {
        if (!resetOpt) {
          let { serv_cont_name, serv_cont_name_ar } =
            this.state.searchValues_cont_Report;
          if (!serv_cont_name || !serv_cont_name_ar) {
            resetOpt = true;
          }
        }
        let new_report_obj = Object.assign(
          this.state.searchValues_cont_Report,
          { from_date: from_date, to_date: to_date }
        );
        this.setState({
          searchValues_cont_Report: new_report_obj,
          disableDownloadContReport: resetOpt,
        });
      } //End of if Cont
      else if (report_type === "resrv") {
        let new_report_obj = Object.assign(
          this.state.searchValues_resrv_Report,
          { from_date: from_date, to_date: to_date }
        );
        this.setState({
          searchValues_resrv_Report: new_report_obj,
          disableDownloadResrvReport: resetOpt,
        });
      } //End of if Resrv
    }
  }; //End of Method

  setParamsOneMonthOfYear = (month, year, resetOpt, report_type) => {
    if (report_type === "fn") {
      let new_report_obj = Object.assign(this.state.searchValues_fn_Report, {
        month: month,
        year: year,
      });
      this.setState({
        searchValues_fn_Report: new_report_obj,
        disableDownloadReport: resetOpt,
      });
      if (month && year) {
        // create bar chart if data is being set
        // this.fetchesGraphData2(month, year);
        this.downloadOneMonthOfYearData(month, year, "barChart");
      }
    } //End of if Fin
    else if (report_type === "pie") {
      let new_pieChart_obj = Object.assign(this.state.searchValues_PieChart, {
        month: month,
        year: year,
      });
      this.setState({
        searchValues_PieChart: new_pieChart_obj,
        disableDownloadPieReport: resetOpt,
      });
      if (month && year) {
        // create PIE chart if data is being set
        this.downloadOneMonthOfYearData(month, year, report_type);
      }
    } //End of if Cont
    else if (report_type === "cont") {
      if (!resetOpt) {
        let { serv_cont_name, serv_cont_name_ar } =
          this.state.searchValues_cont_Report;
        if (!serv_cont_name || !serv_cont_name_ar) {
          resetOpt = true;
        }
      }
      let new_report_obj = Object.assign(this.state.searchValues_cont_Report, {
        month: month,
        year: year,
      });
      this.setState({
        searchValues_cont_Report: new_report_obj,
        disableDownloadContReport: resetOpt,
      });
    } //End of if Cont
    else if (report_type === "resrv") {
      let new_report_obj = Object.assign(this.state.searchValues_resrv_Report, {
        month: month,
        year: year,
      });
      this.setState({
        searchValues_resrv_Report: new_report_obj,
        disableDownloadResrvReport: resetOpt,
      });
    } //End of if resrv
  }; //End of Method

  setParamsBetweenMonths = (
    month1,
    month2,
    year1,
    year2,
    resetOpt,
    report_type
  ) => {
    if (report_type === "fn") {
      let new_report_obj = Object.assign(this.state.searchValues_fn_Report, {
        month1: month1,
        month2: month2,
        year1: year1,
        year2: year2,
      });
      this.setState({
        searchValues_fn_Report: new_report_obj,
        disableDownloadReport: resetOpt,
      });
      /*if(month1 && year1 && month2 && year2){// create bar chart if data is being set
            //Here first already Bar chart is created from 'handleChange' actionListener and then params are set in State         
         }*/
    } //End of if Fin
    else if (report_type === "pie") {
      let new_pieChart_obj = Object.assign(this.state.searchValues_PieChart, {
        month1: month1,
        month2: month2,
        year1: year1,
        year2: year2,
      });
      this.setState({
        searchValues_PieChart: new_pieChart_obj,
        disableDownloadPieReport: resetOpt,
      });
    } else if (report_type === "cont") {
      if (!resetOpt) {
        let { serv_cont_name, serv_cont_name_ar } =
          this.state.searchValues_cont_Report;
        if (!serv_cont_name || !serv_cont_name_ar) {
          resetOpt = true;
        }
      }
      let new_report_obj = Object.assign(this.state.searchValues_cont_Report, {
        month1: month1,
        month2: month2,
        year1: year1,
        year2: year2,
      });
      this.setState({
        searchValues_cont_Report: new_report_obj,
        disableDownloadContReport: resetOpt,
      });
    } //End of if Cont
    else if (report_type === "resrv") {
      let new_report_obj = Object.assign(this.state.searchValues_resrv_Report, {
        month1: month1,
        month2: month2,
        year1: year1,
        year2: year2,
      });
      this.setState({
        searchValues_resrv_Report: new_report_obj,
        disableDownloadResrvReport: resetOpt,
      });
    }
  }; //End of Method

  setParamsYear = (yearOnly, resetOpt, report_type) => {
    if (report_type === "fn") {
      let new_report_obj = Object.assign(this.state.searchValues_fn_Report, {
        yearOnly: yearOnly,
      });
      this.setState({
        searchValues_fn_Report: new_report_obj,
        disableDownloadReport: resetOpt,
      });
      if (yearOnly) {
        // create bar chart if data is being set
        // this.fetchesGraphDataByYear(yearOnly);
        this.downloadYearOnlyData(yearOnly, "barChart");
      }
    } else if (report_type === "pie") {
      let new_pieChart_obj = Object.assign(this.state.searchValues_PieChart, {
        yearOnly: yearOnly,
      });
      this.setState({
        searchValues_PieChart: new_pieChart_obj,
        disableDownloadPieReport: resetOpt,
      });
      if (yearOnly) {
        // create PIE chart if data is being set
        this.downloadYearOnlyData(yearOnly, report_type);
      }
    } else if (report_type === "cont") {
      if (!resetOpt) {
        let { serv_cont_name, serv_cont_name_ar } =
          this.state.searchValues_cont_Report;
        if (!serv_cont_name || !serv_cont_name_ar) {
          resetOpt = true;
        }
      }
      let new_report_obj = Object.assign(this.state.searchValues_cont_Report, {
        yearOnly: yearOnly,
      });
      this.setState({
        searchValues_cont_Report: new_report_obj,
        disableDownloadContReport: resetOpt,
      });
    } //End of Cont
    else if (report_type === "resrv") {
      let new_report_obj = Object.assign(this.state.searchValues_resrv_Report, {
        yearOnly: yearOnly,
      });
      this.setState({
        searchValues_resrv_Report: new_report_obj,
        disableDownloadResrvReport: resetOpt,
      });
    }
  }; //End of Method

  downloadReport = (report_type) => {
    if (report_type === "fn") {
      this.setState({
        spinningMessage: this.msg.FINCDOWNLOADREPORTTIP,
        spin: true,
      });
      let { month, year, month1, month2, year1, year2, yearOnly, allData } =
        this.state.searchValues_fn_Report;
      if (month && year) {
        this.downloadOneMonthOfYearData(month, year, "fn");
        this.setParamsOneMonthOfYear("", "", true, "fn");
      } else if (month1 && month2 && year1 && year2) {
        this.downloadBetweenMonthsData(month1, month2, year1, year2, "fn");
        this.setParamsBetweenMonths("", "", "", "", true, "fn");
      } else if (yearOnly) {
        this.downloadYearOnlyData(yearOnly, "fn");
        this.setParamsYear("", true, "fn");
      } else if (allData) {
        //Bar chart data is aleady displayed on Select Action listener
        this.downloadAllData("fn");
        this.setState({
          searchValues_fn_Report: {
            month: "",
            year: "",
            month1: "",
            month2: "",
            year1: "",
            year2: "",
            yearOnly: "",
            allData: "",
          },
          disableDownloadReport: true,
        });
      }
    } //End of if Fin
    else if (report_type === "cont") {
      this.setState({
        spinningMessage: this.msg.CONTDOWNLOADREPORTTIP,
        spin: true,
      });
      let {
        serv_cont_name,
        serv_cont_name_ar,
        date,
        from_date,
        to_date,
        month,
        year,
        month1,
        month2,
        year1,
        year2,
        yearOnly,
        allData,
      } = this.state.searchValues_cont_Report;
      // console.log('Contractor report obj->', this.state.searchValues_cont_Report);
      if (date && serv_cont_name && serv_cont_name_ar) {
        this.downloadOnDateData(date, "cont");
        this.setParamsOnDate("", true, "cont");
      } else if (from_date && to_date && serv_cont_name && serv_cont_name_ar) {
        this.downloadBetweenDatesData(from_date, to_date, "cont");
        this.setParamsDatesDuration("", "", true, "cont");
      } else if (month && year && serv_cont_name && serv_cont_name_ar) {
        this.downloadOneMonthOfYearData(month, year, "cont");
        this.setParamsOneMonthOfYear("", "", true, "cont");
      } else if (
        month1 &&
        month2 &&
        year1 &&
        year2 &&
        serv_cont_name &&
        serv_cont_name_ar
      ) {
        this.downloadBetweenMonthsData(month1, month2, year1, year2, "cont");
        this.setParamsBetweenMonths("", "", "", "", true, "cont");
      } else if (yearOnly && serv_cont_name && serv_cont_name_ar) {
        this.downloadYearOnlyData(yearOnly, "cont");
        this.setParamsYear("", true, "cont");
      } else if (allData && serv_cont_name && serv_cont_name_ar) {
        this.downloadAllData("cont");
      }
    } //End of Contractor Choice
    else if (report_type === "resrv") {
      this.setState({
        spinningMessage: this.msg.RESRVDOWNLOADREPORTTIP,
        spin: true,
      });
      let { from_date, to_date, status } = this.state.searchValues_resrv_Report;
      if (from_date && to_date && status) {
        this.downloadBetweenDatesData(from_date, to_date, report_type);
        this.setParamsDatesDuration("", "", true, report_type);
      }
    } //End of Reservation Choice
    //Extra code for PIE filter and params selection.. as all this already handled when params are set..
    else if (report_type == "pie") {
      this.expenses_report_download_process(
        this.msg.EXPCREATEPICHARTTIP,
        report_type
      );
    } //End of PieChart Choice
    else if (report_type == "pie_report") {
      this.expenses_report_download_process(
        this.msg.EXPDOWNLOADREPORTTIP,
        report_type
      );
    } //End of PieChart Choice
  }; //End of downloadReport Method

  expenses_report_download_process = (spinning_msg, report_type) => {
    this.setState({ spinningMessage: spinning_msg, spin: true });
    let { month, year, month1, month2, year1, year2, yearOnly, allData } =
      this.state.searchValues_PieChart;
    if (month && year) {
      this.downloadOneMonthOfYearData(month, year, report_type);
      this.setParamsOneMonthOfYear("", "", true, report_type);
    } else if (month1 && month2 && year1 && year2) {
      this.downloadBetweenMonthsData(month1, month2, year1, year2, report_type);
      this.setParamsBetweenMonths("", "", "", "", true, report_type);
    } else if (yearOnly) {
      this.downloadYearOnlyData(yearOnly, report_type);
      this.setParamsYear("", true, report_type);
    } else if (allData) {
      //PIE chart data is aleady displayed on Select Action listener
      this.downloadAllData(report_type);
      this.setState({
        searchValues_PieChart: {
          month: "",
          year: "",
          month1: "",
          year1: "",
          month2: "",
          year2: "",
          yearOnly: "",
          allData: "",
        },
        disableDownloadPieReport: true,
      });
    }
  };

  // Old 2nd version Bar graph Ajax request method; not being used
  fetchesGraphData2 = (month, year) => {
    this.setState({ reportDate: moment(`${year}-${month}`) });
    let total_profit;
    const headers = getHeadersForHttpReq();

    let cardData = [...this.state.CardData];
    // axios.get(`${complete_URL}/statistics/getGraphDataByMonthAndYear/${localStorage.getItem('client_id')}/${month}/${year}`)
    axios
      .get(
        `${complete_URL}/statistics/oneMonthOfYearGraphData/${localStorage.getItem(
          "client_id"
        )}/${month}/${year}`,
        { headers }
      )
      .then((reslt) => {
        // console.log('Bar chart '+month+' month'+' year '+year+' -->',reslt)
        let a = [];
        a[0] = {
          title: "Total Expenses " + parseFloat(reslt.data[1].open).toFixed(2),
        };
        a[1] = {
          title:
            "Total Commission " + parseFloat(reslt.data[3].open).toFixed(2),
        };
        a[2] = {
          title: "Total Paid Rent " + parseFloat(reslt.data[2].open).toFixed(2),
        };
        a[3] = {
          title:
            "Total  Contractor Amount " +
            parseFloat(reslt.data[0].open).toFixed(2),
        };
        total_profit =
          parseFloat(
            parseFloat(reslt.data[2].open) +
              parseFloat(reslt.data[3].open) +
              parseFloat(reslt.data[0].open)
          ) - parseFloat(reslt.data[1].open);
        cardData[0].total = total_profit.toFixed(2);
        this.setState(
          { CardData: cardData, data: Object.values(reslt.data), list: a },
          () => {
            if (this.props.language.AR_FLAG) {
              this.changeBarChartAmountDir();
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Old 2nd version Bar graph Ajax request method; not being used
  fetchesGraphDataAlltime = () => {
    const headers = getHeadersForHttpReq();
    let total_profit;
    let cardData = [...this.state.CardData];
    // axios.get(`${complete_URL}/statistics/alltime/${localStorage.getItem('client_id')}`)
    axios
      .get(
        `${complete_URL}/statistics/completeDataForGraph/${localStorage.getItem(
          "client_id"
        )}`,
        { headers }
      )
      .then((reslt) => {
        // console.log(reslt)
        let a = [];
        a[0] = {
          title: "Total Expenses " + parseFloat(reslt.data[1].open).toFixed(2),
        };
        a[1] = {
          title:
            "Total Commission " + parseFloat(reslt.data[3].open).toFixed(2),
        };
        a[2] = {
          title: "Total Paid Rent " + parseFloat(reslt.data[2].open).toFixed(2),
        };
        a[3] = {
          title:
            "Total  Contractor Amount " +
            parseFloat(reslt.data[0].open).toFixed(2),
        };
        total_profit =
          parseFloat(
            parseFloat(reslt.data[2].open) +
              parseFloat(reslt.data[3].open) +
              parseFloat(reslt.data[0].open)
          ) - parseFloat(reslt.data[1].open);
        cardData[0].total = total_profit.toFixed(2);
        this.setState(
          { CardData: cardData, data: Object.values(reslt.data), list: a },
          () => {
            if (this.props.language.AR_FLAG) {
              this.changeBarChartAmountDir();
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Old 2nd version Bar graph Ajax request method; not being used
  fetchesGraphDataByYear = (year) => {
    let total_profit;
    const headers = getHeadersForHttpReq();

    let cardData = [...this.state.CardData];
    // axios.get(`${complete_URL}/statistics/getGraphDataByYears/${localStorage.getItem('client_id')}/${year}`)
    axios
      .get(
        `${complete_URL}/statistics/annuallyGraphData/${localStorage.getItem(
          "client_id"
        )}/${year}`,
        { headers }
      )
      .then((reslt) => {
        // console.log(reslt)
        let a = [];
        a[0] = {
          title: "Total Expenses " + parseFloat(reslt.data[1].open).toFixed(2),
        };
        a[1] = {
          title:
            "Total Commission " + parseFloat(reslt.data[3].open).toFixed(2),
        };
        a[2] = {
          title: "Total Paid Rent " + parseFloat(reslt.data[2].open).toFixed(2),
        };
        a[3] = {
          title:
            "Total  Contractor Amount " +
            parseFloat(reslt.data[0].open).toFixed(2),
        };
        total_profit =
          parseFloat(
            parseFloat(reslt.data[2].open) +
              parseFloat(reslt.data[3].open) +
              parseFloat(reslt.data[0].open)
          ) - parseFloat(reslt.data[1].open);
        cardData[0].total = total_profit.toFixed(2);
        this.setState(
          { CardData: cardData, data: Object.values(reslt.data), list: a },
          () => {
            if (this.props.language.AR_FLAG) {
              this.changeBarChartAmountDir();
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Old 2nd version Bar graph Ajax request method; not being used
  fetchesGraphBetweenMonths = (month1, month2, year1, year2) => {
    let client_id = localStorage.getItem("client_id");
    if (client_id) {
      const headers = getHeadersForHttpReq();
      let total_profit;
      let cardData = [...this.state.CardData];
      // axios.get(`${complete_URL}/statistics/getGraphDataByBetweenMonths/${localStorage.getItem('client_id')}/${month1}/${year1}/${month2}/${year2}`)
      axios
        .get(
          `${complete_URL}/statistics/betweenMonthsGraphData/${client_id}/${month1}/${year1}/${month2}/${year2}`,
          { headers }
        )
        .then((duration_res) => {
          //console.log('fetchesGraphBetweenMonths.. results->',duration_res.data)
          let a = [];
          a[0] = {
            title:
              "Total Expenses " +
              parseFloat(duration_res.data[1].open).toFixed(2),
          };
          a[1] = {
            title:
              "Total Commission " +
              parseFloat(duration_res.data[3].open).toFixed(2),
          };
          a[2] = {
            title:
              "Total Paid Rent " +
              parseFloat(duration_res.data[2].open).toFixed(2),
          };
          a[3] = {
            title:
              "Total  Contractor Amount " +
              parseFloat(duration_res.data[0].open).toFixed(2),
          };
          total_profit =
            parseFloat(
              parseFloat(duration_res.data[2].open) +
                parseFloat(duration_res.data[3].open) +
                parseFloat(duration_res.data[0].open)
            ) - parseFloat(duration_res.data[1].open);
          cardData[0].total = total_profit.toFixed(2);
          this.setState(
            {
              CardData: cardData,
              data: Object.values(duration_res.data),
              list: a,
            },
            () => {
              if (this.props.language.AR_FLAG) {
                this.changeBarChartAmountDir();
              }
            }
          );
        })
        .catch((duration_err) => {
          console.log(duration_err);
        });
    }
  };

  handleChange = (value, report_type) => {
    // console.log('handleChange ->',value,report_type);
    if (report_type === "fn") {
      this.setState({ fn_range_value: value });
    } else if (report_type === "cont") {
      this.setState({ cont_range_value: value });
    } else if (report_type === "pie") {
      this.setState({ pieChart_range_value: value });
    }
    // console.log('RangePicker ->',value);
  };

  handlePanelChange = (value, mode, report_type) => {
    let month1 = moment(value[0]).locale("en").format("MM");
    let year1 = moment(value[0]).locale("en").format("YYYY");

    let month2 = moment(value[1]).locale("en").format("MM");
    let year2 = moment(value[1]).locale("en").format("YYYY");
    if (month1 && month2 && year1 && year2) {
      if (report_type === "fn") {
        this.setState({
          fn_range_value: value,
          fn_mode: [
            mode[0] === "date" ? "month" : mode[0],
            mode[1] === "date" ? "month" : mode[1],
          ],
        });
        // this.fetchesGraphBetweenMonths(month1, month2, year1, year2);
        this.setParamsBetweenMonths(month1, month2, year1, year2, false, "fn");
        this.downloadBetweenMonthsData(
          month1,
          month2,
          year1,
          year2,
          "barChart"
        );
      } else if (report_type === "cont") {
        this.setState({
          cont_range_value: value,
          cont_mode: [
            mode[0] === "date" ? "month" : mode[0],
            mode[1] === "date" ? "month" : mode[1],
          ],
        });
        this.setParamsBetweenMonths(
          month1,
          month2,
          year1,
          year2,
          false,
          "cont"
        );
      } else if (report_type === "pie") {
        this.setState({
          pieChart_range_value: value,
          pieChart_mode: [
            mode[0] === "date" ? "month" : mode[0],
            mode[1] === "date" ? "month" : mode[1],
          ],
        });
        this.setParamsBetweenMonths(month1, month2, year1, year2, false, "pie");
        this.downloadBetweenMonthsData(
          month1,
          month2,
          year1,
          year2,
          report_type
        );
      }
    } //End of if Range months and years found
  };

  /* Extra fetches method, it is not being called */
  fetches = () => {
    const id = localStorage.getItem("client_id");
    const headers = getHeadersForHttpReq();
    if (this.state.tableNo === 2) {
      axios
        .get(
          `${complete_URL}/expenses/by_clients/table?id=${id}&page=${
            this.state.page
          }&size=${this.state.size}&searchValues=${JSON.stringify(
            this.state.searchValues
          )}`,
          { headers }
        )
        .then((res) => {
          // console.log(res.data);
          let pagination = { ...this.state.pagination };
          pagination.total = res.data.totalElements;
          pagination.pageSize = parseInt(res.data.size);
          var columns = [
            {
              title: (
                <span>
                  {this.lang.TABLEHEADERS.TYPE}
                  {/* <Search
                            placeholder="Search type"
                                    onSearch={value => console.log(value)}
                                    onChange={this.handleChangeSearchValues("expense_type")}
            
                                /> */}
                </span>
              ),
              dataIndex: "expense_type",
              key: "expense_type",
              width: "25%",
              sorter: (a, b) =>
                this.compareByAlph(a.expense_type, b.expense_type),
              render: (text, record) => (
                <button className="link-button">{text}</button>
              ),
            },
            {
              title: (
                <span>
                  {this.lang.TABLEHEADERS.AMOUNT}
                  {/* <Search
                                    placeholder="Search amount"
                                    onSearch={value => console.log(value)}
                                    onChange={this.handleChangeSearchValues("amount")}
            
                                /> */}
                </span>
              ),
              dataIndex: "amount",
              key: "amount",
              width: "25%",
              sorter: (a, b) => this.compareByAlph(a.amount, b.amount),
              render: (text, record) => (
                <button className="link-button">{text}</button>
              ),
            },
            {
              title: (
                <span>
                  {this.lang.TABLEHEADERS.NOTE}
                  {/* <Search
                                    placeholder="Search note"
                                    onSearch={value => console.log(value)}
                                    onChange={this.handleChangeSearchValues("note")}
            
                                /> */}
                </span>
              ),
              dataIndex: "note",
              key: "note",
              width: "25%",
              sorter: (a, b) => this.compareByAlph(a.note, b.note),
              render: (text, record) => (
                <button className="link-button">{text}</button>
              ),
            },
          ];
          this.setState({ dddd: res.data, pagination: pagination, columns });
        })
        .catch((err) => {
          console.log("Error occured while fetching ->", err);
        });
    }
    if (this.state.tableNo === 3) {
      // console.log(`${complete_URL}/reservations/table?id=${id}&page=${this.state.page}&size=${this.state.size}&searchValues2=${JSON.stringify(this.state.searchValues)}`)
      var columns = [
        {
          title: (
            <span>
              {this.lang2.TABLEHEADERS.NO}
              {/* <Search
                      placeholder={`Search ${this.lang2.TABLEHEADERS.NO}`}
                      onSearch={value => console.log(value)}
                      onChange={this.handleChangeSearchValues("no")}
                     
                    /> */}
            </span>
          ),
          dataIndex: "no",
          key: "no",
          width: "20%",
          sorter: (a, b) => this.compareByAlph(a.no, b.no),
          render: (text, record) => (
            <button className="link-button">{text}</button>
          ),
        },
        {
          title: (
            <span>
              {this.lang2.TABLEHEADERS.CUSTOMER}
              {/* <Search
                      placeholder={`Search ${this.lang2.TABLEHEADERS.CUSTOMER}`}
                      onSearch={value => console.log(value)}
                      onChange={this.handleChangeSearchValues("customer")}
                     
                    /> */}
            </span>
          ),
          dataIndex: "customer_name",
          render: (text, record) => (
            <button className="link-button">{text}</button>
          ),
        },
        {
          title: (
            <span>
              {this.lang2.TABLEHEADERS.BANQUET}
              {/* <Search
                      placeholder={`Search ${this.lang2.TABLEHEADERS.BANQUET}`}
                      onSearch={value => console.log(value)}
                      onChange={this.handleChangeSearchValues("banquet")}
                     
                    /> */}
            </span>
          ),
          dataIndex: "banquet_name",
          render: (text, record) => (
            <button className="link-button">{text}</button>
          ),
        },
        {
          title: (
            <span>
              {this.lang2.TABLEHEADERS.HALL}
              {/* <Search
                      placeholder={`Search ${this.lang2.TABLEHEADERS.HALL}`}
                      onSearch={value => console.log(value)}
                      onChange={this.handleChangeSearchValues("hall")}
                     
                    /> */}
            </span>
          ),
          dataIndex: "hall_name",
          render: (text, record) => (
            <button className="link-button">{text}</button>
          ),
        },
        {
          title: (
            <span>
              {this.lang2.TABLEHEADERS.RENT}
              {/* <Search
                      placeholder={`Search ${this.lang2.TABLEHEADERS.RENT}`}
                      onSearch={value => console.log(value)}
                      onChange={this.handleChangeSearchValues("hall")}
                     
                    /> */}
            </span>
          ),
          dataIndex: "total_rent",
          render: (text, record) => (
            <button className="link-button">{text}</button>
          ),
        },
      ];
      axios
        .get(
          `${complete_URL}/reservations/table?id=${id}&page=${
            this.state.page
          }&size=${this.state.size}&searchValues=${JSON.stringify(
            this.state.searchValues2
          )}`,
          { headers }
        )
        .then((res) => {
          // console.log(res.data);
          let pagination = { ...this.state.pagination };
          pagination.total = res.data.totalElements;
          pagination.pageSize = parseInt(res.data.size);
          this.setState({ dddd: res.data, pagination: pagination, columns });
        })
        .catch((err) => {
          console.log("Error occured while fetching ->", err);
        });
    }
    if (this.state.tableNo === 1 || this.state.tableNo === 4) {
      // axios.get(`${complete_URL}/hallservices/all/${id}`)
      axios
        .get(
          `${complete_URL}/hallservices/table?id=${id}&page=${
            this.state.page
          }&size=${this.state.size}&searchValues=${JSON.stringify(
            this.state.searchValues2
          )}`,
          { headers }
        )
        .then((res) => {
          var columns = [
            {
              title: `${this.lang3.SETUP.LABELS.SERVICECONTRACTOR}`,
              dataIndex: "service_contractor",
              width: "20%",
              render: (text, record) => (
                <button className="link-button">{text}</button>
              ),
            },
            {
              title: `${this.lang3.SETUP.LABELS.SERVICETYPE}`,
              dataIndex: "service_type",
              width: "25%",
              render: (text, record) => (
                <button className="link-button">{text}</button>
              ),
            },
            {
              title: `${this.lang3.SETUP.LABELS.AMOUNT}`,
              dataIndex: "amount",
              width: "20%",
              render: (text, record) => (
                <button className="link-button">{text}</button>
              ),
            },
            {
              title: `${this.lang3.SETUP.LABELS.COMMISSIONAMOUNT}`,
              dataIndex: "commission_amount",
              width: "20%",
              render: (text, record) => (
                <button className="link-button">{text}</button>
              ),
            },
          ];
          //   setTimeout(() => {
          // console.log(res.data);
          let pagination = { ...this.state.pagination };
          pagination.total = res.data.totalElements;
          pagination.pageSize = parseInt(res.data.size);

          let dddd = res.data;
          // console.log(dddd)
          this.setState({ dddd, columns, pagination });
        })
        .catch((err) => {
          console.log("Error occured while fetching ->", err);
        });
    } else {
      this.setState({ dddd: [], columns: [] });
    }
  }; //End of Method

  // Extra latest method as Contractor Report options are disabled so it is not needed
  getAssignedContByClient = () => {
    const client_id = localStorage.getItem("client_id");
    if (client_id) {
      const headers = getHeadersForHttpReq();
      axios
        .get(`${complete_URL}/contractors/independent/${client_id}`, {
          headers,
        })
        .then((res) => {
          this.setState({ contractors: res.data.data });
        })
        .catch((err) => {
          console.log(
            "Error occured while fetching the Assigned Contractors->",
            err
          );
        });
    } else {
      message.error(this.msg.LOGINERROR, 3);
    }
  };
  /* not to be called as new logic of independent Client
      Extra method as other Report options are disabled so it is not needed*/
  getContractors = () => {
    const client_id = localStorage.getItem("client_id");
    if (client_id) {
      const headers = getHeadersForHttpReq();
      axios
        .get(`${complete_URL}/contractors/fetch_by_clientId/${client_id}`, {
          headers,
        })
        .then((res) => {
          this.setState({ contractors: res.data.data });
        })
        .catch((err) => {
          console.log(
            "Error occured while fetching the old Contractors->",
            err
          );
        });
    }
  }; //End of Method getContractors

  handleSelectContractor = (contractor_index) => {
    let contractor = this.state.contractors[contractor_index];
    // console.log('Contractor->',contractor);
    if (contractor_index > -1) {
      let {
        date,
        from_date,
        to_date,
        month,
        year,
        month1,
        month2,
        year1,
        year2,
        yearOnly,
        allData,
      } = this.state.searchValues_cont_Report;
      let reportFlag = true;
      if (
        date ||
        (from_date && to_date) ||
        (month && year) ||
        (month1 && month2 && year1 && year2) ||
        yearOnly ||
        allData
      ) {
        reportFlag = false;
      }
      let newContObj = Object.assign(this.state.searchValues_cont_Report, {
        serv_cont_name: contractor.name,
        serv_cont_name_ar: contractor.name_ar,
      });

      this.setState({
        searchValues_cont_Report: newContObj,
        disableDownloadContReport: reportFlag,
      });
    }
  }; //End of Method

  handleSelectStatus = (statusOpt_index) => {
    let statusObj = this.state.resrv_Options[statusOpt_index];
    // console.log('statusObj->',statusObj);
    if (
      statusOpt_index > -1 &&
      statusOpt_index < this.state.resrv_Options.length
    ) {
      let {
        date,
        from_date,
        to_date,
        month,
        year,
        month1,
        month2,
        year1,
        year2,
        yearOnly,
        allData,
      } = this.state.searchValues_resrv_Report;
      let reportFlag = true;
      if (
        date ||
        (from_date && to_date) ||
        (month && year) ||
        (month1 && month2 && year1 && year2) ||
        yearOnly ||
        allData
      ) {
        reportFlag = false;
      }
      let newResrvObj = Object.assign(this.state.searchValues_resrv_Report, {
        status: statusObj.name,
      });
      this.setState({
        searchValues_resrv_Report: newResrvObj,
        disableDownloadContReport: reportFlag,
      });
    } //End of Index Validation
  }; //End of Method

  handleSelectReportType = (report_index) => {
    if (report_index > -1 && report_index < this.state.ReportOptions.length) {
      this.setState({ reportOptIndex: report_index });
    }
  };

  // Extra method as Bar Chart click feture is disabled so it is not needed
  clck = (e) => {
    localStorage.setItem("stTime", e.start);
    localStorage.setItem("enTime", e.end);
    this.props.history.push("/dashboard/addreservation");
  };

  // Extra method as Bar Chart click feture is disabled so it is not needed
  clckEv = (e) => {
    localStorage.setItem("reservationUpdate", e.id);
    this.props.history.push("/dashboard/reservationupdate");
  };

  // Extra method
  compareByAlph = (a, b) => {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  };

  // Extra method as Bar Chart click feture is disabled so it is not needed
  clickedChartBar = (e, w, z, i) => {
    // console.log('In Statistics screen..clickedChartBar method.. params->',e,w,z,i)
    //Action listener of Clicking any of Bar from chart..
    /*this.setState({ showTable: true, tableNo: w.datum.quarter, pagination: {} }, () => {
         this.fetches()
      })*/
  };

  // Extra method as Bar Chart click feture is disabled so it is not needed
  eventStyleGetter = (event, start, end, isSelected) => {
    // console.log('event', event.status)
    var backgroundColor;
    event.status === "pending"
      ? (backgroundColor = "#4CBB17")
      : (backgroundColor = "#FF0000");

    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  // For Contractor feature
  getAssignedClients = (cont_id) => {
    const headers = getHeadersForHttpReq();
    if (cont_id) {
      axios
        .get(`${complete_URL}/cont_clients/${cont_id}`, { headers })
        .then((res) => {
          this.setState({ assigned_clientsList: res.data.data });
        })
        .catch((clt_err) => {
          console.log("Error in fetching assigned Clients list->", clt_err);
        });
    }
  };

  selectClient = (e) => {
    let new_report_obj = Object.assign(this.state.searchValues_fn_Report, {
      selectedClient: e,
    });
    this.setState({ searchValues_fn_Report: new_report_obj });
  };

  formateCardTitle = (fontStyle, label, data, index, opt, opt_d, EN_FLAG) => {
    let val = -1;
    let currency = "OMR";
    if (
      opt === "noOfReservation" ||
      opt === "noOfReservationPending" ||
      opt === "noOfRequest" ||
      opt === "noOfRequestPending"
    ) {
      currency = "";
    }
    if (opt === "total" && opt_d === "total") {
      val = data.length > 0 && data[index].total ? data[index].total : 0;
    } else if (opt === "thismonth" && opt_d === "thismonth") {
      val =
        data.length > 0 && data[index].thismonth ? data[index].thismonth : 0;
    } else if (opt === "lastmonth" && opt_d === "lastmonth") {
      val =
        data.length > 0 && data[index].lastmonth ? data[index].lastmonth : 0;
    } else if (opt === "noOfReservation") {
      //Data other than profit
      if (opt_d === "total") {
        val =
          data.length > 0 && data[index].total[0].noOfReservation
            ? data[index].total[0].noOfReservation
            : 0;
      } else if (opt_d === "thismonth") {
        val =
          data.length > 0 && data[index].thismonth[0].noOfReservation
            ? data[index].thismonth[0].noOfReservation
            : 0;
      } else if (opt_d === "lastmonth") {
        val =
          data.length > 0 && data[index].lastmonth[0].noOfReservation
            ? data[index].lastmonth[0].noOfReservation
            : 0;
      }
    } else if (opt === "noOfRequest") {
      //Data other than profit
      if (opt_d === "total") {
        val =
          data.length > 0 && data[index].total[0].noOfRequest
            ? data[index].total[0].noOfRequest
            : 0;
      } else if (opt_d === "thismonth") {
        val =
          data.length > 0 && data[index].thismonth[0].noOfRequest
            ? data[index].thismonth[0].noOfRequest
            : 0;
      } else if (opt_d === "lastmonth") {
        val =
          data.length > 0 && data[index].lastmonth[0].noOfRequest
            ? data[index].lastmonth[0].noOfRequest
            : 0;
      }
    } else if (opt === "noOfReservationPending") {
      //Data other than profit
      if (opt_d === "total") {
        val =
          data.length > 0 && data[index].total[0].noOfReservationPending
            ? data[index].total[0].noOfReservationPending
            : 0;
      } else if (opt_d === "thismonth") {
        val =
          data.length > 0 && data[index].thismonth[0].noOfReservationPending
            ? data[index].thismonth[0].noOfReservationPending
            : 0;
      } else if (opt_d === "lastmonth") {
        val =
          data.length > 0 && data[index].lastmonth[0].noOfReservationPending
            ? data[index].lastmonth[0].noOfReservationPending
            : 0;
      }
    } else if (opt === "noOfRequestPending") {
      //Data other than profit
      if (opt_d === "total") {
        val =
          data.length > 0 && data[index].total[0].noOfRequestPending
            ? data[index].total[0].noOfRequestPending
            : 0;
      } else if (opt_d === "thismonth") {
        val =
          data.length > 0 && data[index].thismonth[0].noOfRequestPending
            ? data[index].thismonth[0].noOfRequestPending
            : 0;
      } else if (opt_d === "lastmonth") {
        val =
          data.length > 0 && data[index].lastmonth[0].noOfRequestPending
            ? data[index].lastmonth[0].noOfRequestPending
            : 0;
      }
    } else if (opt === "totalExpense") {
      //Data other than profit
      if (opt_d === "total") {
        val =
          data.length > 0 && data[index].total[0].totalExpense
            ? data[index].total[0].totalExpense
            : 0;
      } else if (opt_d === "thismonth") {
        val =
          data.length > 0 && data[index].thismonth[0].totalExpense
            ? data[index].thismonth[0].totalExpense
            : 0;
      } else if (opt_d === "lastmonth") {
        val =
          data.length > 0 && data[index].lastmonth[0].totalExpense
            ? data[index].lastmonth[0].totalExpense
            : 0;
      }
    } else if (opt === "totalIncome") {
      //Data other than profit
      if (opt_d === "total") {
        val =
          data.length > 0 && data[index].total[0].totalIncome
            ? data[index].total[0].totalIncome
            : 0;
      } else if (opt_d === "thismonth") {
        val =
          data.length > 0 && data[index].thismonth[0].totalIncome
            ? data[index].thismonth[0].totalIncome
            : 0;
      } else if (opt_d === "lastmonth") {
        val =
          data.length > 0 && data[index].lastmonth[0].totalIncome
            ? data[index].lastmonth[0].totalIncome
            : 0;
      }
    }
    let row = (
      <Row
        gutter={24}
        key={opt + opt_d}
        dir={this.state.dir}
        style={{ display: this.state.display }}
      >
        <Col span={11}>
          <p style={fontStyle}>{label}</p>
        </Col>
        <Col span={13}>
          <p
            style={{
              color: "#fc5c7d",
              float: EN_FLAG ? "right" : "left",
              ...fontStyle,
            }}
          >
            {val} {currency}
          </p>
        </Col>
      </Row>
    );
    return row;
  };

  render() {
    let {
      dir,
      display,
      spinningMessage,
      spin,
      cardData,
      assigned_clientsList,
    } = this.state;
    // eslint-disable-next-line
    const is_contr = localStorage.getItem("is_contr") == 1;
    const pd = is_contr ? { paddingRight: 0 } : { paddingTop: "1%" };
    const lang = this.props.language._STATISTICS;
    const EN_FLAG = this.props.language.EN_FLAG;
    const durationsDropdownStyle = {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
    };
    let fontStyleT = { fontSize: "13px" };
    let fontStyleB = { fontSize: "12.5px" };
    let w = window.innerWidth;
    //1200px, 1200px >= x > 990px, 990px >= x > 768px,  768px >= x > 640px, 640px >= x > 480, 480 >= x > 400, 400 >= x -> never gonna happen?
    if (w < 1280 && w >= 1024) {
      fontStyleT = { fontSize: "9px" };
      fontStyleB = { fontSize: "9px" };
    } else if (w < 1024 && w >= 768) {
      fontStyleT = { fontSize: "6px" };
      fontStyleB = { fontSize: "6px" };
    }

    let {
      TITLE,
      MONTHLY_OPTION,
      MONTHL,
      STARTMONTH,
      ENDMONTH,
      YEARLY_OPTION,
      ALL,
    } = this.props.language._STATISTICS.FINANCIAL_STATISTICS_CHART;
    let {
      TOTAL_PROFIT,
      CON_RESERVATION,
      CON_REQUESTS,
      PEN_RESERVATION,
      PEN_REQUESTS,
      TOTAL_EXPENSES,
      TOTAL_INCOMES,
      THIS_MONTH,
      LAST_MONTH,
    } = this.props.language._STATISTICS.TOP_PROGRESS_BAR_TITLES;

    const option = {
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let dataset = data.datasets[tooltipItem.datasetIndex];
            let meta = dataset._meta[Object.keys(dataset._meta)[0]];
            let total = meta.total;
            let currentValue = dataset.data[tooltipItem.index];
            let percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(2)
            );
            return currentValue + " (" + percentage + "%)";
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
      maintainAspectRatio: false,
      responsive: false,
      legend: {
        position: "left",
        labels: {
          boxWidth: 25,
        },
      },
    };
    let totalProfitRow = this.formateCardTitle(
      fontStyleT,
      TOTAL_PROFIT,
      cardData,
      6,
      "total",
      "total",
      EN_FLAG
    );
    let thisMonthProfitRow = this.formateCardTitle(
      fontStyleB,
      THIS_MONTH,
      cardData,
      6,
      "thismonth",
      "thismonth",
      EN_FLAG
    );
    let lastMonthProfitRow = this.formateCardTitle(
      fontStyleB,
      LAST_MONTH,
      cardData,
      6,
      "lastmonth",
      "lastmonth",
      EN_FLAG
    );

    let totalConfResrvRow = this.formateCardTitle(
      fontStyleT,
      CON_RESERVATION,
      cardData,
      2,
      "noOfReservation",
      "total",
      EN_FLAG
    );
    let thisMonthConfResrvRow = this.formateCardTitle(
      fontStyleB,
      THIS_MONTH,
      cardData,
      2,
      "noOfReservation",
      "thismonth",
      EN_FLAG
    );
    let lastMonthConfResrvRow = this.formateCardTitle(
      fontStyleB,
      LAST_MONTH,
      cardData,
      2,
      "noOfReservation",
      "lastmonth",
      EN_FLAG
    );

    let totalConfReqRow = this.formateCardTitle(
      fontStyleT,
      CON_REQUESTS,
      cardData,
      2,
      "noOfRequest",
      "total",
      EN_FLAG
    );
    let thisMonthConfReqRow = this.formateCardTitle(
      fontStyleB,
      THIS_MONTH,
      cardData,
      2,
      "noOfRequest",
      "thismonth",
      EN_FLAG
    );
    let lastMonthConfReqRow = this.formateCardTitle(
      fontStyleB,
      LAST_MONTH,
      cardData,
      2,
      "noOfRequest",
      "lastmonth",
      EN_FLAG
    );

    let totalPendResrvRow = this.formateCardTitle(
      fontStyleT,
      PEN_RESERVATION,
      cardData,
      3,
      "noOfReservationPending",
      "total",
      EN_FLAG
    );
    let thisMonthPendResrvRow = this.formateCardTitle(
      fontStyleB,
      THIS_MONTH,
      cardData,
      3,
      "noOfReservationPending",
      "thismonth",
      EN_FLAG
    );
    let lastMonthPendResrvRow = this.formateCardTitle(
      fontStyleB,
      LAST_MONTH,
      cardData,
      3,
      "noOfReservationPending",
      "lastmonth",
      EN_FLAG
    );

    let totalPendReqRow = this.formateCardTitle(
      fontStyleT,
      PEN_REQUESTS,
      cardData,
      3,
      "noOfRequestPending",
      "total",
      EN_FLAG
    );
    let thisMonthPendReqRow = this.formateCardTitle(
      fontStyleB,
      THIS_MONTH,
      cardData,
      3,
      "noOfRequestPending",
      "thismonth",
      EN_FLAG
    );
    let lastMonthPendReqRow = this.formateCardTitle(
      fontStyleB,
      LAST_MONTH,
      cardData,
      3,
      "noOfRequestPending",
      "lastmonth",
      EN_FLAG
    );

    let totalExpRow = this.formateCardTitle(
      fontStyleT,
      TOTAL_EXPENSES,
      cardData,
      1,
      "totalExpense",
      "total",
      EN_FLAG
    );
    let thisMonthExpRow = this.formateCardTitle(
      fontStyleB,
      THIS_MONTH,
      cardData,
      1,
      "totalExpense",
      "thismonth",
      EN_FLAG
    );
    let lastMonthExpRow = this.formateCardTitle(
      fontStyleB,
      LAST_MONTH,
      cardData,
      1,
      "totalExpense",
      "lastmonth",
      EN_FLAG
    );

    let totalIncomeRow = this.formateCardTitle(
      fontStyleT,
      TOTAL_INCOMES,
      cardData,
      5,
      "totalIncome",
      "total",
      EN_FLAG
    );
    let thisMonthIncomeRow = this.formateCardTitle(
      fontStyleB,
      THIS_MONTH,
      cardData,
      5,
      "totalIncome",
      "thismonth",
      EN_FLAG
    );
    let lastMonthIncomeRow = this.formateCardTitle(
      fontStyleB,
      LAST_MONTH,
      cardData,
      5,
      "totalIncome",
      "lastmonth",
      EN_FLAG
    );

    let cardWrapperStyle = {
      padding: "0 0.3%",
      width: "20%",
      display: EN_FLAG ? "inline-block" : "block",
    };
    return (
      <>
        <Spin tip={spinningMessage} spinning={spin}>
          {/* STATISTICS HEADER CONTAINING PROFIT, RESERVED, PENDING, EXPENSE, INCOME */}
          {/* <Row gutter={24} style={{ margin : '20px 0px', display: 'flex', flexDirection: EN_FLAG ? 'row' : 'row-reverse' }}> */}
          <Row gutter={24} dir={dir} style={{ margin: "20px 0px", display }}>
            <div style={cardWrapperStyle}>
              <Card
                key={"card1"}
                size={"small"}
                title={[totalProfitRow]}
                bordered={false}
              >
                {thisMonthProfitRow}
                <div style={{ marginBottom: "5%" }}>
                  <Progress
                    key={"card1sp"}
                    percent={
                      this.state.cardData.length > 0 &&
                      this.state.cardData[6].thismonth < 1
                        ? 0
                        : this.state.cardData.length > 0 &&
                          this.state.cardData[6].lastmonth < 1
                        ? 100
                        : this.state.cardData.length > 0 &&
                          Math.round(
                            (this.state.cardData[6].thismonth /
                              this.state.cardData[6].lastmonth) *
                              100
                          )
                    }
                    status="active"
                    strokeColor="#fc5c7d"
                  />
                </div>
                {lastMonthProfitRow}
              </Card>
            </div>
            {!is_contr && (
              <>
                <div style={cardWrapperStyle}>
                  <Card
                    key={"card2"}
                    size={"small"}
                    title={[totalConfResrvRow]}
                    bordered={false}
                  >
                    {thisMonthConfResrvRow}
                    <div style={{ marginBottom: "5%" }}>
                      <Progress
                        percent={
                          this.state.cardData.length > 0 &&
                          this.state.cardData[2].thismonth[0].noOfReservation <
                            1
                            ? 0
                            : this.state.cardData.length > 0 &&
                              this.state.cardData[2].lastmonth[0]
                                .noOfReservation < 1
                            ? 100
                            : this.state.cardData.length > 0 &&
                              Math.round(
                                (this.state.cardData[2].thismonth[0]
                                  .noOfReservation /
                                  this.state.cardData[2].lastmonth[0]
                                    .noOfReservation) *
                                  100
                              )
                        }
                        status="active"
                        strokeColor="#fc5c7d"
                      />
                    </div>
                    {lastMonthConfResrvRow}
                  </Card>
                </div>
                <div style={cardWrapperStyle}>
                  <Card
                    key={"card3"}
                    size={"small"}
                    title={[totalPendResrvRow]}
                    bordered={false}
                  >
                    {thisMonthPendResrvRow}
                    <div style={{ marginBottom: "5%" }}>
                      <Progress
                        percent={
                          this.state.cardData.length > 0 &&
                          this.state.cardData[3].thismonth[0]
                            .noOfReservationPending < 1
                            ? 0
                            : this.state.cardData.length > 0 &&
                              this.state.cardData[3].lastmonth[0]
                                .noOfReservationPending < 1
                            ? 100
                            : this.state.cardData.length > 0 &&
                              Math.round(
                                (this.state.cardData[3].thismonth[0]
                                  .noOfReservationPending /
                                  this.state.cardData[3].lastmonth[0]
                                    .noOfReservationPending) *
                                  100
                              )
                        }
                        status="active"
                        strokeColor="#fc5c7d"
                      />
                    </div>
                    {lastMonthPendResrvRow}
                  </Card>
                </div>
              </>
            )}
            {is_contr && (
              <>
                <div style={cardWrapperStyle}>
                  <Card
                    key={"card2"}
                    size={"small"}
                    title={[totalConfReqRow]}
                    bordered={false}
                  >
                    {thisMonthConfReqRow}
                    <div style={{ marginBottom: "5%" }}>
                      <Progress
                        percent={
                          this.state.cardData.length > 0 &&
                          this.state.cardData[2].thismonth[0].noOfRequest < 1
                            ? 0
                            : this.state.cardData.length > 0 &&
                              this.state.cardData[2].lastmonth[0].noOfRequest <
                                1
                            ? 100
                            : this.state.cardData.length > 0 &&
                              Math.round(
                                (this.state.cardData[2].thismonth[0]
                                  .noOfRequest /
                                  this.state.cardData[2].lastmonth[0]
                                    .noOfRequest) *
                                  100
                              )
                        }
                        status="active"
                        strokeColor="#fc5c7d"
                      />
                    </div>
                    {lastMonthConfReqRow}
                  </Card>
                </div>
                <div style={cardWrapperStyle}>
                  <Card
                    key={"card3"}
                    size={"small"}
                    title={[totalPendReqRow]}
                    bordered={false}
                  >
                    {thisMonthPendReqRow}
                    <div style={{ marginBottom: "5%" }}>
                      <Progress
                        percent={
                          this.state.cardData.length > 0 &&
                          this.state.cardData[3].thismonth[0]
                            .noOfRequestPending < 1
                            ? 0
                            : this.state.cardData.length > 0 &&
                              this.state.cardData[3].lastmonth[0]
                                .noOfRequestPending < 1
                            ? 100
                            : this.state.cardData.length > 0 &&
                              Math.round(
                                (this.state.cardData[3].thismonth[0]
                                  .noOfRequestPending /
                                  this.state.cardData[3].lastmonth[0]
                                    .noOfRequestPending) *
                                  100
                              )
                        }
                        status="active"
                        strokeColor="#fc5c7d"
                      />
                    </div>
                    {lastMonthPendReqRow}
                  </Card>
                </div>
              </>
            )}
            <div style={cardWrapperStyle}>
              <Card
                key={"card4"}
                size={"small"}
                title={[totalExpRow]}
                bordered={false}
              >
                {thisMonthExpRow}
                <div style={{ marginBottom: "5%" }}>
                  <Progress
                    percent={
                      this.state.cardData.length > 0 &&
                      this.state.cardData[1].thismonth[0].totalExpense < 1
                        ? 0
                        : this.state.cardData.length > 0 &&
                          this.state.cardData[1].lastmonth[0].totalExpense < 1
                        ? 100
                        : this.state.cardData.length > 0 &&
                          Math.round(
                            (this.state.cardData[1].thismonth[0].totalExpense /
                              this.state.cardData[1].lastmonth[0]
                                .totalExpense) *
                              100
                          )
                    }
                    status="active"
                    strokeColor="#fc5c7d"
                  />
                </div>
                {lastMonthExpRow}
              </Card>
            </div>
            <div style={cardWrapperStyle}>
              <Card
                key={"card5"}
                size={"small"}
                title={[totalIncomeRow]}
                bordered={false}
              >
                {thisMonthIncomeRow}
                <div style={{ marginBottom: "5%" }}>
                  <Progress
                    key={"card5sp"}
                    percent={
                      cardData.length > 0 &&
                      cardData[5].thismonth[0].totalIncome < 1
                        ? 0
                        : cardData.length > 0 &&
                          cardData[5].lastmonth[0].totalIncome < 1
                        ? 100
                        : cardData.length > 0 &&
                          Math.round(
                            (cardData[5].thismonth[0].totalIncome /
                              cardData[5].lastmonth[0].totalIncome) *
                              100
                          )
                    }
                    status="active"
                    strokeColor="#fc5c7d"
                  />
                </div>
                {lastMonthIncomeRow}
              </Card>
            </div>
          </Row>
          {/* STATISTICS Report 6 7 8*/}

          <Card key={"card6"} bordered={false}>
            <Row gutter={24} style={{ marginBottom: "1%" }}>
              {/*this.props.language.AR_FLAG && <Col span="12"></Col>*/}
              <Col span={12} style={{ paddingLeft: 0 }}>
                <Card
                  className="chartClass"
                  key={"fnCard"}
                  style={{ height: "730px" }} //495px
                  title={[
                    <span
                      key={"spFn"}
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <h1 key={"spFn2"}>{TITLE}</h1>
                      {/* <MonthlyReport reportDate={this.state.reportDate} /> */}
                      <Button
                        key={"spFn3"}
                        type="primary"
                        onClick={() => {
                          this.downloadReport("fn");
                        }}
                        disabled={this.state.disableDownloadReport}
                        icon="download"
                        size={"default"}
                      >
                        {this.props.language.DOWNLOAD_REPORT}
                      </Button>
                    </span>,
                  ]}
                >
                  {" "}
                  {/*Financial Report Chart*/}
                  {/*contractor will select assigned Client for Financial report/ Barchart */}
                  {is_contr && (
                    <Row gutter={24} dir={dir} style={{ display }}>
                      <Col span={12}>
                        {/* <div style={durationsDropdownStyle}> */}
                        <Select
                          onChange={this.selectClient}
                          placeholder={lang.FINANCIAL_STATISTICS_CHART.CLIENT}
                          style={{ width: "84%", marginBottom: "20px" }}
                        >
                          {EN_FLAG
                            ? assigned_clientsList.length > 0 &&
                              assigned_clientsList.map((ct, index) => (
                                <Select.Option
                                  style={{ textAlign: "left" }}
                                  key={"ct_" + index}
                                  value={ct.client_id}
                                >
                                  {ct.name}
                                </Select.Option>
                              ))
                            : assigned_clientsList.length > 0 &&
                              this.state.assigned_clientsList.map(
                                (ct, index) => (
                                  <Select.Option
                                    style={{ textAlign: "right" }}
                                    key={"ctr_" + index}
                                    value={ct.client_id}
                                  >
                                    {ct.name_ar}
                                  </Select.Option>
                                )
                              )}
                        </Select>
                        {/* </div> */}
                      </Col>
                      <Col span={12}></Col>
                    </Row>
                  )}
                  {/*Select duration for Financial Report /Chart*/}
                  <Row gutter={24} style={{ zIndex: 215 }}>
                    <Col span={24}>
                      <div style={durationsDropdownStyle}>
                        <Select
                          suffixIcon={[<Icon type="filter" key={"fnIc"} />]}
                          onChange={this.filterfnDataOptionOnchange}
                          style={{ width: "40%" }}
                          key={"fn"}
                          placeholder={this.props.language.FILTER_OPTION}
                          value={this.state.filterNode}
                        >
                          <Select.Option
                            style={{ textAlign: this.state.textAlignValue }}
                            value="m"
                            key={"fn" + 1}
                          >
                            {MONTHLY_OPTION}
                          </Select.Option>
                          <Select.Option
                            style={{ textAlign: this.state.textAlignValue }}
                            value="y"
                            key={"fn" + 2}
                          >
                            {" "}
                            {YEARLY_OPTION}
                          </Select.Option>
                          <Select.Option
                            style={{ textAlign: this.state.textAlignValue }}
                            value="mm"
                            key={"fn" + 3}
                          >
                            {MONTHL}
                          </Select.Option>
                          <Select.Option
                            style={{ textAlign: this.state.textAlignValue }}
                            value="aa"
                            key={"fn" + 5}
                          >
                            {ALL}
                          </Select.Option>
                        </Select>
                        <span>
                          {this.state.showFilter &&
                          this.state.filterNode === "m" ? (
                            <MonthPicker
                              defaultValue={new moment()}
                              placeholder={MONTHLY_OPTION}
                              onChange={(e) => {
                                this.setParamsOneMonthOfYear(
                                  moment(e).locale("en").format("MM"),
                                  moment(e).locale("en").format("YYYY"),
                                  false,
                                  "fn"
                                );
                              }}
                            />
                          ) : this.state.filterNode === "y" ? (
                            <YearPicker
                              onChange={(e) => {
                                this.setParamsYear(e, false, "fn");
                              }}
                              placeholder={YEARLY_OPTION}
                            />
                          ) : // <Datetime
                          //    closeOnSelect={true}
                          //    dateFormat="YYYY" onChange={(selectedDate) => { this.setParamsYear(selectedDate.year(), false, 'fn') }}
                          //    placeholder={lang.FINANCIAL_STATISTICS_CHART.YEARLY_OPTION}
                          //    locale = {this.props.language.LOCALE}
                          // />
                          this.state.filterNode === "mm" ? (
                            <RangePicker
                              placeholder={[STARTMONTH, ENDMONTH]}
                              format="YYYY-MM"
                              value={this.state.fn_range_value}
                              mode={this.state.fn_mode}
                              onChange={(value) => {
                                this.handleChange(value, "fn");
                              }}
                              onPanelChange={(value, mode) => {
                                this.handlePanelChange(value, mode, "fn");
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  {/* Victory Bar Chart for Financial Report */}
                  {!is_contr ? (
                    <Row gutter={24}>
                      <Col span={24}>
                        <VictoryChart
                          domainPadding={20}
                          height={300}
                          width={480}
                          padding={{ top: 40, bottom: 60, left: 80, right: 80 }}
                        >
                          <VictoryAxis
                            tickValues={[1, 2, 3, 4, 5, 6, 7, 8]}
                            tickFormat={[
                              this.lang4.CONTRACTORAMOUNT,
                              this.lang4.INCOMES,
                              this.lang4.EXPENSES,
                              this.lang4.TOTALRENTBILL,
                              this.lang4.TOTALRENT,
                              this.lang4.TOTALCOMMISSION,
                              this.lang4.CONTRACTORAMOUNTPAID,
                              this.lang4.PAIDCOMMISSION,
                            ]}
                            style={{
                              tickLabels: {
                                angle: EN_FLAG ? 17 : -17,
                                verticalAnchor: "middle",
                                textAnchor: "start",
                              },
                            }}
                          />
                          <VictoryAxis
                            dependentAxis
                            tickFormat={(x) => `${parseInt(x)}`}
                          />
                          <VictoryBar
                            data={this.state.data}
                            labels={(d) => `${parseInt(d.open)}`}
                            x="quarter"
                            y="earnings"
                            events={[
                              {
                                eventHandlers: {
                                  onClick: (e, w, z, i) => {
                                    this.clickedChartBar(e, w, z, i);
                                  },
                                },
                              },
                            ]}
                          />
                        </VictoryChart>
                      </Col>
                    </Row>
                  ) : (
                    <Row gutter={24}>
                      <Col span={24}>
                        <VictoryChart
                          domainPadding={20}
                          height={300}
                          width={480}
                          padding={{ top: 40, bottom: 60, left: 80, right: 80 }}
                        >
                          <VictoryAxis
                            tickValues={[1, 2, 3]}
                            tickFormat={[
                              this.lang4.CONTRACTORAMOUNT,
                              this.lang4.EXPENSES,
                              this.lang4.INCOMES,
                            ]}
                            style={{
                              tickLabels: {
                                angle: EN_FLAG ? 17 : -17,
                                verticalAnchor: "middle",
                                textAnchor: "start",
                              },
                            }}
                          />
                          <VictoryAxis
                            dependentAxis
                            tickFormat={(x) => `${parseInt(x)}`}
                          />
                          <VictoryBar
                            data={this.state.data}
                            labels={(d) => `${parseInt(d.open)}`}
                            x="quarter"
                            y="earnings"
                            events={[
                              {
                                eventHandlers: {
                                  onClick: (e, w, z, i) => {
                                    this.clickedChartBar(e, w, z, i);
                                  },
                                },
                              },
                            ]}
                          />
                        </VictoryChart>
                      </Col>
                    </Row>
                  )}
                  {/* Extra Table to be display details on any of statistics */}
                  {this.state.showTable && (
                    <Row gutter={24}>
                      <Col span={24}>
                        <Table
                          className="chart-table"
                          size="small"
                          columns={this.state.columns}
                          rowKey="id"
                          dataSource={this.state.dddd.selectedElements}
                          onChange={this.handleChangePagination}
                          pagination={this.state.pagination}
                        />
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
              {!is_contr && (
                <Col span={12} style={{ paddingRight: 0 }}>
                  <AnnualSummaryReport
                    {...this.props}
                    generate_pdf_popup={this.generate_pdf_popup}
                    getReportBasicData={this.getReportBasicData}
                  />
                </Col>
              )}
              {/* </Row>  */}
              {/*28Oct2020 Exp moved here b/c of addition of Summary report*/}
              {/* <Row gutter={24} >  */}
              {/* <Col span={12} style={{paddingLeft : 0}}></Col> */}
              <Col span={12} style={{ paddingLeft: 0, ...pd }}>
                <Card
                  className="chartClass"
                  key={"pieCard"}
                  style={{ height: "495px" }}
                  title={[
                    <span
                      key={"spPie"}
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <h1
                        key={"spPie2"}
                        style={{
                          color: "#fc5c7d",
                          fontSize: "19px",
                          fontWeight: 500,
                        }}
                      >
                        {lang.EXPENSEREPORT.TITLE}
                      </h1>
                      <Button
                        key={"spPie3"}
                        type="primary"
                        onClick={() => {
                          this.downloadReport("pie_report");
                        }}
                        disabled={this.state.disableDownloadPieReport}
                        icon="download"
                        size={"default"}
                      >
                        {this.props.language.DOWNLOAD_REPORT}
                      </Button>
                    </span>,
                  ]}
                >
                  <Row gutter={24} style={{ zIndex: 215 }}>
                    <Col span={24}>
                      <div style={durationsDropdownStyle}>
                        <Select
                          suffixIcon={[<Icon type="filter" key={"pieIc"} />]}
                          onChange={this.filterPieChartDataOptionOnchange}
                          style={{ width: "40%" }}
                          placeholder={this.props.language.FILTER_OPTION}
                          value={this.state.filterPieChartNode}
                        >
                          <Select.Option
                            style={{ textAlign: this.state.textAlignValue }}
                            value="m"
                            key={"pie" + 1}
                          >
                            {MONTHLY_OPTION}
                          </Select.Option>
                          <Select.Option
                            style={{ textAlign: this.state.textAlignValue }}
                            value="y"
                            key={"pie" + 2}
                          >
                            {" "}
                            {YEARLY_OPTION}
                          </Select.Option>
                          <Select.Option
                            style={{ textAlign: this.state.textAlignValue }}
                            value="mm"
                            key={"pie" + 3}
                          >
                            {MONTHL}
                          </Select.Option>
                          <Select.Option
                            style={{ textAlign: this.state.textAlignValue }}
                            value="aa"
                            key={"pie" + 5}
                          >
                            {ALL}
                          </Select.Option>
                        </Select>
                        <span>
                          {this.state.showPieChartFilter &&
                          this.state.filterPieChartNode === "m" ? (
                            <MonthPicker
                              defaultValue={new moment()}
                              placeholder={MONTHLY_OPTION}
                              onChange={(e) => {
                                this.setParamsOneMonthOfYear(
                                  moment(e).locale("en").format("MM"),
                                  moment(e).locale("en").format("YYYY"),
                                  false,
                                  "pie"
                                );
                              }}
                            />
                          ) : this.state.filterPieChartNode === "y" ? (
                            <YearPicker
                              onChange={(e) => {
                                this.setParamsYear(e, false, "pie");
                              }}
                              placeholder={YEARLY_OPTION}
                            />
                          ) : // <Datetime
                          //    closeOnSelect={true}
                          //    dateFormat="YYYY" onChange={(selectedDate) => { this.setParamsYear(selectedDate.year(), false, 'pie') }}
                          //    placeholder={lang.FINANCIAL_STATISTICS_CHART.YEARLY_OPTION}
                          // />
                          this.state.filterPieChartNode === "mm" ? (
                            <RangePicker
                              placeholder={[STARTMONTH, ENDMONTH]}
                              format="YYYY-MM"
                              value={this.state.pieChart_range_value}
                              mode={this.state.pieChart_mode}
                              onChange={(value) => {
                                this.handleChange(value, "pie");
                              }}
                              onPanelChange={(value, mode) => {
                                this.handlePanelChange(value, mode, "pie");
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  {/*Display Pie Chart*/}
                  <Row gutter={24}>
                    <Col span={24}>
                      <Pie
                        data={this.state.piChartCompleteData}
                        options={option}
                        width={400}
                        height={400}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
        </Spin>
      </>
    );
  }
}

export default Statistics;
